import { SettingOutlined } from '@ant-design/icons';
import { Card, Col, Row, Switch } from 'antd';
import { FC } from 'react';
import { connect } from 'react-redux';
import {
  MapReducerUpdate,
  updateMapState,
} from '@netomnia/modules/ProjectModule/views/Map/store/actions';
import { MapReducer } from '@netomnia/modules/ProjectModule/views/Map/store/reducer';

interface Props {
  mapReducer: MapReducer;
  updateMap: (params: MapReducerUpdate) => {};
}

const MapSidebarSettings: FC<Props> = (props: Props) => {
  const { mapReducer, updateMap } = props;
  const toggleOnHoldItems = () => {
    updateMap({
      showOnHoldItems: !mapReducer.showOnHoldItems,
    });
  };

  const toggleMapGreyscale = () => {
    updateMap({
      greyscaleMap: !mapReducer.greyscaleMap,
    });
  };

  return (
    <Card
      style={{ marginBottom: '15px' }}
      title={
        <span>
          <SettingOutlined style={{ marginRight: '5px' }} />
          Settings
        </span>
      }
    >
      <Row>
        {/* Greyscale Switch */}
        <Col span={12}>
          <Switch
            style={{ marginRight: 8, marginTop: 2 }}
            checked={mapReducer.greyscaleMap}
            onClick={() => toggleMapGreyscale()}
          />
          <span style={{ fontSize: '0.9em' }}>Greyscale Map</span>
        </Col>
        {/* On Hold Items */}
        <Col span={12}>
          <Switch
            style={{ marginRight: 8, marginTop: 2 }}
            checked={mapReducer.showOnHoldItems}
            onClick={() => toggleOnHoldItems()}
          />
          <span style={{ fontSize: '0.9em' }}>On Hold items</span>
        </Col>
      </Row>
    </Card>
  );
};

const mapState = (state: any) => ({
  mapReducer: state.mapReducer,
});

const mapDispatch = (dispatch: any) => ({
  updateMap: (params: MapReducerUpdate) => dispatch(updateMapState(params)),
});

export default connect(mapState, mapDispatch)(MapSidebarSettings);
