import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Row } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { orderBuilderContext } from '../index';
import { SET_ACCOUNT, SET_ADDRESS, SET_CONTACT } from '../store/constants';
import { Button, NonIdealState } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import { httpGet } from '@core/http/requests';
import CoreForm from '@core/components/Forms/CoreForm';
import { initializeRecordForm } from '../../../../../redux/stores/form/actions';

interface Props {
  defaultAccount?: DbRecordEntityTransform;
  initializeForm: (params: any) => void;
}

const { CONTACT, ADDRESS, ACCOUNT } = SchemaModuleEntityTypeEnums;
const { CRM_MODULE } = SchemaModuleTypeEnums;
const UUID = uuidv4();

const OrderBuilderAccountManager: React.FunctionComponent<Props> = (props) => {
  const { state, dispatch } = useContext(orderBuilderContext);
  const [accountSchema, setAccountSchema] = useState<SchemaEntity | undefined>(undefined);

  const { initializeForm } = props;

  useEffect(() => {
    getAccountSchema();
  }, []);

  const getAccountSchema = async () => {
    const schema = await getOdinSchemaByEntity(CRM_MODULE, ACCOUNT);
    setAccountSchema(schema);
  };

  const initializeCreateAccountForm = () => {
    if (accountSchema) {
      initializeForm({
        formUUID: UUID,
        showFormModal: true,
        showInitializing: false,
        isCreateReq: true,
        isUpdateReq: false,
        schema: accountSchema,
        sections: [
          {
            name: accountSchema?.name,
            schema: accountSchema,
          },
        ],
      });
    }
  };

  const initializeUpdateAccountForm = () => {
    if (accountSchema) {
      initializeForm({
        formUUID: UUID,
        showFormModal: true,
        showInitializing: false,
        isCreateReq: false,
        isUpdateReq: true,
        selected: state.account,
        schema: accountSchema,
        sections: [
          {
            name: accountSchema?.name,
            schema: accountSchema,
          },
        ],
      });
    }
  };

  // When account is created,
  const onAccountSuccess = async (params: { event: string; results: any }) => {
    console.log('debug: params', params);
    if (params) {
      // 1. Fetch newly created account, and set to context
      const account = await httpGet(`${CRM_MODULE}/v1.0/db/${ACCOUNT}/${params.results.id}`);
      console.log('debug: Account fetched', account?.data?.data);

      if (account?.data?.data) {
        dispatch({ type: SET_ACCOUNT, payload: account?.data.data });
        const contactId = getProperty(account?.data.data, 'ContactId');
        const addressId = getProperty(account?.data.data, 'AddressId');

        // 2. Fetch contact
        if (contactId) {
          const contact = await httpGet(`${CRM_MODULE}/v1.0/db/${CONTACT}/${contactId}`);
          if (contact?.data?.data) {
            dispatch({ type: SET_CONTACT, payload: contact?.data.data });
            console.log('debug: Contact fetched', contact?.data?.data);
          }
        }

        // 2. Fetch Address
        if (addressId) {
          const address = await httpGet(`${CRM_MODULE}/v1.0/db/${ADDRESS}/${addressId}`);
          console.log('debug: ADDRESS', address);
          if (address?.data?.data) {
            console.log('debug: Address fetched', address?.data?.data);
            dispatch({ type: SET_ADDRESS, payload: address?.data.data });
          }
        }
      }
    }
  };

  const renderTitle = () => {
    if (!state?.account) {
      return 'Create Account';
    } else {
      return 'Update Account';
    }
  };

  const renderDescription = () => {
    if (!state?.account) {
      return 'Prior to creating an order, you need to create an Account. Make sure to provide all the necessary information in the form, like Address and Contact.';
    } else {
      return 'Account is missing some information needed to create an Order. Please update the Account and make sure to provide all the necessary information, like Address and Contact.';
    }
  };

  const renderButtonText = () => {
    if (state?.account) {
      return 'Update Account';
    } else {
      return 'Create Account';
    }
  };

  return (
    <>
      <CoreForm
        type="MODAL"
        formUUID={UUID}
        onSubmitEvent={(params: { event: string; results: any }) => onAccountSuccess(params)}
      />

      <Row>
        <Col span={24} style={{ paddingBottom: 20 }}>
          <NonIdealState
            title={renderTitle()}
            description={renderDescription()}
            action={
              <Button
                large
                intent="primary"
                onClick={() => {
                  if (state.account) {
                    initializeUpdateAccountForm();
                  } else {
                    initializeCreateAccountForm();
                  }
                }}
                disabled={!accountSchema}
                text={renderButtonText()}
              />
            }
          />
        </Col>
      </Row>
    </>
  );
};

const mapState = () => ({});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
});

export default connect(mapState, mapDispatch)(OrderBuilderAccountManager);
