import { Callout, DialogStep, MultistepDialog, Section, SectionCard } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getOdinSchemaByRecord } from '@core/helpers/schemaHelpers';
import { httpDelete, httpGet, httpPost, httpPut } from '@core/http/requests';
import SchemaActionFlowStep from '@core/components/SchemaActions/SchemaActionFlowStep';
import { closeRecordForm, initializeRecordForm } from '@redux/stores/form/actions';
import { displayMessage } from '@redux/stores/messages/reducers';
import { updateRecordInShortList } from '@redux/stores/records/actions';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import { TAppointment } from '../../../../components/BookingModal';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';

const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;
const { SERVICE_APPOINTMENT } = SchemaModuleEntityTypeEnums;
const CHANGE_REASON = 'ChangeReason';

interface Props {
  record: DbRecordEntityTransform;
  recordFormReducer: any;
  initializeForm: any;
  isDialogOpen: boolean;
  selectedAppointment: TAppointment | undefined;
  onClose?: any;
  onConfirm?: any;
  closeForm: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
  updateRecordInReducer: (params: { record: DbRecordEntityTransform }) => void;
}

const RescheduleWorkOrderFlow: FC<Props> = (props: Props) => {
  const {
    record,
    recordFormReducer,
    onClose,
    onConfirm,
    alertMessage,
    closeForm,
    isDialogOpen,
    selectedAppointment,
  } = props;

  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(true);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [flowFormData, setFlowFormData] = useState<any[]>([]);
  const [isLoadingSchemaAction, setIsLoadingSchemaAction] = useState<boolean>(false);
  const [recordSchema, setRecordSchema] = useState<SchemaEntity | undefined>(undefined);
  const [schemaActionFlow, setSchemaActionFlow] = useState<SchemaActionEntity | undefined>(
    undefined,
  );
  const [error, setError] = useState<string | undefined>(undefined);

  function resetState() {
    setIsNextDisabled(true);
    setIsConfirmLoading(false);
  }

  useEffect(() => {
    if (record) {
      setError(undefined);
      closeForm();
      resetState();
      fetchSchema();
    }
  }, [record]);

  const fetchSchema = async () => {
    const schema = await getOdinSchemaByRecord(record);
    setRecordSchema(schema);
  };

  useEffect(() => {
    if (recordSchema && !isLoadingSchemaAction && isDialogOpen) {
      fetchSchemaActionFlows();
    }
  }, [recordSchema, isDialogOpen]);

  // Fetch all schema actions and apply a rule set
  function fetchSchemaActionFlows() {
    if (record && recordSchema) {
      setIsLoadingSchemaAction(true);

      httpGet(`SchemaModule/v1.0/schemas-actions`)
        .then((res) => {
          setIsLoadingSchemaAction(false);

          let filteredSchemaActionFlows: SchemaActionEntity[] = res.data?.data || [];

          const schemaTypeId =
            recordSchema?.types?.find((type: SchemaTypeEntity) => type.name === record.type)?.id ||
            undefined;

          if (filteredSchemaActionFlows.length > 0) {
            filteredSchemaActionFlows = filteredSchemaActionFlows.filter(
              (action: SchemaActionEntity) =>
                action.name.toLowerCase().indexOf('reschedule') > -1 &&
                action.schemaTypeId === schemaTypeId &&
                action.isStepFlow === true,
            );

            if (filteredSchemaActionFlows.length > 0) {
              setSchemaActionFlow(filteredSchemaActionFlows[0]);
            } else {
              setError(`No reschedule form found for ${record.type} record type.`);
            }
          }
        })
        .catch((err) => {
          console.error('Error loading table data:', err);
        });
    }
  }

  function setupStep(newStep: any) {
    if (newStep === 0) {
      setFlowFormData([]);
      closeForm();
    } else if (newStep > 0 && newStep <= schemaActionFlow?.definition?.dialogSteps?.length) {
      let newFlowFormData: any[] = flowFormData.slice(0, Number(newStep) - 1);
      newFlowFormData.push(recordFormReducer);
      setFlowFormData(newFlowFormData);
      closeForm();
    }
  }

  // Debug
  // useEffect(() => {
  //   console.log('%cdebug: flowData updated', 'color:royalblue', flowFormData);
  // }, [flowFormData]);

  // Some schema action flows can pass custom URLs to be executed after the Flow is completed. We pass the creates and updates to the custom URL endpoint.
  const handleCustomURLs = async (payload: any) => {
    const onSubmitUrl = schemaActionFlow?.definition?.onSubmitUrl;

    if (schemaActionFlow && onSubmitUrl) {
      try {
        let URL = onSubmitUrl.url;

        // Replace source record id if asked in schema configuration
        if (URL && URL.includes('{source_record_id}')) {
          URL = URL.replace('{source_record_id}', record.id);
        }
        if (onSubmitUrl.method === 'post') {
          const res = await httpPost(URL, payload);
          if (res) {
            alertMessage({
              body: 'Work Order rescheduled successfully.',
              type: 'success',
            });
          }
        } else if (onSubmitUrl.method === 'put') {
          await httpPut(URL, payload);
        } else if (onSubmitUrl.method === 'delete') {
          await httpDelete(URL);
        } else {
          await httpPost(URL, payload);
        }
      } catch (err: any) {
        alertMessage({
          body: 'Could not reschedule Work Order. ' + err?.message,
          type: 'error',
        });
        return true;
      }
    }
  };

  async function handleFinalStepSubmit() {
    setIsConfirmLoading(true);
    const onSubmitUrl = schemaActionFlow?.definition?.onSubmitUrl;

    try {
      if (schemaActionFlow) {
        // Extract data from create steps and pass it to the upsert endpoint
        let createSteps = flowFormData.filter(
          (formReducerSnapshot: any) => formReducerSnapshot.isCreateReq,
        );
        if (onSubmitUrl) {
          let payload = {
            Appointment: {
              entity: `${FIELD_SERVICE_MODULE}:${SERVICE_APPOINTMENT}`,
              properties: {
                TimeBlock: selectedAppointment?.AM ? 'AM' : 'PM',
                Date: selectedAppointment?.Date || null,
                ScheduleId: selectedAppointment?.Config?.id || null,
              },
              actionId: schemaActionFlow?.id,
            },
            ChangeReason: {
              entity: `${FIELD_SERVICE_MODULE}:${CHANGE_REASON}`,
              properties: {
                ...createSteps[0]?.modified[0]?.properties,
                actionId: schemaActionFlow?.id,
              },
            },
          };
          await handleCustomURLs(payload);
        }
      }

      resetState();
      setIsConfirmLoading(false);
      setError(undefined);

      if (onConfirm) {
        onConfirm();
      }
    } catch (err: any) {
      setIsConfirmLoading(false);
      console.log('debug: error', err);
      alertMessage({
        body: err?.response?.data?.message,
        type: 'error',
      });
    }
  }

  // Render each step as a DialogStep
  const renderSchemaActionSteps = () => {
    const steps = schemaActionFlow?.definition?.dialogSteps || [];
    if (steps.length > 0) {
      return steps.map((step: any, index: number) => {
        return (
          <DialogStep
            title={step.name}
            id={index}
            key={index}
            panel={
              <Section style={{ overflowY: 'auto' }}>
                <SectionCard>
                  <SchemaActionFlowStep
                    step={step}
                    sourceRecord={record}
                    isNextDisabled={(isNextDisabled: boolean) => {
                      setIsNextDisabled(isNextDisabled);
                    }}
                    key={index}
                  />
                </SectionCard>
              </Section>
            }
          />
        );
      });
    } else {
      return <Callout intent="danger">No schema action flow steps defined.</Callout>;
    }
  };

  return (
    <>
      <MultistepDialog
        isOpen={isDialogOpen}
        canOutsideClickClose={false}
        showCloseButtonInFooter={true}
        icon="flow-linear"
        navigationPosition="top"
        initialStepIndex={0}
        onClose={() => {
          onClose && onClose();
          resetState();
          setError(undefined);
          setFlowFormData([]);
        }}
        usePortal={true}
        onChange={(newStep: string) => {
          setupStep(newStep);
          setIsNextDisabled(true);
        }}
        nextButtonProps={{
          disabled: isNextDisabled,
        }}
        finalButtonProps={{
          disabled: isConfirmLoading || !!error,
          onClick: () => handleFinalStepSubmit(),
          text: 'Finish',
        }}
        title={'Reschedule Work Order'}
        lazy
      >
        {/* Render dynamic schema action steps */}
        {renderSchemaActionSteps()}

        {
          // Error handling
          error && (
            <DialogStep
              title="Error"
              id={schemaActionFlow?.definition?.dialogSteps?.length}
              key="error"
              panel={
                <Section style={{ overflowY: 'auto' }}>
                  <SectionCard>
                    <Callout intent="danger" icon={null}>
                      <span>{error}</span>
                    </Callout>
                  </SectionCard>
                </Section>
              }
            />
          )
        }

        {/* Confirmation step */}
        {!error && (
          <DialogStep
            title="Confirmation"
            id={schemaActionFlow?.definition?.dialogSteps?.length}
            key="confirmation"
            panel={
              <Section style={{ overflowY: 'auto' }}>
                <SectionCard>
                  <Row>
                    <Col span={24}>
                      <Callout intent="primary" title="New Appointment">
                        <Row>
                          <Col span={24}>
                            <span>
                              {`${dayjs(selectedAppointment?.Date).format('MMMM D, YYYY')} (${
                                selectedAppointment?.AM ? 'AM' : 'PM'
                              })`}
                            </span>
                          </Col>
                        </Row>
                      </Callout>
                    </Col>
                    <Col span={24} style={{ marginTop: 15 }}>
                      <Callout icon={null}>
                        <span>
                          <span>Please confirm that you want to finish the flow.</span>
                        </span>
                      </Callout>
                    </Col>
                  </Row>
                </SectionCard>
              </Section>
            }
          />
        )}
      </MultistepDialog>
    </>
  );
};

const mapState = (state: any) => ({
  recordFormReducer: state.recordFormReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  updateRecordInReducer: (params: { record: DbRecordEntityTransform }) =>
    dispatch(updateRecordInShortList(params)),
  closeForm: () => dispatch(closeRecordForm()),
});

export default connect(mapState, mapDispatch)(RescheduleWorkOrderFlow);
