import { Button, Drawer, Icon, NumericInput } from '@blueprintjs/core';
import RecordCard from '@core/components/RecordCard';
import { getSchemaFromShortListByModuleAndEntity } from '@core/helpers/schemaHelpers';
import { httpPost } from '@core/http/requests';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { displayMessage } from '@redux/stores/messages/reducers';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
  updateOrCreateRecordAssociations,
} from '@redux/stores/recordAssociations/actions';
import { ISearchRecords, searchRecordsRequest } from '@redux/stores/records/actions';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '@redux/stores/schemas/actions';
import { closeAddProductDrawer } from '@redux/stores/userInterface/actions';
import { IUserInterfaceReducer } from '@redux/stores/userInterface/types';
import { Badge, Col, Empty, Row, Select, Tabs, Tag } from 'antd';
import { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import './styles.scss';

interface Props {
  userInterfaceReducer: IUserInterfaceReducer;
  schemaReducer: any;
  getSchema: Function;
  getAssociations: Function;
  closeDrawer: Function;
  searchRecords: Function;
  alertMessage: Function;
  createAssociations: Function;
  onSuccess?: () => void;
}

const { PRODUCT_MODULE } = SchemaModuleTypeEnums;
const { OFFER, PRODUCT } = SchemaModuleEntityTypeEnums;

const AddProductDrawer: FC<Props> = (props: Props) => {
  const {
    schemaReducer,
    getSchema,
    userInterfaceReducer,
    getAssociations,
    closeDrawer,
    searchRecords,
    alertMessage,
    onSuccess,
  } = props;

  const { addProductDrawerVisible, parentRecord } = userInterfaceReducer?.addProduct;

  const [parentSchema, setParentSchema] = useState<SchemaEntity | undefined>(undefined);
  const [offerSchema, setOfferSchema] = useState<SchemaEntity | undefined>(undefined);
  const [productSchema, setProductSchema] = useState<SchemaEntity | undefined>(undefined);
  const [offerList, setOfferList] = useState<DbRecordEntityTransform[]>([]);
  const [productList, setProductList] = useState<DbRecordEntityTransform[]>([]);
  const [selectedOffer, setSelectedOffer] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [isAddingProduct, setIsAddingProduct] = useState<boolean>(false);
  const [isLoadingOffers, setIsLoadingOffers] = useState<boolean>(false);
  const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );

  // When both parent and order item records are available, fetch schemas. Wait for when
  // drawer is visible so we don't overkill the API requests in the UI.
  useEffect(() => {
    if (parentRecord && addProductDrawerVisible && !parentSchema) {
      getSchemas();
    }
  }, [addProductDrawerVisible, schemaReducer.list]);

  // When offer is selected, fetch the list of offer products.
  useEffect(() => {
    if (selectedOffer && offerSchema) {
      setIsLoadingProducts(true);
      getAssociations(
        {
          recordId: selectedOffer?.id,
          key: OFFER,
          schema: offerSchema,
          entities: [PRODUCT],
        },
        (res: any) => {
          if (res && res.results?.[PRODUCT]?.dbRecords?.length > 0) {
            const products = res.results?.[PRODUCT]?.dbRecords;
            setProductList(products);
          }
          setIsLoadingProducts(false);
        },
      );
    }
  }, [selectedOffer, offerSchema]);

  // When customer type and offer schema are available, fetch the list of offers.
  useEffect(() => {
    if (offerSchema) {
      setIsLoadingOffers(true);
      searchRecords(
        {
          schema: offerSchema,
          searchQuery: {
            terms: '*',
            schemas: offerSchema?.id,
            sort: [
              {
                createdAt: {
                  order: 'desc',
                },
              },
            ],
            pageable: {
              page: 1,
              size: 200,
            },
          },
        },
        (res: any) => {
          if (res.data?.data) {
            setOfferList(res.data.data);
          }
          setIsLoadingOffers(false);
        },
      );
    }
  }, [offerSchema]);

  const getSchemas = async () => {
    try {
      const parentModule = parentRecord?.entity?.split(':')[0];
      const parentEntity = parentRecord?.entity?.split(':')[1];
      const shortlistParentRecordSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        parentModule,
        parentEntity,
      );

      if (shortlistParentRecordSchema) {
        setParentSchema(shortlistParentRecordSchema);
      } else {
        getSchema(
          { moduleName: parentModule, entityName: parentEntity },
          (responseSchema: SchemaEntity) => {
            if (responseSchema) {
              setParentSchema(responseSchema);
            }
          },
        );
      }

      // Get Offer Schema
      const shortlistOfferSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        PRODUCT_MODULE,
        OFFER,
      );
      if (shortlistOfferSchema) {
        setOfferSchema(shortlistOfferSchema);
      } else {
        getSchema(
          { moduleName: PRODUCT_MODULE, entityName: OFFER },
          (responseSchema: SchemaEntity) => {
            if (responseSchema) {
              setOfferSchema(responseSchema);
            }
          },
        );
      }

      // Get product Schema
      const shortlistProductSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        PRODUCT_MODULE,
        PRODUCT,
      );
      if (shortlistProductSchema) {
        setProductSchema(shortlistProductSchema);
      } else {
        getSchema(
          { moduleName: PRODUCT_MODULE, entityName: PRODUCT },
          (responseSchema: SchemaEntity) => {
            if (responseSchema) {
              setProductSchema(responseSchema);
            }
          },
        );
      }
    } catch (error) {}
  };

  const handleOfferSelection = (selectedOfferId: string) => {
    const selected = offerList.find(
      (offer: DbRecordEntityTransform) => offer.id === selectedOfferId,
    );
    if (selected) {
      setSelectedOffer(selected);
    }
  };

  const getProductsByCategory = (
    category: 'BASE_PRODUCT' | 'ADD_ON_PRODUCT',
    products: DbRecordEntityTransform[],
  ) => {
    return products?.filter(
      (offerProduct: DbRecordEntityTransform) => getProperty(offerProduct, 'Type') === category,
    );
  };

  const addProductToParentRecord = (product: DbRecordEntityTransform) => {
    setIsAddingProduct(true);

    let addProductsRequestBody: any[] = [];
    let path: string = '';

    // Order handling
    if (parentSchema?.entityName === 'Order') {
      path = `OrderModule/v1.0/orders/${parentRecord?.id}/items`;
    }
    // Invoice handling
    else if (parentSchema?.entityName === 'Invoice') {
      path = `BillingModule/v1.0/invoices/${parentRecord?.id}/items`;
    }

    addProductsRequestBody.push({
      entity: product.entity,
      recordId: product.id,
      relatedAssociationId: product?.dbRecordAssociation?.relatedAssociationId,
      additionalParams: { offerId: selectedOffer?.id },
      properties: {
        Quantity: selectedProduct?.properties?.Quantity || 1,
      },
    });

    httpPost(path, addProductsRequestBody)
      .then(() => {
        onDrawerClose();
        alertMessage({
          body: 'Product added successfully!',
          type: 'success',
        });
        setIsAddingProduct(false);
        onSuccess && onSuccess();
      })
      .catch((err: any) => {
        alertMessage({
          body: 'There was a problem adding the product.',
          type: 'error',
        });
        setIsAddingProduct(false);
      });
  };

  const listProductsByCategory = (
    category: 'BASE_PRODUCT' | 'ADD_ON_PRODUCT',
    products: DbRecordEntityTransform[],
  ) => {
    const filteredProducts = getProductsByCategory(category, products);

    if (filteredProducts?.length > 0) {
      return filteredProducts?.map((product: DbRecordEntityTransform) => (
        <div
          style={{
            marginBottom: 10,
          }}
          key={product.id}
        >
          <RecordCard
            openTitleLinkInNewTab
            propertyColumns={2}
            record={product}
            visibleProperties={[
              'Type',
              'Category',
              'UnitPrice',
              'DiscountType',
              'DiscountValue',
              'TrialUnit',
              'TrialLength',
            ]}
            headerElement={
              <Button
                intent="primary"
                disabled={isLoadingOffers || isLoadingProducts}
                onClick={() => {
                  setSelectedProduct({
                    ...product,
                    properties: {
                      ...product.properties,
                      Quantity: 1,
                    },
                  });
                }}
              >
                Select
              </Button>
            }
          />
        </div>
      ));
    } else {
      return (
        <Row>
          <Col span={24}>
            <Empty
              style={{ padding: '50px 0' }}
              description={
                <Row style={{ marginTop: 20 }}>
                  <Col span={24}>
                    <span>
                      There are no{' '}
                      {category === 'BASE_PRODUCT' ? 'Base Products' : 'Add-on Products'}
                    </span>
                  </Col>
                  <Col span={24}>
                    <span>in the selected offer.</span>
                  </Col>
                </Row>
              }
            />
          </Col>
        </Row>
      );
    }
  };

  const renderProductList = () => {
    return (
      <Row
        style={{
          width: '100%',
          marginTop: 10,
          opacity: isLoadingOffers || isLoadingProducts ? 0.3 : 1,
        }}
      >
        {/* product type filter */}
        <Col
          span={24}
          style={{ height: 'calc(100vh - 110px)', overflowY: 'auto', padding: '0 5px' }}
        >
          <Tabs centered>
            <Tabs.TabPane
              tab={
                <>
                  <Badge
                    style={{ backgroundColor: '#f0f0f0', color: 'black', marginBottom: 2 }}
                    count={getProductsByCategory('BASE_PRODUCT', productList).length}
                  />
                  <span style={{ marginLeft: 5, paddingTop: 2 }}>Base Products</span>
                </>
              }
              key="base-products"
              forceRender
            >
              {listProductsByCategory('BASE_PRODUCT', productList)}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <>
                  <Badge
                    style={{ backgroundColor: '#f0f0f0', color: 'black', marginBottom: 2 }}
                    count={getProductsByCategory('ADD_ON_PRODUCT', productList).length}
                  />
                  <span style={{ marginLeft: 5 }}>Add-on Products</span>
                </>
              }
              key="add-on-products"
              forceRender
            >
              {listProductsByCategory('ADD_ON_PRODUCT', productList)}
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    );
  };

  const onDrawerClose = () => {
    closeDrawer();
    setOfferList([]);
    setProductList([]);
    setSelectedProduct(undefined);
    setOfferSchema(undefined);
    setParentSchema(undefined);
    setSelectedOffer(undefined);
  };

  // This will show when the user selected a product.
  const renderSelectedProductView = () => {
    return (
      <Row>
        {/* Selected product */}
        <Col span={24}>
          <RecordCard
            key={selectedProduct?.id}
            headerElement={
              <>
                <span>Quantity</span>
                <NumericInput
                  min={1}
                  max={10}
                  defaultValue={1}
                  style={{
                    maxWidth: 70,
                  }}
                  onValueChange={(value: any) => {
                    if (selectedProduct) {
                      setSelectedProduct({
                        ...selectedProduct,
                        properties: { ...selectedProduct.properties, Quantity: value },
                      });
                    }
                  }}
                />
              </>
            }
            openTitleLinkInNewTab
            propertyColumns={2}
            record={selectedProduct!}
            visibleProperties={[
              'Type',
              'Category',
              'UnitPrice',
              'DiscountType',
              'DiscountValue',
              'TrialUnit',
              'TrialLength',
            ]}
          />
        </Col>
        {/* Replace Arrows */}
        <Col span={24} style={{ textAlign: 'center', marginBottom: 15, marginTop: 10 }}>
          <Icon icon="double-chevron-down" intent="primary" size={22} />
        </Col>

        {/* Parent record to be replaced */}
        <Col span={24}>
          {
            <RecordCard
              openTitleLinkInNewTab
              propertyColumns={2}
              record={parentRecord!}
              visibleProperties={
                parentRecord?.entity === 'OrderModule:Order'
                  ? [
                      'Quantity',
                      'UnitPrice',
                      'TotalDiscounts',
                      'TotalPrice',
                      'BillingStartDate',
                      'NextInvoiceDate',
                      'NextBillingDate',
                      'BillingPeriodType',
                    ]
                  : ['Balance', 'DueDate', 'Status', 'TotalDue', 'BillingTerms', 'SubTotal']
              }
            />
          }
        </Col>

        {/* Cancel Button */}
        <Col span={24} style={{ textAlign: 'right', marginTop: 20 }}>
          <Button
            outlined
            style={{ marginRight: isMobile ? 0 : 12 }}
            onClick={() => setSelectedProduct(undefined)}
            disabled={isAddingProduct}
          >
            Cancel
          </Button>

          {/* Line Break for Mobile Viewport */}
          {isMobile ? <br /> : <></>}

          {/* Replace Button */}
          <Button
            style={{ marginTop: isMobile ? 10 : 0 }}
            intent="primary"
            loading={isAddingProduct}
            disabled={isAddingProduct}
            onClick={() => addProductToParentRecord(selectedProduct!)}
          >
            Add Product
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <Drawer
      style={{ width: isMobile ? '100%' : 600 }}
      isOpen={addProductDrawerVisible}
      title={`Add Product to ${parentRecord ? parentRecord!.entity?.split(':')[1] : ''}`}
      onClose={() => onDrawerClose()}
    >
      <Row style={{ padding: 20 }}>
        <Col span={24} style={{ display: selectedProduct ? 'none' : 'block' }}>
          <Select
            className="offerSelectInput"
            size="large"
            labelRender={(label: any) => <span style={{ fontSize: '0.9em' }}>{label?.title}</span>}
            placeholder={isLoadingOffers ? 'Loading offers...' : 'Select Offer'}
            loading={isLoadingOffers}
            disabled={isLoadingOffers || offerList?.length === 0 || !offerSchema}
            defaultActiveFirstOption={false}
            value={selectedOffer?.id}
            onChange={handleOfferSelection}
            notFoundContent={null}
            showSearch
            style={{ width: '100%' }}
            filterOption={(input: string, option: any) =>
              option?.label?.toLowerCase().indexOf(input.toLowerCase()) > -1
            }
          >
            {offerList?.map((offer: DbRecordEntityTransform) => (
              <Select.Option
                className="offerSelectInputItem"
                label={offer?.title}
                title={offer?.title}
                value={offer?.id}
                key={`offer-${offer?.id}`}
              >
                <Row>
                  <Col span={24} style={{ fontWeight: 500 }}>
                    {offer?.title}
                  </Col>
                  <Col span={24} style={{ color: '#b0b0b0', fontSize: '0.8em' }}>
                    <span>
                      {/* Contract Type */}
                      {getProperty(offer, 'ContractType') ? (
                        <Tag
                          style={{
                            fontSize: '0.8em',
                            padding: '0px 2px',
                            lineHeight: '15px',
                            borderRadius: 2,
                          }}
                        >
                          {getProperty(offer, 'ContractType') || '-'}
                        </Tag>
                      ) : (
                        <></>
                      )}
                      {/* Customer Type */}
                      {getProperty(offer, 'CustomerType') ? (
                        <Tag
                          style={{
                            fontSize: '0.8em',
                            padding: '0px 2px',
                            lineHeight: '15px',
                            borderRadius: 2,
                          }}
                        >
                          {getProperty(offer, 'CustomerType') || '-'}
                        </Tag>
                      ) : (
                        <></>
                      )}
                      {/* From, To, Code */}
                      From: {getProperty(offer, 'AvailableFrom')}, To:{' '}
                      {getProperty(offer, 'AvailableTo') || '-'}, Code:{' '}
                      {getProperty(offer, 'Code') || '-'}
                    </span>
                  </Col>
                </Row>
              </Select.Option>
            ))}
          </Select>
        </Col>
        {!selectedOffer && (
          <Col span={24} style={{ marginTop: 40, textAlign: 'center' }}>
            <Empty
              description={
                <div style={{ marginTop: 20 }}>
                  <span>Please select an offer in the input above</span>
                  <br />
                  <span>in order to see the products.</span>
                </div>
              }
            />
          </Col>
        )}
        {/* Products */}
        {productList.length > 0 && !selectedProduct ? renderProductList() : <></>}
        {selectedProduct ? renderSelectedProductView() : <></>}
      </Row>
    </Drawer>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userInterfaceReducer: state.userInterfaceReducer,
});

const mapDispatch = (dispatch: any) => ({
  createAssociations: (params: any, cb: () => {}) =>
    dispatch(updateOrCreateRecordAssociations(params, cb)),
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
  closeDrawer: () => dispatch(closeAddProductDrawer()),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(AddProductDrawer);
