import { Alert, Button } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { httpPost } from '@core/http/requests';
import { displayMessage } from '../../../../../redux/stores/messages/reducers';

interface Props {
  record: DbRecordEntityTransform;
  alertMessage: (params: { body: string; type: string }) => void;
}

const { BILLING_MODULE } = SchemaModuleTypeEnums;

const CreditNoteSendConfirmation: React.FC<Props> = (props: Props) => {
  const { record, alertMessage } = props;
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [isSendingConfirmation, setIsSendingConfirmation] = useState<boolean>(false);

  const sendConfirmation = () => {
    setIsSendingConfirmation(true);

    httpPost(`${BILLING_MODULE}/v1.0/credit-notes/${record.id}/email/SENDGRID_CREDIT_NOTE_NEW`, {})
      .then(() => {
        setIsSendingConfirmation(false);
        setIsAlertVisible(false);
        alertMessage({
          body: 'Confirmation email sent successfully',
          type: 'success',
        });
      })
      .catch((error: any) => {
        setIsSendingConfirmation(false);
        setIsAlertVisible(false);
        alertMessage({
          body:
            error.response?.data?.message || 'There was an error sending the confirmation email',
          type: 'error',
        });
      });
  };

  return (
    <>
      <Button
        intent="primary"
        outlined
        text="Send Confirmation"
        onClick={() => setIsAlertVisible(true)}
      />
      <Alert
        intent="primary"
        onCancel={() => setIsAlertVisible(false)}
        isOpen={isAlertVisible}
        cancelButtonText="Cancel"
        onConfirm={sendConfirmation}
        loading={isSendingConfirmation}
      >
        <p>Are you sure you want to send the Credit Note confirmation?</p>
      </Alert>
    </>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(CreditNoteSendConfirmation);
