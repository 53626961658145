import { GanttStatic, NewTask } from '@d19n/dhtmlx-gantt-pro';

export const SET_GANTT_INSTANCE = 'SET_GANTT_INSTANCE';
export const NEW_GANTT_TASK = 'NEW_GANTT_TASK';
export const SET_GANTT_USERS = 'SET_GANTT_USERS';

export interface GanttReducer {
  ganttInstance: GanttStatic | null;
}

export const initialState: GanttReducer = {
  ganttInstance: null,
};

export function ganttReducer(
  state: GanttReducer = initialState,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case SET_GANTT_INSTANCE:
      return { ...state, ganttInstance: action.payload };
    case NEW_GANTT_TASK:
      if (state.ganttInstance) {
        const task: NewTask = {
          $new: true,
          type: 'task',
          text: '',
          owner_id: undefined,
          description: '',
          start_date: undefined,
          end_date: undefined,
        };

        state.ganttInstance.createTask(task);
      }
      return state;
    case SET_GANTT_USERS:
      return { ...state, users: action.payload };
    default:
      return state;
  }
}
