import { CaretDownOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Tooltip } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { httpDelete, httpGet } from '@core/http/requests';
import { displayMessage } from '@redux/stores/messages/reducers';
import history from '@core/helpers/browserHistory';
import { getSchemaFromShortListByModuleAndEntity } from '@core/helpers/schemaHelpers';
import {
  generateFilterKey,
  generateModuleAndEntityKeyFromProps,
  getSavedFilter,
} from '@core/helpers/searchHelpers';
import {
  ISearchRecords,
  searchRecordsRequest,
  setDbRecordSearchQuery,
} from '@redux/stores/records/actions';
import { IRecordReducer } from '@redux/stores/records/reducer';
import { setQueryBuilderState } from '../DynamicTable/QueryBuilder/store/actions';
import { IQueryBuilderByModuleAndEntityReducer } from '../DynamicTable/QueryBuilder/store/reducer';
import { saveTableFilters, setTableColumns } from '@redux/stores/table/actions';

interface Props {
  moduleName: string;
  entityName: string;
  schemaReducer: any;
  recordReducer: IRecordReducer;
  recordTableReducer: any;
  initQueryBuilder: (params: any) => {};
  initSearchQuery: any;
  initTableColumns: any;
  updateTableListView: any;
  searchRecords: any;
  alertMessage: any;
}

interface State {
  isLoading: boolean;
  views: any[];
  selected: any;
}

class ViewManager extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      views: [],
      selected: undefined,
    };
  }

  componentDidMount() {
    this.initializeListView();
    this.loadLists();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.entityName !== this.props.entityName) {
      // this.loadLists();
      // this.resetState();
    }

    if (prevState.selected && prevProps.entityName !== this.props.entityName) {
      this.setState({
        ...this.state,
        selected: undefined,
      });
    }

    if (prevState.views !== this.state.views) {
      this.initializeListView();
    }

    if (prevState.selected !== this.state.selected) {
      this.applyView();
    }
  }

  resetState() {
    const urlNoHash = window.location.href.substr(0, window.location.href.indexOf('#'));

    window.location.href = urlNoHash;

    this.setState({
      isLoading: false,
      views: [],
      selected: undefined,
    });
  }

  private async initializeListView() {
    const { views } = this.state;
    if (history.location.hash) {
      const viewKey = history.location.hash.replace('#View_', '');
      await this.getViewByKey(viewKey);
    } else {
      this.applyView();
    }
  }

  async getViewByKey(viewKey: string) {
    const { moduleName } = this.props;
    this.setState({
      isLoading: true,
    });
    await httpGet(`${moduleName}/v1.0/views/byKey/${viewKey}`)
      .then((res) => {
        this.setState({
          isLoading: false,
          selected: res.data.data,
        });

        this.applyView();
      })
      .catch((err) => {
        const error = err.response ? err.response.data : undefined;

        this.setState({
          isLoading: false,
          selected: undefined,
        });

        console.error(error);
      });
  }

  async loadLists() {
    const { moduleName, entityName } = this.props;

    this.setState({
      isLoading: true,
    });

    await httpGet(`${moduleName}/v1.0/views/byModule/${moduleName}/${entityName}`)
      .then((res) => {
        this.setState({
          isLoading: false,
          views: res.data.data,
        });
      })
      .catch((err) => {
        const error = err.response ? err.response.data : undefined;
        this.setState({
          isLoading: false,
          views: [],
        });
        console.error(error);
      });
  }

  async deleteList(viewId: string) {
    const { alertMessage, moduleName } = this.props;
    await httpDelete(`${moduleName}/v1.0/views/${viewId}`)
      .then((res) => {
        alertMessage({ body: 'view successfully deleted', type: 'success' });
      })
      .catch((err) => {
        const error = err.response ? err.response.data : undefined;
        alertMessage({
          body: error?.message || 'error deleting view',
          type: 'error',
        });
      });
  }

  applyView() {
    const {
      moduleName,
      entityName,
      schemaReducer,
      recordTableReducer,
      updateTableListView,
      initQueryBuilder,
      initTableColumns,
    } = this.props;
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );

    if (schema && this.state.selected) {
      const name = generateFilterKey(schema.moduleName, schema.entityName);

      updateTableListView(name, {
        search: this.state.selected.view.search[schema.id],
        columns: this.state.selected.view.columns,
        queryBuilder: this.state.selected.view.queryBuilder,
      });
      // set the query builder reducer state
      initQueryBuilder(this.state.selected.view.queryBuilder);
      // set the table columns
      initTableColumns(this.state.selected.view.columns);
    } else if (schema) {
      // set the query builder reducer state
      const queryBuilder = getSavedFilter(
        schemaReducer,
        recordTableReducer,
        moduleName,
        entityName,
      );
      initQueryBuilder(queryBuilder);
    }
  }

  copyValueToClipboard = (value: any, message: string) => {
    const { alertMessage } = this.props;
    navigator.clipboard.writeText(value);
    alertMessage({ body: message, type: 'success' });
  };

  render() {
    return (
      <Dropdown
        trigger={['click']}
        onVisibleChange={(e) => this.loadLists()}
        overlay={
          <Menu>
            {this.state.views &&
              this.state.views.map((elem) => (
                <Menu.Item>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span
                      onClick={() => {
                        this.setState({ selected: elem });
                        history.push(`#View_${elem.key}`);
                      }}
                    >
                      {elem?.title}
                    </span>
                    <Tooltip title="Copy URL to Clipboard">
                      <Button
                        onClick={() => {
                          this.copyValueToClipboard(
                            `${window.location.href.split('#')[0]}#View_${elem.key}`,
                            'Copied View URL to Clipboard',
                          );
                        }}
                        style={{ marginLeft: 8 }}
                        type="primary"
                        size="small"
                        shape="circle"
                        ghost
                        icon={<CopyOutlined />}
                      />
                    </Tooltip>
                    <Tooltip title="Delete">
                      <Button
                        onClick={() => {
                          this.deleteList(elem.id);
                        }}
                        style={{ marginLeft: 8 }}
                        type="primary"
                        size="small"
                        shape="circle"
                        ghost
                        danger
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </div>
                </Menu.Item>
              ))}
          </Menu>
        }
      >
        {this.state.selected ? (
          <a style={{ fontSize: 13, marginLeft: 5 }}>
            {this.state.selected.title} <CaretDownOutlined style={{ fontSize: '0.9em' }} />
          </a>
        ) : (
          <a style={{ fontSize: 13, marginLeft: 5 }} className="ant-dropdown-link">
            List Views <CaretDownOutlined style={{ fontSize: '0.9em' }} />
          </a>
        )}
      </Dropdown>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
  recordTableReducer: state.recordTableReducer,
});

const mapDispatch = (dispatch: any, ownProps: any) => ({
  updateTableListView: (name: string, params: any) => dispatch(saveTableFilters(name, params)),
  initSearchQuery: (params: ISearchRecords) => dispatch(setDbRecordSearchQuery(params)),
  initQueryBuilder: (params: IQueryBuilderByModuleAndEntityReducer) =>
    dispatch(setQueryBuilderState(generateModuleAndEntityKeyFromProps(ownProps), params)),
  initTableColumns: (columns: any) => dispatch(setTableColumns(columns)),
  searchRecords: (params: ISearchRecords) => dispatch(searchRecordsRequest(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(ViewManager);
