import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Alert, Button } from '@blueprintjs/core';
import CoreForm from '@core/components/Forms/CoreForm';
import { MyCasesContext } from '../../../../index';
import { FormReducerSubmitEvt } from '@core/components/Forms/SharedForm/SharedFormModal';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import {
  deleteRecordAssociationById,
  IDeleteRecordAssociation,
} from '../../../../../../../../../redux/stores/recordAssociations/actions';
import { MY_CASES_SET_CONTACT, MY_CASES_UPDATE_CASE } from '../../../../store/constants';
import { IUpdateRecordById, updateRecordByIdRequest } from '../../../../../../../../../redux/stores/records/actions';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

interface Props {
  deleteAssociation: (params: IDeleteRecordAssociation, cb: any) => void;
  updateRecord: (params: IUpdateRecordById, cb: any) => void;
  userReducer: any;
}

const MyCasesUnlinkContactButton: React.FC<Props> = (props: Props) => {
  const { deleteAssociation, updateRecord, userReducer } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const [contactSchema, setContactSchema] = useState<SchemaEntity | undefined>(undefined);
  const [caseSchema, setCaseSchema] = useState<SchemaEntity | undefined>(undefined);
  const [isUnlinkUserAlertVisible, setIsUnlinkUserAlertVisible] = useState<boolean>(false);
  const [isUnlinkingContactFromCase, setIsUnlinkingContactFromCase] = useState<boolean>(false);

  useEffect(() => {
    getSchemas();
  }, []);

  const isCaseOwn = getProperty(state.selectedCase, 'OwnerId') === userReducer.user.id;

  const getSchemas = async () => {
    try {
      const CTSchema = await getOdinSchemaByEntity('CrmModule', 'Contact');
      if (CTSchema) {
        setContactSchema(CTSchema);
      }

      const CSSchema = await getOdinSchemaByEntity('SupportModule', 'Case');
      if (CSSchema) {
        setCaseSchema(CSSchema);
      }
    } catch (e: any) {}
  };

  const unlinkContactFromCase = async () => {
    if (contactSchema && caseSchema && state.selectedCase && state.selectedCaseContact) {
      setIsUnlinkingContactFromCase(true);
      try {
        const schemaAssociation = caseSchema?.associations.find(
          (assoc: any) =>
            assoc.childSchemaId === contactSchema?.id || assoc.parentSchemaId === contactSchema.id,
        );

        if (schemaAssociation) {
          // Delete association between Contact and Case records
          deleteAssociation(
            {
              schema: caseSchema,
              schemaAssociation: schemaAssociation,
              dbRecordAssociationId: state.selectedCaseContact?.dbRecordAssociation?.id || '',
            },
            () => {
              // Update ContactId property on the record to null
              updateRecord(
                {
                  schema: caseSchema,
                  recordId: state.selectedCase?.id!,
                  createUpdate: {
                    id: state.selectedCase?.id,
                    entity: state.selectedCase?.entity,
                    type: state.selectedCase?.type,
                    properties: {
                      ContactId: null,
                    },
                  },
                },
                (res: any) => {
                  dispatch({ type: MY_CASES_UPDATE_CASE, payload: res });
                },
              );

              dispatch({ type: MY_CASES_SET_CONTACT, payload: undefined });
              setIsUnlinkingContactFromCase(false);
            },
          );
        }
      } catch (e: any) {
        setIsUnlinkingContactFromCase(false);
      }
    }
  };

  return contactSchema ? (
    <>
      {/* Unlink User Alert */}
      <Alert
        intent="danger"
        onCancel={() => setIsUnlinkUserAlertVisible(false)}
        isOpen={isUnlinkUserAlertVisible}
        cancelButtonText="Cancel"
        confirmButtonText="Unlink"
        onConfirm={unlinkContactFromCase}
        loading={isUnlinkingContactFromCase}
      >
        <p>
          Are you sure you want to unlink {state.selectedCaseContact?.title || 'this user'} from
          this Case? This will not delete the contact, it will only remove the link between the two.
        </p>
      </Alert>
      <CoreForm
        type="MODAL"
        formUUID={contactSchema!.id}
        onSubmitEvent={(params: FormReducerSubmitEvt) => () => {}}
      />
      <Button
        icon={<i className="bi bi-trash3" />}
        small
        disabled={!isCaseOwn}
        intent="danger"
        minimal
        onClick={() => setIsUnlinkUserAlertVisible(true)}
      />
    </>
  ) : (
    <></>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});
const mapDispatch = (dispatch: any) => ({
  deleteAssociation: (params: IDeleteRecordAssociation, cb: any) =>
    dispatch(deleteRecordAssociationById(params, cb)),
  updateRecord: (params: IUpdateRecordById, cb: any) =>
    dispatch(updateRecordByIdRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(MyCasesUnlinkContactButton);
