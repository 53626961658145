import { httpGet } from '@core/http/requests';

// GET all view configurations
export const getViewConfigurations = async (schemaId: string): Promise<any[]> => {
  try {
    let path = 'SchemaModule/v1.0/schemas-views';
    const res = await httpGet(path);

    // Filter by schemaId
    let filteredResponse: any[] = Object.assign(res.data.data);
    if (filteredResponse.length > 0 && schemaId) {
      filteredResponse = filteredResponse.filter((item: any) => item.schemaId === schemaId);
    }

    return filteredResponse;
  } catch (error: any) {
    throw new Error(error.response?.data?.message ?? error.message);
  }
};
