import { BlueprintNavigation } from '@core/components/BlueprintPagination';
import { Col, Flex, Row } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, Text, XAxis, YAxis } from 'recharts';
import CardBase from './CardBase/CardBase';

export type DataItem = {
  name: string;
  value: number;
};
type Props = {
  title: string;
  data: DataItem[];
  orderByValue?: 'asc' | 'desc';
  pageSize?: number;
  barColorChangeConditionFn?: (item: DataItem) => string;
};
const DashboardBarChart = (props: Props) => {
  const [innerData, setInnerData] = useState(props.data);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = props.pageSize ?? 10;
  const [currentPageData, setCurrentPageData] = useState(props.data.slice(0, pageSize));

  const maxTextWidth = useMemo(
    () =>
      currentPageData.reduce((acc, cur) => {
        const value = cur.name;
        const width = measureText(value.toLocaleString());
        if (width > acc) {
          return width;
        }
        return acc;
      }, 0),
    [currentPageData],
  );

  useEffect(() => {
    if (props.orderByValue) {
      const sortedData = [...props.data].sort((a, b) => {
        if (props.orderByValue === 'asc') {
          return a.value - b.value;
        } else {
          return b.value - a.value;
        }
      });
      setInnerData(sortedData);
    } else {
      setInnerData(props.data);
    }
  }, [props.data, props.orderByValue]);

  useEffect(() => {
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    if (end > innerData.length && start > innerData.length) {
      setCurrentPage(1);
      setCurrentPageData(innerData.slice(0, pageSize));
      return;
    }
    if (start < 0) {
      setCurrentPage(1);
      setCurrentPageData(innerData.slice(0, pageSize));
      return;
    }

    setCurrentPageData(innerData.slice(start, end));
  }, [currentPage, innerData, pageSize]);

  return (
    <CardBase title={props.title}>
      <Row
        align="top"
        style={{
          height: '100%',
          borderRadius: 8,
        }}
      >
        <Col span={24} style={{ padding: 0 }}>
          <ResponsiveContainer width="100%" height={250} maxHeight={500} minWidth={300}>
            <BarChart layout="vertical" data={currentPageData} margin={{ right: 50 }}>
              <XAxis
                type="number"
                axisLine={false}
                tickLine={false}
                tick={false}
                padding={{ left: 5 }}
              />
              <YAxis
                dataKey="name"
                width={maxTextWidth + 20}
                type="category"
                axisLine={true}
                stroke="#E6E7E8"
                interval={0}
                strokeWidth={1}
                tickLine={false}
                tick={YAxisLeftTick}
              />

              <Bar dataKey="value" minPointSize={2} barSize={8}>
                {currentPageData.map((d, idx) => {
                  return (
                    <Cell
                      key={d.name + idx}
                      fill={
                        props.barColorChangeConditionFn
                          ? props.barColorChangeConditionFn(d)
                          : '#000'
                      }
                    />
                  );
                })}
                <LabelList
                  dataKey="value"
                  position="right"
                  fill="#000000"
                  formatter={(value: number) => value.toLocaleString('en-US')}
                  style={{
                    fontFamily: 'DM Sans',
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '18px',
                    letterSpacing: '0%',
                    textAlign: 'right',
                    verticalAlign: 'middle',
                  }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Col>

        {props.data.length > pageSize && (
          <Flex style={{ width: '100%' }} justify="center">
            <BlueprintNavigation
              totalCount={innerData.length}
              currentPage={currentPage}
              pageSize={pageSize}
              onPaginate={setCurrentPage}
            />
          </Flex>
        )}
      </Row>
    </CardBase>
  );
};

type CustomTickProps = {
  x: number;
  y: number;
  payload: { value: string | number };
};
const YAxisLeftTick = ({ y, x, payload: { value } }: CustomTickProps) => {
  return (
    <Text
      x={x - 5}
      y={y}
      textAnchor="end"
      verticalAnchor="middle"
      fill="#000000"
      style={{
        fontFamily: 'DM Sans',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '12px',
        letterSpacing: '0%',
        textAlign: 'right',
        verticalAlign: 'middle',
      }}
    >
      {value}
    </Text>
  );
};

export const measureText = (text: string) => {
  const ctx: any = document.createElement('canvas').getContext('2d');
  ctx.font = "12px 'DM Sans'";

  const width = ctx.measureText(text).width;
  ctx.canvas.remove();
  return width;
};

export default DashboardBarChart;
