import {
  ADD_FIELD_TO_SHARED_FORM,
  CLOSE_SHARED_FORM_MODAL,
  INITIALIZE_SHARED_FORM,
  REMOVE_FIELD_FROM_SHARED_FORM,
  UPDATE_SHARED_FORM_INPUT,
} from './constants';

// Actions
export function initializeSharedForm(params: any) {
  return {
    type: INITIALIZE_SHARED_FORM,
    params,
  };
}

export function closeSharedForm() {
  return {
    type: CLOSE_SHARED_FORM_MODAL,
  };
}

export function updateSharedFormInput(params: any) {
  return {
    type: UPDATE_SHARED_FORM_INPUT,
    params,
  };
}

export function addFieldToSharedForm(params: any) {
  return {
    type: ADD_FIELD_TO_SHARED_FORM,
    params,
  };
}

export function removeFieldFromSharedForm(params: { property: string }) {
  return {
    type: REMOVE_FIELD_FROM_SHARED_FORM,
    params,
  };
}
