import { Button, MenuItem, TagInput } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { SchemaActionDefinitionContext } from '@core/modules/ControlPanelModule/components/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration';

import { Col, Row } from 'antd';

import React, { useContext } from 'react';

type TPropertyFilter = {
  property: string;
  operator: 'NOT IN' | 'IN';
  values: string[] | number[];
};

type TProperty = {
  name: string;
  id: string;
};

interface Props {
  propertyFilter: TPropertyFilter | undefined;
  index: number;
}

const FlowBuilderPropertyFilter: React.FC<Props> = (props: Props) => {
  const { propertyFilter, index } = props;

  const { schema, schemaAction, parsedDefinition, definition, setDefinition } = useContext(
    SchemaActionDefinitionContext,
  );

  let properties: TProperty[] =
    schema?.columns.map((column) => ({
      name: column.name,
      id: column.id,
    })) || [];

  properties = properties.sort((a, b) => a.name.localeCompare(b.name));

  const handlePropertyChange = (value: string) => {
    // in parsed definition, find the property filter by index and update it

    console.log('debug: VALUE!', value);
    if (propertyFilter) {
      const newDefinition = {
        ...parsedDefinition,
        propertyFilters: parsedDefinition.propertyFilters.map(
          (filter: TPropertyFilter, i: number) => {
            if (i === index) {
              return {
                ...filter,
                property: value,
              };
            }
            return filter;
          },
        ),
      };
      setDefinition(JSON.stringify(newDefinition, null, 4));
    }
  };

  const renderSchemaActionItems: ItemRenderer<TProperty> = (
    property: { name: string; id: string },
    { handleClick, handleFocus, modifiers },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else
      return (
        <MenuItem
          active={property.name === propertyFilter?.property}
          key={property.id}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={property.name}
        />
      );
  };

  const renderOperatorSelect: ItemRenderer<'IN' | 'NOT IN'> = (
    operator: string,
    { handleClick, handleFocus, modifiers },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else
      return (
        <MenuItem
          active={operator === propertyFilter?.operator}
          key={operator}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={operator}
        />
      );
  };

  const handleValuesChange = (values: React.ReactNode[]) => {
    console.log('debug: values', values);

    if (propertyFilter) {
      const newDefinition = {
        ...parsedDefinition,
        propertyFilters: parsedDefinition.propertyFilters.map(
          (filter: TPropertyFilter, i: number) => {
            if (i === index) {
              return {
                ...filter,
                values: values.map((value) => value),
              };
            }
            return filter;
          },
        ),
      };
      setDefinition(JSON.stringify(newDefinition, null, 4));
    }
  };

  const handleOperatorChange = (value: 'IN' | 'NOT IN') => {
    const newDefinition = {
      ...parsedDefinition,
      propertyFilters: parsedDefinition.propertyFilters.map(
        (filter: TPropertyFilter, i: number) => {
          if (i === index) {
            return {
              ...filter,
              operator: value,
            };
          }
          return filter;
        },
      ),
    };
    setDefinition(JSON.stringify(newDefinition, null, 4));
  };

  const removePropertyFilterFromDefinition = () => {
    const newDefinition = {
      ...parsedDefinition,
      propertyFilters: parsedDefinition.propertyFilters.filter(
        (filter: TPropertyFilter, i: number) => i !== index,
      ),
    };
    setDefinition(JSON.stringify(newDefinition, null, 4));
  };

  return (
    <Row style={{ marginBottom: 10 }}>
      <Col span={24}>
        <Row gutter={16}>
          <Col span={9}>
            <Select<TProperty>
              fill
              items={properties}
              activeItem={properties.find(
                (property: TProperty) => property.name === propertyFilter?.property,
              )}
              scrollToActiveItem={true}
              itemRenderer={renderSchemaActionItems}
              filterable={true}
              onItemSelect={(value) => {
                handlePropertyChange(value.name);
              }}
            >
              <Button
                ellipsizeText
                fill
                outlined={propertyFilter?.property?.length! > 0}
                rightIcon="caret-down"
                alignText="left"
                text={propertyFilter?.property || 'Select Property'}
              />
            </Select>
          </Col>

          {/* Operator */}
          <Col span={5}>
            <Select
              fill
              items={['IN', 'NOT IN']}
              filterable={false}
              itemRenderer={renderOperatorSelect}
              onItemSelect={handleOperatorChange}
            >
              <Button
                ellipsizeText
                fill
                outlined={true}
                rightIcon="caret-down"
                alignText="left"
                text={propertyFilter?.operator || 'Select Operator'}
              />
            </Select>
          </Col>
          <Col span={8}>
            <TagInput
              values={propertyFilter?.values}
              onChange={handleValuesChange}
              addOnBlur
              tagProps={{
                intent: 'primary',
                minimal: true,
              }}
            />
          </Col>
          <Col span={2} style={{ textAlign: 'right' }}>
            <Button
              small
              icon="cross"
              intent="danger"
              minimal
              onClick={removePropertyFilterFromDefinition}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default FlowBuilderPropertyFilter;
