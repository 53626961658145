import React, { useEffect, useState, ChangeEvent } from 'react';
import { Col, Row, Spin } from 'antd';
import { Button, InputGroup, MenuItem } from '@blueprintjs/core';
import { MultiSelect as Select } from '@blueprintjs/select';
import { connect } from 'react-redux';

import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaColumnOptionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/option/schema.column.option.entity';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

import { getSchemaByModuleAndEntityRequest } from '@redux/stores/schemas/actions';
import { IRecordReducer } from '@redux/stores/records/reducer';

import { PageHeader } from 'src/core/components/PageHeader';
import { httpGet, httpPost } from '@core/http/requests';
import { useRequest } from '@core/hooks/useRequest';
import KanbanBoard from '@core/components/KanbanBoard';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { IOpenRecordDrawer, openRecordDrawer } from '@redux/stores/userInterface/actions';

interface Props {
  recordReducer: IRecordReducer;
  openDrawer: (params: IOpenRecordDrawer) => void;
}

const RFCManagementViewV2 = ({ recordReducer, openDrawer }: Props) => {
  const [featureSchema, setFeatureSchema] = useState<SchemaEntity | undefined>();
  const [pipeline, setPipeline] = useState<PipelineEntity | undefined>();

  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [allFeatures, setAllFeatures] = useState<DbRecordEntityTransform[]>([]);
  const [featuresList, setFeaturesList] = useState<DbRecordEntityTransform[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const [exchangesList, setExchangesList] = useState<DbRecordEntityTransform[]>([]);
  const [selectedExchange, setSelectedExchange] = useState<DbRecordEntityTransform | undefined>();

  const [routeTypeList, setRouteTypeList] = useState<SchemaColumnOptionEntity[]>([]);
  const [structureTypeList, setStructureTypeList] = useState<SchemaColumnOptionEntity[]>([]);

  const [selectedAssignee, setSelectedAssignee] = useState<string | undefined>();
  const [assigneesList, setAssigneesList] = useState<Array<{ id: string; name: string }>>([]);

  const [selectedBuildContractor, setSelectedBuildContractor] = useState<string | undefined>();
  const [buildContractorsList, setBuildContractorsList] = useState<Array<string>>([]);

  const [selectedPriority, setSelectedPriority] = useState<string | undefined>();
  const [priorityList, setPriorityList] = useState<SchemaColumnOptionEntity[]>([]);

  const getSchema = useRequest(getSchemaByModuleAndEntityRequest);

  // Listen for record updates
  useEffect(() => {
    const records = Object.values(recordReducer.shortList);
    const lastUpdatedRecord = records.at(-1);

    if (lastUpdatedRecord?.entity === 'ProjectModule:Feature') {
      setAllFeatures((prev) =>
        prev.map((feature) => (feature.id === lastUpdatedRecord.id ? lastUpdatedRecord : feature)),
      );

      // Check for new assignee
      const assigneeId = lastUpdatedRecord.properties?.OwnerUserId;
      const assigneeName = lastUpdatedRecord.featureUser?.[0]?.name;
      const buildContractor = lastUpdatedRecord.properties?.BuildContractor;

      if (assigneeId && assigneeName && !assigneesList.some((a) => a.id === assigneeId)) {
        setAssigneesList((prev) => {
          const newList = [...prev, { id: assigneeId, name: assigneeName }];
          return newList.sort((a, b) => a.name.localeCompare(b.name));
        });
      }

      // Check for new build contractor
      if (buildContractor && !buildContractorsList.includes(buildContractor)) {
        setBuildContractorsList((prev) => {
          const newList = [...prev, buildContractor];
          return newList.sort();
        });
      }
    }
  }, [recordReducer.shortList]);

  // Update the Assignees and Contractors filters to only include relevant options
  useEffect(() => {
    if (!selectedExchange && !selectedAssignee && !selectedBuildContractor) {
      setBuildContractorsFromFeatures();
      setAssigneesFromFeatures();
    }
  }, [allFeatures]);

  const fetchExchanges = async () => {
    const response = await httpPost('ProjectModule/v2.0/records/search', {
      query: {
        entity: 'ProjectModule:Exchange',
        type: 'and',
        value: [],
        pagination: {
          size: 1000,
          page: 1,
        },
      },
    });

    const data = response.data.data.records.filter((record: DbRecordEntityTransform) =>
      getProperty(record, 'ExPolygonId'),
    );

    // sort exchanges alphabetically by title in front-end as backend sorting by title is not working
    data.sort((a: DbRecordEntityTransform, b: DbRecordEntityTransform) => {
      return a.title?.localeCompare(b.title ?? '');
    });

    setExchangesList(data);
  };

  // Fetch pipeline and stages
  const fetchPipeline = async (schema: SchemaEntity) => {
    const res = await httpGet(
      `SchemaModule/v1.0/pipelines/bymodule/${schema.moduleName}/${schema.entityName}`,
    );
    const pipelines = res.data?.data || [];

    if (pipelines.length > 0) {
      setPipeline(pipelines[0]);
    }
  };

  const setBuildContractorsFromFeatures = async () => {
    const buildContractors: Array<string> = ['No Build Contractor'];

    allFeatures.forEach((record: DbRecordEntityTransform) => {
      const contractor = record.properties?.BuildContractor;
      if (contractor && !buildContractors.includes(contractor)) {
        buildContractors.push(contractor);
      }
    });

    setBuildContractorsList(buildContractors.sort());
  };

  const setAssigneesFromFeatures = async () => {
    const assignees: Array<{ id: string; name: string }> = [];

    allFeatures.forEach((record: DbRecordEntityTransform) => {
      const assigneeId = record.properties?.OwnerUserId;
      const assigneeName = record.featureUser?.[0]?.name;

      if (assigneeId && assigneeName && !assignees.some((a) => a.id === assigneeId)) {
        assignees.push({ id: assigneeId, name: assigneeName });
      }
    });

    // Add "Unassigned" option at the beginning and sort by name
    setAssigneesList([
      { id: 'no_assignee', name: 'No Assignee' },
      ...assignees.sort((a, b) => a.name.localeCompare(b.name)),
    ]);
  };

  const fetchExchangeFeatures = async () => {
    let filtersQuery = [];

    filtersQuery.push({
      columnName: 'JiraProjectKey',
      operator: 'isNull',
      value: '',
    });

    filtersQuery.push({
      columnName: 'EntityType',
      operator: 'in',
      value: ['SURVEY_STRUCTURE', 'SURVEY_ROUTE'],
    });

    if (selectedExchange) {
      filtersQuery.push({
        columnName: 'ExPolygonId',
        operator: 'eq',
        value: getProperty(selectedExchange, 'ExPolygonId'),
      });
    }

    // Add assignee filter to the API request
    if (selectedAssignee) {
      if (selectedAssignee === 'no_assignee') {
        filtersQuery.push({
          columnName: 'OwnerUserId',
          operator: 'isNull',
          value: '',
        });
      } else {
        filtersQuery.push({
          columnName: 'OwnerUserId',
          operator: 'eq',
          value: selectedAssignee,
        });
      }
    }

    // Add BuildContractor filter
    if (selectedBuildContractor) {
      if (selectedBuildContractor === 'No Build Contractor') {
        filtersQuery.push({
          columnName: 'BuildContractor',
          operator: 'isNull',
          value: '',
        });
      } else {
        filtersQuery.push({
          columnName: 'BuildContractor',
          operator: 'phrase',
          value: selectedBuildContractor,
        });
      }
    }

    // Add Priority filter
    if (selectedPriority) {
      if (selectedPriority === 'no_priority') {
        filtersQuery.push({
          columnName: 'Priority',
          operator: 'isNull',
          value: '',
        });
      } else {
        filtersQuery.push({
          columnName: 'Priority',
          operator: 'eq',
          value: selectedPriority,
        });
      }
    }

    const RFCs = await httpPost('ProjectModule/v2.0/records/search', {
      query: {
        entity: 'ProjectModule:Feature',
        type: 'and',
        value: [
          ...filtersQuery,
          {
            columnName: 'StageKey',
            operator: 'in',
            value: [
              'JiraSurveyTicketsStageOpen',
              'JiraSurveyTicketsStageInReview',
              'JiraSurveyTicketsStageAdditionalInformationRequired',
              'JiraSurveyTicketsStageToQc',
              'JiraSurveyTicketsStageRbmApproval',
              'JiraSurveyTicketsStageMiUiCaseRaised',
              'JiraSurveyTicketsStageDone',
              'JiraSurveyTicketsStagecancelled',
            ],
          },
        ],
        sort: [{ createdAt: { order: 'desc' } }],
        pageSize: 1000,
        returnProperties: ['id', 'stage', 'featureUser', 'type', 'properties.*', 'entity'],
      },
    });

    setAllFeatures(RFCs.data.data.records);
  };

  // Filter features based on search value
  useEffect(() => {
    let filteredFeatures = allFeatures;

    if (searchValue) {
      filteredFeatures = filteredFeatures.filter((feature) => {
        const externalRef = getProperty(feature, 'ExternalRef');
        if (!externalRef) return false;
        return externalRef.toLowerCase().includes(searchValue.toLowerCase());
      });
    }

    setFeaturesList(filteredFeatures);
  }, [searchValue, allFeatures]);

  // Fetching features when Exchange, Assignee, or build contractor filters change
  useEffect(() => {
    setUpdating(true);

    fetchExchangeFeatures().finally(() => {
      setUpdating(false);
    });
  }, [selectedExchange, selectedAssignee, selectedBuildContractor, selectedPriority]);

  // Fetching schemas, exchanges, and assignees on mount
  useEffect(() => {
    setLoading(true);
    fetchExchanges();

    getSchema({ moduleName: 'ProjectModule', entityName: 'Feature' }).then((schema) => {
      setFeatureSchema(schema);

      // Set route and structure type lists when schema is set
      const routeTypeColumn = schema.columns.find(
        (col: SchemaColumnEntity) => col.name === 'RouteType',
      );

      if (routeTypeColumn && routeTypeColumn.options) {
        setRouteTypeList(routeTypeColumn.options);
      }

      const structureTypeColumn = schema.columns.find(
        (col: SchemaColumnEntity) => col.name === 'StructureType',
      );

      if (structureTypeColumn && structureTypeColumn.options) {
        setStructureTypeList(structureTypeColumn.options);
      }

      const priorityColumn = schema.columns.find(
        (col: SchemaColumnEntity) => col.name === 'Priority',
      );

      if (priorityColumn && priorityColumn.options) {
        setPriorityList([
          { value: 'no_priority', label: 'No Priority' },
          ...priorityColumn.options,
        ]);
      }

      fetchPipeline(schema).then(() => {
        setLoading(false);
      });
    });
  }, []);

  return (
    <div className="rfc-management-view">
      <Row gutter={12}>
        <Col span={24}>
          {/* Page Header */}
          <PageHeader
            style={{ border: '1px solid #dddbda', background: 'white' }}
            title="RFC Management V2"
          >
            <Row gutter={[8, 8]}>
              <Col xs={24} md={6} lg={5} xl={4}>
                <InputGroup
                  style={{ marginBottom: 8 }}
                  className="bp5-input-group--search"
                  round
                  id="rfc-search"
                  placeholder="Search by External Ref"
                  disabled={loading}
                  leftIcon="search"
                  value={searchValue}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
                  rightElement={
                    searchValue?.length > 0 ? (
                      <Button
                        minimal
                        intent="danger"
                        icon="cross"
                        onClick={() => setSearchValue('')}
                      />
                    ) : (
                      <></>
                    )
                  }
                />
              </Col>
              <Col xs={24} md={6} lg={5} xl={4}>
                <Select
                  placeholder="Filter by Exchange"
                  disabled={loading}
                  items={
                    selectedExchange
                      ? exchangesList.filter((exchange) => exchange.id !== selectedExchange.id)
                      : exchangesList
                  }
                  selectedItems={selectedExchange ? [selectedExchange] : []}
                  itemRenderer={(exchange, { handleClick, handleFocus, modifiers }) => {
                    return (
                      <MenuItem
                        active={modifiers.active}
                        disabled={modifiers.disabled}
                        key={exchange.id}
                        label={getProperty(exchange, 'ExPolygonId')}
                        text={getProperty(exchange, 'ExchangeName') ?? exchange.title}
                        onClick={handleClick}
                        onFocus={handleFocus}
                      />
                    );
                  }}
                  itemPredicate={(originalQuery, exchange, _index, exactMatch) => {
                    const exchangeName = (
                      getProperty(exchange, 'ExchangeName') ?? exchange.title
                    ).toLowerCase();
                    const query = originalQuery.toLowerCase();

                    return exactMatch ? exchangeName === query : exchangeName.includes(query);
                  }}
                  tagRenderer={(exchange) =>
                    getProperty(exchange, 'ExchangeName') ?? exchange.title
                  }
                  tagInputProps={{ tagProps: { minimal: true } }}
                  onRemove={() => setSelectedExchange(undefined)}
                  onItemSelect={setSelectedExchange}
                  fill
                />
              </Col>
              <Col xs={24} md={6} lg={5} xl={4}>
                <Select
                  placeholder="Filter by Assignee"
                  disabled={loading}
                  items={
                    selectedAssignee
                      ? assigneesList.filter((assignee) => assignee.id !== selectedAssignee)
                      : assigneesList
                  }
                  selectedItems={
                    selectedAssignee ? [assigneesList.find((a) => a.id === selectedAssignee)!] : []
                  }
                  itemRenderer={(assignee, { handleClick, handleFocus, modifiers }) => (
                    <MenuItem
                      active={modifiers.active}
                      disabled={modifiers.disabled}
                      key={assignee.id}
                      text={assignee.name}
                      onClick={handleClick}
                      onFocus={handleFocus}
                    />
                  )}
                  itemPredicate={(query, assignee, _index, exactMatch) => {
                    const assigneeLower = assignee.name.toLowerCase();
                    const queryLower = query.toLowerCase();
                    return exactMatch
                      ? assigneeLower === queryLower
                      : assigneeLower.includes(queryLower);
                  }}
                  tagRenderer={(assignee) => assignee.name}
                  tagInputProps={{ tagProps: { minimal: true } }}
                  onRemove={() => setSelectedAssignee(undefined)}
                  onItemSelect={(assignee) => setSelectedAssignee(assignee.id)}
                  fill
                />
              </Col>
              <Col xs={24} md={6} lg={5} xl={4}>
                <Select
                  placeholder="Filter by Build Contractor"
                  disabled={loading || buildContractorsList.length === 0}
                  items={
                    selectedBuildContractor
                      ? buildContractorsList.filter(
                          (contractor) => contractor !== selectedBuildContractor,
                        )
                      : buildContractorsList
                  }
                  selectedItems={selectedBuildContractor ? [selectedBuildContractor] : []}
                  itemRenderer={(contractor, { handleClick, handleFocus, modifiers }) => (
                    <MenuItem
                      active={modifiers.active}
                      disabled={modifiers.disabled}
                      key={contractor}
                      text={contractor}
                      onClick={handleClick}
                      onFocus={handleFocus}
                    />
                  )}
                  itemPredicate={(query, contractor, _index, exactMatch) => {
                    const contractorLower = contractor.toLowerCase();
                    const queryLower = query.toLowerCase();
                    return exactMatch
                      ? contractorLower === queryLower
                      : contractorLower.includes(queryLower);
                  }}
                  tagRenderer={(contractor) => contractor}
                  tagInputProps={{ tagProps: { minimal: true } }}
                  onRemove={() => setSelectedBuildContractor(undefined)}
                  onItemSelect={(contractor) => setSelectedBuildContractor(contractor)}
                  fill
                />
              </Col>
              <Col xs={24} md={6} lg={5} xl={4}>
                <div style={{ position: 'relative' }}>
                  <Select
                    placeholder="Filter by Priority"
                    disabled={loading || priorityList.length === 0}
                    items={
                      selectedPriority
                        ? priorityList.filter((priority) => priority.value !== selectedPriority)
                        : priorityList
                    }
                    selectedItems={
                      selectedPriority
                        ? [priorityList.find((p) => p.value === selectedPriority)!]
                        : []
                    }
                    itemRenderer={(priority, { handleClick, handleFocus, modifiers }) => (
                      <MenuItem
                        active={modifiers.active}
                        disabled={modifiers.disabled}
                        key={priority.value}
                        text={priority.label}
                        onClick={handleClick}
                        onFocus={handleFocus}
                      />
                    )}
                    itemPredicate={(query, priority, _index, exactMatch) => {
                      const priorityLower = priority.label.toLowerCase();
                      const queryLower = query.toLowerCase();
                      return exactMatch
                        ? priorityLower === queryLower
                        : priorityLower.includes(queryLower);
                    }}
                    tagRenderer={(priority) => priority.label}
                    tagInputProps={{ tagProps: { minimal: true } }}
                    onRemove={() => setSelectedPriority(undefined)}
                    onItemSelect={(priority) => setSelectedPriority(priority.value)}
                    fill
                  />
                  {updating && (
                    <Spin
                      size="small"
                      style={{ position: 'absolute', right: '-24px', top: '7px' }}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </PageHeader>
        </Col>
        <Col span={24}>
          {/* Page Content */}
          {featureSchema && pipeline?.stages && (
            <KanbanBoard
              loading={loading}
              updating={updating}
              records={featuresList}
              schema={featureSchema}
              routeTypeList={routeTypeList}
              structureTypeList={structureTypeList}
              stages={pipeline.stages}
              openDrawer={openDrawer}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
});

const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(RFCManagementViewV2);
