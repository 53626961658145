import React, { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { SchemaEntity } from "@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity";

import { OdinSearchContextType, OdinSearchParams, SearchV2Filter } from "./types";
import * as paramsStorage from "./filterStorage";

const initialSearchContextState: OdinSearchContextType = {
  state: {
    isLoading: true,
    savedParams: [],
    selectedParams: undefined,
  },
  actions: {
    addFilter: () => {},
    removeFilter: () => {},
    replaceFilters: () => {},
    setSort: () => {},
    saveParams: () => {},
    deleteParams: () => {},
    selectParams: () => {},
  },
};

const OdinSearchContext = createContext<OdinSearchContextType>(initialSearchContextState);

type OdinSearchProviderProps = {
  schema?: SchemaEntity;
};

export const useOdinSearchState = () => {
  const context = React.useContext(OdinSearchContext);
  if (context === undefined) {
    throw new Error("useOdinSearchState must be used within a OdinSearchProvider");
  }

  return useMemo(() => context.state, [context.state]);
};

export const useOdinSearchActions = () => {
  const context = React.useContext(OdinSearchContext);
  if (context === undefined) {
    throw new Error("useOdinSearchActions must be used within a OdinSearchProvider");
  }

  return useMemo(() => context.actions, [context.actions]);
}

export const OdinSearchProvider: React.FC<PropsWithChildren<OdinSearchProviderProps>> = (
  { schema, children }
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [savedParams, setSavedParams] = useState<OdinSearchParams[]>([]);
  const [selectedParams, setSelectedParams] = useState<OdinSearchParams>();

  useEffect(() => {
    if (!schema?.id) return;

    const allParams = paramsStorage.loadAllParams(schema.id);
    setSavedParams(allParams);

    const selectedParams = paramsStorage.loadSelectedParams(schema.id);
    setSelectedParams(selectedParams);
    setIsLoading(false);
  }, [schema?.id]);

  const addFilter = useCallback((filter: SearchV2Filter) => {
    if (!schema?.id) return;

    setSelectedParams(params => {
      const newParams = params
        ? { ...params, filters: [...(params?.filters || []), filter] }
        : { filters: [filter] };

      paramsStorage.saveSelectedParams(schema.id, newParams);

      return newParams;
    })
  }, [schema?.id]);

  const removeFilter = useCallback((filter: SearchV2Filter) => {
    if (!schema?.id) return;

    setSelectedParams(params => {
      const newParams = !params
        ? { filters: [] }
        : { ...params, filters: params.filters.filter(f => f !== filter) };

      paramsStorage.saveSelectedParams(schema.id, newParams);

      return newParams;
    });
  }, [schema?.id]);

  const replaceFilters = (filters: SearchV2Filter[]) => {
    if (!schema?.id) return;

    setSelectedParams((params) => {
      const newParams = params ? { ...params, filters } : { filters };
      paramsStorage.saveSelectedParams(schema.id, newParams);
      
      return newParams;
    });
  };

  const setSort = useCallback((sort: OdinSearchParams["sort"]) => {
    if (!schema?.id) return;

    setSelectedParams(params => {
      const newParams = !params
        ? { filters: [], sort }
        : { ...params, sort };

        paramsStorage.saveSelectedParams(schema.id, newParams);

        return newParams;
      });
  }, [schema?.id]);

  const saveParams = useCallback((params: OdinSearchParams) => {
    if (!schema?.id) return;

    const updatedParams = paramsStorage.saveParams(schema.id, params);
    setSavedParams(updatedParams);
    setSelectedParams(params);
  }, [schema?.id]);

  const deleteParams = useCallback((params: OdinSearchParams) => {
    if (!schema?.id) return;

    const updatedParams = paramsStorage.deleteParams(schema.id, params);
    setSavedParams(updatedParams);
    setSelectedParams(undefined);
  }, [schema?.id]);

  const selectParams = useCallback((params: OdinSearchParams = { filters: [] }) => {
    setSelectedParams({ ...params, filters: [...params.filters] });
  }, []);

  const state = useMemo(() => ({ isLoading, savedParams, selectedParams }), [isLoading, savedParams, selectedParams]);

  const actions = useMemo(() => ({ addFilter, removeFilter, replaceFilters, setSort, saveParams, deleteParams, selectParams }), [
    addFilter,
    removeFilter,
    setSort,
    saveParams,
    deleteParams,
    selectParams,
  ]);

  return (
    <OdinSearchContext.Provider value={{state, actions }}>
      {children}
    </OdinSearchContext.Provider>
  );
};
