import { Button, MenuItem, Tooltip } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { isSystemAdmin } from '@core/helpers/rbacRules';
import { SchemaActionDefinitionContext } from '@core/modules/ControlPanelModule/components/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { IUserReducer } from '@redux/stores/identity/reducers';
import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';

interface IFormField {
  key: string;
  name: string;
  value: string;
  label: string;
}

interface Props {
  userReducer: IUserReducer;
}

const SchemaActionInsertLinkTemplateButton: React.FC<Props> = (props: Props) => {
  const { userReducer } = props;
  const { schema, schemaAction, JSONInvalid, isSavingDefinition, definition, setDefinition } =
    useContext(SchemaActionDefinitionContext);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleQueryChange = (e: string) => {
    setSearchQuery(e);
  };

  const renderFormField: ItemRenderer<IFormField> = (
    entity,
    { handleClick, handleFocus, modifiers },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else
      return (
        <MenuItem
          style={{ fontWeight: entity.key === 'ALL' ? 500 : 'auto' }}
          intent={entity.key === 'ALL' ? 'primary' : 'none'}
          disabled={modifiers.disabled}
          key={entity.name}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={entity.name}
        />
      );
  };

  const handleItemSelect = (item: IFormField) => {
    let parsed: any = JSON.parse(definition);

    parsed.formDefinition[0].formFields?.push({
      name: item.name,
      label: item.name,
      type: 'association',
      required: false,
      schemaAssociationId: item.key,
      order: parsed.formDefinition[0].formFields?.length + 1,
    });

    setDefinition(JSON.stringify(parsed, null, '\t'));
  };

  const schemaAssociations: SchemaAssociationEntity[] = schema?.associations || [];

  let FORM_FIELDS: IFormField[] = schemaAssociations.map(
    (association: SchemaAssociationEntity) => ({
      key: association.id,
      name: association.label,
      value: association.id,
      label: association.label,
    }),
  );

  if (searchQuery.length > 0) {
    FORM_FIELDS = FORM_FIELDS.filter((field: IFormField) => {
      return field.name.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }

  const isCreate = schemaAction?.isCreate;

  return (
    <Tooltip content="Available only for CREATE schema actions" disabled={isCreate} fill>
      <Select<IFormField>
        items={FORM_FIELDS}
        fill
        resetOnClose
        disabled={JSONInvalid || isSavingDefinition || !isSystemAdmin(userReducer) || !isCreate}
        itemRenderer={renderFormField}
        noResults={<MenuItem disabled={true} text="No results." roleStructure="menuitem" />}
        onItemSelect={handleItemSelect}
        query={searchQuery}
        scrollToActiveItem
        onQueryChange={(e: any) => handleQueryChange(e)}
      >
        <Button disabled={!isCreate} text="Insert Link Field" fill></Button>
      </Select>
    </Tooltip>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

export default connect(mapState)(SchemaActionInsertLinkTemplateButton);
