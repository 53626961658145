import { Card, Flex } from 'antd';
import { PropsWithChildren, ReactNode } from 'react';
import './CardBase.scss';

type Props = PropsWithChildren<{ title: string; subTitle?: ReactNode; backgroundColor?: string }>;
const CardBase = (props: Props) => {
  return (
    <Card
      styles={{ body: { padding: 12, backgroundColor: props.backgroundColor } }}
      classNames={{ body: 'card-base' }}
      style={{ width: '100%', borderRadius: '8px' }}
      variant="borderless"
    >
      <Flex vertical gap={8} flex={1} style={{ height: '100%' }}>
        <Flex vertical gap={8} justify="space-between" style={{ height: '100%' }}>
          <div className="title">{props.title}</div>
          {!!props.subTitle && <div className="sub-title">{props.subTitle}</div>}
        </Flex>
        {props.children}
      </Flex>
    </Card>
  );
};

export default CardBase;
