import { httpPost } from '@core/http/requests';
import { CaseChannel } from '@d19n/sandbox-odin-sdk/dist/entities-v2/Case';
import { useEffect, useState } from 'react';
import CardBase from './CardBase/CardBase';

type Props = {
  refreshBit: 0 | 1;
};
const OpenOfflineCases = (props: Props) => {
  const [chatsInQueue, setChatsInQueue] = useState<number>(0);

  const getOfflineCasesCount = async () => {
    // TODO change this back to SupportModule as soon as schema manager is deployed
    const result = await httpPost(`SupportModule/v2.0/records/count`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Channel',
            operator: 'not in',
            value: [CaseChannel.WEB_CHAT, CaseChannel.CALL],
          },
          {
            columnName: 'StageName',
            operator: 'in',
            value: ['Open', 'Pending Agent', 'Pending Review'],
          },
        ],
      },
    });
    const count = result.data.data.totalRecords ?? 0;
    setChatsInQueue(count);
  };

  useEffect(() => {
    getOfflineCasesCount();
  }, [props.refreshBit]);

  return <CardBase title="Open" subTitle={chatsInQueue.toLocaleString('en-US')} />;
};

export default OpenOfflineCases;
