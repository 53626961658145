import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RecordCard from 'src/core/components/RecordCard';
import { IRecordReducer } from '../../../../../redux/stores/records/reducer';
import { getRecordFromShortListById } from '@core/helpers/recordHelpers';
import DetailView from 'src/core/views/DefaultDetailView';

const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;
const { WORK_ORDER } = SchemaModuleEntityTypeEnums;

interface PropsType {
  recordReducer: IRecordReducer;
  match: any;
  schemaReducer: any;
}

const PermissionToFailDetailView: FunctionComponent<PropsType> = (props) => {
  const { recordReducer, match } = props;
  const record = getRecordFromShortListById(recordReducer.shortList, match.params.recordId);

  return (
    <>
      <DetailView
        showCollaborators
        leftColumn={[
          <RecordCard
            showActionMenu
            borderless
            showItemActionMenu
            shouldPollData={true}
            pollingIntervalSeconds={5}
            showRecordStage={true}
            record={record}
            displayQuickView
            associatedRecordModuleName={FIELD_SERVICE_MODULE}
            associatedRecordEntityName={WORK_ORDER}
            visibleProperties={['EngineerName']}
          />,
          <RecordCard
            showActionMenu
            borderless
            showItemActionMenu
            shouldPollData={true}
            pollingIntervalSeconds={5}
            record={record}
            displayQuickView
            associatedRecordModuleName={FIELD_SERVICE_MODULE}
            associatedRecordEntityName={'RemediationRequiredReason'}
            visibleProperties={['Reason', 'CustomerInformed', 'OtherInformation']}
          />,
        ]}
      />
    </>
  );
};

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default withRouter(connect(mapState, mapDispatch)(PermissionToFailDetailView));
