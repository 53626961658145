import { Button, Drawer, OverlayToaster } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { ISearchRecords, searchRecordsRequest } from '@redux/stores/records/actions';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '@redux/stores/schemas/actions';
import { ISchemaReducer } from '@redux/stores/schemas/reducer';
import {
  setAppointmentCountNumber,
  setMentionsCountNumber,
  toggleNotificationDrawer,
} from '@redux/stores/userInterface/actions';
import { IUserInterfaceReducer } from '@redux/stores/userInterface/types';
import { Badge, Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import history from '../../helpers/browserHistory';
import { getSchemaFromShortListByModuleAndEntity } from '../../helpers/schemaHelpers';
import { TMention } from '@core/views/InboxView/store/types';
import { getAppointmentsWithElastic, getMentionsForUser } from './api';
import NotificationDrawerAppointmentsList from './NotificationDrawerAppointmentsList';
import NotificationDrawerMentionList from './NotificationDrawerMentionList';
import './styles.scss';

interface Props {
  schemaReducer: ISchemaReducer;
  userInterfaceReducer: IUserInterfaceReducer;
  userReducer: any;
  getSchema: (payload: ISchemaByModuleAndEntity, cb?: any) => void;
  searchRecords: (params: ISearchRecords, cb?: any) => void;
  setAppointmentCount: (params: { count: number }) => void;
  setMentionCount: (params: { count: number }) => void;
  toggleDrawer: () => void;
  history: any;
}

const { CRM_MODULE } = SchemaModuleTypeEnums;
const APPOINTMENT = 'Appointment';

let timer: NodeJS.Timeout | undefined = undefined;
const pollingIntervalMilliseconds = 15000;
let STOP_POLLING = false;

let lastMention: DbRecordEntityTransform | undefined = undefined;
let newMentions: DbRecordEntityTransform[] = [];
let dismissedMentionsIds: string[] = [];

const NotificationDrawer: React.FC<Props> = (props: Props) => {
  const {
    schemaReducer,
    userInterfaceReducer,
    userReducer,
    toggleDrawer,
    getSchema,
    setAppointmentCount,
    setMentionCount,
  } = props;
  const [appointments, setAppointments] = useState<DbRecordEntityTransform[]>([]);
  const [isLoadingAppointments, setIsLoadingAppointments] = useState<boolean>(false);
  const [mentions, setMentions] = useState<DbRecordEntityTransform[]>([]);
  const [isLoadingMentions, setIsLoadingMentions] = useState<boolean>(false);
  const [appointmentSchema, setAppointmentSchema] = useState<SchemaEntity | undefined>(undefined);
  const [selectedTab, setSelectedTab] = useState<'MENTIONS' | 'APPOINTMENTS'>('MENTIONS');
  // const [dismissedMentionsIds, setDismissedMentionsIds] = useState<string[]>([]);

  const myToaster = React.createRef<OverlayToaster>();

  const navigateToInbox = (e: any) => {
    // if the user is already in the inbox, we need to reload the page to fetch the new mention
    if (location.pathname.indexOf('/inbox') > -1) {
      e.stopPropagation();
      e.preventDefault();
      toggleDrawer();
    } else {
      e.stopPropagation();
      e.preventDefault();
      toggleDrawer();

      // Wait for the drawer to close before navigating
      setTimeout(() => {
        history.push(`/inbox/`);
      }, 700);
    }
  };

  // Configure polling timer
  const startTimer = () => {
    timer = setInterval(() => {
      if (!document.hidden && !STOP_POLLING) {
        fetchMentions();
      }
    }, pollingIntervalMilliseconds);
  };
  const clearTimer = () => {
    clearInterval(timer);
    timer = undefined;
  };

  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, []);
  useEffect(() => {
    startTimer();
    fetchMentions();
  }, []);

  // On component mount, get Mentions
  useEffect(() => {
    // newMentions.push(mentions[0]);
    if (newMentions.length === 1) {
      const mention = newMentions[0];
      myToaster.current?.show(
        {
          message: `You were mentioned by ${
            mention?.lastModifiedBy?.fullName || mention?.createdBy?.fullName || '-'
          }`,
          timeout: 5000,
          icon: 'at',
          intent: 'primary',
          action: {
            text: 'Show',
            onClick: toggleDrawer,
          },
        },
        'message',
      );
      newMentions = [];
    } else if (newMentions.length > 1) {
      myToaster.current?.show(
        {
          message: `You have ${newMentions.length} new mentions`,
          timeout: 5000,
          icon: 'at',
          intent: 'primary',
          action: {
            text: 'Show',
            onClick: toggleDrawer,
          },
        },
        'message',
      );
      newMentions = [];
    }
  }, [mentions]);

  const fetchMentions = () => {
    if (userReducer.user) {
      setIsLoadingMentions(true);
      getMentionsForUser(userReducer.user?.id).then((res: TMention[]) => {
        // Filter out mentions that have been dismissed
        let filteredMentions = Object.assign([], res);

        filteredMentions = filteredMentions.filter((mention: DbRecordEntityTransform) => {
          return !mention.metaData?.read;
        });

        filteredMentions = filteredMentions.filter((mention: DbRecordEntityTransform) => {
          return !dismissedMentionsIds.includes(mention.id);
        });
        filteredMentions =
          filteredMentions.sort((a: DbRecordEntityTransform, b: DbRecordEntityTransform) =>
            dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1,
          ) || [];

        setMentions(filteredMentions);
        setMentionCount({ count: filteredMentions?.length });
        setIsLoadingMentions(false);

        // First, filter out mentions that are newer than the latest mention date
        if (lastMention) {
          const newestMentions = filteredMentions.filter((mention: DbRecordEntityTransform) => {
            return dayjs(mention.createdAt).isAfter(dayjs(lastMention?.createdAt));
          });
          if (newestMentions.length > 0) {
            newMentions = newestMentions;
          }
        }

        // Get latest mention date and redux it to state
        if (filteredMentions.length > 0) {
          lastMention = filteredMentions[0];
        }

        // console.log('DEBUG: Fetched mentions', filteredMentions);
      });
    }
  };

  // On Component mount, fetch Appointment schema
  useEffect(() => {
    const hasAccessToAppointmentSchema = !!schemaReducer.list?.find(
      (schema: SchemaEntity) =>
        schema.moduleName === CRM_MODULE && schema.entityName === APPOINTMENT,
    );

    if (!appointmentSchema && hasAccessToAppointmentSchema) {
      const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        CRM_MODULE,
        APPOINTMENT,
      );

      if (shortlistSchema) {
        setAppointmentSchema(shortlistSchema);
      } else {
        getSchema(
          {
            moduleName: CRM_MODULE,
            entityName: APPOINTMENT,
          },
          (schema: SchemaEntity) => {
            setAppointmentSchema(schema);
          },
        );
      }
    }
  }, [schemaReducer?.list?.length]);

  // When Appointment schema becomes available, fetch appointments
  useEffect(() => {
    if (appointmentSchema && userReducer?.user) {
      const userId = userReducer.user?.id;
      setIsLoadingAppointments(true);
      getAppointmentsWithElastic(appointmentSchema?.id, userId).then(
        (res: DbRecordEntityTransform[]) => {
          setIsLoadingAppointments(false);
          if (res.length > 0) {
            // Filter only appointments for today
            const appointmentsToDate = res.filter((appointment: DbRecordEntityTransform) => {
              return (
                dayjs(getProperty(appointment, 'Date')).format('DD/MM/YYYY') ===
                dayjs().format('DD/MM/YYYY')
              );
            });
            setAppointments(appointmentsToDate);
            setAppointmentCount({ count: appointmentsToDate?.length });
          }
        },
      );
    }
  }, [appointmentSchema]);

  const onMentionRemove = (mention: DbRecordEntityTransform) => {
    if (mention) {
      STOP_POLLING = true;
      dismissedMentionsIds.push(mention.id);
      setMentionCount({ count: mentions?.length - 1 });
      setTimeout(() => {
        setMentions(mentions.filter((m: DbRecordEntityTransform) => m.id !== mention.id));
      }, 600);
      STOP_POLLING = false;
    }
  };

  return (
    <>
      <OverlayToaster
        className="notificationToaster"
        usePortal={true}
        ref={myToaster}
        position="top-right"
      />

      <Drawer
        title={
          <Row align="middle">
            <Col span={12}>Notifications</Col>
            <Col span={12} style={{ textAlign: 'right', paddingRight: 5 }}>
              <Button
                small
                text="Inbox"
                icon="inbox"
                minimal
                intent="primary"
                onClick={(e: any) => navigateToInbox(e)}
              />
            </Col>
          </Row>
        }
        icon="notifications"
        isOpen={userInterfaceReducer.notificationDrawerVisible}
        onClose={toggleDrawer}
        style={{ width: isMobile ? '95%' : '30%' }}
      >
        <Row style={{ padding: 18 }}>
          {/* Tab Switcher */}
          <Col span={24}>
            <Row>
              <Col span={12}>
                <Button
                  fill
                  intent="primary"
                  text="Mentions"
                  outlined={selectedTab !== 'MENTIONS'}
                  onClick={() => setSelectedTab('MENTIONS')}
                  rightIcon={
                    userInterfaceReducer.mentionsNotificationCount > 0 && (
                      <Badge
                        color="green"
                        size="small"
                        style={{ fontSize: 10 }}
                        count={userInterfaceReducer.mentionsNotificationCount}
                      />
                    )
                  }
                />
              </Col>
              <Col span={12}>
                <Button
                  fill
                  intent="primary"
                  text="Appointments"
                  rightIcon={
                    userInterfaceReducer.appointmentNotificationCount > 0 && (
                      <Badge
                        color="green"
                        size="small"
                        style={{ fontSize: 10 }}
                        count={userInterfaceReducer.appointmentNotificationCount}
                      />
                    )
                  }
                  outlined={selectedTab !== 'APPOINTMENTS'}
                  onClick={() => setSelectedTab('APPOINTMENTS')}
                  disabled={isLoadingAppointments}
                />
              </Col>
            </Row>
          </Col>

          {/* Mentions */}
          {selectedTab === 'MENTIONS' && (
            <Col span={24} style={{ marginTop: 20, height: '86vh', overflowY: 'auto' }}>
              <NotificationDrawerMentionList
                mentions={mentions}
                onMentionRemove={onMentionRemove}
                dismissedMentionsIds={dismissedMentionsIds}
              />
            </Col>
          )}

          {/* Appointments */}
          {selectedTab === 'APPOINTMENTS' && (
            <Col span={24} style={{ marginTop: 20 }}>
              <NotificationDrawerAppointmentsList appointments={appointments} />
            </Col>
          )}
        </Row>
      </Drawer>
    </>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userInterfaceReducer: state.userInterfaceReducer,
  userReducer: state.userReducer,
});
const mapDispatch = (dispatch: any) => ({
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
  setAppointmentCount: (params: { count: number }) => dispatch(setAppointmentCountNumber(params)),
  setMentionCount: (params: { count: number }) => dispatch(setMentionsCountNumber(params)),
  toggleDrawer: () => dispatch(toggleNotificationDrawer()),
});

export default withRouter(connect(mapState, mapDispatch)(NotificationDrawer));
