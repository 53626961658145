import dayjs from 'dayjs';
import { TScheduleUser } from '../types';
import {
  CASE_MANAGEMENT_SCHEDULING_ADD_INITIAL_TIME_BLOCK,
  CASE_MANAGEMENT_SCHEDULING_IS_LOADING_LIST_VIEW,
  CASE_MANAGEMENT_SCHEDULING_REMOVE_INITIAL_TIME_BLOCK,
  CASE_MANAGEMENT_SCHEDULING_REMOVE_TIME_BLOCK_FROM_USER,
  CASE_MANAGEMENT_SCHEDULING_SET_LIST_VIEW_DATA,
  CASE_MANAGEMENT_SCHEDULING_SET_SELECTED_USER,
  CASE_MANAGEMENT_SCHEDULING_SET_ZOOM,
  CASE_MANAGEMENT_SCHEDULING_TOGGLE_USER_DIALOG,
  CASE_MANAGEMENT_SCHEDULING_UPDATE_USERS_TIMEBLOCKS,
} from './constants';

export interface ICaseManagementSchedulingReducer {
  isLoadingListView: boolean;
  isSchedulingUserDialogOpen: boolean;
  schedulingUsers: [];
  selectedSchedulingUser: TScheduleUser | undefined;
  horizontalZoom: number;
  initialTimeBlock:
    | {
        startTime: string;
        endTime: string;
      }
    | undefined;
}

export const CaseManagementSchedulingReducerInitialState: ICaseManagementSchedulingReducer = {
  isLoadingListView: false,
  isSchedulingUserDialogOpen: false,
  schedulingUsers: [],
  selectedSchedulingUser: undefined,
  horizontalZoom: 100,
  initialTimeBlock: undefined,
};

export function caseManagementSchedulingReducer(
  state: ICaseManagementSchedulingReducer,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case CASE_MANAGEMENT_SCHEDULING_TOGGLE_USER_DIALOG:
      return { ...state, isSchedulingUserDialogOpen: !state.isSchedulingUserDialogOpen };
    case CASE_MANAGEMENT_SCHEDULING_SET_SELECTED_USER:
      return { ...state, selectedSchedulingUser: action.payload };
    case CASE_MANAGEMENT_SCHEDULING_IS_LOADING_LIST_VIEW:
      return { ...state, isLoadingListView: action.payload };
    case CASE_MANAGEMENT_SCHEDULING_SET_LIST_VIEW_DATA:
      // Go through all users and in each time block, replace startTime and endTime with dayjs(startTime, 'HH:mm:ss').format('HH:mm')
      const users = action.payload.map((user: TScheduleUser) => {
        const timeBlocks = user.timeBlocks?.map((timeBlock: any) => {
          return {
            ...timeBlock,
            startTime: dayjs(timeBlock.startTime, 'HH:mm:ss').format('HH:mm'),
            endTime: dayjs(timeBlock.endTime, 'HH:mm:ss').format('HH:mm'),
          };
        });

        return { ...user, timeBlocks };
      });

      return { ...state, schedulingUsers: users };

    case CASE_MANAGEMENT_SCHEDULING_UPDATE_USERS_TIMEBLOCKS:
      //   Find a user with payload.userId and replace timeBlocks with payload.timeBlocks
      const updatedUsers = state.schedulingUsers.map((user: TScheduleUser) => {
        if (user.userId === action.payload.userId) {
          const correctedTimeFormatTimeBlocks = action.payload.timeBlocks.map((timeBlock: any) => {
            return {
              ...timeBlock,
              startTime: dayjs(timeBlock.startTime, 'HH:mm:ss').format('HH:mm'),
              endTime: dayjs(timeBlock.endTime, 'HH:mm:ss').format('HH:mm'),
            };
          });

          return { ...user, timeBlocks: correctedTimeFormatTimeBlocks };
        }
        return user;
      });

      return { ...state, schedulingUsers: updatedUsers };

    case CASE_MANAGEMENT_SCHEDULING_SET_ZOOM:
      return { ...state, horizontalZoom: action.payload };
    case CASE_MANAGEMENT_SCHEDULING_REMOVE_TIME_BLOCK_FROM_USER:
      const updatedUsersTimeBlocks = state.schedulingUsers.map((user: TScheduleUser) => {
        if (user.userId === action.payload.userId) {
          const timeBlocks = user.timeBlocks?.filter(
            (timeBlock: any) => timeBlock.id !== action.payload.timeBlockId,
          );
          return { ...user, timeBlocks };
        }
        return user;
      });
      return { ...state, schedulingUsers: updatedUsersTimeBlocks };

    case CASE_MANAGEMENT_SCHEDULING_ADD_INITIAL_TIME_BLOCK:
      return {
        ...state,
        initialTimeBlock: {
          startTime: action.payload.startTime,
          endTime: action.payload.endTime,
        },
      };

    case CASE_MANAGEMENT_SCHEDULING_REMOVE_INITIAL_TIME_BLOCK:
      return { ...state, initialTimeBlock: undefined };
    default:
      return state;
  }
}
