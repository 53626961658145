import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Col, Popconfirm } from 'antd';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { orderBuilderContext } from '../index';
import RecordCard from 'src/core/components/RecordCard';
import {
  addRecordToShortList,
  IAddRecordToShortList,
} from '../../../../../redux/stores/records/actions';

type Props = {
  shortListRecord: Function;
};

const DiscountPanel: React.FC<Props> = (props: Props) => {
  const { state, dispatch } = useContext(orderBuilderContext);
  const { shortListRecord } = props;

  const renderDiscountCard = () => {
    return (
      <Col span={24} key={state.discount?.title} style={{ padding: '0 15px 10px 15px' }}>
        <RecordCard
          openTitleLinkInNewTab
          propertyColumns={2}
          displayQuickView={false}
          headerElement={
            <>
              <Button
                icon={<EyeOutlined />}
                style={{ marginRight: 10 }}
                onClick={() =>
                  shortListRecord({
                    showPreview: true,
                    record: state.discount,
                  })
                }
              />
              <Popconfirm
                placement="topLeft"
                title="Do you want to remove discount from the Order?"
                onConfirm={() => dispatch({ type: 'SET_DISCOUNT', payload: false })}
                okText="Yes"
                cancelText="No"
              >
                <Button danger icon={<DeleteOutlined />} />
              </Popconfirm>
            </>
          }
          record={state.discount}
          visibleProperties={['DiscountLength', 'DiscountType', 'DiscountValue', 'Description']}
        />
      </Col>
    );
  };

  return !state.discount ? <></> : renderDiscountCard();
};

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
});

const mapDispatch = (dispatch: any) => ({
  shortListRecord: (params: IAddRecordToShortList) => dispatch(addRecordToShortList(params)),
});

export default connect(mapState, mapDispatch)(DiscountPanel);
