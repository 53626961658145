import ConfigsListViewTable from '@core/modules/ControlPanelModule/components/Configs/ConfigsListViewTable';
import { Col, Row } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import './styles.scss';

const ConfigsListView: React.FC = () => {
  return (
    <Row>
      {/* Content */}
      <Col span={24}>
        <ConfigsListViewTable />
      </Col>
    </Row>
  );
};

export default withRouter(ConfigsListView);
