import { Button } from '@blueprintjs/core';
import PoundSymbol from '@core/components/PoundSymbol';
import Typography from '@core/components/Typography';
import { getBrowserPath } from '@core/helpers/recordHelpers';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Row, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  IOpenRecordDrawer,
  openRecordDrawer,
} from '../../../../../../redux/stores/userInterface/actions';

interface Props {
  invoiceItems: DbRecordEntityTransform[];
  openDrawer: (params: IOpenRecordDrawer) => void;
  isLoading: boolean;
}

const { BILLING_MODULE } = SchemaModuleTypeEnums;
const { INVOICE_ITEM } = SchemaModuleEntityTypeEnums;

const InvoiceOverviewInvoiceItemList: React.FC<Props> = (props: Props) => {
  const { invoiceItems, openDrawer, isLoading } = props;

  interface DataType {
    key: string;
    record: DbRecordEntityTransform;
  }

  const tableColumns: ColumnsType<DataType> = [
    {
      title: '#',
      dataIndex: 'recordNumber',
      key: 'recordNumber',
      render: (text: any, record: any) => (
        <Link to={getBrowserPath(record?.record)} target="_blank">
          {text}
        </Link>
      ),
    },
    {
      title: 'Item',
      dataIndex: 'invoiceItem',
      key: 'invoiceItem',
      render: (text: any, record: any) => record?.record?.title,
    },
    {
      title: 'Quantity',
      dataIndex: 'invoiceQuantity',
      key: 'invoiceQuantity',
      align: 'center',
      render: (text: any, record: any) => text,
    },
    {
      title: 'Discounts',
      dataIndex: 'totalDiscounts',
      key: 'totalDiscounts',
      render: (text: any, record: any) => <PoundSymbol>{text}</PoundSymbol>,
    },
    {
      title: 'Total',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (text: any, record: any) => <PoundSymbol>{text}</PoundSymbol>,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      align: 'right',
      render: (text: any, record: any) => (
        <Tooltip title="Quick View" mouseEnterDelay={1.2}>
          <Button
            size="small"
            icon="panel-stats"
            onClick={() =>
              openDrawer({
                recordId: record.record?.id,
                moduleName: BILLING_MODULE,
                entityName: INVOICE_ITEM,
              })
            }
          />
        </Tooltip>
      ),
    },
  ];

  let tableData = invoiceItems.map((invoiceItem: DbRecordEntityTransform) => {
    return {
      key: invoiceItem.id,
      recordNumber: invoiceItem.recordNumber || '-',
      invoiceItem: invoiceItem?.title || '-',
      invoiceQuantity: getProperty(invoiceItem, 'Quantity') || '-',
      unitPrice: getProperty(invoiceItem, 'UnitPrice') || '-',
      totalDiscounts: getProperty(invoiceItem, 'TotalDiscounts') || '-',
      totalPrice: getProperty(invoiceItem, 'TotalPrice') || '-',
      record: invoiceItem,
    };
  });

  return (
    <>
      <Row style={{ marginBottom: 15 }}>
        <Col span={24}>
          <Typography stronger>Invoice Items</Typography>
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={24}>
          <Table
            style={{ opacity: isLoading ? 0.3 : 1 }}
            size="small"
            bordered={false}
            loading={isLoading}
            columns={tableColumns}
            dataSource={tableData}
            pagination={false}
          />
        </Col>
      </Row>
    </>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(InvoiceOverviewInvoiceItemList);
