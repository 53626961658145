import { Alert, Button, Callout, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getFirstRelation,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Row, Skeleton, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '../../../../../redux/stores/recordAssociations/actions';
import { getSchemaByModuleAndEntityRequest } from '../../../../../redux/stores/schemas/actions';
import { ISchemaReducer } from '../../../../../redux/stores/schemas/reducer';
import { httpPost } from '@core/http/requests';
import { displayMessage } from '../../../../../redux/stores/messages/reducers';
import { getSchemaFromShortListByModuleAndEntity } from '@core/helpers/schemaHelpers';

interface Props {
  record: DbRecordEntityTransform;
  alertMessage: (params: { body: string; type: string }) => void;
  getAssociations: (params: IGetRecordAssociations, cb?: any) => void;
  schemaReducer: ISchemaReducer;
  getSchema: (params: any, cb: any) => void;
}

const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;
const { CONTACT, WORK_ORDER } = SchemaModuleEntityTypeEnums;

const WorkOrderEmailActionButton: React.FC<Props> = (props: Props) => {
  const { record, alertMessage, getAssociations, schemaReducer, getSchema } = props;

  const [installConfirmationAlertOpen, setInstallConfirmationAlertOpen] = useState<boolean>(false);
  const [lateNotificationAlertOpen, setLateNotificationAlertOpen] = useState<boolean>(false);
  const [associatedContact, setAssociatedContact] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [workOrderSchema, setWorkOrderSchema] = useState<SchemaEntity | undefined>(undefined);
  const [isGettingContact, setIsGettingContact] = useState<boolean>(false);
  const [isSendingSMS, setIsSendingSMS] = useState<boolean>(false);

  // On component mount fetch the WO Schema and redux to state
  useEffect(() => {
    if (!workOrderSchema) {
      const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        FIELD_SERVICE_MODULE,
        WORK_ORDER,
      );
      if (shortlistSchema) {
        setWorkOrderSchema(shortlistSchema);
      } else {
        getSchema(
          {
            moduleName: FIELD_SERVICE_MODULE,
            entityName: WORK_ORDER,
          },
          (res: any) => {
            setWorkOrderSchema(res);
          },
        );
      }
    }
  }, []);

  const getAssociatedContact = (schema: SchemaEntity) => {
    setIsGettingContact(true);
    getAssociations(
      {
        recordId: record?.id,
        schema: schema,
        entities: [CONTACT],
      },
      (res: any) => {
        setIsGettingContact(false);
        const relatedContact = getFirstRelation(res.results, CONTACT);
        if (relatedContact) {
          setAssociatedContact(relatedContact);
        }
      },
    );
  };

  const sendOrderConfirmationEmail = () => {
    httpPost(
      `FieldServiceModule/v1.0/WorkOrder/${
        record ? record.id : null
      }/email/SENDGRID_WORK_ORDER_CONFIRMATION`,
      {},
    );
    setInstallConfirmationAlertOpen(false);
    alertMessage({
      body: 'Install confirmation Email sent successfully',
      type: 'success',
    });
  };

  const sendLateNotificationSMS = () => {
    if (associatedContact) {
      setIsSendingSMS(true);
      let newMessage: any = {};
      newMessage.messageTitle = 'WO Running Late Message';
      newMessage.toName = getProperty(associatedContact, 'FirstName');
      newMessage.toPhoneNumber = getProperty(associatedContact, 'Phone');
      newMessage.webhookUrl = 'https://api.prod.youfibre.com/NotificationModule/v1.0/sms/webhook';
      newMessage.message = `Hello ${getProperty(associatedContact, 'FirstName')},`;
      newMessage.message += `\nregretfully, your engineer is running behind schedule today. They'll still be attending as planned. Apologies for any inconvenience caused.`;
      newMessage.properties = {
        Category: 'WORK_ORDER_DELAY',
      };
      newMessage.associations = [
        {
          entity: record.entity,
          recordId: record?.id,
        },
        {
          entity: associatedContact.entity,
          recordId: associatedContact?.id,
        },
      ];
      httpPost(`NotificationModule/v1.0/sms`, newMessage)
        .then((res: any) => {
          setIsSendingSMS(false);
          setLateNotificationAlertOpen(false);
          alertMessage({
            body: 'Late notification SMS sent successfully',
            type: 'success',
          });
        })
        .catch((err: any) => {
          setIsSendingSMS(false);
          setLateNotificationAlertOpen(false);
          alertMessage({
            body: 'Error sending Late notification SMS',
            type: 'error',
          });
        });
    }
  };

  const menuItems = () => {
    return (
      <Menu>
        <MenuItem
          text="Send Install Confirmation"
          key="sendConfirmation"
          onClick={() => setInstallConfirmationAlertOpen(true)}
        />
        <MenuItem
          text="Send Running Late Notification"
          key="sendRunningLateNotification"
          disabled={!workOrderSchema}
          onClick={() => {
            getAssociatedContact(workOrderSchema!);
            setLateNotificationAlertOpen(true);
          }}
        />
      </Menu>
    );
  };

  return (
    <>
      {/* Send Late Notification Alert dialog */}
      <Alert
        icon="envelope"
        isOpen={lateNotificationAlertOpen}
        onConfirm={sendLateNotificationSMS}
        onCancel={() => setLateNotificationAlertOpen(false)}
        loading={isGettingContact || isSendingSMS}
        cancelButtonText="Cancel"
        intent="primary"
        confirmButtonText="Send"
        canEscapeKeyCancel
      >
        <Row>
          <Skeleton loading={isGettingContact}>
            <Col span={24}>
              <Typography.Text strong>Running Late SMS notification</Typography.Text>
            </Col>
            <Col span={24} style={{ marginTop: 15 }}>
              <span>Confirm message</span>
            </Col>
            <Col span={24} style={{ margin: '15px 0' }}>
              <Callout title="" style={{ fontStyle: 'italic' }}>
                <Row>
                  <Col span={24}>
                    <span>
                      Hello {getProperty(associatedContact, 'FirstName')}, regretfully, your
                      engineer is running behind schedule today. They'll still be attending as
                      planned. Apologies for any inconvenience caused.
                    </span>
                  </Col>
                </Row>
              </Callout>
            </Col>
          </Skeleton>
        </Row>
      </Alert>

      {/* Send Confirmation Alert dialog */}
      <Alert
        icon="envelope"
        isOpen={installConfirmationAlertOpen}
        onConfirm={sendOrderConfirmationEmail}
        onCancel={() => setInstallConfirmationAlertOpen(false)}
        cancelButtonText="Cancel"
        intent="primary"
        confirmButtonText="Send"
        canEscapeKeyCancel
      >
        <Row>
          <Col span={24}>
            <Typography.Text strong>Install Confirmation Email</Typography.Text>
          </Col>
          <Col span={24} style={{ marginTop: 15 }}>
            <span>
              Clicking submit will send an install confirmation email to:{' '}
              {getProperty(associatedContact, 'EmailAddress')}
            </span>
          </Col>
        </Row>
      </Alert>

      {/* Button Action Menu */}
      <Popover content={menuItems()} placement="bottom">
        <Button alignText="left" rightIcon="envelope" intent="primary" outlined />
      </Popover>
    </>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  getAssociations: (params: IGetRecordAssociations, cb?: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  getSchema: (params: any, cb: any) => dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(WorkOrderEmailActionButton);
