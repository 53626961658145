import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Descriptions, Row, Spin } from 'antd';
import { FC, useState } from 'react';
import './styles.scss';
import { Button, Section } from '@blueprintjs/core';
import { httpGet } from '@core/http/requests';

interface Props {
  record: DbRecordEntityTransform | undefined; // Order record
}

type ETFResponse = {
  paidInvoiceTotal: number;
  contractLength: number;
  terminationFee: number;
  contractTotal: number;
};

const OrderEarlyTerminationFeeCalculator: FC<Props> = (props: Props) => {
  const { record } = props;
  const [data, setData] = useState<ETFResponse | undefined>(undefined);
  const [isRequestingData, setIsRequestingData] = useState<boolean>(false);

  const getEarlyTerminationFee = async () => {
    try {
      setIsRequestingData(true);

      const res = await httpGet(`OrderModule/v1.0/orders/${record?.id}/early-termination-fee`);
      if (res) {
        setData(res.data?.data);
      }
      setIsRequestingData(false);
    } catch (e: any) {
      setIsRequestingData(false);
    }
  };

  const renderCardBody = (data: ETFResponse | undefined) => {
    if (isRequestingData) {
      return (
        <Row>
          <Col span={24} style={{ textAlign: 'center', padding: '25px 0' }}>
            <Spin />
          </Col>
        </Row>
      );
    } else if (!isRequestingData && !data) {
      return (
        <Row>
          <Col
            span={24}
            style={{
              textAlign: 'center',
              padding: '25px 0',
              borderRadius: 5,
              border: '1px solid #efefef',
            }}
          >
            <Button
              intent="primary"
              disabled={data !== undefined}
              loading={isRequestingData}
              onClick={() => getEarlyTerminationFee()}
            >
              Get ETF value
            </Button>
          </Col>
        </Row>
      );
    } else if (!isRequestingData && data) {
      return (
        <Row>
          <Col span={24}>
            <Descriptions bordered column={1} size="small">
              {/* Contract Length */}
              <Descriptions.Item label="Contract Length">
                {data?.contractLength !== null ? data?.contractLength + ' months' : '-'}
              </Descriptions.Item>

              {/* Contract Value Total */}
              <Descriptions.Item label="Contract Value Total">
                {data?.contractTotal !== null ? '£' + data?.contractTotal?.toFixed(2) : '-'}
              </Descriptions.Item>

              {/* Paid Invoice Total */}
              <Descriptions.Item label="Paid Invoice Total">
                {data?.paidInvoiceTotal !== null ? '£' + data?.paidInvoiceTotal?.toFixed(2) : '-'}
              </Descriptions.Item>

              {/* Termination Fee */}
              <Descriptions.Item label="Termination Fee">
                {data?.terminationFee !== null ? '£' + data?.terminationFee?.toFixed(2) : '-'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      );
    }
  };

  return record ? (
    <Row>
      <Col span={24} style={{ padding: 0 }}>
        <Section
          title="Early Termination Fee"
          compact
          className="early-termination-fee"
          rightElement={
            data !== undefined ? (
              <Button
                small
                disabled={isRequestingData}
                icon="refresh"
                text="Refresh"
                onClick={() => {
                  getEarlyTerminationFee();
                }}
              />
            ) : (
              <></>
            )
          }
        >
          {renderCardBody(data)}
        </Section>
      </Col>
    </Row>
  ) : (
    <></>
  );
};

export default OrderEarlyTerminationFeeCalculator;
