import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Col, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../../../../../redux/stores/schemas/actions';
import { getSchemaFromShortListByModuleAndEntityOrAPI } from '@core/helpers/schemaHelpers';
import RecordViewHeader from '../RecordViewHeader';

interface Props {
  record: DbRecordEntityTransform | undefined;
  schemaReducer: any;
  getSchema: Function;
}

const RecordView: FC<Props> = (props: Props) => {
  const { record, schemaReducer, getSchema } = props;

  const [isLoadingSchema, setIsLoadingSchema] = useState<boolean>(false);
  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);

  // When schema shortlist updates, try to fetch all necessary schemas.
  useEffect(() => {
    if (schemaReducer?.shortList && !schema && record) {
      getSchemas();
    }
  }, [schemaReducer?.shortList, record]);

  const getSchemas = async () => {
    try {
      const moduleName = record?.entity?.split(':')[0];
      const entityName = record?.entity?.split(':')[1];

      if (moduleName && entityName) {
        const parentSchema = await getSchemaFromShortListByModuleAndEntityOrAPI(
          schemaReducer.shortList,
          moduleName,
          entityName,
          getSchema,
        );

        setSchema(parentSchema);
      }
    } catch (error) {}
  };

  return (
    <Row>
      <Col span={24}>
        <RecordViewHeader isLoading={isLoadingSchema} record={record} />
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(RecordView);
