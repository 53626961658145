import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { httpPost } from '@core/http/requests';
import { displayMessage } from '../../../../../redux/stores/messages/reducers';
import { getOdinSchemaByRecord } from '@core/helpers/schemaHelpers';
import dayjs from 'dayjs';
import { Button, Dialog, DialogBody, DialogFooter } from '@blueprintjs/core';

const { Option } = Select;

interface Props {
  record: DbRecordEntityTransform;
  alertMessage: any;
}

interface IState {
  visible: boolean;
  isSubmitting: boolean;
  authorized: boolean;
  paymentMethod: string | undefined;
  externalRef: string | undefined;
  statusUpdatedAt: string | undefined;
  amount: number;
}

const initialState: IState = {
  visible: false,
  isSubmitting: false,
  authorized: false,
  paymentMethod: 'GOCARDLESS',
  externalRef: undefined,
  statusUpdatedAt: undefined,
  amount: 0,
};

class InvoiceTakePayment extends React.Component<Props> {
  state: IState = initialState;

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState(initialState);
  };

  handleOk = async () => {
    const { record, alertMessage } = this.props;

    const schema = await getOdinSchemaByRecord(record);

    this.setState({ isSubmitting: true });

    if (record && schema) {
      await httpPost(`BillingModule/v1.0/transactions/invoices/${record?.id}`, {
        externalRef: this.state.externalRef,
        paymentMethod: this.state.paymentMethod,
        amount: this.state.amount,
        properties: {
          StatusUpdatedAt: this.state.statusUpdatedAt,
        },
      })
        .then((res: any) => {
          this.setState({ isSubmitting: false });
          alertMessage({
            body: res.data?.message || 'payment transaction created',
            type: 'success',
          });
        })
        .catch((err: any) => {
          this.setState({ isSubmitting: false });
          const error = err.response ? err.response.data : undefined;
          alertMessage({
            body: (error && error.message) || 'error processing payment',
            type: 'error',
          });
        });

      this.setState({
        visible: false,
        isSubmitting: false,
      });
    }
  };

  // If the balance is 0 or less, disable the button
  renderPaymentButtonState() {
    return !(Number(getProperty(this.props.record, 'Balance')) > 0);
  }

  validateForm = () => {
    const { paymentMethod, authorized, statusUpdatedAt, externalRef } = this.state;

    // 1. Form has to be authorized and payment method has to be selected
    if (!authorized) {
      return false;
    }
    // 2. ExternalRef has to be entered for all payment methods except GOCARDLESS
    else if (paymentMethod !== 'GOCARDLESS' && !externalRef) {
      return false;
    }
    // 3. StatusUpdatedAt has to be entered for BANK_TRANSFER
    else if (paymentMethod === 'BANK_TRANSFER' && !statusUpdatedAt) {
      return false;
    }
    // Form is validated!
    else {
      return true;
    }
  };

  render() {
    const { record } = this.props;

    return (
      <>
        <Button
          intent="primary"
          outlined
          onClick={this.showModal}
          disabled={this.renderPaymentButtonState()}
        >
          Process Payment
        </Button>
        <Dialog
          title="Process Payment"
          isOpen={this.state.visible}
          onClose={this.handleCancel}
          canEscapeKeyClose={!this.state.isSubmitting}
          canOutsideClickClose={!this.state.isSubmitting}
          isCloseButtonShown={false}
        >
          <DialogBody>
            <Row>
              <Col span={24} style={{ paddingTop: 10 }}>
                <Form style={{ width: '100%' }} layout="vertical">
                  <Form.Item initialValue={this.state.paymentMethod}>
                    <Select
                      defaultValue={this.state.paymentMethod}
                      value={this.state.paymentMethod}
                      placeholder="Select a payment method"
                      style={{ width: '100%' }}
                      onChange={(val) =>
                        this.setState({
                          paymentMethod: val,
                          externalRef: undefined,
                        })
                      }
                    >
                      <Option value="BANK_TRANSFER">Bank Transfer</Option>
                      <Option value="CARD_PAYLINK">Card PayLink</Option>
                      <Option value="DCA_PAYMENT">DCA Payment</Option>
                      <Option value="GOCARDLESS">GoCardless</Option>
                      <Option value="PHONE_PAYMENT">Phone Payment</Option>
                      <Option value="CUSTOM_PAYMENT">Custom</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item>
                    <DatePicker
                      defaultValue={this.state.statusUpdatedAt || undefined}
                      format={'YYYY-MM-DD'}
                      style={{ width: '100%' }}
                      onChange={(val) =>
                        this.setState({
                          statusUpdatedAt: val ? dayjs(val).format('YYYY-MM-DD') : undefined,
                        })
                      }
                      placeholder={'Settled Date'}
                    />
                  </Form.Item>

                  {this.state.paymentMethod !== 'GOCARDLESS' && (
                    <Form.Item>
                      <Input
                        style={{ width: '100%' }}
                        placeholder={'Payment reference number'}
                        value={this.state.externalRef}
                        onChange={(e) => this.setState({ externalRef: e.target.value })}
                      />
                    </Form.Item>
                  )}
                  <Form.Item initialValue={Number(getProperty(record, 'Balance'))}>
                    <InputNumber
                      min={0}
                      style={{ width: '40%' }}
                      max={Number(getProperty(record, 'Balance'))}
                      value={this.state.amount || Number(getProperty(record, 'Balance'))}
                      onChange={(val) => this.setState({ amount: val })}
                    />
                  </Form.Item>

                  <Divider style={{ marginTop: 10, marginBottom: 15 }} />

                  <Form.Item label="Authorize Payment" style={{ marginBottom: 0, padding: 0 }}>
                    <Switch
                      checked={this.state.authorized}
                      value={this.state.authorized}
                      onClick={() => this.setState({ authorized: !this.state.authorized })}
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </DialogBody>
          <DialogFooter
            actions={[
              <Button
                key="cancel"
                text="Cancel"
                onClick={this.handleCancel}
                disabled={this.state.isSubmitting}
              />,
              <Button
                key="submit"
                text="Submit"
                intent="primary"
                loading={this.state.isSubmitting}
                disabled={!this.validateForm()}
                onClick={this.handleOk}
              />,
            ]}
          />
        </Dialog>
      </>
    );
  }
}

const mapState = () => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(InvoiceTakePayment);
