import { EyeOutlined } from '@ant-design/icons';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Button, Input, Layout } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSearch } from '@core/components/DataTable/helpers/search/useSearchV2';
import { VendorDashboardView, useVendorDashboard } from './hooks';
import { DashboardLineItem } from './hooks/types';
import { VendorDashboardPageHeader } from './PageHeader';
import { VendorDashboardQuickView } from './QuickView';

import './styles.css';
import { SubmissionStatusDrawer } from './SubmissionStatusDrawer';
import { VendorDashboardTable } from './Table';
import { getFilteredLineItems, getLineItemRowKey, getUniqueValues } from './utils';
import { MultiFilter } from './MultiFilter';
import { Card, Tab, Tabs } from '@blueprintjs/core';

const stripProjectTitle = (
  projectTitle: string,
  selectedExchange?: DbRecordEntityTransform
) => projectTitle.replace(`${selectedExchange?.title ?? ''} - `, '');

const getDebugColumns = () => {
  if (window.location.search !== '?debugColumns') return [];

  return [
    {
      title: 'FPE ID',
      key: 'fpe_id',
      dataIndex: 'fpe_id',
      // render: (text: string) => String(text),
    },
    {
      title: 'Parent',
      key: 'parent_fpe_id',
      dataIndex: 'parent_fpe_id',
      // render: (text: string) => String(text),
    },
    {
      title: 'AFP',
      key: 'afp_id',
      dataIndex: 'afp_id',
      render: (text: string) => <Link to={`/ProjectModule/ApplicationForPayment/${text}/approval`}>View on AFP</Link>,
    },
    {
      title: 'FPE Title',
      key: 'fpe_title',
      dataIndex: 'fpe_title',
    },

  ];
}

type GetTableColumnsParams = {
  selectedExchange?: DbRecordEntityTransform;
  filters?: Partial<Record<keyof DashboardLineItem, Object>>;
  service: ReturnType<typeof useVendorDashboard>["service"];
};

const getTableColumns = ({ selectedExchange, filters, service }: GetTableColumnsParams): ColumnsType<DashboardLineItem> => {
  return [
    ...getDebugColumns(),
    {
      title: 'Project',
      key: 'project_title',
      dataIndex: 'project_title',
      render: (text: string) => stripProjectTitle(text, selectedExchange),
      ...(filters?.project_title ? filters.project_title : {}),
    },
    {
      title: 'Contractor',
      key: 'contractor',
      dataIndex: 'contractor',
      render: (text: string) => text.toUpperCase(),
      ...(filters?.contractor ? filters.contractor : {}),
    },
    {
      title: 'PO #',
      key: 'po_number',
      dataIndex: 'po_number',
    },
    {
      title: 'Feature Type',
      key: 'feature_type',
      dataIndex: 'feature_type',
      ...(filters?.feature_type ? filters.feature_type : {}),
    },
    {
      title: 'Feature Id',
      key: 'qgis_feature_id',
      dataIndex: 'qgis_feature_id',
      ...(filters?.qgis_feature_id ? filters.qgis_feature_id : {}),
    },
    {
      title: 'Rate Code',
      key: 'rate_code',
      dataIndex: 'rate_code',
    },
    {
      title: 'Type',
      key: 'fpe_type',
      dataIndex: 'fpe_type',
      ...(filters?.fpe_type ? filters.fpe_type : {})
    },
    {
      title: 'QTY',
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Price',
      key: 'price',
      dataIndex: 'price',
    },
    {
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      ...(filters?.status ? filters.status : {})
    },
    {
      title: 'Rejection Reason',
      key: 'rejection_reason',
      dataIndex: 'rejection_reason',
      ...(filters?.rejection_reason ? filters.rejection_reason : {}),
    },
    {
      title: 'Rejection Response',
      key: 'rejection_response_comment',
      dataIndex: 'rejection_response_comment',
      render: (text, lineItem) => {
        if (lineItem.is_approved === 'false')
          return (
            <Input placeholder="Rejection Response" defaultValue={lineItem.rejection_response_comment ?? ''} onPressEnter={(evt) => {
              if (!lineItem.fpe_id) return;

              const input = evt.target as HTMLInputElement;
              service.updateRejectionResponseComment(lineItem.fpe_id, input.value);
            }}/>
          );
        else
          return text;
      },
    },
    {
      title: 'AFP',
      key: 'afp_record_number',
      dataIndex: 'afp_record_number',
    },
    {
      title: 'PN',
      key: 'pn_record_number',
      dataIndex: 'pn_record_number',
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: '',
      render: (_, lineItem) => (
        <Button
          key={`quickViewBtn-${lineItem.feature_id}`}
          icon={
            <EyeOutlined
              onClick={() => service.openFeatureQuickView(
                lineItem.feature_id,
                lineItem.feature_type,
                lineItem.qgis_feature_id
              )}
            />
          }
        />
      )
    },
  ]
}

const zeroTotals = { totalValue: 0, totalRejected: "0", totalUnBilled: "0", totalApproved: "0", totalSubmitted: "0" };

const VendorDashboardV4 = () => {
  const { state: { exchanges, lineItems, isTableLoading, submission, selectedView }, service } = useVendorDashboard();
  const selectedExchange = exchanges.data.find(exchange => getProperty(exchange, 'ExPolygonId') === exchanges.selected);

  // Building filters
  const textFilterHandler = (dataIndex: string) => (value: string) => {
    if (!value || value === '') {
      service.setLineItemFilter(dataIndex, []);
      return;
    }

    service.setLineItemFilter(dataIndex, [value]);
  };

  const uniqueContractors = getUniqueValues(lineItems.data?.lineItems ?? [], 'contractor');
  const contractorFilter = {
    filters: uniqueContractors.map(value => ({ text: value.toUpperCase(), value })),
    filterDropdown: (props: any) => <MultiFilter {...props} dataIndex='contractor' setLineItemFilter={service.setLineItemFilter} />,
    filteredValue: lineItems.filters.contractor ?? [],
  };

  const uniqueProjectTitles = getUniqueValues(lineItems.data?.lineItems ?? [], 'project_title')
  const projectFilter = {
    filters: uniqueProjectTitles.map(value => ({ text: stripProjectTitle(value, selectedExchange), value })),
    filterDropdown: (props: any) => <MultiFilter {...props} dataIndex='project_title' setLineItemFilter={service.setLineItemFilter} />,
    filteredValue: lineItems.filters.project_title ?? [],
  };

  const featureIdFilter = useSearch({
    dataIndex: 'qgis_feature_id',
    value: lineItems.filters.qgis_feature_id[0],
    onSearch: textFilterHandler('qgis_feature_id'),
  });

  const uniqueFeatureTypes = getUniqueValues(lineItems.data?.lineItems ?? [], 'feature_type').filter(Boolean);
  const featureTypeFilter = {
    filters: uniqueFeatureTypes.map(value => ({ text: value, value })),
    filterDropdown: (props: any) => <MultiFilter {...props} dataIndex='feature_type' setLineItemFilter={service.setLineItemFilter} />,
    filteredValue: lineItems.filters.feature_type,
  };

  const uniqueRejectionReasons = getUniqueValues(lineItems.data?.lineItems ?? [], 'rejection_reason');
  const rejectionReasonFilter = {
    filters: uniqueRejectionReasons.filter(Boolean).map(value => ({ text: value, value })),
    filterDropdown: (props: any) => <MultiFilter {...props} dataIndex='rejection_reason' setLineItemFilter={service.setLineItemFilter} />,
    filteredValue: lineItems.filters.rejection_reason,
  };

  const uniqueTypes = ['DEFAULT', 'ADJUSTMENT'];
  const typeFilter = {
    filters: uniqueTypes.map(value => ({ text: value, value })),
    filterDropdown: (props: any) => <MultiFilter {...props} dataIndex='fpe_type' setLineItemFilter={service.setLineItemFilter} />,
    filteredValue: lineItems.filters.fpe_type,
  };

  const statusFilter = {
    filters: [
      {
        text: 'Not Submitted',
        value: 'Not Submitted',
      },
      {
        text: 'Submitted',
        value: 'Submitted',
      },
      {
        text: 'Approved',
        value: 'Approved',
      },
      {
        text: 'Rejected',
        value: 'Rejected',
      },
      {
        text: 'Resubmitted',
        value: 'Resubmitted',
      }
    ],
    filterDropdown: (props: any) => <MultiFilter {...props} dataIndex='status' setLineItemFilter={service.setLineItemFilter} />,
    filteredValue: lineItems.filters.status,
  };

  // Exporting data
  const exportCsvHandler = () => {
    const filteredLineItems = getFilteredLineItems(lineItems.data?.lineItems ?? [], lineItems.filters);
    service.exportCsv(filteredLineItems, selectedExchange?.title?.replace(/ /g, '_'));
  }
  const selectedRowKeys: React.Key[] = lineItems.selectedLineItems.map(getLineItemRowKey);

  const isTabDisabled = !selectedExchange || isTableLoading || lineItems.status === 'loading';

  return (
    <Layout
      style={{
        padding: 8,
        border: '1px solid #dadada',
        background: '#fafafa',
        overflow: 'auto',
      }}
    >
      <VendorDashboardPageHeader
        exchanges={exchanges}
        selectedLineItems={lineItems.selectedLineItems}
        totals={['loading', 'error'].includes(lineItems.status) ? zeroTotals : lineItems.data ?? zeroTotals}
        service={service}
        exportProps={{
          canExport: lineItems.data ? lineItems.data.lineItems.length > 0 : false,
          onExportClick: exportCsvHandler
        }}
        selectedView={selectedView}
        loading={lineItems.status === 'loading'}
      />
      <Card style={{ marginTop: 10 }}>
        <Tabs
          id="VendorDashboardView"
          selectedTabId={selectedView}
          onChange={tabId => {
            service.setSelectedView(tabId as VendorDashboardView)
            service.resetLineItemFilters();
          }}
          animate
          large
          fill
        >
          <Tab
            disabled={isTabDisabled}
            id="pending_submissions"
            title="Pending Submissions"
            panel={
              <VendorDashboardTable
                // expandable
                loading={lineItems.status === 'loading'}
                globalLoader={isTableLoading}
                lineItems={lineItems}
                columns={getTableColumns({
                  selectedExchange,
                  filters: {
                    project_title: projectFilter,
                    contractor: contractorFilter,
                    qgis_feature_id: featureIdFilter,
                    feature_type: featureTypeFilter,
                    rejection_reason: rejectionReasonFilter,
                    status: statusFilter,
                    fpe_type: typeFilter,
                  },
                  service
                })}
                rowKey={lineItem => getLineItemRowKey(lineItem)}
                rowSelection={{
                  type: 'checkbox',
                  getCheckboxProps: lineItem => {
                    return {
                      disabled: lineItem.status !== 'Not Submitted',
                    };
                  },
                  onChange: (_selectedRowKeys, selectedRows, info) => {
                    if (info.type === 'all' && selectedRows.length) {
                      const filteredLineItems = getFilteredLineItems(lineItems.data?.lineItems ?? [], lineItems.filters);
                      const lineItemsToSelect = (filteredLineItems).filter(li => li.status === 'Not Submitted');

                      service.setSelectedLineItems(selectedRows.length < lineItems.selectedLineItems.length ? [] : lineItemsToSelect);
                      return;
                    }

                    service.setSelectedLineItems(selectedRows);
                  },
                  selectedRowKeys,
                }}
              />
            }
          />
          <Tab
            disabled={isTabDisabled}
            id="pending_review"
            title="Pending Review"
            panel={
              <VendorDashboardTable
                // expandable
                loading={lineItems.status === 'loading'}
                globalLoader={isTableLoading}
                lineItems={lineItems}
                columns={getTableColumns({
                  selectedExchange,
                  filters: {
                    project_title: projectFilter,
                    contractor: contractorFilter,
                    qgis_feature_id: featureIdFilter,
                    feature_type: featureTypeFilter,
                    rejection_reason: rejectionReasonFilter,
                    status: statusFilter,
                  },
                  service
                })}
                rowKey={lineItem => getLineItemRowKey(lineItem)}
                rowSelection={{
                  type: 'checkbox',
                  getCheckboxProps: lineItem => {
                    return {
                      disabled: lineItem.status !== 'Not Submitted',
                    };
                  },
                  onChange: (_selectedRowKeys, selectedRows, info) => {
                    if (info.type === 'all' && selectedRows.length) {
                      const filteredLineItems = getFilteredLineItems(lineItems.data?.lineItems ?? [], lineItems.filters);
                      const lineItemsToSelect = (filteredLineItems).filter(li => li.status === 'Not Submitted');

                      service.setSelectedLineItems(selectedRows.length < lineItems.selectedLineItems.length ? [] : lineItemsToSelect);
                      return;
                    }

                    service.setSelectedLineItems(selectedRows);
                  },
                  selectedRowKeys,
                }}
              />
            }
          />
          <Tab
            disabled={isTabDisabled}
            id="pending_rejections"
            title="Pending Rejections"
            panel={
              <VendorDashboardTable
                // expandable
                loading={lineItems.status === 'loading'}
                globalLoader={isTableLoading}
                lineItems={lineItems}
                columns={getTableColumns({
                  selectedExchange,
                  filters: {
                    project_title: projectFilter,
                    contractor: contractorFilter,
                    qgis_feature_id: featureIdFilter,
                    feature_type: featureTypeFilter,
                    rejection_reason: rejectionReasonFilter,
                    status: statusFilter,
                    fpe_type: typeFilter,
                  },
                  service
                })}
                rowKey={lineItem => getLineItemRowKey(lineItem)}
                rowSelection={{
                  type: 'checkbox',
                  getCheckboxProps: lineItem => {
                    return {
                      disabled: lineItem.status !== 'Not Submitted',
                    };
                  },
                  onChange: (_selectedRowKeys, selectedRows, info) => {
                    if (info.type === 'all' && selectedRows.length) {
                      const filteredLineItems = getFilteredLineItems(lineItems.data?.lineItems ?? [], lineItems.filters);
                      const lineItemsToSelect = (filteredLineItems).filter(li => li.status === 'Not Submitted');

                      service.setSelectedLineItems(selectedRows.length < lineItems.selectedLineItems.length ? [] : lineItemsToSelect);
                      return;
                    }

                    service.setSelectedLineItems(selectedRows);
                  },
                  selectedRowKeys,
                }}
              />
            }
          />
          <Tab
            disabled={isTabDisabled}
            id="all_fpes"
            title="All FPEs"
            panel={
              <VendorDashboardTable
                // expandable
                loading={lineItems.status === 'loading'}
                globalLoader={isTableLoading}
                lineItems={lineItems}
                columns={getTableColumns({
                  selectedExchange,
                  filters: {
                    project_title: projectFilter,
                    contractor: contractorFilter,
                    qgis_feature_id: featureIdFilter,
                    feature_type: featureTypeFilter,
                    rejection_reason: rejectionReasonFilter,
                    status: statusFilter,
                    fpe_type: typeFilter,
                  },
                  service
                })}
                rowKey={lineItem => getLineItemRowKey(lineItem)}
              />
            }
          />
        </Tabs>
      </Card>
      <VendorDashboardQuickView />
      <SubmissionStatusDrawer
        status={submission.status}
        onClose={service.closeSubmissionDrawer}
        selectedLineItems={lineItems.selectedLineItems}
        onSubmissionSuccess={(afp, lineItems) => {
          lineItems.map(lineItem => {
            service.updateUnsubmittedLineItemByTitle(lineItem.fpe_title, { afp_id: afp.id, status: 'Submitted' });
          });
          service.setSelectedLineItems(selectedLineItems => {
            const submittedLineItemTitles = lineItems.map(li => li.fpe_title);
            return selectedLineItems.filter(selected => !submittedLineItemTitles.includes(selected.fpe_title))
          });
          service.setSubmissionStatus('finished');
        }}
        onSubmissionFailed={() => {
          service.setSubmissionStatus('finished');
        }}
      />
    </Layout>
  );
};

export default VendorDashboardV4;
