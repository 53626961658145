import { DetailViewContext } from '@core/components/DetailViewContextProvider';
import DetailView from 'src/core/views/DefaultDetailView';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Layout } from 'antd';
import { FC, useContext } from 'react';
import RecordCard from 'src/core/components/RecordCard';

const { ORDER_MODULE, BILLING_MODULE } = SchemaModuleTypeEnums;
const { ORDER, INVOICE } = SchemaModuleEntityTypeEnums;

const BillingAdjustmentDetailView: FC = () => {
  const { record } = useContext(DetailViewContext);

  return (
    <Layout>
      <DetailView
        showCollaborators
        leftColumn={[
          <RecordCard
            borderless
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={ORDER_MODULE}
            associatedRecordEntityName={ORDER}
            visibleProperties={['ActiveDate']}
          />,
          <RecordCard
            borderless
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={BILLING_MODULE}
            associatedRecordEntityName={INVOICE}
            visibleProperties={['Status', 'Balance']}
          />,
        ]}
      />
    </Layout>
  );
};

export default BillingAdjustmentDetailView;
