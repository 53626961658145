import { FC, useState } from 'react';
import { Result } from 'antd';
import { Button } from '@blueprintjs/core';
import { useDispatch } from 'react-redux';

import { loginFromToken } from '@redux/stores/identity/actions';

import { Status } from './index';

type OrganizationSetupFinishedProps = {
  token?: string;
  status: Status
}

export const OrganizationSetupFinished: FC<OrganizationSetupFinishedProps> = ({
  token,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const signInHandler = () => {
    if (!token) return;

    setLoading(true);
    dispatch(
      loginFromToken(
        token,
        () => {
          setLoading(false);
          window.location.href = '/ControlPanelModule';
        }
      ),
    );
  };

  const extra = [];
  if (token) {
    extra.push(
      <Button
        intent="primary"
        onClick={signInHandler}
        loading={loading}
      >
        Sign-In
      </Button>
    );
  }

  return (
    <div className="organization-setup-finished" style={{}}>
      <Result
        style={{ marginTop: 50 }}
        status="success"
        title="Organization Setup Finished"
        subTitle="You can now sign-in as the admin user you just created and start using the system."
        extra={extra}
      />
    </div>
  );
};
