import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Menu } from 'antd';
import fileDownload from 'js-file-download';
import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getEmailDataRequest,
  previewEmailRequest,
  sendConfirmationEmail,
  SendgridEmailEntity,
} from '../../../../../redux/stores/email/actions';
import AssociationDataTable from 'src/core/components/AssociationDataTable';
import InvoiceTakePayment from '../../components/TakePayment';
import VoidInvoice from '../../components/VoidInvoice';
import { displayMessage } from '../../../../../redux/stores/messages/reducers';
import RecordCard from '@core/components/RecordCard';
import AddProduct from 'src/core/modules/ProductModule/components/AddProduct';
import DetailView from 'src/core/views/DefaultDetailView';
import { Alert, Button, Icon, MenuItem, Popover } from '@blueprintjs/core';
import InvoiceOverview from '../../components/InvoiceOverview';
import { DetailViewContext } from '@core/components/DetailViewContextProvider';

const { CRM_MODULE, BILLING_MODULE, ORDER_MODULE } = SchemaModuleTypeEnums;
const { CONTACT, ORDER, TRANSACTION, ADDRESS, BILLING_ADJUSTMENT, CREDIT_NOTE, BILLING_REQUEST } =
  SchemaModuleEntityTypeEnums;

const TEMPLATE_SENDGRID_INVOICE_NEW = 'SENDGRID_INVOICE_NEW';

interface Props {
  sendConfirmation: (path: string, body?: SendgridEmailEntity) => {};
  getEmailData: (path: string, cb: any, body?: SendgridEmailEntity) => {};
  previewEmail: (body: SendgridEmailEntity, cb: any) => {};
  alertMessage: (params: { body: string; type: string }) => void;
}

const InvoiceDetailView: React.FC<Props> = (props: Props) => {
  const { sendConfirmation } = props;
  const [isGeneratingPreview, setIsGeneratingPreview] = useState<boolean>(false);
  const [invoiceConfirmationModalVisible, setInvoiceConfirmationModalVisible] =
    useState<boolean>(false);
  const { record, schema } = useContext(DetailViewContext);

  const previewEmail = () => {
    const { getEmailData, previewEmail } = props;
    setIsGeneratingPreview(true);

    // get the email data
    getEmailData(
      `BillingModule/v1.0/invoices/${record?.id}/email-data/${TEMPLATE_SENDGRID_INVOICE_NEW}`,
      (resp: any) => {
        const emailData = resp?.results?.data;
        if (emailData) {
          // request the preview
          previewEmail(emailData, (previewResp: any) => {
            setIsGeneratingPreview(false);

            if (previewResp?.results) {
              // try to open preview in a new tab
              const newWindow = window.open();
              if (newWindow) {
                newWindow.document.write(previewResp.results);
                newWindow.document.close();
                newWindow.document.title = `Invoice ${record?.recordNumber} preview`;
                newWindow.opener = null;
              } else {
                // otherwise download the preview as a html file
                fileDownload(previewResp.results, `preview_${record?.recordNumber}.html`);
              }
            }
          });
        } else {
          setIsGeneratingPreview(false);
        }
      },
    );
  };

  // const handleRecalculate = (record: DbRecordEntityTransform) => {
  //   const { alertMessage } = props;
  //   httpPost(`BillingModule/v1.0/invoices/calculate/${record.id}`, undefined, {
  //     recalculateItems: true,
  //   })
  //     .then((res) => {
  //       const orderId = res.data?.data?.id;
  //       if (orderId) {
  //         alertMessage({ body: 'invoice re-calculated', type: 'success' });
  //         history.push(`/BillingModule/Invoice/${orderId}`);
  //       } else {
  //         alertMessage({ body: 'error re-calculating invoice', type: 'error' });
  //       }
  //     })
  //     .catch((err) => {
  //       const error = err.response ? err.response.data : undefined;
  //       alertMessage({
  //         body: (error && error.message) || 'error re-calculating invoice',
  //         type: 'error',
  //       });
  //     });
  // };

  const menuItems = () => {
    return (
      <Menu>
        <MenuItem key={1} text="Preview Email" onClick={() => previewEmail()} />
        <MenuItem
          key={2}
          text="Send Invoice Confirmation"
          onClick={() => setInvoiceConfirmationModalVisible(true)}
        />
      </Menu>
    );
  };

  return (
    <div>
      {/* Add Product Drawer */}
      <AddProduct />

      {/* Invoice Confirmation Modal */}
      <Alert
        intent="primary"
        onCancel={() => setInvoiceConfirmationModalVisible(false)}
        isOpen={invoiceConfirmationModalVisible}
        cancelButtonText="Cancel"
        onConfirm={() =>
          sendConfirmation(
            `BillingModule/v1.0/invoices/${record.id}/email/${TEMPLATE_SENDGRID_INVOICE_NEW}`,
          )
        }
      >
        <p>Are you sure you want to send the invoice confirmation?</p>
      </Alert>

      {/* Email Preview Modal */}
      <Alert intent="primary" isOpen={isGeneratingPreview} loading={isGeneratingPreview}>
        <p>Generating Preview...</p>
      </Alert>

      <DetailView
        showCollaborators
        showCommunicationHistory
        defaultTabKey="Summary"
        customTabs={[
          {
            key: 'Summary',
            tab: 'Summary',
          },
        ]}
        customTabBodies={{
          Summary: (
            <>
              <InvoiceOverview record={record} />
              <AssociationDataTable
                collapsedByDefault
                title={TRANSACTION}
                record={record}
                moduleName={BILLING_MODULE}
                entityName={TRANSACTION}
                showAllRecords
              />
              <AssociationDataTable
                collapsedByDefault
                title={BILLING_ADJUSTMENT}
                record={record}
                moduleName={BILLING_MODULE}
                entityName={BILLING_ADJUSTMENT}
                showAllRecords
              />
              <AssociationDataTable
                collapsedByDefault
                title={CREDIT_NOTE}
                record={record}
                moduleName={BILLING_MODULE}
                entityName={CREDIT_NOTE}
                showAllRecords
              />
              <AssociationDataTable
                collapsedByDefault
                title={BILLING_REQUEST}
                record={record}
                moduleName={BILLING_MODULE}
                entityName={BILLING_REQUEST}
                showAllRecords
              />
            </>
          ),
        }}
        leftColumn={[
          <RecordCard
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={CRM_MODULE}
            associatedRecordEntityName={CONTACT}
            visibleProperties={['EmailAddress', 'Phone', 'Mobile']}
          />,
          <RecordCard
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={CRM_MODULE}
            associatedRecordEntityName={ADDRESS}
            visibleProperties={['Type', 'SalesStatus']}
          />,
          <RecordCard
            showRecordStage
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={ORDER_MODULE}
            associatedRecordEntityName={ORDER}
            visibleProperties={['ContractStartDate', 'ContractEndDate']}
          />,
        ]}
        headerExtras={[
          <InvoiceTakePayment record={record} />,
          <VoidInvoice schema={schema} record={record} />,
          <Popover content={<Menu>{menuItems()}</Menu>} placement="bottom">
            <Button
              alignText="left"
              intent="primary"
              outlined
              icon={<Icon icon="envelope" />}
              text="Email"
            />
          </Popover>,
        ]}
      />
    </div>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  sendConfirmation: (path: string, body?: SendgridEmailEntity) =>
    dispatch(sendConfirmationEmail(path, body)),
  getEmailData: (path: string, cb: any, body?: SendgridEmailEntity) =>
    dispatch(getEmailDataRequest(path, cb, body)),
  previewEmail: (body: SendgridEmailEntity, cb: any) => dispatch(previewEmailRequest(body, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default withRouter(connect(mapState, mapDispatch)(InvoiceDetailView));
