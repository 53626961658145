import React, { FC, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';

import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getAllRelations,
  getFirstRelation,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';

import { IdentityUserReducer } from '../../../../../redux/stores/identityUser/reducer';
import { RecordDataTable } from 'src/core/components/DataTable';
import { useSearch } from '@core/components/DataTable/helpers/search/useSearch';
import { EditablePropertiesState } from '@core/components/DataTable/hooks/useEditableProperties';
import { TableRowAction } from '@core/components/DataTable/types';
import RecordCard from 'src/core/components/RecordCard';
import RecordQuickViewDrawer from 'src/core/components/RecordQuickViewDrawer';
import { useRecordAssociations } from '@core/hooks/useRecordAssociations';
import { updateRecordByIdRequest } from '../../../../../redux/stores/records/actions';
import { IRecordReducer } from '../../../../../redux/stores/records/reducer';
import { getSchemaByModuleAndEntityRequest } from '../../../../../redux/stores/schemas/actions';
import {
  IOpenRecordDrawer,
  openRecordDrawer,
} from '../../../../../redux/stores/userInterface/actions';
import { hasPermissions } from '../../../../../core/helpers/rbacRules';
import { DISPLAY_MESSAGE } from '../../../../../redux/stores/messages/reducers';
import { DetailViewContext } from '../../../../../core/components/DetailViewContextProvider';
import DetailView from '../../../../../core/views/DefaultDetailView';
import { CreateAdjustment } from '@netomnia/modules/ProjectModule/views/ProjectModuleWorkListDetailView/CreateAdjustment';
import { AFPPreview } from '../../components/AFPPreview';
import { useRequest } from '../../../../../core/hooks/useRequest';
import { httpGet } from '../../../../../core/http/requests';

const CREATE_ADJUSTMENT_PERMISSION = 'projectmodule.featurepriceentry.create';
const { PROJECT_MODULE } = SchemaModuleTypeEnums;
const { PROJECT } = SchemaModuleEntityTypeEnums;

interface Props {
  recordReducer: IRecordReducer;
  userReducer: IdentityUserReducer;
  displayMessage: (message: { body: string; type: 'success' | 'error' }) => void;
  openDrawer: (params: IOpenRecordDrawer) => void;
}

const WorkListDetailView: FC<Props> = (props: Props) => {
  const { recordReducer, userReducer, openDrawer } = props;
  const { record } = useContext(DetailViewContext);
  const getSchema = useRequest(getSchemaByModuleAndEntityRequest);
  const updateRecord = useRequest(updateRecordByIdRequest);
  const [associatedProject, setAssociatedProject] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );

  const [quickView, setQuickView] = useState<{
    id: string | undefined;
    showDrawer: boolean;
  }>({
    id: undefined,
    showDrawer: false,
  });

  const [selectedFeature, setSelectedFeature] = React.useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [workItems, setWorkItems] = React.useState<DbRecordEntityTransform[]>([]);
  const [workItemsAdjustments, setWorkItemsAdjustments] = React.useState<DbRecordEntityTransform[]>(
    [],
  );

  // Load WorkList -> Project record association
  useEffect(() => {
    if (record && !associatedProject) {
      httpGet(
        `${PROJECT_MODULE}/v1.0/db-associations/WorkList/${record.id}/one-relation?entity=${PROJECT}`,
      )
        .then((res: any) => {
          const project = getFirstRelation(res.data, PROJECT);
          project && setAssociatedProject(project);
        })
        .catch((err) => {
          console.error('Error loading associated Project:', err);
        });
    }
  }, [record]);

  const addWorkItemAdjustment = async (feature: DbRecordEntityTransform) => {
    setSelectedFeature(feature);
  };

  const viewRecord = async (feature: DbRecordEntityTransform) => {
    openDrawer({
      recordId: feature?.id,
      moduleName: feature?.entity?.split(':')[0] || '',
      entityName: feature?.entity?.split(':')[1] || '',
    });
  };

  useRecordAssociations(
    record,
    [SchemaModuleEntityTypeEnums.FEATURE, 'WorkList'],
    ({ results }: any) => {
      const features = getAllRelations(results, SchemaModuleEntityTypeEnums.FEATURE) ?? [];
      setWorkItems(features);
    },
  );

  const { refetch: refetchAdjustments } = useRecordAssociations(
    record,
    ['FeaturePriceEntry', 'WorkList', 'ApplicationForPayment', 'Feature'],
    ({ results }: any) => {
      const fpes = getAllRelations(results, 'FeaturePriceEntry') ?? [];
      setWorkItemsAdjustments(fpes);
    },
  );

  const getColumnSearchProps = useSearch();

  const workItemRowActions: TableRowAction[] = [
    {
      title: 'View',
      onClick: ({ rowRecord }: any) => {
        return viewRecord(rowRecord);
      },
    },
    {
      title: 'Map',
      onClick: (record) =>
        window.open(
          `/ProjectModule/Map/${record.type}/${getProperty(record?.rowRecord, 'ExternalRef')}`,
          '_blank',
        ),
    },
  ];

  if (hasPermissions(userReducer, [CREATE_ADJUSTMENT_PERMISSION]))
    workItemRowActions.push({
      title: 'Adjustment',
      onClick: (record) => addWorkItemAdjustment(record.rowRecord),
    });

  const updateAdjustments = async (values: EditablePropertiesState) => {
    const fpeSchema = await getSchema({
      moduleName: 'ProjectModule',
      entityName: 'FeaturePriceEntry',
    });

    const recordIds = Object.keys(values);
    for (const recordId of recordIds) {
      const properties = values[recordId];
      if ('Quantity' in properties) {
        const fpe = workItemsAdjustments.find((adj) => adj.id === recordId);
        if (fpe) {
          properties['Total'] = (
            Number(properties.Quantity) * Number(getProperty(fpe, 'Price'))
          ).toFixed(2);
        }
      }

      await updateRecord({
        schema: fpeSchema,
        recordId,
        createUpdate: {
          schemaId: fpeSchema.id,
          type: 'ADJUSTMENT',
          properties,
        },
      }).catch(() => {});
    }

    refetchAdjustments();
  };

  return (
    <Layout>
      {quickView?.id && quickView.id !== '' && (
        <RecordQuickViewDrawer
          recordId={quickView.id}
          moduleName={SchemaModuleTypeEnums.PROJECT_MODULE}
          entityName={'Feature'}
          visible={quickView.showDrawer && !!quickView?.id}
          onClose={() =>
            setQuickView({
              id: undefined,
              showDrawer: false,
            })
          }
        />
      )}

      <DetailView
        showCollaborators
        defaultTabKey={'WorkItems'}
        customTabs={[
          {
            key: 'WorkItems',
            tab: 'Work Items',
          },
          {
            key: 'Build56',
            tab: 'Build 5-6',
          },
          {
            key: 'PendingSubmissions',
            tab: 'Pending Submissions',
          },
        ]}
        customTabBodies={{
          WorkItems: (
            <div>
              <div className="association-data-table-wrapper">
                <RecordDataTable
                  title="Work Items"
                  dataSource={workItems}
                  loading={recordReducer.isUpdating}
                  rowActions={workItemRowActions}
                  customColumnAttributes={{
                    'properties.ExternalRef': getColumnSearchProps('properties.ExternalRef'),
                  }}
                />
                <br />
                <RecordDataTable
                  title={'Adjustments'}
                  dataSource={workItemsAdjustments}
                  editableProperties={['Quantity', 'Reason', 'ReasonDescription', 'RFCNumber']}
                  onSaveEditableProperties={updateAdjustments}
                  loading={recordReducer.isUpdating}
                  disableSaveChanges={
                    !hasPermissions(userReducer, ['projectmodule.featurepriceentry.update'])
                  }
                  customColumnAttributes={{
                    'properties.QGISFeatureId': getColumnSearchProps('properties.QGISFeatureId'),
                  }}
                  cardExtraActions={
                    <>
                      {hasPermissions(userReducer, [CREATE_ADJUSTMENT_PERMISSION]) && (
                        <CreateAdjustment
                          workListId={record?.id}
                          project={associatedProject}
                          feature={selectedFeature}
                          onClose={() => {
                            setSelectedFeature(undefined);
                            refetchAdjustments();
                          }}
                        />
                      )}
                    </>
                  }
                />
              </div>
            </div>
          ),
          Build56: <AFPPreview workList={record} todoOnly />,
          PendingSubmissions: <AFPPreview workList={record} />,
        }}
        leftColumn={[
          <RecordCard
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={SchemaModuleTypeEnums.PROJECT_MODULE}
            associatedRecordEntityName={SchemaModuleEntityTypeEnums.PROJECT}
            visibleProperties={[
              'Contractor',
              'PurchaseOrderNumber',
              'EstimatedStart',
              'EstimatedEnd',
            ]}
          />,
        ]}
      />
    </Layout>
  );
};

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
  displayMessage: (message: { body: string; type: 'success' | 'error' }) =>
    dispatch({
      type: DISPLAY_MESSAGE,
      message,
    }),
});

export default withRouter(connect(mapState, mapDispatch)(WorkListDetailView));
