import { Classes, Icon, Popover } from '@blueprintjs/core';

import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import React, { useState } from 'react';
import { FilterOption } from './types';

type Props = {
  label: string;
  renderOptions: () => React.ReactElement;
  hasFilters: boolean;
  onClose?: any;
  disabled?: boolean;
};
export const ColumnFilter: React.FC<Props> = (props: Props) => {
  const { label, renderOptions, hasFilters, onClose, disabled } = props;

  const color = hasFilters ? 'blue' : 'gray';
  return (
    <>
      {label}
      <Popover
        popoverClassName={Classes.POPOVER_CONTENT}
        position="bottom"
        content={renderOptions()}
        onClosing={onClose}
        disabled={disabled}
      >
        <Icon
          icon="filter"
          size={10}
          color={color}
          style={{
            position: 'relative',
            top: -3,
            marginLeft: 5,
            cursor: 'pointer',
            opacity: disabled ? 0.5 : 1,
          }}
        />
      </Popover>
    </>
  );
};

export const getAssignedTeamOptions = (records: DbRecordEntityTransform[]) => {
  const options: { [key: string]: FilterOption } = {};

  records.forEach((record) => {
    const label = getProperty(record, 'TeamName');
    const value = getProperty(record, 'TeamId');
    // D19-2902 - Fixed issue with empty team values
    if (!value || !label) return;
    options[label] = {
      label,
      value,
    };
  });

  return Object.values(options).sort((a, b) => a.label?.localeCompare(b?.label));
};

export const getAssignedOwnerOptions = (records: DbRecordEntityTransform[]) => {
  const options: { [key: string]: FilterOption } = {};

  records.forEach((record) => {
    const label = getProperty(record, 'OwnerName');
    const value = getProperty(record, 'OwnerId');
    options[label] = {
      label,
      value,
    };
  });

  return Object.values(options).sort((a, b) => a.label?.localeCompare(b?.label));
};
export const getAssignedEngineerOptions = (records: DbRecordEntityTransform[]) => {
  const options: { [key: string]: FilterOption } = {};

  records.forEach((record) => {
    const label = getProperty(record, 'EngineerName');
    const value = getProperty(record, 'EngineerId');
    options[label] = {
      label,
      value,
    };
  });

  return Object.values(options).sort((a, b) => a.label?.localeCompare(b?.label));
};

type UseTableFilterParams = {
  options: FilterOption[];
};

export const useMultiSelectFilter = ({ options }: UseTableFilterParams) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  return {
    options,
    selectedValues,
    setSelectedValues,
  };
};

export const getClassNameForRemediationRow = (wo: DbRecordEntityTransform) => {
  let className = '';

  if (getProperty(wo, 'Status') === 'ON_HOLD') {
    className = 'on-hold-row';
  }

  if (getProperty(wo, 'HasActiveOrder') === 'true') {
    className = 'active-order-row';
  }

  return className;
};
