import { Button, Callout, IconName, Intent } from '@blueprintjs/core';
import { IGetSchemaById } from '@d19n/temp-fe-d19n-models/dist/rabbitmq/rabbitmq.interfaces';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import CoreForm from '@core/components/Forms/CoreForm';
import { getSchemaActionVersion } from '@core/components/Forms/helpers';
import { closeRecordForm, initializeRecordForm } from '@redux/stores/form/actions';
import { getSchemaByIdRequest } from '@redux/stores/schemas/actions';
import { httpGet } from '@core/http/requests';
import { hasPermissions } from '@core/helpers/rbacRules';
import { isSchemaActionPropertyFilterMatching } from '../helpers';

interface Props {
  step: any;
  isNextDisabled?: Function;
  onSuccess?: (record: any) => void;
  closeForm: () => void;
  initializeForm: (params: any) => void;
  getSchemaById: (payload: any, cb: any) => void;
  sourceRecord?: DbRecordEntityTransform;
  userReducer: any;
}

const uuid = uuidv4();
type TError = {
  title: string;
  message: string;
  icon?: IconName;
  intent?: Intent;
};

const SchemaActionFlowStep: React.FC<Props> = (props: Props) => {
  const {
    step,
    isNextDisabled,
    onSuccess,
    closeForm,
    initializeForm,
    getSchemaById,
    sourceRecord,
    userReducer,
  } = props;
  const [schemaActions, setSchemaActions] = useState<SchemaActionEntity[]>([]);
  const [selectedSchemaAction, setSelectedSchemaAction] = useState<SchemaActionEntity | undefined>(
    undefined,
  );
  const [error, setError] = useState<TError | undefined>(undefined);

  // When step changes / comes into component, fetch schema actions
  useEffect(() => {
    if (step && step.schemaActions) {
      setError(undefined);
      getSchemaActionsByNames(step.schemaActions);
    }
  }, [step]);

  // Get schema actions by name(s)
  const getSchemaActionsByNames = (names: string[]) => {
    if (names.length > 0) {
      httpGet(`SchemaModule/v1.0/schemas-actions`).then((res: any) => {
        let filteredActions =
          res.data?.data?.filter((action: any) => names.includes(action.name)) || [];

        // Filter actions based on property filters, if set into schema action
        filteredActions = filteredActions.filter((action: SchemaActionEntity) => {
          const propertyFilters = action.definition?.propertyFilters;
          if (propertyFilters && propertyFilters?.length! > 0) {
            return isSchemaActionPropertyFilterMatching(sourceRecord?.properties, propertyFilters);
          } else {
            return action;
          }
        });

        // If there's only 1 action, initialize it immediately
        if (filteredActions.length === 1) {
          initializeAction(filteredActions[0]);
        }
        // Else, show a choice to select from
        else if (filteredActions.length > 1) {
          setSchemaActions(filteredActions);
        } else if (filteredActions.length === 0) {
          setError({
            title: 'No Schema Actions Found',
            message: 'No schema actions could be retrieved for this step.',
            intent: Intent.WARNING,
          });
        }
      });
    }
  };

  const renderSchemaActionsSelection = () => {
    return schemaActions.map((action: SchemaActionEntity) => {
      const version = getSchemaActionVersion(action);
      const Definition = action.definition;

      let actionLabel: string;

      if (version === 1 && Definition?.actionLabel) {
        actionLabel = Definition?.actionLabel;
      } else if (version === 2 && Definition?.settings?.actionLabel) {
        actionLabel = Definition?.settings?.actionLabel;
      } else {
        actionLabel = action.name;
      }

      return (
        <Col span={isMobile ? 24 : 12} key={action.id + 'col'}>
          <Button
            large
            key={action.id + 'button'}
            intent="primary"
            fill
            style={{ marginBottom: 10, borderRadius: 5 }}
            onClick={() => {
              initializeAction(action);
            }}
            text={actionLabel}
          />
        </Col>
      );
    });
  };

  const initializeAction = (schemaAction: SchemaActionEntity) => {
    closeForm();

    let schemaActionPermissions: string[] =
      schemaAction.permissions?.map((permission) => permission.name) || [];

    // If permissions are set, check if user has them and throw an error if not
    if (
      schemaActionPermissions.length > 0 &&
      !hasPermissions(userReducer, schemaActionPermissions)
    ) {
      setError({
        title: 'Missing Permissions',
        message: 'You do not have permissions to perform this action. Please contact your admin.',
        icon: 'key',
        intent: Intent.DANGER,
      });
    }
    // No permissions, or user has them. Initialize the form.
    else {
      setSelectedSchemaAction(schemaAction);
      getSchemaById({ schemaId: schemaAction.schemaId }, (schema: any) => {
        // If a schema action has a type, set it to form reducer
        const type: SchemaTypeEntity | undefined =
          schema?.types?.find((type: SchemaTypeEntity) => type.id === schemaAction.schemaTypeId)
            ?.name || undefined;

        initializeForm({
          formUUID: uuid,
          title: schemaAction.name,
          showFormModal: true,
          isCreateReq: schemaAction.isCreate,
          isUpdateReq: schemaAction.isUpdate,
          schema: schema!,
          sections: [{ name: schema?.name, schema: schema }],
          schemaActionId: schemaAction?.id,
          selected: schemaAction.isUpdate ? sourceRecord : null,
          selectedFlowRecord: sourceRecord,
          recordType: type,
          // Once we introduce proper typing to form reducer, we can use
          // custom object to pass custom information to the form as we see fit.
          custom: {
            linkType: step?.linkType || null,
            schemaAction: schemaAction,
          },
        });
      });
    }
  };

  const getSelectedSchemaActionName = (schemaAction: SchemaActionEntity) => {
    if (schemaAction) {
      const version = getSchemaActionVersion(schemaAction);
      const Definition = schemaAction.definition;

      let actionLabel: string = '';

      if (version === 1 && Definition?.actionLabel) {
        actionLabel = Definition?.actionLabel;
      } else if (version === 2 && Definition?.settings?.actionLabel) {
        actionLabel = Definition?.settings?.actionLabel;
      } else {
        actionLabel = schemaAction.name;
      }

      return actionLabel;
    } else {
      return '';
    }
  };

  return (
    <Row>
      {/* Show Error */}
      {error && (
        <Col span={24}>
          <Callout title={error.title} icon={error.icon} intent={error.intent}>
            {error.message}
          </Callout>
        </Col>
      )}

      <Col span={24}>
        {/* If there are more than 1 schema actions, show a selector */}
        {!selectedSchemaAction && schemaActions.length > 1 && !error && (
          <Row gutter={8}>
            <Col span={24} style={{ marginBottom: 15 }}>
              <Callout title="Select action">Select one form from the list below.</Callout>
            </Col>
            {renderSchemaActionsSelection()}
          </Row>
        )}
      </Col>

      {/* Show Schema Action name and back button if needed */}
      {selectedSchemaAction && !error && (
        <Col span={24} style={{ marginBottom: 15 }}>
          <Row align="middle">
            <Col span={18}>
              <span style={{ fontSize: '1.3em', fontWeight: 600 }}>
                {getSelectedSchemaActionName(selectedSchemaAction)}
              </span>
            </Col>
            {schemaActions.length > 1 && (
              <Col span={6}>
                <Button
                  intent="primary"
                  fill
                  onClick={() => {
                    setSelectedSchemaAction(undefined);
                    closeForm();
                  }}
                  style={{ borderRadius: 5 }}
                  icon="caret-left"
                  text="Return to list"
                />
              </Col>
            )}
          </Row>
        </Col>
      )}

      {!error && (
        <Col span={24}>
          <CoreForm
            type="EMBEDDED"
            formUUID={uuid}
            isCreateRecord={true}
            showFormActions={false}
            isNextDisabled={isNextDisabled}
            onSubmitEvent={(e: any) => {
              onSuccess && onSuccess(e);
            }}
          />
        </Col>
      )}
    </Row>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});
const mapDispatch = (dispatch: any) => ({
  getSchemaById: (payload: IGetSchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  closeForm: () => dispatch(closeRecordForm()),
});

export default connect(mapState, mapDispatch)(SchemaActionFlowStep);
