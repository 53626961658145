import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  Divider,
  InputGroup,
  Label,
  MenuItem,
} from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { getOdinSchemaById } from '@core/helpers/schemaHelpers';
import { httpGet, httpPost, httpPut } from '@core/http/requests';
import { PipelineStageEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/stage/pipeline.stage.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import { displayMessage } from '@redux/stores/messages/reducers';
import { ISchemaReducer } from '@redux/stores/schemas/reducer';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

export type TViewConfig = {
  id?: string;
  name: string;
  description: string;
  schemaId: string;
  schemaTypeId: string | undefined;
  stageId: string | undefined;
};

interface Props {
  mode: 'EDIT' | 'CREATE';
  config?: TViewConfig;
  schemaReducer: ISchemaReducer;
  onUpdate?: (config: TViewConfig) => void;
  onCreate?: (config: TViewConfig) => void;
  alertMessage: (params: { body: string; type: string }) => void;
  buttonProps?: any;
  defaultSchemaId?: string;
}

interface ISchemaMenuItem {
  key: string;
  name: string;
  value: string;
  entityName: string;
  moduleName: string;
}

interface ISchemaTypeMenuItem {
  key: string;
  name: string;
}

interface IStageMenuItem {
  key: string;
  name: string;
}

const ViewConfigEditCreateForm: React.FC<Props> = (props: Props) => {
  const {
    config,
    mode,
    schemaReducer,
    onUpdate,
    onCreate,
    alertMessage,
    buttonProps,
    defaultSchemaId,
  } = props;

  // State
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [allSchemas, setAllSchemas] = useState<any[]>([]);
  const [schemaTypes, setSchemaTypes] = useState<SchemaTypeEntity[]>([]);
  const [schemaSearchQuery, setSchemaSearchQuery] = useState<string>('');
  const [stages, setStages] = useState<PipelineStageEntity[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);

  // Form values
  const [formSchemaId, setFormSchemaId] = useState<string | undefined>(undefined);
  const [formSchemaTypeId, setFormSchemaTypeId] = useState<string | undefined>(undefined);
  const [formDescription, setFormDescription] = useState<string | undefined>(undefined);
  const [formName, setFormName] = useState<string | undefined>(undefined);
  const [formStage, setFormStage] = useState<string | undefined>(undefined);

  // Get all Schemas from the schema reducer list. We use this for select dropdown
  useEffect(() => {
    if (isOpen && schemaReducer.list?.length! > 0) {
      const schemas = schemaReducer.list.map((schema: SchemaEntity) => {
        return {
          id: schema.id,
          name: schema.name,
          entityName: schema.entityName,
          moduleName: schema.moduleName,
        };
      });
      setAllSchemas(schemas);
    }
  }, [isOpen, schemaReducer.list]);

  // In edit mode, set all values from the schema action
  useEffect(() => {
    // Edit mode
    if (isOpen && mode === 'EDIT') {
      if (config?.schemaId) {
        setFormSchemaId(config.schemaId);
      }
      if (config?.schemaTypeId) {
        setFormSchemaTypeId(config.schemaTypeId);
      }

      if (config?.description) {
        setFormDescription(config.description);
      }

      if (config?.name) {
        setFormName(config.name);
      }

      if (config?.stageId) {
        setFormStage(config.stageId);
      }
    }
  }, [config, isOpen, mode]);

  // Set default schema id on modal open in CREATE mode
  useEffect(() => {
    if (defaultSchemaId && isOpen && mode === 'CREATE') {
      setFormSchemaId(props.defaultSchemaId);
    }
  }, [defaultSchemaId, mode, isOpen]);

  // When a schema is selected, get the schema types
  useEffect(() => {
    if (formSchemaId) {
      getSchemaTypesBySchemaId(formSchemaId);
    }
  }, [formSchemaId]);

  // When both schema and schema type are selected, get the pipeline
  useEffect(() => {
    if (formSchemaId) {
      getPipeline(formSchemaId, formSchemaTypeId);
    }
  }, [formSchemaId, formSchemaTypeId, schemaTypes]);

  const onClose = () => {
    setIsOpen(false);
    setFormSchemaId(undefined);
    setFormSchemaTypeId(undefined);
    setFormDescription(undefined);
    setFormStage(undefined);
    setSchemaSearchQuery('');
    setFormName(undefined);
    setSchemaTypes([]);
    setStages([]);
  };

  const getPipeline = async (schemaId: string, typeId?: string) => {
    try {
      const schema = await getOdinSchemaById(schemaId);
      const type = schemaTypes.find((t: SchemaTypeEntity) => t.id === typeId);

      if (schema) {
        const res = await httpGet(
          `SchemaModule/v1.0/pipelines/bymodule/${schema?.moduleName}/${schema?.entityName}${
            type?.name ? `?schemaType=${type.name}` : '?schemaType='
          }`,
        );

        const pipelines = res?.data?.data || [];

        if (pipelines.length > 0) {
          const stages = pipelines[0]?.stages || [];
          if (stages.length > 0) {
            setStages(stages);
          }
        }
      }
    } catch (error) {}
  };

  const getSchemaTypesBySchemaId = async (schemaId: string) => {
    try {
      const schema = await getOdinSchemaById(schemaId);
      if (schema && schema?.types?.length! > 0) {
        setSchemaTypes(schema.types);
      } else if (schema && schema?.types?.length! === 0) {
        setSchemaTypes([]);
      }
    } catch (error) {
      console.error('Error getting schema types', error);
    }
  };

  const renderSchemaItem: ItemRenderer<ISchemaMenuItem> = (
    schema,
    { handleClick, handleFocus, modifiers },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else
      return (
        <MenuItem
          active={schema.key === formSchemaId}
          label={schema.moduleName}
          disabled={modifiers.disabled}
          key={schema.key}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={schema.name}
        />
      );
  };

  const updateAction = async () => {
    try {
      setIsUpdating(true);

      const payload = {
        description: formDescription || null,
        name: formName || null,
        schemaAssociationId: null,
        schemaId: formSchemaId,
        schemaTypeId: formSchemaTypeId || null,
        stageId: formStage || null,
      };

      const res = await httpPut(`SchemaModule/v1.0/schemas-views/${config?.id}`, payload);
      alertMessage({ body: 'View config updated', type: 'success' });
      onUpdate && onUpdate(res.data.data);
      setIsUpdating(false);
      onClose();
    } catch (err: any) {
      alertMessage({
        body: err.response ? err.response.data : 'Error updating View config',
        type: 'error',
      });
      setIsUpdating(false);
    }
  };

  const createAction = async () => {
    try {
      const payload = {
        name: formName,
        description: formDescription,
        schemaAssociationId: undefined,
        schemaId: formSchemaId,
        schemaTypeId: formSchemaTypeId,
        stageId: formStage,
      };

      const res = await httpPost(`SchemaModule/v1.0/schemas-views`, payload);
      console.log('%cdebug: View Config created!', 'color:limegreen', res?.data.data);
      alertMessage({ body: 'View Config created', type: 'success' });
      onCreate && onCreate(res.data.data);
      setIsCreating(false);
      onClose();
    } catch (e: any) {
      console.error('Error creating view config', e);
      alertMessage({ body: e.response?.data?.message ?? e.message, type: 'error' });
      setIsCreating(false);
    }
  };

  const canUpdateOrCreate = () => {
    return formSchemaId && formName && formDescription;
  };

  const renderSchemaTypeItem: ItemRenderer<ISchemaTypeMenuItem> = (
    schema,
    { handleClick, handleFocus, modifiers },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else
      return (
        <MenuItem
          active={schema.key === formSchemaTypeId}
          disabled={modifiers.disabled}
          key={schema.key}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={schema.name}
        />
      );
  };

  const renderStageItem: ItemRenderer<IStageMenuItem> = (
    stage,
    { handleClick, handleFocus, modifiers },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else
      return (
        <MenuItem
          active={stage.name === formStage}
          disabled={modifiers.disabled}
          key={stage.key}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={stage.name}
        />
      );
  };

  let ALL_SCHEMAS = allSchemas.map((schema: any) => {
    return {
      key: schema.id,
      name: schema.entityName,
      value: schema.name,
      entityName: schema.entityName,
      moduleName: schema.moduleName,
    };
  });
  ALL_SCHEMAS = ALL_SCHEMAS.sort((a: any, b: any) => {
    return a.name.localeCompare(b.name);
  });
  if (schemaSearchQuery.length > 0) {
    ALL_SCHEMAS = ALL_SCHEMAS.filter((schema: any) => {
      return schema.name.toLowerCase().includes(schemaSearchQuery.toLowerCase());
    });
  }

  const getSelectedSchemaName = () => {
    if (allSchemas.length > 0 && formSchemaId) {
      const schema = allSchemas.find((s: any) => s.id === formSchemaId);
      return schema.entityName;
    } else {
      return '';
    }
  };

  const getSelectedSchemaTypeName = () => {
    if (schemaTypes.length > 0 && formSchemaTypeId) {
      const schemaType = schemaTypes.find((s: SchemaTypeEntity) => s.id === formSchemaTypeId);
      return schemaType?.name;
    } else {
      return '';
    }
  };

  let SCHEMA_TYPES: ISchemaTypeMenuItem[] = schemaTypes.map((schemaType: SchemaTypeEntity) => {
    return {
      key: schemaType.id,
      name: schemaType.name,
    };
  });
  //  Add first option to schema type (None)
  SCHEMA_TYPES.unshift({
    key: 'none',
    name: '(None)',
  });

  let STAGES: IStageMenuItem[] = stages.map((stage: PipelineStageEntity) => {
    return {
      key: stage.id,
      name: stage.key,
    };
  });
  // Add first option to stages (None)
  STAGES.unshift({
    key: 'none',
    name: '(None)',
  });

  return (
    <>
      <Button
        fill
        onClick={() => setIsOpen(true)}
        disabled={isOpen}
        intent={mode === 'EDIT' ? undefined : 'success'}
        text={mode === 'EDIT' ? 'Edit' : 'Create View Config'}
        icon={mode === 'EDIT' ? 'annotation' : 'plus'}
        {...buttonProps}
      />
      <Dialog
        canOutsideClickClose={false}
        isOpen={isOpen}
        onClose={onClose}
        usePortal
        title={mode === 'EDIT' ? 'Edit View Config' : 'Create View Config'}
      >
        <DialogBody>
          <Row>
            {/* Schema */}
            <Col span={24}>
              <Label style={{ marginBottom: 5 }}>
                Schema<span style={{ color: 'red' }}> *</span>
              </Label>
              <Select<ISchemaMenuItem>
                items={ALL_SCHEMAS}
                resetOnClose
                disabled={allSchemas.length === 0}
                itemRenderer={renderSchemaItem}
                noResults={<MenuItem disabled={true} text="No results." roleStructure="menuitem" />}
                onItemSelect={(schema: any) => {
                  setFormSchemaId(schema.key);
                  getSchemaTypesBySchemaId(schema.key);
                  setFormSchemaTypeId(undefined);
                  setFormStage(undefined);
                }}
                query={schemaSearchQuery}
                onQueryChange={(e: any) => {
                  setSchemaSearchQuery(e);
                }}
              >
                <Button
                  outlined={!!formSchemaId}
                  alignText="left"
                  style={{ backgroundColor: formSchemaId ? 'white' : undefined }}
                  disabled={allSchemas.length === 0 || mode === 'EDIT' || !!defaultSchemaId}
                  text={formSchemaId ? getSelectedSchemaName() : 'Select Schema'}
                  rightIcon="caret-down"
                  fill
                />
              </Select>
            </Col>

            {/* Schema Type */}
            <Col span={24} style={{ marginTop: 15 }}>
              <Label style={{ marginBottom: 5 }}>Schema Type</Label>
              <Select<ISchemaTypeMenuItem>
                items={SCHEMA_TYPES}
                resetOnClose
                filterable={false}
                disabled={allSchemas.length === 0}
                itemRenderer={renderSchemaTypeItem}
                onItemSelect={(schema: any) => {
                  if (schema.key === 'none') {
                    setFormSchemaTypeId(undefined);
                    setFormStage(undefined);
                    setStages([]);
                  } else {
                    setFormSchemaTypeId(schema.key);
                  }
                  setFormStage(undefined);
                }}
              >
                <Button
                  style={{ backgroundColor: formSchemaTypeId ? 'white' : undefined }}
                  outlined={!!formSchemaTypeId}
                  alignText="left"
                  disabled={!formSchemaId || schemaTypes.length === 0}
                  text={formSchemaTypeId ? getSelectedSchemaTypeName() : 'Select Type'}
                  rightIcon="caret-down"
                  fill
                />
              </Select>
            </Col>

            <Col span={24} style={{ marginTop: 15 }}>
              <Divider style={{ marginLeft: 0, marginRight: 0 }} />
            </Col>

            {/* Action Name */}
            <Col span={24} style={{ marginTop: 10 }}>
              <Label style={{ marginBottom: 5 }}>
                View Config Name <span style={{ color: 'red' }}>*</span>
              </Label>
              <InputGroup
                fill
                type="text"
                placeholder="Name"
                value={formName}
                disabled={!formSchemaId}
                onChange={(e: any) => setFormName(e.target.value)}
              />
            </Col>

            {/* Description */}
            <Col span={24} style={{ marginTop: 15 }}>
              <Label style={{ marginBottom: 5 }}>
                Action Description<span style={{ color: 'red' }}> *</span>
              </Label>
              <InputGroup
                fill
                type="text"
                placeholder="Description"
                value={formDescription}
                disabled={!formSchemaId}
                onChange={(e: any) => setFormDescription(e.target.value)}
              />
            </Col>

            {/* Stage */}
            <Col span={24} style={{ marginTop: 15 }}>
              <Label style={{ marginBottom: 5 }}>Stage</Label>
              <Select<ISchemaTypeMenuItem>
                items={STAGES}
                resetOnClose
                filterable={false}
                disabled={allSchemas.length === 0}
                itemRenderer={renderStageItem}
                onItemSelect={(selectedStage: any) => {
                  if (selectedStage.key === 'none') {
                    setFormStage(undefined);
                  } else {
                    setFormStage(selectedStage.key);
                  }
                }}
              >
                <Button
                  style={{ backgroundColor: formStage ? 'white' : undefined }}
                  outlined={!!formStage}
                  alignText="left"
                  disabled={
                    !stages.length || !formSchemaId || (schemaTypes.length > 0 && !formSchemaTypeId)
                  }
                  text={
                    formStage ? stages.find((s: any) => s.id === formStage)?.key : 'Select Stage'
                  }
                  rightIcon="caret-down"
                  fill
                />
              </Select>
            </Col>
          </Row>
        </DialogBody>

        <DialogFooter
          actions={[
            <Button key="cancel" onClick={onClose} text="Close" />,
            <Button
              disabled={!canUpdateOrCreate()}
              loading={isUpdating || isCreating}
              key="submit"
              intent="primary"
              onClick={mode === 'CREATE' ? createAction : updateAction}
              text={mode === 'CREATE' ? 'Submit' : 'Save'}
            />,
          ]}
        />
      </Dialog>
    </>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(ViewConfigEditCreateForm);
