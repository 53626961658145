import { SET_IS_BILLING_ADJUSTMENT_APPLIED } from '../store/constants';
import { Row, Col, Spin, Switch } from 'antd';
import { invoiceBuilderContext } from '../index';
import React, { useContext } from 'react';
import RecordCard from '@core/components/RecordCard';

const BillingAdjustmentPanel: React.FC = () => {

    const { state, dispatch } = useContext(invoiceBuilderContext)

    const renderBillingAdjustmentCard = () => {

        return (
            <Col span={24} style={{ margin: 5 }}>
                <RecordCard
                    cardBodyOpacity={state.isBillingAdjustmentApplied ? 1 : 0.5}
                    showActionMenu={!state.billingAdjustment}
                    record={state.parentRecord!}
                    associatedRecordModuleName='OrderModule'
                    associatedRecordEntityName='BillingAdjustment'
                    visibleProperties={['DiscountLength', 'DiscountType', 'DiscountValue', 'Description']}
                    headerElement={
                        state.billingAdjustment && state.invoiceItems.length > 0
                            ?
                            <Switch
                                style={{ marginTop: 4 }}
                                checked={state.isBillingAdjustmentApplied}
                                onChange={() =>
                                    dispatch({
                                        type: SET_IS_BILLING_ADJUSTMENT_APPLIED,
                                        payload: !state.isBillingAdjustmentApplied
                                    })
                                }
                            />
                            : <></>
                    }
                />
            </Col>
        )
    }

    return (
        state.isLoadingParentRecordAssociations
            ? <Row style={{ padding: 40, textAlign: 'center' }}>
                <Col span={24}>
                    <Spin size="large" />
                </Col>
                <Col span={24} style={{ marginTop: 30 }}>
                    <span>Loading Billing adjustment</span>
                </Col>
            </Row>
            : renderBillingAdjustmentCard()
    );
};

export default BillingAdjustmentPanel;
