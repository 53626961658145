import { call, put, takeLatest } from 'redux-saga/effects';
import { httpGet, httpPost } from '@core/http/requests';
import { ERROR_NOTIFICATION } from '../../stores/notification/reducers';
import { USER_LOGOUT_REQUEST } from '../../stores/identity/constants';
import { ICreateUserAuditEvents, IGetUsersAuditEventsByRecord } from './actions';
import {
  CREATE_USER_AUDIT_EVENTS_ERROR,
  CREATE_USER_AUDIT_EVENTS_REQUEST,
  CREATE_USER_AUDIT_EVENTS_SUCCESS,
  GET_USERS_AUDIT_EVENTS_BY_RECORD_ERROR,
  GET_USERS_AUDIT_EVENTS_BY_RECORD_REQUEST,
  GET_USERS_AUDIT_EVENTS_BY_RECORD_SUCCESS,
} from './constants';

function* getUserAuditEventsByRecord(action: {
  params: IGetUsersAuditEventsByRecord;
  cb?: (resp: any) => void;
}) {
  const { recordId, types, usersIds } = action.params;

  const queryParams = { types, usersIds };

  try {
    let path = `AuditModule/v1.0/UserAudit/record/${recordId}`;

    //@ts-ignore
    const res = yield call(async () => await httpGet(path, queryParams));

    yield put({
      type: GET_USERS_AUDIT_EVENTS_BY_RECORD_SUCCESS,
      params: action.params,
      results: res.data,
    });

    if (action.cb) {
      yield call(action.cb, { results: res.data });
    }
  } catch (e: any) {
    const error = e.response?.data ?? e;
    yield put({ type: GET_USERS_AUDIT_EVENTS_BY_RECORD_ERROR, error });

    if (action.cb) {
      yield call(action.cb, undefined);
    }

    if (error?.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else if (error?.statusCode !== 404) {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* createUserAuditEvents(action: {
  params: ICreateUserAuditEvents;
  cb?: (resp: any) => void;
}) {
  const { items } = action.params;

  try {
    let path = `AuditModule/v1.0/UserAudit/batch`;

    //@ts-ignore
    const res = yield call(async () => await httpPost(path, items));

    yield put({
      type: CREATE_USER_AUDIT_EVENTS_SUCCESS,
      params: action.params,
      results: res.data,
    });

    if (action.cb) {
      yield call(action.cb, { results: res.data });
    }
  } catch (e: any) {
    const error = e.response?.data ?? e;
    yield put({ type: CREATE_USER_AUDIT_EVENTS_ERROR, error });

    if (action.cb) {
      yield call(action.cb, undefined);
    }

    if (error?.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else if (error?.statusCode !== 404) {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* rootSaga() {
  yield takeLatest<any>(GET_USERS_AUDIT_EVENTS_BY_RECORD_REQUEST, getUserAuditEventsByRecord);
  yield takeLatest<any>(CREATE_USER_AUDIT_EVENTS_REQUEST, createUserAuditEvents);
}

export default rootSaga;
