import { Button, Icon, InputGroup, Section } from '@blueprintjs/core';
import {
  Cell,
  Column,
  RenderMode,
  SelectionModes,
  Table2,
  TableLoadingOption,
} from '@blueprintjs/table';
import { Col, Row, Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

import { BlueprintNavigation } from '@core/components/BlueprintPagination';
import { PageHeader } from '@core/components/PageHeader';
import { searchString } from '@core/helpers/searchHelpers';
import { httpGet } from '@core/http/requests';
import ViewConfigEditCreateForm from '@core/modules/ControlPanelModule/components/ViewConfigs/ViewConfigEditCreateForm';
import ViewConfigListViewDetails from '@core/modules/ControlPanelModule/components/ViewConfigs/ViewConfigListViewDetails';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { initializeRecordForm } from '@redux/stores/form/actions';
import { displayMessage } from '@redux/stores/messages/reducers';
import { getRecordByIdRequest, IGetRecordById } from '@redux/stores/records/actions';
import { ISchemaReducer } from '@redux/stores/schemas/reducer';
import { v4 } from 'uuid';

const uuid = v4();

interface Props {
  initializeForm: any;
  alertMessage: (params: { body: string; type: string }) => void;
  onSchemaSelected?: (schemaId: string) => void;
  schemaReducer: ISchemaReducer;
  getRecord: (payload: IGetRecordById, cb: any) => void;
}

interface ITableData {
  key: string;
  name: string;
  entityName: string;
  description: string;
  schemaType: string;
  stage: string;
}

const ViewConfigsListViewTable: FC<Props> = (props: Props) => {
  const { alertMessage, schemaReducer, initializeForm, getRecord } = props;
  const [searchKey, setSearchKey] = useState<string>('');
  const [tableWidth, setTableWidth] = useState<number>(1);
  const [selectedTableRegions, setSelectedTableRegions] = useState<any[]>([]);
  const [configsList, setConfigsList] = useState<DbRecordEntityTransform[]>([]);
  const [selectedConfig, setSelectedConfig] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(33);
  const [tableSlice, setTableSlice] = useState<ITableData[]>([]);
  const [isConfigLoading, setIsSchemaActionLoading] = useState<boolean>(false);
  const [selectedEntityName, setSelectedEntityName] = useState<string>('');
  const [isLoadingConfigsList, setIsLoadingConfigsList] = useState<boolean>(false);

  const tableRef = React.createRef<any>();

  useEffect(() => {
    getAllViewConfigsList();
  }, []);

  useEffect(() => {
    setSelectedTableRegions([]);
    setSelectedConfig(undefined);
  }, [currentPage]);

  // Get selected schemaAction details
  useEffect(() => {
    if (selectedTableRegions.length > 0 && tableSlice.length > 0) {
      const id: any = tableSlice[selectedTableRegions[0].rows[0]]?.key;
      const config = configsList.find((c) => c.id === id);

      if (config) {
        setSelectedConfig(config);
      }
    }
  }, [selectedTableRegions, tableSlice, selectedEntityName]);

  const onSelect = (e: any) => {
    setSelectedTableRegions([
      {
        cols: [0, 4],
        rows: [e[0].rows[0], e[0].rows[0]],
      },
    ]);
  };

  useEffect(() => {
    if (configsList.length > 0) {
      let tableData: ITableData[] = [];

      // Get a list of all schemas with entity name and id
      const schemaList = schemaReducer.list.map((s: any) => ({
        id: s.id,
        entityName: s.entityName,
        moduleName: s.moduleName,
        types: s.types || [],
      }));

      tableData = configsList.map((config: any) => {
        const schema: any = schemaList.find((s: any) => s.id === config.schemaId);
        const typeName: string =
          schema?.types?.find((t: any) => t.id === config.schemaTypeId)?.name || '';

        return {
          key: config.id,
          name: config.name || '',
          entityName: schema?.entityName || '',
          description: config.description || '',
          schemaType: typeName,
          stage: config.stageId || '',
        };
      });

      // Apply search
      tableData = tableData.filter((t: ITableData) => {
        return searchString(t.name, searchKey);
      });

      //  Sort table daya by entityName
      tableData = tableData.sort((a, b) => a.name.localeCompare(b.name));

      // Apply pagination
      const start = currentPage * pageSize - pageSize;
      const end = start + pageSize - 1;
      if (!searchKey) {
        tableData = tableData.slice(start, end);
      }

      setTableSlice(tableData);
    }
  }, [configsList, currentPage, searchKey, pageSize, selectedEntityName]);

  const getAllViewConfigsList = async () => {
    try {
      setIsLoadingConfigsList(true);
      await httpGet(`SchemaModule/v1.0/schemas-views`).then((res: any) => {
        const configs = res?.data?.data || [];
        // console.log('%cdebug: Config Results', 'color:limegreen', configs);
        setConfigsList(configs);
      });
    } catch (error: any) {
      setConfigsList([]);
      alertMessage({ body: error.response?.data?.message || error.message, type: 'error' });
    } finally {
      setIsLoadingConfigsList(false);
    }
  };

  // Table Width Calculation
  const getColumnWidthByPercentage = (percentage: number): number => {
    return (percentage / 100) * tableWidth;
  };

  const updateWidth = () => {
    if (tableRef.current) {
      const width = tableRef.current.scrollContainerElement?.clientWidth;
      setTableWidth(width);
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [selectedConfig, tableRef, tableSlice]);

  const onSearch = (e: any) => {
    setSelectedTableRegions([]);
    setSearchKey(e.target.value);
  };

  const onConfigCreate = (config: any) => {
    if (config) {
      setConfigsList([...configsList, config]);
      setSelectedConfig(config);
    }
  };

  const onConfigUpdate = (config: DbRecordEntityTransform) => {
    if (config) {
      setSelectedConfig(config);
      const index = configsList.findIndex((c) => c.id === config.id);
      if (index > -1) {
        configsList[index] = config;
        setConfigsList([...configsList]);
      }
    }
  };

  const onConfigDelete = (configId: string) => {
    console.log('debug: Deleted config', configId);
    const updatedConfigs = configsList.filter((c) => c.id !== configId);
    setConfigsList(updatedConfigs);
    setSelectedConfig(undefined);
  };

  const renderListView = () => {
    return (
      <>
        <PageHeader
          className="page-tool-bar"
          style={{ background: 'white', padding: 0, margin: 0 }}
          ghost
        >
          <Row style={{ marginBottom: 15, marginTop: 5 }} justify="end">
            <Col span={6}>
              <h2 style={{ margin: 0 }}>View Configs</h2>
            </Col>
            <Col span={18} style={{ textAlign: 'right' }}>
              <Space>
                <InputGroup
                  type="search"
                  placeholder="Search View Configs"
                  intent={searchKey.length > 0 ? 'primary' : 'none'}
                  onChange={onSearch}
                  value={searchKey}
                  leftIcon="search"
                  style={{ width: isMobile ? '100%' : 220 }}
                />
                <ViewConfigEditCreateForm mode="CREATE" onCreate={onConfigCreate} />
              </Space>
            </Col>
          </Row>
        </PageHeader>
        {/* Row with dynamically calculated height */}
        <Row className="listViewContainer">
          <Col span={selectedConfig ? 17 : 24} style={{ height: '100%', width: 0, padding: 1 }}>
            {/* Table */}
            <Table2
              loadingOptions={isLoadingConfigsList ? [TableLoadingOption.CELLS] : []}
              ref={tableRef}
              numRows={tableSlice.length}
              defaultRowHeight={30}
              onSelection={onSelect}
              enableGhostCells={isLoadingConfigsList}
              selectedRegions={selectedTableRegions}
              enableMultipleSelection={false}
              enableRowHeader={false}
              renderMode={RenderMode.NONE}
              forceRerenderOnSelectionChange={false}
              cellRendererDependencies={[tableWidth, currentPage, tableSlice]}
              selectionModes={SelectionModes.ROWS_AND_CELLS}
              columnWidths={[
                getColumnWidthByPercentage(30),
                getColumnWidthByPercentage(15),
                getColumnWidthByPercentage(35),
                getColumnWidthByPercentage(10),
                getColumnWidthByPercentage(10),
              ]}
            >
              <Column
                key="name"
                name="Name"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].name}</Cell>
                )}
              />

              <Column
                key="entityName"
                name="Entity Name"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].entityName}</Cell>
                )}
              />

              <Column
                key="description"
                name="Description"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].description}</Cell>
                )}
              />
              <Column
                key="schemaType"
                name="Schema Type"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].schemaType}</Cell>
                )}
              />

              <Column
                key="stageId"
                name="Stage"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key} style={{ textAlign: 'center' }}>
                    {tableSlice[rowIndex].stage ? (
                      <Icon
                        key={tableSlice[rowIndex].key}
                        icon="tick"
                        color="#32A467"
                        style={{ verticalAlign: 'middle' }}
                      />
                    ) : (
                      <></>
                    )}
                  </Cell>
                )}
              />
            </Table2>
          </Col>

          {/* Schema action Details */}
          {selectedConfig && (
            <Col
              className="listViewDetailsColumn"
              span={7}
              style={{ opacity: isConfigLoading ? 0.6 : 1 }}
            >
              <Section
                compact
                title="View Config Details"
                rightElement={
                  <Button
                    icon="cross"
                    small
                    minimal
                    onClick={() => {
                      setSelectedTableRegions([]);
                      setSelectedConfig(undefined);
                    }}
                  />
                }
              >
                <ViewConfigListViewDetails
                  config={selectedConfig}
                  onUpdate={onConfigUpdate}
                  onDelete={onConfigDelete}
                />
              </Section>
            </Col>
          )}
        </Row>
        {/* Pagination */}
        <Row style={{ background: 'white' }}>
          <div style={{ padding: '10px 0' }}>
            <BlueprintNavigation
              totalCount={configsList.length}
              currentPage={currentPage}
              pageSize={pageSize}
              onPaginate={setCurrentPage}
              disabled={searchKey.length > 0}
            />
          </div>
        </Row>
      </>
    );
  };

  return <div style={{ background: 'white', padding: '0 15px' }}>{renderListView()}</div>;
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  getRecord: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(ViewConfigsListViewTable);
