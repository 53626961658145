import React, { Fragment, useCallback } from 'react';
import { Button, InputGroup, Popover, Tag } from '@blueprintjs/core';
import { Divider, Space } from 'antd';

import {
  useOdinSearchActions,
  useOdinSearchState,
} from '@core/modules/SupportModule/views/SupportDashboard/views/CaseManagementCaseListView/context/provider';

export const AppliedFilters: React.FC = () => {
  // Context state
  const { selectedParams } = useOdinSearchState();
  const { removeFilter, saveParams, replaceFilters } = useOdinSearchActions();

  // Local state
  const [filterName, setFilterName] = React.useState<string>('');

  // Event handlers
  const handleSaveFilterClick = useCallback(() => {
    if (!selectedParams) return;

    saveParams({ ...selectedParams, name: filterName });
  }, [selectedParams, saveParams, filterName]);

  const filterValues = selectedParams ? selectedParams.filters : [];

  if (filterValues.length === 0) return null;

  return (
    <Fragment>
      <Space style={{ display: 'flex' }}>
        {filterValues.map((filter) => (
          <Tag
            intent="success"
            minimal
            key={`${filter.columnName}-${filter.operator}-${filter.value}`}
            style={{ borderRadius: 5, height: 30 }}
            onRemove={() => {
              removeFilter(filter);
            }}
          >
            {filter.columnName}: {filter.value}
          </Tag>
        ))}
        <Popover
          content={
            <InputGroup
              placeholder="Filter Name"
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
              rightElement={
                <Button
                  icon="floppy-disk"
                  text="Save"
                  disabled={!filterName}
                  onClick={handleSaveFilterClick}
                />
              }
            />
          }
        >
          <Button icon="floppy-disk" style={{ borderRadius: 5 }} />
        </Popover>
      </Space>
      <Space>
        <Divider type="vertical" />
      </Space>
      <Button icon="cross" style={{ borderRadius: 5 }} onClick={() => replaceFilters([])} />
    </Fragment>
  );
};
