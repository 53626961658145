import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import { Button, Card, Input, Select, Switch, Tooltip } from 'antd';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  IMapWFSQuery,
  MapReducerUpdate,
  MapSearch,
  searchByNOIRef,
  searchByPostcode,
  setMapSearchQuery,
  updateMapState,
  WFSRequest,
} from '@netomnia/modules/ProjectModule/views/Map/store/actions';
import { MapReducer } from '@netomnia/modules/ProjectModule/views/Map/store/reducer';
import {
  ISearchRecords,
  resetRecordsList,
  searchRecordsRequest,
} from '../../../../../../../../redux/stores/records/actions';
import { IRecordReducer } from '../../../../../../../../redux/stores/records/reducer';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../../../../../redux/stores/schemas/actions';
import { ISchemaReducer } from '../../../../../../../../redux/stores/schemas/reducer';
import { displayMessage } from '../../../../../../../../redux/stores/messages/reducers';
import { getSchemaFromShortListByModuleAndEntity } from '@core/helpers/schemaHelpers';
import { removeMapLayersByClassName } from '../../../helpers';
import MapSidebarSavedViews from '../../MapSidebarSavedViews';

const { PROJECT_MODULE } = SchemaModuleTypeEnums;
const { FEATURE } = SchemaModuleEntityTypeEnums;
const { Search } = Input;

interface Props {
  match: any;
  mapReducer: MapReducer;
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  updateMap: (params: MapReducerUpdate) => {};
  setSearchQuery: (params: MapSearch) => {};
  resetSearchMap: any;
  searchRecords: any;
  alertMessage: any;
  getSchema: Function;
  makeWFSRequest: (params: IMapWFSQuery, cb: any) => void;
  searchPostcode: (params: { searchQuery: string }, cb: any) => void;
  searchNOIRef: (params: { searchQuery: string }, cb: any) => void;
}

interface State {
  featureSchema: SchemaEntity | undefined;
}

class MapSidebarSearchCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      featureSchema: undefined,
    };
  }

  componentDidMount() {
    const { schemaReducer, getSchema, match } = this.props;

    // If there is no search param in the URL, clean the search results on mount.
    if (!match?.params?.featureId) {
      this.clearSearch();
    }

    const shortlistFeatureSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PROJECT_MODULE,
      FEATURE,
    );

    if (shortlistFeatureSchema) {
      this.setState({ featureSchema: shortlistFeatureSchema });
    } else {
      getSchema({ moduleName: PROJECT_MODULE, entityName: FEATURE }, (response: SchemaEntity) => {
        if (response) {
          this.setState({ featureSchema: response });
        }
      });
    }
  }

  handleAddFeatureMenuClick(LayerName: string) {
    const { updateMap } = this.props;
    updateMap({
      queryLayer: LayerName,
      inputFieldFeatureID: '',
      inputFieldPostcode: '',
      inputFieldNOIRef: '',
    });
  }

  clearSearch() {
    const { updateMap, resetSearchMap, mapReducer } = this.props;
    const { map } = mapReducer;

    // Remove all features that were searched on map
    if (map) {
      removeMapLayersByClassName(['feature_by_id_layer'], map);
    }

    updateMap({
      map,
      mapSidebarFilteringActive: false,
      features: [],
      coordinates: [],
      drawEnabled: false,
      addEnabled: false,
      query: undefined,
      queryLayer: undefined,
      inputFieldFeatureID: '',
      inputFieldPostcode: '',
      inputFieldNOIRef: '',
      isSearchingMap: false,
      createLinked: {
        fromType: undefined,
        fromId: undefined,
        toType: undefined,
      },
      mapSelectionFiltering: 'ALL',
    });

    resetSearchMap();
  }

  // Search map by id
  async handleMapSearch(searchQuery: any) {
    const { updateMap, setSearchQuery, mapReducer, alertMessage, makeWFSRequest } = this.props;
    const { queryLayer } = mapReducer;

    // After three seconds, reset the isSearchingMap property
    setTimeout(() => {
      updateMap({
        isSearchingMap: false,
      });
    }, 3000);

    if (searchQuery && queryLayer) {
      makeWFSRequest(
        {
          typeName: queryLayer,
          featureId: `${queryLayer}.${searchQuery}`,
        },
        (res: any) => {
          setSearchQuery({
            featureIds: searchQuery,
          });
          updateMap({
            drawEnabled: false,
            addEnabled: false,
            features: res.data.features ? res.data.features : [],
            query: `type=${queryLayer}&featureId=${searchQuery}`,
            coordinates: [],
            createLinked: {
              fromType: undefined,
              fromId: undefined,
              toType: undefined,
            },
          });
        },
      );
    }
  }

  async handlePostcodeSearch(searchQuery: any) {
    const { updateMap, alertMessage, searchPostcode } = this.props;

    searchPostcode({ searchQuery }, (response: any) => {
      const results = response?.data?.data;
      if (results) {
        const exPolygon = results.find((elem: { id: number; name: string }) => elem.name === 'EX');
        const l1Polygon = results.find((elem: { id: number; name: string }) => elem.name === 'L1');
        const l2Polygon = results.find((elem: { id: number; name: string }) => elem.name === 'L2');
        const l3Polygon = results.find((elem: { id: number; name: string }) => elem.name === 'L3');
        const l4Polygon = results.find((elem: { id: number; name: string }) => elem.name === 'L4');
        // we want to find any of the 5 polygons and search in order by
        // smallest to largest
        updateMap({
          queryLayer: 'polygon',
          drawEnabled: false,
          coordinates: [],
          addEnabled: false,
          query: `type=polygon&featureId=${
            l4Polygon?.id || l3Polygon?.id || l2Polygon?.id || l1Polygon?.id || exPolygon?.id
          }`,
          createLinked: {
            fromType: undefined,
            fromId: undefined,
            toType: undefined,
          },
        });
      }
    });
  }

  async handleNoiRefSearch(searchQuery: any) {
    const { updateMap, setSearchQuery, alertMessage, searchNOIRef } = this.props;
    searchNOIRef({ searchQuery }, (response: any) => {
      const results = response?.data?.data;
      if (results) {
        setSearchQuery({
          featureIds: results.map((elem: { id: any }) => elem.id).join(),
        });
        updateMap({
          queryLayer: 'cable',
          query: `type=cable&featureId=${results
            .map((elem: { id: any }) => elem.id)
            .join()}&noiRef=${searchQuery}`,
          drawEnabled: false,
          addEnabled: false,
          createLinked: {
            fromType: undefined,
            fromId: undefined,
            toType: undefined,
          },
        });
      }
    });
  }

  render() {
    const { mapReducer, match, updateMap } = this.props;

    const { featureSchema } = this.state;
    const { queryLayer, inputFieldFeatureID, inputFieldPostcode, inputFieldNOIRef } = mapReducer;
    const { Option } = Select;

    let SearchFeatures = featureSchema?.types?.map((type: SchemaTypeEntity) => (
      <Option key={type.name} value={type.name.toLowerCase()}>
        {type.name.toLowerCase()}
      </Option>
    ));

    const renderIdPlaceholder = () => {
      if (queryLayer === 'pia_structure' || queryLayer === 'pia_duct') {
        return 'Object ID';
      } else {
        return 'Feature ID';
      }
    };

    return (
      <div>
        <Card
          size="small"
          title={
            <span>
              <SearchOutlined style={{ marginRight: 7 }} />
              Search Features
            </span>
          }
          extra={[
            <Tooltip title="Filter with Saved Views" mouseEnterDelay={0.8}>
              <Switch
                checked={mapReducer.mapSidebarFilteringActive}
                onClick={() =>
                  updateMap({ mapSidebarFilteringActive: !mapReducer.mapSidebarFilteringActive })
                }
                style={{ marginRight: 12, marginBottom: 2 }}
                checkedChildren={<FilterOutlined />}
                unCheckedChildren={<FilterOutlined />}
                defaultChecked={false}
              />
            </Tooltip>,
            <Button
              type="primary"
              ghost
              size="small"
              style={{ borderRadius: 3 }}
              onClick={() => this.clearSearch()}
            >
              {isMobile ? 'Clear' : 'Clear Search'}
            </Button>,
          ]}
        >
          {mapReducer.mapSidebarFilteringActive ? (
            <MapSidebarSavedViews />
          ) : (
            <div>
              <Select
                disabled={mapReducer.isSearchingMap}
                placeholder="Select Feature"
                value={mapReducer.queryLayer}
                onChange={(e) => {
                  this.handleAddFeatureMenuClick(e.toString());
                }}
                style={{ width: '100%', marginBottom: 8 }}
              >
                {SearchFeatures}
              </Select>

              <Search
                value={inputFieldFeatureID}
                style={{ marginBottom: 8 }}
                disabled={!queryLayer || mapReducer.isSearchingMap}
                placeholder={renderIdPlaceholder()}
                defaultValue={match?.params?.featureId}
                onPressEnter={(e: any) => this.handleMapSearch(e.target.value)}
                onSearch={(value: any, event: any) => this.handleMapSearch(value)}
                onChange={(e: any) =>
                  updateMap({
                    inputFieldFeatureID: e.target.value,
                  })
                }
              />

              <Search
                value={inputFieldPostcode}
                style={{ marginBottom: 8 }}
                placeholder="Postcode"
                disabled={mapReducer.isSearchingMap}
                onPressEnter={(e: any) => this.handlePostcodeSearch(e.target.value)}
                onSearch={(e: any) => this.handlePostcodeSearch(e)}
                onChange={(e: any) =>
                  updateMap({
                    inputFieldPostcode: e.target.value,
                  })
                }
              />

              <Search
                value={inputFieldNOIRef}
                style={{ marginBottom: 8 }}
                placeholder="NOI Ref"
                disabled={mapReducer.isSearchingMap}
                onPressEnter={(e: any) => this.handleNoiRefSearch(e.target.value)}
                onSearch={(e: any) => this.handleNoiRefSearch(e)}
                onChange={(e: any) =>
                  updateMap({
                    inputFieldNOIRef: e.target.value,
                  })
                }
              />
            </div>
          )}
        </Card>
      </div>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
  mapReducer: state.mapReducer,
});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  searchRecords: (params: ISearchRecords) => dispatch(searchRecordsRequest(params)),
  updateMap: (params: MapReducerUpdate) => dispatch(updateMapState(params)),
  setSearchQuery: (params: MapSearch) => dispatch(setMapSearchQuery(params)),
  resetSearchMap: () => dispatch(resetRecordsList()),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  makeWFSRequest: (params: IMapWFSQuery, cb: any) => dispatch(WFSRequest(params, cb)),
  searchPostcode: (params: { searchQuery: string }, cb: any) =>
    dispatch(searchByPostcode(params, cb)),
  searchNOIRef: (params: { searchQuery: string }, cb: any) => dispatch(searchByNOIRef(params, cb)),
});

export default withRouter(connect(mapState, mapDispatch)(MapSidebarSearchCard));
