import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Badge, Button, Col, Divider, Dropdown, Menu, Row, Tooltip } from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { initializeRecordForm } from '@redux/stores/form/actions';
import { addRecordToShortList, IAddRecordToShortList } from '@redux/stores/records/actions';
import ModuleEntityIcon from 'src/core/components/ModuleEntityIcon';
import { getBrowserPath } from '@core/helpers/recordHelpers';
import {
  getSchemaFromShortListByModuleAndEntity,
  getSchemaFromShortListBySchemaId,
} from '@core/helpers/schemaHelpers';
import { v4 as uuidv4 } from 'uuid';
import CoreForm from '@core/components/Forms/CoreForm';
import { DataSetWorkContext } from '../../index';
import { toSentenceCase } from '@core/helpers/stringHelpers';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
  IUpdateRelatedRecordAssociation,
  updateRecordAssociationRequest,
} from '@redux/stores/recordAssociations/actions';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import {
  SET_ASSOCIATED_DATASET_RECORDS,
  SET_IS_SEARCHING_DATASET_ASSOCIATIONS,
} from '../../store/constants';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { getColorForDSRecordStatus } from '../../../helpers';
import {
  IOpenRecordDrawer,
  ISetOrderBuilderDrawerRecordIds,
  openOrderBuilderDrawer,
  openRecordDrawer,
  setOrderBuilderDrawerRecordIds,
} from '@redux/stores/userInterface/actions';
import OrderBuilderDrawer from '@core/modules/OrderModule/OrderBuilder/OrderBuilderDrawer';

interface Props {
  isLoading: boolean;
  record: DbRecordEntityTransform | undefined;
  shortListRecord: Function;
  initializeForm: Function;
  schemaReducer: any;
  updateRecordAssociation: Function;
  getAssociations: Function;
  openOrderBuilder: () => void;
  setOrderBuilderDrawerRecordIds: (params: ISetOrderBuilderDrawerRecordIds) => void;
  openDrawer: (params: IOpenRecordDrawer) => void;
}

const { CRM_MODULE } = SchemaModuleTypeEnums;

const uuid = uuidv4();

const RecordViewHeader: FC<Props> = (props: Props) => {
  const {
    record,
    initializeForm,
    schemaReducer,
    updateRecordAssociation,
    getAssociations,
    openOrderBuilder,
    openDrawer,
  } = props;
  const { state, dispatch } = useContext(DataSetWorkContext);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false);
  const [recordStatus, setRecordStatus] = useState<string | undefined>(undefined);

  const [isPreparingOrderBuilder, setIsPreparingOrderBuilder] = useState<boolean>(false);

  const moduleName = record?.entity?.split(':')[0];
  const entityName = record?.entity?.split(':')[1];

  // On component mount, set the record status to state
  useEffect(() => {
    if (record) {
      setRecordStatus(getProperty(record, 'DS_Status'));
    }
  }, [record]);

  // Fetch related CONTACT and ADDRESS, start order builder
  const startOrderBuilder = () => {
    openOrderBuilder();
  };

  const initializeUpdateForm = async (record: DbRecordEntityTransform) => {
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);

    if (schema) {
      initializeForm({
        formUUID: uuid,
        title: `Updating Related ${schema.entityName} Properties`,
        hasColumnMappings: false,
        showFormModal: true,
        isUpdateReq: true,
        schema: schema,
        selected: record,
        recordType: record.type,
        sections: [{ name: schema.name, schema: schema }],
        relatedEntityName: entityName,
      });
    }
  };

  const refreshDataSetAssociations = () => {
    dispatch({ type: SET_IS_SEARCHING_DATASET_ASSOCIATIONS, payload: true });

    let associatedEntityName = state.dataSetRecord?.type
      ? toSentenceCase(state.dataSetRecord?.type)
      : 'Address';

    const dataSetSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      CRM_MODULE,
      'CrmDataset',
    );

    getAssociations(
      {
        recordId: state.dataSetRecord?.id,
        schema: dataSetSchema,
        entities: [associatedEntityName],
      },
      (res: any) => {
        if (res && res.results?.[associatedEntityName]?.dbRecords?.length > 0) {
          const associations = res.results?.[associatedEntityName]?.dbRecords;
          dispatch({ type: SET_IS_SEARCHING_DATASET_ASSOCIATIONS, payload: false });
          dispatch({ type: SET_ASSOCIATED_DATASET_RECORDS, payload: associations });
        } else {
          dispatch({ type: SET_IS_SEARCHING_DATASET_ASSOCIATIONS, payload: false });
        }
      },
    );
  };

  const updateRecordStatus = (record: DbRecordEntityTransform | undefined, status: string) => {
    if (record) {
      const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);
      setIsUpdatingStatus(true);

      return updateRecordAssociation(
        {
          relatedEntityName: 'CrmDataset',
          parentSchema: schema,
          recordId: record.id,
          dbRecordAssociationId: record.dbRecordAssociation?.id,
          createUpdate: {
            entity: record.entity,
            schemaId: record.schemaId,
            properties: {
              DS_Status: status,
            },
          },
        },
        (res: DbRecordEntityTransform) => {
          if (res) {
            setRecordStatus(status);
            setTimeout(() => {
              refreshDataSetAssociations();
            }, 300);
          }
          setIsUpdatingStatus(false);
        },
      );
    }
  };

  return (
    <>
      <Row justify="space-between">
        {/* Left side */}
        <Col>
          <Row>
            <Col style={{ paddingTop: '8px' }}>
              <ModuleEntityIcon
                moduleName={moduleName!}
                entityName={entityName!}
                style={{ margin: '5px 3px', fontSize: '1.3em' }}
              />
            </Col>
            <Col>
              <Row>
                <Col span={24} style={{ marginTop: 1 }}>
                  <span style={{ fontSize: '0.87em' }}>{entityName!}</span>
                </Col>
                <Col span={24} style={{ fontWeight: 500 }}>
                  <Link to={getBrowserPath(record!)} target="_blank">
                    {record?.title || record?.recordNumber}
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        {/* Right Side */}
        <Col style={{ textAlign: 'right', paddingTop: '8px' }}>
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu>
                {state.allRecordStatuses?.map((status: string) => (
                  <Menu.Item
                    key={status}
                    onClick={() => updateRecordStatus(record, status)}
                    disabled={isUpdatingStatus}
                  >
                    <Badge style={{ marginRight: 8 }} color={getColorForDSRecordStatus(status)} />
                    {toSentenceCase(status)}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <Button style={{ marginRight: 10 }} disabled={isUpdatingStatus}>
              <Badge
                key={'orange'}
                color={getColorForDSRecordStatus(recordStatus!)}
                text={''}
                style={{ marginRight: 8 }}
              />
              {recordStatus ? toSentenceCase(recordStatus) : 'Todo'}
            </Button>
          </Dropdown>

          {/* Order Builder */}
          {state.dataSetRecord?.type === 'LEAD' && (
            <Button
              style={{ marginRight: 8 }}
              onClick={startOrderBuilder}
              loading={isPreparingOrderBuilder}
              disabled={isPreparingOrderBuilder}
            >
              Create Order
            </Button>
          )}

          <Button
            type="default"
            icon={<EyeOutlined />}
            style={{ verticalAlign: 'top', marginRight: 10 }}
            onClick={() =>
              openDrawer({
                recordId: record?.id || '',
                moduleName: moduleName!,
                entityName: entityName!,
              })
            }
          />

          <Tooltip title="Update Record" mouseEnterDelay={0.5}>
            <Button
              type="default"
              style={{ marginRight: 8 }}
              icon={<EditOutlined />}
              onClick={() => initializeUpdateForm(record!)}
            />
          </Tooltip>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        </Col>
      </Row>

      <OrderBuilderDrawer />

      <CoreForm
        type="MODAL"
        formUUID={uuid}
        onSubmitEvent={(params: { event: string; results: any }) =>
          console.log('%cRecord updated!', 'color:lime', params)
        }
      />
    </>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  shortListRecord: (params: IAddRecordToShortList) => dispatch(addRecordToShortList(params)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  updateRecordAssociation: (params: IUpdateRelatedRecordAssociation, cb: any) =>
    dispatch(updateRecordAssociationRequest(params, cb)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  openOrderBuilder: () => dispatch(openOrderBuilderDrawer()),
  setOrderBuilderDrawerRecordIds: (params: ISetOrderBuilderDrawerRecordIds) =>
    dispatch(setOrderBuilderDrawerRecordIds(params)),
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(RecordViewHeader);
