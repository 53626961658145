import { Button } from '@blueprintjs/core';
import { isSystemAdmin } from '@core/helpers/rbacRules';
import { SchemaActionDefinitionContext } from '@core/modules/ControlPanelModule/components/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration';
import {
  DefaultSchemaActionFlowTemplate,
  DefaultSchemaActionTemplate,
} from '@core/modules/ControlPanelModule/components/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration/SchemaActionJSONDefinition/V2Template';
import React, { useContext } from 'react';
import { connect } from 'react-redux';

interface Props {
  userReducer: any;
}

const SchemaActionInsertTemplateButton: React.FC<Props> = (props: Props) => {
  const { userReducer } = props;
  const { schema, schemaAction, setDefinition, isSavingDefinition } = useContext(
    SchemaActionDefinitionContext,
  );

  const addTemplateToDefinition = (template: 'DEFAULT' | 'FLOW') => {
    if (schema && template === 'DEFAULT') {
      const template = DefaultSchemaActionTemplate(schema.moduleName, schema.entityName);
      setDefinition(JSON.stringify(template, null, '\t'));
    } else if (schema && template === 'FLOW') {
      const template = DefaultSchemaActionFlowTemplate(schema.moduleName, schema.entityName);
      setDefinition(JSON.stringify(template, null, '\t'));
    }
  };

  return (
    <Button
      disabled={isSavingDefinition || !isSystemAdmin(userReducer)}
      text="Insert Template"
      fill
      onClick={() => {
        if (schemaAction?.isStepFlow) {
          addTemplateToDefinition('FLOW');
        } else {
          addTemplateToDefinition('DEFAULT');
        }
      }}
    />
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(SchemaActionInsertTemplateButton);
