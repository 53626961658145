import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SearchQueryType } from '@d19n/temp-fe-d19n-models/dist/search/search.query.type';
import { Card, Col, Tabs, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { getPipelineFromShortListBySchemaId } from '@core/helpers/pipelineHelpers';
import {
  generateModuleAndEntityKeyFromProps,
  getSavedFilter,
  setSearchQuery,
  setSortQuery,
} from '@core/helpers/searchHelpers';
import { getPipelinesByModuleAndEntity } from '../../../../redux/stores/pipelines/actions';
import { PipelineReducerState } from '../../../../redux/stores/pipelines/reducer';
import { ISchemaReducer } from '../../../../redux/stores/schemas/reducer';
import { searchRecordsRequest } from '../../../../redux/stores/records/actions';
import { IRecordReducer } from '../../../../redux/stores/records/reducer';
import { setTableConfig } from '@redux/stores/table/actions';
import { TableReducer } from '@redux/stores/table/reducer';
import DateFilters from './components/DateFilters';
import GroupsFilterDropdown from './components/GroupsFilterDropdown';
import PipelineFilterDropdown from './components/PipelineFilterDropdown';
import TablePropertiesFilter from './components/PropertyFilters';
import SearchButton from './components/SearchButton';
import TableColumnsFilter from './components/VisibilityFilters';
import { createElasticSearchFieldNames } from './helpers/recordFilterParsers';

import {
  resetQueryBuilderState,
  setQueryBuilderDefaultTab,
  setQueryBuilderState,
} from './store/actions';
import {
  getQueryBuilderReducer,
  IQueryBuilderByModuleAndEntityReducer,
  QueryBuilderReducer,
} from './store/reducer';
import { isMobile } from 'react-device-detect';
import SchemaTypeFilterDropdown from './components/SchemaTypeFilterDropdown';

const { TabPane } = Tabs;
const { Title } = Typography;

interface Props {
  schema: SchemaEntity | undefined;
  pipelinesEnabled: boolean;
  schemaReducer: ISchemaReducer;
  recordReducer: IRecordReducer;
  pipelineReducer: PipelineReducerState;
  getPipelines: (params: { schema: SchemaEntity }) => {};
  queryBuilderReducer: QueryBuilderReducer;
  recordTableReducer: TableReducer;
  searchRecords: any;
  setFilterableProps: any;
  setBuilderState: (params: any) => {};
  reset: () => {};
  setQueryBuilderDefaultTab: (params: { activeKey: string }) => {};
}

class QueryBuilder extends React.Component<Props> {
  componentDidMount(): void {
    const { schema, setFilterableProps } = this.props;

    this.loadRelatedPipelines();

    createElasticSearchFieldNames(schema, setFilterableProps);

    this.loadSavedQueries();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    const { schema } = this.props;
    const prevQbr = getQueryBuilderReducer(
      prevProps.queryBuilderReducer,
      schema?.moduleName,
      schema?.entityName,
    );
    const currentQbr = getQueryBuilderReducer(
      this.props.queryBuilderReducer,
      schema?.moduleName,
      schema?.entityName,
    );
    if (prevQbr?.queries !== currentQbr?.queries) {
      this.fetchData();
    }

    // ODN-1524 preload associations schemas pipelines
    if (
      prevProps.schema?.id !== this.props.schema?.id ||
      prevProps.schema?.associations?.length !== this.props.schema?.associations?.length
    ) {
      this.loadRelatedPipelines();
    }
  }

  private loadSavedQueries() {
    const { setBuilderState, schema, schemaReducer, recordTableReducer } = this.props;
    const savedFilter = getSavedFilter(
      schemaReducer,
      recordTableReducer,
      schema?.moduleName ?? '',
      schema?.entityName ?? '',
    );

    if (!!savedFilter) {
      setBuilderState(savedFilter);
    }
  }

  private loadRelatedPipelines() {
    const { schema, pipelineReducer, getPipelines } = this.props;
    schema?.associations?.forEach((association) => {
      if (association.childSchema && association.searchable) {
        const pipeline = getPipelineFromShortListBySchemaId(
          pipelineReducer.shortList,
          association.childSchema.id,
        );
        if (pipeline === undefined) {
          getPipelines({ schema: association.childSchema });
        }
      }
    });
  }

  private fetchData() {
    const { schema, schemaReducer, recordReducer, searchRecords } = this.props;

    const queryBuilderReducer = getQueryBuilderReducer(
      this.props.queryBuilderReducer,
      schema?.moduleName,
      schema?.entityName,
    );

    if (schema) {
      searchRecords({
        schema: schema,
        searchQuery: {
          schemas: schema.id,
          terms: setSearchQuery(schemaReducer, recordReducer, schema.moduleName, schema.entityName),
          sort: setSortQuery(schemaReducer, recordReducer, schema.moduleName, schema.entityName),
          boolean: queryBuilderReducer.queries,
        },
      });
    }
  }

  render() {
    const { schema, pipelinesEnabled } = this.props;
    const queryBuilderReducer = getQueryBuilderReducer(
      this.props.queryBuilderReducer,
      schema?.moduleName,
      schema?.entityName,
    );

    return (
      queryBuilderReducer.isVisible && (
        <Col span={isMobile ? 14 : 6}>
          <Card
            className="query-builder"
            style={{ height: 'calc(100vh - 200px)', overflow: 'hidden', borderTop: 0 }}
          >
            <Tabs
              size="small"
              type="card"
              centered
              activeKey={queryBuilderReducer.activeKey}
              onTabClick={(activeKey) => this.props.setQueryBuilderDefaultTab({ activeKey })}
            >
              <TabPane tab="Show / Hide" key="1">
                <div className="scroll-content">
                  <TableColumnsFilter
                    moduleName={schema?.moduleName}
                    entityName={schema?.entityName}
                  />
                </div>
              </TabPane>
              <TabPane tab="Filters" key="2">
                <div className="scroll-content" style={{ height: 'calc(100% - 52px)' }}>
                  {schema?.types?.length! > 0 && (
                    <div style={{ marginBottom: 24 }}>
                      <Title style={{ marginTop: 10 }} level={5}>
                        Type Filters
                      </Title>
                      <SchemaTypeFilterDropdown schema={schema} />
                    </div>
                  )}
                  {pipelinesEnabled && (
                    <div style={{ marginBottom: 24 }}>
                      <Title level={5}>Stage Filters</Title>
                      <PipelineFilterDropdown schema={schema} />
                    </div>
                  )}
                  <div style={{ marginBottom: 24 }}>
                    <Title level={5}>Groups Filters</Title>
                    <GroupsFilterDropdown schema={schema} />
                  </div>
                  <div style={{ marginBottom: 24 }}>
                    <Title level={5}>Date Filters</Title>
                    <DateFilters moduleName={schema?.moduleName} entityName={schema?.entityName} />
                  </div>
                  <div style={{ marginBottom: 24 }}>
                    <Title level={5}>Property Filters</Title>
                    <TablePropertiesFilter
                      moduleName={schema?.moduleName}
                      entityName={schema?.entityName}
                    />
                  </div>
                </div>
                <SearchButton moduleName={schema?.moduleName} entityName={schema?.entityName} />
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      )
    );
  }
}

const mapState = (state: any) => ({
  queryBuilderReducer: state.queryBuilderReducer,
  recordTableReducer: state.recordTableReducer,
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
  pipelineReducer: state.pipelineReducer,
});

const mapDispatch = (dispatch: any, ownProps: any) => ({
  setBuilderState: (params: IQueryBuilderByModuleAndEntityReducer) =>
    dispatch(setQueryBuilderState(generateModuleAndEntityKeyFromProps(ownProps), params)),
  setFilterableProps: (params: any) => dispatch(setTableConfig(params)),
  reset: () => dispatch(resetQueryBuilderState(generateModuleAndEntityKeyFromProps(ownProps))),
  searchRecords: (params: { schema: SchemaEntity; searchQuery: SearchQueryType }) =>
    dispatch(searchRecordsRequest(params)),
  setQueryBuilderDefaultTab: (params: { activeKey: string }) =>
    dispatch(setQueryBuilderDefaultTab(generateModuleAndEntityKeyFromProps(ownProps), params)),
  getPipelines: (params: { schema: SchemaEntity }) =>
    dispatch(getPipelinesByModuleAndEntity(params)),
});

export default connect(mapState, mapDispatch)(QueryBuilder);
