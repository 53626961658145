import { Button, InputGroup, Section } from '@blueprintjs/core';
import {
  Cell,
  Column,
  RenderMode,
  SelectionModes,
  Table2,
  TableLoadingOption,
} from '@blueprintjs/table';
import { BlueprintNavigation } from '@core/components/BlueprintPagination';
import FormModal, { FormReducerSubmitEvt } from '@core/components/Forms/SharedForm/SharedFormModal';
import { PageHeader } from '@core/components/PageHeader';
import { searchString } from '@core/helpers/searchHelpers';
import { httpGet, httpPost } from '@core/http/requests';
import { getErrorMessage } from '@core/modules/ControlPanelModule/helpers/errors';
import { displayMessage } from '@redux/stores/messages/reducers';
import { initializeSharedForm } from '@redux/stores/sharedForm/actions';
import { SharedFormReducer } from '@redux/stores/sharedForm/reducer';
import { Col, Row, Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import AreaDetails from '../AreaDetails';
import { formFields } from '../FormFields';

interface Props {
  initializeForm: any;
  formReducer: SharedFormReducer;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface ITableData {
  key: string;
  name: string;
  description: string;
}

const uuid = uuidv4();

const AreasListView: FC<Props> = (props: Props) => {
  const { formReducer, initializeForm, alertMessage } = props;
  const [searchKey, setSearchKey] = useState<string>('');
  const [tableWidth, setTableWidth] = useState<number>(1);
  // TableRegion is a BlueprintJS concept, unrelated to Odin's Region
  const [selectedTableRegions, setSelectedTableRegions] = useState<any[]>([]);
  const [selectedArea, setSelectedArea] = useState<any>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(33);
  const [areaList, setAreaList] = useState<any[]>([]);
  const [tableSlice, setTableSlice] = useState<ITableData[]>([]);
  const [isAreaLoading, setIsAreaLoading] = useState<boolean>(false);
  const [isLoadingAreaList, setIsLoadingAreaList] = useState<boolean>(false);

  // create ref for html element
  const tableRef = React.createRef<any>();

  useEffect(() => {
    getAllAreasList();
  }, []);

  useEffect(() => {
    setSelectedTableRegions([]);
    setSelectedArea(undefined);
  }, [currentPage]);

  const onSelect = (e: any) => {
    setSelectedTableRegions([
      {
        cols: [0, 1],
        rows: [e[0].rows[0], e[0].rows[0]],
      },
    ]);
  };

  useEffect(() => {
    if (areaList.length > 0) {
      let tableData: ITableData[] = Object.assign([], areaList);

      tableData = areaList.map((t: any) => ({
        key: t.id,
        name: t.name,
        description: t.description,
      }));

      // Apply search
      tableData = tableData.filter((t: any) => {
        return searchString(t.name, searchKey) || searchString(t.description, searchKey);
      });

      // Apply pagination
      const start = currentPage * pageSize - pageSize;
      const end = start + pageSize - 1;
      if (!searchKey) {
        tableData = tableData.slice(start, end);
      }

      setTableSlice(tableData);
    }
  }, [areaList, currentPage, searchKey, pageSize]);

  // Get selected area details
  useEffect(() => {
    if (selectedTableRegions.length > 0 && tableSlice.length > 0) {
      const area: any = tableSlice[selectedTableRegions[0].rows[0]];
      if (area && !isAreaLoading) {
        getAreaById(area.key);
      }
    }
  }, [selectedTableRegions, tableSlice]);

  const getAreaById = (id: string) => {
    setIsAreaLoading(true);
    httpGet(`IdentityModule/v2.0/areas/${id}`)
      .then((res) => {
        setIsAreaLoading(false);
        setSelectedArea(res.data.data);
        console.log('%cdebug: Selected area', 'color:limegreen', res.data.data);
      })
      .catch((error: any) => {
        const message = getErrorMessage(error);
        alertMessage({
          body: 'Could not retrieve area. ' + message,
          type: 'error',
        });
        setIsAreaLoading(false);
        setSelectedArea(undefined);
        setSelectedTableRegions([]);
      });
  };

  const getAllAreasList = async () => {
    setIsLoadingAreaList(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/areas?size=10000`);
      const areas: any[] = res.data.data || [];
      setAreaList(areas);
    } catch (error: any) {
      const message = getErrorMessage(error);
      setIsLoadingAreaList(false);
      alertMessage({ body: 'Could not retrieve area list. ' + message, type: 'error' });
      setAreaList([]);
    } finally {
      setIsLoadingAreaList(false);
    }
  };

  // Table Width Calculation
  const getColumnWidthByPercentage = (percentage: number): number => {
    return (percentage / 100) * tableWidth;
  };

  const updateWidth = () => {
    if (tableRef.current) {
      const width = tableRef.current.scrollContainerElement?.clientWidth;
      setTableWidth(width);
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [selectedArea, tableRef, tableSlice]);

  const showCreateAreaForm = () => {
    initializeForm({
      showModal: true,
      formUUID: uuid,
      title: 'Create Area',
      formFields: formFields,
      entityName: 'Area',
    });
  };

  const handleFormSubmit = (params: FormReducerSubmitEvt) => {
    if (params.data && !formReducer.isUpdateReq) {
      const body = {
        name: params.data.name,
        description: params.data.description,
      };
      createArea({ body });
    }
  };

  const createArea = async (params: { body: any }) => {
    const { body } = params;

    try {
      const res = await httpPost('IdentityModule/v2.0/areas', body);
      const newArea = res.data.data;
      setAreaList([...areaList, newArea]);

      getAreaById(newArea.id);
      alertMessage({ body: 'Area Created', type: 'success' });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not create area. ' + message,
        type: 'error',
      });
    }
  };

  const onSearch = (e: any) => {
    setSelectedTableRegions([]);
    setSearchKey(e.target.value);
  };

  const onAreaUpdate = () => {
    if (selectedArea) {
      getAreaById(selectedArea.id);
    }
  };

  const onAreaDelete = () => {
    setSelectedTableRegions([]);

    const id = selectedArea?.id;
    if (id) {
      setAreaList(areaList.filter((r) => r.id !== id));
      setSelectedArea(undefined);
    }
  };

  const renderAreas = () => {
    return (
      <>
        <FormModal
          formUUID={uuid}
          onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
        />
        <PageHeader
          className="page-tool-bar"
          style={{ background: 'white', padding: 0, margin: 0 }}
          ghost
        >
          <Row style={{ marginBottom: 15, marginTop: 5 }} justify="end">
            <Col span={4}>
              <h2 style={{ margin: 0 }}>Areas</h2>
            </Col>
            <Col span={20} style={{ textAlign: 'right' }}>
              <Space>
                <InputGroup
                  type="search"
                  placeholder="Search Areas"
                  intent={searchKey.length > 0 ? 'primary' : 'none'}
                  onChange={onSearch}
                  leftIcon="search"
                  style={{ width: isMobile ? '100%' : 220 }}
                />
                <Button
                  icon="plus"
                  intent="success"
                  onClick={showCreateAreaForm}
                  style={{ borderRadius: 5 }}
                >
                  Create Area
                </Button>
              </Space>
            </Col>
          </Row>
        </PageHeader>

        {/* Row with dynamically calculated height */}
        <Row className="listViewContainer">
          <Col span={selectedArea ? 17 : 24} style={{ height: '100%', width: 0, padding: 1 }}>
            {/* Table */}
            <Table2
              loadingOptions={isLoadingAreaList ? [TableLoadingOption.CELLS] : []}
              ref={tableRef}
              numRows={tableSlice.length}
              defaultRowHeight={30}
              onSelection={onSelect}
              enableGhostCells={isLoadingAreaList}
              selectedRegions={selectedTableRegions}
              enableMultipleSelection={false}
              enableRowHeader={false}
              renderMode={RenderMode.NONE}
              forceRerenderOnSelectionChange={false}
              cellRendererDependencies={[tableWidth, currentPage, tableSlice]}
              selectionModes={SelectionModes.ROWS_AND_CELLS}
              columnWidths={[getColumnWidthByPercentage(30), getColumnWidthByPercentage(70)]}
            >
              <Column
                key="name"
                name="Name"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].name}</Cell>
                )}
              />
              <Column
                key="description"
                name="Description"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].description}</Cell>
                )}
              />
            </Table2>
          </Col>

          {/* Area Details */}
          {selectedArea && (
            <Col
              className="listViewDetailsColumn"
              span={7}
              style={{ opacity: isAreaLoading ? 0.6 : 1 }}
            >
              <Section
                compact
                title="Area Details"
                rightElement={
                  <Button
                    icon="cross"
                    small
                    minimal
                    onClick={() => {
                      setSelectedTableRegions([]);
                      setSelectedArea(undefined);
                    }}
                  />
                }
              >
                <AreaDetails area={selectedArea} onUpdate={onAreaUpdate} onDelete={onAreaDelete} />
              </Section>
            </Col>
          )}
        </Row>

        {/* Pagination */}
        <Row style={{ background: 'white' }}>
          <div style={{ padding: '10px 0' }}>
            <BlueprintNavigation
              totalCount={areaList.length}
              currentPage={currentPage}
              pageSize={pageSize}
              onPaginate={setCurrentPage}
              disabled={searchKey.length > 0}
            />
          </div>
        </Row>
      </>
    );
  };

  return <div style={{ background: 'white', padding: '0 15px' }}>{renderAreas()}</div>;
};

const mapState = (state: any) => ({
  formReducer: state.formReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(AreasListView);
