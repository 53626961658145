import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { SearchQueryType } from '@d19n/temp-fe-d19n-models/dist/search/search.query.type';
import { Col, Row, Spin } from 'antd';
import React, { FunctionComponent, useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  ISearchRecords,
  searchRecordsRequest,
  setDbRecordSearchQuery,
} from '@redux/stores/records/actions';

import { getSchemaFromShortListByModuleAndEntity } from '@core/helpers/schemaHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import PDFModalViewer from '../PDFModalViewer';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '@redux/stores/schemas/actions';
import FileCard from './FileCard';
import './styles.scss';

interface OwnProps {
  schema: SchemaEntity | undefined;
  moduleName: string | undefined;
  entityName: string | undefined;
  recordReducer: any;
  schemaReducer: any;
  setSearchQuery: any;
  searchRecords: any;
  displayed: 'LIST' | 'FEED';
  getSchema: Function;
}

type Props = OwnProps;
const { SCHEMA_MODULE } = SchemaModuleTypeEnums;

const FileFeed: FunctionComponent<Props> = (props) => {
  const { recordReducer, searchRecords, getSchema } = props;
  const [fileList, setFileList] = useState<Array<any>>([]);
  const [fileListPageNum, setFileListPageNum] = useState<number>(1);
  const [schema, setSchema] = useState<any>(null);
  const [colHeight, setColHeight] = useState<any>(null);
  const [pdfFile, setPDFFile] = useState<string>('');
  const [pdfModalVisible, setPdfModalVisible] = useState<boolean>(false);
  const [loadingAdditionalFiles, setLoadingAdditionalFiles] = useState<boolean>(false);

  const thumbnailRef = React.useRef(null);
  const thumbnailContainerRef = React.useRef(null);

  useEffect(() => {
    // Initial view loading, get the files into list and render thumbnails
    if (props.displayed && props.displayed === 'FEED' && fileList && !fileList?.length) {
      const fileSchema = getSchemaFromShortListByModuleAndEntity(
        props.schemaReducer?.shortList,
        SCHEMA_MODULE,
        SchemaModuleEntityTypeEnums.FILE,
      );

      if (fileSchema) {
        setSchema(fileSchema);
        setFileList(recordReducer.list[fileSchema?.id]);
      } else {
        getSchema(
          { moduleName: SCHEMA_MODULE, entityName: SchemaModuleEntityTypeEnums.FILE },
          (response: SchemaEntity) => {
            if (response) {
              setSchema(response);
              setFileList(recordReducer.list[response?.id]);
            }
          },
        );
      }
    }
  }, [props.displayed]);

  // On each file list update, add new files to the list
  useEffect(() => {
    if (
      !recordReducer.isSearching &&
      !recordReducer.isRequesting &&
      schema &&
      recordReducer.list &&
      schema
    ) {
      if (fileList?.length > 0 && loadingAdditionalFiles) {
        setFileList(fileList.concat(recordReducer.list[schema.id]));
        setLoadingAdditionalFiles(false);
      } else {
        setFileList(recordReducer.list[schema.id]);
      }
    }
  }, [recordReducer.list, schema]);

  // When view changed to Thumbnail, adjust dynamic column height.
  useEffect(() => {
    if (fileList) {
      setColumnHeight();
    }
  }, [props.displayed]);

  // When page number is changed, run query with page incremented
  useEffect(() => {
    if (schema && schema.id && fileListPageNum) {
      searchRecords({
        schema: {
          id: schema.id,
          entityName: 'Files',
          moduleName: 'SchemaModule',
        },
        searchQuery: {
          terms: recordReducer.search.terms,
          schemas: [schema.id],
          sort: [{ updatedAt: { order: 'desc' } }],
          pageable: {
            page: fileListPageNum,
            size: 15,
          },
        },
      });
    }
  }, [fileListPageNum]);

  // If scrolled to bottom, add new files to list
  const handleScroll = (e: any) => {
    const target = e.target;

    if (
      target.scrollTop + target.clientHeight < target.scrollHeight + 10 &&
      !loadingAdditionalFiles &&
      recordReducer.list[schema.id]?.length > 14
    ) {
      setLoadingAdditionalFiles(true);
      setFileListPageNum(fileListPageNum + 1);
    }
  };

  const setColumnHeight = () => {
    if (thumbnailRef && thumbnailRef.current) {
      let width = window.getComputedStyle(thumbnailRef.current!).width;
      setColHeight(width);
    }
  };

  const togglePDFModal = () => setPdfModalVisible(!pdfModalVisible);

  useLayoutEffect(() => {
    if (thumbnailRef && thumbnailRef?.current) {
      setColumnHeight();
    }
  }, [fileList]);

  const showPDFViewer = (record: DbRecordEntityTransform) => {
    setPDFFile(getProperty(record, 'Url'));
    setPdfModalVisible(true);
  };

  return (
    <Row
      style={{
        padding: 20,
        height: '75vh',
        overflowY: 'scroll',
        marginBottom: 20,
        background: 'white',
      }}
      ref={thumbnailContainerRef}
      onScroll={handleScroll}
    >
      <PDFModalViewer
        isModalVisible={pdfModalVisible}
        file={pdfFile}
        togglePDFModal={togglePDFModal}
      />

      <Col span={24}>
        <Row gutter={[16, 16]}>
          {fileList && fileList.length > 0 ? (
            fileList.map((file: DbRecordEntityTransform) => {
              return file ? (
                <FileCard
                  record={file}
                  thumbnailRef={thumbnailRef}
                  colHeight={colHeight}
                  showPDFViewer={showPDFViewer}
                  fileCardSize={4}
                />
              ) : (
                <></>
              );
            })
          ) : (
            <Col span={24} style={{ textAlign: 'center', marginTop: 50 }}>
              <Spin size="large" />
            </Col>
          )}
        </Row>
        {loadingAdditionalFiles ? (
          <Row style={{ padding: '30px 0' }}>
            <Col span={24} style={{ padding: 10, textAlign: 'center' }}>
              <span>
                <Spin />
              </span>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};

const mapDispatch = (dispatch: any, ownProps: any) => ({
  searchRecords: (params: { schema: SchemaEntity; searchQuery: SearchQueryType }, cb: any) =>
    dispatch(searchRecordsRequest(params, cb)),
  setSearchQuery: (params: ISearchRecords) => dispatch(setDbRecordSearchQuery(params)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
});

export default connect(mapState, mapDispatch)(FileFeed);
