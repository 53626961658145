import { call, put, takeLatest } from 'redux-saga/effects';
import {
  RUN_WFS_QUERY_ERROR,
  RUN_WFS_QUERY_REQUEST,
  RUN_WFS_QUERY_SUCCESS,
  SEARCH_BY_NOI_REF_ERROR,
  SEARCH_BY_NOI_REF_REQUEST,
  SEARCH_BY_NOI_REF_SUCCESS,
  SEARCH_BY_POSTCODE_ERROR,
  SEARCH_BY_POSTCODE_REQUEST,
  SEARCH_BY_POSTCODE_SUCCESS,
} from './constants';
import { IMapWFSQuery } from './actions';
import { USER_LOGOUT_REQUEST } from '../../../../../../redux/stores/identity/constants';
import { ERROR_NOTIFICATION } from '../../../../../../redux/stores/notification/reducers';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { httpGet } from '@core/http/requests';

const VITE_QGIS_SERVER_URL =
  'https://api.odin.prod.netomnia.com/cgi-bin/qgis_mapserv.fcgi?map=/home/qgis/projects/project.qgs';

function* WFSRequest(action: { params: IMapWFSQuery; cb: any }): any {
  try {
    const { typeName, featureId } = action.params;

    let path: string = `${VITE_QGIS_SERVER_URL}&SERVICE=WFS&REQUEST=GetFeature&VERSION=1.1.0&TYPENAME=${typeName}&MAXFEATURES=1&OUTPUTFORMAT=GeoJSON&FEATUREID=${featureId}`;
    const response = yield call(async () => await axios.get(path));

    yield put({ type: RUN_WFS_QUERY_SUCCESS });

    if (action.cb) {
      yield call(action.cb, response);
    }
  } catch (e: any) {
    Sentry.captureException(e);
    const error = e.response ? e.response.data : undefined;
    yield put({ type: RUN_WFS_QUERY_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* searchByPostcode(action: { params: { searchQuery: string }; cb: any }): any {
  try {
    const { searchQuery } = action.params;

    let path: string = `ProjectModule/v1.0/ftth/polygon/byPostcode/${searchQuery.toUpperCase()}`;
    const response = yield call(async () => await httpGet(path));

    yield put({ type: SEARCH_BY_POSTCODE_SUCCESS });

    if (action.cb) {
      yield call(action.cb, response);
    }
  } catch (e: any) {
    Sentry.captureException(e);
    const error = e.response ? e.response.data : undefined;
    yield put({ type: SEARCH_BY_POSTCODE_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* searchByNOIRef(action: { params: { searchQuery: string }; cb: any }): any {
  try {
    const { searchQuery } = action.params;

    let path: string = `ProjectModule/v1.0/ftth/cables/byNoiRef/${searchQuery.toUpperCase()}`;
    const response = yield call(async () => await httpGet(path));

    yield put({ type: SEARCH_BY_NOI_REF_SUCCESS });

    if (action.cb) {
      yield call(action.cb, response);
    }
  } catch (e: any) {
    Sentry.captureException(e);
    const error = e.response ? e.response.data : undefined;
    yield put({ type: SEARCH_BY_NOI_REF_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* rootSaga() {
  yield takeLatest<any>(RUN_WFS_QUERY_REQUEST, WFSRequest);
  yield takeLatest<any>(SEARCH_BY_POSTCODE_REQUEST, searchByPostcode);
  yield takeLatest<any>(SEARCH_BY_NOI_REF_REQUEST, searchByNOIRef);
}

export default rootSaga;
