/* Build Pack Map */
export const QGIS_SERVER_BUILDPACK =
  'https://api.odin.dev.netomnia.com/cgi-bin/qgis_mapserv.fcgi?map=/home/qgis/projects/project.qgs';

export type networkDiagramData =
  | [
      {
        nodes: any[];
        edges: any[];
        features: {
          cables: any[];
          chambers: any[];
          closures: any[];
          ducts: any[];
          poles: any[];
          L4polygons: any[];
        };
      },
    ]
  | null;

export const PDFColors = {
  openreach: '#CCFFFF',
  construction: '#CCFFCC',
  cable_openreach: '#FFE6CC',
  cable: '#FFE6CC',
};
