import { isNetomniaUser, isYouFibreUser } from '@core/helpers/identityHelpers';
import DefaultDetailViewRouter from '@core/routes/DefaultDetailViewRouter';
import DefaultRoutes from '@core/routes/DefaultRoutes';
import NetomniaDetailViewRouter from '@netomnia/routes/NetomniaDetailViewRouter';
import NetomniaRoutes from '@netomnia/routes/NetomniaRoutes';
import YoufibreDetailViewRouter from '@youfibre/routes/YoufibreDetailViewRouter';
import YoufibreRoutes from '@youfibre/routes/YoufibreRoutes';
import { ReactNode } from 'react';

export type TRouteConfig = {
  routes: ReactNode[];
  detailViewRouter: ReactNode;
};

export const configureRouter = (): TRouteConfig => {
  let config: TRouteConfig = {
    routes: DefaultRoutes(),
    detailViewRouter: <DefaultDetailViewRouter />,
  };

  const netomniaUser = isNetomniaUser();
  const youfibreUser = isYouFibreUser();

  if (netomniaUser) {
    config = {
      routes: NetomniaRoutes(),
      detailViewRouter: <NetomniaDetailViewRouter />,
    };
  }

  if (youfibreUser) {
    config = {
      routes: YoufibreRoutes(),
      detailViewRouter: <YoufibreDetailViewRouter />,
    };
  }

  return config;
};
