import { Button, Dialog, DialogBody, DialogFooter } from '@blueprintjs/core';
import { DualPanelAssignment } from '@core/components/DualPanelAssignment';
import { httpDelete, httpGet, httpPost } from '@core/http/requests';
import { displayMessage } from '@redux/stores/messages/reducers';
import { FC, useState } from 'react';
import { connect } from 'react-redux';

interface Props {
  permission: any; // ex. company
  roles: any[]; // ex. departments []
  onUpdate: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

type TRole = {
  id: string;
  name: string;
  description: string;
};

const ManagePermissionRolesDialog: FC<Props> = (props: Props) => {
  const { permission, alertMessage, onUpdate } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [allRoles, setAllRoles] = useState<TRole[]>([]);
  const [permissionRoles, setPermissionRoles] = useState<TRole[]>([]);
  const [isLoadingRoles, setIsLoadingRoles] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  // Get ids of roles to add and remove the permissions
  const roleIdsToAddPermission =
    permissionRoles
      .filter((e) => !props.roles?.map((r) => r.id).includes(e.id))
      ?.map((d: any) => d.id) || [];
  const roleIdsToRemovePermission =
    props.roles
      ?.filter((d) => !permissionRoles.map((cd) => cd.id).includes(d.id))
      ?.map((d: any) => d.id) || [];

  const closeModal = () => {
    setIsDialogOpen(false);
    setAllRoles([]);
    setPermissionRoles([]);
  };

  const openModal = () => {
    loadAllRoles();
    getExistingRolesWithPermission();
  };

  const getExistingRolesWithPermission = () => {
    if (props.roles) {
      const roles: any[] =
        props.roles?.map((d: any) => ({
          id: d.id,
          name: d.name,
          description: d.description,
        })) || [];
      setPermissionRoles(roles);
    }
  };

  const loadAllRoles = async () => {
    setIsLoadingRoles(true);
    try {
      const res = await httpGet('IdentityModule/v2.0/roles?size=10000');
      const roles: TRole[] =
        res.data.data?.map((r: any) => ({
          id: r.id,
          name: r.name,
          description: r.description,
        })) || [];

      // Remove roles that are already assigned with permission
      const existingRolesWithPermissionIds = props.roles?.map((r: any) => r.id) || [];
      const filteredRoles = roles.filter((r) => !existingRolesWithPermissionIds.includes(r.id));

      setAllRoles(filteredRoles);
      setIsLoadingRoles(false);
      setIsDialogOpen(true);
    } catch (e: any) {
      setIsLoadingRoles(false);
    }
  };

  // Merge roles to add and remove permissions, then update them
  const updateRolesWithPermission = async () => {
    setIsSaving(true);

    const roleIds = [...roleIdsToAddPermission, ...roleIdsToRemovePermission];

    try {
      await Promise.all(
        roleIds.map(async (roleId: string) => {
          if (roleIdsToAddPermission.includes(roleId)) {
            await httpPost(`IdentityModule/v2.0/roles/${roleId}/permissions`, {
              permissionIds: [permission.id],
            });
          } else if (roleIdsToRemovePermission.includes(roleId)) {
            await httpDelete(`IdentityModule/v2.0/roles/${roleId}/permissions`, {
              permissionIds: [permission.id],
            });
          }
        }),
      );

      alertMessage({ body: 'Roles with permission updated successfully', type: 'success' });
      setIsSaving(false);
      onUpdate();
      closeModal();
    } catch (e: any) {
      alertMessage({ body: 'Failed to update roles with permission', type: 'error' });
      setIsSaving(false);
    }
  };

  const isSaveButtonDisabled = () => {
    const existingRoleIds =
      props.roles?.map((r: any) => r.id)?.sort((a: any, b: any) => a.localeCompare(b)) || [];
    const rolesWithPermissionIds = permissionRoles
      .map((r) => r.id)
      ?.sort((a: any, b: any) => a.localeCompare(b));
    return JSON.stringify(existingRoleIds) === JSON.stringify(rolesWithPermissionIds);
  };

  // Add roles to the list, remove from the left side list
  const onDepartmentPanelChange = (roleId: string) => {
    const role = allRoles.find((r) => r.id === roleId);
    if (role) {
      setPermissionRoles([...permissionRoles, role]);
      setAllRoles(allRoles.filter((d) => d.id !== roleId));
    }
  };

  // Remove roles with permission, add back to the left side list
  const onRolePanelChange = (roleId: string) => {
    const role: TRole | undefined = permissionRoles?.find((r) => r.id === roleId);
    if (role) {
      setPermissionRoles(permissionRoles.filter((r) => r.id !== roleId));
      setAllRoles([...allRoles, role]);
    }
  };

  const clearRolesWithPermissions = () => {
    const newDepartments = [...allRoles, ...permissionRoles].sort((a, b) =>
      a.name.localeCompare(b.name),
    );

    setAllRoles(newDepartments);
    setPermissionRoles([]);
  };

  return (
    <>
      <Button
        small
        minimal
        intent="primary"
        text="Manage"
        loading={isLoadingRoles}
        onClick={openModal}
      />
      <Dialog
        title="Manage Roles with Permission"
        isOpen={isDialogOpen}
        onClose={closeModal}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        style={{ width: '80%' }}
      >
        <DialogBody>
          <DualPanelAssignment
            // Left panel
            leftPanelTitle="Roles"
            leftPanelIcon="id-number"
            leftPanelSubtitle="All available Roles"
            leftPanelData={allRoles}
            onLeftPanelChange={onDepartmentPanelChange}
            // Right Panel
            rightPanelTitle={`${permission.name}`}
            rightPanelIcon="id-number"
            rightPanelSubtitle="All Roles with this Permission"
            rightPanelData={permissionRoles}
            onRightPanelChange={onRolePanelChange}
            onRightPanelClear={clearRolesWithPermissions}
          />
        </DialogBody>
        <DialogFooter
          actions={[
            <Button key="Close" text="Close" onClick={closeModal} />,
            <Button
              key="SaveChanges"
              text="Save Changes"
              disabled={isSaveButtonDisabled()}
              intent="primary"
              onClick={updateRolesWithPermission}
              loading={isSaving}
            />,
          ]}
        />
      </Dialog>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(ManagePermissionRolesDialog);
