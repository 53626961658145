import React from 'react';
import { JSONTable } from '../../../../../../core/components/JSONTable';

const PriceListTableColumnPositions = {
  price_list: -100,
  record_no: -99,
  item_id: -98,
  item_name: -97,
  value: -96,
};

const filterFields = ((item: any) => ({
  price_list: item.PRICELISTID,
  record_no: item.RECORDNO,
  item_id: item.ITEMID,
  item_name: item.ITEMNAME,
  value: item.VALUE,
}));

type PriceListTableProps = {
  dataSource: any[];
  loading?: boolean;
  selectedRowKeys: any[];
  onRowSelectionChanged: (record: any, selected: boolean, selectedRows: any[]) => void;
};

export const PriceListTable = ({
                                 dataSource = [],
                                 loading = false,
                                 selectedRowKeys,
                                 onRowSelectionChanged,
                               }: PriceListTableProps) => {
  return (
    <JSONTable
      jsonDataSource={dataSource.map(filterFields)}
      loading={loading}
      columnPositions={PriceListTableColumnPositions}
      getRowKey={(record: any) => `${record.item_id}`}
      rowClassName={() => ''}
      rowSelection={{
        type: 'checkbox',
        // onChange: (selectedRowKeys: any) => this.onSelectChange(selectedRowKeys),
        onSelect: (record: any, selected: boolean, selectedRows: any) => {
          onRowSelectionChanged(record, selected, selectedRows);
        },
        onSelectAll: (selected: boolean, selectedRows: any, changeRows: any) =>
          onRowSelectionChanged(changeRows, selected, selectedRows),
        selectedRowKeys,
        getCheckboxProps: (record: any) => ({
          disabled: !!record.worklist_number, // Column configuration not to be checked
        }),
      }}
    />
  );
};
