import { Callout, Tag } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';
import { isMobile } from 'react-device-detect';

interface Props {
  record: DbRecordEntityTransform | undefined;
}

const OrderAlertPanel: FC<Props> = (props: Props) => {
  const { record } = props;

  return record ? (
    <Row>
      <Col span={24}>
        <Callout intent="danger" style={{ borderRadius: 5 }}>
          <Row>
            <Col xs={24} md={20} style={{ textAlign: 'justify' }}>
              {getProperty(record, 'AlertMessage')}
            </Col>
            <Col xs={24} md={4} style={{ textAlign: 'right', marginTop: isMobile ? 10 : 0 }}>
              <Tag intent="danger">
                {dayjs(getProperty(record, 'AlertMessageDate')).format('DD/MM/YYYY')}
              </Tag>
            </Col>
          </Row>
        </Callout>
      </Col>
    </Row>
  ) : (
    <></>
  );
};

export default OrderAlertPanel;
