import { Button, Section } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import React, { useState } from 'react';
import ActivityCenter from 'src/core/components/ActivityCenter';
import { Col, Row } from 'antd';
import NoteFeed from '../NoteFeed';

interface Props {
  defaultTab?: 'ACTIVITIES' | 'NOTES';
  record: DbRecordEntityTransform;
  schema: SchemaEntity;
  maxHeightPx?: number;
}

const ActivitiesAndNotes: React.FC<Props> = (props: Props) => {
  const { defaultTab, record, schema, maxHeightPx } = props;

  const [activeTab, setActiveTab] = useState<'ACTIVITIES' | 'NOTES'>(defaultTab || 'ACTIVITIES');

  return (
    <Section
      title="Activities & Notes"
      rightElement={
        <div>
          <Button
            intent="primary"
            outlined={activeTab !== 'ACTIVITIES'}
            onClick={() => setActiveTab('ACTIVITIES')}
            text="Activities"
          />
          <Button
            intent="primary"
            outlined={activeTab !== 'NOTES'}
            onClick={() => setActiveTab('NOTES')}
            text="Notes"
          />
        </div>
      }
    >
      <Row style={{ margin: 10, overflowY: 'scroll', maxHeight: maxHeightPx }}>
        {activeTab === 'ACTIVITIES' && (
          <Col span={24}>
            <ActivityCenter record={record} schema={schema} compact />
          </Col>
        )}
        {activeTab === 'NOTES' && (
          <Col span={24}>
            <NoteFeed record={record} />
          </Col>
        )}
      </Row>
    </Section>
  );
};
export default ActivitiesAndNotes;
