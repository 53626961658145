import React from 'react';
import { Spin } from 'antd';

const AppLoadingPage: React.FC = () => {
  return (
    <div className="loading-page">
      <Spin size="large" />
    </div>
  );
};

export default AppLoadingPage;
