import React, { RefObject } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import {
  MapReducerUpdate,
  updateMapState,
} from '@netomnia/modules/ProjectModule/views/Map/store/actions';
import MapSidebarDynamicLegend from './MapSidebarDynamicLegend';
import MapSidebarSearchFeatureResults from './MapSideBarSearch/MapSidebarSearchFeatureResults';
import MapSidebarSearchProjectResults from './MapSideBarSearch/MapSidebarSearchProjectResults';
import SidebarLayerSwitcher from './MapSidebarLayerSwitcher';
import MapSidebarSearchCard from './MapSideBarSearch/MapSidebarSearchCard';
import './styles.scss';
import MapSidebarSettings from './MapSidebarSettings';
import MapSidebarWorkItems from './MapSidebarWorkItems';
import { MapReducer } from '@netomnia/modules/ProjectModule/views/Map/store/reducer';

type Props = OwnProps;
const tabContentRef = React.createRef<HTMLDivElement>();

interface OwnProps {
  mapReducer: MapReducer;
  updateMap: any;
  visibleLayers: Array<string>;
  layerSwitcherRef: RefObject<any>;
}

interface State {
  inputFieldFeatureID: string;
  inputFieldPostcode: string;
  inputFieldNOIRef: string;
}

class MapSidebar extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      inputFieldFeatureID: '',
      inputFieldPostcode: '',
      inputFieldNOIRef: '',
    };
  }

  // When tab is changed, scroll to the top of tab contents
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (
      prevProps.mapReducer.mapSidebarSection !== this.props.mapReducer.mapSidebarSection &&
      tabContentRef.current
    ) {
      tabContentRef.current.scrollTo(0, 0);
    }
  }

  cleanSearchFormInputFields = () => {
    this.setState({
      inputFieldFeatureID: '',
      inputFieldNOIRef: '',
      inputFieldPostcode: '',
    });
  };

  updateInputFeatureID = (value: string) => this.setState({ inputFieldFeatureID: value });
  updateInputPostcode = (value: string) => this.setState({ inputFieldPostcode: value });
  updateInputNOIRef = (value: string) => this.setState({ inputFieldNOIRef: value });

  render() {
    const { mapReducer, updateMap, visibleLayers, layerSwitcherRef } = this.props;

    return (
      <Col
        className="mapSidebar"
        flex={mapReducer.mapSidebarVisible ? (isMobile ? '100' : '0.45') : '0'}
        style={{
          padding: mapReducer.mapSidebarVisible ? (isMobile ? '15px' : '5px 20px') : '0',
        }}
      >
        <Row style={{ margin: '8px 0' }}>
          {/* Map Sidebar - Floating Close Button */}
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button
              className="mapSidebarCloseSidebarButton"
              icon={<CloseOutlined />}
              size="large"
              onClick={() => {
                updateMap({ mapSidebarVisible: !mapReducer.mapSidebarVisible });
              }}
              ghost
            />
          </Col>
        </Row>

        {/* THREE BUTTON TAB - Selectors */}
        <Row style={{ marginTop: 5, paddingBottom: 20 }}>
          {/* 1. Layers */}
          <Col span={8}>
            <Button
              type="primary"
              ghost={mapReducer.mapSidebarSection !== 'LAYERS'}
              style={{
                width: '100%',
                borderRadius: '7px 0px 0px 7px',
                borderRight: 'none',
                fontWeight: mapReducer.mapSidebarSection === 'LAYERS' ? 500 : '400',
              }}
              onClick={() => updateMap({ mapSidebarSection: 'LAYERS' })}
            >
              Layers
            </Button>
          </Col>
          {/* 2. Features */}
          <Col span={8}>
            <Button
              type="primary"
              ghost={mapReducer.mapSidebarSection !== 'FEATURES'}
              style={{
                width: '100%',
                borderRight: 'none',
                borderRadius: '0',
                fontWeight: mapReducer.mapSidebarSection === 'FEATURES' ? 500 : '400',
              }}
              onClick={() => updateMap({ mapSidebarSection: 'FEATURES' })}
            >
              Features
            </Button>
          </Col>
          {/* 3. Settings */}
          <Col span={8}>
            <Button
              type="primary"
              ghost={mapReducer.mapSidebarSection !== 'WORK_ITEMS'}
              style={{
                width: '100%',
                borderRadius: '0 7px 7px 0',
                fontWeight: mapReducer.mapSidebarSection === 'WORK_ITEMS' ? 500 : '400',
              }}
              onClick={() => updateMap({ mapSidebarSection: 'WORK_ITEMS' })}
            >
              Work Items
            </Button>
          </Col>
        </Row>

        {/* THREE BUTTON TAB - Content */}
        <Row className="mapSidebarContents" ref={tabContentRef}>
          {/* 1. Layer switcher */}
          <Col
            span={24}
            style={{
              display: mapReducer.mapSidebarSection === 'LAYERS' ? 'block' : 'none',
              height: '100%',
              marginBottom: 30,
            }}
          >
            <SidebarLayerSwitcher layerSwitcherRef={layerSwitcherRef} />
            <MapSidebarSettings />
            <MapSidebarDynamicLegend visibleLayers={visibleLayers} />
          </Col>

          {/* 2. Feature Search & Results */}
          <Col
            span={24}
            style={{
              display: mapReducer.mapSidebarSection === 'FEATURES' ? 'block' : 'none',
            }}
          >
            {/* <MapSidebarSavedViews/> */}
            <MapSidebarSearchCard />
            <MapSidebarSearchProjectResults visible={mapReducer.queryLayer === 'polygon'} />
            {mapReducer.mapSidebarFilteringActive ? (
              <></>
            ) : (
              <MapSidebarSearchFeatureResults visible={mapReducer.queryLayer !== 'polygon'} />
            )}
          </Col>

          {/* 3. Work Items */}
          <Col
            span={24}
            style={{
              display: mapReducer.mapSidebarSection === 'WORK_ITEMS' ? 'block' : 'none',
            }}
          >
            <MapSidebarWorkItems />
          </Col>
        </Row>
      </Col>
    );
  }
}

const mapDispatch = (dispatch: any) => ({
  updateMap: (params: MapReducerUpdate) => dispatch(updateMapState(params)),
});

const mapState = (state: any) => ({
  mapReducer: state.mapReducer,
});

export default connect(mapState, mapDispatch)(MapSidebar);
