import React, { useContext } from 'react';
import { Card, Col, Empty, Row, Spin, Typography } from 'antd';
import { addRecordToShortList, IAddRecordToShortList } from '@redux/stores/records/actions';
import { IRecordReducer } from '@redux/stores/records/reducer';
import InvoiceItemCard from './InvoiceItemCard';
import { connect } from 'react-redux';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
//import { InvoiceItem } from '../types';
import { invoiceBuilderContext } from '../index';
import BillingAdjustmentPanel from '../BillingAdjustmentPanel';
import DiscountPanel from '../DiscountPanel';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { calculateInvoiceTotals } from '../billingHelpers';
import { sortBy } from 'lodash';

interface Props {
  recordReducer: IRecordReducer;
  shortListRecord: (params: IAddRecordToShortList) => {};
  recordAssociationReducer: any;
}

export const renderInvoiceSummary = (state: any) => {
  const invoiceSummary = calculateInvoiceTotals(state.orderRecord!, state.invoiceItems);

  return (
    <Row style={{ textAlign: 'right', padding: 10 }}>
      {invoiceSummary?.Adjustment ? (
        <Col span={24} style={{ fontSize: '1.2em' }}>
          Subtotal excl. adjustment:{' '}
          {state.invoiceItems
            .filter((item: any) => getProperty(item, 'Type') !== 'Type')
            ?.reduce((acc: any, elem: DbRecordEntityTransform) => {
              return acc + Number(getProperty(elem, 'Subtotal'));
            }, 0)
            .toFixed(2) || '0.00'}
        </Col>
      ) : (
        <></>
      )}

      <Col span={24} style={{ fontSize: '1.2em' }}>
        Subtotal: {invoiceSummary?.Subtotal || '0.00'}
      </Col>

      <Col span={24} style={{ fontSize: '1.2em' }}>
        Total Discounts: {invoiceSummary?.TotalDiscounts || '0.00'}
      </Col>

      <Col span={24} style={{ fontSize: '1.2em' }}>
        Total VAT{state.invoiceType === 'RESIDENTIAL' ? '(Incl.): ' : ': '}
        {invoiceSummary?.TotalTaxAmount || '0.00'}
      </Col>

      <Col span={24} style={{ marginTop: 10 }}>
        <Typography.Title level={3}>Total: {String(invoiceSummary?.TotalPrice)}</Typography.Title>
      </Col>
    </Row>
  );
};

// Sort items by type and remove BAs
const getSortedInvoiceItemsList = (invoiceItems: DbRecordEntityTransform[]) => {
  if (invoiceItems?.length > 0) {
    let sortedInvoiceItems = Object.assign(invoiceItems);

    sortedInvoiceItems = sortedInvoiceItems.filter(
      (item: DbRecordEntityTransform) =>
        item.entity === 'ProductModule:Product' || item.entity === 'OrderModule:OrderItem',
    );

    sortedInvoiceItems = sortBy(sortedInvoiceItems, 'entity');
    return sortedInvoiceItems;
  } else {
    return [];
  }
};

const InvoiceItemsList: React.FC<Props> = (props: Props) => {
  const { state } = useContext(invoiceBuilderContext);

  const sortedInvoiceItems = getSortedInvoiceItemsList(state.invoiceItems);

  return (
    <>
      {state.isLoadingParentRecordAssociations ? (
        <Row style={{ padding: 70, textAlign: 'center' }}>
          <Col span={24} style={{ marginBottom: 10 }}>
            <span>Loading initial products...</span>
          </Col>
          <Col span={24}>
            <Spin />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            {state.invoiceItems?.length > 0 ? (
              sortedInvoiceItems?.map((singleInvoiceProduct: DbRecordEntityTransform) => (
                <InvoiceItemCard invoiceProduct={singleInvoiceProduct} />
              ))
            ) : (
              <Col span={24} style={{ padding: 5 }}>
                <Card style={{ padding: '40px 0' }} bordered={false}>
                  <Empty
                    description={
                      <span>
                        There are no invoice items.
                        <br />
                        Please add at least one product to the invoice.
                      </span>
                    }
                  />
                </Card>
              </Col>
            )}
          </Row>
          <Row style={{ paddingTop: 5 }}>
            {state.billingAdjustment ? (
              <Col sm={24} lg={24}>
                <BillingAdjustmentPanel />
              </Col>
            ) : (
              <></>
            )}
            {state.discount ? (
              <Col sm={24} lg={24}>
                <DiscountPanel />
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            <Col span={24}>
              {state.orderRecord && state.invoiceItems?.length > 0 ? (
                renderInvoiceSummary(state)
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
});

const mapDispatch = (dispatch: any) => ({
  shortListRecord: (params: IAddRecordToShortList) => dispatch(addRecordToShortList(params)),
});

export default connect(mapState, mapDispatch)(InvoiceItemsList);
