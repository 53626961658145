import { Button, Dialog, DialogBody, DialogFooter } from '@blueprintjs/core';
import { OrganizationUserEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/organization.user.entity';
import { FC, useState } from 'react';
import { connect } from 'react-redux';
import { httpDelete, httpGet, httpPost } from '@core/http/requests';
import { displayMessage } from '@redux/stores/messages/reducers';
import { DualPanelAssignment } from '@core/components/DualPanelAssignment';
import { getErrorMessage } from '@core/modules/ControlPanelModule/helpers/errors';
import './styles.scss';

interface Props {
  user: OrganizationUserEntity;
  groups: any[];
  alertMessage: (params: { body: string; type: string }) => void;
  onUpdate: () => void;
}

type TGroup = {
  id: string;
  name: string;
  description: string;
};

const ManageUserGroupsDialog: FC<Props> = (props: Props) => {
  const { user, alertMessage, onUpdate } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [allGroups, setAllGroups] = useState<TGroup[]>([]);
  const [userGroups, setUserGroups] = useState<TGroup[]>([]);
  const [isLoadingGroups, setIsLoadingGroups] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [searchQuery, setSearchQuery] = useState<string>('');

  const closeModal = () => {
    setIsDialogOpen(false);
    setAllGroups([]);
    setUserGroups([]);
  };

  const openModal = () => {
    loadAllGroups();
    getUsersGroups();
  };

  const getUsersGroups = () => {
    if (props.groups) {
      const groups: TGroup[] =
        props.groups?.map((role: any) => ({
          id: role.id,
          name: role.name,
          description: role.description,
        })) || [];
      setUserGroups(groups);
    }
  };

  const loadAllGroups = async () => {
    setIsLoadingGroups(true);
    try {
      const res = await httpGet('IdentityModule/v2.0/groups?size=10000');
      const groups: TGroup[] =
        res.data.data?.map((group: any) => ({
          id: group.id,
          name: group.name,
          description: group.description,
        })) || [];

      // Remove roles that the user already has
      const existingUserGroups = props.groups?.map((group: any) => group.id) || [];
      const filteredGroups = groups.filter((group) => !existingUserGroups.includes(group.id));

      setAllGroups(filteredGroups);
      setIsLoadingGroups(false);
      setIsDialogOpen(true);
    } catch (e: any) {
      setIsLoadingGroups(false);
    }
  };

  const addGroups = async () => {
    const groupIds =
      userGroups
        .filter((g) => !props.groups?.map((ug) => ug.id).includes(g.id))
        ?.map((g: any) => g.id) || [];

    if (!groupIds.length || !user) return;
    try {
      await httpPost(`IdentityModule/v2.0/users/${user.id}/groups`, {
        groupIds,
      });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({ body: 'Could not add groups to a user. ' + message, type: 'error' });
    }
  };

  const removeGroups = async () => {
    const groupIds =
      props.groups
        ?.filter((g) => !userGroups.map((ug) => ug.id).includes(g.id))
        ?.map((g: any) => g.id) || [];

    if (!groupIds.length || !user) return;
    try {
      await httpDelete(`IdentityModule/v2.0/users/${user.id}/groups`, {
        groupIds,
      });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({ body: 'Could not remove groups from a user. ' + message, type: 'error' });
    }
  };

  const updateGroupsOnUser = async () => {
    setIsSaving(true);

    const addGroupsPromise = addGroups();
    const removeGroupsPromise = removeGroups();

    try {
      await Promise.all([addGroupsPromise, removeGroupsPromise]);
      alertMessage({ body: 'Groups updated successfully', type: 'success' });
      setIsSaving(false);
      closeModal();
      onUpdate();
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not update groups for a user. ' + message,
        type: 'error',
      });
      setIsSaving(false);
    }
  };

  const isSaveButtonDisabled = () => {
    const existingUserGroupIds =
      props.groups?.map((group: any) => group.id)?.sort((a: any, b: any) => a.localeCompare(b)) ||
      [];
    const userGroupsIds = userGroups
      .map((group) => group.id)
      ?.sort((a: any, b: any) => a.localeCompare(b));

    return JSON.stringify(existingUserGroupIds) === JSON.stringify(userGroupsIds);
  };

  // Add group to user, remove from the groups list
  const onGroupPanelChange = (groupId: string) => {
    const group = allGroups.find((group) => group.id === groupId);
    if (group) {
      setUserGroups([...userGroups, group]);
      setAllGroups(allGroups.filter((group) => group.id !== groupId));
    }
  };

  // Remove group from user, add to the group list
  const onUserPanelChange = (groupId: string) => {
    const group: TGroup | undefined = userGroups?.find((group) => group.id === groupId);
    if (group) {
      setUserGroups(userGroups.filter((group) => group.id !== groupId));
      setAllGroups([...allGroups, group]);
    }
  };

  const clearUserGroups = () => {
    const newGroups = [...allGroups, ...userGroups].sort((a, b) => a.name.localeCompare(b.name));
    setAllGroups(newGroups);
    setUserGroups([]);
  };

  return (
    <>
      <Button
        small
        minimal
        intent="primary"
        text="Manage"
        loading={isLoadingGroups}
        onClick={openModal}
      />
      <Dialog
        title="Manage User's Groups"
        isOpen={isDialogOpen}
        onClose={closeModal}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        style={{ width: '80%' }}
      >
        <DialogBody className="assignRolesDialog">
          <DualPanelAssignment
            // Left panel
            leftPanelTitle="Groups"
            leftPanelIcon="people"
            leftPanelSubtitle="All available groups"
            leftPanelData={allGroups}
            onLeftPanelChange={onGroupPanelChange}
            // Right Panel
            rightPanelTitle={`${user.firstname} ${user.lastname}`}
            rightPanelIcon="person"
            rightPanelSubtitle="All groups this user belongs to"
            rightPanelData={userGroups}
            onRightPanelChange={onUserPanelChange}
            onRightPanelClear={clearUserGroups}
          />
        </DialogBody>
        <DialogFooter
          actions={[
            <Button key="Close" text="Close" onClick={closeModal} />,
            <Button
              key="SaveChanges"
              text="Save Changes"
              disabled={isSaveButtonDisabled()}
              intent="primary"
              onClick={updateGroupsOnUser}
              loading={isSaving}
            />,
          ]}
        />
      </Dialog>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(ManageUserGroupsDialog);
