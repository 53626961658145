import React from 'react';
import { Alert, Form, Input, Select } from 'antd';

export type RecordRejectionDetails = {
  RejectionReason: string;
  RejectionComment: string;
};

type RejectRecordModalProps = {
  errorMessage?: string;
  setRejectionReason: (reason: string) => void;
  setRejectionComment: (comment: string) => void;
};

export const RejectRecordModal = ({
  errorMessage,
  setRejectionReason,
  setRejectionComment,
}: RejectRecordModalProps) => {
  return (
    <>
      {!!errorMessage && (
        <>
          <Alert message={errorMessage} type="error" /> <br />
        </>
      )}

      <Form name={'RegisterUserView New User'} autoComplete="off">
        <Form.Item
          key={'RejectionReason'}
          name={'RejectionReason'}
          label="Rejection Reason"
          //labelCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please input email' }]}
        >
          <Select
            onChange={(val) => {
              setRejectionReason(val);
            }}
          >
            <Select.Option value="DUPLICATE">Duplicate</Select.Option>
            <Select.Option value="INCORRECT_FEATURE_ID">Incorrect Feature ID</Select.Option>
            <Select.Option value="OVERCLAIM">Overclaim</Select.Option>
            <Select.Option value="UNDERCLAIM">Underclaim</Select.Option>
            <Select.Option value="WRONG_PROJECT">Wrong Project</Select.Option>
            <Select.Option value="NOT_BUILD_DONE_7">Not Build Done 7</Select.Option>
            <Select.Option value="NO_PURCHASE_ORDER">No Purchase Order</Select.Option>
            <Select.Option value="DEFECTS">Defects</Select.Option>
            <Select.Option value="INCORRECT_COST_CODE">Incorrect cost code</Select.Option>
            <Select.Option value="EVIDENCE_REQUIRED">Evidence Required</Select.Option>
            <Select.Option value="INCORRECT_POLYGON">Incorrect Polygon</Select.Option>
            <Select.Option value="REMOVE_FROM_AFP">Remove From AFP</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          key={'RejectionComment'}
          name={'RejectionComment'}
          label="Rejecton Comment"
          //labelCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please enter a rejection comment' }]}
        >
          <Input
            type="text"
            placeholder="Rejection comment"
            size="large"
            autoFocus
            onChange={(e) => setRejectionComment(e.target.value)}
          />
        </Form.Item>
      </Form>
    </>
  );
};
