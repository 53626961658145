import { displayMessage } from '@redux/stores/messages/reducers';
import { ISearchRecords, searchRecordsRequest } from '@redux/stores/records/actions';
import { Col, Flex, Row } from 'antd';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ChatQueueCard from './MetricComponents/ChatQueueCard';
import CurrentCasesStatus from './MetricComponents/CurrentCasesStatus';
import MaxWaitTimeCard from './MetricComponents/MaxWaitTimeCard';
import OngoingChatsConcurrencyChart from './MetricComponents/OngoingChatsConcurrencyChart';
import OngoingChatsLengthChart from './MetricComponents/OngoingChatsLengthChart';
import OpenOfflineCases from './MetricComponents/OpenOfflineCases';
import OutOfSlaCasesCard from './MetricComponents/OutOfSlaCasesCard';
import OutOfSlaCategoryChart from './MetricComponents/OutOfSlaCategoryChart';
import SectionCard from './SectionCard';

interface Props {
  searchRecords: (params: ISearchRecords, cb: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

const CaseManagementDashboard = (props: Props) => {
  const [refreshBit, setRefreshBit] = useState<0 | 1>(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshBit((prev) => (prev === 0 ? 1 : 0));
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
      <Row>
        <Col span={24}>
          <h1 style={{ margin: 0 }}>Dashboard</h1>
        </Col>
      </Row>

      <Row
        style={{
          marginTop: 30,
          height: 'calc(100vh - 140px)',
          alignContent: 'flex-start',
          gap: '16px',
        }}
      >
        <Flex flex={1} gap={16} vertical>
          <SectionCard title="Offline Case Load">
            <Flex gap={8}>
              <Flex flex={1}>
                <OpenOfflineCases refreshBit={refreshBit} />
              </Flex>
              <Flex flex={1}>
                <OutOfSlaCasesCard refreshBit={refreshBit} />
              </Flex>
            </Flex>

            <Flex gap={8}>
              <Flex flex={1}>
                <CurrentCasesStatus refreshBit={refreshBit} />
              </Flex>
              <Flex flex={1}>
                <OutOfSlaCategoryChart refreshBit={refreshBit} />
              </Flex>
            </Flex>
          </SectionCard>
          <SectionCard title="Calls"></SectionCard>
        </Flex>

        <Flex flex={1} gap={16} vertical>
          <SectionCard title="Agents"></SectionCard>
          <SectionCard title="Chats">
            <Flex flex={1} gap={8}>
              <Flex flex={1}>
                <span></span>
                <ChatQueueCard refreshBit={refreshBit} />
              </Flex>
              <Flex flex={1}>
                <MaxWaitTimeCard refreshBit={refreshBit} />
              </Flex>
            </Flex>
            <OngoingChatsConcurrencyChart refreshBit={refreshBit} />
            <OngoingChatsLengthChart refreshBit={refreshBit} />
          </SectionCard>
        </Flex>
      </Row>
    </div>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(CaseManagementDashboard);
