import { useEffect, useRef } from 'react';

/*
 * Access previous props or state with Functional React components
 * Usage: const prevSomething = usePrevious(something)
 */
export function usePrevious(value: any) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
