import { Alert, Button, Collapse, Divider, EntityTitle, Section, Spinner } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { OrganizationUserRbacRoleEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/rbac/role/organization.user.rbac.role.entity';

import SharedFormModal, {
  FormReducerSubmitEvt,
} from '@core/components/Forms/SharedForm/SharedFormModal';
import { initializeSharedForm } from '@redux/stores/sharedForm/actions';
import { httpDelete, httpGet, httpPost } from '@core/http/requests';
import { displayMessage } from '@redux/stores/messages/reducers';

import ManageRolePermissionsDialog from '../ManageRolePermissionsDialog';
import { RoleViewUsersDialog } from '../RoleViewUsersDialog';
import { createRoleFormFields } from '../FormFields';
import { getErrorMessage } from '@core/modules/ControlPanelModule/helpers/errors';

interface Props {
  role: any;
  initializeForm: (params: any) => void;
  onUpdate: () => void;
  onDelete: () => void;
  onClone: (role: OrganizationUserRbacRoleEntity) => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

const cloneRoleForm = uuidv4();

const RoleDetails: FC<Props> = (props: Props) => {
  const { role, onUpdate, onClone, initializeForm, alertMessage, onDelete } = props;
  const [isShowingFullDetails, setIsShowingFullDetails] = useState<boolean>(true);
  const [isDeleteRoleAlertVisible, setIsDeleteRoleAlertVisible] = useState<boolean>(false);
  const [copiedIdToClipboard, setCopiedIdToClipboard] = useState<boolean>(false);

  const [permissions, setPermissions] = useState<any[]>([]);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState<boolean>(false);
  const [users, setUsers] = useState<any[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);

  const showCloneRoleModal = () => {
    initializeForm({
      showModal: true,
      formUUID: cloneRoleForm,
      title: 'Clone Role',
      formFields: createRoleFormFields,
      entityName: 'Role',
    });
  };

  useEffect(() => {
    if (role) {
      setUsers([]);
      setPermissions([]);
      getAssociatedUsers(role.id);
      getAssociatedPermissions(role.id);
    }
  }, [role]);

  const getAssociatedPermissions = async (roleId: string) => {
    setIsLoadingPermissions(true);
    try {
      const permissionsResponse = await httpGet(`IdentityModule/v2.0/roles/${roleId}/permissions`);
      const permissions = permissionsResponse.data?.data;
      if (permissions) {
        setPermissions(permissions);
      }
      setIsLoadingPermissions(false);
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Could not fetch associated permissions. ' + message, type: 'error' });
      setIsLoadingPermissions(false);
    }
  };

  const getAssociatedUsers = async (roleId: string) => {
    setIsLoadingUsers(true);
    try {
      const usersResponse = await httpGet(`IdentityModule/v2.0/roles/${roleId}/users`);
      const users = usersResponse.data?.data;
      if (users) {
        setUsers(users);
      }
      setIsLoadingUsers(false);
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Could not fetch associated users. ' + message, type: 'error' });
      setIsLoadingUsers(false);
    }
  };

  const handleFormSubmit = (params: any) => {
    if (params.title === 'Edit Role') {
      // editRole(params.data);
    }

    if (params.title === 'Clone Role') {
      cloneRole(params.data);
    }
  };

  const deleteRole = async () => {
    try {
      await httpDelete(`IdentityModule/v1.0/rbac/roles/${role.id}`);
      alertMessage({ body: 'Role deleted', type: 'success' });
      onDelete();
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Error removing role. ' + message, type: 'error' });
    }
  };

  const cloneRole = async (roleData: any) => {
    try {
      const response = await httpPost(`IdentityModule/v2.0/roles/${role.id}/clone`, {
        name: roleData.name,
        description: roleData.description,
      });
      alertMessage({ body: 'Role cloned', type: 'success' });
      onClone(response.data.data);
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Error cloning role. ' + message, type: 'error' });
    }
  };

  const copyIdToClipboard = (id: any) => {
    navigator.clipboard.writeText(id);
    setCopiedIdToClipboard(true);
    setTimeout(() => {
      setCopiedIdToClipboard(false);
    }, 1500);
  };

  return (
    <>
      <Row style={{ padding: 15 }}>
        {/* Edit Role Form Modal */}
        <SharedFormModal
          formUUID={role.id}
          onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
        />
        {/* Clone Role Form Modal */}
        <SharedFormModal
          formUUID={cloneRoleForm}
          onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
        />

        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h3 style={{ marginTop: 0, marginBottom: 5, lineBreak: 'anywhere' }}>{role.name}</h3>
              <span>{role.description}</span>
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ marginTop: 20, marginBottom: 0 }}>
          <Row gutter={8}>
            <Col span={8}>
              <Button icon="trash" intent="danger" text="Delete" disabled fill />
            </Col>
            <Col span={8}>
              <Button text="Edit" disabled fill icon="annotation" />
            </Col>
            <Col span={8}>
              <Button text="Clone" fill icon="duplicate" onClick={showCloneRoleModal} />
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Permissions */}
        <Col span={24} className="detailViewSection">
          <Section
            title={`Permissions ${permissions.length ? `(${permissions.length})` : ''}`}
            rightElement={
              !isLoadingPermissions ? (
                <ManageRolePermissionsDialog
                  role={role}
                  permissions={permissions}
                  onUpdate={onUpdate}
                />
              ) : (
                <></>
              )
            }
          >
            <Row>
              {/* Permissions list */}
              {permissions.slice(0, 3)?.map((permission: any) => (
                <Col span={24} key={permission.id}>
                  <EntityTitle key={permission.id} icon="key" title={permission.name} />
                </Col>
              ))}
              {/* Show how many more Permissions  */}
              {permissions.length > 3 && (
                <Col span={24} style={{ marginTop: 8 }}>
                  <span>{`+ ${permissions.length - 3} more`}</span>
                </Col>
              )}
              {!permissions.length && (
                <Col span={24}>
                  <span>
                    {isLoadingPermissions ? (
                      <Spinner size={15} style={{ justifyContent: 'start' }} />
                    ) : (
                      'None'
                    )}
                  </span>
                </Col>
              )}
            </Row>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Users */}
        <Col span={24} className="detailViewSection">
          <Section
            title={`Users ${users.length > 0 ? `(${users?.length})` : ''}`}
            rightElement={
              !isLoadingUsers && users.length > 0 ? (
                <RoleViewUsersDialog role={role} users={users} />
              ) : (
                <></>
              )
            }
          >
            <Row>
              {/* Users list */}
              {users?.slice(0, 3)?.map((user: any) => (
                <Col span={24} key={user.id}>
                  <EntityTitle key={user.id} icon="person" title={user.name} />
                </Col>
              ))}
              {/* Show how many more Users  */}
              {users.length > 3 && (
                <Col span={24} style={{ marginTop: 8 }}>
                  <span>{`+ ${users.length - 3} more`}</span>
                </Col>
              )}
              {!users?.length && (
                <Col span={24}>
                  <span>
                    {isLoadingUsers ? (
                      <Spinner size={15} style={{ justifyContent: 'start' }} />
                    ) : (
                      'None'
                    )}
                  </span>
                </Col>
              )}
            </Row>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Role ID */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Role Id"
            rightElement={
              <Button
                minimal
                small
                rightIcon={copiedIdToClipboard ? 'tick' : null}
                text={copiedIdToClipboard ? 'Copied' : 'Copy'}
                intent={copiedIdToClipboard ? 'success' : 'primary'}
                onClick={() => copyIdToClipboard(role.id)}
              />
            }
          >
            <span>{role.id}</span>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Full Details */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Full Details"
            rightElement={
              <Button
                minimal
                small
                text={isShowingFullDetails ? 'Hide' : 'Show'}
                intent="primary"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setIsShowingFullDetails(!isShowingFullDetails);
                }}
              />
            }
          >
            <Row>
              <Col span={24}>
                <Collapse isOpen={isShowingFullDetails}>
                  <Row>
                    {/* Updated At */}
                    <Col span={24} style={{ marginTop: 5 }}>
                      <EntityTitle
                        key="updatedAt"
                        title="Updated At"
                        subtitle={
                          <span>
                            {dayjs(role.updated_at).format('DD/MM/YYYY HH:mm:ss') || 'None'}
                          </span>
                        }
                      />
                    </Col>

                    {/* Created At */}
                    <Col span={24} style={{ marginTop: 10, marginBottom: 20 }}>
                      <EntityTitle
                        key="createdAt"
                        title="Created At"
                        subtitle={
                          <span>
                            {dayjs(role.created_at).format('DD/MM/YYYY HH:mm:ss') || 'None'}
                          </span>
                        }
                      />
                    </Col>
                  </Row>
                </Collapse>
              </Col>
            </Row>
          </Section>
        </Col>
      </Row>

      {/* Delete Role Alert */}
      <Alert
        intent="danger"
        canEscapeKeyCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        isOpen={isDeleteRoleAlertVisible}
        onCancel={() => setIsDeleteRoleAlertVisible(false)}
        onClose={() => setIsDeleteRoleAlertVisible(false)}
        onConfirm={deleteRole}
      >
        <p>Are you sure you want to delete this role? This action cannot be undone.</p>
      </Alert>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(RoleDetails);
