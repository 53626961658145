import { SearchV2Filter } from '@core/modules/SupportModule/views/SupportDashboard/views/CaseManagementCaseListView/context/types';

import { OdinDropdownOption } from '../OdinDropdown';

export type GlobalFilterItem = {
  description?: string;
  filters?: SearchV2Filter[];
} & OdinDropdownOption;

type GlobalFilter = {
  placeholder: string;
  items: GlobalFilterItem[];
};

export const GLOBAL_FILTERS: GlobalFilter[] = [
  {
    placeholder: 'Open Web Chats',
    items: [
      {
        id: 'owc-1',
        text: 'Open Web Chats - All',
        alternativeText: 'All',
        description: 'Live chat queue of waiting or chats in progress',
        filters: [
          { columnName: 'Channel', operator: 'eq', value: 'WEB_CHAT' },
          { columnName: 'ConversationStatus', operator: 'eq', value: 'CREATED' },
          { columnName: 'StageName', operator: 'eq', value: 'Open' },
        ],
      },
      {
        id: 'owc-2',
        text: 'Open Web Chats - Installs & Service Visits',
        alternativeText: 'Installs & Service Visits',
        description: 'Live chat queue of waiting or chats in progress for the Installation team',
        filters: [
          { columnName: 'Channel', operator: 'eq', value: 'WEB_CHAT' },
          { columnName: 'ConversationStatus', operator: 'eq', value: 'CREATED' },
          { columnName: 'StageName', operator: 'eq', value: 'Open' },
          { columnName: 'Category', operator: 'eq', value: 'INSTALLS_AND_SERVICE_VISITS' },
        ],
      },
    ],
  },
  {
    placeholder: 'Actionable Cases',
    items: [
      {
        id: 'ac-1',
        text: 'Actionable Cases - All',
        alternativeText: 'All',
        description: 'All cases that are actionable and require action',
        filters: [
          { columnName: 'StageName', operator: 'not in', value: ['Closed'] },
          { columnName: 'StageName', operator: 'not in', value: ['Solved'] },
          { columnName: 'StageName', operator: 'not in', value: ['Pending Reply'] },
          { columnName: 'StageName', operator: 'not in', value: ['Blocked'] },
        ],
      },
      {
        id: 'ac-2',
        text: 'Actionable Cases - Installs & Service Visits',
        alternativeText: 'Installs & Service Visits',
        description: 'All cases that are actionable and require action for the Installation team',
        filters: [
          { columnName: 'StageName', operator: 'not in', value: ['Closed'] },
          { columnName: 'StageName', operator: 'not in', value: ['Solved'] },
          { columnName: 'StageName', operator: 'not in', value: ['Pending Reply'] },
          { columnName: 'StageName', operator: 'not in', value: ['Blocked'] },
          { columnName: 'Category', operator: 'eq', value: 'INSTALLS_AND_SERVICE_VISITS' },
        ],
      },
      {
        id: 'ac-3',
        text: 'Actionable Cases - Contract Buyouts',
        alternativeText: 'Contract Buyouts',
        description: 'All Contract buyout cases awaiting action',
        filters: [
          { columnName: 'StageName', operator: 'not in', value: ['Closed'] },
          { columnName: 'StageName', operator: 'not in', value: ['Solved'] },
          { columnName: 'StageName', operator: 'not in', value: ['Pending Reply'] },
          { columnName: 'StageName', operator: 'not in', value: ['Blocked'] },
          { columnName: 'SubCategory', operator: 'eq', value: 'CONTRACT_BUYOUT' },
        ],
      },
      {
        id: 'ac-4',
        text: 'Actionable Cases - Complaints',
        alternativeText: 'Complaints',
        description: 'All Complaint cases awaiting action',
        filters: [
          { columnName: 'StageName', operator: 'not in', value: ['Closed'] },
          { columnName: 'StageName', operator: 'not in', value: ['Solved'] },
          { columnName: 'StageName', operator: 'not in', value: ['Pending Reply'] },
          { columnName: 'StageName', operator: 'not in', value: ['Blocked'] },
          { columnName: 'Category', operator: 'eq', value: 'COMPLAINT' },
        ],
      },
      {
        id: 'ac-5',
        text: 'Actionable Cases - Phone Porting',
        alternativeText: 'Phone Porting',
        description: 'All Magrathea cases awaiting action',
        filters: [
          { columnName: 'StageName', operator: 'not in', value: ['Closed'] },
          { columnName: 'StageName', operator: 'not in', value: ['Solved'] },
          { columnName: 'StageName', operator: 'not in', value: ['Pending Reply'] },
          { columnName: 'StageName', operator: 'not in', value: ['Blocked'] },
          {
            columnName: 'ContactId',
            operator: 'eq',
            value: 'cad652a6-21cb-4738-bbbb-3924ccbe7186',
          },
        ],
      },
    ],
  },
  {
    placeholder: 'Pending Review',
    items: [
      {
        id: 'pr-1',
        text: 'Pending Review - All',
        alternativeText: 'All',
        description: 'All cases pending review and requiring action from a TL or above',
        filters: [{ columnName: 'StageName', operator: 'eq', value: 'Pending Review' }],
      },
    ],
  },
  {
    placeholder: 'Unsolved Cases',
    items: [
      {
        id: 'us-1',
        text: 'Unsolved Cases - All',
        alternativeText: 'All',
        description: 'All unsolved cases, whether actionable or not',
        filters: [
          { columnName: 'StageName', operator: 'not in', value: ['Closed'] },
          {
            columnName: 'StageName',
            operator: 'not in',
            value: ['Solved'],
          },
        ],
      },
      {
        id: 'us-2',
        text: 'Unsolved Cases - Installs & Service Visits',
        alternativeText: 'Installs & Service Visits',
        description: 'All unsolved cases, whether actionable or not for the Installation team',
        filters: [
          { columnName: 'StageName', operator: 'not in', value: ['Closed'] },
          {
            columnName: 'StageName',
            operator: 'not in',
            value: ['Solved'],
          },
          { columnName: 'Category', operator: 'eq', value: 'INSTALLS_AND_SERVICE_VISITS' },
        ],
      },
    ],
  },
];
