import { InputGroup, Section, SectionCard, Switch } from '@blueprintjs/core';

export type SettingsFormProps = {
  name: string;
  setName: (name: string) => void;
  pluralName: string;
  setPluralName: (pluralName: string) => void;
  apiName: string;
  setApiName: (apiName: string) => void;
  position: number;
  setPosition: (position: number) => void;
  cascadeDelete: boolean;
  setCascadeDelete: (cascadeDelete: boolean) => void;
  linkProperties: boolean;
  setLinkProperties: (linkProperties: boolean) => void;
  isSearchable: boolean;
  setIsSearchable: (isSearchable: boolean) => void;
  isDeletable: boolean;
  setIsDeletable: (isDeletable: boolean) => void;
  processDependency: boolean;
  setProcessDependency: (processDependency: boolean) => void;
};

export const SettingsForm = (props: SettingsFormProps) => {
  return (
    <Section title="Link Settings">
      <SectionCard>
        <div className="link-settings">
          <div className="link-settings__item">
            <h4>Name</h4>
            <InputGroup type="text" fill value={props.name} onValueChange={props.setName} />
          </div>
          <div className="link-settings__item">
            <h4>Plural Name</h4>
            <InputGroup
              type="text"
              fill
              value={props.pluralName}
              onValueChange={props.setPluralName}
            />
          </div>
          <div className="link-settings__item">
            <h4>API Name</h4>
            <InputGroup type="text" fill value={props.apiName} onValueChange={props.setApiName} />
          </div>
        </div>
      </SectionCard>
      <SectionCard>
        <div className="link-settings">
          <div className="link-settings__item">
            <h4>Position</h4>
            <InputGroup
              type="number"
              fill
              value={String(props.position)}
              onValueChange={(value) => props.setPosition(Number(value))}
              min={0}
              max={100}
            />
          </div>
          <div className="link-settings__item">
            <h4>
              <label htmlFor="cascade-delete-child-recs">Cascade delete child records</label>
            </h4>
            <Switch
              id="cascade-delete-child-recs"
              size="large"
              checked={props.cascadeDelete}
              onChange={(e) => props.setCascadeDelete(e.currentTarget.checked)}
            />
          </div>
          <div className="link-settings__item">
            <h4>
              <label htmlFor="allow-link-attrs">Has associated properties</label>
            </h4>
            <Switch
              id="allow-link-attrs"
              size="large"
              checked={props.linkProperties}
              onChange={(e) => props.setLinkProperties(e.currentTarget.checked)}
            />
          </div>
        </div>
      </SectionCard>
      <SectionCard>
        <div className="link-settings">
          <div className="link-settings__item">
            <h4>
              <label htmlFor="is-searchable">Is Searchable</label>
            </h4>
            <Switch
              id="is-searchable"
              size="large"
              checked={props.isSearchable}
              onChange={(e) => props.setIsSearchable(e.currentTarget.checked)}
            />
          </div>
          <div className="link-settings__item">
            <h4>
              <label htmlFor="is-deletable">Is Deletable</label>
            </h4>
            <Switch
              id="is-deletable"
              size="large"
              checked={props.isDeletable}
              onChange={(e) => props.setIsDeletable(e.currentTarget.checked)}
            />
          </div>
          <div className="link-settings__item">
            <h4>
              <label htmlFor="process-dependency-enabled">Process Dependency</label>
            </h4>
            <Switch
              id="process-dependency-enabled"
              size="large"
              checked={props.processDependency}
              onChange={(e) => props.setProcessDependency(e.currentTarget.checked)}
            />
          </div>
        </div>
      </SectionCard>
    </Section>
  );
};
