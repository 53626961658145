import { ArrowRightOutlined } from '@ant-design/icons';
import { LogsUserAuditEventTypesEnum } from '@d19n/temp-fe-d19n-models/dist/logs/audit/logs.user.audit.event.types';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Button, Col, Drawer, Row } from 'antd';
import { FunctionComponent, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ProjectModuleRecordDetailViewWide from '@netomnia/modules/ProjectModule/views/ProjectModuleRecordDetailViewWide';
import {
  getModuleAndEntityNameFromRecord,
  getRecordFromShortListById,
} from '@core/helpers/recordHelpers';
import {
  IMapSetWorkItemQuickView,
  setWorkItemQuickView,
} from '@netomnia/modules/ProjectModule/views/Map/store/actions';
import { MapReducer } from '@netomnia/modules/ProjectModule/views/Map/store/reducer';
import { getSchemaByModuleAndEntityRequest } from '@redux/stores/schemas/actions';
import {
  createUserAuditEventsRequest,
  ICreateUserAuditEvents,
} from '@redux/stores/userAudit/actions';
import { getRecordByIdRequest } from '@redux/stores/records/actions';
import { IRecordReducer } from '@redux/stores/records/reducer';
import './styles.scss';

interface OwnProps {
  recordId: string;
  visible: boolean;
  onClose?: any;
  moduleName?: SchemaModuleTypeEnums;
  entityName?: 'Feature' | 'Project';
  recordReducer: IRecordReducer;
  getRecord: (
    props: { moduleName: string; entityName: string; recordId: string },
    cb?: any,
  ) => DbRecordEntityTransform;
  navigationReducer: any;
  allowV2Upgrade?: boolean;
  setNewQuickView: (payload: IMapSetWorkItemQuickView) => void;
  logUserRecordViewEvent: (params: ICreateUserAuditEvents, cb?: any) => void;
  mapReducer: MapReducer;
}

type Props = OwnProps;

const RecordQuickViewDrawer: FunctionComponent<Props> = (props) => {
  const {
    recordId,
    visible,
    onClose,
    moduleName,
    entityName,
    navigationReducer,
    logUserRecordViewEvent,
    mapReducer,
    recordReducer,
  } = props;

  const [moduleAndEntityName, setModuleAndEntityName] = useState<
    { moduleName: string; entityName: string } | undefined
  >(undefined);

  const [shortlistRecord, setShortlistRecord] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );

  // If the Drawer is visible and navigation route changes (e.g. user clicks a record in the associations), close the drawer.
  useEffect(() => {
    if (visible) {
      onClose();
      setShortlistRecord(undefined);
    }
  }, [navigationReducer.previousPage]);

  // When record is available in the shortlist, and set to state - log user view action
  useEffect(() => {
    if (shortlistRecord) {
      logUserRecordView(shortlistRecord);
    }
  }, [shortlistRecord]);

  useEffect(() => {
    const { recordId, recordReducer, getRecord } = props;

    if (recordId) {
      const record = getRecordFromShortListById(recordReducer.shortList, recordId);

      if (record) {
        setShortlistRecord(record);

        const { moduleName, entityName } = getModuleAndEntityNameFromRecord(record);

        if (moduleName && entityName) {
          setModuleAndEntityName({
            moduleName,
            entityName,
          });
        } else if (record) {
          const { moduleName, entityName } = getModuleAndEntityNameFromRecord(record);
          setModuleAndEntityName({
            moduleName: moduleName,
            entityName: entityName,
          });
        }
      } else {
        if (!moduleName || !entityName) {
          console.error('moduleName and entityName not provided and record not in the shortList!', {
            recordId,
          });
          return;
        }
        getRecord({ moduleName, entityName, recordId });
      }
    }
  }, [recordId, recordReducer.shortList]);

  const logUserRecordView = (record: DbRecordEntityTransform) => {
    if (record) {
      logUserRecordViewEvent({
        items: [
          {
            type: LogsUserAuditEventTypesEnum.DB_RECORD_VIEWED,
            recordId: record?.id,
            data: {
              recordNumber: record?.recordNumber,
              title: record?.title,
              ...(mapReducer.recordId && { quickViewVersion: 'V1' }),
              componentName: 'RecordQuickViewDrawer',
            },
          },
        ],
      });
    }
  };

  return (
    <Drawer
      width={isMobile ? '100%' : '90%'}
      className="featureQuickViewDrawer"
      title={
        <Row>
          {!isMobile ? (
            <Col span={16} style={{ paddingTop: 3 }}>
              <span style={{ fontSize: '1.2em' }}>Quick View</span>
            </Col>
          ) : (
            <></>
          )}

          <Col span={isMobile ? 24 : 8} style={{ textAlign: 'right', paddingRight: 20 }}>
            <Link
              to={`/${moduleName || moduleAndEntityName?.moduleName}/${
                entityName || moduleAndEntityName?.entityName
              }/${recordId}`}
              target="_blank"
            >
              {}
              <Button type="primary" ghost disabled={!setModuleAndEntityName}>
                Go to record
                <ArrowRightOutlined />
              </Button>
            </Link>
          </Col>
        </Row>
      }
      open={visible}
      onClose={() => (onClose ? onClose() : {})}
      destroyOnClose
    >
      {recordId && (
        <ProjectModuleRecordDetailViewWide
          quickView
          disableBreadcrumbs
          entityName={entityName || moduleAndEntityName?.entityName!}
          moduleName={moduleName!}
          recordId={recordId!}
        />
      )}
    </Drawer>
  );
};

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  navigationReducer: state.navigationReducer,
  mapReducer: state.mapReducer,
});

const mapDispatch = (dispatch: any) => ({
  setNewQuickView: (params: IMapSetWorkItemQuickView) => dispatch(setWorkItemQuickView(params)),
  logUserRecordViewEvent: (params: ICreateUserAuditEvents, cb?: any) =>
    dispatch(createUserAuditEventsRequest(params, cb)),
  getRecord: (
    {
      moduleName,
      entityName,
      recordId,
    }: { moduleName: string; entityName: string; recordId: string },
    cb?: any,
  ) => {
    return dispatch(
      getSchemaByModuleAndEntityRequest({ moduleName, entityName }),
      (schema: SchemaEntity) => {
        return dispatch(getRecordByIdRequest({ schema, recordId }, cb));
      },
    );
  },
});

export default connect(mapState, mapDispatch)(RecordQuickViewDrawer);
