import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React, { createContext, useEffect, useState } from 'react';
import './styles.scss';
import CaseManagementScheduling from './views/CaseManagementScheduling';
import CaseManagementTemplates from './views/CaseManagementTemplates';
import CaseManagementCaseListView from './views/CaseManagementCaseListView';
import CaseManagementDashboard from './views/CaseManagementDashboard';
import MyCasesViewV2 from '../MyCasesView';
import CaseManagementCaseLoadView from './views/CaseManagementCaseLoadView';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import { httpGet } from '@core/http/requests';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { isSystemAdmin } from '@core/helpers/rbacRules';
import { connect } from 'react-redux';
import CaseManagementDashboardV2 from './views/CaseManagementDashboard/CaseManagementDashboardV2';

type TMenuItem =
  | 'Dashboard'
  | 'DashboardV2' // TODO TEMP for PROD testing
  | 'Teams'
  | 'Performance'
  | 'CaseLoad'
  | 'Cases'
  | 'Scheduling'
  | 'Employees'
  | 'ShiftTemplates'
  | 'MyCases';

export const SupportDashboardContext = createContext<{
  caseSchema: SchemaEntity | undefined;
  assignOwnerAction: SchemaActionEntity | undefined;
  removeAssignmentAction: SchemaActionEntity | undefined;
}>({
  caseSchema: undefined,
  assignOwnerAction: undefined,
  removeAssignmentAction: undefined,
});

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;
const CASE = 'Case';

interface Props {
  userReducer: any;
}

const SupportDashboard: React.FC<Props> = (props: Props) => {
  const { userReducer } = props;

  const [activeMenuItem, setActiveMenuItem] = useState<TMenuItem | undefined>(undefined);
  const [isMenuCollapsed, setIsMenuCollapsed] = useState<boolean>(true);
  const [caseSchema, setCaseSchema] = useState<SchemaEntity | undefined>(undefined);
  const [assignOwnerAction, setAssignOwnerAction] = useState<SchemaActionEntity | undefined>(
    undefined,
  );
  const [removeAssignmentAction, setRemoveAssignmentAction] = useState<
    SchemaActionEntity | undefined
  >(undefined);

  useEffect(() => {
    getCaseSchema();
  }, []);

  useEffect(() => {
    if (caseSchema && !assignOwnerAction) {
      getAllActions(caseSchema.id);
    }
  }, [caseSchema, assignOwnerAction]);

  const getCaseSchema = async () => {
    try {
      const schema = await getOdinSchemaByEntity(SUPPORT_MODULE, CASE);
      if (schema) {
        setCaseSchema(schema);
      }
    } catch (error) {}
  };

  const getAllActions = async (schemaId: string) => {
    try {
      const res = await httpGet(`SchemaModule/v1.0/schemas-actions/schema/${schemaId}`);
      const GAOAction = res?.data?.data?.find(
        (item: SchemaActionEntity) => item.name === 'AssignCase',
      );
      const RAAction = res?.data?.data?.find(
        (item: SchemaActionEntity) => item.name === 'RemoveAssignmentFromCase',
      );
      if (GAOAction) {
        setAssignOwnerAction(GAOAction);
      }
      if (RAAction) {
        setRemoveAssignmentAction(RAAction);
      }
    } catch (error) {}
  };

  useEffect(() => {
    let hash = window.location.hash;
    if (hash) {
      let tab = hash.split('#')[1];
      setActiveMenuItem(tab as TMenuItem);
      setIsMenuCollapsed(true);
    } else {
      setActiveMenuItem('Dashboard');
    }
  }, []);

  useEffect(() => {
    if (activeMenuItem !== undefined) {
      window.history.pushState('#' + activeMenuItem, '', '#' + activeMenuItem);
    }
  }, [activeMenuItem]);

  const renderContent = () => {
    switch (activeMenuItem) {
      case 'Dashboard':
        return <CaseManagementDashboard />;
      // TODO TEMP for PROD testing
      case 'DashboardV2':
        return <CaseManagementDashboardV2 />;
      case 'Scheduling':
        return isSystemAdmin(userReducer) ? <CaseManagementScheduling /> : <></>;
      case 'ShiftTemplates':
        return <CaseManagementTemplates />;
      case 'Cases':
        return <CaseManagementCaseListView />;
      case 'MyCases':
        return <MyCasesViewV2 />;
      case 'CaseLoad':
        return <CaseManagementCaseLoadView />;
      default:
        return <></>;
    }
  };

  const handleMenuClick = (menuItem: TMenuItem) => {
    setActiveMenuItem(menuItem);
    setIsMenuCollapsed(true);
  };

  return (
    <SupportDashboardContext.Provider
      value={{ caseSchema, assignOwnerAction, removeAssignmentAction }}
    >
      <Row style={{ height: 'calc(100vh - 38px)' }}>
        <Col span={isMenuCollapsed ? 1 : 3} className="CaseManagementLeftSidebar">
          <Menu className={`caseManagementSidebarMenu ${isMenuCollapsed ? 'closed' : 'open'}`}>
            {/* Closed Menu -> Open */}
            {isMenuCollapsed && (
              <>
                <MenuItem
                  text={isMenuCollapsed ? null : 'Menu'}
                  icon={<i className="bi bi-list" />}
                  onClick={() => setIsMenuCollapsed(false)}
                />
                <MenuDivider />
              </>
            )}

            {/* Open Menu -> Close */}
            {!isMenuCollapsed && (
              <>
                <MenuItem
                  style={{ color: 'black', fontWeight: 500 }}
                  icon={
                    <i
                      className="bi bi-list"
                      style={{ fontWeight: 600, marginTop: 2, marginRight: 4 }}
                    />
                  }
                  text="Support Module"
                  onClick={() => setIsMenuCollapsed(true)}
                />

                <MenuDivider />
              </>
            )}

            {/* Menu Items */}
            <MenuItem
              text={isMenuCollapsed ? null : 'Dashboard'}
              icon={<i className="bi bi-columns" />}
              active={activeMenuItem === 'Dashboard'}
              onClick={() => handleMenuClick('Dashboard')}
            />
            <MenuItem
              text={isMenuCollapsed ? null : 'Case Load'}
              icon={<i className="bi bi-minecart-loaded" />}
              active={activeMenuItem === 'CaseLoad'}
              onClick={() => setActiveMenuItem('CaseLoad')}
            />
            <MenuItem
              text={isMenuCollapsed ? null : 'Cases'}
              icon={<i className="bi bi-view-list" />}
              active={activeMenuItem === 'Cases'}
              onClick={() => handleMenuClick('Cases')}
            />
            <MenuItem
              text={isMenuCollapsed ? null : 'My Cases'}
              icon={<i className="bi bi-headphones" />}
              active={activeMenuItem === 'MyCases'}
              onClick={() => handleMenuClick('MyCases')}
            />
            {isSystemAdmin(userReducer) && (
              <MenuItem
                text={isMenuCollapsed ? null : 'Scheduling'}
                icon={<i className="bi bi-calendar4-range" />}
                active={activeMenuItem === 'Scheduling'}
                onClick={() => setActiveMenuItem('Scheduling')}
              />
            )}
          </Menu>
        </Col>
        <Col span={isMenuCollapsed ? 23 : 21} style={{ background: 'white', padding: 15 }}>
          {renderContent()}
        </Col>
      </Row>
    </SupportDashboardContext.Provider>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(SupportDashboard);
