import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Callout, Icon, Section } from '@blueprintjs/core';
import { Col, Form, Input, Layout, Row } from 'antd';
import { FC, useState } from 'react';
import { connect } from 'react-redux';
import { forgotPasswordRequest, resetPasswordRequest } from '@redux/stores/identity/actions';
import { displayMessage } from '@redux/stores/messages/reducers';
import { Link } from 'react-router-dom';
import { checkPasswordRule, isValidPassword, passwordRules } from '../../helpers/password';

interface Props {
  resetPasswordReq: any;
  match?: any;
  alertMessage?: any;
}

const ResetPasswordView: FC<Props> = (props: Props) => {
  const { resetPasswordReq, alertMessage } = props;
  const [token, setToken] = useState<string>(props.match.params.token || '');
  const [password, setPassword] = useState<string>('');
  const [confirmedPassword, setConfirmedPassword] = useState<string>('');

  const [isResettingPassword, setIsResettingPassword] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const resetPassword = () => {
    const data = {
      password: password,
      confirmPassword: confirmedPassword,
    };

    setIsResettingPassword(true);

    resetPasswordReq({ data: data, token: token }, (resp: any) => {
      if (resp) {
        setIsResettingPassword(false);
        setIsSuccess(true);
        alertMessage({
          body: 'Your password has been updated',
          type: 'success',
        });
      } else {
        setIsResettingPassword(false);
        setIsSuccess(false);
      }
    });
  };

  const isRepeatedPasswordMatching: boolean =
    password.length > 0 && confirmedPassword.length > 0 && password === confirmedPassword;

  return (
    <Layout>
      <Row style={{ height: '100vh' }} align="middle" justify="center">
        <Col xs={22} md={12} lg={9}>
          <Section title="Resetting Password">
            <div style={{ margin: 20 }}>
              {/* Form */}
              <Row>
                <Col span={24}>
                  <Form name="password-reset">
                    <Form.Item name="password">
                      <Input.Password
                        style={{ borderRadius: 2 }}
                        autoComplete="new-password"
                        disabled={isResettingPassword}
                        size="large"
                        type="password"
                        placeholder="Enter new password"
                        onChange={(e) => setPassword(e.target.value)}
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>

                    <Form.Item name="confirmPassword">
                      <Input.Password
                        style={{ borderRadius: 2 }}
                        autoComplete="new-password"
                        size="large"
                        disabled={isResettingPassword || isSuccess}
                        type="password"
                        placeholder="Repeat new password"
                        onChange={(e) => setConfirmedPassword(e.target.value)}
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                  </Form>
                </Col>
              </Row>

              {/* Password Requirements */}
              {!isSuccess && (
                <Row>
                  <Col span={24}>
                    <Callout
                      icon={
                        isValidPassword(password) && isRepeatedPasswordMatching
                          ? 'tick-circle'
                          : 'info-sign'
                      }
                      intent={
                        isValidPassword(password) && isRepeatedPasswordMatching
                          ? 'success'
                          : 'primary'
                      }
                      title="Password Requirements"
                      style={{ borderRadius: 8 }}
                    >
                      <Row style={{ marginTop: 18 }}>
                        {passwordRules.map((rule: any, i: number) => (
                          <Col
                            xs={24}
                            sm={12}
                            key={i}
                            style={{
                              color: checkPasswordRule(rule, password) ? 'green' : '#CD4246',
                              marginBottom: 5,
                            }}
                          >
                            {rule.title}{' '}
                            {checkPasswordRule(rule, password) && (
                              <Icon icon="tick" color="#238551" />
                            )}
                          </Col>
                        ))}

                        {/* Repeated password is matching */}
                        <Col
                          xs={24}
                          sm={12}
                          style={{
                            color: isRepeatedPasswordMatching ? 'green' : '#CD4246',
                            marginBottom: 5,
                          }}
                        >
                          Repeated password is matching{' '}
                          {isRepeatedPasswordMatching && <Icon icon="tick" color="#238551" />}
                        </Col>
                      </Row>
                    </Callout>
                  </Col>
                </Row>
              )}

              {/* Success Callout */}
              {isSuccess && (
                <Callout intent="success" icon={null}>
                  Your password has been reset. Log with your new password by heading out to Login
                  Page and use it to access the application.
                </Callout>
              )}

              {/* Success Link to HomeView */}
              {isSuccess && (
                <Row style={{ marginTop: 30 }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <Link to="/login">
                      <Button large intent="primary">
                        Go to Login Page
                      </Button>
                    </Link>
                  </Col>
                </Row>
              )}

              {/* Submit Button */}
              {!isSuccess && (
                <Row style={{ marginTop: 30 }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <Button
                      large
                      intent="primary"
                      onClick={resetPassword}
                      loading={isResettingPassword}
                      disabled={
                        password !== confirmedPassword ||
                        password === '' ||
                        confirmedPassword === '' ||
                        !isValidPassword(password) ||
                        isSuccess
                      }
                    >
                      Reset Password
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          </Section>
        </Col>
      </Row>
    </Layout>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  forgotPassword: (params: any, cb: any) => dispatch(forgotPasswordRequest(params, cb)),
  resetPasswordReq: (params: any, cb: any) => dispatch(resetPasswordRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(ResetPasswordView);
