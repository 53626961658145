import { Button } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getAllRelations,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Layout } from 'antd';
import dayjs from 'dayjs';
import { FC, useContext, useState } from 'react';
import { RecordDataTable } from 'src/core/components/DataTable';
import RecordCard from 'src/core/components/RecordCard';
import { useRecordAssociations } from '@core/hooks/useRecordAssociations';
import { httpPost } from '../../../../../core/http/requests';
import { DetailViewContext } from '../../../../../core/components/DetailViewContextProvider';
import DetailView from '../../../../../core/views/DefaultDetailView';

const PaymentNoticeDetailView: FC = () => {
  const { record } = useContext(DetailViewContext);

  const [acceptedItems, setAcceptedItems] = useState<DbRecordEntityTransform[]>([]);
  const [rejectedItems, setRejectedItems] = useState<DbRecordEntityTransform[]>([]);

  useRecordAssociations(record, ['FeaturePriceEntry'], ({ results }: any) => {
    const approved: DbRecordEntityTransform[] = [];
    const rejected: DbRecordEntityTransform[] = [];
    const fpes = getAllRelations(results, 'FeaturePriceEntry') ?? [];
    fpes.forEach((fpe) => {
      const isApproved = String(getProperty(fpe, 'IsApproved'));
      if (isApproved === 'true') approved.push(fpe);
      else if (isApproved === 'false') rejected.push(fpe);
    });

    setAcceptedItems(approved);
    setRejectedItems(rejected);
  });

  const downloadFile = async () => {
    httpPost(`ProjectModule/v1.0/PaymentNotice/export-for-sage/${record?.id}`, {})
      .then((res) => {
        console.log('res', res);
        // trigger file download here.
        const link = document.createElement('a');

        console.log(
          'this',
          `payment-notice-export-${record?.recordNumber}-${dayjs().format('DD-MM-YYYY')}.csv`,
        );
        // // does not work for cross origin site downloads.
        // link.download = `payment-notice-export-${record?.recordNumber}-${dayjs().format(
        //   'DD-MM-YYYY',
        // )}.csv`;
        link.href = res.data.data.fileUrl;

        console.log('res.data.data.fileUrl', res.data.data.fileUrl);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {});
  };

  const downloadPdfFile = async () => {
    httpPost(`ProjectModule/v1.0/PaymentNotice/export-pdf/${record?.id}`, {})
      .then((res) => {
        console.log('res', res);
        // trigger file download here.
        const link = document.createElement('a');

        console.log(
          'this',
          `payment-notice-export-${record?.recordNumber}-${dayjs().format('DD-MM-YYYY')}.pdf`,
        );
        // // does not work for cross origin site downloads.
        // link.download = `payment-notice-export-${record?.recordNumber}-${dayjs().format(
        //   'DD-MM-YYYY',
        // )}.csv`;
        link.target = '_blank';
        link.href = res.data.data.url;

        console.log('res.data.data.url', res.data.data.url);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {});
  };

  return (
    <Layout>
      <DetailView
        showCollaborators
        headerExtras={[
          <Button onClick={() => downloadFile()} disabled={record?.stage?.name === 'Draft'}>
            Sage Export
          </Button>,
          <Button onClick={() => downloadPdfFile()} disabled={record?.stage?.name === 'Draft'}>
            PDF Export
          </Button>,
        ]}
        defaultTabKey={'LineItems'}
        customTabs={[
          {
            key: 'LineItems',
            tab: 'Line Items',
          },
        ]}
        customTabBodies={{
          LineItems: (
            <div>
              <div className="association-data-table-wrapper">
                <RecordDataTable title="Accepted Items" dataSource={acceptedItems} />
              </div>
              <div className="association-data-table-wrapper">
                <RecordDataTable title="Rejected Items" dataSource={rejectedItems} />
              </div>
            </div>
          ),
        }}
        leftColumn={[
          <RecordCard
            showActionMenu
            showItemActionMenu
            showWithoutPagination
            record={record}
            displayQuickView
            associatedRecordModuleName={SchemaModuleTypeEnums.PROJECT_MODULE}
            associatedRecordEntityName={'ApplicationForPayment'}
            visibleProperties={['ClosingDate', 'ExchangeName', 'Contractor', 'DataRecordSource']}
          />,
        ]}
      />
    </Layout>
  );
};

export default PaymentNoticeDetailView;
