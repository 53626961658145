import { combineReducers } from 'redux';
import autosplicingReducer from '@netomnia/modules/ProjectModule/views/Autosplicing/store/reducer';
import appointmentReducer from './stores/appointments/reducer';
import mapReducer from '@netomnia/modules/ProjectModule/views/Map/store/reducer';
import { USER_LOGOUT_REQUEST } from './stores/identity/constants';
import userReducer from './stores/identity/reducers';
import identityGroupsReducer from './stores/identityGroups/reducer';
import identityUserReducer from './stores/identityUser/reducer';
import navigationReducer from './stores/navigation/reducer';
import emailNotificationReducer from './stores/email/reducer';
import pipelineReducer from './stores/pipelines/reducer';
import auditLogsReducer from './stores/recordAudit/reducer';
import queryBuilderReducer from '@core/components/DynamicTable/QueryBuilder/store/reducer';
import recordTableReducer from '@redux/stores/table/reducer';
import recordFormReducer from './stores/form/reducer';
import recordReducer from './stores/records/reducer';
import recordAssociationReducer from './stores/recordAssociations/reducer';
import reportReducer from './stores/reporting/reducer';
import schemaReducer from './stores/schemas/reducer';
import schemaAssociationReducer from './stores/schemasAssociations/reducer';
import schemaColumnReducer from './stores/schemasColumns/reducer';
import userAuditReducer from './stores/userAudit/reducer';
import userInterfaceReducer from './stores/userInterface/reducer';
import formReducer from './stores/sharedForm/reducer';
import stepViewReducer from '@core/components/StepView/store/reducer';
import messageReducer from './stores/messages/reducers';
import notificationReducer from './stores/notification/reducers';
import { ganttReducer } from '@core/components/GanttChart/store/reducer';

const rootReducer = combineReducers({
  appointmentReducer,
  auditLogsReducer,
  autosplicingReducer,
  emailNotificationReducer,
  formReducer,
  ganttReducer,
  identityGroupsReducer,
  identityUserReducer,
  mapReducer,
  messageReducer,
  navigationReducer,
  notificationReducer,
  pipelineReducer,
  queryBuilderReducer,
  recordAssociationReducer,
  recordFormReducer,
  recordReducer,
  recordTableReducer,
  reportReducer,
  schemaAssociationReducer,
  schemaColumnReducer,
  schemaReducer,
  stepViewReducer,
  userAuditReducer,
  userInterfaceReducer,
  userReducer,
});

// Handle cleanup / reset persisted state on logout
const appReducer = (state, action) => {
  let newState = state;

  if (action.type === USER_LOGOUT_REQUEST) {
    sessionStorage.clear();
    localStorage.removeItem('token');
    localStorage.removeItem('originalToken');
    localStorage.removeItem('expiresIn');
    localStorage.removeItem('timestamp');
    localStorage.removeItem(`${import.meta.env.VITE_ODIN_REDUX_STORE_NAME}`);
    newState = undefined;
    newState = {
      navigationReducer: state.navigationReducer,
      schemaReducer: state.schemaReducer,
    };
  }
  return rootReducer(newState, action);
};

export default appReducer;
