import { message, Space } from 'antd';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { resetMessage } from '../../../redux/stores/messages/reducers';

const successMessage = (messageReducer: any) => {
  return message.success(messageReducer.message.body, 4);
};
const infoMessage = (messageReducer: any) => {
  return message.info(messageReducer.message.body, 4);
};
const errorMessage = (messageReducer: any) => {
  return message.error(messageReducer.message.body, 6);
};

interface Props {
  messageReducer: any;
}

const Message: FC<Props> = (props: Props) => {
  const { messageReducer } = props;

  return (
    <Space>
      {messageReducer.message.type === 'success' && successMessage(messageReducer)}
      {messageReducer.message.type === 'info' && infoMessage(messageReducer)}
      {messageReducer.message.type === 'error' && errorMessage(messageReducer)}
    </Space>
  );
};

const mapState = (state: any) => ({
  messageReducer: state.messageReducer,
});

const mapDispatch = (dispatch: any) => ({
  reset: () => dispatch(resetMessage()),
});

export default connect(mapState, mapDispatch)(Message);
