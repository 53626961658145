import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Button } from '@blueprintjs/core';
import { MyCasesContext } from '../../../index';
import { getRecordByIdRequest, IGetRecordById } from '../../../../../../../../redux/stores/records/actions';
import { FormReducerSubmitEvt } from '@core/components/Forms/SharedForm/SharedFormModal';
import { MY_CASES_SET_SELECTED_CASE, MY_CASES_UPDATE_CASE } from '../../../store/constants';
import CoreForm from '@core/components/Forms/CoreForm';
import { initializeRecordForm } from '../../../../../../../../redux/stores/form/actions';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

interface Props {
  caseSchema: SchemaEntity;
  initializeForm: (params: any) => void;
  getRecordById: (payload: IGetRecordById, cb: any) => void;
  userReducer: any;
}

const MyCasesEditCaseButton: React.FC<Props> = (props: Props) => {
  const { initializeForm, getRecordById, caseSchema, userReducer } = props;
  const { state, dispatch } = useContext(MyCasesContext);

  const isCaseOwn = getProperty(state.selectedCase, 'OwnerId') === userReducer.user.id;

  const initializeEditCaseForm = () => {
    if (caseSchema && state.selectedCase) {
      initializeForm({
        formUUID: `${caseSchema!.id}_Edit`,
        title: `Edit Case`,
        showFormModal: true,
        isCreateReq: false,
        isUpdateReq: true,
        schema: caseSchema,
        selected: state.selectedCase,
        sections: [{ name: caseSchema.name, schema: caseSchema }],
      });
    }
  };

  const handleFormSubmit = (params: any) => {
    const recordId = params.results?.id;
    if (recordId && caseSchema) {
      getRecordById({ schema: caseSchema, recordId: recordId }, (res: any) => {
        dispatch({ type: MY_CASES_SET_SELECTED_CASE, payload: res });
        dispatch({ type: MY_CASES_UPDATE_CASE, payload: res });
      });
    }
  };

  return caseSchema ? (
    <>
      <CoreForm
        type="MODAL"
        formUUID={`${caseSchema.id}_Edit`}
        onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
      />

      <Button
        disabled={!isCaseOwn}
        icon={<i className="bi bi-pencil" />}
        intent="primary"
        minimal
        onClick={initializeEditCaseForm}
      />
    </>
  ) : (
    <></>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});
const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(MyCasesEditCaseButton);
