import { Tag } from '@blueprintjs/core';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Button, Checkbox, Col, List, Row } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useDraggable } from '@dnd-kit/core';
import { HolderOutlined } from '@ant-design/icons';
import { IOpenRecordDrawer } from '../../../../../../../redux/stores/userInterface/actions';
import './index.scss';

interface Props {
  id: string;
  agents?: any[];
  dataset: any;
  style?: any;
  checkable?: boolean;
  draggable?: boolean;
  openDrawer?: (params: IOpenRecordDrawer) => void;
}

const DataSetItem: FC<Props> = ({
  agents,
  dataset,
  id,
  checkable = true,
  draggable = true,
  style,
  openDrawer = ({}) => {},
}: Props) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: `${id}-${dataset.id}`,
    disabled: !draggable,
  });

  const customStyle = {
    // Outputs `translate3d(x, y, 0)`
    // transform: CSS.Translate.toString(transform),
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 100,
    cursor: draggable ? 'grab' : '',
  };

  return (
    <div ref={setNodeRef} style={{ ...customStyle, ...style }}>
      {/* <List.Item style={{ width: '100%', padding: '2px 0' }} key={dataset.id}> */}
      <Row>
        <Col span={1} className="gutter-row">
          {checkable && <Checkbox value={dataset} />}
        </Col>
        <Col {...listeners} {...attributes} flex="auto">
          <Row>
            <Col span={17}>
              <Row gutter={16}>
                <Col className="gutter-row">
                  <button
                    className="link"
                    onClick={() =>
                      openDrawer({
                        recordId: dataset.id,
                        moduleName: 'CrmModule',
                        entityName: 'CrmDataset',
                      })
                    }
                  >
                    {dataset.title}
                  </button>
                </Col>
              </Row>
            </Col>
            <Col span={7}>
              <Row gutter={10} justify="end">
                {agents && agents.length > 0 && (
                  <Col className="gutter-row">
                    {agents.length > 0 && (
                      <Tag minimal round>
                        {agents.join(',')}
                      </Tag>
                    )}
                  </Col>
                )}

                <Col className="gutter-row">{draggable && <HolderOutlined width={50} />}</Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* </List.Item> */}
    </div>
  );
};

export default DataSetItem;
