import React, { useEffect, useState } from 'react';
import { Card, Col, DatePicker, Layout, Row, Statistic, Table } from 'antd';

import { PageHeader } from '@core/components/PageHeader';
import { httpPost } from '@core/http/requests';
import { capitalCase } from 'change-case';
import dayjs, { Dayjs } from 'dayjs';
import { CompareFn } from 'antd/lib/table/interface';

const { RangePicker } = DatePicker;

type ScoreEntry = {
  id: number;
  firstname: string;
  lastname: string;
  job_title: string;
  availability_status: string;
  sales_region: string;
  sales_territory: string;
  leads_in_queue: number;
  call_count: number;
  avg_call_duration: number;
  appointment_count: number;
  order_count: number;
  orders_per_day: number;
};

const MAX_DAYS_ALLOWED = 30;

export const TelesalesDashboard: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [scores, setScores] = useState<ScoreEntry[]>([]);
  const [startDate, setStartDate] = useState<string | undefined>();
  const [endDate, setEndDate] = useState<string | undefined>();
  const [leadData, setLeadData] = useState<any[]>([
    {
      name: 'Prospecting',
      count: '0',
      position: 1,
    },
    {
      name: 'Contacted',
      count: '0',
      position: 2,
    },
    {
      name: 'Qualified',
      count: '0',
      position: 3,
    },
    {
      name: 'Won',
      count: '0',
      position: 4,
    },
    {
      name: 'Lost',
      count: '0',
      position: 5,
    },
  ]);
  const [callsOrderSummary, setCallsOrderSummary] = useState<any[]>([]);

  const getSalesLeaderBoardColumns = () => {
    const columnPositions: Record<string, number> = {
      id: 10,
      firstname: 20,
      lastname: 30,
      job_title: 40,
      availability_status: 45,
      sales_territory: 50,
      sales_region: 60,
      leads_in_queue: 70,
      leads_assigned_today: 75,
      leads_assigned_today_not_contacted: 80,
      call_count: 90,
      avg_call_duration: 90,
      appointment_count: 90,
      order_count: 90,
      orders_per_day: 100,
    };

    const columnSorters: Record<string, CompareFn<ScoreEntry>> = {
      leads_in_queue: (a: ScoreEntry, b: ScoreEntry) => a.leads_in_queue - b.leads_in_queue,
      call_count: (a: ScoreEntry, b: ScoreEntry) => a.call_count - b.call_count,
      order_count: (a: ScoreEntry, b: ScoreEntry) => a.order_count - b.order_count,
      orders_per_day: (a: ScoreEntry, b: ScoreEntry) => a.orders_per_day - b.orders_per_day,
    };

    const fields = Object.keys(columnPositions);

    return fields.map((key) => ({
      title: key === 'id' ? '#' : capitalCase(key),
      dataIndex: key,
      ellipsis: false,
      position: columnPositions[key],
      sorter: columnSorters[key],
    }));
  };

  // loading scores
  useEffect(() => {
    let mounted = true;

    const loadScores = async () => {
      if (!document.hidden) {
        setIsLoading(true);
        return await httpPost('CrmModule/v1.0/dashboards/telesales-leader-board', {
          startDate,
          endDate,
        })
          .then(async (res) => {
            if (!mounted) return;
            setIsLoading(false);
            setScores(
              res.data?.data?.leaderBoard.map((score: Record<string, any>, index: number) => ({
                id: index + 1,
                ...score,
              })),
            );

            const newLeadData = leadData.map((elem) => {
              const match = res.data?.data?.leadData?.find((res: any) => res.name === elem.name);
              if (match) {
                return {
                  ...elem,
                  ...match,
                };
              } else {
                return elem;
              }
            });
            setLeadData(newLeadData);

            setCallsOrderSummary(res.data?.data?.callsOrderSummary);
          })
          .catch((err) => {
            if (!mounted) return;
            setIsLoading(false);
          });
      }
    };

    // updates data every minute
    const interval = setInterval(loadScores, 60000);

    // load initial data
    loadScores();

    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, [startDate, endDate]);

  const handleDateChanged = (dates: any) => {
    if (!dates) return;

    const [startDate, endDate] = dates.map((date: any) => date?.format('YYYY-MM-DD'));
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const shouldDisableDate = (current: Dayjs) => {
    const tooEarly = startDate && current.diff(startDate, 'days') > MAX_DAYS_ALLOWED;
    const tooLate = endDate && dayjs(endDate).diff(current, 'days') > MAX_DAYS_ALLOWED;

    return !!tooEarly || !!tooLate;
  };

  return (
    <Layout style={{ padding: 10 }}>
      <PageHeader
        title="Telesales Dashboard"
        extra={
          <RangePicker
            value={[dayjs(startDate), dayjs(endDate)]}
            onChange={handleDateChanged}
            disabledDate={shouldDisableDate}
            changeOnBlur
          />
        }
      />
      <Row>
        <Col span={24} xs={24} md={24} xl={24} style={{ marginBottom: 10 }}>
          <Card size="small" title="" loading={isLoading}>
            <div style={{ display: 'flex' }}>
              {leadData
                .sort((a, b) => {
                  return a.position - b.position;
                })
                .map((data, index) => (
                  <Card bordered={false}>
                    <Statistic style={{ marginRight: 15 }} title={data.name} value={data.count} />
                  </Card>
                ))}
            </div>
          </Card>
        </Col>

        <Col span={24} xs={24} md={24} xl={24} style={{ marginBottom: 10 }}>
          <Card size="small" title="" loading={isLoading}>
            <div style={{ display: 'flex' }}>
              <Card bordered={false}>
                <Statistic
                  style={{ marginRight: 15 }}
                  title={'Call Count'}
                  value={callsOrderSummary[0]?.call_count}
                />
              </Card>
              <Card bordered={false}>
                <Statistic
                  style={{ marginRight: 15 }}
                  title={'Avg. Duration'}
                  value={callsOrderSummary[0]?.avg_call_duration}
                />
              </Card>
              <Card bordered={false}>
                <Statistic
                  style={{ marginRight: 15 }}
                  title={'Appointment Count'}
                  value={callsOrderSummary[0]?.appointment_count}
                />
              </Card>
              <Card bordered={false}>
                <Statistic
                  style={{ marginRight: 15 }}
                  title={'Order Count'}
                  value={callsOrderSummary[0]?.order_count}
                />
              </Card>
            </div>
          </Card>
        </Col>
        <Col span={24} xs={24} md={24} xl={24} style={{ marginBottom: 10 }}>
          <Card size="small" title="" loading={isLoading}>
            <Table size="small" columns={getSalesLeaderBoardColumns()} dataSource={scores} />
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};
