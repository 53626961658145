import { CloseCircleOutlined, ExportOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { chunkArray } from '@d19n/temp-fe-d19n-common/dist/helpers/Utilities';
import { RelationTypeEnum } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/types/db.record.association.constants';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import {
  Button,
  Checkbox,
  Descriptions,
  Dropdown,
  Form,
  Menu,
  message,
  Popconfirm,
  Popover,
  Result,
  Select,
  Table,
  TableColumnsType,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import Papa from 'papaparse';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import CoreForm from '@core/components/Forms/CoreForm';
import { initializeRecordForm } from '../../../../../../../redux/stores/form/actions';
import AssociationSimpleList from 'src/core/components/AssociationSimpleList';
import { httpDelete, httpGet, httpPatch, httpPost, httpPut } from '@core/http/requests';
import { hasAnyRoles, hasPermissions } from '@core/helpers/rbacRules';
import { getSchemaFromShortListByModuleAndEntity } from '@core/helpers/schemaHelpers';
import SplicingMatrixUpload from '../AutosplicingFiberSplicingMatrixGridView/SpliceMatrixUpload';
import { openRecordDrawer } from 'src/redux/stores/userInterface/actions';
import { IOpenRecordDrawer } from 'src/redux/stores/userInterface/actions';
import { LockFilled } from '@ant-design/icons';

const { TabPane } = Tabs;
const { Text } = Typography;

const uuid = uuidv4();

export const getCableTypeFromId = (id: number) => {
  if (id === 1) {
    return 'Spine';
    // 288 fiber
  }
  if (id === 2) {
    return 'Distribution';
    // 288 fiber
  }
  if (id === 3) {
    return 'Access';
    // 48 fibre
  }
  if (id === 4) {
    return 'Feed';
    // 48 fibre
  }
};

enum TubeColor48 {
  T1 = '#0000FF', // Blue
  T2 = '#FFA500', // Orange
  T3 = '#008000', // Green
  T4 = '#8B4513', // Brown
  T5 = '#708090', // Slate
  T6 = '#FFFFFF', // White
  T7 = '#8B0000', // Dark Red
  T8 = '#000000', // Black
  T9 = '#FFFF00', // Yellow
  T10 = '#00008B', // Violet
  T11 = '#654321', // Pink
  T12 = '#006400', // Aqua
}

enum TubeColor144 {
  T1 = '#0000FF', // Blue
  T2 = '#FFA500', // Orange
  T3 = '#008000', // Green
  T4 = '#8B4513', // Brown
  T5 = '#708090', // Slate
  T6 = '#FFFFFF', // White
  T7 = '#8B0000', // Dark Red
  T8 = '#000000', // Black
  T9 = '#FFFF00', // Yellow
  T10 = '#00008B', // Violet
  T11 = '#654321', // Pink
  T12 = '#006400', // Aqua
}

enum TubeColor288 {
  T1 = '#0000FF', // Blue
  T2 = '#FFA500', // Orange
  T3 = '#008000', // Green
  T4 = '#8B4513', // Brown
  T5 = '#708090', // Slate
  T6 = '#FFFFFF', // White
  T7 = '#8B0000', // Dark Red
  T8 = '#000000', // Black
  T9 = '#FFFF00', // Yellow
  T10 = '#0000FF', // Blue
  T11 = '#FFA500', // Orange
  T12 = '#008000', // Green
  T13 = '#8B4513', // Brown
  T14 = '#708090', // Slate
  T15 = '#FFFFFF', // White
  T16 = '#8B0000', // Dark Red
  T17 = '#000000', // Black
  T18 = '#FFFF00', // Yellow
  T19 = '#EE82EE', // Violet
  T20 = '#FFC0CB', // Pink
  T21 = '#00FFFF', // Aqua
  T22 = '#00008B', // Violet
  T23 = '#654321', // Pink
  T24 = '#006400', // Aqua
}

enum FiberColor {
  F1 = '#0000FF', // Blue
  F2 = '#FFA500', // Orange
  F3 = '#008000', // Green
  F4 = '#8B4513', // Brown
  F5 = '#708090', // Slate
  F6 = '#FFFFFF', // White
  F7 = '#8B0000', // Dark Red
  F8 = '#000000', // Black
  F9 = '#FFFF00', // Yellow
  F10 = '#EE82EE', // Violet
  F11 = '#FFC0CB', // Pink
  F12 = '#00FFFF', // Aqua
}

interface ICreateFiberConnection {
  type: string;
  fromClosure: number;
  fromCable: number;
  fromTubeFiber: string;
  toClosure: number;
  toCable: number;
  toTubeFiber: string;
  trayNumber: string;
}

const ClosureFiberSplicingTable = (props: {
  externalRef: number;
  record: DbRecordEntityTransform;
  userReducer: any;
  initializeForm: any;
  schemaReducer: any;
  openDrawer: (params: IOpenRecordDrawer) => void;
  enableSplicingUploader?: boolean;
}) => {
  const {
    externalRef,
    record,
    userReducer,
    initializeForm,
    schemaReducer,
    openDrawer,
    enableSplicingUploader = false,
  } = props;

  const [activeTab, setActiveTab] = useState<string>('all');
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [tabContent, setTabContent] = useState<DbRecordEntityTransform[]>([]);
  const [cableConnections, setCableConnectionData] = useState<DbRecordEntityTransform[]>([]);
  const [closureSlots, setClosureSlotContent] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [newConnections, setNewConnections] = useState<any[]>([]);
  const [loadingTabs, setLoadingTabs] = useState<boolean>(true);
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [isEditing, setEditing] = useState<boolean>(false);
  const [modifiedData, setModifiedData] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [closureRfcData, setClosureRfcData] = useState<any[]>([]);
  // State to keep track of the prop value
  const [currentProp, setCurrentProp] = useState(record);
  const [editingRow, setEditingRow] = useState<any>(undefined);

  const [youFibreApi, setYouFibreApi] = useState<
    | {
        apiKey: string;
        baseUrl: string;
      }
    | undefined
  >(undefined);
  const [oltList, setOltList] = useState<DbRecordEntityTransform[]>([]);

  // Effect to update the state when the prop changes
  useEffect(() => {
    loadData();
    setCurrentProp(record);
    setActiveTab('all');
    setEditing(false);
    setSelectedRowKeys([]);
  }, [record]);

  const loadData = () => {
    if (record) {
      setLoadingTabs(true);
      setLoadingTable(true);
      // Load tab data using an API call
      httpGet(`ProjectModule/v1.0/db/Feature/${record.id}?entities=["CableConnection"]`)
        .then((res) => {
          const outCableConnections = res.data.data['CableConnection']?.dbRecords?.filter(
            (elem: DbRecordEntityTransform) => getProperty(elem, 'Direction') === 'OUT',
          );
          setCableConnectionData(res.data.data['CableConnection']?.dbRecords);
          setTabContent(outCableConnections);
        })
        .catch((err) => {
          console.error('Error loading tab data:', err);
        });

      httpGet(
        `ProjectModule/v1.0/db-associations/Feature/${record.id}/one-relation?entity=Feature&withLinks=false`,
      )
        .then((res) => {
          setClosureRfcData(
            res.data['Feature']?.dbRecords?.filter(
              (elem: DbRecordEntityTransform) =>
                elem.type === 'SURVEY_ROUTE' || elem.type === 'SURVEY_STRUCTURE',
            ) || [],
          );
        })
        .catch((err) => {
          console.error('Error loading tab data:', err);
        });

      httpGet(
        `ProjectModule/v1.0/db-associations/Feature/${record.id}/one-relation?entity=FeatureComponent&withLinks=false`,
      )
        .then((res) => {
          setClosureSlotContent(
            res.data['FeatureComponent']?.dbRecords?.filter(
              (elem: DbRecordEntityTransform) => elem.type === 'CLOSURE_SLOT',
            ),
          );
        })
        .catch((err) => {
          console.error('Error loading tab data:', err);
        });

      httpGet(`ProjectModule/v1.0/network/autosplicing/splicing-matrix/${externalRef}`)
        .then((res) => {
          setTableData(res.data.data.data);
          setLoadingTabs(false);
          setLoadingTable(false);
        })
        .catch((err) => {
          console.error('Error loading table data:', err);
        });
    }
  };

  useEffect(() => {
    getOltList();
  }, [youFibreApi?.apiKey]);

  const getOltList = async () => {
    console.log('GET OLT LIST DISABLED');
    // const res = await axios({
    //   method: 'post',
    //   timeout: 60 * 1000 * 2,
    //   url: `${youFibreApi?.baseUrl}/ServiceModule/v1.0/db/lookup`,
    //   headers: {
    //     Authorization: 'Bearer ' + youFibreApi?.apiKey
    //   },
    //   data: {
    //     entity: 'ServiceModule:NetworkDevice',
    //     properties: [
    //       {
    //         columnName: 'ExPolygonId',
    //         operator: 'VECTOR',
    //         value: getProperty(record, 'ExPolygonId')
    //       }
    //     ],
    //     pagination: {
    //       size: 1000,
    //       page: 1
    //     }
    //   }
    // });
    // console.log('getOltList.res', res?.data?.data);
    setOltList([]);
  };

  const exportToCsv = () => {
    const csvData = Papa.unparse(tableData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `closure-${externalRef}-splicing.csv`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const disabledFromEditing = (record: any, key: string) => {
    if (record.splice_complete) {
      return true;
    }

    if (key === 'tray_number' && record.type === 'LOOP') {
      return true;
    }

    if (!record.is_new && !['in_fiber_state', 'out_fiber_state'].includes(key)) {
      return true;
    }
  };

  const handleTabChange = (tabKey: React.SetStateAction<string>) => {
    setActiveTab(tabKey);
    setSelectedRowKeys([]);
    setEditing(false);
    // @ts-ignore
    const tabItem = tabContent.find((tab: any) => tab.id === tabKey);
    setFilteredData(
      tableData.filter(
        (elem: any) => elem.out_cable === String(getProperty(tabItem, 'CableExternalRef')),
      ),
    );
  };

  const edit = (record: any) => {
    setEditing(true);
  };

  const cancel = (record: any) => {
    // Remove item from modifiedData

    const newSelectedKeys = selectedRowKeys.filter((elem) => elem !== record.id);

    const newModifiedData = modifiedData.filter((elem: any) => elem.id !== record.id);

    if (record.is_new) {
      // remove item from newConnections
      const newNewConnections = newConnections.filter((elem: any) => elem.id !== record.id);
      setNewConnections(newNewConnections);
      // remove item from tableData
      const newTableData = tableData.filter((elem: any) => elem.id !== record.id);
      setTableData(newTableData);
      // remove item from filteredData
      const newFilteredData = filteredData.filter((elem: any) => elem.id !== record.id);
      setFilteredData(newFilteredData);
    }

    setModifiedData(newModifiedData);

    setSelectedRowKeys(newSelectedKeys);

    if (modifiedData.length <= 1) {
      setEditing(false);
    }
  };

  const generateTubeList = (tubeCount: number) => {
    const combinations = [];
    for (let t = 1; t <= tubeCount; t++) {
      const combination = `T${t}`;
      combinations.push(combination);
    }
    return combinations;
  };

  const generateFiberList = () => {
    const combinations = [];
    for (let f = 1; f <= 12; f++) {
      const combination = `F${f}`;
      combinations.push(combination);
    }
    return combinations;
  };

  const handleOnChange = (row: any, key: string, val: any) => {
    console.log('OLT CHANGE', row);
    const existsInModified = modifiedData.find((elem: any) => elem.id === row.id);
    if (existsInModified) {
      // replace the modified row with the new one
      const newModifiedData = modifiedData.map((elem: any) => {
        if (elem.id === row.id) {
          return { ...existsInModified, id: elem.id, [key]: val };
        }
        return elem;
      });

      // reset the out closure if the cable changes and it is not a matching pair
      if (key === 'out_cable') {
        if (val !== existsInModified.out_cable) {
          handleOnChange(row, 'out_closure', null);
        }
      }

      // reset the in fiber if the tube changes and it is not a matching pair
      if (key === 'in_tube') {
        if (val !== existsInModified.in_tube) {
          handleOnChange(row, 'in_fiber', null);
        }
      }

      // reset the in fiber if the tube changes and it is not a matching pair
      if (key === 'out_tube') {
        if (val !== existsInModified.out_tube) {
          handleOnChange(row, 'out_fiber', null);
        }
      }

      console.log('MODIFIED DATA', newModifiedData);

      // @ts-ignore
      setModifiedData(newModifiedData);
    } else {
      console.log('NO MODIFIED DATA', [
        ...modifiedData,
        {
          id: row.id,
          [key]: val,
        },
      ]);
      // @ts-ignore
      setModifiedData([...modifiedData, { id: row.id, [key]: val }]);
    }
  };

  const save = async () => {
    try {
      const newTableDataRows = [];
      const creates: ICreateFiberConnection[] = [];
      const updates: any[] = [];
      for (const item of modifiedData) {
        const tableDataItem = tableData.find((elem: any) => elem.id === item.id);
        //@ts-ignore
        if (item.is_new) {
          // Create connections
          // Update table data with the new data
          const newConnection: ICreateFiberConnection = {
            type: item.type,
            fromClosure: item.in_closure,
            fromCable: item.in_cable,
            fromTubeFiber: `${item.in_tube}:${item.in_fiber}`,
            toClosure: item.out_closure,
            toCable: item.out_cable,
            toTubeFiber: `${item.out_tube}:${item.out_fiber}`,
            trayNumber: item.tray_number,
          };

          creates.push(newConnection);
        } else {
          // These are updates
          if (item.in_fiber_state) {
            // find the fiber in the table data
            const update = {
              entity: 'ProjectModule:FeatureComponent',
              type: 'TUBE_FIBER',
              id: tableDataItem.in_fiber_id,
              properties: {
                FiberState: item.in_fiber_state,
              },
            };
            updates.push(update);
          }

          if (item.out_fiber_state) {
            const update = {
              entity: 'ProjectModule:FeatureComponent',
              type: 'TUBE_FIBER',
              id: tableDataItem.out_fiber_id,
              properties: {
                FiberState: item.out_fiber_state,
              },
            };
            updates.push(update);
          }

          if (item.tray_number) {
            // find the closure slot matching the tray number
            const matchingSlot = closureSlots.find(
              (elem: any) => String(getProperty(elem, 'SlotNumber')) === String(item.tray_number),
            );
            if (matchingSlot) {
              // get the slot trayId
              const update = {
                entity: 'ProjectModule:FiberConnection',
                type: tableDataItem.type,
                id: tableDataItem.id,
                properties: {
                  SlotId: matchingSlot.id,
                  AmendedSlotId: matchingSlot.id,
                },
              };
              updates.push(update);
            }
          }

          if (item.hasOwnProperty('olt_name')) {
            // find the fiber in the table data
            const update = {
              entity: 'ProjectModule:FiberConnection',
              type: item.type,
              id: tableDataItem.id,
              properties: {
                OltName: item.olt_name || null,
                OltPort: item.olt_port || null,
              },
            };
            console.log('UPDATE FIBER CONNECTION', update);
            updates.push(update);
          }
        }
      }

      // Handle updates
      if (updates?.length > 0) {
        httpPut(`ProjectModule/v1.0/db/bulk-update`, {
          recordsToUpdate: updates,
        })
          .then((res) => {
            // Update the table data with the new data
            const newTableData = tableData.map((elem: any) => {
              const modified = modifiedData.find((item: any) => item.id === elem.id);
              if (modified) {
                return {
                  ...elem,
                  ...modified,
                };
              }
              return elem;
            });

            setTableData(newTableData);

            // Update the filtered data with the new data
            const newFilteredData = filteredData.map((elem: any) => {
              const modified = modifiedData.find((item: any) => item.id === elem.id);
              if (modified) {
                return {
                  ...elem,
                  ...modified,
                };
              }
              return elem;
            });

            setFilteredData(newFilteredData);
            // Reset state
            setIsUpdating(false);
            setSelectedRowKeys([]);
            setModifiedData([]);
          })
          .catch((err) => {
            console.error('Error loading tab data:', err);
            message.error(err.message);
            setIsUpdating(false);
          });
      }

      // Handle creates
      if (creates?.length > 0) {
        await httpPost(
          `ProjectModule/v1.0/network/changemanager/create-fiber-connections`,
          { fiberConnections: creates },
          (res: any) => {
            console.log('Create Res', res);

            // Update the table data with the new data
            const newTableData = tableData.map((elem: any) => {
              const modified = modifiedData.find((item: any) => item.id === elem.id);
              if (modified) {
                return {
                  ...elem,
                  ...modified,
                };
              }
              return elem;
            });

            setTableData(newTableData);

            // Update the filtered data with the new data
            const newFilteredData = filteredData.map((elem: any) => {
              const modified = modifiedData.find((item: any) => item.id === elem.id);
              if (modified) {
                return {
                  ...elem,
                  ...modified,
                };
              }
              return elem;
            });

            setFilteredData(newFilteredData);
            // We need to fetch the new data because it has unique Ids
            setModifiedData([]);
          },
        ).catch((err) => {
          console.error('Error creating fiber connections', err);
          message.error(err.message);
          setIsUpdating(false);
        });
      }

      setEditing(false);
    } catch (errorInfo) {
      console.error('Validate Failed:', errorInfo);
    }
  };

  const handleAdd = () => {
    const inCableConnection = cableConnections.find(
      (elem) => getProperty(elem, 'Direction') === 'IN',
    );
    const outCableConnection = cableConnections.find((elem) => elem.id === activeTab);

    const newData: any = {
      is_new: true,
      id: uuidv4(),
      type: 'SPLICE',
      olt_name: null,
      olt_port: null,
      in_cable: getProperty(inCableConnection, 'CableExternalRef'),
      in_closure: getProperty(inCableConnection, 'InClosureExternalRef'),
      in_tube_fiber: '',
      in_fiber_state: 'USED',
      tray_number: '',
      out_tube_fiber: '',
      out_fiber_state: 'USED',
      out_cable: getProperty(outCableConnection, 'CableExternalRef'),
      out_closure: getProperty(outCableConnection, 'InClosureExternalRef'),
      editable: true,
    };

    // @ts-ignore
    // setNewConnections([newData, ...newConnections]);
    setModifiedData([...modifiedData, newData]);
    setTableData([newData, ...tableData]);
    setFilteredData([newData, ...filteredData]);
    setEditing(true);
  };

  const outClosureInputDropdown = (row: any) => {
    // const uniqueArr = [...new Set(tableData.map((elem: any) => elem.out_closure))];
    // Get closure cable pairs from the table data
    const closureCablePairs = tableData.map((elem: any) => {
      return {
        closure: Number(elem.out_closure),
        cable: Number(elem.out_cable),
      };
    });

    let outCable = row.out_cable;
    // get modified row
    const modifiedRow = modifiedData.find((elem: any) => elem.id === row.id);
    if (modifiedRow) {
      outCable = Number(modifiedRow.out_cable);
    }

    // if row.out_cable
    // Select the closure cable pairs that match the current row
    const closureCablePairsMatch = closureCablePairs.filter((elem: any) => {
      return elem.cable === Number(outCable);
    });

    // get unique closures
    const uniqueClosures: number[] = [
      ...new Set(closureCablePairsMatch.map((elem: any) => elem.closure)),
    ];

    return (
      <Select
        defaultValue={row.out_closure}
        onChange={(val) => handleOnChange(row, 'out_closure', val)}
      >
        {uniqueClosures
          .sort((a, b) => a - b)
          .map((elem: number) => (
            <Select.Option value={elem}>{elem}</Select.Option>
          ))}
      </Select>
    );
  };

  const outCableInputDropdown = (row: any) => {
    let uniqueCables = [...new Set(tableData.map((elem: any) => elem.out_cable))];

    // Get closure cable pairs from the table data
    const closureCablePairs = tableData.map((elem: any) => {
      return {
        closure: Number(elem.out_closure),
        cable: Number(elem.out_cable),
      };
    });

    // Select the closure cable pairs that match the current row
    const closureCablePairsMatch = closureCablePairs.filter((elem: any) => {
      return elem.closure === row.outClosure;
    });

    // get unique closures
    uniqueCables = [...new Set(closureCablePairsMatch.map((elem: any) => elem.out_cable))];

    return (
      <Select
        defaultValue={row.out_cable}
        onChange={(val) => handleOnChange(row, 'out_cable', val)}
      >
        {uniqueCables
          .sort((a, b) => a - b)
          .map((elem: number) => (
            <Select.Option value={elem}>{elem}</Select.Option>
          ))}
      </Select>
    );
  };

  const inFiberStateInputDropdown = (row: any) => {
    return (
      <Select
        defaultValue={row.in_fiber_state}
        onChange={(val) => handleOnChange(row, 'in_fiber_state', val)}
      >
        <Select.Option value={'USED'}>USED</Select.Option>
        <Select.Option value={'BROKEN'}>BROKEN</Select.Option>
        {/*<Select.Option value={'RESERVED'}>RESERVED</Select.Option>*/}
      </Select>
    );
  };

  const outFiberStateInputDropdown = (row: any) => {
    const uniqueArr = [...new Set(tableData.map((elem: any) => elem.in_closure))];
    return (
      <Select
        defaultValue={row.out_fiber_state}
        onChange={(val) => handleOnChange(row, 'out_fiber_state', val)}
      >
        <Select.Option value={'USED'}>USED</Select.Option>
        <Select.Option value={'BROKEN'}>BROKEN</Select.Option>
        {/*<Select.Option value={'RESERVED'}>RESERVED</Select.Option>*/}
      </Select>
    );
  };

  const inCableTube = (row: any) => {
    // Get the in cable connection
    const inCableConnection = cableConnections.find(
      (elem) => getProperty(elem, 'Direction') === 'IN',
    );
    // Get the cable type
    // const cableConnection = tabContent.find(elem => elem.id === activeTab);
    let tubeCount = 0;
    if ([7].includes(Number(getProperty(inCableConnection, 'CableType')))) {
      // Cablelink 144 fibers 12 tubes
      tubeCount = 12;
    } else if ([1, 2].includes(Number(getProperty(inCableConnection, 'CableType')))) {
      // Spine and distribution 288 fibers 24 tubes
      tubeCount = 24;
    } else if ([3].includes(Number(getProperty(inCableConnection, 'CableType')))) {
      // Access 48 fibers 4 tubes
      tubeCount = 4;
    }

    // get all the used tubes
    const usedTubes = tableData
      .filter((elem: any) => {
        return ['USED', 'BROKEN', 'RESERVED'].includes(elem.in_fiber_state);
      })
      .filter((elem) => elem)
      .map((elem) => elem?.in_tube_fiber?.split(':')[0]);

    const tubeNumbers = usedTubes
      .filter((elem) => elem)
      .map((elem) => Number(elem?.split('T')[1]))
      .sort((a, b) => a - b)
      .filter((elem) => !isNaN(elem));
    // get unique tubeNumbers;
    const uniqueTubeNumbers = [...new Set(tubeNumbers)];

    // Generate all the tubes before the first tube number and 1
    const usedUpstream: string[] = [];
    for (let i = 1; i <= uniqueTubeNumbers[0]; i++) {
      usedUpstream.push(`T${i}`);
    }

    const reduced = usedTubes.reduce((acc: any, curr: any) => {
      acc[curr] = (acc[curr] || 0) + 1;
      return acc;
    }, {});

    const notAvailable = Object.keys(reduced).filter((elem: any) => reduced[elem] >= tubeCount);

    const tubeFiberList = generateTubeList(tubeCount);
    return (
      <Select defaultValue={row.in_tube} onChange={(val) => handleOnChange(row, 'in_tube', val)}>
        {tubeFiberList
          .filter((elem) => ![...notAvailable, ...usedUpstream].includes(elem))
          .map((elem: string) => (
            <Select.Option value={elem}>{elem}</Select.Option>
          ))}
      </Select>
    );
  };

  const inCableFibers = (row: any) => {
    const tubeFiberList = generateFiberList();

    // Get modifiedData
    const modified = modifiedData.find((elem) => elem.id === row.id);

    if (!modified.in_tube) {
      return [];
    }

    // get all the used tubes
    const usedFibers = tableData
      .filter((elem: any) => {
        return (
          ['USED', 'BROKEN', 'RESERVED'].includes(elem.in_fiber_state) &&
          elem.in_tube_fiber.indexOf(modified.in_tube) > -1
        );
      })
      .filter((elem) => elem)
      .map((elem) => elem?.in_tube_fiber?.split(':')[1]);

    const fiberNumbers = usedFibers
      .filter((elem) => elem)
      .map((elem) => Number(elem?.split('F')[1]))
      .sort((a, b) => a - b)
      .filter((elem) => !isNaN(elem));
    // get unique fiberNumbers;
    const uniqueFibers = [...new Set(fiberNumbers)];

    // Generate all the tubes before the first tube number and 1
    const usedUpstream: string[] = [];
    for (let i = 1; i <= uniqueFibers[0]; i++) {
      usedUpstream.push(`F${i}`);
    }

    const reduced = usedFibers.reduce((acc: any, curr: any) => {
      acc[curr] = (acc[curr] || 0) + 1;
      return acc;
    }, {});

    const notAvailable = Object.keys(reduced).filter((elem: any) => reduced[elem] >= 1);

    return (
      <Select defaultValue={row.in_fiber} onChange={(val) => handleOnChange(row, 'in_fiber', val)}>
        {tubeFiberList
          .filter((elem) => ![...notAvailable, ...usedUpstream].includes(elem))
          .map((elem: string) => (
            <Select.Option value={elem}>{elem}</Select.Option>
          ))}
      </Select>
    );
  };

  const outCableTube = (row: any) => {
    // Get the cable type
    const cableConnection = cableConnections.find((elem) => elem.id === activeTab);
    let tubeCount = 0;
    if ([1, 2].includes(Number(getProperty(cableConnection, 'CableType')))) {
      tubeCount = 24;
    } else if ([3].includes(Number(getProperty(cableConnection, 'CableType')))) {
      tubeCount = 4;
    }

    const tubeFiberList = generateTubeList(tubeCount);
    return (
      <Select defaultValue={row.out_tube} onChange={(val) => handleOnChange(row, 'out_tube', val)}>
        {tubeFiberList.map((elem: string) => (
          <Select.Option value={elem}>{elem}</Select.Option>
        ))}
      </Select>
    );
  };

  const outCableFibers = (row: any) => {
    const tubeFiberList = generateFiberList();

    // Get modifiedData
    const modified = modifiedData.find((elem) => elem.id === row.id);

    if (!modified.out_tube) {
      return [];
    }

    // get all the used tubes
    const usedFibers = tableData
      .filter((elem: any) => {
        return (
          ['BROKEN', 'RESERVED'].includes(elem.out_fiber_state) &&
          elem.out_tube_fiber.indexOf(modified.out_tube) > -1
        );
      })
      .filter((elem) => elem)
      .map((elem) => elem?.out_tube_fiber?.split(':')[1]);

    return (
      <Select
        defaultValue={row.out_fiber}
        onChange={(val) => handleOnChange(row, 'out_fiber', val)}
      >
        {tubeFiberList
          .filter((elem) => !usedFibers.includes(elem))
          .map((elem: string) => (
            <Select.Option value={elem}>{elem}</Select.Option>
          ))}
      </Select>
    );
  };

  const inCableInputDropdown = (row: any) => {
    const uniqueArr = [...new Set(tableData.map((elem: any) => elem.in_cable))];
    return (
      <Select defaultValue={row.in_cable} onChange={(val) => handleOnChange(row, 'in_cable', val)}>
        {uniqueArr
          .sort((a, b) => a - b)
          .map((elem: number) => (
            <Select.Option value={elem}>{elem}</Select.Option>
          ))}
      </Select>
    );
  };

  const slotInputDropdown = (row: any) => {
    // Need to filter out slots that are available
    // Max 12 SPLICE
    // MAX 8 SPLIT

    // get all the used tubes
    // Count the number of splice and split per tray_number
    const reduced = tableData.reduce((acc: any, curr: any) => {
      if (!acc[curr.tray_number]) {
        acc[curr.tray_number] = {};
      }
      acc[curr.tray_number][curr.type] = (acc[curr.tray_number][curr.type] || 0) + 1;
      return acc;
    }, {});

    // Filter out all trays that have 12 SPLICE or 8 SPLIT
    const filteredSlots = closureSlots.filter((elem: any) => {
      if (reduced[getProperty(elem, 'SlotNumber')]) {
        if (
          reduced[getProperty(elem, 'SlotNumber')].SPLICE >= 12 ||
          reduced[getProperty(elem, 'SlotNumber')].SPLIT >= 8
        ) {
          return false;
        }
      }
      return true;
    });

    return (
      <Select
        defaultValue={row.tray_number}
        onChange={(val) => handleOnChange(row, 'tray_number', val)}
      >
        {filteredSlots
          .sort((a, b) => getProperty(a, 'SlotNumber') - getProperty(b, 'SlotNumber'))
          .map((elem: DbRecordEntityTransform) => (
            <Select.Option value={getProperty(elem, 'SlotNumber')}>
              {getProperty(elem, 'SlotNumber')}
            </Select.Option>
          ))}
      </Select>
    );
  };

  const typeInputDropdown = (row: any) => {
    return (
      <Select defaultValue={row.type} onChange={(val) => handleOnChange(row, 'type', val)}>
        <Select.Option value={'SPLICE'}>SPLICE</Select.Option>
        {/*<Select.Option value={'SPLIT'}>SPLIT</Select.Option>*/}
      </Select>
    );
  };

  const handleCheck = (id: any) => {
    const newSelectedRowKeys = [...selectedRowKeys];
    // @ts-ignore
    if (newSelectedRowKeys.includes(id)) {
      // @ts-ignore
      newSelectedRowKeys.splice(newSelectedRowKeys.indexOf(id), 1);
    } else {
      // @ts-ignore
      newSelectedRowKeys.push(id);
    }
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleCheckAll = (e: any) => {
    const checked = e.target.checked;
    const newSelectedRowKeys = checked ? tableData.map((record: any) => record.id) : [];
    // @ts-ignore
    setSelectedRowKeys(newSelectedRowKeys);
  };

  function handleCutCable() {
    //  bulk-update API for all fiber connections

    const matchingRows = tableData.filter((row: any) => selectedRowKeys.includes(row.id));

    if (matchingRows.find((elem) => elem.type !== 'LOOP')) {
      message.error('Please select rows with type LOOP');
      return;
    }

    // get the unique outCableIds from the selected rows
    const uniqueOutCableIds = [...new Set(matchingRows.map((row: any) => row.out_cable))];
    if (uniqueOutCableIds.length > 1) {
      message.error('Please select rows with the same out cable id');
      return;
    }

    setIsUpdating(true);
    // get the unique in_tube_fibers from the selected rows
    const uniqueInTubeFibers = [...new Set(matchingRows.map((row: any) => row.in_tube_fiber))];

    httpPatch(`ProjectModule/v1.0/network/changemanager/cut-cable/${uniqueOutCableIds[0]}`, {
      tubeFibers: uniqueInTubeFibers,
    })
      .then((res) => {
        const updatedTableData = tableData.map((row: any) => {
          if (selectedRowKeys.includes(row.id)) {
            return {
              ...row,
              type: 'SPLICE',
            };
          }
          return row;
        });
        setTableData(updatedTableData);

        const updatedFilteredData = filteredData.map((row: any) => {
          if (selectedRowKeys.includes(row.id)) {
            return {
              ...row,
              type: 'SPLICE',
            };
          }
          return row;
        });
        setFilteredData(updatedFilteredData);

        setIsUpdating(false);
        setSelectedRowKeys([]);
      })
      .catch((err) => {
        console.error('Error loading tab data:', err);
        message.error(err.message);
        setIsUpdating(false);
      });
  }

  function handleBulkDeleteFiberConnections() {
    const deletes = selectedRowKeys.map((id: string) => ({
      recordId: id,
      entity: 'ProjectModule:FiberConnection',
    }));

    setIsUpdating(true);

    httpDelete(`ProjectModule/v1.0/db/bulk-delete`, {
      recordsToDelete: deletes,
    })
      .then((res) => {
        // remove items from the data table
        const updatedTableData = tableData.filter((row: any) => !selectedRowKeys.includes(row.id));
        setTableData(updatedTableData);

        // remove items from the filtered data
        const updatedFilteredData = filteredData.filter(
          (row: any) => !selectedRowKeys.includes(row.id),
        );
        setFilteredData(updatedFilteredData);

        setIsUpdating(false);
        setSelectedRowKeys([]);
      })
      .catch((err) => {
        console.error('Error loading tab data:', err);
        message.error(err.message);
        setIsUpdating(false);
      });

    // TODO: bulk update the fibers to unused
    //
    // httpDelete(`ProjectModule/v1.0/db/bulk-update`, {
    //   recordsToUpdate: deletes
    // })
    //  .then((res) => {
    //
    //    console.log('Successfully updated fiber state');
    //
    //  })
    //  .catch((err) => {
    //    console.error('Error loading tab data:', err);
    //    message.error(err.message);
    //    setIsUpdating(false);
    //  });
  }

  function handleMarkOdfPatchingComplete() {
    //  bulk-update API for all fiber connections
    const matchingRows = tableData.filter((row: any) => selectedRowKeys.includes(row.id));

    if (matchingRows.find((elem) => !elem.cablelink_fc_id)) {
      message.error('Please select rows with cablelink_fc_id');
      return;
    }

    setIsUpdating(true);

    const updates = [];

    const fiberConnectionsToLock = matchingRows.map((row: any) => ({
      id: row.cablelink_fc_id,
      entity: 'ProjectModule:FiberConnection',
      type: 'SPLICE',
      // isLocked: true,
      properties: {
        PatchComplete: true,
        PatchDate: dayjs().toISOString(),
        PatchedBy: `${userReducer.user.firstname} ${userReducer.user.lastname}`,
      },
    }));

    updates.push(...fiberConnectionsToLock);

    httpPut(`ProjectModule/v1.0/db/bulk-update`, {
      recordsToUpdate: updates,
    })
      .then((res) => {
        // Update the table data with the new fields on success
        const updatedTableData = tableData.map((row: any) => {
          if (selectedRowKeys.includes(row.id)) {
            return {
              ...row,
              cablelink_patch_complete: true,
              cablelink_patch_date: dayjs().toISOString(),
              cablelink_patched_by: `${userReducer.user.firstname} ${userReducer.user.lastname}`,
            };
          }
          return row;
        });
        setTableData(updatedTableData);

        const updatedFilteredData = filteredData.map((row: any) => {
          if (selectedRowKeys.includes(row.id)) {
            return {
              ...row,
              cablelink_patch_complete: true,
              cablelink_patch_date: dayjs().toISOString(),
              cablelink_patched_by: `${userReducer.user.firstname} ${userReducer.user.lastname}`,
            };
          }
          return row;
        });
        setFilteredData(updatedFilteredData);
        setIsUpdating(false);
        setSelectedRowKeys([]);
      })
      .catch((err) => {
        console.error('Error loading tab data:', err);
        message.error(err.message);
        setIsUpdating(false);
      });
  }

  const oltNameOptions = (row: any) => {
    if (row.id === editingRow?.id) {
      let portCount = 16;
      // get all the used olts
      const usedOlts = tableData.filter((elem) => elem.olt_name).map((elem) => elem?.olt_name);

      // console.log('usedOlts', usedOlts);

      const reduced = usedOlts.reduce((acc: any, curr: any) => {
        acc[curr] = (acc[curr] || 0) + 1;
        return acc;
      }, {});

      // console.log('reduced', reduced);

      const notAvailable = Object.keys(reduced).filter((elem: any) => reduced[elem] >= portCount);
      // console.log('notAvailable', notAvailable);

      return (
        <Select
          defaultValue={row.olt_name}
          allowClear={true}
          onChange={(val) => handleOnChange(row, 'olt_name', val)}
        >
          {oltList
            .filter((elem) => !notAvailable.includes(elem.title!))
            .map((elem: DbRecordEntityTransform) => (
              <Select.Option value={elem.title}>{elem.title}</Select.Option>
            ))}
        </Select>
      );
    } else {
      return (
        <Select
          defaultValue={row.olt_name}
          onChange={(val) => handleOnChange(row, 'olt_name', val)}
          onFocus={() => setEditingRow(row)}
        >
          <Select.Option value={row.olt_name}>{row.olt_name}</Select.Option>
        </Select>
      );
    }
  };
  const oltPortOptions = (row: any) => {
    let oltName = row.olt_name;

    if (!oltName) {
      const modifiedRow = modifiedData.find((elem: any) => elem.id === row.id);
      if (modifiedRow) {
        oltName = modifiedRow.olt_name;
      }
    }

    if (row.id === editingRow?.id && !!oltName) {
      let portCount = 16;

      // get all the used olts
      const oltRows = tableData
        .filter((elem) => elem && elem.olt_name === oltName)
        .map((elem) => elem?.olt_port);

      // Merge table data with modified data
      const modifiedOltRows = modifiedData
        .filter((elem) => elem.olt_name === oltName)
        .map((elem) => elem?.olt_port);
      const mergedOltRows: number[] = [...oltRows, ...modifiedOltRows];
      // console.log('mergedOltRows', mergedOltRows);

      const usedOltPorts = mergedOltRows.filter((elem) => elem).map((elem) => Number(elem));
      // console.log('usedOltPorts', usedOltPorts);

      // Generate a list of ports 1-16
      const portList = [];
      for (let i = 1; i <= portCount; i++) {
        portList.push(i);
      }

      // console.log('portList', portList);
      // console.log('usedOltPorts', usedOltPorts);

      return (
        <Select
          defaultValue={row.olt_port}
          allowClear={true}
          onChange={(val) => handleOnChange(row, 'olt_port', val)}
        >
          {portList
            .filter((elem) => !usedOltPorts.includes(elem))
            .map((elem: number) => (
              <Select.Option value={elem}>{elem}</Select.Option>
            ))}
        </Select>
      );
    } else {
      return (
        <Select
          defaultValue={row.olt_port}
          onChange={(val) => handleOnChange(row, 'olt_port', val)}
          onFocus={() => setEditingRow(row)}
        >
          <Select.Option value={row.olt_port}>{row.olt_port}</Select.Option>
        </Select>
      );
    }
  };

  function unlockFiberConnectionsByCable() {
    setIsUpdating(true);

    httpPatch(
      `ProjectModule/v1.0/network/changemanager/unlock-fibre-connections/${tableData[0]?.in_cable}`,
      {},
    )
      .then((res) => {
        // Update the table data with the new fields on success
        const updatedTableData = tableData.map((row: any) => {
          if (row.splice_complete) {
            return {
              ...row,
              splice_complete: false,
              splice_date: null,
              spliced_by: null,
            };
          }
          return row;
        });
        setTableData(updatedTableData);

        const updatedFilteredData = filteredData.map((row: any) => {
          if (row.splice_complete) {
            return {
              ...row,
              splice_complete: false,
              splice_date: null,
              spliced_by: null,
            };
          }
          return row;
        });
        setFilteredData(updatedFilteredData);
        setIsUpdating(false);
        setSelectedRowKeys([]);
      })
      .catch((err) => {
        console.error('Error loading tab data:', err);
        message.error(err.message);
        setIsUpdating(false);
      });
  }

  async function handleMarkSplicingComplete() {
    // find selected keys in the table data
    const matchingRows = tableData.filter((row: any) => selectedRowKeys.includes(row.id));

    if (matchingRows?.find((elem) => elem.out_cable === '0')) {
      message.error('Please select rows with out_cable');
      return;
    }

    if (matchingRows?.find((elem) => elem.type === 'LOOP')) {
      message.error('Can not mark LOOP as splice complete');
      return;
    }

    //  bulk-update API for all fiber connections
    const updates = [];
    const fiberConnectionsToLock = matchingRows
      .filter((elem) => ['SPLIT', 'SPLICE'].includes(elem.type))
      .map((row: any) => ({
        id: row.id,
        entity: 'ProjectModule:FiberConnection',
        type: row.type,
        isLocked: true,
        properties: {
          SpliceComplete: true,
          SpliceDate: dayjs().toISOString(),
          SplicedBy: `${userReducer.user.firstname} ${userReducer.user.lastname}`,
        },
      }));
    updates.push(...fiberConnectionsToLock);

    const inFibresToLock = matchingRows
      ?.filter((elem) => !elem.splice_complete)
      .map((row: any) => ({
        id: row.in_fiber_id,
        entity: 'ProjectModule:FeatureComponent',
        type: 'TUBE_FIBER',
        isLocked: true,
      }));
    updates.push(...inFibresToLock);

    setIsUpdating(true);

    const batches = chunkArray(updates, 200);

    for (const batch of batches) {
      try {
        await httpPut(`ProjectModule/v1.0/db/bulk-update`, {
          recordsToUpdate: batch,
        });
      } catch (e) {
        console.error('Error loading tab data:', e);
        message.error(e);
        setIsUpdating(false);
      }
    }

    // Update the table data with the new fields on success
    const updatedTableData = tableData.map((row: any) => {
      if (selectedRowKeys.includes(row.id)) {
        return {
          ...row,
          splice_complete: true,
          splice_date: dayjs().toISOString(),
          spliced_by: `${userReducer.user.firstname} ${userReducer.user.lastname}`,
        };
      }
      return row;
    });
    setTableData(updatedTableData);

    const updatedFilteredData = filteredData.map((row: any) => {
      if (selectedRowKeys.includes(row.id)) {
        return {
          ...row,
          splice_complete: true,
          splice_date: dayjs().toISOString(),
          spliced_by: `${userReducer.user.firstname} ${userReducer.user.lastname}`,
        };
      }
      return row;
    });
    setFilteredData(updatedFilteredData);

    setIsUpdating(false);
    setSelectedRowKeys([]);
  }

  const getTableColumns = () => {
    // @ts-ignore
    const columns: TableColumnsType<never> | undefined = [
      // Define your table columns here
      // Example: { title: 'Name', dataIndex: 'name', key: 'name' },
      {
        title: 'CABLELINK ID',
        dataIndex: 'cablelink_id',
        key: 'cablelink_id',
        width: 110,
        render: (value, record: any) => {
          // Conditionally apply a class to the cell based on the record value
          const cellClassName = record.cablelink_patch_complete ? 'cablelink-patch-complete' : '';

          if (record.cablelink_patch_complete) {
            return (
              <div className={cellClassName}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>{value}</div>
                  <div className="date-in-cell">
                    {dayjs(record.cablelink_patch_date).format('DD/MM/YYYY')}
                  </div>
                </div>
              </div>
            );
          } else {
            return <div className="text-cell">{value}</div>;
          }
        },
      },
      {
        title: 'CABLELINK FIBER',
        dataIndex: 'cablelink_fiber',
        key: 'cablelink_fiber',
        width: 100,
        render: (value, record: any) => {
          // Conditionally apply a class to the cell based on the record value
          const cellClassName = record.cablelink_patch_complete ? 'cablelink-patch-complete' : '';

          if (record.cablelink_patch_complete) {
            return (
              <div className={cellClassName}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>{value}</div>
                  <div className="date-in-cell">
                    {dayjs(record.cablelink_patch_date).format('DD/MM/YYYY')}
                  </div>
                </div>
              </div>
            );
          } else {
            return <div className="text-cell">{value}</div>;
          }
        },
      },
      {
        title: 'TYPE',
        dataIndex: 'type',
        key: 'type',
        width: 100,
        render: (value, record: any) =>
          isEditing && !disabledFromEditing(record, 'type') ? (
            <Form.Item name="type" style={{ margin: 0 }} initialValue={value}>
              {typeInputDropdown(record)}
            </Form.Item>
          ) : (
            <div className="text-cell">{value}</div>
          ),
      },
      {
        title: 'IN STATE',
        dataIndex: 'in_fiber_state',
        key: 'in_fiber_state',
        width: 100,
        render: (value, record: any) =>
          isEditing && !disabledFromEditing(record, 'in_fiber_state') ? (
            <Form.Item name="type" style={{ margin: 0 }} initialValue={value}>
              {inFiberStateInputDropdown(record)}
            </Form.Item>
          ) : (
            <div className="align-cell-content-center">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>{value}</div>
                {record.splice_date && (
                  <div className="date-in-cell">
                    {dayjs(record.splice_date).format('DD/MM/YYYY')}
                  </div>
                )}
              </div>
            </div>
          ),
      },
      {
        title: 'IN CLOSURE',
        dataIndex: 'in_closure',
        key: 'in_closure',
        width: 100,
        render: (value, record: any) => <div className="text-cell">{value}</div>,
      },
      {
        title: 'IN CABLE',
        dataIndex: 'in_cable',
        key: 'in_cable',
        width: 100,
        render: (value, record: any) =>
          isEditing && !disabledFromEditing(record, 'in_cable') ? (
            <Form.Item name="type" style={{ margin: 0 }} initialValue={value}>
              {inCableInputDropdown(record)}
            </Form.Item>
          ) : (
            <div className="text-cell">{value}</div>
          ),
      },
      {
        title: 'IN TUBE',
        dataIndex: 'in_tube_fiber',
        key: 'in_tube',
        width: 100,
        render: (value, record) =>
          isEditing && !disabledFromEditing(record, 'in_tube_fiber') ? (
            <Form.Item name="type" style={{ margin: 0 }} initialValue={value}>
              {inCableTube(record)}
            </Form.Item>
          ) : (
            <div
              className="tube-fibre-tag"
              style={{
                //@ts-ignore
                backgroundColor: TubeColor288[value?.split(':')[0]],
                fontWeight: 600,
                color: ['T6', 'T9', 'T12', 'T18', 'T21', 'T24'].includes(value?.split(':')[0])
                  ? 'black'
                  : 'white',
              }}
            >
              {value?.split(':')[0]}
            </div>
          ),
      },
      {
        title: 'IN FIBRE',
        dataIndex: 'in_tube_fiber',
        key: 'in_fiber',
        width: 100,
        render: (value, record) =>
          isEditing && !disabledFromEditing(record, 'in_tube_fiber') ? (
            <Form.Item name="type" style={{ margin: 0 }} initialValue={value}>
              {inCableFibers(record)}
            </Form.Item>
          ) : (
            <div
              className="tube-fibre-tag"
              style={{
                //@ts-ignore
                backgroundColor: FiberColor[value?.split(':')[1]],
                fontWeight: 600,
                color: ['F6', 'F9', 'F12'].includes(value?.split(':')[1]) ? 'black' : 'white',
              }}
            >
              {value?.split(':')[1]}
            </div>
          ),
      },
      {
        title: 'TRAY',
        dataIndex: 'tray_number',
        key: 'tray_number',
        width: 100,
        render: (value, record: any) =>
          isEditing && !disabledFromEditing(record, 'tray_number') ? (
            <Form.Item name="type" style={{ margin: 0 }} initialValue={value}>
              {slotInputDropdown(record)}
            </Form.Item>
          ) : (
            <div className="text-cell">{value}</div>
          ),
      },
      {
        title: 'OUT TUBE',
        dataIndex: 'out_tube_fiber',
        key: 'out_tube',
        width: 100,
        render: (value, record) =>
          isEditing && !disabledFromEditing(record, 'out_tube_fiber') ? (
            <Form.Item name="type" style={{ margin: 0 }} initialValue={value}>
              {outCableTube(record)}
            </Form.Item>
          ) : (
            <div
              className="tube-fibre-tag"
              style={{
                //@ts-ignore
                backgroundColor: TubeColor288[value?.split(':')[0]],
                fontWeight: 600,
                color: ['T6', 'T9', 'T12', 'T18', 'T21', 'T24'].includes(value?.split(':')[0])
                  ? 'black'
                  : 'white',
              }}
            >
              {value?.split(':')[0]}
            </div>
          ),
      },
      {
        title: 'OUT FIBRE',
        dataIndex: 'out_tube_fiber',
        key: 'out_fiber',
        width: 100,
        render: (value, record) =>
          isEditing && !disabledFromEditing(record, 'out_tube_fiber') ? (
            <Form.Item name="type" style={{ margin: 0 }} initialValue={value}>
              {outCableFibers(record)}
            </Form.Item>
          ) : (
            <div
              className="tube-fibre-tag"
              style={{
                //@ts-ignore
                backgroundColor: FiberColor[value?.split(':')[1]],
                fontWeight: 600,
                color: ['F6', 'F9', 'F12'].includes(value?.split(':')[1]) ? 'black' : 'white',
              }}
            >
              {value?.split(':')[1]}
            </div>
          ),
      },
      {
        title: 'OUT CABLE',
        dataIndex: 'out_cable',
        key: 'out_cable',
        width: 100,
        render: (value, record: any) =>
          isEditing && !disabledFromEditing(record, 'out_cable') ? (
            <Form.Item name="type" style={{ margin: 0 }} initialValue={value}>
              {outCableInputDropdown(record)}
            </Form.Item>
          ) : (
            <div className="text-cell">{value}</div>
          ),
      },
      {
        title: 'OUT CLOSURE',
        dataIndex: 'out_closure',
        key: 'out_closure',
        width: 100,
        render: (value, record: any) =>
          isEditing && !disabledFromEditing(record, 'out_closure') ? (
            <Form.Item name="type" style={{ margin: 0 }} initialValue={value}>
              {outClosureInputDropdown(record)}
            </Form.Item>
          ) : (
            <div className="text-cell">{value}</div>
          ),
      },
      {
        title: 'OUT STATE',
        dataIndex: 'out_fiber_state',
        key: 'out_fiber_state',
        width: 100,
        render: (value, record: any) =>
          isEditing && !disabledFromEditing(record, 'out_fiber_state') ? (
            <Form.Item name="type" style={{ margin: 0 }} initialValue={value}>
              {outFiberStateInputDropdown(record)}
            </Form.Item>
          ) : (
            <div className="align-cell-content-center">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>{value}</div>
                {record.splice_date && (
                  <div className="date-in-cell">
                    {dayjs(record.splice_date).format('DD/MM/YYYY')}
                  </div>
                )}
              </div>
            </div>
          ),
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        fixed: 'right', // Fix the column on the left
        render: (_, record: any) => {
          let fiberConnectionSummary = (
            <div style={{ width: 500 }}>
              <Tooltip title="" mouseEnterDelay={1.5}>
                <Link to={`/ProjectModule/FiberConnection/${record.id}`} target="_blank">
                  <Button
                    ghost
                    size="large"
                    type="link"
                    disabled={!record}
                    icon={<ExportOutlined />}
                  />
                </Link>
              </Tooltip>

              <Descriptions
                bordered
                size="small"
                title="Splice Information"
                column={1}
                layout="horizontal"
              >
                <Descriptions.Item label="Type">{record.type}</Descriptions.Item>
                <Descriptions.Item label="In Closure">{record.in_closure}</Descriptions.Item>
                <Descriptions.Item label="In Cable">{record.in_cable}</Descriptions.Item>
                <Descriptions.Item label="Out Closure">{record.out_closure}</Descriptions.Item>
                <Descriptions.Item label="Out Cable">{record.out_cable}</Descriptions.Item>
              </Descriptions>

              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                <div style={{ flex: 1, marginRight: 5 }}>
                  <Descriptions
                    bordered
                    size="small"
                    title="Inbound Fiber"
                    column={1}
                    layout="horizontal"
                  >
                    <Descriptions.Item label="Tube/Fibre">{record.in_tube_fiber}</Descriptions.Item>
                    <Descriptions.Item label="Fiber State">
                      {record.in_fiber_state}
                    </Descriptions.Item>
                    <Descriptions.Item label="Slot Number">{record.tray_number}</Descriptions.Item>
                  </Descriptions>
                </div>
                <div style={{ flex: 1, marginLeft: 5 }}>
                  <Descriptions
                    bordered
                    size="small"
                    title="Outbound Fiber"
                    column={1}
                    layout="horizontal"
                  >
                    <Descriptions.Item label="Tube/Fibre">
                      {record.out_tube_fiber}
                    </Descriptions.Item>
                    <Descriptions.Item label="Fiber State">
                      {record.out_fiber_state}
                    </Descriptions.Item>
                    <Descriptions.Item label="Slot Number">{record.tray_number}</Descriptions.Item>
                  </Descriptions>
                </div>
              </div>

              <Descriptions bordered size="small" title="Field Work" column={1} layout="horizontal">
                <Descriptions.Item label="Cablelink">{record.cablelink_id}</Descriptions.Item>
                <Descriptions.Item label="Cablelink Fibre">
                  {record.cablelink_fiber}
                </Descriptions.Item>
                <Descriptions.Item label="Patch Date">
                  {record.cablelink_patched_date}
                </Descriptions.Item>
                <Descriptions.Item label="Patched By">
                  {record.cablelink_patched_by}
                </Descriptions.Item>
                <Descriptions.Item label="Splice Date">{record.splice_date}</Descriptions.Item>
                <Descriptions.Item label="Spliced By">{record.spliced_by}</Descriptions.Item>
              </Descriptions>
            </div>
          );
          return (
            <>
              <Popover
                style={{ width: 500 }}
                content={fiberConnectionSummary}
                placement="left"
                trigger="hover"
              >
                <Button icon={<InfoCircleOutlined />} type="default" />
              </Popover>
              <Button
                style={{ marginLeft: 5 }}
                disabled={record.splice_complete}
                icon={<CloseCircleOutlined />}
                onClick={() => cancel(record)}
              />
            </>
          );
        },
      },
    ];

    // If the closure is an L0 closure
    // add the olt_name and old_port columns in position 1 and 2

    if (getProperty(record, 'ClosureType') === '1') {
      columns.unshift(
        {
          title: (
            <Checkbox
              className="text-cell"
              disabled={loadingTable}
              indeterminate={
                selectedRowKeys.length > 0 && selectedRowKeys.length < tableData.length
              }
              checked={selectedRowKeys.length === tableData.length}
              onChange={handleCheckAll}
            />
          ),
          dataIndex: 'checkbox',
          width: 40,
          render: (_, record: any) => (
            <Checkbox
              className="text-cell"
              disabled={
                loadingTable ||
                record.splice_complete ||
                record.in_fiber_state === 'BROKEN' ||
                record.out_fiber_state === 'BROKEN'
              }
              // @ts-ignore
              checked={selectedRowKeys.includes(record.id)}
              // @ts-ignore
              onChange={() => handleCheck(record.id)}
            />
          ),
        },
        {
          title: 'OLT_NAME',
          dataIndex: 'olt_name',
          key: 'olt_name',
          width: isEditing ? 200 : 100,
          render: (value, record: any) =>
            isEditing ? (
              <Form.Item name="type" style={{ margin: 0 }} initialValue={value}>
                {oltNameOptions(record)}
              </Form.Item>
            ) : (
              <div className="text-cell">{value}</div>
            ),
        },
        {
          title: 'OLT_PORT',
          dataIndex: 'olt_port',
          key: 'olt_port',
          width: 100,
          render: (value, record: any) =>
            isEditing ? (
              <Form.Item name="type" style={{ margin: 0 }} initialValue={value}>
                {oltPortOptions(record)}
              </Form.Item>
            ) : (
              <div className="text-cell">{value}</div>
            ),
        },
      );
    } else {
      columns.unshift({
        title: (
          <Checkbox
            className="text-cell"
            disabled={loadingTable}
            indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < tableData.length}
            checked={selectedRowKeys.length === tableData.length}
            onChange={handleCheckAll}
          />
        ),
        dataIndex: 'checkbox',
        width: 40,
        render: (_, record: any) => (
          <Checkbox
            className="text-cell"
            disabled={
              loadingTable ||
              record.splice_complete ||
              record.in_fiber_state === 'BROKEN' ||
              record.out_fiber_state === 'BROKEN'
            }
            // @ts-ignore
            checked={selectedRowKeys.includes(record.id)}
            // @ts-ignore
            onChange={() => handleCheck(record.id)}
          />
        ),
      });
    }

    return columns;
  };

  const initializeRFCCreateForm = () => {
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      SchemaModuleTypeEnums.PROJECT_MODULE,
      SchemaModuleEntityTypeEnums.FEATURE,
    );

    if (schema) {
      initializeForm({
        formUUID: uuid,
        isCreateReq: true,
        title: 'Create' + schema.entityName,
        showFormModal: true,
        isBatchCreateReq: false,
        schema: schema,
        recordType: 'SURVEY_STRUCTURE',
        sections: [{ name: schema?.name, schema: schema }],
        modified: [
          {
            associations: [
              {
                entity: record.entity,
                recordId: record.id,
                relationType: RelationTypeEnum.PARENT,
              },
            ],
            schemaId: schema?.id,
          },
        ],
      });
    }
  };

  const handleQuickViewAction = (record: DbRecordEntityTransform) => {
    openDrawer({
      recordId: record.id,
      moduleName: SchemaModuleTypeEnums.PROJECT_MODULE,
      entityName: SchemaModuleEntityTypeEnums.FEATURE,
    });
  };

  const hasUnresolvedRFC = () => {
    return closureRfcData?.filter((elem) => getProperty(elem, 'Resolved') !== 'true')?.length > 0;
  };

  const ActionPanel = () => {
    const menu = (
      <Menu>
        <Menu.Item>
          <Button
            style={{ width: 115 }}
            disabled={
              activeTab === 'all' ||
              loadingTable ||
              !hasPermissions(userReducer, ['projectmodule.fiberconnection.create'])
            }
            // disabled={true}
            onClick={() => handleAdd()}
          >
            New Splice
          </Button>
        </Menu.Item>
        <Menu.Item>
          <Popconfirm
            disabled={
              loadingTable ||
              selectedRowKeys.length === 0 ||
              (hasUnresolvedRFC() &&
                !hasPermissions(userReducer, ['projectmodule.fiberconnection.delete']))
            }
            title="Please confirm."
            onConfirm={() => handleBulkDeleteFiberConnections()}
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              style={{ width: 115 }}
              disabled={
                loadingTable ||
                selectedRowKeys.length === 0 ||
                (hasUnresolvedRFC() &&
                  !hasPermissions(userReducer, ['projectmodule.fiberconnection.delete']))
              }
            >
              Delete ({selectedRowKeys.length})
            </Button>
          </Popconfirm>
        </Menu.Item>
        <Menu.Item>
          <Popconfirm
            disabled={
              activeTab === 'all' ||
              loadingTable ||
              selectedRowKeys.length === 0 ||
              (hasUnresolvedRFC() &&
                !hasAnyRoles(
                  userReducer,
                  'NetworkPlanningLead',
                  'RegionalPlanningLead',
                  'ConnectionAdmin',
                ))
            }
            title="Please confirm."
            onConfirm={() => handleCutCable()}
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              style={{ width: 115 }}
              disabled={
                activeTab === 'all' ||
                loadingTable ||
                selectedRowKeys.length === 0 ||
                (hasUnresolvedRFC() &&
                  !hasAnyRoles(
                    userReducer,
                    'NetworkPlanningLead',
                    'RegionalPlanningLead',
                    'ConnectionAdmin',
                  ))
              }
            >
              Cut Fibres ({selectedRowKeys.length})
            </Button>
          </Popconfirm>
        </Menu.Item>
        <Menu.Item>
          <Button style={{ width: 115 }} disabled={loadingTable} onClick={exportToCsv}>
            Export
          </Button>
        </Menu.Item>
        <Menu.Item>{enableSplicingUploader && <SplicingMatrixUpload record={record} />}</Menu.Item>
      </Menu>
    );

    function getClosureNameFromType(property: any) {
      switch (property) {
        case 1:
          return 'L0';
        case 2:
          return 'L1';
        case 3:
          return 'L2';
        case 4:
          return 'L3';
        case 5:
          return 'L4';
        case 6:
          return 'LX';
        case 7:
          return 'LC';
        case 11:
          return 'LM';
        case 12:
          return 'LT';
        case 9:
          return 'ODF';
        case 10:
          return 'OLT';
        default:
          return '';
      }
    }

    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between', // Use 'space-between' to align to the left and right
          padding: '10px',
          background: '#f0f0f0',
        }}
      >
        <div
          style={{
            height: '30px',
            width: 200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text strong>
            {getClosureNameFromType(Number(getProperty(record, 'ClosureType')))} -{record.type} -{' '}
            {getProperty(record, 'ExternalRef')}
          </Text>
        </div>

        <div
          style={{
            width: '100%',
            background: '#f0f0f0',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            // display: 'flex',
            // justifyContent: 'flex-end' // Use 'space-between' to align to the left and right
          }}
        >
          <Button.Group>
            {/*<Popconfirm*/}
            {/*  disabled={*/}
            {/*    loadingTable ||*/}
            {/*    selectedRowKeys.length === 0 ||*/}
            {/*    (hasUnresolvedRFC() &&*/}
            {/*      !hasAnyRoles(userReducer, 'NetworkPlanningLead', 'RegionalPlanningLead'))*/}
            {/*  }*/}
            {/*  title="Please confirm you are marking splicing complete?"*/}
            {/*  onConfirm={() => handleMarkOdfPatchingComplete()}*/}
            {/*  okText="Yes"*/}
            {/*  cancelText="No"*/}
            {/*>*/}
            {/*  <Button*/}
            {/*    disabled={*/}
            {/*      loadingTable ||*/}
            {/*      selectedRowKeys.length === 0 ||*/}
            {/*      (hasUnresolvedRFC() &&*/}
            {/*        !hasAnyRoles(userReducer, 'NetworkPlanningLead', 'RegionalPlanningLead'))*/}
            {/*    }*/}
            {/*  >*/}
            {/*    Patch Complete ({selectedRowKeys.length})*/}
            {/*  </Button>*/}
            {/*</Popconfirm>*/}
            {/*<Popconfirm*/}
            {/*  disabled={loadingTable || selectedRowKeys.length === 0 || hasUnresolvedRFC()}*/}
            {/*  title="Please confirm you are marking splicing complete?"*/}
            {/*  onConfirm={() => handleMarkSplicingComplete()}*/}
            {/*  okText="Yes"*/}
            {/*  cancelText="No"*/}
            {/*>*/}
            {/*  <Button disabled={loadingTable || selectedRowKeys.length === 0 || hasUnresolvedRFC()}>*/}
            {/*    Splice Complete ({selectedRowKeys.length})*/}
            {/*  </Button>*/}
            {/*</Popconfirm>*/}
            {/*{hasPermissions(userReducer, ['projectmodule.fiberconnection.unlock']) && (*/}
            {/*  <Popconfirm*/}
            {/*    disabled={*/}
            {/*      loadingTable ||*/}
            {/*      (hasUnresolvedRFC() &&*/}
            {/*        !hasAnyRoles(userReducer, 'NetworkPlanningLead', 'RegionalPlanningLead'))*/}
            {/*    }*/}
            {/*    title="Please confirm you want to unlock this fiber connection?"*/}
            {/*    onConfirm={() => unlockFiberConnectionsByCable()}*/}
            {/*    okText="Yes"*/}
            {/*    cancelText="No"*/}
            {/*  >*/}
            {/*    <Button*/}
            {/*      danger*/}
            {/*      disabled={*/}
            {/*        loadingTable ||*/}
            {/*        (hasUnresolvedRFC() &&*/}
            {/*          !hasAnyRoles(userReducer, 'NetworkPlanningLead', 'RegionalPlanningLead'))*/}
            {/*      }*/}
            {/*    >*/}
            {/*      Unlock fiber connections*/}
            {/*    </Button>*/}
            {/*  </Popconfirm>*/}
            {/*)}*/}
            {isEditing ? (
              <Popconfirm
                disabled={loadingTable || modifiedData.length === 0 || hasUnresolvedRFC()}
                title="Please confirm."
                onConfirm={() => save()}
                okText="Yes"
                cancelText="No"
              >
                <Button>Save ({modifiedData.length})</Button>
              </Popconfirm>
            ) : (
              <Button
                disabled={
                  loadingTable ||
                  hasUnresolvedRFC() ||
                  !hasPermissions(userReducer, ['projectmodule.fiberconnection.update'])
                }
                onClick={() => setEditing(true)}
              >
                Edit ({selectedRowKeys.length})
              </Button>
            )}
            <Button
              disabled={loadingTable || hasUnresolvedRFC()}
              onClick={() => initializeRFCCreateForm()}
            >
              New RFC
            </Button>
            <Dropdown disabled={loadingTable} overlay={menu} placement="bottomRight">
              <Button>More</Button>
            </Dropdown>
            <Button disabled={loadingTable} onClick={() => loadData()}>
              Refresh
            </Button>
          </Button.Group>
        </div>
      </div>
    );
  };

  const getRowClassName = (record: any) => {
    if (modifiedData.some((modifiedRecord: any) => modifiedRecord.id === record.id)) {
      return 'modified-values ';
    }
    if (record.splice_complete) {
      return 'splicing-complete';
    }

    if (record.in_fiber_state === 'BROKEN' || record.out_fiber_state === 'BROKEN') {
      return 'fiber-broken';
    }

    return '';
  };

  function handleSubmitSuccess(params: { event: string; results: any }) {
    httpGet(
      `ProjectModule/v1.0/db-associations/Feature/${record.id}/one-relation?entity=Feature&withLinks=false`,
    )
      .then((res) => {
        setClosureRfcData(
          res.data['Feature']?.dbRecords?.filter(
            (elem: DbRecordEntityTransform) => elem.type === 'SURVEY_STRUCTURE',
          ),
        );
      })
      .catch((err) => {
        console.error('Error loading tab data:', err);
      });
  }

  function hasOverrideAccess() {
    return hasAnyRoles(
      userReducer,
      'NetworkPlanningLead',
      'RegionalPlanningLead',
      'ConnectionAdmin',
    );
  }

  return (
    <div className="closure-fiber-splicing">
      <CoreForm
        type="MODAL"
        formUUID={uuid}
        onSubmitEvent={(params: { event: string; results: any }) => handleSubmitSuccess(params)}
      />
      <div>
        <Tabs
          defaultActiveKey="all"
          activeKey={activeTab}
          onChange={handleTabChange}
          destroyInactiveTabPane={true}
        >
          {loadingTabs ? (
            <TabPane tab="Loading cable connections..." key="loading" disabled />
          ) : (
            <>
              <TabPane tab="All Connections" key="all" />
              {tabContent?.map((tab: DbRecordEntityTransform) => (
                <TabPane
                  tab={`${getCableTypeFromId(
                    Number(getProperty(tab, 'CableType')),
                  )} - ${getProperty(tab, 'CableExternalRef')}`}
                  key={tab.id}
                />
              ))}
              <TabPane tab={`RFC (${closureRfcData.length})`} key="rfc-list" />
            </>
          )}
        </Tabs>
      </div>
      <ActionPanel />
      {hasUnresolvedRFC() && !hasOverrideAccess() ? (
        <Result
          icon={<LockFilled />}
          status="error"
          title="Splicing Locked"
          subTitle="This feature is locked due to an open RFC. When the RFC is complete, you can access splicing data."
          extra={
            [
              // <Button type="primary" key="console">
              //   Go Console
              // </Button>,
            ]
          }
        >
          <div className="desc">
            <AssociationSimpleList
              parentRecord={record}
              associationEntityName={SchemaModuleEntityTypeEnums.FEATURE}
              associationModuleName={SchemaModuleTypeEnums.PROJECT_MODULE}
              associationTypes={['SURVEY_STRUCTURE', 'SURVEY_ROUTE']}
              recordColumns={['ExternalRef', 'Description', 'stage']}
              overrideTitle="List of RFCs"
              customRowAction={handleQuickViewAction}
            />
          </div>
        </Result>
      ) : activeTab === 'rfc-list' ? (
        <AssociationSimpleList
          parentRecord={record}
          associationEntityName={SchemaModuleEntityTypeEnums.FEATURE}
          associationModuleName={SchemaModuleTypeEnums.PROJECT_MODULE}
          associationTypes={['SURVEY_STRUCTURE', 'SURVEY_ROUTE']}
          recordColumns={['ExternalRef', 'Description', 'stage']}
          overrideTitle="List of RFCs"
          customRowAction={handleQuickViewAction}
        />
      ) : getProperty(record, 'BuildStatus') < 6 && !hasOverrideAccess() ? (
        <Result
          status="error"
          title="Closure not ready for splicing"
          subTitle="When the closure reached build status 6-In Progress splicing will be available."
          extra={
            [
              // <Button type="primary" key="console">
              //   Go Console
              // </Button>,
            ]
          }
        ></Result>
      ) : (
        <Table
          bordered
          //@ts-ignore
          columns={getTableColumns()}
          dataSource={
            activeTab === 'all'
              ? [...newConnections, ...tableData]
              : [...newConnections, ...filteredData]
          }
          loading={loadingTable || isUpdating}
          rowClassName={getRowClassName}
          rowKey="id"
          pagination={false} // Disable pagination
          scroll={{
            y: 400,
            x: 'max-content',
          }} // Set the desired height for scrolling
        />
      )}
    </div>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
});

export default connect(mapState, mapDispatch)(ClosureFiberSplicingTable);
