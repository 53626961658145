import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import OrderBuilder from '../index';
import { Spin } from 'antd';

import { isMobile } from 'react-device-detect';
import { IUserInterfaceReducer } from '../../../../../redux/stores/userInterface/types';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import { getRecordByIdRequest, IGetRecordById } from '../../../../../redux/stores/records/actions';
import {
  closeOrderBuilderDrawer,
  openOrderBuilderDrawer,
} from '../../../../../redux/stores/userInterface/actions';
import { Drawer } from '@blueprintjs/core';

type Props = RouteComponentProps<any> & {
  match: any;
  getRecordById: Function;
  userInterfaceReducer: IUserInterfaceReducer;
  openDrawer: () => void;
  closeDrawer: () => void;
};

const { CRM_MODULE } = SchemaModuleTypeEnums;
const { ACCOUNT, LEAD } = SchemaModuleEntityTypeEnums;

const OrderBuilderDrawer: FC<Props> = (props: Props) => {
  const { getRecordById, userInterfaceReducer, closeDrawer } = props;
  const open = userInterfaceReducer.orderBuilderDrawerVisible;
  const [accountRecord, setAccountRecord] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [leadRecord, setLeadRecord] = useState<DbRecordEntityTransform | undefined>(undefined);

  // Fetch schemas on component mount
  useEffect(() => {
    getSchemasAndRecords();
  }, [open]);

  const onClose = () => {
    setAccountRecord(undefined);
    setLeadRecord(undefined);
    closeDrawer();
  };

  const getSchemasAndRecords = async () => {
    const { userInterfaceReducer } = props;
    const accountId = userInterfaceReducer?.orderBuilderDrawerRecordIds?.accountId;
    const leadId = userInterfaceReducer?.orderBuilderDrawerRecordIds?.leadId;

    try {
      // Fetch Account Schema and Record
      if (accountId) {
        const accountSchema = await getOdinSchemaByEntity(CRM_MODULE, ACCOUNT);

        if (accountSchema) {
          getRecordById(
            {
              schema: accountSchema,
              recordId: accountId,
            },
            (response: any) => {
              setAccountRecord(response);
            },
          );
        }
      }

      // Fetch Lead Schema and Record
      if (leadId) {
        const leadSchema = await getOdinSchemaByEntity(CRM_MODULE, LEAD);
        if (leadSchema) {
          getRecordById(
            {
              schema: leadSchema,
              recordId: leadId,
            },
            (response: any) => {
              setLeadRecord(response);
            },
          );
        }
      }
    } catch (error) {}
  };

  return (
    <Drawer
      title=""
      isOpen={open}
      usePortal={true}
      onClose={() => onClose()}
      style={{ width: isMobile ? '100%' : '95%', overflowY: 'auto' }}
    >
      {accountRecord || leadRecord ? (
        <div style={{ padding: 15 }}>
          <OrderBuilder defaultAccount={accountRecord} defaultLead={leadRecord} />
        </div>
      ) : (
        <Spin />
      )}
    </Drawer>
  );
};

const mapState = (state: any) => ({
  userInterfaceReducer: state.userInterfaceReducer,
});

const mapDispatch = (dispatch: any) => ({
  getRecordById: (payload: IGetRecordById, cb?: any) => dispatch(getRecordByIdRequest(payload, cb)),
  openDrawer: () => dispatch(openOrderBuilderDrawer()),
  closeDrawer: () => dispatch(closeOrderBuilderDrawer()),
});

export default withRouter(connect(mapState, mapDispatch)(OrderBuilderDrawer));
