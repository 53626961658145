import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { Button, Dialog, DialogBody, DialogFooter, InputGroup } from '@blueprintjs/core';

import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaColumnTypes } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/types/schema.column.types';
import { SchemaColumnOptionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/option/schema.column.option.entity';

import { OdinDropdown, OdinDropdownOption } from 'src/core/components/OdinDropdown';
import { OdinElasticFilter } from '../index';

interface FilterDialogProps {
  schema: SchemaEntity;
  isOpen: boolean;
  onConfirm: (filter: OdinElasticFilter['values'][number]) => void;
  onClose?: () => void;
}

const getColumnsForSchema = (schema: SchemaEntity): OdinDropdownOption[] => {
  const columns = schema.columns.map((column) => ({
    id: column.id,
    text: column.name,
  }));

  if (schema.moduleName === 'SupportModule' && schema.entityName === 'Case') {
    columns.push({
      id: 'StageName',
      text: 'Status',
    });
  }

  columns.sort((a, b) => a.text.localeCompare(b.text));

  return columns;
};

const getCurrentColumnForSchema = (
  schema: SchemaEntity,
  selectedColumn: OdinDropdownOption | null,
): SchemaColumnEntity | undefined => {
  if (
    schema.moduleName === 'SupportModule' &&
    schema.entityName === 'Case' &&
    selectedColumn?.id === 'StageName'
  ) {
    return {
      id: 'StageName',
      name: 'Status',
      type: SchemaColumnTypes.ENUM,
      options: [
        { value: 'Open', label: 'Open' },
        { value: 'Blocked', label: 'Blocked' },
        { value: 'Pending Reply', label: 'Pending Reply' },
        { value: 'Pending Agent', label: 'Pending Agent' },
        { value: 'Pending Review', label: 'Pending Review' },
        { value: 'Solved', label: 'Solved' },
        { value: 'Closed', label: 'Closed' },
      ] as SchemaColumnOptionEntity[],
    } as SchemaColumnEntity;
  }

  const column = schema.columns.find((column) => column.id === selectedColumn?.id);
  column?.options?.sort((a, b) => a.label.localeCompare(b.label));
  return column;
};

export const FilterDialog: React.FC<FilterDialogProps> = ({
  schema,
  isOpen,
  onClose,
  onConfirm,
}) => {
  const [selectedColumn, setSelectedColumn] = useState<OdinDropdownOption | null>(null);
  const [selectedOperation, setSelectedOperation] = useState<OdinDropdownOption | null>(
    { id: 'eq', text: 'Equals' }
  );
  const [selectedValue, setSelectedValue] = useState<string>('');

  const columns = getColumnsForSchema(schema);

  const operations = [
    {
      id: 'eq',
      text: 'Equals',
    },
    {
      id: 'gte',
      text: 'Greater Than or Equals',
    },
    {
      id: 'lte',
      text: 'Less Than or Equals',
    },
    {
      id: 'gt',
      text: 'Greater Than',
    },
    {
      id: 'lt',
      text: 'Less Than',
    },
    {
      id: 'isNull',
      text: 'Is Null',
    },
    {
      id: 'in',
      text: 'In',
    },
    {
      id: 'not in',
      text: 'Not In',
    },
  ].sort((a, b) => a.text.localeCompare(b.text));

  const currentColumn = getCurrentColumnForSchema(schema, selectedColumn);
  const columnValues =
    currentColumn?.type === 'ENUM'
      ? currentColumn.options.map((o) => ({
          id: o.value,
          text: o.label,
        }))
      : [];
  const currentValue = columnValues.find((value) => value.id === selectedValue);

  const isSubmitDisabled =
    (!selectedColumn || !selectedOperation || !selectedValue) &&
    !(selectedColumn?.id && selectedOperation?.id === 'isNull');

  return (
    <Dialog
      icon="filter"
      title="Add Filter"
      isOpen={isOpen}
      onClose={() => onClose?.()}
      style={{ maxWidth: '450px' }}
      canOutsideClickClose
      canEscapeKeyClose
    >
      <DialogBody>
        {/* Column */}
        <Row style={{ padding: 12, alignItems: 'baseline' }}>
          <Col span={8}>
            <label htmlFor="column-dropdown">Column:</label>
          </Col>
          <Col span={16}>
            <OdinDropdown
              id="column-dropdown"
              items={columns}
              placeholder={'Select a property'}
              selectedItem={selectedColumn}
              onChange={setSelectedColumn}
            />
          </Col>
        </Row>

        {/* Operation */}
        <Row style={{ padding: 12, alignItems: 'baseline' }}>
          <Col span={8}>
            <label htmlFor="operation-dropdown">Operation:</label>
          </Col>
          <Col span={16}>
            <OdinDropdown
              id="operation-dropdown"
              items={operations}
              placeholder={'Select an operation'}
              selectedItem={selectedOperation}
              onChange={(value) => {
                setSelectedOperation(value);
                if (value?.id === 'isNull') setSelectedValue('');
              }}
            />
          </Col>
        </Row>

        {/* Value */}
        {currentColumn?.type === 'ENUM' ? (
          <Row style={{ padding: 12, alignItems: 'baseline' }}>
            <Col span={8}>
              <label htmlFor="value-dropdown">Value:</label>
            </Col>
            <Col span={16}>
              <OdinDropdown
                id="value-dropdown"
                items={columnValues}
                placeholder={'Select a value'}
                selectedItem={currentValue ? currentValue : null}
                disabled={selectedOperation?.id === 'isNull'}
                onChange={(value) => setSelectedValue(value?.id || '')}
              />
            </Col>
          </Row>
        ) : (
          <Row style={{ padding: 12, alignItems: 'baseline' }}>
            <Col span={8}>
              <label htmlFor="value-input">Value:</label>
            </Col>
            <Col span={16}>
              <InputGroup
                id="value-input"
                placeholder={'Input a value'}
                value={selectedValue}
                disabled={selectedOperation?.id === 'isNull'}
                onChange={(e) => setSelectedValue(e.target.value)}
              />
            </Col>
          </Row>
        )}
      </DialogBody>
      <DialogFooter
        actions={
          <Button
            intent="primary"
            disabled={isSubmitDisabled}
            onClick={() => {
              const column =
                selectedColumn?.id === 'StageName'
                  ? { id: 'StageName', name: 'StageName' }
                  : getCurrentColumnForSchema(schema, selectedColumn);
              onConfirm({
                columnName: column?.name || '',
                operator: selectedOperation?.id || '',
                value: ['in', 'not in'].includes(selectedOperation?.id || '')
                  ? [selectedValue]
                  : selectedValue,
              });

              setSelectedColumn(null);
              setSelectedOperation(null);
              setSelectedValue('');
            }}
            style={{ marginLeft: 'auto' }}
          >
            Add Filter
          </Button>
        }
      />
    </Dialog>
  );
};
