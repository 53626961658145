import { Alert, Button, Collapse, Divider, EntityTitle, Section, Tag } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import CoreForm from '@core/components/Forms/CoreForm';
import { initializeRecordForm } from '@redux/stores/form/actions';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { httpDelete, httpGet } from '@core/http/requests';
import { displayMessage } from '@redux/stores/messages/reducers';
import ViewConfigEditCreateForm from '@core/modules/ControlPanelModule/components/ViewConfigs/ViewConfigEditCreateForm';
import { getOdinSchemaById } from '@core/helpers/schemaHelpers';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { Link } from 'react-router-dom';
import ViewConfigDefinitionEditor from '../ViewConfigDefinitionEditor';

interface Props {
  config: any;
  onUpdate: (action?: any) => void;
  onDelete: (actionId: string) => void;
  initializeForm: (params: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

const ConfigListViewDetails: FC<Props> = (props: Props) => {
  const { config, onUpdate, onDelete, initializeForm, alertMessage } = props;

  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);
  const [isShowingSchemaActionDetails, setIsShowingSchemaActionDetails] = useState<boolean>(true);
  const [isDeletingConfig, setIsDeletingConfig] = useState<boolean>(false);
  const [editedConfig, setEditedConfig] = useState<DbRecordEntityTransform | undefined>(undefined);
  const [isDeleteConfigAlertVisible, setIsDeleteConfigAlertVisible] = useState<boolean>(false);
  const [copiedTextToClipboard, setCopiedTextToClipboard] = useState<boolean>(false);
  const [isLoadingSchema, setIsLoadingSchema] = useState<boolean>(false);
  const [pipeline, setPipeline] = useState<PipelineEntity | undefined>(undefined);
  const [isShowingSchemaDetails, setIsShowingSchemaDetails] = useState<boolean>(true);

  useEffect(() => {
    if (config) {
      setSchema(undefined);
      getSchemaForViewConfig(config.schemaId);
    }
  }, [config]);

  const getSchemaForViewConfig = async (schemaId: string) => {
    try {
      setIsLoadingSchema(true);
      const res = await getOdinSchemaById(schemaId);
      setSchema(res);
      setIsLoadingSchema(false);
      getSchemaPipelines(res, config.schemaTypeId);
    } catch (e: any) {
      setIsLoadingSchema(false);
      alertMessage({ body: e.message, type: 'error' });
    }
  };

  const getSchemaPipelines = async (schema: SchemaEntity, schemaTypeId?: string | null) => {
    try {
      const schemaType = schema.types.find((type) => type.id === schemaTypeId);
      const res = await httpGet(
        `SchemaModule/v1.0/pipelines/bymodule/${schema?.moduleName}/${schema?.entityName}${
          schemaType ? `?schemaType=${schemaType.name}` : '?schemaType='
        }`,
      );

      const pipelines = res?.data?.data || [];
      if (pipelines.length > 0) {
        setPipeline(pipelines[0]);
      }
    } catch (e: any) {}
  };

  const onConfigUpdate = (config: any) => {
    onUpdate && onUpdate(config);
  };

  const deleteConfig = async () => {
    setIsDeletingConfig(true);
    if (config) {
      try {
        await httpDelete(`SchemaModule/v1.0/schemas-views/${config.id}`);
        setIsDeletingConfig(false);
        setIsDeleteConfigAlertVisible(false);
        onDelete && onDelete(config?.id);
      } catch (e: any) {
        setIsDeletingConfig(false);
        alertMessage({ body: e.response?.data?.message ?? e.message, type: 'error' });
      }
    }
  };

  const copyTextToClipboard = (text: any) => {
    navigator.clipboard.writeText(text);
    setCopiedTextToClipboard(true);
    setTimeout(() => {
      setCopiedTextToClipboard(false);
    }, 1500);
  };

  const getSchemaType = (config: any): string => {
    return schema?.types?.find((t: any) => t.id === config.schemaTypeId)?.name || 'None';
  };

  const getSchemaStage = (config: any) => {
    const stage = pipeline?.stages?.find((s: any) => s.id === config.stageId);
    if (stage) {
      return (
        <Tag
          style={{
            borderRadius: 4,
          }}
          key={stage?.name}
          minimal
          intent="success"
          fill={false}
        >
          {stage?.key}
        </Tag>
      );
    } else {
      return 'None';
    }
  };

  return (
    <>
      <ViewConfigDefinitionEditor
        schema={schema}
        config={editedConfig}
        onClose={() => setEditedConfig(undefined)}
        onUpdate={onConfigUpdate}
      />
      <Row style={{ padding: 15 }}>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h3 style={{ marginTop: 0, marginBottom: 5, lineBreak: 'anywhere' }}>
                {config?.name || 'No Config name'}
              </h3>
              <span>{config.description || 'No Description'}</span>
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ marginTop: 20, marginBottom: 0 }}>
          <Row gutter={8}>
            <Col span={12}>
              <Button
                icon="trash"
                intent="danger"
                text="Delete"
                fill
                onClick={() => setIsDeleteConfigAlertVisible(true)}
              />
            </Col>
            <Col span={12}>
              <ViewConfigEditCreateForm mode="EDIT" onUpdate={onConfigUpdate} config={config} />
            </Col>
            <Col span={24} style={{ marginTop: 10 }}>
              <Button
                disabled={!!editedConfig}
                fill
                text="Definition Editor"
                onClick={() => {
                  setEditedConfig(config);
                }}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Company ID */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Config ID"
            rightElement={
              <Button
                minimal
                small
                rightIcon={copiedTextToClipboard ? 'tick' : null}
                text={copiedTextToClipboard ? 'Copied' : 'Copy'}
                intent={copiedTextToClipboard ? 'success' : 'primary'}
                onClick={() => copyTextToClipboard(config?.id)}
              />
            }
          >
            <span>{config?.id}</span>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Config Details */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Details"
            rightElement={
              <Button
                minimal
                small
                text={isShowingSchemaActionDetails ? 'Hide' : 'Show'}
                intent="primary"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setIsShowingSchemaActionDetails(!isShowingSchemaActionDetails);
                }}
              />
            }
          >
            <Row>
              <Col span={24}>
                <Collapse isOpen={isShowingSchemaActionDetails}>
                  <Row>
                    {/* Stage */}
                    <Col span={24} style={{ marginTop: 5 }}>
                      <EntityTitle
                        title="Stage"
                        subtitle={<span>{getSchemaStage(config) || 'None'}</span>}
                      />
                    </Col>

                    {/* Updated At */}
                    <Col span={12} style={{ marginTop: 15 }}>
                      <EntityTitle
                        title="Updated At"
                        subtitle={
                          <span>
                            {dayjs(config?.updatedAt).format('DD/MM/YYYY HH:mm') || 'None'}
                          </span>
                        }
                      />
                    </Col>

                    {/* Created At */}
                    <Col span={12} style={{ marginTop: 15 }}>
                      <EntityTitle
                        title="Created At"
                        subtitle={
                          <span>
                            {dayjs(config?.createdAt).format('DD/MM/YYYY HH:mm') || 'None'}
                          </span>
                        }
                      />
                    </Col>
                  </Row>
                </Collapse>
              </Col>
            </Row>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Schema Details */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Schema Details"
            rightElement={
              <Button
                minimal
                small
                text={isShowingSchemaDetails ? 'Hide' : 'Show'}
                intent="primary"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setIsShowingSchemaDetails(!isShowingSchemaDetails);
                }}
              />
            }
          >
            <Row>
              <Col span={24}>
                <Collapse isOpen={isShowingSchemaDetails}>
                  <Row>
                    {/* Entity Name */}
                    <Col span={24} style={{ marginTop: 5 }}>
                      <EntityTitle
                        loading={isLoadingSchema}
                        title="Entity Name"
                        subtitle={
                          <Link
                            to={`/ControlPanelModule/SchemaManager/${schema?.id}`}
                            target="_blank"
                          >
                            {schema?.entityName || '...'}
                          </Link>
                        }
                      />
                    </Col>

                    {/* Module Name */}
                    <Col span={24} style={{ marginTop: 15 }}>
                      <EntityTitle
                        loading={isLoadingSchema}
                        title="Module Name"
                        subtitle={<span>{schema?.moduleName || '...'}</span>}
                      />
                    </Col>

                    {/* Schema Type */}
                    <Col span={24} style={{ marginTop: 15 }}>
                      <EntityTitle
                        loading={isLoadingSchema}
                        title="Schema Type"
                        subtitle={<span>{getSchemaType(config)}</span>}
                      />
                    </Col>
                  </Row>
                </Collapse>
              </Col>
            </Row>
          </Section>
        </Col>
      </Row>
      {/* Delete Config Alert */}
      <Alert
        loading={isDeletingConfig}
        intent="danger"
        canEscapeKeyCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        isOpen={isDeleteConfigAlertVisible}
        onCancel={() => setIsDeleteConfigAlertVisible(false)}
        onClose={() => setIsDeleteConfigAlertVisible(false)}
        onConfirm={deleteConfig}
      >
        <p>Are you sure you want to delete this View Config? This action cannot be undone.</p>
      </Alert>

      {/* Update Form */}
      <CoreForm
        type="MODAL"
        formUUID={config?.id}
        onSubmitEvent={(params: { event: string; results: DbRecordEntityTransform }) =>
          onUpdate(params.results)
        }
      />
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(ConfigListViewDetails);
