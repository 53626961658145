import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Descriptions } from 'antd';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import './styles.scss';
import RecordProperties from '@core/components/RecordProperties';

interface OwnProps {
  feature: DbRecordEntityTransform;
}

type Props = OwnProps;

const RenderOdinFeature: FunctionComponent<Props> = (props) => {
  const { feature } = props;

  return (
    <div style={{ width: '100%' }}>
      <RecordProperties record={feature} columns={1} size="smaller" />

      <Descriptions
        size="small"
        bordered={true}
        layout="horizontal"
        className="featureDescriptions"
        labelStyle={{ width: '50%', fontWeight: 500 }}
      >
        {/* Updated By */}
        <Descriptions.Item span={3} label="Updated By">
          {feature.lastModifiedBy?.fullName ? feature.lastModifiedBy?.fullName : '-'}
        </Descriptions.Item>

        {/* Updated At */}
        <Descriptions.Item span={3} label="Updated At">
          {feature.updatedAt ? moment(feature.updatedAt).format('DD/MM/YYYY') : '-'}
          &nbsp;
          {feature.updatedAt ? moment(feature.updatedAt).format('hh:mm A') : '-'}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Source">
          ODIN
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default RenderOdinFeature;
