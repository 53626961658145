import { httpPost } from '@core/http/requests';
import { CaseChannel } from '@d19n/sandbox-odin-sdk/dist/entities-v2/Case';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import CardBase from './CardBase/CardBase';

const filters = [
  {
    columnName: 'Channel',
    operator: 'not in',
    value: [CaseChannel.WEB_CHAT, CaseChannel.CALL],
  },
  {
    columnName: 'StageName',
    operator: 'in',
    value: ['Open', 'Pending Agent', 'Pending Review'],
  },
];
type Props = {
  refreshBit: 0 | 1;
};
const OutOfSlaCasesCard = (props: Props) => {
  const [outOfSlaCount, setOutOfSlaCount] = useState<number>(0);
  const [outOfSlaPercent, setOutOfSlaPercent] = useState<number>(0);

  const calculateOutOfSlaMetrics = async () => {
    const openPromise = httpPost(`SupportModule/v2.0/records/count`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [...filters],
      },
    });
    const outOfSlaPromise = httpPost(`SupportModule/v2.0/records/count`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          ...filters,
          {
            columnName: 'SlaEndDate',
            operator: 'lte',
            value: dayjs().format('YYYY-MM-DD'),
          },
        ],
      },
    });

    const [openResults, outOfSlaResults] = await Promise.all([openPromise, outOfSlaPromise]);
    const openCount = openResults.data.data?.totalRecords ?? 0;
    const outOfSlaCount = outOfSlaResults.data.data?.totalRecords ?? 0;
    setOutOfSlaCount(outOfSlaCount);
    const outOfSlaPercent = (outOfSlaCount / openCount) * 100;
    setOutOfSlaPercent(+outOfSlaPercent.toPrecision(1));
  };

  const getCardColor = () => {
    switch (true) {
      case outOfSlaPercent <= 5:
        return '#0089494D';
      case outOfSlaPercent > 5 && outOfSlaPercent <= 15:
        return '#F37F104D';
      case outOfSlaPercent > 15:
        return '#EC10174D';
    }
  };

  useEffect(() => {
    calculateOutOfSlaMetrics();
  }, [props.refreshBit]);

  return (
    <CardBase
      backgroundColor={getCardColor()}
      title="Out of SLA"
      subTitle={
        <>
          {outOfSlaCount.toLocaleString('en-US')}{' '}
          <span style={{ fontSize: 20 }}>({outOfSlaPercent}%)</span>
        </>
      }
    />
  );
};

export default OutOfSlaCasesCard;
