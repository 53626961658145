import { httpPost } from '@core/http/requests';
import { CaseChannel } from '@d19n/sandbox-odin-sdk/dist/entities-v2/Case';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import CardBase from './CardBase/CardBase';

type Props = {
  refreshBit: 0 | 1;
};
const MaxWaitTimeCard = (props: Props) => {
  const [maxWaitTime, setMaxWaitTime] = useState<number>(0);

  const getMaxWaitTime = async () => {
    const result = await httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Channel',
            operator: 'eq',
            value: CaseChannel.WEB_CHAT,
          },
          {
            columnName: 'OwnerId',
            operator: 'isNull',
            value: '',
          },
          {
            columnName: 'StageName',
            operator: 'eq',
            value: 'Open',
          },
          {
            columnName: 'ConversationStatus',
            operator: 'eq',
            value: 'Created',
          },
        ],
        sort: {
          createdAt: {
            order: 'asc',
          },
        },
        returnProperties: [],
        pageSize: 1,
      },
    });
    const record = result.data.data.records[0];
    if (record) {
      const waitTime = dayjs().diff(dayjs(record.createdAt), 'minute');
      setMaxWaitTime(waitTime);
    } else {
      setMaxWaitTime(0);
    }
  };

  const getCardColor = () => {
    switch (true) {
      case maxWaitTime <= 5:
        return '#0089494D';
      case maxWaitTime > 5 && maxWaitTime < 10:
        return '#F37F104D';
      case maxWaitTime > 10:
        return '#EC10174D';
    }
  };

  useEffect(() => {
    getMaxWaitTime();
  }, [props.refreshBit]);

  return (
    <CardBase
      backgroundColor={getCardColor()}
      title="Max Wait Time"
      subTitle={
        <>
          {maxWaitTime.toLocaleString('en-US')} <span style={{ fontSize: 20 }}> mins</span>
        </>
      }
    />
  );
};

export default MaxWaitTimeCard;
