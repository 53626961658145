import { Icon } from '@blueprintjs/core';
import LookupCreateRecordDrawer from '@core/components/LookupInputFormField/LookupCreateRecordDrawer';
import { lookupSearchById, lookupSearchByTerm } from '@core/components/LookupInputFormField/api';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import { RelationTypeEnum } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/types/db.record.association.constants';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaAssociationSchemaTypesConstraintEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/constraint/schema.association.schema.types.constraint.entity';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { IRecordAssociationsReducer } from '@redux/stores/recordAssociations/reducer';
import { getRecordByIdRequest, IGetRecordById } from '@redux/stores/records/actions';
import { Select } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';

const { Option } = Select;
const { CRM_MODULE } = SchemaModuleTypeEnums;
const { CONTACT } = SchemaModuleEntityTypeEnums;

interface Props {
  autoFocus?: boolean;
  clearAfterSelect?: boolean;
  disabled?: boolean;
  getRecordById: (params: IGetRecordById, cb: any) => void;
  initialValue?: string;
  onBlur?: any;
  onChange?: (value: string | DbRecordEntityTransform) => void;
  onKeyDown?: any;
  parentRecord?: DbRecordEntityTransform;
  processId?: string;
  recordAssociationReducer: IRecordAssociationsReducer;
  responseType: 'ID' | 'RECORD';
  schemaAssociation: SchemaAssociationEntity;
  schemaId: string;
  schemaTypesConstraint?: SchemaAssociationSchemaTypesConstraintEntity;
  // Custom filter when doing a lookup from a side record. For an example, searching for
  // users in a team in the form.
  customFilterEntity?: string;
  customFilterRecordId?: string;
}

const LookupInputFormField: FC<Props> = (props: Props) => {
  const {
    autoFocus,
    clearAfterSelect,
    customFilterEntity,
    customFilterRecordId,
    disabled,
    getRecordById,
    initialValue,
    onBlur,
    onChange,
    onKeyDown,
    parentRecord,
    processId,
    recordAssociationReducer,
    responseType,
    schemaAssociation,
    schemaId,
    schemaTypesConstraint,
  } = props;
  const [inputValue, setInputValue] = useState<any>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [data, setData] = useState<DbRecordEntityTransform[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const [customFilterSchema, setCustomFilterSchema] = useState<SchemaEntity | undefined>(undefined);

  // On component mount, if initialValue is passed, search for it and set it as value in state.
  // Else, search for everything.
  useEffect(() => {
    if (initialValue) {
      setInputValue(initialValue);
      searchForInitialValue(initialValue);
    } else {
      setSearchValue('*');
    }
  }, []);

  // On every searchValue update, search for it
  useEffect(() => {
    if (searchValue && !customFilterEntity) {
      handleSearch(searchValue);
    } else if (searchValue && customFilterEntity && customFilterSchema) handleSearch(searchValue);
  }, [searchValue, customFilterSchema]);

  const getCustomFilterSchema = async () => {
    const moduleName = customFilterEntity?.split(':')[0];
    const entityName = customFilterEntity?.split(':')[1];

    if (moduleName && entityName) {
      const schema = await getOdinSchemaByEntity(moduleName, entityName);
      setCustomFilterSchema(schema);
    }
  };

  // Reset search on custom filter update
  useEffect(() => {
    if (customFilterRecordId) {
      handleSearch('*');
    }
  }, [customFilterRecordId]);
  // Fetch custom filter schema
  useEffect(() => {
    if (customFilterEntity && !customFilterSchema) {
      getCustomFilterSchema();
    }
  }, [customFilterEntity]);

  const getRelatedSchema = () => {
    return schemaAssociation.parentSchemaId === schemaId
      ? schemaAssociation.childSchema
      : schemaAssociation.parentSchema;
  };
  const getRelatedType = () => {
    return schemaAssociation.parentSchemaId === schemaId
      ? RelationTypeEnum.CHILD
      : RelationTypeEnum.PARENT;
  };

  const handleChange = (value: string) => {
    setInputValue(value);

    // Id
    let response: DbRecordEntityTransform | string = value;

    // Record
    const record = data.find((record) => record.id === value);
    if (record && responseType === 'RECORD') {
      response = record;
    }

    if (onChange) onChange(response);

    if (clearAfterSelect) {
      // Clear input field after 200ms
      setTimeout(() => {
        handleClear();
      }, 200);
    }
  };

  const handleClear = () => {
    setInputValue('');
    setData([]);
    if (searchValue === '*') {
      handleSearch('*');
    } else {
      setSearchValue('*');
    }
    if (onChange) onChange('');
  };

  const resetSearchButKeepSelectedValue = () => {
    if (searchValue !== '*' && inputValue) {
      setSearchValue('*');
    }
  };

  // This is searching for initialValue and setting the value, happens when there is a initialValue and component is freshly mounted
  const searchForInitialValue = (initialValue: string) => {
    const relatedSchema = getRelatedSchema();
    const relatedType = getRelatedType();
    setIsSearching(true);
    // Configure query to search for initial value (id)
    let boolean: any = {
      must: [
        {
          match: {
            id: initialValue,
          },
        },
      ],
    };

    // Add schema types filter if needed
    if (schemaTypesConstraint) {
      const type =
        relatedType === RelationTypeEnum.CHILD
          ? schemaTypesConstraint.childSchemaType?.name
          : schemaTypesConstraint.parentSchemaType?.name;
      if (type) {
        boolean.must.push({
          match: {
            type: type,
          },
        });
      }
    }

    lookupSearchById(relatedSchema!, parentRecord?.id, initialValue).then((res: any) => {
      setData(res);
      setIsSearching(false);
    });
  };

  // Search associations for text
  const handleSearch = (text: string) => {
    setIsSearching(true);
    const relatedSchema = getRelatedSchema();
    const relatedType = getRelatedType();

    // Add type to query if needed
    let boolean: any;
    if (schemaTypesConstraint) {
      const type =
        relatedType === RelationTypeEnum.CHILD
          ? schemaTypesConstraint.childSchemaType?.name
          : schemaTypesConstraint.parentSchemaType?.name;

      if (type) {
        boolean = {
          must: [
            {
              match: {
                type: type,
              },
            },
          ],
        };
      } else {
        boolean = {};
      }
    }

    const relatedSchemaAssociation = {
      findInSchema: relatedSchema?.id || '',
    };

    const schemas = () => {
      // Searching by custom schema set in schema action. Like, searching for a user that belongs to a certain team.
      if (customFilterSchema) {
        return customFilterSchema.id;
      }
      // Else, try the association schema
      else if (schemaAssociation?.findInChildSchema || schemaAssociation.findInChildSchema) {
        return props.schemaId;
      }
      // Else, it's the
      else {
        return relatedSchema!.id;
      }
    };

    lookupSearchByTerm({
      recordId: customFilterRecordId || parentRecord?.id || undefined,
      processId: processId,
      schema: customFilterSchema ? customFilterSchema : relatedSchema!,
      schemaAssociation: customFilterRecordId
        ? (relatedSchemaAssociation as SchemaAssociationEntity)
        : schemaAssociation,
      searchQuery: {
        terms: text,
        boolean,
        schemas:
          // Only when there are cross lookups i.e findInSchema or findInChildSchema should the schemas=Source Record Schema ID otherwise it should be the schemaId of the entity being searched.
          schemas(),
        pageable: {
          page: 1,
          size: 100,
        },
        sort: recordAssociationReducer.searchQuery.sort,
      },
    }).then((res: any) => {
      setData(res);
      setIsSearching(false);
    });
  };

  const renderSelectOptions = () => {
    if (isSearching) {
      return (
        <Option key="loading" value="Loading" disabled>
          Loading...
        </Option>
      );
    } else {
      return data.map((option: any) => (
        <Option key={option.id} value={option.id} label={generateOptionLabel(option)}>
          {generateOptionLabel(option)}
        </Option>
      ));
    }
  };

  // When create drawer passes back the id of the created record,
  // fetch the record and put it into the data array, select the
  // record immediately.
  const onDrawerSuccess = (id: string | number) => {
    getRecordById({ recordId: String(id), schema: schemaAssociation?.childSchema }, (res: any) => {
      setData([...data, res]);
      handleChange(String(id));
    });

    setIsDrawerOpen(false);
  };

  // Reset value when drawer is closed, but only if there was no initial value
  const onDrawerClose = (id?: string) => {
    setIsDrawerOpen(false);
    if (!inputValue) {
      setInputValue([]);
    }
  };

  const generateOptionLabel = (option: DbRecordEntityTransform) => {
    // For Contacts, show email address as well
    if (option.entity === `${CRM_MODULE}:${CONTACT}`) {
      return (
        <div>
          <span>{option.title}</span>{' '}
          <span style={{ opacity: 0.4, paddingLeft: 3 }}>
            {getProperty(option, 'EmailAddress')}
          </span>
        </div>
      );
    }
    // If recordNumber is present, show it as well
    else if (option.recordNumber) {
      return `${option.recordNumber} - ${option.title}`;
    } else {
      return option.title;
    }
  };

  return (
    <>
      <Select
        key={schemaAssociation.id}
        allowClear={true}
        autoFocus={autoFocus}
        defaultActiveFirstOption={false}
        filterOption={false}
        style={{ width: '100%' }}
        loading={isSearching}
        notFoundContent={null}
        onBlur={onBlur && onBlur}
        onChange={(e: any) => {
          if (e === 'search') {
            setIsDrawerOpen(true);
          } else {
            handleChange(e);
          }
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
          onKeyDown && onKeyDown(e);
        }}
        onSearch={(e: string) => setSearchValue(e)}
        onClear={handleClear}
        placeholder="Type to search"
        showSearch
        value={inputValue || undefined}
        disabled={customFilterRecordId === null && !initialValue ? true : disabled}
        onDropdownVisibleChange={(open: boolean) => {
          resetSearchButKeepSelectedValue();
        }}
      >
        {/* Show Create option only when parent action allows so */}
        {(schemaAssociation.parentActions === 'LOOKUP_AND_CREATE' ||
          schemaAssociation.parentActions === 'CREATE_ONLY') && (
          <Select.Option key="search" value="search">
            <Icon
              icon="plus"
              size={12}
              color="#2C72D2"
              style={{ verticalAlign: 'middle', marginBottom: 2, marginRight: 8 }}
            />
            <span style={{ color: '#2C72D2' }}>
              Create New {schemaAssociation?.childSchema?.entityName}
            </span>
          </Select.Option>
        )}
        {renderSelectOptions()}
      </Select>

      {/* Create Record Drawer */}
      <LookupCreateRecordDrawer
        isOpen={isDrawerOpen}
        onDrawerClose={onDrawerClose}
        schema={schemaAssociation?.childSchema}
        sourceRecord={parentRecord!}
        onSuccess={onDrawerSuccess}
      />
    </>
  );
};

const mapState = (state: any) => ({
  recordAssociationReducer: state.recordAssociationReducer,
});

const mapDispatch = (dispatch: any) => ({
  getRecordById: (params: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(LookupInputFormField);
