import { Avatar, Col, Row } from 'antd';
import React, { useContext } from 'react';
import { ProgressBar, Tooltip } from '@blueprintjs/core';
import YouFibreRoundLogo from '@youfibre/assets/youfibre-round-color-500.png';
import NetomniaRoundLogo from '@netomnia/assets/netomnia-round-white-500.png';
import '../styles.scss';
import { HourlyViewContext } from '../index';
import { getInitialsFromName } from '@core/helpers/userInterfaceHelpers';
import { SET_ASSIGN_MODAL_VISIBLE, SET_ASSIGNING_TO_ENGINEER_ID } from '../store/constants';
import { WorkOrderBundle } from '../types';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

interface Props {
  engineer: any;
  percentageCompleted: number;
  hideTopBorder?: boolean;
  hideBottomBorder?: boolean;
  fullWidth?: boolean;
  shownInModal?: boolean;
}

const HourlyViewEngineerCard: React.FC<Props> = (props: Props) => {
  const {
    engineer,
    shownInModal,
    percentageCompleted,
    hideTopBorder,
    fullWidth,
    hideBottomBorder,
  } = props;
  const { state, dispatch } = useContext(HourlyViewContext);

  const getProgressBarIntent = () => {
    if (percentageCompleted > 50) {
      return 'success';
    } else if (percentageCompleted > 30 && percentageCompleted <= 50) {
      return 'warning';
    } else {
      return 'danger';
    }
  };

  const isYouFibreEngineer = (engineer: any) => {
    return engineer.properties?.EmailAddress?.indexOf('youfibre') > -1;
  };

  const isNetomniaEngineer = (engineer: any) => {
    return engineer.properties?.EmailAddress?.indexOf('netomnia') > -1;
  };

  const getEngineerAvatarClassName = (engineer: any) => {
    if (isYouFibreEngineer(engineer)) {
      return 'youfibre';
    } else if (isNetomniaEngineer(engineer)) {
      return 'netomnia';
    } else {
      return '';
    }
  };

  const getAvatarContents = (engineer: any) => {
    if (isYouFibreEngineer(engineer)) {
      return (
        <Tooltip content="YouFibre" hoverOpenDelay={1000} key={engineer.id}>
          <img
            alt={'YouFibre' + engineer?.id}
            key={engineer.id}
            src={YouFibreRoundLogo}
            style={{ width: 18, paddingTop: 6, paddingLeft: 1 }}
          />
        </Tooltip>
      );
    } else if (isNetomniaEngineer(engineer)) {
      return (
        <Tooltip content="Netomnia" hoverOpenDelay={1000} key={engineer.id}>
          <img
            alt="Netomnia"
            key={engineer.id}
            src={NetomniaRoundLogo}
            style={{ width: 16, paddingTop: 7 }}
          />
        </Tooltip>
      );
    } else {
      return getInitialsFromName(engineer.title);
    }
  };

  const setAssignModalWithUnassignedWOs = (engineerId: string) => {
    if (
      !shownInModal &&
      state.workOrdersToAssign.length > 0 &&
      canUserAssignWorkOrdersToThisEngineer()
    ) {
      dispatch({ type: SET_ASSIGNING_TO_ENGINEER_ID, payload: engineerId });
      dispatch({ type: SET_ASSIGN_MODAL_VISIBLE, payload: true });
    }
  };

  const canUserAssignWorkOrdersToThisEngineer = () => {
    const WOBundle = state.allWorkOrders.find(
      (woBundle: WorkOrderBundle) => woBundle.engineer?.id === engineer.id,
    );

    if (WOBundle) {
      const idsToBeAssigned = state.workOrdersToAssign.map((wo: DbRecordEntityTransform) => wo.id);
      return !WOBundle.workOrders.some((wo: DbRecordEntityTransform) =>
        idsToBeAssigned.includes(wo.id),
      );
    } else {
      return true;
    }
  };

  return (
    <Col
      onClick={() => setAssignModalWithUnassignedWOs(engineer.id)}
      className={`hourlyViewEngineerCard ${
        (state.workOrdersToAssign.length > 0 && !shownInModal) ||
        (state.isAssigningWorkOrders && !shownInModal)
          ? 'assignable'
          : ''
      }`}
      key={'HourlyViewEngineerCardCol1' + engineer.id}
      span={fullWidth ? 24 : 4}
      style={{
        borderLeft: 0,
        borderRight: 0,
        borderTop: hideTopBorder ? 'none' : '1px solid #DCDCDD',
        borderBottom: hideBottomBorder ? 'none' : '1px solid #DCDCDD',
        cursor:
          state.workOrdersToAssign.length > 0 &&
          !shownInModal &&
          canUserAssignWorkOrdersToThisEngineer()
            ? 'copy'
            : 'default',
        marginTop: '-1px',
      }}
    >
      <Row
        key={'HourlyViewEngineerCardRow1' + engineer.id}
        align="middle"
        style={{ height: '100%', padding: '0 10px' }}
      >
        <Col span={5} key={'HourlyViewEngineerCardCol2' + engineer.id}>
          {/* Engineer avatar */}
          <Avatar
            key={'HourlyViewEngineerCardAvatar' + engineer.id}
            className={`hourlyViewEngineerCardAvatar ${getEngineerAvatarClassName(engineer)}`}
            size={32}
            style={{ fontSize: 14 }}
          >
            {getAvatarContents(engineer)}
          </Avatar>
        </Col>
        <Col span={19} key={'HourlyViewEngineerCardCol3' + engineer.id}>
          <Row key={'HourlyViewEngineerCardRow2' + engineer.id} align="middle">
            {/* Engineer name */}
            <Col span={24} key={'HourlyViewEngineerCardCol4' + engineer.id}>
              <span
                key={'HourlyViewEngineerCardSpan1' + engineer.id}
                style={{
                  fontSize: 13,
                  fontWeight: 600,
                  verticalAlign: 'middle',
                  lineHeight: '12px',
                }}
              >
                {engineer.title}
              </span>
            </Col>
            {/* Engineer Action Drop Menu */}
            {/* <Col span={4} style={{ textAlign: 'right' }}>
              <Popover
                position="bottom"
                interactionKind="click"
                content={
                  <Menu>
                    <MenuItem
                      text="Assign Work Orders"
                      onClick={() => setEngineerToReceiveWorkOrders(engineer.id)}
                    />
                  </Menu>
                }
              >
                <Button small minimal icon="more" />
              </Popover>
            </Col> */}
            {/* Engineer data */}
            <Col span={24} key={'HourlyViewEngineerCardCol5' + engineer.id}>
              <Row align="middle">
                <Col span={19}>
                  <Tooltip
                    fill
                    content="Percent of successfully completed Work Orders"
                    hoverOpenDelay={800}
                    disabled={state.workOrdersToAssign.length > 0}
                  >
                    <ProgressBar
                      intent={getProgressBarIntent()}
                      value={percentageCompleted ? percentageCompleted / 100 : 0}
                      animate={false}
                      stripes={false}
                    />
                  </Tooltip>
                </Col>
                <Col span={5} style={{ textAlign: 'right' }}>
                  <span style={{ fontSize: 10, verticalAlign: 'middle', lineHeight: '3px' }}>
                    {percentageCompleted?.toFixed(0) || 0}%
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
export default HourlyViewEngineerCard;
