import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Row } from 'antd';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

import { Button, CompoundTag, Tag } from '@blueprintjs/core';
import { isSystemAdmin } from '@core/helpers/rbacRules';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setRawDataDrawerContents, toggleRawDataDrawer } from '@redux/stores/userInterface/actions';
import ActivityBody from '../ActivityBody';
import {
  checkIfActivityIsAnEmail,
  getActivityEntity,
  getAssociatedRecordForAnActivity,
  getChildRecordInformation,
  getParentRecordInformation,
  isActivityAnAssociation,
  isActivityNewGroupUpdate,
  isActivityRecordTitleUpdate,
  isActivityStageUpdate,
} from '../helpers';
import { eventTypesFmtENUM } from '../types';
import './styles.scss';

interface Props {
  activityItem: DbRecordEntityTransform;
  recordEntityName: string | undefined;
  toggleActivityPanel: Function;
  setRawDataDrawer: Function;
  toggleDrawer: Function;
  compact?: boolean;
  userReducer: any;
}

const ActivityItem: React.FC<Props> = (props: Props) => {
  const { setRawDataDrawer, toggleDrawer, compact, userReducer } = props;
  const [associatedRecord, setAssociatedRecord] = useState<any>(undefined);

  // On component mount, get an associated record
  useEffect(() => {
    if (isActivityAnAssociation(activityItem.type)) {
      setAssociatedRecord(getAssociatedRecordForAnActivity(activityItem));
    }
  }, []);

  const { activityItem } = props;

  const renderActivityTitle = (activityItem: DbRecordEntityTransform) => {
    let activityEntity = getActivityEntity(activityItem);

    // Check if activity is association
    if (activityItem.type && activityItem?.type?.indexOf('ASSOCIATION_CREATED') > -1) {
      if (activityEntity === 'File') {
        if (checkIfActivityIsAnEmail(activityItem)) {
          return <span>sent an Email</span>;
        } else {
          return <span>uploaded a File</span>;
        }
      } else if (activityEntity === 'Note') {
        return <span>created a Note</span>;
      } else {
        const parentRecordInformation = getParentRecordInformation(activityItem);
        const childRecordInformation = getChildRecordInformation(activityItem);

        const { parentEntity, parentRecordNumber, parentRecordUrl, parentRecordTitle } =
          parentRecordInformation;

        const { childEntity, childRecordNumber, childRecordUrl, childRecordTitle } =
          childRecordInformation;

        return (
          <div className={compact ? 'compactSize' : ''} style={{ display: 'contents' }}>
            {/* Action */}
            <span
              style={{ color: activityItem.type.indexOf('DELETED') > -1 ? '#d02d2d' : 'black' }}
            >
              {eventTypesFmtENUM[activityItem.type as keyof typeof eventTypesFmtENUM]}{' '}
            </span>

            {/* Parent Record */}
            {/* <ModuleEntityIcon moduleName={parentModule} entityName={parentEntity} compact /> */}

            {(parentRecordUrl && parentRecordNumber) || (parentRecordUrl && parentRecordTitle) ? (
              <Link to={parentRecordUrl} target="_blank">
                <span>{parentRecordNumber ? `(${parentRecordNumber}) ` : ''}</span>
                <span>{parentRecordTitle}</span>
              </Link>
            ) : (
              <span
                style={{
                  padding: '2px 4px',
                  border: '1px solid #bababa',
                  borderRadius: 4,
                  color: '#363636',
                }}
                className={compact ? 'compactSize' : ''}
              >
                Missing {parentEntity} record
              </span>
            )}

            {/* Arrow */}
            <span style={{ margin: '5px 5px 0px 5px' }}>→</span>

            {/* Child Record */}

            {/* <ModuleEntityIcon moduleName={childModule} entityName={childEntity} compact /> */}
            <Link to={childRecordUrl} target="_blank">
              {['Activity'].includes(childEntity) ? (
                <>
                  <span>Activity</span>
                </>
              ) : (
                <>
                  <span>{childRecordNumber ? `(${childRecordNumber}) ` : ''}</span>
                  <span>{childRecordTitle}</span>
                </>
              )}
            </Link>
          </div>
        );
      }
    }
    // Title update
    else if (isActivityRecordTitleUpdate(activityItem)) {
      return (
        <span className={compact ? 'compactSize' : ''}>
          updated record title to "{activityItem.revision?.title}"
        </span>
      );
    }
    // Stage update
    else if (isActivityStageUpdate(activityItem)) {
      const stageId = activityItem.revision?.stageId;
      const stage = activityItem.associations?.find(
        (association: any) => association.id === stageId,
      );
      return (
        <span className={compact ? 'compactSize' : ''}>
          updated record stage to "{stage?.name}"
        </span>
      );
    }

    // NEW Group update
    else if (isActivityNewGroupUpdate(activityItem)) {
      return (
        <span className={compact ? 'compactSize' : ''}>
          {`updated record group from ${
            activityItem?.revision?.previousGroups?.join(', ') || 'N/A'
          } to ${activityItem?.revision?.nextGroups?.join(', ')}`}
        </span>
      );
    }
    // All the rest...
    else {
      return (
        <span className={compact ? 'compactSize' : ''}>
          {eventTypesFmtENUM[activityItem.type as keyof typeof eventTypesFmtENUM]}
        </span>
      );
    }
  };

  const toggleActivityPanel = (id: string) => {
    props.toggleActivityPanel(id);
  };

  const hasSchemaAction = (activityItem: DbRecordEntityTransform) => {
    return activityItem?.schemaActionId || activityItem?.actionName;
  };

  const renderSchemaActionName = (activityItem: DbRecordEntityTransform) => {
    const actionName = activityItem.actionName;
    const actionId = activityItem.schemaActionId;

    if (actionName) {
      if (actionId) {
        return (
          <Link
            key={activityItem.id}
            style={{ color: '#fff' }}
            to={`/ControlPanelModule/Forms/ListView#schemaActionId=${actionId}`}
            target="_blank"
          >
            {activityItem.actionName}
            <i
              className="bi bi-box-arrow-in-right"
              key={activityItem.id}
              style={{ marginLeft: 5 }}
            />
          </Link>
        );
      } else {
        return actionName;
      }
    } else if (actionId) {
      return (
        <Link
          key={activityItem.id}
          target="_blank"
          to={`/ControlPanelModule/Forms/ListView#schemaActionId=${actionId}`}
        >
          View
          <i key={activityItem.id} className="bi bi-box-arrow-in-right" style={{ marginLeft: 5 }} />
        </Link>
      );
    } else {
      return '-';
    }
  };

  return (
    <Row style={{ marginBottom: 13 }} align="middle">
      <Col span={24}>
        <div style={{ display: 'inline-block' }} className="ActivityRow">
          {/* Collapse / Expand caret */}
          {activityItem.open ? (
            <i
              key={activityItem.id}
              className="bi bi-dash-square activityItemToggle"
              style={{ marginRight: 7, fontSize: '0.9em', paddingLeft: 1 }}
              onClick={() => toggleActivityPanel(activityItem.id)}
            />
          ) : (
            <i
              key={activityItem.id}
              className="bi bi-plus-square activityItemToggle"
              style={{ marginRight: 7, fontSize: '0.9em', paddingLeft: 1 }}
              onClick={() => toggleActivityPanel(activityItem.id)}
            />
          )}

          {/* Activity Icon */}
          {hasSchemaAction(activityItem) && (
            <Tag
              intent="primary"
              style={{ fontSize: '0.8em', minHeight: 'auto', padding: '0 5px', marginRight: 4 }}
              onClick={() => toggleActivityPanel(activityItem.id)}
            >
              Action
            </Tag>
          )}

          {/* User name */}
          <span
            style={{ fontWeight: 500 }}
            className={`activityUserName ${compact ? 'compactSize' : ''}`}
            onClick={() => toggleActivityPanel(activityItem.id)}
          >
            {activityItem.userName}&nbsp;
          </span>

          {/* Activity title */}
          <span onClick={() => toggleActivityPanel(activityItem.id)} style={{ cursor: 'pointer' }}>
            {renderActivityTitle(activityItem)}
          </span>

          {/* Time */}
          <span style={{ marginLeft: 5, color: '#a1a1a1' }}>
            {/*@ts-ignore*/}
            {DateTime.fromISO(activityItem.createdAt).toFormat('d/M/yyyy h:mm a ZZZZ')}
          </span>
        </div>
      </Col>

      {/* Body */}
      <Col span={24}>
        {activityItem.open ? (
          <div
            style={{
              padding: 10,
              marginRight: 10,
              borderLeft: '2px dotted #d1d1d1',
              marginLeft: 5,
            }}
            key={activityItem.id}
          >
            <ActivityBody activityItem={activityItem} compact={compact} />
            {isSystemAdmin(userReducer) ? (
              <Row
                align="middle"
                style={{ marginTop: 15, padding: '0 15px' }}
                justify="space-between"
              >
                {/* Schema action Name */}
                {hasSchemaAction(activityItem) ? (
                  <Col style={{}}>
                    <CompoundTag intent="primary" leftContent="Action">
                      {renderSchemaActionName(activityItem)}
                    </CompoundTag>
                  </Col>
                ) : (
                  <Col />
                )}

                {/* View Raw Data Button */}
                <Col style={{ textAlign: 'left' }}>
                  <Button
                    small
                    onClick={() => {
                      setRawDataDrawer(activityItem);
                      toggleDrawer();
                    }}
                  >
                    View Raw data
                  </Button>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any, ownProps: any) => ({
  setRawDataDrawer: (params: string) => dispatch(setRawDataDrawerContents(params)),
  toggleDrawer: () => dispatch(toggleRawDataDrawer()),
});

export default connect(mapState, mapDispatch)(ActivityItem);
