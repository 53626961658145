import { all } from 'redux-saga/effects';
import userLoginSaga from './stores/identity/sagas';
import schemaSaga from './stores/schemas/sagas';
import dbRecordSaga from './stores/records/sagas';
import dbRecordAssociationsSaga from './stores/recordAssociations/sagas';
import auditLogsSaga from './stores/recordAudit/sagas';
import pipelineSaga from './stores/pipelines/sagas';
import reportSaga from './stores/reporting/sagas';
import emailNotificationSaga from './stores/email/sagas';
import appointmentSaga from './stores/appointments/sagas';
import schemasColumnSaga from './stores/schemasColumns/sagas';
import schemasAssociationsSaga from './stores/schemasAssociations/sagas';
import identityUserSaga from './stores/identityUser/sagas';
import identityGroups from './stores/identityGroups/sagas';
import userAuditSaga from './stores/userAudit/sagas';
import autosplicingSaga from '@netomnia/modules/ProjectModule/views/Autosplicing/store/sagas';
import mapSaga from '@netomnia/modules/ProjectModule/views/Map/store/sagas';

export default function* rootSaga() {
  yield all([
    userLoginSaga(),
    schemaSaga(),
    schemasColumnSaga(),
    schemasAssociationsSaga(),
    dbRecordSaga(),
    dbRecordAssociationsSaga(),
    pipelineSaga(),
    auditLogsSaga(),
    reportSaga(),
    emailNotificationSaga(),
    appointmentSaga(),
    identityUserSaga(),
    identityGroups(),
    userAuditSaga(),
    autosplicingSaga(),
    mapSaga(),
  ]);
}
