import {
  Alert,
  Button,
  Icon,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
} from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { initailizeCancelAppointmentModal } from '../../../redux/stores/appointments/actions';
import { listUsers } from '../../../redux/stores/identity/actions';
import { displayMessage } from '../../../redux/stores/messages/reducers';
import { getPipelinesByModuleAndEntity } from '../../../redux/stores/pipelines/actions';
import CoreForm, { Props as OdinFormModalProps } from '@core/components/Forms/CoreForm';
import { InputChangeParams } from '@core/components/Forms/FormFields';
import { initializeRecordForm } from '../../../redux/stores/form/actions';
import AssignGroupsToRecords from 'src/core/components/AssignGroupsToRecordsDialog';
import {
  deleteRecordByIdRequest,
  getRecordByIdRequest,
  IDeleteRecordById,
  IGetRecordById,
  IRestoreRecordById,
  restoreRecordByIdRequest,
} from '../../../redux/stores/records/actions';
import {
  CREATE_DB_RECORD_REQUEST,
  UPDATE_DB_RECORD_BY_ID_REQUEST,
} from '../../../redux/stores/records/constants';
import { IRecordReducer } from '../../../redux/stores/records/reducer';
import {
  deleteRecordAssociationById,
  getRecordAssociationByIdRequest,
  getRecordAssociationsRequest,
  IDeleteRecordAssociation,
  IGetRecordAssociationById,
  IGetRecordAssociations,
} from '../../../redux/stores/recordAssociations/actions';
import { DB_RECORD_ASSOCIATIONS_UPDATE_REQUEST } from '../../../redux/stores/recordAssociations/constants';
import { ISchemaReducer } from '../../../redux/stores/schemas/reducer';
import { toggleAssignRecordToGroupModal } from '../../../redux/stores/userInterface/actions';
import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import history from '../../helpers/browserHistory';
import {
  canUserDeleteRecord,
  canUserUpdateRecord,
  hasAnyRoles,
  hasPermissions,
  isSystemAdmin,
} from '../../helpers/rbacRules';
import { checkRecordIsLocked, getBrowserPath } from '../../helpers/recordHelpers';
import { getSchemaFromShortListBySchemaId } from '../../helpers/schemaHelpers';

const { PRODUCT_MODULE, SCHEMA_MODULE } = SchemaModuleTypeEnums;
const { PRODUCT } = SchemaModuleEntityTypeEnums;

type PathParams = {
  url: string;
  recordId: string;
};

type PropsType = RouteComponentProps<PathParams> & {
  record: DbRecordEntityTransform;
  match: any;
  userReducer: any;
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  initializeForm: any;
  deleteRecord: (param: IDeleteRecordById, cb: (res: any) => {}) => {};
  restoreRecord: (param: IRestoreRecordById, cb: (res: any) => {}) => {};
  getRecord: any;
  getRelatedRecordById: any;
  getUsers: any;
  getPipelines: any;
  getAssociations: any;
  hasColumnMappings?: boolean;
  visibleProperties?: string[];
  children?: any;
  style?: any;
  onDelete?: any;
  initializeCancelAppointment: any;
  updateFormDisabledFields?: string[];
  updateFromCustomValidations?: { [key: string]: any };
  updateFormAdditionalInputChangeHandler?: (
    updateFormProps: OdinFormModalProps,
    params: InputChangeParams,
  ) => void;
  deleteRecordAssociation: (payload: IDeleteRecordAssociation, cb: any) => void;
  alertMessage: any;
  entityName?: string;
  onRecalculate?: (record: DbRecordEntityTransform) => void;
  toggleAssignGroupModal: () => void;
  showViewConfiguration?: boolean;
  isQuickView?: boolean;
};

interface State {
  deleteFileModalVisible: boolean;
  restoreFileModalVisible: boolean;
  isDeletingFile: boolean;
  isRestoringFile: boolean;
}

class ActionMenuDetailView extends React.Component<PropsType, State> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      deleteFileModalVisible: false,
      restoreFileModalVisible: false,
      isDeletingFile: false,
      isRestoringFile: false,
    };
  }

  async initializeUpdateForm() {
    const {
      record,
      schemaReducer,
      initializeForm,
      getUsers,
      hasColumnMappings,
      getPipelines,
      visibleProperties,
      updateFormDisabledFields,
      updateFromCustomValidations,
      updateFormAdditionalInputChangeHandler,
      isQuickView,
    } = this.props;

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);

    if (schema) {
      getUsers();
      getPipelines({ schema: schema });

      const relatedEntityName = this.props?.match?.params?.relatedRecordEntityName;

      initializeForm({
        formUUID: `${record.id}${isQuickView ? '-quickView' : ''}`,
        title: hasColumnMappings
          ? `Update Linked Record (${relatedEntityName} → ${schema.entityName})`
          : `Update ${schema.entityName}`,
        hasColumnMappings,
        visibleFieldOverride: visibleProperties,
        disabledFields: updateFormDisabledFields,
        customValidations: updateFromCustomValidations,
        additionalInputChangeHandler: updateFormAdditionalInputChangeHandler,
        showFormModal: true,
        isUpdateReq: true,
        schema: schema,
        selected: record,
        recordType: record.type,
        sections: [{ name: schema.name, schema: schema }],
        relatedEntityName,
      });
    }
  }

  menuItems = () => {
    const {
      userReducer,
      record,
      schemaReducer,
      hasColumnMappings,
      toggleAssignGroupModal,
      showViewConfiguration,
    } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);

    return (
      <Menu>
        <MenuItem
          icon={<Icon icon="edit" />}
          text="Edit"
          key="menuItemEdit"
          disabled={
            !!record?.dbRecordAssociation?.relatedAssociationId ||
            !canUserUpdateRecord(userReducer, schema, record) ||
            checkRecordIsLocked(record)
          }
          onClick={() => this.initializeUpdateForm()}
        />

        {/* Restore */}
        {isSystemAdmin(userReducer) && (
          <MenuItem
            text="Restore"
            icon={<Icon icon="redo" />}
            key="menuItemRestore"
            disabled={!isSystemAdmin(userReducer)}
            onClick={() => this.setState({ restoreFileModalVisible: true })}
          />
        )}

        {/* Full View */}
        {hasColumnMappings && (
          <MenuItem
            key="menuItemFullView"
            text={
              <Link target="_blank" to={getBrowserPath(record)}>
                Full View
              </Link>
            }
          />
        )}

        {
          // render the Re-calculate action
          ['OrderModule:Order', 'BillingModule:Invoice'].includes(String(record?.entity)) &&
          this.props.onRecalculate ? (
            <MenuItem
              text="Recalculate"
              key="menuItemReCalculate"
              disabled={
                !(
                  (record.entity === 'OrderModule:Order' &&
                    hasPermissions(userReducer, ['ordermodule.order.recalculate'])) ||
                  (record.entity === 'BillingModule:Invoice' &&
                    hasPermissions(userReducer, ['billingmodule.invoice.recalculate']))
                ) || !isSystemAdmin(userReducer)
              }
              onClick={() => {
                if (this.props.onRecalculate) {
                  this.props.onRecalculate(record);
                }
              }}
            >
              Recalculate
            </MenuItem>
          ) : (
            <></>
          )
        }

        {/* Manage Groups on Record */}
        <MenuItem
          icon={<Icon icon="people" />}
          key="menuItemViewAssignGroups"
          disabled={!hasPermissions(userReducer, ['groups.assign'])}
          onClick={toggleAssignGroupModal}
          text="Manage Groups"
        />

        {/* Show View Config */}
        {isSystemAdmin(userReducer) && schema && showViewConfiguration && (
          <Link
            to={`/${SCHEMA_MODULE}/Schema/${schema?.id}/#ViewConfigs`}
            target="_blank"
            style={{ color: 'black', textDecoration: 'none' }}
          >
            <MenuItem key="menuItemShowViewConfigs" text="Show View Configurations" />
          </Link>
        )}

        {isSystemAdmin(userReducer) && schema && <MenuDivider />}

        {/* View Schema */}
        {isSystemAdmin(userReducer) && schema && (
          <Link
            to={`/ControlPanelModule/SchemaManager/${schema?.id}`}
            target="_blank"
            style={{ color: 'black', textDecoration: 'none' }}
          >
            <MenuItem key="menuItemShowSchema" text="View Schema" />
          </Link>
        )}

        {/* View Actions */}
        {isSystemAdmin(userReducer) && schema && (
          <Link
            to={`/ControlPanelModule/Forms/ListView#entityName=${schema?.entityName}`}
            target="_blank"
            style={{ color: 'black', textDecoration: 'none' }}
          >
            <MenuItem key="menuItemViewActions" text="View Actions" />
          </Link>
        )}

        <MenuDivider />

        {/* Delete */}
        {record?.entity === 'FieldServiceModule:ServiceAppointment' ? (
          <MenuItem
            text="Delete"
            intent="danger"
            icon={<Icon icon="trash" />}
            key="menuItemDelete1"
            disabled={
              !schema ||
              !canUserDeleteRecord(userReducer, schema, record) ||
              // ODN-1640 prevent deleting if record is locked
              (checkRecordIsLocked(record) &&
                !hasAnyRoles(userReducer, 'system.admin', 'BillingModuleAdmin'))
            }
            onClick={() => this.initializeDeleteAppointmentForm()}
          />
        ) : (
          <MenuItem
            icon={<Icon icon="trash" />}
            intent="danger"
            text="Delete"
            onClick={() => this.setState({ deleteFileModalVisible: true })}
            key="menuItemDelete2"
            disabled={
              !schema ||
              !canUserDeleteRecord(userReducer, schema, record) ||
              checkRecordIsLocked(record)
            }
          />
        )}
      </Menu>
    );
  };

  render() {
    const { record, schemaReducer, hasColumnMappings, style, isQuickView } = this.props;

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);

    return (
      <div style={style}>
        <AssignGroupsToRecords schema={schema} singleRecord={record} />

        {/* Delete File Modal */}
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          loading={this.state.isDeletingFile}
          intent={Intent.DANGER}
          isOpen={this.state.deleteFileModalVisible}
          onCancel={() => this.setState({ deleteFileModalVisible: false })}
          onConfirm={() => this.deleteRecord()}
        >
          <p>Are you sure you want to delete this record? You will be able to restore it later.</p>
        </Alert>

        {/* Restore File Modal */}
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Restore"
          loading={this.state.isRestoringFile}
          intent={Intent.PRIMARY}
          isOpen={this.state.restoreFileModalVisible}
          onCancel={() => this.setState({ restoreFileModalVisible: false })}
          onConfirm={() => this.restoreRecord()}
        >
          <p>Are you sure you want to restore this record?</p>
        </Alert>

        <CoreForm
          type="MODAL"
          formUUID={`${record.id}${isQuickView ? '-quickView' : ''}`}
          hasColumnMappings={hasColumnMappings}
          onSubmitEvent={(params: { event: string; results: any }) => this.handleFormSubmit(params)}
        />
        <Popover content={this.menuItems()} placement="bottom">
          <Button alignText="left" rightIcon="caret-down" />
        </Popover>
      </div>
    );
  }

  private deleteRecord() {
    const { record, schemaReducer, deleteRecord, onDelete, deleteRecordAssociation } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);
    const dbRecordAssociationId = this.props.match.params.dbRecordAssociationId;

    if (schema) {
      this.setState({ isDeletingFile: true });

      // check if the path matches related product
      // if so, don't delete the product but association with related record
      // the record association id is extracted from the URL
      if (
        window.location.pathname.includes(`/${PRODUCT_MODULE}/${PRODUCT}/`) &&
        window.location.pathname.includes(`/related/`) &&
        dbRecordAssociationId
      ) {
        const schemaAssociation = schema?.associations.find(
          (elem) => elem.label === 'PriceBook__Product',
        );
        deleteRecordAssociation(
          {
            schema: schema as SchemaEntity,
            schemaAssociation: schemaAssociation as SchemaAssociationEntity,
            dbRecordAssociationId: dbRecordAssociationId,
          },
          () => {
            this.setState({ deleteFileModalVisible: false, isDeletingFile: false });
            window.history.back();
          },
        );
      } else {
        if (schema && record) {
          deleteRecord(
            {
              schema: schema,
              recordId: record.id,
            },
            () => {
              this.setState({ deleteFileModalVisible: false, isDeletingFile: false });
              if (onDelete) {
                return onDelete();
              } else {
                history.push(`/${schema.moduleName}/${schema.entityName}`);
              }
            },
          );
        }
      }
    }
  }

  private restoreRecord() {
    const { record, schemaReducer, restoreRecord } = this.props;

    if (record) {
      const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);

      if (schema) {
        this.setState({ isRestoringFile: true });
        restoreRecord(
          {
            schema,
            recordId: record.id,
          },
          (res) => {
            this.setState({
              restoreFileModalVisible: false,
              isRestoringFile: false,
            });
            return res;
          },
        );
      }
    }
  }

  private handleFormSubmit(params: { event: string; results: any }) {
    const { getRecord, getRelatedRecordById, record, schemaReducer } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);
    switch (params.event) {
      case DB_RECORD_ASSOCIATIONS_UPDATE_REQUEST:
        getRelatedRecordById({
          parentSchema: schema,
          recordId: record.id,
          dbRecordAssociationId: record?.dbRecordAssociation?.id,
        });
        break;
      case UPDATE_DB_RECORD_BY_ID_REQUEST:
        getRecord({ schema, recordId: params.results.id });
        break;
      case CREATE_DB_RECORD_REQUEST:
        history.push(`${getBrowserPath(params.results)}`);
        break;
    }
  }

  private initializeDeleteAppointmentForm() {
    const { record, initializeCancelAppointment } = this.props;
    console.log('debug: record', record);
    initializeCancelAppointment({
      cancelModalVisible: true,
      cancelRelatedRecord: record,
      deleteFromDetail: true,
      schemaType: 'SA_CANCEL',
    });
  }
}

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  getUsers: (cb: any) => dispatch(listUsers(cb)),
  getPipelines: (params: { schema: SchemaEntity }) =>
    dispatch(getPipelinesByModuleAndEntity(params)),
  getRelatedRecordById: (payload: IGetRecordAssociationById) =>
    dispatch(getRecordAssociationByIdRequest(payload)),
  getRecord: (payload: IGetRecordById) => dispatch(getRecordByIdRequest(payload)),
  deleteRecord: (payload: any, cb: any) => dispatch(deleteRecordByIdRequest(payload, cb)),
  restoreRecord: (payload: any, cb: any) => dispatch(restoreRecordByIdRequest(payload, cb)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getAssociations: (params: IGetRecordAssociations, db: any) =>
    dispatch(getRecordAssociationsRequest(params, db)),
  initializeCancelAppointment: (params: any) => dispatch(initailizeCancelAppointmentModal(params)),
  deleteRecordAssociation: (payload: IDeleteRecordAssociation, cb: any) =>
    dispatch(deleteRecordAssociationById(payload, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  toggleAssignGroupModal: () => dispatch(toggleAssignRecordToGroupModal()),
});

export default withRouter(connect(mapState, mapDispatch)(ActionMenuDetailView));
