import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import React from 'react';
import OdinTable from '@core/components/OdinTable';
import { Button } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import { IOpenRecordDrawer, openRecordDrawer } from '@redux/stores/userInterface/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getBrowserPath } from '@core/helpers/recordHelpers';
import TableCellStageTag from '@core/components/TableCellStageTag';

interface Props {
  workOrders: DbRecordEntityTransform[];
  openDrawer: (params: IOpenRecordDrawer) => void;
}

const ContactAccountWorkOrderTable: React.FC<Props> = (props: Props) => {
  const { workOrders, openDrawer } = props;

  const tableWorkOrders = workOrders.map((wo: DbRecordEntityTransform) => ({
    key: wo.id,
    recordNumber: (
      <Link key={wo.id} to={getBrowserPath(wo)} target="_blank">
        {wo.recordNumber}
      </Link>
    ),
    title: wo.title,
    status: <TableCellStageTag small key={wo.id} record={wo} />,
    action: (
      <Button
        key={wo.id}
        size="small"
        icon="panel-stats"
        onClick={() =>
          openDrawer({
            recordId: wo?.id || '',
            moduleName: 'FieldServiceModule',
            entityName: 'WorkOrder',
          })
        }
      />
    ),
  }));

  return (
    <Row>
      <Col span={24}>
        <OdinTable
          height="auto"
          data={tableWorkOrders}
          columns={[
            {
              key: 'recordNumber',
              title: 'WO #',
              width: 3,
            },
            {
              key: 'title',
              title: 'Title',
              width: 15,
            },
            {
              key: 'status',
              title: 'Status',
              width: 4,
            },
            {
              key: 'action',
              title: '',
              width: 2,
              align: 'right',
            },
          ]}
        />
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(ContactAccountWorkOrderTable);
