import { Button } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getFirstRelation } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '../../../../../redux/stores/recordAssociations/actions';
import {
  ISetOrderBuilderDrawerRecordIds,
  openOrderBuilderDrawer,
  setOrderBuilderDrawerRecordIds,
  setOrderBuilderRecontracting,
} from '../../../../../redux/stores/userInterface/actions';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';

interface Props {
  record: DbRecordEntityTransform; // Order record
  getAssociations: (params: IGetRecordAssociations, cb: any) => void;
  openOBDrawer: () => void;
  setOBDrawerRecordIds: (params: ISetOrderBuilderDrawerRecordIds) => void;
  setOBRecontracting: (params: { orderId: string }) => void;
}

const { ORDER_MODULE } = SchemaModuleTypeEnums;
const { ORDER, ACCOUNT } = SchemaModuleEntityTypeEnums;

const OrderContractRenewal: FC<Props> = (props: Props) => {
  const { record, getAssociations, openOBDrawer, setOBDrawerRecordIds, setOBRecontracting } = props;
  const [orderSchema, setOrderSchema] = useState<SchemaEntity | undefined>(undefined);
  const [isLoadingAssociations, setIsLoadingAssociations] = useState<boolean>(false);

  // Fetch Order schema on component mount
  useEffect(() => {
    if (record && !orderSchema) {
      getSchema();
    }
  }, [record]);

  const getSchema = async () => {
    const schema = await getOdinSchemaByEntity(ORDER_MODULE, ORDER);
    setOrderSchema(schema);
  };

  const startRecontract = () => {
    if (orderSchema && record) {
      setIsLoadingAssociations(true);
      getAssociations(
        {
          recordId: record.id,
          key: ORDER,
          schema: orderSchema,
          entities: [ACCOUNT],
        },
        (res: any) => {
          setIsLoadingAssociations(false);
          const accountRecord = getFirstRelation(res.results, ACCOUNT);

          setOBDrawerRecordIds({
            accountId: accountRecord ? accountRecord?.id : undefined,
          });

          setOBRecontracting({ orderId: record?.id });
          openOBDrawer();
        },
      );
    }
  };

  const isDisabled = () => {
    return record && record?.stage?.key !== 'OrderStageActive';
  };

  return (
    <Button
      outlined
      intent="primary"
      disabled={!orderSchema || isLoadingAssociations || isDisabled()}
      loading={isLoadingAssociations}
      onClick={startRecontract}
    >
      Recontract
    </Button>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  openOBDrawer: () => dispatch(openOrderBuilderDrawer()),
  setOBDrawerRecordIds: (params: ISetOrderBuilderDrawerRecordIds) =>
    dispatch(setOrderBuilderDrawerRecordIds(params)),
  setOBRecontracting: (params: { orderId: string }) =>
    dispatch(setOrderBuilderRecontracting(params)),
});

export default connect(mapState, mapDispatch)(OrderContractRenewal);
