import { Button, InputGroup, Section } from '@blueprintjs/core';
import {
  Cell,
  Column,
  RenderMode,
  SelectionModes,
  Table2,
  TableLoadingOption,
} from '@blueprintjs/table';
import { BlueprintNavigation } from '@core/components/BlueprintPagination';
import { PageHeader } from '@core/components/PageHeader';
import { searchString } from '@core/helpers/searchHelpers';
import { httpGet } from '@core/http/requests';
import SchemaActionDetails from '@core/modules/ControlPanelModule/components/Actions/SchemaActionDetails';
import SchemaActionEditCreateForm from '@core/modules/ControlPanelModule/components/Actions/SchemaActionEditCreateForm';
import SchemaActionEntityNameSelect from '@core/modules/ControlPanelModule/components/Actions/SchemaActionEntityNameSelect';
import { getErrorMessage } from '@core/modules/ControlPanelModule/helpers/errors';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { displayMessage } from '@redux/stores/messages/reducers';
import { ISchemaReducer } from '@redux/stores/schemas/reducer';
import { initializeSharedForm } from '@redux/stores/sharedForm/actions';
import { Col, Row, Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getSchemaActionType } from '../helpers';

interface Props {
  initializeForm: any;
  alertMessage: (params: { body: string; type: string }) => void;
  onSchemaSelected?: (schemaId: string) => void;
  schemaReducer: ISchemaReducer;
}

interface ITableData {
  key: string;
  name: string;
  description: string;
  entityName: string;
  moduleName: string;
  type: string;
  schemaType: string;
}

const SchemaActionListViewTable: FC<Props> = (props: Props) => {
  const { alertMessage, schemaReducer } = props;
  const [searchKey, setSearchKey] = useState<string>('');
  const [tableWidth, setTableWidth] = useState<number>(1);
  const [selectedTableRegions, setSelectedTableRegions] = useState<any[]>([]);
  const [schemaActionList, setSchemaActionList] = useState<SchemaActionEntity[]>([]);
  const [isLoadingSchemaActionList, setIsLoadingSchemaActionList] = useState<boolean>(false);
  const [selectedSchemaAction, setSelectedSchemaAction] = useState<SchemaActionEntity | undefined>(
    undefined,
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(33);
  const [tableSlice, setTableSlice] = useState<ITableData[]>([]);

  const [isSchemaActionLoading, setIsSchemaActionLoading] = useState<boolean>(false);

  const [allEntityNames, setAllEntityNames] = useState<string[]>([]);
  const [selectedEntityName, setSelectedEntityName] = useState<string>('');

  let location = useLocation();

  // parse location to get hash value entityName if it's passed
  const hash = location.hash;
  const entityName = hash?.split('#entityName=')[1];
  const schemaActionId = hash?.split('#schemaActionId=')[1];

  const tableRef = React.createRef<any>();

  useEffect(() => {
    if (schemaReducer.list?.length! > 0) {
      getAllSchemaActionsList();
    }
  }, [schemaReducer.list]);

  useEffect(() => {
    if (schemaActionList.length > 0 && schemaReducer.list?.length! > 0) {
      getAllEntityNames();
    }
  }, [schemaActionList, schemaReducer.list]);

  // If entityName is passed in the hash, set it as the selectedEntityName
  useEffect(() => {
    if (entityName) {
      if (allEntityNames.includes(entityName)) {
        setSelectedEntityName(entityName);
      }
    }
  }, [entityName, allEntityNames]);

  // If schemaActionId is passed in the hash, set it as the selectedSchemaAction
  useEffect(() => {
    if (schemaActionId) {
      const schemaAction = schemaActionList.find((sa) => sa.id === schemaActionId);

      if (schemaAction) {
        setSearchKey(schemaAction.name);
        setSelectedSchemaAction(schemaAction);
      }
    }
  }, [schemaActionId, schemaActionList]);

  useEffect(() => {
    setSelectedTableRegions([]);
    setSelectedSchemaAction(undefined);
  }, [currentPage]);

  // Get selected schemaAction details
  useEffect(() => {
    if (selectedTableRegions.length > 0 && tableSlice.length > 0) {
      const schemaAction: any = tableSlice[selectedTableRegions[0].rows[0]];
      if (schemaAction && !isSchemaActionLoading) {
        loadSchemaAction(schemaAction.key);
      }
    }
  }, [selectedTableRegions, tableSlice, selectedEntityName]);

  const onSchemaActionCreate = (action: SchemaActionEntity) => {
    // Add the new action to the list and select it
    setSchemaActionList([...schemaActionList, action]);
    setSelectedSchemaAction(action);
  };

  const getAllEntityNames = () => {
    // Get unique entityNames from all schemas in the schemaReducer.shortList
    const entityNames: string[] = [];
    schemaReducer.list.forEach((schema: any) => {
      if (!entityNames.includes(schema.entityName)) {
        entityNames.push(schema.entityName);
      }
    });

    setAllEntityNames(entityNames);
  };

  const loadSchemaAction = async (id: string) => {
    try {
      setIsSchemaActionLoading(true);
      const res = await httpGet(`SchemaModule/v1.0/schemas-actions/${id}`);
      const schemaAction = res?.data?.data;
      console.log('debug: Selected Schema Action', schemaAction);
      setSelectedSchemaAction(schemaAction);
      setIsSchemaActionLoading(false);
    } catch (e) {
      setSelectedSchemaAction(undefined);
      setIsSchemaActionLoading(false);
    }
  };

  const onSelect = (e: any) => {
    setSelectedTableRegions([
      {
        cols: [0, 5],
        rows: [e[0].rows[0], e[0].rows[0]],
      },
    ]);
  };

  useEffect(() => {
    if (schemaActionList.length > 0) {
      let tableData: ITableData[];

      // Get a list of all schemas with entity name and id
      const schemaList = schemaReducer.list.map((s: any) => ({
        id: s.id,
        entityName: s.entityName,
        moduleName: s.moduleName,
        types: s.types || [],
      }));

      tableData = schemaActionList.map((sa: any) => {
        const schema: any = schemaList.find((s: any) => s.id === sa.schemaId);
        const typeName: string =
          schema?.types?.find((t: any) => t.id === sa.schemaTypeId)?.name || '';

        return {
          key: sa.id,
          name: sa.name,
          description: sa.description,
          entityName: schema?.entityName || '',
          moduleName: schema?.moduleName || '',
          type: getSchemaActionType(sa),
          schemaType: typeName,
        };
      });

      // Apply filtering by selected entity name
      if (selectedEntityName) {
        tableData = tableData.filter((t: ITableData) => {
          return t.entityName === selectedEntityName;
        });
      }

      // Apply search
      tableData = tableData.filter((t: ITableData) => {
        return (
          searchString(t.entityName, searchKey) ||
          searchString(t.name, searchKey) ||
          searchString(t.description, searchKey)
        );
      });

      //  Sort table daya by entityName
      tableData = tableData.sort((a, b) => a.name.localeCompare(b.name));

      // Apply pagination
      const start = currentPage * pageSize - pageSize;
      const end = start + pageSize - 1;
      if (!searchKey) {
        tableData = tableData.slice(start, end);
      }

      setTableSlice(tableData);
    }
  }, [schemaActionList, currentPage, searchKey, pageSize, selectedEntityName]);

  const getAllSchemaActionsList = async () => {
    try {
      setIsLoadingSchemaActionList(true);
      const res = await httpGet(`SchemaModule/v1.0/schemas-actions/`);
      const actions: any[] = res.data.data || [];
      // console.log('%cdebug: SCHEMA ACTIONS', 'color:aquamarine', actions);
      setSchemaActionList(actions);
      setIsLoadingSchemaActionList(false);
    } catch (error: any) {
      setIsLoadingSchemaActionList(false);
      const message = getErrorMessage(error);
      alertMessage({ body: 'Could not retrieve schema actions. ' + message, type: 'error' });
      setSchemaActionList([]);
    }
  };

  // Table Width Calculation
  const getColumnWidthByPercentage = (percentage: number): number => {
    return (percentage / 100) * tableWidth;
  };

  const updateWidth = () => {
    if (tableRef.current) {
      const width = tableRef.current.scrollContainerElement?.clientWidth;
      setTableWidth(width);
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [selectedSchemaAction, tableRef, tableSlice]);

  const onSearch = (e: any) => {
    setSelectedTableRegions([]);
    setSearchKey(e.target.value);
  };

  const onActionUpdate = (action: SchemaActionEntity) => {
    // Update the schema action list
    if (action) {
      setSelectedSchemaAction(action);
      const index = schemaActionList.findIndex((sa) => sa.id === action.id);
      if (index > -1) {
        schemaActionList[index] = action;
        setSchemaActionList([...schemaActionList]);
      }
    }
  };

  const onActionDelete = (actionId: string) => {
    // Remove the action from the list
    const index = schemaActionList.findIndex((sa) => sa.id === actionId);
    if (index > -1) {
      schemaActionList.splice(index, 1);
      setSchemaActionList([...schemaActionList]);
    }

    // If selected action is deleted, clear the selection
    if (selectedSchemaAction?.id === actionId) {
      setSelectedSchemaAction(undefined);
    }
  };

  const renderAreas = () => {
    return (
      <>
        <PageHeader
          className="page-tool-bar"
          style={{ background: 'white', padding: 0, margin: 0 }}
          ghost
        >
          <Row style={{ marginBottom: 15, marginTop: 5 }} justify="end">
            <Col span={6}>
              <h2 style={{ margin: 0 }}>Actions</h2>
            </Col>
            <Col span={18} style={{ textAlign: 'right' }}>
              <Space>
                <InputGroup
                  type="search"
                  placeholder="Search Actions"
                  intent={searchKey.length > 0 ? 'primary' : 'none'}
                  onChange={onSearch}
                  value={searchKey}
                  leftIcon="search"
                  style={{ width: isMobile ? '100%' : 220 }}
                />
                <SchemaActionEntityNameSelect
                  allEntityNames={allEntityNames}
                  selectedEntityName={selectedEntityName}
                  setSelectedEntityName={setSelectedEntityName}
                />
                <SchemaActionEditCreateForm mode="CREATE" onCreate={onSchemaActionCreate} />
              </Space>
            </Col>
          </Row>
        </PageHeader>

        {/* Row with dynamically calculated height */}
        <Row className="listViewContainer">
          <Col
            span={selectedSchemaAction ? 17 : 24}
            style={{ height: '100%', width: 0, padding: 1 }}
          >
            {/* Table */}
            <Table2
              loadingOptions={isLoadingSchemaActionList ? [TableLoadingOption.CELLS] : []}
              ref={tableRef}
              numRows={tableSlice.length}
              defaultRowHeight={30}
              onSelection={onSelect}
              selectedRegions={selectedTableRegions}
              enableMultipleSelection={false}
              enableGhostCells={isLoadingSchemaActionList}
              enableRowHeader={false}
              renderMode={RenderMode.NONE}
              forceRerenderOnSelectionChange={false}
              cellRendererDependencies={[tableWidth, currentPage, tableSlice]}
              selectionModes={SelectionModes.ROWS_AND_CELLS}
              columnWidths={[
                getColumnWidthByPercentage(25),
                getColumnWidthByPercentage(20),
                getColumnWidthByPercentage(15),
                getColumnWidthByPercentage(15),
                getColumnWidthByPercentage(13),
                getColumnWidthByPercentage(12),
              ]}
            >
              <Column
                key="name"
                name="Name"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].name}</Cell>
                )}
              />
              <Column
                key="description"
                name="Description"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].description}</Cell>
                )}
              />
              <Column
                key="entityName"
                name="Entity Name"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].entityName}</Cell>
                )}
              />
              <Column
                key="moduleName"
                name="Module Name"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].moduleName}</Cell>
                )}
              />
              <Column
                key="schemaType"
                name="Schema Type"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].schemaType}</Cell>
                )}
              />
              <Column
                key="type"
                name="Action Type"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].type}</Cell>
                )}
              />
            </Table2>
          </Col>

          {/* Schema action Details */}
          {selectedSchemaAction && (
            <Col
              className="listViewDetailsColumn"
              span={7}
              style={{ opacity: isSchemaActionLoading ? 0.6 : 1 }}
            >
              <Section
                compact
                title="Action Details"
                rightElement={
                  <Button
                    icon="cross"
                    small
                    minimal
                    onClick={() => {
                      setSelectedTableRegions([]);
                      setSelectedSchemaAction(undefined);
                    }}
                  />
                }
              >
                <SchemaActionDetails
                  setSelectedAction={setSelectedSchemaAction}
                  allSchemaActions={schemaActionList}
                  schemaAction={selectedSchemaAction}
                  onUpdate={onActionUpdate}
                  onDelete={onActionDelete}
                />
              </Section>
            </Col>
          )}
        </Row>

        {/* Pagination */}
        <Row style={{ background: 'white' }}>
          <div style={{ padding: '10px 0' }}>
            <BlueprintNavigation
              totalCount={selectedEntityName || searchKey.length > 0 ? 0 : schemaActionList.length}
              currentPage={currentPage}
              pageSize={pageSize}
              onPaginate={setCurrentPage}
              disabled={searchKey.length > 0}
            />
          </div>
        </Row>
      </>
    );
  };

  return <div style={{ background: 'white', padding: '0 15px' }}>{renderAreas()}</div>;
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(SchemaActionListViewTable);
