import React from 'react';
import { notification, Space } from 'antd';
import { RadiusBottomrightOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

const openNotification = (notificationReducer: any) => {
  notification.error({
    message: notificationReducer.error.message,
    description: notificationReducer.error.validation,
    placement: notificationReducer.ui.placement,
  });
};

interface Props {
  notificationReducer: any;
}

// @ts-ignore
const Notification: FC<Props> = (props: Props) => {
  const { notificationReducer } = props;

  return (
    <Space>
      {notificationReducer.ui.hasError && openNotification(notificationReducer)}
      {notificationReducer.ui.placement === 'bottom-right' && <RadiusBottomrightOutlined />}
    </Space>
  );
};

const mapState = (state: any) => ({
  notificationReducer: state.notificationReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(Notification);
