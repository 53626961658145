export const pipelineFormFields = (pipeline?: any) => [
  {
    label: 'Name',
    property: 'name',
    type: 'TEXT',
    isRequired: true,
    message: 'Please input name',
    value: pipeline?.name || undefined,
  },
  {
    label: 'Description',
    property: 'description',
    type: 'TEXT',
    isRequired: true,
    message: 'Please input description',
    value: pipeline?.description || undefined,
  },
  {
    label: 'Key',
    property: 'key',
    type: 'TEXT',
    isRequired: true,
    message: 'Please input key',
    isDisabled: true,
    value: pipeline?.key || undefined,
  },
  {
    label: 'Schema Types',
    property: 'schemaTypes',
    type: 'SCHEMA_TYPE_SELECT',
    isRequired: true,
    isDisabled: pipeline?.schemaTypes ? true : false,
    message: 'Please add schema type',
    value: pipeline?.schemaTypes || 'DEFAULT',
  },
  {
    label: 'Is Sequential',
    property: 'isSequential',
    type: 'SWITCH',
    isRequired: true,
    value: !pipeline ? true : pipeline?.isSequential,
  },
];
