import React, { useContext } from 'react';
import { TIME_SLOT_WIDTH_PX } from '../types';
import { Col, Row } from 'antd';
import { IOpenRecordDrawer, openRecordDrawer } from '../../../../../../../../redux/stores/userInterface/actions';
import { connect } from 'react-redux';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { Icon, Tooltip } from '@blueprintjs/core';
import { HourlyViewContext } from '../index';
import { SET_WORK_ORDERS_TO_ASSIGN } from '../store/constants';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import '../styles.scss';

interface Props {
  workOrder: any;
  openDrawer: (params: IOpenRecordDrawer) => void;
  isHighlighted: boolean;
  shownInModal?: boolean;
}

const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;
const { WORK_ORDER } = SchemaModuleEntityTypeEnums;

const HourlyViewAssignedWorkOrder: React.FC<Props> = (props: Props) => {
  const { workOrder, openDrawer, isHighlighted, shownInModal } = props;
  const { state, dispatch } = useContext(HourlyViewContext);

  const address = workOrder?.Address?.dbRecords[0] || [];
  const SA = workOrder?.ServiceAppointment?.dbRecords[0] || [];

  const getWorkOrderBlockClassName = (workOrder: any) => {
    if (workOrder?.sla?.status === 'RED') {
      return 'red';
    } else {
      switch (workOrder?.stage?.name) {
        case 'Scheduled':
          return 'lightBlue';
        case 'Accepted':
          return 'lightBlue';
        case 'In Progress':
          return 'lightGreen';
        case 'Complete':
          return 'darkBlue';
        case 'Cancelled':
          return 'white';
        case 'Remediation Required':
          return 'lightOrange';
        case 'En Route':
          return 'lightTurquoise';
        default:
          return 'white';
      }
    }
  };

  const constructAddressLine = (): string => {
    if (address) {
      return `${address.properties?.AddressLine1}, ${address.properties?.PostalCode}`;
    } else {
      return '';
    }
  };

  const isWOInAssignedList = (workOrderId: string) => {
    return state.workOrdersToAssign.some((wo: any) => wo.id === workOrderId);
  };

  const addRemoveFromAssignedWOList = (WO: DbRecordEntityTransform) => {
    if (!shownInModal) {
      if (state.workOrdersToAssign.some((wo: DbRecordEntityTransform) => wo.id === WO.id)) {
        const newAssigningWorkOrderList = state.workOrdersToAssign.filter(
          (wo: any) => wo.id !== workOrder.id,
        );
        console.log('debug: adding!');
        dispatch({ type: SET_WORK_ORDERS_TO_ASSIGN, payload: newAssigningWorkOrderList });
      } else {
        const newAssigningWorkOrderList = state.workOrdersToAssign.concat(workOrder);
        dispatch({ type: SET_WORK_ORDERS_TO_ASSIGN, payload: newAssigningWorkOrderList });
        console.log('debug: removing...');
      }
    }
  };

  const handleWOClick = () => {
    // Regular mode, open quick view
    if (!shownInModal && !state.isAssigningWorkOrders) {
      openDrawer({
        recordId: workOrder?.id,
        moduleName: FIELD_SERVICE_MODULE,
        entityName: WORK_ORDER,
      });
    } else if (!shownInModal && state.isAssigningWorkOrders) {
      addRemoveFromAssignedWOList(workOrder);
    }
  };

  if (SA) {
    const hasTimeBlock = SA.properties?.TimeBlock;

    return (
      <Tooltip
        disabled={workOrder.sla?.status !== 'RED'}
        content={workOrder?.sla?.reason}
        fill
        hoverOpenDelay={800}
        key={'hourlyViewWOMasterTooltip' + workOrder.id}
      >
        <Col
          key={'hourlyViewWOMasterCol' + workOrder.id}
          className={`hourlyViewWorkOrderBlock ${getWorkOrderBlockClassName(workOrder)} ${
            isHighlighted ? 'highlighted' : ''
          }`}
          style={{
            cursor: 'pointer',
            width: TIME_SLOT_WIDTH_PX * 2 - 10,
            opacity:
              !shownInModal && state.isAssigningWorkOrders && !isWOInAssignedList(workOrder.id)
                ? 0.5
                : 1,
          }}
          onClick={handleWOClick}
        >
          <Row key={'hourlyViewWORow' + workOrder.id}>
            {/* Record Number + Star */}
            <Col span={16} key={'hourlyViewWOCol1' + workOrder.id}>
              <span
                className={isHighlighted ? 'highlighted' : ''}
                key={'hourlyViewSpan1' + workOrder.id}
                style={{ fontSize: 10, fontWeight: 600 }}
              >
                {workOrder.recordNumber}
                {workOrder?.properties?.IsVIP && (
                  <Icon
                    icon="star"
                    color="gold"
                    size={11}
                    style={{ paddingLeft: 4, paddingBottom: 1 }}
                  />
                )}
              </span>
            </Col>

            {/* Time Slot */}
            <Col span={8} key={'hourlyViewCol2' + workOrder.id} style={{ textAlign: 'right' }}>
              {hasTimeBlock && (
                <span style={{ fontSize: 10, fontWeight: 600 }}>{SA.properties?.TimeBlock}</span>
              )}
            </Col>

            <Col span={24} style={{ marginTop: 0 }}>
              {/* Type */}
              <Row style={{ lineHeight: '12px', width: '100%' }}>
                <Col>
                  <span style={{ fontWeight: 600, fontSize: 8, marginRight: 5 }}>Type:</span>
                </Col>
                <Col>
                  <span style={{ fontSize: 8 }}>{workOrder?.type}</span>
                </Col>
              </Row>

              {/* Exchange */}
              <Row style={{ lineHeight: '12px', width: '100%' }}>
                <Col>
                  <span style={{ fontWeight: 600, fontSize: 8, marginRight: 5 }}>Exchange: </span>
                </Col>
                <Col>
                  <span style={{ fontSize: 8 }}>
                    {address?.properties?.ExchangeName || 'Unknown Exchange'}
                  </span>
                </Col>
              </Row>
              <Row style={{ lineHeight: '12px', width: '100%' }}>
                <Col>
                  <span style={{ fontWeight: 600, fontSize: 8, marginRight: 5 }}>Address: </span>
                </Col>
                <Col>
                  <span style={{ fontSize: 8 }}>{constructAddressLine()}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Tooltip>
    );
  } else {
    return <></>;
  }
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});
export default connect(mapState, mapDispatch)(HourlyViewAssignedWorkOrder);
