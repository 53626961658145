import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { CaseManagementSchedulingContext } from '../index';
import {
  CASE_MANAGEMENT_SCHEDULING_ADD_INITIAL_TIME_BLOCK,
  CASE_MANAGEMENT_SCHEDULING_SET_SELECTED_USER,
  CASE_MANAGEMENT_SCHEDULING_TOGGLE_USER_DIALOG,
} from '../store/constants';
import { ALL_TIME_BLOCKS, TScheduleUser } from '../types';
import './styles.scss';

interface Props {
  user: TScheduleUser;
}

const SchedulingUserTimeline: React.FC<Props> = (props: Props) => {
  const { user } = props;
  let timeBlocks: any[] = user.timeBlocks || [];
  const { state, dispatch } = useContext(CaseManagementSchedulingContext);

  const getTimeSlotWidth = () => {
    switch (state.horizontalZoom) {
      case 0:
        return 40;
      case 25:
        return 50;
      case 50:
        return 60;
      case 75:
        return 70;
      case 100:
        return 80;
      default:
        return 80;
    }
  };

  const TimeSlot = (props: {
    time: string;
    schedule: any;
    isStart?: boolean;
    isEnd?: boolean;
    isBetween?: boolean;
  }) => {
    const { time, schedule, isStart, isEnd, isBetween } = props;

    const type = schedule?.type ? schedule?.type[0] : '';
    let isEndingIn30min = false;

    const isCrossingMidnight = dayjs(schedule.endTime, 'HH:mm').isBefore(
      dayjs(schedule.startTime, 'HH:mm'),
    );

    if (isStart) {
      const endTime = dayjs(schedule.endTime, 'HH:mm');
      const timeFrame = dayjs(time, 'HH:mm');
      isEndingIn30min = endTime.isSame(timeFrame.add(30, 'minute'));
    }

    return (
      <div
        key={user.userId + 'div1'}
        style={{
          height: 40,
          width: getTimeSlotWidth(),
        }}
      >
        <div
          className={`schedulingTimeSlot schedulingTimeSlotColor ${schedule.type} ${
            isStart ? 'start' : ''
          } ${isEnd || isEndingIn30min ? 'end' : ''} ${isBetween ? 'between' : ''}`}
          onClick={() => {
            dispatch({ type: CASE_MANAGEMENT_SCHEDULING_SET_SELECTED_USER, payload: user });
            dispatch({ type: CASE_MANAGEMENT_SCHEDULING_TOGGLE_USER_DIALOG, payload: true });
          }}
          key={user.userId + 'div2'}
        >
          <Row justify="space-between" key={user.userId + 'row1'}>
            {state.horizontalZoom > 50 && <Col key={user.userId}></Col>}
            <Col key={user.userId} span={state.horizontalZoom <= 50 ? 24 : undefined}>
              <span key={user.userId + time + 3}>
                {/* if there is type, get first character */}
                {isStart && (
                  <span>
                    {type}
                    {isCrossingMidnight && (
                      <i className="bi bi-arrow-right" style={{ marginLeft: 8 }} />
                    )}
                  </span>
                )}
                {isBetween && time === '00:00' && isCrossingMidnight && (
                  <span>
                    <i className="bi bi-arrow-left" style={{ marginRight: 8 }} />
                    {type}
                  </span>
                )}
              </span>
            </Col>
            {state.horizontalZoom > 50 && (
              <Col key={user.userId} style={{ paddingRight: 10 }}>
                <i key={user.userId} className={'editIcon bi bi-pencil'} />
              </Col>
            )}
          </Row>
        </div>
      </div>
    );
  };

  const EmptySlot = (props: { time: string }) => {
    const { time } = props;

    return (
      <div
        style={{
          width: getTimeSlotWidth(),
          border: '2px solid transparent',
          borderRight: time === '23:30' ? 'none' : '1px solid #efefef',
          height: 34,
        }}
        key={user.userId + time + 1}
        onClick={() => {
          dispatch({ type: CASE_MANAGEMENT_SCHEDULING_SET_SELECTED_USER, payload: user });
          dispatch({ type: CASE_MANAGEMENT_SCHEDULING_TOGGLE_USER_DIALOG, payload: true });
          dispatch({
            type: CASE_MANAGEMENT_SCHEDULING_ADD_INITIAL_TIME_BLOCK,
            payload: {
              startTime: time,
              endTime: dayjs(time, 'HH:mm').add(30, 'minute').format('HH:mm'),
            },
          });
        }}
      >
        <div
          className="schedulingEmptySlot"
          key={user.userId + time + 2}
          style={{
            background: '#F2F2F2',
            verticalAlign: 'middle',
            borderRadius: 4,
            padding: '0 5px',
            marginRight: 6,
            border: '1px dashed #D1DAF9',
            paddingTop: 7,
          }}
        >
          <span key={user.userId + time + 3}>
            <i style={{ marginLeft: 3 }} className="bi bi-plus-lg" />
          </span>
        </div>
      </div>
    );
  };

  const renderSlot = (timeFrame: string) => {
    // 00:00, 00:30, 01:00 ...
    const time = dayjs(timeFrame, 'HH:mm');

    // Time frame is at the start of a schedule
    const startSchedule = timeBlocks.find((schedule) => {
      const startTime = dayjs(schedule.startTime, 'HH:mm');
      return time.isSame(startTime);
    });

    // Time frame is happening between schedule start and end
    const betweenSchedule = timeBlocks.find((tb) => {
      // startTime       endTime
      // 23:00      -    01:00
      const isCrossingMidnight = dayjs(tb.endTime, 'HH:mm').isBefore(dayjs(tb.startTime, 'HH:mm'));

      // If schedule is crossing midnight, we need to check if the time frame is between 00:00 and the end time
      let endTime;
      let startTime;

      startTime = dayjs(tb.startTime, 'HH:mm');
      endTime = dayjs(tb.endTime, 'HH:mm');

      // const startTime =
      //   timeFrame === '00:00' && isCrossingMidnight
      //     ? dayjs('24:00', 'HH:mm')
      //     : dayjs(tb.startTime, 'HH:mm');
      // const endTime =
      //   isCrossingMidnight && timeFrame === '23:30'
      //     ? dayjs('24:00', 'HH:mm')
      //     : dayjs(tb.endTime, 'HH:mm');

      if (isCrossingMidnight) {
        return time.isAfter(startTime) || time.isBefore(endTime);
      } else {
        return time.isBetween(startTime, endTime) && !endTime.isBefore(startTime);
      }
    });

    // Time frame is at the end of a schedule
    const endSchedule = timeBlocks.find((schedule) => {
      const endTime = dayjs(schedule.endTime, 'HH:mm');
      return time.isSame(endTime.subtract(30, 'minute'));
    });

    //console.table({
    //table: 'DEBUG',
    //time: timeFrame,
    //startSchedule: { startTime: startSchedule?.startTime, endTime: startSchedule?.endTime },
    // betweenSchedule: { startTime: betweenSchedule?.startTime, endTime: betweenSchedule?.endTime },
    // endSchedule: { startTime: endSchedule?.startTime, endTime: endSchedule?.endTime },
    //});

    // return <EmptySlot time={timeFrame} />;

    if (startSchedule) {
      return <TimeSlot time={timeFrame} schedule={startSchedule} isStart />;
    } else if (endSchedule) {
      return <TimeSlot time={timeFrame} schedule={endSchedule} isEnd />;
    } else if (betweenSchedule) {
      return <TimeSlot time={timeFrame} schedule={betweenSchedule} isBetween />;
    } else {
      return <EmptySlot time={timeFrame} />;
    }
  };

  const renderTimeFrames = () => {
    return ALL_TIME_BLOCKS.map((time: string, index) => (
      <div
        key={user.userId + time + index}
        style={{
          paddingTop: 6,
          height: 47,
          textAlign: 'center',
          display: 'inline-block',
          verticalAlign: 'top',
        }}
      >
        {renderSlot(time)}
      </div>
    ));
  };

  return <>{renderTimeFrames()}</>;
};
export default SchedulingUserTimeline;
