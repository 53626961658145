import { Button, Callout, Section } from '@blueprintjs/core';
import { forgotPasswordRequest } from '@redux/stores/identity/actions';
import { displayMessage } from '@redux/stores/messages/reducers';
import { Col, Input, Layout, Row } from 'antd';
import { FC, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '@core/views/UserLoginView/styles.scss';

interface Props {
  forgotPassword: (params: any, cb: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

const ForgotPasswordView: FC<Props> = (props: Props) => {
  const { forgotPassword, alertMessage } = props;

  const [email, setEmail] = useState<string>('');
  const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);

  const handleOnChange = (email: string) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email)) {
      setEmail(email);
    } else {
      setEmail('');
    }
  };

  const submitEmail = () => {
    setIsSendingEmail(true);

    forgotPassword({ email: email.toLowerCase() }, (resp: any) => {
      if (resp) {
        setIsSendingEmail(false);
        setIsSuccessful(true);
        alertMessage({
          body: 'Link with password reset link has been sent to your email.',
          type: 'success',
        });
      } else {
        setIsSendingEmail(false);
        alertMessage({
          body: 'There was an issue resetting your password.',
          type: 'error',
        });
      }
    });
  };

  return (
    <Layout>
      <Row style={{ height: '100vh' }} align="middle" justify="center">
        <Col xs={22} md={12} lg={7}>
          <Section
            title="Forgot Password"
            rightElement={
              <Link to="/login">
                <Button minimal text="Login Page" icon="caret-left" />
              </Link>
            }
          >
            <Row style={{ padding: 20 }}>
              {/* Callout */}
              <Col span={24}>
                {isSuccessful ? (
                  <Callout intent="success" title="Request Sent!" icon={null}>
                    We sent you an email with a link that will take you to a Password reset page.
                    Please check your email.
                  </Callout>
                ) : (
                  <Callout>
                    <span>
                      In order to reset your password, please enter your email address here and
                      request a password reset email. After that, you will receive an automatic
                      email with a link to Password reset page.
                    </span>
                  </Callout>
                )}
              </Col>

              {/* Email Address */}
              <Col span={24} style={{ marginTop: 20 }}>
                <Input
                  style={{ borderRadius: 2 }}
                  type="email"
                  placeholder="Email address"
                  disabled={isSendingEmail || isSuccessful}
                  size="large"
                  autoFocus
                  onChange={(e) => handleOnChange(e.target.value)}
                />
              </Col>

              {/* Submit Button */}
              <Col span={24} style={{ marginTop: 20, textAlign: 'center' }}>
                <Button
                  large
                  intent="primary"
                  loading={isSendingEmail}
                  disabled={email === '' || isSendingEmail || isSuccessful}
                  onClick={submitEmail}
                  style={{ minWidth: 150 }}
                >
                  Request Password Reset
                </Button>
              </Col>
            </Row>
          </Section>
        </Col>
      </Row>
    </Layout>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  forgotPassword: (params: any, cb: any) => dispatch(forgotPasswordRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(ForgotPasswordView);
