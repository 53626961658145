import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Empty, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { toggleNotificationDrawer } from '../../../../redux/stores/userInterface/actions';
import { Button, Tooltip } from '@blueprintjs/core';
import { httpPost } from '../../../http/requests';
import './styles.scss';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import history from '../../../helpers/browserHistory';

interface Props {
  mentions: DbRecordEntityTransform[];
  toggleDrawer: () => void;
  onMentionRemove: (mention: DbRecordEntityTransform) => void;
  dismissedMentionsIds: string[];
}

const NotificationDrawerMentionList: React.FC<Props> = (props: Props) => {
  const { mentions, toggleDrawer, onMentionRemove, dismissedMentionsIds } = props;
  const location = useLocation();

  const navigateToMention = (mentionId: string) => {
    // if the user is already in the inbox, we need to reload the page to fetch the new mention
    if (location.pathname.indexOf('/inbox') > -1) {
      history.push(`/inbox/${mentionId}`);
      setTimeout(() => {
        window.location.reload();
      }, 200);
    } else {
      toggleDrawer();
      setTimeout(() => {
        history.push(`/inbox/${mentionId}`);
      }, 700);
    }
  };

  const markMentionAsRead = (mention: DbRecordEntityTransform) => {
    if (mention) {
      httpPost(`AuditModule/v1.0/UserAudit/batch`, [
        {
          type: 'DB_RECORD_VIEWED',
          recordId: mention.id,
          data: { viewedFrom: 'Feed' },
        },
      ]).then(() => {
        onMentionRemove && onMentionRemove(mention);
      });
    }
  };

  return (
    <Row>
      {mentions.length > 0 ? (
        mentions.map((mention: DbRecordEntityTransform, i: number) => {
          return (
            <Col
              className={`mentionCard ${dismissedMentionsIds.includes(mention.id) ? 'hidden' : ''}`}
              span={24}
              key={mention.id}
              style={{ marginBottom: 15, padding: 10, background: '#F5F5F5', borderRadius: 8 }}
            >
              <Row key={i} align="top">
                <Col span={22} style={{ fontWeight: 500, paddingTop: 1 }}>
                  <span>
                    <span
                      onClick={() => navigateToMention(mention.id)}
                      style={{ color: '#2D72D2', cursor: 'pointer' }}
                    >
                      Mentioned By{' '}
                      {mention.lastModifiedBy?.fullName ||
                        mention.createdBy?.fullName ||
                        'Unknown'}{' '}
                    </span>
                  </span>
                </Col>
                <Col span={2} style={{ textAlign: 'right' }}>
                  <Tooltip content="Mark as Read" hoverOpenDelay={1200}>
                    <Button
                      small
                      minimal
                      icon="cross"
                      disabled={dismissedMentionsIds.includes(mention.id)}
                      onClick={() => markMentionAsRead(mention)}
                    />
                  </Tooltip>
                </Col>
              </Row>
              <Row key={'row2' + i}>
                <Col span={24} style={{ marginTop: 5 }}>
                  <span style={{ opacity: 0.4 }}>
                    {dayjs(mention.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                  </span>
                </Col>
              </Row>
            </Col>
          );
        })
      ) : (
        <Col span={24} style={{ marginTop: 50 }}>
          <Empty description="No mentions to show" />
        </Col>
      )}
    </Row>
  );
};

const mapState = (state: any) => ({});
const mapDispatch = (dispatch: any) => ({
  toggleDrawer: () => dispatch(toggleNotificationDrawer()),
});

export default connect(mapState, mapDispatch)(NotificationDrawerMentionList);
