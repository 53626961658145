import { Button, MenuItem } from '@blueprintjs/core';
import { ItemRendererProps, Select } from '@blueprintjs/select';
import { JSX } from 'react';
import { Action, ActionLabel } from './types';

type ActionsDropdownProps = {
  value?: Action;
  onChange: (value: Action) => void;
};

const allActionKeys: Action[] = ['LOOKUP_AND_CREATE', 'LOOKUP_ONLY', 'CREATE_ONLY', 'READ_ONLY'];

const renderAction = (
  item: Action,
  { handleClick, handleFocus, modifiers }: ItemRendererProps,
): JSX.Element => {
  return (
    <MenuItem
      key={item}
      text={ActionLabel[item]}
      roleStructure="listoption"
      active={modifiers.active}
      onClick={handleClick}
      onFocus={handleFocus}
    />
  );
};

export const ActionsDropdown = (props: ActionsDropdownProps) => {
  return (
    <Select<Action>
      items={allActionKeys}
      filterable={false}
      itemRenderer={renderAction}
      activeItem={props.value}
      onItemSelect={props.onChange}
    >
      <Button
        text={props.value ? ActionLabel[props.value] : 'Select an action'}
        endIcon="caret-down"
        size="small"
        alignText="left"
        fill
        style={{ fontWeight: 'normal' }}
        className="dropdown__button"
      />
    </Select>
  );
};
