import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@blueprintjs/core';
import { initializeRecordForm } from '../../../../../../../../../redux/stores/form/actions';
import CoreForm from '@core/components/Forms/CoreForm';
import { MyCasesContext } from '../../../../index';
import { FormReducerSubmitEvt } from '@core/components/Forms/SharedForm/SharedFormModal';
import { getRecordByIdRequest, IGetRecordById } from '../../../../../../../../../redux/stores/records/actions';
import { MY_CASES_SET_CONTACT } from '../../../../store/constants';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

interface Props {
  initializeForm: (params: any) => void;
  getRecordById: (payload: IGetRecordById, cb: any) => void;
  userReducer: any;
}

const MyCasesCreateContactButton: React.FC<Props> = (props: Props) => {
  const { initializeForm, getRecordById, userReducer } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const [contactSchema, setContactSchema] = useState<SchemaEntity | undefined>(undefined);

  useEffect(() => {
    getContactSchema();
  }, []);

  const getContactSchema = async () => {
    try {
      const schema = await getOdinSchemaByEntity('CrmModule', 'Contact');
      if (schema) {
        setContactSchema(schema);
      }
    } catch (e: any) {}
  };

  const isCaseOwn = getProperty(state.selectedCase, 'OwnerId') === userReducer.user.id;

  const initializeEditContactForm = () => {
    if (contactSchema && state.selectedCaseContact) {
      initializeForm({
        formUUID: `${contactSchema!.id}_Edit`,
        title: `Edit Contact`,
        showFormModal: true,
        isCreateReq: false,
        isUpdateReq: true,
        schema: contactSchema,
        selected: state.selectedCaseContact,
        sections: [{ name: contactSchema.name, schema: contactSchema }],
      });
    }
  };

  const handleFormSubmit = (params: any) => {
    const recordId = params.results?.id;
    if (recordId && contactSchema) {
      getRecordById({ schema: contactSchema, recordId: recordId }, (res: any) => {
        dispatch({ type: MY_CASES_SET_CONTACT, payload: res });
      });
    }
  };

  return contactSchema ? (
    <>
      <CoreForm
        type="MODAL"
        formUUID={`${contactSchema!.id}_Edit`}
        onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
      />
      <Button
        icon={<i className="bi bi-pencil" />}
        disabled={!isCaseOwn}
        small
        intent="primary"
        minimal
        onClick={initializeEditContactForm}
      />
    </>
  ) : (
    <></>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});
const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(MyCasesCreateContactButton);
