import { httpPost } from '@core/http/requests';
import { CaseChannel } from '@d19n/sandbox-odin-sdk/dist/entities-v2/Case';
import { useEffect, useState } from 'react';
import DashboardBarChart, { DataItem } from './DashboardBarChart';

type Props = {
  refreshBit: 0 | 1;
};
const CurrentCasesStatus = (props: Props) => {
  const [currentCasesStatusesAggregate, setCurrentCasesStatusesAggregate] = useState<DataItem[]>(
    [],
  );

  const getCurrentCasesAggregate = async () => {
    const result = await httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Channel',
            operator: 'not in',
            value: [CaseChannel.WEB_CHAT, CaseChannel.CALL],
          },
          {
            columnName: 'StageName',
            operator: 'not in',
            value: ['Solved', 'Closed'],
          },
        ],
        returnProperties: [],
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 0,
      },
    });
    const aggregations = result.data.data.aggregations['stage-agg'].buckets;
    if (aggregations?.length > 0) {
      setCurrentCasesStatusesAggregate(
        aggregations.map((item: any) => ({ name: item.key, value: item.doc_count })),
      );
    }
  };

  useEffect(() => {
    getCurrentCasesAggregate();
  }, [props.refreshBit]);

  return (
    <DashboardBarChart
      title="Current Status"
      orderByValue="desc"
      data={currentCasesStatusesAggregate}
    />
  );
};

export default CurrentCasesStatus;
