import { Button, Callout, NumericInput } from '@blueprintjs/core';
import { httpPost } from '@core/http/requests';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Popconfirm, Row } from 'antd';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import RecordCard from 'src/core/components/RecordCard';
import {
  IOpenRecordDrawer,
  openRecordDrawer,
} from '../../../../../../redux/stores/userInterface/actions';
import { isRecordOrderItem } from '../../helpers';
import { orderBuilderContext } from '../../index';
import { REMOVE_ORDER_ITEM, SET_ORDER_ITEMS } from '../../store/constants';
import '../../styles.scss';

interface Props {
  orderItem: DbRecordEntityTransform;
  openDrawer: (params: IOpenRecordDrawer) => void;
}

const { PRODUCT_MODULE } = SchemaModuleTypeEnums;
const { PRODUCT } = SchemaModuleEntityTypeEnums;

const OrderItemCard: React.FC<Props> = (props: Props) => {
  const { orderItem, openDrawer } = props;
  const { state, dispatch } = useContext(orderBuilderContext);

  // Remove order item from the list
  const removeOrderItem = (orderItem: DbRecordEntityTransform) => {
    dispatch({ type: REMOVE_ORDER_ITEM, payload: orderItem.id });
  };

  const renderProductTrialAndDiscountInfo = (record: DbRecordEntityTransform) => {
    const trialLength = getProperty(record, 'TrialLength');

    const discount1Value = Number(getProperty(record, 'DiscountValue'));
    const discount1Type = getProperty(record, 'DiscountType');
    const discount1Length = getProperty(record, 'DiscountLength');

    const discount2Value = Number(getProperty(record, 'Discount2Value'));
    const discount2Type = getProperty(record, 'Discount2Type');
    const discount2Length = getProperty(record, 'Discount2Length');

    let trialDiscountText: string = '';

    // Free period available
    if (record && trialLength > 0) {
      trialDiscountText = `Product is free for ${trialLength} ${
        trialLength > 1 ? 'months' : 'month'
      }. `;
    }

    // Discount 1 available
    if (record && Number(discount1Value) > 0) {
      trialDiscountText += `Product is ${trialLength > 0 ? 'then ' : ''}discounted ${
        discount1Type === 'AMOUNT' ? '£' : ''
      }${discount1Value}${discount1Type === 'PERCENT' ? '%' : ''} for ${discount1Length} ${
        discount1Length > 1 ? 'months' : 'month'
      }.`;
    }

    // Discount 2 available
    if (record && Number(discount2Value) > 0) {
      trialDiscountText += `${
        trialLength === 0 && discount1Length === 0 ? 'Product is ' : ' After that, product is '
      }discounted ${discount2Type === 'AMOUNT' ? '£' : ''}${discount2Value}${
        discount2Type === 'PERCENT' ? '%' : ''
      } for ${discount2Length} ${discount2Length > 1 ? 'months' : 'month'}.`;
    }

    if (trialDiscountText.length > 0) {
      return (
        <Callout intent="primary" style={{ marginTop: 10, marginBottom: 15 }}>
          {trialDiscountText}
        </Callout>
      );
    } else {
      return <></>;
    }
  };

  const onQuantityChange = (record: DbRecordEntityTransform, quantity: number): any => {
    let newOrderItems: DbRecordEntityTransform[] = Object.assign(state.orderItems);

    newOrderItems.map((item: DbRecordEntityTransform) => {
      if (item.id === record.id) {
        item.properties.Quantity = quantity;
        return item;
      }
    });

    const filteredProducts = newOrderItems?.map((product: DbRecordEntityTransform) => ({
      recordId: product.id,
      relatedAssociationId:
        product.dbRecordAssociation && product.dbRecordAssociation.relatedAssociationId
          ? product.dbRecordAssociation.relatedAssociationId
          : null,
      properties: {
        Quantity: getProperty(product, 'Quantity'),
      },
    }));

    httpPost(`OrderModule/v1.0/orders/quote`, {
      discountId: state.discount?.id || null,
      products: filteredProducts,
    }).then((response: any) => {
      dispatch({ type: 'SET_ORDER_SUMMARY', payload: response.data?.data });
    });

    dispatch({ type: SET_ORDER_ITEMS, payload: newOrderItems });
  };

  const orderItemVisibleProperties = ['ProductCategory', 'ProductType'];
  const productVisibleProperties = ['Category', 'Type', 'TrialUnit', 'TrialLength'];

  return (
    <Col span={24} key={orderItem.title} style={{ padding: 15 }}>
      <RecordCard
        openTitleLinkInNewTab
        propertyColumns={2}
        headerElement={
          <>
            <Button
              icon="panel-stats"
              onClick={() =>
                openDrawer({
                  recordId: orderItem?.id,
                  moduleName: PRODUCT_MODULE,
                  entityName: PRODUCT,
                })
              }
            />
            <Popconfirm
              placement="topLeft"
              title="Do you want to remove this product from the order?"
              onConfirm={() => removeOrderItem(orderItem)}
              okText="Yes"
              cancelText="No"
              disabled={orderItem.lockedRemoval}
            >
              <Button intent="danger" outlined icon="trash" disabled={orderItem.lockedRemoval} />
            </Popconfirm>
          </>
        }
        record={orderItem}
        visibleProperties={
          isRecordOrderItem(orderItem) ? orderItemVisibleProperties : productVisibleProperties
        }
        footerElements={[
          <Row>
            <Col span={24}>{renderProductTrialAndDiscountInfo(orderItem)}</Col>
          </Row>,
          <Row
            style={{
              padding: '7',
            }}
          >
            <Col span={20}>
              <span>Quantity:</span>
              <br />
              <NumericInput
                defaultValue={1}
                min={1}
                max={1000}
                style={{ width: 80 }}
                onValueChange={(e: any) => onQuantityChange(orderItem, e)}
              />
            </Col>

            <Col span={4}>
              <Row justify="end">
                {/* Total price */}
                <Col style={{ textAlign: 'right' }}>
                  <Row>
                    <Col span={24}>
                      <span style={{ fontWeight: 600, fontSize: '1.2em' }}>Unit Price</span>
                    </Col>
                    <Col span={24} style={{ marginTop: 5, fontWeight: 400, fontSize: '1.1em' }}>
                      <span>{getProperty(orderItem, 'UnitPrice')}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>,
        ]}
      />
    </Col>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(OrderItemCard);
