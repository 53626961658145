import { Button, Card, NonIdealState, Section, Tag, Tooltip } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import ModuleEntityIcon from 'src/core/components/ModuleEntityIcon';
import { INavigationReducer } from '../../../../redux/stores/navigation/reducer';
import { ISchemaReducer } from '../../../../redux/stores/schemas/reducer';
import {
  IOpenRecordDrawer,
  openRecordDrawer,
} from '../../../../redux/stores/userInterface/actions';
import NoteFeed from '../../../components/NoteFeed';
import TableCellStageTag from '../../../components/TableCellStageTag';
import { getInboxViewportHeight } from '../helpers';
import { InboxContext } from '../index';
import './styles.scss';

interface Props {
  assignments: DbRecordEntityTransform[];
  navigationReducer: INavigationReducer;
  schemaReducer: ISchemaReducer;
  userReducer: any;
  openDrawer: (params: IOpenRecordDrawer) => void;
  owner: 'My' | 'Team';
}

const InboxAssignmentsList: React.FC<Props> = (props: Props) => {
  const { navigationReducer, openDrawer, assignments, owner } = props;
  const { state, setSelectedMention, toggleMentionReadStatus } = useContext(InboxContext);

  const selectedAssignment = state.selectedAssignment;

  const isAssignmentSelected = (assignment: DbRecordEntityTransform) => {
    if (selectedAssignment) {
      return assignment.id === selectedAssignment.id;
    } else {
      return false;
    }
  };

  return (
    <Row>
      <Col
        span={selectedAssignment ? 14 : 24}
        onClick={(e: any) => {
          if (selectedAssignment) {
            setSelectedMention(undefined);
          }
        }}
      >
        <Row>
          {
            // If there are no mentions, show a message
            assignments.length === 0 && (
              <Col span={24} style={{ textAlign: 'center', padding: 20 }}>
                <span>No assignments to show</span>
              </Col>
            )
          }

          {/* Render Mentions */}
          {assignments.length > 0 &&
            assignments.map((assignment: DbRecordEntityTransform, i: number) => (
              <Col
                key={i}
                span={24}
                className={`inboxListAssignmentRow ${
                  isAssignmentSelected(assignment) ? 'selected' : ''
                }`}
              >
                <Card compact elevation={0} key={i}>
                  <Row align="middle">
                    <Col span={14} key={i}>
                      <Row align="middle">
                        <Col>
                          <ModuleEntityIcon
                            moduleName={assignment.entity?.split(':')[0] || ''}
                            entityName={assignment.entity?.split(':')[1] || ''}
                            iconContainerStyle={{ fontSize: 10, padding: '4px 6px' }}
                          />
                        </Col>
                        <Col>
                          <span style={{ fontWeight: 600, marginRight: 15 }}>
                            {assignment.recordNumber}
                          </span>
                        </Col>
                        <Col>
                          <span style={{ opacity: 0.55 }}>
                            <span>{assignment.title}</span>
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={10}>
                      <Row align="middle" justify="end">
                        {owner === 'Team' && (
                          <Col>
                            <Tag round minimal intent="primary" style={{ marginRight: 15 }}>
                              {assignment.properties?.TeamName || 'Unknown team'}
                            </Tag>
                          </Col>
                        )}
                        <Col span={8} style={{ cursor: 'pointer', paddingRight: 10 }}>
                          <Tooltip
                            fill
                            content={`Stage updated at ${dayjs(assignment.stageUpdatedAt).format(
                              'DD/MM/YYYY HH:mm:ss',
                            )}`}
                            hoverOpenDelay={1000}
                          >
                            <TableCellStageTag record={assignment} />
                          </Tooltip>
                        </Col>

                        <Col style={{ textAlign: 'right', paddingRight: 15 }} span={6}>
                          <span className="dateTime">
                            {dayjs(assignment.createdAt).format('DD/MM/YYYY HH:mm')}
                          </span>
                        </Col>
                        <Col>
                          <Button
                            icon="panel-stats"
                            onClick={() =>
                              openDrawer({
                                recordId: assignment.id,
                                moduleName: assignment.entity?.split(':')[0] || '',
                                entityName: assignment.entity?.split(':')[1] || '',
                              })
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
        </Row>
      </Col>
      {selectedAssignment && (
        <Col span={10}>
          <Section
            title="Message Preview"
            rightElement={
              <>
                {selectedAssignment?.linkedRecord && (
                  <Button
                    text={selectedAssignment.linkedRecord.recordNumber}
                    onClick={() =>
                      openDrawer({
                        recordId: selectedAssignment.linkedRecord?.id,
                        moduleName: selectedAssignment.linkedRecord?.entity?.split(':')[0] || '',
                        entityName: selectedAssignment.linkedRecord?.entity?.split(':')[1] || '',
                      })
                    }
                    size="small"
                    icon="panel-stats"
                  />
                )}
                <Button
                  onClick={() => {
                    setSelectedMention(undefined);
                  }}
                  small
                  rightIcon="cross"
                  minimal
                />
              </>
            }
          >
            <div
              style={{
                height: getInboxViewportHeight(navigationReducer.tabHistory!.length! || 0),
                overflowY: 'scroll',
                paddingBottom: 40,
              }}
            >
              <Row>
                <Col span={24} style={{ paddingTop: 8 }}>
                  {/* Cannot show Preview */}
                  {!selectedAssignment?.linkedRecord && (
                    <div style={{ padding: 50 }}>
                      <NonIdealState
                        icon="search"
                        title={'Cannot show Preview'}
                        description="We could not find a mention to preview. Please select another mention from the list."
                      />
                    </div>
                  )}

                  {/* Can show Preview */}
                  {selectedAssignment?.linkedRecord && (
                    <div style={{ paddingRight: 15, overflowY: 'scroll' }}>
                      <NoteFeed
                        previewMode
                        hideCreateNoteForm
                        record={selectedAssignment?.linkedRecord!}
                        showOnlyNoteIds={[
                          selectedAssignment?.properties?.ParentNoteId || selectedAssignment.id,
                        ]}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Section>
        </Col>
      )}
    </Row>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
  navigationReducer: state.navigationReducer,
});
const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(InboxAssignmentsList);
