import { call, put, takeLatest } from 'redux-saga/effects';
import { httpGet } from '@core/http/requests';
import { ERROR_NOTIFICATION } from '../../stores/notification/reducers';
import { USER_LOGOUT_REQUEST } from '../../stores/identity/constants';
import {
  GET_INVESTIGATION_OVERVIEW_ERROR,
  GET_INVESTIGATION_OVERVIEW_REQUEST,
  GET_INVESTIGATION_OVERVIEW_SUCCESS,
  GET_ORDERS_OVERVIEW_ERROR,
  GET_ORDERS_OVERVIEW_REQUEST,
  GET_ORDERS_OVERVIEW_SUCCESS,
  GET_PIPELINES_OVERVIEW_ERROR,
  GET_PIPELINES_OVERVIEW_REQUEST,
  GET_PIPELINES_OVERVIEW_SUCCESS,
  GET_TRANSACTION_OVERVIEW_ERROR,
  GET_TRANSACTION_OVERVIEW_REQUEST,
  GET_TRANSACTION_OVERVIEW_SUCCESS,
} from './constants';

function* getPipelinesOverview(params: { moduleName?: string; entityName?: string }): any {
  const { moduleName, entityName } = params;
  try {
    let path = 'ConnectModule/v1.0/reporting/pipelines-overview';
    if (!!moduleName) {
      path = path + `?moduleName=${moduleName}`;
    }
    if (!!entityName) {
      path = path + `?entityName=${entityName}`;
    }

    const res = yield call(async () => await httpGet(path));

    yield put({ type: GET_PIPELINES_OVERVIEW_SUCCESS, results: res.data });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: GET_PIPELINES_OVERVIEW_ERROR });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error: e.response.data });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: e.response.data });
    }
  }
}

function* getOrdersOverview(action: { params: { orderStageKey: string } }): any {
  const { orderStageKey } = action.params;

  try {
    let path = `ConnectModule/v1.0/reporting/orders-overview?orderStageKey=${orderStageKey}`;
    const res = yield call(async () => await httpGet(path));
    yield put({ type: GET_ORDERS_OVERVIEW_SUCCESS, results: res.data });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: GET_ORDERS_OVERVIEW_ERROR });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error: e.response.data });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: e.response.data });
    }
  }
}

function* getInvestigationsOverview(): any {
  try {
    let path = `ConnectModule/v1.0/reporting/needs-investigating`;
    const res = yield call(async () => await httpGet(path));
    yield put({ type: GET_INVESTIGATION_OVERVIEW_SUCCESS, results: res.data });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: GET_INVESTIGATION_OVERVIEW_ERROR });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error: e.response.data });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: e.response.data });
    }
  }
}

function* getTransactionOverview(): any {
  try {
    let path = `ConnectModule/v1.0/reporting/bill-runs`;
    const res = yield call(async () => await httpGet(path));

    yield put({ type: GET_TRANSACTION_OVERVIEW_SUCCESS, results: res.data });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: GET_TRANSACTION_OVERVIEW_ERROR });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error: e.response.data });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: e.response.data });
    }
  }
}

function* rootSaga() {
  yield takeLatest<any>(GET_PIPELINES_OVERVIEW_REQUEST, getPipelinesOverview);
  yield takeLatest<any>(GET_ORDERS_OVERVIEW_REQUEST, getOrdersOverview);
  yield takeLatest<any>(GET_INVESTIGATION_OVERVIEW_REQUEST, getInvestigationsOverview);
  yield takeLatest<any>(GET_TRANSACTION_OVERVIEW_REQUEST, getTransactionOverview);
}

export default rootSaga;
