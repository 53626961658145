import { capitalCase } from 'change-case';

export function changeToCapitalCase(value: string | undefined) {
  if (value) {
    return capitalCase(value);
  }
}

export const chunkArray = (arr: any[], size: number) => {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size),
  );
};
