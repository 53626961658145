import { Button, NonIdealState, Section } from '@blueprintjs/core';
import SharedFormModal, {
  FormReducerSubmitEvt,
} from '@core/components/Forms/SharedForm/SharedFormModal';
import { httpGet, httpPost } from '@core/http/requests';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { initializeSharedForm, updateSharedFormInput } from '@redux/stores/sharedForm/actions';
import { SharedFormReducer } from '@redux/stores/sharedForm/reducer';
import { Col, Row, Skeleton } from 'antd';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { SchemaDetailsContext } from '../..';
import { pipelineFormFields } from './formFields';
import PipelineDetails from './PipelineDetails';

interface Props {
  schemaTypeId: string | null;
  formReducer: SharedFormReducer;
  initializeForm: any;
  updateForm: any;
}

const uuid = uuidv4();

const SchemaPipelineDetailView: FC<Props> = (props: Props) => {
  const { initializeForm, formReducer, updateForm } = props;
  const [pipelines, setPipelines] = useState<PipelineEntity[]>([]);
  const [isLoadingPipelines, setIsLoadingPipelines] = useState<boolean>(false);
  const [isCreatingPipelines, setIsCreatingPipelines] = useState<boolean>(false);
  const { schema } = useContext(SchemaDetailsContext);

  const prevNameRef = useRef(formReducer?.saveData?.name);
  const formRef = useRef<any>(null);

  // Every time user updates the pipeline name, autogenerate the key and update the form field
  useEffect(() => {
    const currentName = formReducer?.saveData?.name;
    if (currentName && currentName !== prevNameRef.current && !formReducer.isUpdateReq) {
      const words = currentName.replace(/[^a-zA-Z\s]/g, '').split(/\s+/);

      // Capitalize first letter of each word and lowercase the rest
      const formattedWords = words.map((word: string) => {
        if (word.length === 0) return '';
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
      });

      // Join all words together
      const key = formattedWords.join('');
      updateForm({
        property: 'key',
        value: key,
      });

      // Update key form field with formRef
      if (formRef.current) {
        formRef.current.setFieldsValue({
          key: key,
        });
      }

      prevNameRef.current = currentName;
    }
  }, [formReducer.saveData?.name]);

  // On component mount, fetch pipelines
  useEffect(() => {
    if (schema) {
      fetchData();
    }
  }, [schema]);

  const fetchData = () => {
    setIsLoadingPipelines(true);
    httpGet(`SchemaModule/v1.0/pipelines/list/${schema?.moduleName}/${schema?.entityName}`).then(
      (response) => {
        setIsLoadingPipelines(false);
        const pipelines = response.data?.data || [];
        setPipelines(pipelines);
      },
    );
  };

  const showCreatePipelineForm = () => {
    initializeForm({
      formUUID: uuid,
      title: 'Create Pipeline',
      showModal: true,
      formFields: pipelineFormFields(),
      entityName: schema?.entityName,
      isUpdateReq: false,
      isCreateReq: true,
      schema: schema,
    });
  };

  const handleCreatePipelineFormSubmit = (params: FormReducerSubmitEvt) => {
    if (schema && params.data && !formReducer.isUpdateReq) {
      setIsCreatingPipelines(true);
      const body: any = {
        name: params.data.name,
        key: params.data.key,
        isSequential: params.data.isSequential,
        description: params.data.description,
        moduleName: schema.moduleName,
        entityName: schema.entityName,
        schema: schema,
        schemaTypes:
          typeof params.data.schemaTypes === 'string'
            ? [params.data.schemaTypes]
            : params.data.schemaTypes,
      };

      httpPost(`SchemaModule/v1.0/pipelines`, body).then(() => {
        setIsCreatingPipelines(false);
        fetchData();
      });
    }
  };

  const renderPipelineSections = () => {
    return pipelines.map((pipeline: PipelineEntity, i: number) => {
      return (
        <PipelineDetails
          last={i === pipelines.length - 1}
          pipeline={pipeline}
          key={pipeline.id}
          schema={schema}
          disableDelete={pipelines.length === 1}
          onUpdate={() => fetchData()}
        />
      );
    });
  };

  return (
    <div className="pipeline-section">
      {/* Create/Edit Pipeline Form */}
      {formRef && uuid && (
        <SharedFormModal
          externalRef={formRef as any}
          formUUID={uuid}
          onSubmitEvent={(params: FormReducerSubmitEvt) => handleCreatePipelineFormSubmit(params)}
        />
      )}

      {/* Header */}
      <Section
        title="Pipelines"
        rightElement={
          <Button
            icon="plus"
            variant="outlined"
            style={{ borderRadius: 5 }}
            text="Add"
            intent="success"
            loading={isCreatingPipelines}
            onClick={showCreatePipelineForm}
          />
        }
      >
        {/* Loading Pipelines */}
        {isLoadingPipelines && (
          <Row>
            <Col span={24} style={{ padding: '30px 0' }}>
              <Skeleton active />
            </Col>
          </Row>
        )}

        {/* No Pipelines found */}
        {!isLoadingPipelines && pipelines.length === 0 && (
          <Row>
            <Col span={24} style={{ padding: '30px 0' }}>
              <NonIdealState
                icon="search"
                title={'No Pipeline Found'}
                description="Create new pipeline for this entity"
              />
            </Col>
          </Row>
        )}

        {/* Pipelines Found! */}
        {!isLoadingPipelines && pipelines.length > 0 && renderPipelineSections()}
      </Section>
    </div>
  );
};

const mapState = (state: any) => ({
  formReducer: state.formReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: SharedFormReducer) => dispatch(initializeSharedForm(params)),
  updateForm: (params: any) => dispatch(updateSharedFormInput(params)),
});

export default connect(mapState, mapDispatch)(SchemaPipelineDetailView);
