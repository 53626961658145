import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Empty } from 'antd';
import { FC, useContext } from 'react';
import { DetailViewContext } from '@core/components/DetailViewContextProvider';
import DetailView from '@core/views/DefaultDetailView';

const VisitDetailView: FC = () => {
  const { record } = useContext(DetailViewContext);

  const renderGoogleMapFromCoordinates = (record: DbRecordEntityTransform) => {
    if (getProperty(record, 'Coordinates')) {
      const split = getProperty(record, 'Coordinates').split(',');

      return (
        <a target="__blank" href={`http://maps.google.com/maps?q=${split[1]},${split[0]}`}>
          View in google
        </a>
      );
    } else {
      return <Empty />;
    }
  };

  return (
    <DetailView
      showCollaborators
      defaultTabKey="Map"
      customTabs={[
        {
          key: 'Map',
          tab: 'Map',
        },
      ]}
      customTabBodies={{
        Map: <div>{renderGoogleMapFromCoordinates(record)}</div>,
      }}
    />
  );
};

export default VisitDetailView;
