import { Alert, Button, FormGroup, Icon, InputGroup, Section } from '@blueprintjs/core';
import { SchemaActionDefinitionContext } from '@core/modules/ControlPanelModule/components/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration';
import SchemaActionNameSearch from '@core/modules/ControlPanelModule/components/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration/SchemaActionNameSearch';
import { Col, Row } from 'antd';
import React, { useContext, useState } from 'react';
import './styles.scss';

interface Props {
  dialogStep: any;
  stepNumber: number;
}

const FlowBuilderDialogStep: React.FC<Props> = (props: Props) => {
  const { dialogStep, stepNumber } = props;
  const [isRemoveStepAlertVisible, setIsRemoveStepAlertVisible] = useState<boolean>(false);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);

  const { parsedDefinition, setDefinition } = useContext(SchemaActionDefinitionContext);

  const stepName = dialogStep?.name;
  const linkType = dialogStep?.linkType || null;
  const description = dialogStep?.description || null;
  const schemaActions = dialogStep?.schemaActions || [];

  const removeDialogStep = () => {
    setIsRemoving(true);

    // 300ms delay for fade out animation
    setTimeout(() => {
      const newDialogSteps = parsedDefinition.dialogSteps?.filter(
        (step: any, index: number) => index !== stepNumber - 1,
      );
      const newParsedDefinition = {
        ...parsedDefinition,
        dialogSteps: newDialogSteps,
      };
      setDefinition(JSON.stringify(newParsedDefinition, null, 4));
      setIsRemoving(false);
    }, 300);
  };

  const addFormToDialogStep = () => {
    const newDialogSteps = parsedDefinition.dialogSteps?.map((step: any, index: number) => {
      if (index === stepNumber - 1) {
        return {
          ...step,
          schemaActions: [...step.schemaActions, ''],
        };
      }
      return step;
    });
    const newDefinition = {
      ...parsedDefinition,
      dialogSteps: newDialogSteps,
    };
    setDefinition(JSON.stringify(newDefinition, null, 4));
  };

  const updateStepValue = (index: number, key: any, value: string) => {
    // find a step with index and update step[key] with value
    const newDialogSteps = parsedDefinition.dialogSteps?.map((step: any, stepIndex: number) => {
      if (stepIndex === index) {
        return {
          ...step,
          [key]: value,
        };
      }
      return step;
    });
    const newDefinition = {
      ...parsedDefinition,
      dialogSteps: newDialogSteps,
    };
    setDefinition(JSON.stringify(newDefinition, null, 4));
  };

  const renderSchemaActions = () => {
    let actions: string[] = Object.assign([], schemaActions);

    const addFormToDialogStep = (newFormName: string, oldFormName: string | undefined) => {
      let forms = Object.assign([], dialogStep.schemaActions);

      // Remove empty strings
      forms = forms.filter((form: string) => form !== '');

      // Replace old form name with new form name
      if (oldFormName) {
        forms = forms.map((form: string) => {
          if (form === oldFormName) {
            return newFormName;
          }
          return form;
        });
      }
      // Or if adding new form
      else {
        forms.push(newFormName);
      }

      const newDialogSteps = parsedDefinition.dialogSteps?.map((step: any, index: number) => {
        if (index === stepNumber - 1) {
          return {
            ...step,
            schemaActions: forms,
          };
        }
        return step;
      });
      const newDefinition = {
        ...parsedDefinition,
        dialogSteps: newDialogSteps,
      };
      setDefinition(JSON.stringify(newDefinition, null, 4));
    };

    const removeFormFromDialogStep = (index: number) => {
      const newDialogSteps = parsedDefinition.dialogSteps?.map((step: any, stepIndex: number) => {
        if (stepIndex === stepNumber - 1) {
          return {
            ...step,
            schemaActions: step.schemaActions.filter(
              (form: string, formIndex: number) => formIndex !== index,
            ),
          };
        }
        return step;
      });
      const newDefinition = {
        ...parsedDefinition,
        dialogSteps: newDialogSteps,
      };
      setDefinition(JSON.stringify(newDefinition, null, 4));
    };

    return actions?.map((actionName: string, index: number) => {
      return (
        <Col span={24} key={`col1-forms-` + actionName}>
          <Row
            key={`row1-forms-` + actionName}
            style={{ marginTop: index > 0 ? 10 : 0 }}
            align="middle"
          >
            <Col span={22} key={`col2-forms-` + actionName}>
              <SchemaActionNameSearch
                onSelect={addFormToDialogStep}
                selectedSchemaActionName={actionName}
              />
            </Col>
            <Col span={2} style={{ textAlign: 'right' }} key={`col3-forms-` + actionName}>
              <Button
                icon="cross"
                minimal
                small
                intent="danger"
                style={{ borderRadius: 5 }}
                key={`button1-forms-` + actionName}
                onClick={() => removeFormFromDialogStep(index)}
              />
            </Col>
          </Row>
        </Col>
      );
    });
  };

  return (
    <Row
      align="middle"
      style={{ marginTop: 15 }}
      className={`builderDialogStep ${isRemoving ? 'removing' : ''}`}
      key={`dialogStep` + stepNumber}
    >
      <Col span={24}>
        <Row>
          <Col
            span={1}
            style={{
              textAlign: 'center',
              height: 'inherit',
              border: '1px solid #7f99bb',
              borderBottomLeftRadius: 8,
              borderTopLeftRadius: 8,
              borderRight: 0,
              background: '#869fc0',
            }}
          >
            <Row justify="center">
              <Col style={{ padding: '8px 4px', textAlign: 'center' }}>
                <Icon
                  icon="drag-handle-vertical"
                  color="white"
                  size={18}
                  style={{
                    cursor: 'move',
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={23} style={{ padding: '1px 0' }}>
            <Section
              compact
              title={`Step ${stepNumber}`}
              style={{ borderTopRightRadius: 8, borderBottomRightRadius: 8 }}
              rightElement={
                <Button
                  intent="danger"
                  minimal
                  small
                  icon="trash"
                  onClick={() => setIsRemoveStepAlertVisible(true)}
                />
              }
            >
              <div style={{ padding: 22 }}>
                <Row gutter={12}>
                  {/* Step Name */}
                  <Col span={16}>
                    <FormGroup label="Step Name" labelFor="step-name">
                      <InputGroup
                        key={`stepName-${stepNumber}`}
                        id="step-name"
                        autoComplete="off"
                        placeholder="Step Name"
                        defaultValue={stepName}
                        value={stepName}
                        onChange={(e) => {
                          updateStepValue(stepNumber - 1, 'name', e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {/* Link Type */}
                  <Col span={8}>
                    <FormGroup label="Link Type" labelFor="link-type">
                      <InputGroup
                        id="link-type"
                        key={`linkType-${stepNumber}`}
                        type="text"
                        placeholder="Link Type"
                        defaultValue={linkType}
                        value={linkType}
                        onChange={(e) =>
                          updateStepValue(stepNumber - 1, 'linkType', e.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>

                  {/* Description */}
                  <Col span={24}>
                    <FormGroup label="Description" labelFor="description">
                      <InputGroup
                        key={`description-${stepNumber}`}
                        id="description"
                        type="text"
                        value={description}
                        autoComplete="off"
                        placeholder="Description"
                        defaultValue={description}
                        onChange={(e) =>
                          updateStepValue(stepNumber - 1, 'description', e.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>

                {/* Forms (Schema Actions) */}
                <Row>
                  {schemaActions?.length! > 0 ? (
                    <Col span={24}>
                      <Row style={{ marginTop: 10 }}>
                        <Col span={24} style={{ marginBottom: 10 }}>
                          <span style={{ fontWeight: 600 }}>Forms</span>
                        </Col>
                        {renderSchemaActions()}
                      </Row>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>

                <Row>
                  <Col
                    span={24}
                    style={{ textAlign: 'center', marginTop: schemaActions?.length! > 0 ? 20 : 0 }}
                  >
                    <Button
                      intent="success"
                      icon="plus"
                      text="Add Form"
                      outlined={!schemaActions.includes('')}
                      minimal={schemaActions.includes('')}
                      onClick={addFormToDialogStep}
                      disabled={schemaActions.includes('')}
                    />
                  </Col>
                </Row>
              </div>
            </Section>
          </Col>
        </Row>
      </Col>

      {/* Remove Dialog Step */}
      <Alert
        intent="danger"
        canEscapeKeyCancel
        cancelButtonText="Cancel"
        confirmButtonText="Remove"
        isOpen={isRemoveStepAlertVisible}
        onClose={() => setIsRemoveStepAlertVisible(false)}
        onConfirm={removeDialogStep}
      >
        <p>Are you sure you want to remove Step {stepNumber}?</p>
      </Alert>
    </Row>
  );
};
export default FlowBuilderDialogStep;
