import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Layout, Row } from 'antd';
import React, { useEffect, useReducer, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { getRecordByIdRequest, IGetRecordById } from '../../../../../../redux/stores/records/actions';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '../../../../../../redux/stores/recordAssociations/actions';
import ModuleEntityIcon from 'src/core/components/ModuleEntityIcon';
import { getBrowserPath } from '@core/helpers/recordHelpers';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import { toSentenceCase } from '@core/helpers/stringHelpers';
import RecordSelectPanel from './RecordSelectPanel';
import RecordViewPanel from './RecordViewPanel';
import {
  SET_ASSOCIATED_DATASET_RECORDS,
  SET_IS_SEARCHING_DATASET_ASSOCIATIONS,
  SET_SELECTED_RECORD,
} from './store/constants';
import { dataSetWorkInitialState, dataSetWorkReducer } from './store/reducer';
import './styles.scss';
import { PageHeader } from 'src/core/components/PageHeader';
import { Button } from '@blueprintjs/core';

type TProps = RouteComponentProps<any> & {
  match: any;
  history: any;
  getRecordById: Function;
  getAssociations: Function;
};

// Polling data
let timer: NodeJS.Timeout | undefined = undefined;
const interval = 10000; // 10 seconds

const { CRM_MODULE } = SchemaModuleTypeEnums;

export const DataSetWorkContext = React.createContext<any>({});

const DataSetWork: React.FC<TProps> = (props: TProps) => {
  const { match, getRecordById, getAssociations } = props;
  const [state, dispatch] = useReducer(dataSetWorkReducer, dataSetWorkInitialState);
  const [dataSetSchema, setDataSetSchema] = useState<SchemaEntity | undefined>(undefined);

  // Configure timer
  const startTimer = () => {
    timer = setInterval(() => {
      if (!document.hidden && state.dataSetRecord) {
        getAllDataSetAssociations(state.dataSetRecord, true);
      }
    }, interval);
  };
  const clearTimer = () => {
    clearInterval(timer);
    timer = undefined;
  };

  // Start timer when DataSet record is available
  useEffect(() => {
    if (state.dataSetRecord) {
      startTimer();
    }
  }, [state.dataSetRecord]);
  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, []);

  // When schema shortlist updates, try to fetch all necessary schemas.
  useEffect(() => {
    if (!dataSetSchema) {
      getSchemas();
    }
  }, []);

  const getSchemas = async () => {
    const schema = await getOdinSchemaByEntity(CRM_MODULE, 'CrmDataset');
    setDataSetSchema(schema);
  };

  // On component mount, wait for CrmDataset Schema and fetch the record
  useEffect(() => {
    if (match.params?.recordId && dataSetSchema) {
      getRecordById(
        {
          schema: dataSetSchema,
          recordId: match.params?.recordId,
        },
        (response: DbRecordEntityTransform) => {
          if (response) {
            dispatch({ type: 'SET_DATASET_RECORD', payload: response });
            getAllDataSetAssociations(response);
          }
        },
      );
    }
  }, [dataSetSchema]);

  // Load all records associated with the DataSet.
  const getAllDataSetAssociations = (record: DbRecordEntityTransform, overrideLoader?: boolean) => {
    if (!overrideLoader) {
      dispatch({ type: SET_IS_SEARCHING_DATASET_ASSOCIATIONS, payload: true });
    }

    let associatedEntityName = record?.type ? toSentenceCase(record?.type) : 'Address';

    getAssociations(
      {
        recordId: record?.id,
        schema: dataSetSchema,
        entities: [associatedEntityName],
      },
      (res: any) => {
        if (res && res.results?.[associatedEntityName]?.dbRecords?.length > 0) {
          const associations = res.results?.[associatedEntityName]?.dbRecords;
          dispatch({ type: SET_IS_SEARCHING_DATASET_ASSOCIATIONS, payload: false });
          dispatch({ type: SET_ASSOCIATED_DATASET_RECORDS, payload: associations });
        } else {
          dispatch({ type: SET_IS_SEARCHING_DATASET_ASSOCIATIONS, payload: false });
        }
      },
    );
  };

  const refreshDataSetAssociations = (selectedRecord: DbRecordEntityTransform) => {
    let associatedEntityName = state.dataSetRecord?.type
      ? toSentenceCase(state.dataSetRecord?.type)
      : 'Address';

    getAssociations(
      {
        recordId: state.dataSetRecord?.id,
        schema: dataSetSchema,
        entities: [associatedEntityName],
      },
      (res: any) => {
        if (res && res.results?.[associatedEntityName]?.dbRecords?.length > 0) {
          const associations = res.results?.[associatedEntityName]?.dbRecords;

          const newSelectedRecord = associations?.find(
            (assoc: DbRecordEntityTransform) => assoc.id === selectedRecord.id,
          );

          // Replace old selected record with the new one
          dispatch({ type: SET_ASSOCIATED_DATASET_RECORDS, payload: associations });
          dispatch({ type: SET_SELECTED_RECORD, payload: newSelectedRecord });
        }
      },
    );
  };

  return (
    <DataSetWorkContext.Provider value={{ state, dispatch }}>
      <Layout style={{ padding: 15, marginTop: 5 }}>
        <Row gutter={12}>
          <Col span={24}>
            <PageHeader
              style={{ borderRadius: 6, border: '1px solid #dddbda' }}
              extra={
                <Col>
                  <Link to={`/${CRM_MODULE}/CrmDataset/${state.dataSetRecord?.id}/Build`}>
                    <Button
                      style={{ marginTop: 4 }}
                      large
                      intent="primary"
                      minimal
                      disabled={!state.dataSetRecord}
                      icon={<i className="bi bi-box-arrow-in-right" style={{ marginRight: 10 }} />}
                    >
                      Build Mode
                    </Button>
                  </Link>
                </Col>
              }
              title={
                <Row align="middle">
                  <Col style={{ marginTop: 8 }}>
                    <ModuleEntityIcon
                      moduleName={CRM_MODULE}
                      entityName="CrmDataset"
                      style={{ padding: '0px 5px' }}
                    />
                  </Col>
                  <Col style={{ paddingLeft: 8 }}>
                    <Row>
                      <Col span={24}>
                        <span style={{ fontSize: '1.1em' }}>
                          DataSet - Work Mode (
                          <Link to={getBrowserPath(state.dataSetRecord!)}>
                            {state.dataSetRecord?.recordNumber}
                          </Link>
                          )
                        </span>
                      </Col>
                      <Col span={24} style={{ lineHeight: '15px', margin: 0 }}>
                        <span style={{ fontSize: '14px', color: '#b9b9b9', fontWeight: 400 }}>
                          {state.dataSetRecord?.title}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }
              className="dataSetBuilderHeader"
            ></PageHeader>
          </Col>
          <Col span={24} style={{ marginTop: 15 }}>
            <Row gutter={16}>
              {/* Left Panel */}
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                <RecordSelectPanel />
              </Col>

              {/* Right Panel */}
              <Col xs={24} sm={24} md={12} lg={12} xl={16} style={{ marginTop: isMobile ? 20 : 0 }}>
                <RecordViewPanel onRecordUpdate={refreshDataSetAssociations} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    </DataSetWorkContext.Provider>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
});

export default withRouter(connect(mapState, mapDispatch)(DataSetWork));
