import { Classes, Drawer } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { closeRecordForm, initializeRecordForm } from '../../../../redux/stores/form/actions';
import { v4 as uuidv4 } from 'uuid';
import { Col, Row } from 'antd';
import CoreForm from '@core/components/Forms/CoreForm';
import { isMobile } from 'react-device-detect';
import { FormReducerSubmitEvt } from '@core/components/Forms/SharedForm/SharedFormModal';
import './styles.scss';

const uuid = uuidv4();

interface Props {
  isOpen: boolean;
  onDrawerClose: () => void;
  schema: SchemaEntity | undefined;
  sourceRecord: DbRecordEntityTransform;
  initializeForm: (params: any) => void;
  closeForm: () => void;
  onSuccess?: (id: string | number) => void;
}

// This component is used only in lookup inputs, as a way to create a new record
// directly from the input, if allowed by the schema association.
const LookupCreateRecordDrawer: React.FC<Props> = (props: Props) => {
  const { isOpen, onDrawerClose, schema, initializeForm, onSuccess } = props;

  const handleFormSubmit = (params: any) => {
    const createdRecordId = params?.results?.id;
    if (createdRecordId) {
      onSuccess && onSuccess(createdRecordId);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        initForm();
      }, 300);
    }
  }, [isOpen]);

  const initForm = () => {
    if (schema) {
      initializeForm({
        formUUID: uuid,
        isSecondaryForm: true,
        title: `Create ${schema.entityName}`,
        showFormModal: true,
        isCreateReq: true,
        isUpdateReq: false,
        schema: schema,
        selected: null,
        sections: [{ name: schema.name, schema: schema }],
      });
    }
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onDrawerClose}
      title={`Create ${schema?.entityName}`}
      usePortal={true}
      style={{ width: isMobile ? '98%' : '38%' }}
      className="lookupCreateRecordDrawer"
    >
      <Row className={Classes.DRAWER_BODY} style={{ padding: 15 }}>
        <Col span={24} style={{ paddingBottom: 30 }}>
          <CoreForm
            type="EMBEDDED"
            formUUID={uuid}
            isSecondaryForm
            showFormActions
            onCloseEvent={onDrawerClose}
            onCancelEvent={onDrawerClose}
            onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
          />
        </Col>
      </Row>
    </Drawer>
  );
};

const mapState = (state: any) => ({});
const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  closeForm: () => dispatch(closeRecordForm()),
});

export default connect(mapState, mapDispatch)(LookupCreateRecordDrawer);
