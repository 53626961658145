import dayjs from 'dayjs';

import { AppointmentView } from '../../context/types';

const isToday = (date: string) => dayjs().format('YYYY-MM-DD') === date;

const formatDate = (date: string) => <span>{dayjs(date).format('DD MMMM YYYY')}</span>;

const formatRange = (start: string, end: string) => (
  <div style={{ letterSpacing: '-1px' }}>
    <span>{dayjs(start).format('DD MMMM YYYY')}</span>
    <span style={{ margin: '0 8px' }}> - </span>
    <span>{dayjs(end).format('DD MMMM YYYY')}</span>
  </div>
);

export const formatDateFilter = (
  type: AppointmentView,
  dateFilter: { start: string; end: string },
) => {
  if (type === 'day') return isToday(dateFilter.start) ? 'Today' : formatDate(dateFilter.start);
  return formatRange(dateFilter.start, dateFilter.end);
};
