import { httpPost } from '@core/http/requests';
import { CaseChannel } from '@d19n/sandbox-odin-sdk/dist/entities-v2/Case';
import { useEffect, useState } from 'react';
import CardBase from './CardBase/CardBase';

type Props = {
  refreshBit: 0 | 1;
};
const ChatQueueCard = (props: Props) => {
  const [chatsInQueue, setChatsInQueue] = useState<number>(0);

  const getChatsInQueue = async () => {
    // TODO change this back to SupportModule as soon as schema manager is deployed
    const result = await httpPost(`SupportModule/v2.0/records/count`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Channel',
            operator: 'eq',
            value: CaseChannel.WEB_CHAT,
          },
          {
            columnName: 'OwnerId',
            operator: 'isNull',
            value: '',
          },
          {
            columnName: 'StageName',
            operator: 'eq',
            value: 'Open',
          },
        ],
      },
    });
    const count = result.data.data.totalRecords ?? 0;
    setChatsInQueue(count);
  };

  useEffect(() => {
    getChatsInQueue();
  }, [props.refreshBit]);

  return <CardBase title="Queueing" subTitle={chatsInQueue.toLocaleString('en-US')} />;
};

export default ChatQueueCard;
