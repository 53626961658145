import React, { useCallback } from 'react';
import { Col, Row } from 'antd';
import { Button, ButtonGroup, Popover } from '@blueprintjs/core';

import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';

import {
  useOdinSearchActions,
  useOdinSearchState,
} from '@core/modules/SupportModule/views/SupportDashboard/views/CaseManagementCaseListView/context/provider';

import { FilterDialog } from './FilterDialog';
import { ParamsDropdown } from './ParamsDropdown';
import { AppliedFilters } from './AppliedFilters';
import { GlobalFilters } from './GlobalFilters';

export type SearchV2Filter = {
  columnName: string;
  operator: string;
  value: string | string[];
};

export type OdinElasticFilter = {
  name?: string;
  values: SearchV2Filter[];
};

export type OdinElasticFilterProps = {
  schema?: SchemaEntity;
};

export const OdinElasticFilters: React.FC<OdinElasticFilterProps> = ({ schema }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  // Context state for filters
  const { selectedParams, savedParams } = useOdinSearchState();
  const { addFilter, saveParams, selectParams } = useOdinSearchActions();

  // Event handlers
  const handleOnConfirm = (filter: SearchV2Filter) => {
    addFilter(filter);
    setIsOpen(false);
  };

  const newParamsHandler = useCallback(() => {
    if (!schema?.id) return;

    const newParams = { name: 'New Filter', filters: [] };
    selectParams(newParams);
  }, [schema?.id, selectParams]);

  if (!schema) return null;

  const disableExtraActions = savedParams.length === 0 && !selectedParams;

  return (
    <>
      <Row style={{ marginBottom: 12 }}>
        <Col span={20} style={{ display: 'flex' }}>
          <ParamsDropdown />
          <GlobalFilters />
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <ButtonGroup>
            <Button
              style={{ borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }}
              icon="plus"
              onClick={() => setIsOpen(true)}
            >
              Add Filter
            </Button>
            <Popover
              position="bottom"
              content={
                <ButtonGroup vertical style={{ minWidth: 120 }}>
                  <Button icon="filter" onClick={newParamsHandler}>
                    New Filter
                  </Button>
                </ButtonGroup>
              }
            >
              <Button
                style={{ borderTopRightRadius: 5, borderBottomRightRadius: 5 }}
                icon="caret-down"
                disabled={disableExtraActions}
              />
            </Popover>
          </ButtonGroup>
        </Col>
      </Row>
      {!!selectedParams?.filters.length ? (
        <Row style={{ marginBottom: 12 }}>
          <Col span={24} style={{ display: 'flex' }}>
            <AppliedFilters />
          </Col>
        </Row>
      ) : null}
      <FilterDialog
        schema={schema}
        isOpen={isOpen}
        onConfirm={handleOnConfirm}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};
