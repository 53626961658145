import { Button, MenuItem, Spinner, Size } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import React from 'react';

import './index.scss'

type OdinDropdownProps = {
  placeholder: string;
  items: OdinDropdownOption[];
  selectedItem: OdinDropdownOption | null;
  onChange: (selected: OdinDropdownOption | null) => void;
  loading?: boolean;
  disabled?: boolean;
  id?: string;
  filterable?: boolean;
  size?: Size
  rounded?: boolean
  className?: string
  style?: React.CSSProperties
};
export type OdinDropdownOption = {
  id: string;
  text: string;
  alternativeText?: string;
};

const renderItem: ItemRenderer<OdinDropdownOption> = (
  item,
  { handleClick, handleFocus, modifiers },
) => {
  return (
    <MenuItem
      key={item.id}
      text={item.alternativeText ? item.alternativeText : item.text}
      roleStructure="listoption"
      active={modifiers.active}
      onClick={handleClick}
      onFocus={handleFocus}
    />
  );
};

export const OdinDropdown: React.FC<OdinDropdownProps> = ({
  placeholder,
  items,
  selectedItem,
  onChange,
  loading = false,
  disabled,
  id,
  filterable = true,
  size = 'small',
  rounded,
  className = '',
  style
}) => {
  return (
    <Select<OdinDropdownOption>
      // Items
      items={items}
      itemRenderer={renderItem}
      // Selection
      activeItem={selectedItem}
      onItemSelect={onChange}
      // Filter
      filterable={filterable}
      itemPredicate={(query, item) => item.text.toLowerCase().includes(query.toLowerCase())}
      className={`odin-dropdown ${className}`}
    >
      <Button
        id={id}
        text={selectedItem ? selectedItem.text : placeholder}
        endIcon={loading ? <Spinner size={16} /> : 'caret-down'}
        fill
        alignText="left"
        size={size}
        disabled={loading || disabled}
        className="dropdown__button"
        style={style ? style : { borderRadius: rounded ? 5 : 2 }}
      />
    </Select>
  );
};
