import { Row, Col } from 'antd';
import { DateTime } from 'luxon';
import dayjs from 'dayjs';
import { PipelineStageEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/stage/pipeline.stage.entity';

export interface BoardCardProps {
  cardFields: {
    id: string;
    rfcType: string;
    externalRef: string;
    exchangeName: string;
    description: string;
    assigneeName: string;
    dueDate: string;
    priority: string;
    stage: PipelineStageEntity;
    column: string;
  };
  selected?: boolean;
  dragging?: boolean;
  cardShape?: any;
}

const BoardCard: React.FC<BoardCardProps> = ({ cardFields, selected, dragging }) => {
  const isOverdue = () => {
    // Don't show overdue if the stage is Done (isSuccess) or Cancelled (isFail)
    if (cardFields.stage.isSuccess || cardFields.stage.isFail) {
      return false;
    } else {
      const dueDate = DateTime.fromISO(cardFields.dueDate);
      return dueDate < DateTime.now();
    }
  };

  return (
    <Row>
      <Col span={24} className="wx-card__heading">
        <span className="wx-card__title">
          {cardFields.externalRef} - {cardFields.exchangeName}
        </span>

        {cardFields.rfcType && <span className="wx-card__tag">{cardFields.rfcType}</span>}
      </Col>

      <Col span={24} className="wx-card__description">
        {cardFields.description}
      </Col>

      {(cardFields.assigneeName || cardFields.dueDate) && (
        <Col span={24} className="wx-card__tags">
          <div className="wx-card__tags-container">
            {cardFields.assigneeName && (
              <div className="wx-card__tag wx-card__tag--primary">{cardFields.assigneeName}</div>
            )}
          </div>

          <div className="wx-card__tags-container">
            {cardFields.priority && (
              <div className={`wx-card__tag wx-card__tag--${cardFields.priority.toLowerCase()}`}>
                {cardFields.priority}
              </div>
            )}
            {cardFields.dueDate && (
              <div className={`wx-card__tag ${isOverdue() ? 'wx-card__tag--overdue' : ''}`}>
                {dayjs(cardFields.dueDate).format('DD/MM/YYYY')}
              </div>
            )}
          </div>
        </Col>
      )}
    </Row>
  );
};

export default BoardCard;
