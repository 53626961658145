import { Button, Icon, Section } from '@blueprintjs/core';
import { doesUserHaveRoutesInTheAppMenu } from '@core/components/Navigation/helpers';
import Search from '@core/components/Search/Search';
import { storeSelectedEntity, storeSelectedModule } from '@redux/stores/navigation/actions';
import { ISearchRecords, searchRecordsRequest } from '@redux/stores/records/actions';
import { Col, Layout, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Typography from '../../components/Typography';
import './index.scss';

interface Props {
  userReducer: any;
  navigationReducer: any;
  storeSelectedModule: any;
  storeSelectedEntity: any;
  searchRecords: any;
}

class HomeView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  getFirstEntityFromModule = (moduleName: string) => {
    const { navigationReducer } = this.props;

    if (navigationReducer && navigationReducer.navigationStructure) {
      const targetedModule = navigationReducer.navigationStructure.find(
        (module: any) => module.moduleName === moduleName,
      );
      return targetedModule && targetedModule.entities.length > 0
        ? targetedModule.entities[0].entityName
        : '';
    }
  };

  renderModules = () => {
    const { storeSelectedModule, storeSelectedEntity, navigationReducer } = this.props;

    const menuModules = navigationReducer?.navigationStructure?.filter(
      (module: any) => module.showInApps,
    );

    return menuModules?.map((module: any) => (
      <Col xs={8} sm={8} md={3} lg={4} xl={4} key={module.moduleName} style={{ padding: 5 }}>
        <Link
          to={`/${module.moduleName}/${this.getFirstEntityFromModule(module.moduleName)}`}
          onClick={() => {
            storeSelectedModule({ selectedModule: module.moduleName });
            storeSelectedEntity({
              selectedEntity: this.getFirstEntityFromModule(module.moduleName),
            });
          }}
          className="topMenuAppLink"
        >
          <Button
            style={{
              width: '100%',
              textAlign: 'center',
              paddingTop: 20,
              borderRadius: 5,
            }}
          >
            <Col span={24}>{<Icon size={18} icon={module.icon} color="black" />}</Col>
            <Col span={24} style={{ paddingTop: 5, paddingBottom: 10, marginTop: 8 }}>
              <Typography strong>{module.moduleName.replace('Module', '')}</Typography>
            </Col>
          </Button>
        </Link>
      </Col>
    ));
  };

  render() {
    const { navigationReducer } = this.props;

    return (
      <Layout
        style={{
          padding: 8,
          borderTop: '1px solid #dadada',
          background: 'transparent',
          overflow: 'auto',
        }}
      >
        <Row justify="center" align="middle">
          {
            // Don't show this section if no navigation structure or every item in navigation structure has empty array in entities object. This is a common pattern in new organizations without schemas.
            doesUserHaveRoutesInTheAppMenu(navigationReducer?.navigationStructure) && (
              <Col xl={14} lg={24} sm={24} md={24} className="homePageContainer">
                <Section title="Applications">
                  <Row style={{ textAlign: 'center', padding: 10 }}>{this.renderModules()}</Row>
                </Section>
              </Col>
            )
          }

          <Col xl={14} lg={24} sm={24} md={24} className="homePageContainer">
            <Section title="Search">
              <Row style={{ padding: 20 }} justify="center">
                <Col xs={24} md={12} lg={12}>
                  <Search
                    entities={
                      window.location.host?.indexOf('netomnia') > -1
                        ? ['ProjectModule:Project', 'ProjectModule:Feature']
                        : ['CrmModule:Contact']
                    }
                    schema={{
                      id: 'GLOBAL_SEARCH_DRAWER',
                      moduleName: 'SchemaModule',
                      entityName: 'ALL',
                    }}
                    renderStyle="card"
                  />
                </Col>
              </Row>
            </Section>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapDispatch = (dispatch: any) => ({
  storeSelectedModule: (params: { selectedModule: string }) =>
    dispatch(storeSelectedModule(params)),
  storeSelectedEntity: (params: { selectedEntity: string }) =>
    dispatch(storeSelectedEntity(params)),
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
});

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  navigationReducer: state.navigationReducer,
});

export default connect(mapState, mapDispatch)(HomeView);
