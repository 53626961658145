import AgentCaseDashboard from '@core/modules/SupportModule/views/AgentCaseDashboard';
import AppointmentsCalendar from 'src/core/modules/CrmModule/components/AppointmentsCalendar';
import Autosplicing from '@netomnia/modules/ProjectModule/views/Autosplicing';
import BillingModuleDashboard from '@core/modules/BillingModule/views/Dashboard';
import BuildPackV2 from '@netomnia/modules/ProjectModule/views/BuildPack';
import BulkBuildComplete from '@netomnia/modules/ProjectModule/components/Openreach/BulkBuildComplete';
import ClosureConfigurator from '@netomnia/modules/ProjectModule/components/Feature/ClosureConfigurator';
import CrmModuleDashboard from 'src/core/modules/CrmModule/views/Dashboard';
import DataSetAssign from '@core/modules/CrmModule/components/DataSet/DataSetAssign';
import DataSetBuild from '@core/modules/CrmModule/components/DataSet/DataSetBuild';
import DataSetWork from '@core/modules/CrmModule/components/DataSet/DataSetWork';
import DetailViewContext from '@core/components/DetailViewContext';
import FieldServiceModuleDashboard from '@core/modules/FieldServiceModule/views/Dashboard';
import HtmlContentViewWithSlate from '@core/modules/SupportModule/views/KnowledgeArticleView/KnowledgeArticleView';
import Inventory from '@netomnia/modules/ProjectModule/components/Openreach/Inventory';
import InvoiceBuilder from 'src/core/modules/OrderModule/views/InvoiceBuilder';
import JSONGraphBrowser from '@netomnia/modules/ProjectModule/components/JSONGraphBrowser';
import LinearIssues from '@core/modules/SupportModule/views/LinearIssues';
import NetomniaDetailViewRouter from '@netomnia/routes/NetomniaDetailViewRouter';
import Noi from '@netomnia/modules/ProjectModule/components/Openreach/NoticeOfIntent';
import OfferDetailView from 'src/core/modules/ProductModule/views/OfferDetailView';
import OpenreachNetworkAdjustmentDetail from '@netomnia/modules/ProjectModule/components/OpenreachNetworkAdjustmentDetail';
import OrderBuilder from '@core/modules/OrderModule/OrderBuilder';
import OrderItemReplaceProductView from '@core/modules/OrderModule/components/OrderItemReplaceProductView';
import OrderModuleDashboard from '@core/modules/OrderModule/views/Dashboard';
import OutageList from '@core/modules/ServiceModule/OutageList';
import PlanningModuleMap from '@netomnia/modules/ProjectModule/views/Map';
import PriceBookDetailView from 'src/core/modules/ProductModule/views/PriceBookDetailView';
import ProductDetailView from 'src/core/modules/ProductModule/views/ProductDetailView';
import ProjectModuleApplicationForPaymentApprovals from '@netomnia/modules/ProjectModule/components/ProjectModuleApplicationForPaymentApprovals';
import ProjectModuleCableConnectionDetailViewWide from '@netomnia/modules/ProjectModule/views/ProjectModuleCableConnectionDetailViewWide';
import ProjectModuleDashboard from '@netomnia/modules/ProjectModule/views/Dashboard';
import ProjectModuleRecordDetailViewWide from '@netomnia/modules/ProjectModule/views/ProjectModuleRecordDetailViewWide';
import ProtectedRoute from '@core/components/ProtectedRoute';
import RFCManagementViewV2 from '@netomnia/modules/ProjectModule/views/RFCManagementViewV2';
import RecordListView from '@core/components/ListView';
import RelatedRecordDetailView from '@core/modules/SupportModule/views/RelatedRecordDetailView';
import SalesDashboard from '@core/components/SalesDashboard/SalesDashboard';
import SupportDashboard from '@core/modules/SupportModule/views/SupportDashboard';
import VendorDashboard from '@netomnia/modules/ProjectModule/views/VendorDashboard';
import WorkOrderRemediationView from 'src/core/modules/FieldServiceModule/views/WorkOrderRemediationDashboard';
import { ReactNode } from 'react';
import { Route, Switch } from 'react-router-dom';
import { SalesLeaderBoard } from 'src/core/modules/CrmModule/views/SalesLeaderBoard';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { ServiceAppointmentCalendar } from 'src/core/modules/FieldServiceModule/views/ServiceAppointmentCalendar';
import { TelesalesDashboard } from 'src/core/modules/CrmModule/views/TelesalesDashboard';

const {
  ORDER,
  ORDER_ITEM,
  PRODUCT,
  PROGRAM,
  PROJECT,
  REGION,
  WORK_ORDER,
  ACCOUNT,
  ADDRESS,
  CONTACT,
  LEAD,
  INVOICE,
  INVOICE_ITEM,
  TRANSACTION,
  PAYMENT_METHOD,
  CREDIT_NOTE,
  BILLING_REQUEST,
  BILLING_ADJUSTMENT,
  SERVICE_APPOINTMENT_CONFIG,
} = SchemaModuleEntityTypeEnums;
const {
  PRODUCT_MODULE,
  PROJECT_MODULE,
  FIELD_SERVICE_MODULE,
  CRM_MODULE,
  IDENTITY_MODULE,
  ORDER_MODULE,
  BILLING_MODULE,
  NOTIFICATION_MODULE,
  SUPPORT_MODULE,
  SERVICE_MODULE,
} = SchemaModuleTypeEnums;

const EXCHANGE = 'Exchange';
const PAYMENT_METHOD_REFUND = 'PaymentMethodRefund';
const KNOWLEDGE_ARTICLE = 'KnowledgeArticle';

// Warning: This router is used in sandbox and development environments.
const NetomniaRoutes = (): ReactNode[] => [
  /* NOTIFICATION MODULE */
  <Route path="/NotificationModule">
    <Switch>
      <ProtectedRoute
        exact
        path={`/${NOTIFICATION_MODULE}/:entityName`}
        moduleName={NOTIFICATION_MODULE}
        component={<RecordListView moduleName={NOTIFICATION_MODULE} />}
      />
      <ProtectedRoute
        exact
        path={`/${NOTIFICATION_MODULE}/:entityName/:recordId`}
        moduleName={NOTIFICATION_MODULE}
        component={
          <DetailViewContext moduleName={NOTIFICATION_MODULE}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
    </Switch>
  </Route>,

  /* SUPPORT MODULE */
  <Route path="/SupportModule">
    <Switch>
      <ProtectedRoute
        exact
        path={`/${SUPPORT_MODULE}/LinearIssue`}
        moduleName={SUPPORT_MODULE}
        entityName="LinearIssue"
        component={<LinearIssues />}
      />

      <ProtectedRoute
        exact
        path={`/${SUPPORT_MODULE}/Dashboard`}
        moduleName={SUPPORT_MODULE}
        entityName="Dashboard"
        component={<SupportDashboard />}
      />

      <ProtectedRoute
        exact
        path={`/${SUPPORT_MODULE}/${KNOWLEDGE_ARTICLE}/:recordId`}
        moduleName={SUPPORT_MODULE}
        entityName={KNOWLEDGE_ARTICLE}
        component={
          <DetailViewContext moduleName={SUPPORT_MODULE} entityName={KNOWLEDGE_ARTICLE}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${SUPPORT_MODULE}/${KNOWLEDGE_ARTICLE}/:recordId/view`}
        moduleName={SUPPORT_MODULE}
        entityName={KNOWLEDGE_ARTICLE}
        component={
          <DetailViewContext moduleName={SUPPORT_MODULE} entityName={KNOWLEDGE_ARTICLE}>
            <HtmlContentViewWithSlate />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${SUPPORT_MODULE}/CaseQueueListView`}
        moduleName={SUPPORT_MODULE}
        entityName="CaseQueueListView"
        component={<AgentCaseDashboard />}
      />
      <ProtectedRoute
        exact
        path={`/${SUPPORT_MODULE}/Case/:recordId`}
        moduleName={SUPPORT_MODULE}
        entityName="Case"
        component={
          <DetailViewContext moduleName={SUPPORT_MODULE} entityName="Case">
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${SUPPORT_MODULE}/:entityName`}
        moduleName={SUPPORT_MODULE}
        component={<RecordListView moduleName={SUPPORT_MODULE} />}
      />
      <ProtectedRoute
        exact
        path={`/${SUPPORT_MODULE}/:entityName/:recordId`}
        moduleName={SUPPORT_MODULE}
        component={
          <DetailViewContext moduleName={SUPPORT_MODULE}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
    </Switch>
  </Route>,

  /* PRODUCT MODULE */
  <Route path="/ProductModule">
    <Switch>
      <ProtectedRoute
        exact
        path={`/${PRODUCT_MODULE}/PriceBook/:recordId`}
        moduleName={PRODUCT_MODULE}
        entityName="PriceBook"
        component={
          <DetailViewContext moduleName={PRODUCT_MODULE} entityName="PriceBook">
            <PriceBookDetailView />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PRODUCT_MODULE}/Offer/:recordId`}
        moduleName={PRODUCT_MODULE}
        entityName="Offer"
        component={
          <DetailViewContext moduleName={PRODUCT_MODULE} entityName="Offer">
            <OfferDetailView />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PRODUCT_MODULE}/Product/:recordId`}
        moduleName={PRODUCT_MODULE}
        entityName="Product"
        component={
          <DetailViewContext moduleName={PRODUCT_MODULE} entityName="Product">
            <ProductDetailView
              hasColumnMappings={false}
              excludeRelations={[
                'Lead',
                'OrderItem',
                'WorkOrder',
                'Task',
                'ConsumptionSchedule',
                'Feature',
              ]}
            />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/:relatedRecordModuleName/:relatedRecordEntityName/:relatedRecordId/related/:dbRecordAssociationId/${PRODUCT_MODULE}/Product/:recordId`}
        moduleName={PRODUCT_MODULE}
        entityName="Product"
        component={
          <DetailViewContext moduleName={PRODUCT_MODULE} entityName="Product">
            <ProductDetailView
              hasColumnMappings={true}
              excludeRelations={[
                'Lead',
                'OrderItem',
                'WorkOrder',
                'Task',
                'ConsumptionSchedule',
                'Feature',
              ]}
              visibleProperties={[
                'ContractType',
                'UnitPrice',
                'DiscountValue',
                'DiscountLength',
                'DiscountType',
                'DiscountUnit',
                'TrialLength',
                'TrialUnit',
                'IntervalUnit',
                'IntervalLength',
                'PromoTextLabelDown',
                'PromoTextLabelUp',
                'DisplayName',
                'IsTrialCancelable',
                'LegalTerms',
                'Description',
                'Discount2Value',
                'Discount2Length',
                'Discount2Type',
                'Discount2Unit',
                'TermsAndConditions',
              ]}
              relatedProduct
            />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/:relatedRecordModuleName/:relatedRecordEntityName/:relatedRecordId/related/:dbRecordAssociationId/${PRODUCT_MODULE}/ProductComponent/:recordId`}
        moduleName={PRODUCT_MODULE}
        entityName="Product"
        component={
          <DetailViewContext moduleName={PRODUCT_MODULE} entityName="Product">
            <RelatedRecordDetailView
              excludeRelations={['Lead', 'OrderItem', 'WorkOrder', 'Task', 'ConsumptionSchedule']}
              visibleProperties={['Quantity']}
            />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PRODUCT_MODULE}/:entityName`}
        moduleName={PRODUCT_MODULE}
        component={<RecordListView moduleName={PRODUCT_MODULE} />}
      />
      <ProtectedRoute
        exact
        path={`/${PRODUCT_MODULE}/:entityName/:recordId`}
        moduleName={PRODUCT_MODULE}
        component={
          <DetailViewContext moduleName={PRODUCT_MODULE}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
    </Switch>
  </Route>,

  /* ORDER MODULE */
  <Route path="/OrderModule">
    <Switch>
      <ProtectedRoute
        exact
        path={`/${ORDER_MODULE}/OrderBuilder/`}
        moduleName={ORDER_MODULE}
        entityName={'Order'}
        component={
          <div style={{ padding: 15 }}>
            <OrderBuilder />
          </div>
        }
      />
      <ProtectedRoute
        exact
        path={'/OrderModule/Dashboard'}
        moduleName={ORDER_MODULE}
        entityName="Dashboard"
        component={<OrderModuleDashboard />}
      />
      <ProtectedRoute
        exact
        path={`/OrderModule/Order/:recordId`}
        moduleName={ORDER_MODULE}
        entityName="Order"
        component={
          <DetailViewContext moduleName={ORDER_MODULE} entityName={ORDER}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/OrderModule/BillingAdjustment/:recordId`}
        moduleName={ORDER_MODULE}
        entityName={BILLING_ADJUSTMENT}
        component={
          <DetailViewContext moduleName={ORDER_MODULE} entityName={BILLING_ADJUSTMENT}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/OrderModule/ChurnRequest/:recordId`}
        moduleName={ORDER_MODULE}
        entityName={'ChurnRequest'}
        component={
          <DetailViewContext moduleName={ORDER_MODULE} entityName={'ChurnRequest'}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/OrderModule/PICRequest/:recordId`}
        moduleName={ORDER_MODULE}
        entityName={'PICRequest'}
        component={
          <DetailViewContext moduleName={ORDER_MODULE} entityName={'PICRequest'}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />

      <ProtectedRoute
        exact
        path={`/OrderModule/OrderItem/:recordId`}
        moduleName={ORDER_MODULE}
        entityName={ORDER_ITEM}
        component={
          <DetailViewContext moduleName={ORDER_MODULE} entityName={ORDER_ITEM}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/OrderModule/OrderItem/:recordId/w`}
        moduleName={ORDER_MODULE}
        entityName="OrderItem"
        component={
          <DetailViewContext moduleName={ORDER_MODULE} entityName={ORDER_ITEM}>
            <OrderItemReplaceProductView />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/OrderModule/:entityName`}
        moduleName={ORDER_MODULE}
        component={<RecordListView moduleName={ORDER_MODULE} />}
      />
      <ProtectedRoute
        exact
        path={`/OrderModule/:entityName/:recordId`}
        moduleName={ORDER_MODULE}
        component={
          <DetailViewContext moduleName={ORDER_MODULE}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/:relatedRecordModuleName/:relatedRecordEntityName/:relatedRecordId/related/:dbRecordAssociationId/${PRODUCT_MODULE}/${PRODUCT}/:recordId`}
        moduleName={PRODUCT_MODULE}
        entityName={PRODUCT}
        component={
          <DetailViewContext moduleName={PRODUCT_MODULE} entityName={PRODUCT}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/OrderModule/Order/:recordId/invoice`}
        moduleName={ORDER_MODULE}
        entityName={ORDER}
        component={<InvoiceBuilder moduleName={ORDER_MODULE} entityName={ORDER} />}
      />
    </Switch>
  </Route>,

  /* AUDIT MODULE */
  <Route path="/AuditModule">
    <Switch>
      <ProtectedRoute
        exact
        path={`/AuditModule/:entityName`}
        moduleName={'AuditModule'}
        entityName="ActivityLog"
        component={<RecordListView moduleName={'AuditModule'} />}
      />
      <ProtectedRoute
        exact
        path={`/AuditModule/:entityName/:recordId`}
        moduleName={'AuditModule'}
        entityName="ActivityLog"
        component={
          <DetailViewContext moduleName={'AuditModule'} entityName={'ActivityLog'}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
    </Switch>
  </Route>,

  /* CRM MODULE */
  <Route path="/CrmModule">
    <Switch>
      <ProtectedRoute
        exact
        path={`/CrmModule/Dashboard`}
        moduleName={CRM_MODULE}
        entityName="Dashboard"
        component={<CrmModuleDashboard />}
      />
      <ProtectedRoute
        exact
        path="/CrmModule/SalesLeaderBoard"
        moduleName={CRM_MODULE}
        entityName="SalesLeaderBoard"
        component={<SalesLeaderBoard />}
      />
      <ProtectedRoute
        exact
        path="/CrmModule/TelesalesDashboard"
        moduleName={CRM_MODULE}
        entityName="TelesalesDashboard"
        component={<TelesalesDashboard />}
      />
      <ProtectedRoute
        exact
        path={`/${CRM_MODULE}/AppointmentCalendar`}
        moduleName={CRM_MODULE}
        entityName="TelesalesDashboard"
        component={<AppointmentsCalendar />}
      />
      <ProtectedRoute
        exact
        path={`/CrmModule/Visit/:recordId`}
        moduleName={CRM_MODULE}
        entityName="Visit"
        component={
          <DetailViewContext moduleName={CRM_MODULE} entityName="Visit">
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/CrmModule/Lead/:recordId`}
        moduleName={CRM_MODULE}
        entityName="Lead"
        component={
          <DetailViewContext moduleName={CRM_MODULE} entityName={LEAD}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/CrmModule/Account/:recordId`}
        moduleName={CRM_MODULE}
        entityName="Account"
        component={
          <DetailViewContext moduleName={CRM_MODULE} entityName={ACCOUNT}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/CrmModule/Contact/:recordId`}
        moduleName={CRM_MODULE}
        entityName="Contact"
        component={
          <DetailViewContext moduleName={CRM_MODULE} entityName={CONTACT}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${CRM_MODULE}/CrmDataset/Assign/`}
        moduleName={CRM_MODULE}
        accessPermissions={['crmmodule.crmdataset.assign.read']}
        component={<DataSetAssign />}
      />
      <ProtectedRoute
        exact
        path={`/CrmModule/CrmDataSet/:recordId`}
        moduleName={CRM_MODULE}
        entityName="CrmDataset"
        component={
          <DetailViewContext moduleName={CRM_MODULE} entityName={'CrmDataset'}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/CrmModule/Address/:recordId`}
        moduleName={CRM_MODULE}
        entityName="Address"
        component={
          <DetailViewContext moduleName={CRM_MODULE} entityName={ADDRESS}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/:relatedRecordModuleName/:relatedRecordEntityName/:relatedRecordId/related/:dbRecordAssociationId/CrmModule/Contact/:recordId/`}
        moduleName={CRM_MODULE}
        entityName="Contact"
        component={
          <DetailViewContext moduleName={CRM_MODULE} entityName="Contact">
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${CRM_MODULE}/:entityName`}
        moduleName={CRM_MODULE}
        component={<RecordListView moduleName={CRM_MODULE} />}
      />
      <ProtectedRoute
        exact
        path={`/${CRM_MODULE}/CrmDataset/:recordId/Build`}
        moduleName={CRM_MODULE}
        entityName="CrmDataset"
        component={<DataSetBuild />}
      />
      <ProtectedRoute
        exact
        path={`/${CRM_MODULE}/CrmDataset/:recordId/Work`}
        moduleName={CRM_MODULE}
        entityName="CrmDataset"
        component={<DataSetWork />}
      />
      <ProtectedRoute
        exact
        path={`/${CRM_MODULE}/:entityName/:recordId`}
        moduleName={CRM_MODULE}
        component={
          <DetailViewContext moduleName={CRM_MODULE}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
    </Switch>
  </Route>,

  /* FIELD SERVICE MODULE */
  <Route path="/FieldServiceModule">
    <Switch>
      <ProtectedRoute
        exact
        path={`/${FIELD_SERVICE_MODULE}/Dashboard`}
        moduleName={FIELD_SERVICE_MODULE}
        entityName="Dashboard"
        component={<FieldServiceModuleDashboard />}
      />
      <ProtectedRoute
        exact
        path={`/${FIELD_SERVICE_MODULE}/WorkOrder/:recordId`}
        moduleName={FIELD_SERVICE_MODULE}
        entityName="WorkOrder"
        component={
          <DetailViewContext moduleName={FIELD_SERVICE_MODULE} entityName={WORK_ORDER}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${FIELD_SERVICE_MODULE}/Calendar`}
        moduleName={FIELD_SERVICE_MODULE}
        entityName="WorkOrder"
        component={<ServiceAppointmentCalendar />}
      />
      <ProtectedRoute
        exact
        path={`/${FIELD_SERVICE_MODULE}/ServiceAppointmentConfig/:recordId`}
        moduleName={FIELD_SERVICE_MODULE}
        entityName="ServiceAppointmentConfig"
        component={
          <DetailViewContext
            moduleName={FIELD_SERVICE_MODULE}
            entityName={SERVICE_APPOINTMENT_CONFIG}
            skipLoadingUserActivity
          >
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${FIELD_SERVICE_MODULE}/PermissionToFail/:recordId`}
        moduleName={FIELD_SERVICE_MODULE}
        entityName="PermissionToFail"
        component={
          <DetailViewContext moduleName={FIELD_SERVICE_MODULE} entityName={'PermissionToFail'}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${FIELD_SERVICE_MODULE}/WorkOrderRemediationView`}
        moduleName={FIELD_SERVICE_MODULE}
        entityName="WorkOrderRemediationView"
        component={<WorkOrderRemediationView />}
      />
      <ProtectedRoute
        exact
        path={`/${FIELD_SERVICE_MODULE}/:entityName`}
        moduleName={FIELD_SERVICE_MODULE}
        component={<RecordListView moduleName={FIELD_SERVICE_MODULE} />}
      />
      <ProtectedRoute
        exact
        path={`/${FIELD_SERVICE_MODULE}/:entityName/:recordId`}
        moduleName={FIELD_SERVICE_MODULE}
        component={
          <DetailViewContext moduleName={FIELD_SERVICE_MODULE}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${FIELD_SERVICE_MODULE}/${WORK_ORDER}/:recordId/invoice`}
        moduleName={FIELD_SERVICE_MODULE}
        entityName={WORK_ORDER}
        component={<InvoiceBuilder moduleName={FIELD_SERVICE_MODULE} entityName={WORK_ORDER} />}
      />
    </Switch>
  </Route>,

  /* BILLING MODULE */
  <Route path="/BillingModule">
    <Switch>
      <ProtectedRoute
        exact
        path={`/${BILLING_MODULE}/Dashboard`}
        moduleName={BILLING_MODULE}
        entityName="Dashboard"
        component={<BillingModuleDashboard />}
      />
      <ProtectedRoute
        exact
        path={`/${BILLING_MODULE}/${INVOICE}/:recordId`}
        moduleName={BILLING_MODULE}
        entityName="Invoice"
        component={
          <DetailViewContext moduleName={BILLING_MODULE} entityName={INVOICE}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${BILLING_MODULE}/${TRANSACTION}/:recordId`}
        moduleName={BILLING_MODULE}
        entityName={TRANSACTION}
        component={
          <DetailViewContext moduleName={BILLING_MODULE} entityName={TRANSACTION}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${BILLING_MODULE}/${PAYMENT_METHOD_REFUND}/:recordId`}
        moduleName={BILLING_MODULE}
        entityName={PAYMENT_METHOD_REFUND}
        component={
          <DetailViewContext moduleName={BILLING_MODULE} entityName={PAYMENT_METHOD_REFUND}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${BILLING_MODULE}/${INVOICE_ITEM}/:recordId`}
        moduleName={BILLING_MODULE}
        entityName={INVOICE_ITEM}
        component={
          <DetailViewContext moduleName={BILLING_MODULE} entityName={INVOICE_ITEM}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${BILLING_MODULE}/${BILLING_REQUEST}/:recordId`}
        moduleName={BILLING_MODULE}
        entityName={BILLING_REQUEST}
        component={
          <DetailViewContext moduleName={BILLING_MODULE} entityName={BILLING_REQUEST}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${BILLING_MODULE}/PaymentMethod/:recordId`}
        moduleName={BILLING_MODULE}
        entityName="PaymentMethod"
        component={
          <DetailViewContext moduleName={BILLING_MODULE} entityName={PAYMENT_METHOD}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${BILLING_MODULE}/${CREDIT_NOTE}/:recordId`}
        moduleName={BILLING_MODULE}
        entityName={CREDIT_NOTE}
        component={
          <DetailViewContext moduleName={BILLING_MODULE} entityName={CREDIT_NOTE}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${BILLING_MODULE}/:entityName`}
        moduleName={BILLING_MODULE}
        component={<RecordListView moduleName={BILLING_MODULE} />}
      />
      <ProtectedRoute
        exact
        path={`/${BILLING_MODULE}/:entityName/:recordId`}
        moduleName={BILLING_MODULE}
        component={
          <DetailViewContext moduleName={BILLING_MODULE}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
    </Switch>
  </Route>,

  /* IDENTITY MODULE */
  <Route path="/IdentityModule">
    <Switch>
      <ProtectedRoute
        path={`/${IDENTITY_MODULE}/User/:recordId`}
        moduleName={IDENTITY_MODULE}
        entityName="User"
        component={
          <DetailViewContext moduleName={IDENTITY_MODULE} entityName={'User'}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${IDENTITY_MODULE}/:entityName`}
        moduleName={IDENTITY_MODULE}
        component={<RecordListView moduleName={IDENTITY_MODULE} />}
      />
      <ProtectedRoute
        exact
        path={`/${IDENTITY_MODULE}/:entityName/:recordId`}
        moduleName={IDENTITY_MODULE}
        component={
          <DetailViewContext moduleName={IDENTITY_MODULE}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
    </Switch>
  </Route>,

  /* SERVICE MODULE */
  <Route path="/ServiceModule">
    <Switch>
      <ProtectedRoute
        exact
        path={`/${SERVICE_MODULE}`}
        moduleName={SERVICE_MODULE}
        entityName="Service"
        component={<RecordListView moduleName={SERVICE_MODULE} entityName="Service" />}
      />
      <ProtectedRoute
        exact
        path={`/${SERVICE_MODULE}/Outage`}
        moduleName={SERVICE_MODULE}
        entityName="Outage"
        component={<OutageList />}
      />
      <ProtectedRoute
        exact
        path={`/${SERVICE_MODULE}/:entityName`}
        moduleName={SERVICE_MODULE}
        component={<RecordListView moduleName={SERVICE_MODULE} />}
      />
      <ProtectedRoute
        exact
        path={`/${SERVICE_MODULE}/:entityName/:recordId`}
        moduleName={SERVICE_MODULE}
        component={
          <DetailViewContext moduleName={SERVICE_MODULE}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      ]
    </Switch>
  </Route>,

  /* PROJECT MODULE */
  <Route path="/ProjectModule">
    <Switch>
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/Connection/RawData`}
        moduleName={PROJECT_MODULE}
        entityName="Connection"
        component={<JSONGraphBrowser />}
      />
      {/* NETOMNIA ProjectModule Routes */}
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/Connection/:closureId?/:cableId?`}
        moduleName={PROJECT_MODULE}
        entityName="Connection"
        component={<Autosplicing />}
      />
      ,
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/Autosplicing/:closureId?/:cableId?`}
        moduleName={PROJECT_MODULE}
        entityName="Map"
        component={<Autosplicing />}
      />
      ,
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/VendorDashboard`}
        moduleName={PROJECT_MODULE}
        entityName="VendorDashboard"
        component={<VendorDashboard />}
      />
      {/* END NETOMNIA ProjectModule Routes */}
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/Dashboard`}
        moduleName={PROJECT_MODULE}
        entityName="Dashboard"
        component={<ProjectModuleDashboard />}
      />
      ,
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/Connection`}
        moduleName={PROJECT_MODULE}
        entityName="Connection"
        component={<Autosplicing />}
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/${REGION}/:recordId`}
        moduleName={PROJECT_MODULE}
        entityName="Region"
        component={
          <DetailViewContext moduleName={PROJECT_MODULE} entityName={REGION}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/FeaturePriceEntry/:recordId`}
        moduleName={PROJECT_MODULE}
        entityName="FeaturePriceEntry"
        component={
          <DetailViewContext moduleName={PROJECT_MODULE} entityName={'FeaturePriceEntry'}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/Feature/:recordId`}
        moduleName={PROJECT_MODULE}
        entityName="Feature"
        component={
          <DetailViewContext moduleName={PROJECT_MODULE} entityName={'Feature'}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/${EXCHANGE}/:recordId`}
        moduleName={PROJECT_MODULE}
        entityName="Exchange"
        component={
          <DetailViewContext moduleName={PROJECT_MODULE} entityName={EXCHANGE}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/${PROGRAM}/:recordId`}
        moduleName={PROJECT_MODULE}
        entityName="Program"
        component={
          <DetailViewContext moduleName={PROJECT_MODULE} entityName={PROGRAM}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/${PROJECT}/:recordId`}
        moduleName={PROJECT_MODULE}
        entityName="Project"
        component={
          <DetailViewContext moduleName={PROJECT_MODULE} entityName={PROJECT}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/Feature/:recordId`}
        moduleName={PROJECT_MODULE}
        entityName="Feature"
        component={
          <DetailViewContext moduleName={PROJECT_MODULE} entityName={'Feature'}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/Feature/:recordId/configure-closure`}
        moduleName={PROJECT_MODULE}
        entityName="Feature"
        component={
          <DetailViewContext moduleName={PROJECT_MODULE} entityName={'Feature'}>
            <ClosureConfigurator />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/ChangeRequest/:recordId`}
        moduleName={PROJECT_MODULE}
        entityName="ChangeRequest"
        component={
          <DetailViewContext moduleName={PROJECT_MODULE} entityName="ChangeRequest">
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/OpenreachNoi`}
        moduleName={PROJECT_MODULE}
        entityName="OpenreachNoi"
        component={<Noi />}
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/OpenreachInventory`}
        moduleName={PROJECT_MODULE}
        entityName="OpenreachInventory"
        component={<Inventory />}
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/OpenreachNetworkAdjustment/:recordId`}
        moduleName={PROJECT_MODULE}
        entityName="OpenreachNetworkAdjustment"
        component={
          <DetailViewContext moduleName={PROJECT_MODULE} entityName={'OpenreachNetworkAdjustment'}>
            <OpenreachNetworkAdjustmentDetail defaultTabKey={'OrderDetails'} />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/BuildComplete/:recordId`}
        moduleName={PROJECT_MODULE}
        entityName="BuildComplete"
        component={
          <DetailViewContext moduleName={PROJECT_MODULE} entityName={'BuildComplete'}>
            <ProjectModuleRecordDetailViewWide moduleName={PROJECT_MODULE} />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/OpenreachBuildComplete`}
        moduleName={PROJECT_MODULE}
        entityName="OpenreachBuildComplete"
        component={<BulkBuildComplete />}
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/CableConnection/:recordId`}
        moduleName={PROJECT_MODULE}
        entityName="CableConnection"
        component={
          <DetailViewContext moduleName={PROJECT_MODULE} entityName={'CableConnection'}>
            <ProjectModuleCableConnectionDetailViewWide />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/Map/:featureType?/:featureId?`}
        moduleName={PROJECT_MODULE}
        entityName="Map"
        component={<PlanningModuleMap />}
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/BuildPack/:recordId`}
        moduleName={PROJECT_MODULE}
        entityName="Map"
        component={<BuildPackV2 />}
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/BuildPack/Splicing/:recordId`}
        moduleName={PROJECT_MODULE}
        entityName="Map"
        component={<BuildPackV2 withSplicing />}
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/ApplicationForPayment/:recordId`}
        moduleName={PROJECT_MODULE}
        entityName="ApplicationForPayment"
        component={
          <DetailViewContext moduleName={PROJECT_MODULE} entityName={'ApplicationForPayment'}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/ApplicationForPayment/:recordId/approval`}
        moduleName={PROJECT_MODULE}
        entityName="ApplicationForPayment"
        component={<ProjectModuleApplicationForPaymentApprovals />}
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/WorkList/:recordId`}
        moduleName={PROJECT_MODULE}
        entityName="WorkList"
        component={
          <DetailViewContext moduleName={PROJECT_MODULE} entityName={'WorkList'}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/PaymentNotice/:recordId`}
        moduleName={PROJECT_MODULE}
        entityName="PaymentNotice"
        component={
          <DetailViewContext moduleName={PROJECT_MODULE} entityName={'PaymentNotice'}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/RfcManagementViewV2`}
        moduleName={PROJECT_MODULE}
        entityName="RfcManagementViewV2"
        component={<RFCManagementViewV2 />}
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/GanttPortfolio/:recordId`}
        moduleName={PROJECT_MODULE}
        entityName="GanttPortfolio"
        component={
          <DetailViewContext moduleName={PROJECT_MODULE} entityName="GanttPortfolio">
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/GanttProgram/:recordId`}
        moduleName={PROJECT_MODULE}
        entityName="GanttProgram"
        component={
          <DetailViewContext moduleName={PROJECT_MODULE} entityName="GanttProgram">
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/GanttProject/:recordId`}
        moduleName={PROJECT_MODULE}
        entityName="GanttProject"
        component={
          <DetailViewContext moduleName={PROJECT_MODULE} entityName="GanttProject">
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/:entityName`}
        moduleName={PROJECT_MODULE}
        component={<RecordListView moduleName={PROJECT_MODULE} />}
      />
      <ProtectedRoute
        exact
        path={`/${PROJECT_MODULE}/:entityName/:recordId`}
        moduleName={PROJECT_MODULE}
        component={
          <DetailViewContext moduleName={PROJECT_MODULE}>
            <NetomniaDetailViewRouter />
          </DetailViewContext>
        }
      />
    </Switch>
  </Route>,

  /* REPORTING DASHBOARD */
  <Route path="/Dashboard">
    <Switch>
      <ProtectedRoute
        exact
        path={`/Dashboard`}
        moduleName={'REPORTING'}
        component={<SalesDashboard />}
      />
    </Switch>
  </Route>,
];

export default NetomniaRoutes;
