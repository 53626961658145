import { Button, Col, Row } from 'antd';
import { FC, useState } from 'react';

import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import AssociationDataTable from 'src/core/components/AssociationDataTable';
import { connect } from 'react-redux';
import {
  IMapUpdateWorkItems,
  MapReducerUpdate,
  updateMapState,
  updateWorkItems,
} from '@netomnia/modules/ProjectModule/views/Map/store/actions';
import { MapReducer } from '@netomnia/modules/ProjectModule/views/Map/store/reducer';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import ActivityCenter from '@core/components/ActivityCenter';
import { getAllSchemaAssociationSchemas } from '@core/helpers/recordHelpers';

interface Props {
  record: DbRecordEntityTransform;
  schema: SchemaEntity;
  updateWI: (payload: IMapUpdateWorkItems) => void;
  updateMap: (params: MapReducerUpdate) => {};
  mapReducer: MapReducer;
  closeDrawer: Function;
  showRecordInQuickView: Function;
}

type TQuickViewTabs = 'ACTIVITY' | 'RELATED' | 'FILES';

const DefaultRecordBody: FC<Props> = (props: Props) => {
  const { record, schema } = props;
  const [activeTab, setActiveTab] = useState<TQuickViewTabs>('ACTIVITY');

  // Find schemas with OUTCOME_FORM but watch for schema type constraints
  let schemasWithOutcomeForm: any[] =
    schema?.associations.filter(
      (assoc: SchemaAssociationEntity) =>
        assoc.childSchema?.types?.some((type: SchemaTypeEntity) => type.name === 'OUTCOME_FORM') &&
        assoc.schemaTypesConstraints?.find(
          (constraint: any) => constraint.parentSchemaType?.name === record?.type,
        ),
    ) || [];

  if (schemasWithOutcomeForm.length > 0) {
    schemasWithOutcomeForm = schemasWithOutcomeForm.map((sch: any) => sch.childSchema);
  }

  let relatedSchemas = getAllSchemaAssociationSchemas(schema?.associations, ['Note', 'File']);

  return (
    <>
      {/* FEATURE RECORD */}
      <Row style={{ marginTop: 15 }}>
        <Col span={8}>
          <Button
            ghost={activeTab !== 'ACTIVITY'}
            size="large"
            style={{
              width: '100%',
              borderRadius: '5px 0 0 5px',
              fontWeight: activeTab === 'ACTIVITY' ? 600 : 400,
              borderRight: 'none',
            }}
            type="primary"
            onClick={() => setActiveTab('ACTIVITY')}
          >
            Activity
          </Button>
        </Col>
        <Col span={8}>
          <Button
            ghost={activeTab !== 'RELATED'}
            size="large"
            style={{
              width: '100%',
              borderRight: 'none',
              fontWeight: activeTab === 'RELATED' ? 600 : 400,
            }}
            type="primary"
            onClick={() => setActiveTab('RELATED')}
          >
            Related
          </Button>
        </Col>
        <Col span={8}>
          <Button
            size="large"
            ghost={activeTab !== 'FILES'}
            style={{
              width: '100%',
              borderRadius: '0 5px 5px 0',
              fontWeight: activeTab === 'FILES' ? 600 : 400,
            }}
            type="primary"
            onClick={() => setActiveTab('FILES')}
          >
            Files
          </Button>
        </Col>
      </Row>

      {/* ACTIVITY */}
      <Row
        style={{ display: activeTab === 'ACTIVITY' ? 'block' : 'none', marginTop: 20, padding: 10 }}
      >
        <Col span={24}>
          <ActivityCenter record={record!} schema={schema!} hideEmailTab />
        </Col>
      </Row>

      {/* FILES */}
      {activeTab === 'RELATED' ? (
        <Row style={{ marginTop: 20 }}>
          <Col span={24}>
            {relatedSchemas?.map((relatedSchema: SchemaEntity) => (
              <AssociationDataTable
                location="association"
                title={relatedSchema.entityName}
                record={record}
                hideIcon
                moduleName={relatedSchema.moduleName}
                entityName={relatedSchema.entityName}
              />
            ))}
          </Col>
        </Row>
      ) : (
        <></>
      )}

      {activeTab === 'FILES' ? (
        <Row style={{ marginTop: 20 }}>
          <Col span={24}>
            <AssociationDataTable
              thumbnailSize={12}
              title="Files"
              record={record}
              showFileCategoryForType="DEFAULT"
              moduleName="SchemaModule"
              entityName="File"
              location="sidebar"
              collapsedByDefault
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};

const mapState = (state: any) => ({
  mapReducer: state.mapReducer,
});

const mapDispatch = (dispatch: any) => ({
  updateWI: (params: IMapUpdateWorkItems) => dispatch(updateWorkItems(params)),
  updateMap: (params: MapReducerUpdate) => dispatch(updateMapState(params)),
});

export default connect(mapState, mapDispatch)(DefaultRecordBody);
