import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { capitalCase } from 'change-case';

import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Modal } from 'antd';
import { RecordDataTable } from 'src/core/components/DataTable';
import { RecordRejectionDetails, RejectRecordModal } from '../RejectRecordModal';
import { useParams } from 'react-router-dom';
import '@netomnia/modules/ProjectModule/views/ProjectModuleApplicationForPaymentDetailView/styles.scss';
import { FPEStatusFilter } from '../../context/hooks/useAFPApprovalReducer/types';
import { getFeatureTypeOptionsFromFpes } from '../../context/helpers';
import { useAFPApprovalService, useAFPApprovalState } from '../../context';

const useTableSelections = () => {
  const selectedRecordsRef = useRef<DbRecordEntityTransform[]>([]);

  const handleSelection = (selected: DbRecordEntityTransform[]) => {
    selectedRecordsRef.current = selected;
  };

  const rowSelectionProps = {
    type: 'checkbox',

    onSelect: (_row: any, _selected: boolean, selectedRows: any[]) => {
      handleSelection(selectedRows.map(row => row.rowRecord));
    },

    onSelectAll: (_selected: boolean, selectedRows: any[], _changeRows: any) => {
      handleSelection(selectedRows.map(row => row.rowRecord));
    },
  };

  return {
    selectedRecordsRef,
    rowSelectionProps,
  };
};

type BulkApprovalItemPickerProps = {
  records: DbRecordEntityTransform[];
  afpId: string;
  rowSelection?: {};
  activeTab?: string;
}

const BulkApprovalItemPicker = ({ records, afpId, rowSelection, activeTab }: BulkApprovalItemPickerProps) => {
  const getRowClassName = (row: any) => {
    const record = row.rowRecord;
    const isApproved = String(getProperty(record, 'IsApproved'));
    return `row-afp-status-${isApproved}`;
  };

  return (
    <div className="application-for-payment">
      <RecordDataTable title="Feature Price Entries" dataSource={records} rowSelection={rowSelection}
                       rowClassName={getRowClassName} />
    </div>
  );
};

// Bulk Approval Modal
type UpdatableProperties = {
  ApplicationForPaymentId: string;
  IsApproved: 'true' | 'false' | '';
  ApprovedBy?: string;
  ApprovedDate: string;
  RejectionReason?: string;
  RejectionComment?: string;
  RejectedBy?: string;
  RejectedDate?: string;
};

type BulkApprovalModalProps = {
  records: DbRecordEntityTransform[];
  action: FPEStatusFilter;
  onClose: () => void;
};

export const BulkApprovalModal = ({ records, action, onClose }: BulkApprovalModalProps) => {
  const userReducer = useSelector((state: any) => state.userReducer);
  const { records: { feature } } = useAFPApprovalState();
  const { selectedRecordsRef, rowSelectionProps } = useTableSelections();
  const params = useParams<{ recordId: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const { approveFpes, rejectFpes, clearFpesStatus } = useAFPApprovalService();

  const rejectFpesHandler = async ({ RejectionReason, RejectionComment }: RecordRejectionDetails) => {
    console.log('[DebugX] rejectFpesHandler');
    setIsLoading(true);

    await rejectFpes(
      selectedRecordsRef.current,
      {
        RejectionReason,
        RejectionComment,
        RejectedBy: userReducer.user.id,
        ApplicationForPaymentId: params.recordId,
      },
    );

    setIsLoading(false);
    onClose();
  };

  const [RejectionReason, setRejectionReason] = useState('');
  const [RejectionComment, setRejectionComment] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const bulkRejectFpes = (_: DbRecordEntityTransform, rejectionDetails: RecordRejectionDetails) => rejectFpesHandler(rejectionDetails);
  const rejectCurrentFpe = (rec: DbRecordEntityTransform, rejectionDetails: RecordRejectionDetails) => {
    selectedRecordsRef.current = [rec];
    rejectFpesHandler(rejectionDetails);
  };

  const onReject = records.length > 1 ? bulkRejectFpes : rejectCurrentFpe;

  const handleOk = () => {

    if (action === 'rejected') {
      setStep(2);
      return;
    }

    if (action === 'approved') {
      setIsLoading(true);
      approveFpes(
        selectedRecordsRef.current,
        {
          ApprovedBy: userReducer.user.id,
          ApplicationForPaymentId: params.recordId,
        },
      ).then(() => {
        setIsLoading(false);
        onClose();
      });
      return;
    }

    if (action === 'todo') {
      setIsLoading(true);
      clearFpesStatus(
        selectedRecordsRef.current,
        { ApplicationForPaymentId: params.recordId },
      ).then(() => {
        setIsLoading(false);
        onClose();
      });
      return;
    }
  };

  const handleOkStep2 = () => {
    setErrorMessage('');
    if (!RejectionReason) {
      setErrorMessage('Please select a rejection reason');
      return;
    }

    if (!RejectionComment) {
      setErrorMessage('Please enter a rejection comment');
      return;
    }

    onReject(records[0], { RejectionReason, RejectionComment });
  };

  const handleCancel = () => {
    onClose();
  };

  const handleCancelStep2 = () => {
    setStep(1);
  };

  const featureTypes = getFeatureTypeOptionsFromFpes(records);

  const actionText = action === 'approved' ? 'Approval' : action === 'rejected' ? 'Rejection' : 'Undo';

  const recordText = feature.data
    ? `Feature ${feature.data.recordNumber}`
    : featureTypes.length === 1
      ? capitalCase(featureTypes[0].label as string)
      : 'Work Items';

  return (
    <Modal
      open={true}
      onOk={step === 1 ? handleOk : handleOkStep2}
      onCancel={step === 1 ? handleCancel : handleCancelStep2}
      confirmLoading={isLoading}
      // footer={null}
      width={'65%'}
      title={`Bulk ${actionText} for ${recordText}`}
    >
      {step === 1 && <BulkApprovalItemPicker
        records={records}
        afpId={params.recordId}
        rowSelection={rowSelectionProps}
        // activeTab={tabService.activeTab}
      />}
      {step === 2 && <RejectRecordModal
        errorMessage={errorMessage}
        setRejectionComment={setRejectionComment}
        setRejectionReason={setRejectionReason}
      />}
    </Modal>
  );
};
