import {
  Alert,
  Button,
  Card,
  CardList,
  NonIdealState,
  Section,
  SectionCard,
  Tag,
} from '@blueprintjs/core';
import FormModal, { FormReducerSubmitEvt } from '@core/components/Forms/SharedForm/SharedFormModal';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import {
  CreateSchemaType,
  createSchemaTypeRequest,
  DeleteSchemaType,
  deleteSchemaTypeRequest,
} from '@redux/stores/schemas/actions';
import { initializeSharedForm } from '@redux/stores/sharedForm/actions';
import { SharedFormReducer } from '@redux/stores/sharedForm/reducer';
import { Col, Row } from 'antd';
import { FC, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { SchemaDetailsContext } from '../..';
import { formFields } from './formFields';

interface Props {
  schemaTypeId: string | null;
  formReducer: SharedFormReducer;
  initializeForm: any;
  createType: (params: CreateSchemaType) => void;
  deleteType: (params: DeleteSchemaType) => void;
}

const uuid = uuidv4();

const TypesSection: FC<Props> = (props: Props) => {
  const { initializeForm, createType, deleteType, formReducer } = props;
  const { schema } = useContext(SchemaDetailsContext);
  const [schemaTypeToDelete, setSchemaTypeToDelete] = useState<string | undefined>();
  const [deleteSchemaAlertVisible, setDeleteSchemaAlertVisible] = useState<boolean>(false);

  const showCreateForm = () => {
    initializeForm({
      showModal: true,
      formUUID: uuid,
      title: 'Create Entity Type',
      formFields: formFields,
      entityName: 'SchemaType',
      pipelineId: schema?.id,
    });
  };

  const handleFormSubmit = (params: FormReducerSubmitEvt) => {
    if (schema && params.data && !formReducer.isUpdateReq) {
      const body = {
        schemaId: schema?.id,
        name: params.data.name,
        label: params.data.label,
        description: params.data.description,
        isDefault: params.data.isDefault,
      };
      createType({ schemaId: schema.id, body });
    }
  };

  const handleDelete = () => {
    if (schema && schemaTypeToDelete) {
      deleteType({ schemaId: schema?.id, schemaTypeId: schemaTypeToDelete });
      closeModal();
    } else {
      closeModal();
    }
  };

  const closeModal = () => {
    setDeleteSchemaAlertVisible(false);
    setSchemaTypeToDelete(undefined);
  };

  const renderSchemaTypes = () => {
    return (
      <CardList bordered={false}>
        {schema?.types?.map((type: SchemaTypeEntity) => (
          <Card key={type.id} interactive compact>
            <Row style={{ width: '100%' }} align="middle">
              <Col span={7}>
                <span style={{ fontWeight: 600 }}>{type.name}</span>
              </Col>
              <Col span={11}>
                <span>{type.description}</span>
              </Col>
              <Col span={5} style={{ textAlign: 'right' }}>
                <span>
                  {type.isDefault ? (
                    <Tag intent="primary" round size="large">
                      DEFAULT
                    </Tag>
                  ) : (
                    <></>
                  )}
                </span>
              </Col>
              <Col span={1} style={{ textAlign: 'right' }}>
                <Button
                  icon="trash"
                  size="small"
                  variant="minimal"
                  intent="danger"
                  onClick={() => {
                    setDeleteSchemaAlertVisible(true);
                    setSchemaTypeToDelete(type.id);
                  }}
                />
              </Col>
            </Row>
          </Card>
        ))}
      </CardList>
    );
  };

  return (
    <div>
      <FormModal
        formUUID={uuid}
        onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
      />

      <Alert
        intent="danger"
        canEscapeKeyCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        isOpen={deleteSchemaAlertVisible}
        onCancel={closeModal}
        onClose={closeModal}
        onConfirm={handleDelete}
      >
        <p>Are you sure you want to delete this schema type? This action cannot be undone.</p>
      </Alert>

      <Section
        className="types-section"
        title="Entity Types"
        rightElement={
          <Button
            style={{ borderRadius: 5 }}
            intent="success"
            icon="plus"
            onClick={showCreateForm}
            text="Add"
            variant="outlined"
          />
        }
      >
        <SectionCard padded={false} className="types-section-card">
          <Row>
            <Col span={24}>
              {schema?.types?.length! > 0 ? (
                renderSchemaTypes()
              ) : (
                <div style={{ margin: '30px 0' }}>
                  <NonIdealState
                    icon="search"
                    title="No Entity Types"
                    description="Use Add button to create a new entity type"
                  />
                </div>
              )}
            </Col>
          </Row>
        </SectionCard>
      </Section>
    </div>
  );
};

const mapState = (state: any) => ({
  formReducer: state.formReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  createType: (params: CreateSchemaType) => dispatch(createSchemaTypeRequest(params)),
  deleteType: (params: DeleteSchemaType) => dispatch(deleteSchemaTypeRequest(params)),
});

export default connect(mapState, mapDispatch)(TypesSection);
