import { Button, EntityTitle, Icon, Section, Tag } from '@blueprintjs/core';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import { ISchemaReducer } from '@redux/stores/schemas/reducer';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

interface Props {
  schemaReducer: ISchemaReducer;
  links: any;
  onLinkClick: any;
  onLinkRemove: any;
  schema: any;
}

const ListView: FC<Props> = (props: Props) => {
  const { schemaReducer, links, onLinkClick, onLinkRemove, schema } = props;

  const renderBoolean = (value: boolean) => {
    if (value === true) {
      return <Icon icon="tick" intent="success" />;
    } else {
      return <Icon icon="cross" intent="danger" />;
    }
  };

  const findInSchemaObject = (schemaId: string | undefined): SchemaEntity | undefined => {
    const schema = schemaReducer?.list?.find((s: SchemaEntity) => s.id === schemaId);
    if (schema) {
      return schema;
    } else {
      return undefined;
    }
  };

  return (
    <Row>
      <Col span={24}>
        {links.map((link: SchemaAssociationEntity, index: number) => {
          const parentSchema = link.parentSchema || schema;
          const childSchema = link.childSchema || schema;
          const parentEntityName = parentSchema?.entityName || schema?.entityName;
          const childEntityName = childSchema?.entityName || schema?.entityName;
          const findInSchemaSchemaEntity: SchemaEntity | undefined = findInSchemaObject(
            link.findInSchema,
          );
          const findInChildSchemaSchemaEntity: SchemaEntity | undefined = findInSchemaObject(
            link.findInChildSchema,
          );

          // Type Constraints
          let typeConstraints: string[] = [];

          if (link?.schemaTypesConstraints?.length! > 0) {
            const parentSchemaTypes = findInSchemaObject(parentSchema.id)?.types;
            const childSchemaTypes = findInSchemaObject(childSchema.id)?.types;

            link.schemaTypesConstraints.map((typeConstraint: any) => {
              const parentSchemaType = parentSchemaTypes?.find(
                (type: SchemaTypeEntity) => type.id === typeConstraint.parentSchemaTypeId,
              );

              const childSchemaType = childSchemaTypes?.find(
                (type: SchemaTypeEntity) => type.id === typeConstraint.childSchemaTypeId,
              );
              typeConstraints.push(`${parentSchemaType?.name} - ${childSchemaType?.name}`);
            });
          }

          return (
            <Section
              key={link.id}
              collapsible
              collapseProps={{
                defaultIsOpen: false,
              }}
              rightElement={
                <>
                  <Button
                    key={link.id + 'annotation'}
                    icon="annotation"
                    size="small"
                    variant="minimal"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onLinkClick(link);
                    }}
                  />
                  <Button
                    key={link.id + 'trash'}
                    icon="trash"
                    size="small"
                    variant="minimal"
                    intent="danger"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onLinkRemove(link);
                    }}
                  />
                </>
              }
              compact
              title={
                <>
                  <span style={{ fontWeight: 500 }}>{parentEntityName}</span>
                  <i className="bi bi-arrow-right" style={{ marginLeft: 8 }} />
                  {/* Link type */}
                  <Tag
                    round
                    minimal
                    fill={false}
                    style={{ verticalAlign: 'middle', marginLeft: 8, marginBottom: 4 }}
                  >
                    {link.type?.replace(/_/g, ' ')}
                  </Tag>
                  <i className="bi bi-arrow-right" style={{ marginLeft: 8, marginRight: 8 }} />
                  <span style={{ fontWeight: 500 }}>{childEntityName}</span>
                  <span style={{ fontWeight: 300, marginLeft: 12, opacity: 0.4 }}>
                    {link.label}
                  </span>
                </>
              }
            >
              <div style={{ margin: 10 }}>
                <Row style={{ padding: 5, marginBottom: 5 }}>
                  {/* Parent Schema */}
                  <Col span={5}>
                    <EntityTitle
                      title="Parent Schema"
                      subtitle={
                        parentSchema ? (
                          <Link
                            to={`/ControlPanelModule/SchemaManager/${parentSchema?.id}`}
                            target="_blank"
                          >
                            {parentSchema?.entityName}
                          </Link>
                        ) : (
                          '-'
                        )
                      }
                    />
                  </Col>

                  {/* Child Schema */}
                  <Col span={5}>
                    <EntityTitle
                      title="Child Schema"
                      subtitle={
                        childSchema ? (
                          <Link
                            to={`/ControlPanelModule/SchemaManager/${childSchema?.id}`}
                            target="_blank"
                          >
                            {childSchema?.entityName}
                          </Link>
                        ) : (
                          '-'
                        )
                      }
                    />
                  </Col>

                  {/* Link Type */}
                  <Col span={4}>
                    <EntityTitle title="Link Type" subtitle={link.type || '-'} />
                  </Col>

                  {/* Find in Schema */}
                  <Col span={5}>
                    <EntityTitle
                      title="Find in Schema"
                      subtitle={
                        link.findInSchema && findInSchemaSchemaEntity ? (
                          <Link
                            target="_blank"
                            to={`/ControlPanelModule/SchemaManager/${findInSchemaSchemaEntity?.id}`}
                          >
                            {findInSchemaSchemaEntity?.entityName}
                          </Link>
                        ) : (
                          '-'
                        )
                      }
                    />
                  </Col>
                  {/* Find in Child Schema */}
                  <Col span={5}>
                    <EntityTitle
                      title="Find in Child Schema"
                      subtitle={
                        link.findInChildSchema && findInChildSchemaSchemaEntity ? (
                          <Link
                            target="_blank"
                            to={`/ControlPanelModule/SchemaManager/${findInChildSchemaSchemaEntity?.id}`}
                          >
                            {findInChildSchemaSchemaEntity?.entityName}
                          </Link>
                        ) : (
                          '-'
                        )
                      }
                    />
                  </Col>
                </Row>

                <Row style={{ padding: 5, marginBottom: 5 }}>
                  {/* Cascade Delete Child */}
                  <Col span={5}>
                    <EntityTitle
                      title="Cascade Delete Child"
                      subtitle={renderBoolean(link.cascadeDeleteChildRecord)}
                    />
                  </Col>

                  {/* Has Column Mappings */}
                  <Col span={5}>
                    <EntityTitle
                      title="Has Column Mappings"
                      subtitle={renderBoolean(link.hasColumnMappings)}
                    />
                  </Col>

                  {/* Is Deleteable */}
                  <Col span={4}>
                    <EntityTitle title="Is Deleteable" subtitle={renderBoolean(link.isDeletable)} />
                  </Col>

                  {/* Is Master */}
                  <Col span={5}>
                    <EntityTitle title="Is Master" subtitle={renderBoolean(!!link.master)} />
                  </Col>

                  {/* Position */}
                  <Col span={4}>
                    <EntityTitle title="Position" subtitle={<span>{Number(link.position)}</span>} />
                  </Col>
                </Row>

                {/* Add new Row with three columns createdAt, updatedAt, lastModifiedBy.fullname */}
                <Row style={{ padding: 5, marginBottom: 5 }}>
                  {/* Parent Action */}
                  <Col span={5}>
                    <EntityTitle title="Parent Action" subtitle={link.parentActions || '-'} />
                  </Col>

                  {/* Child Action */}
                  <Col span={5}>
                    <EntityTitle title="Child Action" subtitle={link.childActions || '-'} />
                  </Col>

                  {/* Created At */}
                  <Col span={4}>
                    <EntityTitle
                      title="Created At"
                      subtitle={
                        <span>
                          {link.createdAt ? (
                            <span>{dayjs(link.createdAt).format('DD/MM/YYYY HH:mm:ss')}</span>
                          ) : (
                            '-'
                          )}
                        </span>
                      }
                    />
                  </Col>

                  {/* Updated At */}
                  <Col span={5}>
                    <EntityTitle
                      title="Updated At"
                      subtitle={<span>{dayjs(link.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</span>}
                    />
                  </Col>

                  {/* Updated By */}
                  <Col span={5}>
                    <EntityTitle
                      title="Updated By"
                      // @ts-ignore
                      subtitle={<span>{link.lastModifiedBy?.fullName || '-'}</span>}
                    />
                  </Col>
                </Row>

                {/* Types Constraints */}
                {typeConstraints.length > 0 && (
                  <Row style={{ padding: 5 }}>
                    <Col span={24}>
                      <EntityTitle
                        title="Type Constraints"
                        subtitle={
                          <>
                            {typeConstraints.map((constraint: string, index: number) => (
                              <Tag
                                intent="warning"
                                minimal
                                key={constraint}
                                style={{
                                  marginRight: index !== typeConstraints.length - 1 ? 7 : 0,
                                  marginTop: 7,
                                }}
                              >
                                {constraint}
                              </Tag>
                            ))}
                          </>
                        }
                      />
                    </Col>
                  </Row>
                )}
              </div>
            </Section>
          );
        })}
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

export default connect(mapState)(ListView);
