import React, { useContext } from 'react';
import DetailView from 'src/core/views/DefaultDetailView';
import UserCalendar from '../../components/UserCalendar';
import { DetailViewContext } from '@core/components/DetailViewContextProvider';

interface Props {}

const UserDetailView: React.FC<Props> = (props: Props) => {
  const { record, schema } = useContext(DetailViewContext);
  return (
    <DetailView
      // fullWidth
      showGroupsInDetails={true}
      showCollaborators={false}
      customTabs={[{ key: 'Calendar', tab: 'Calendar' }]}
      customTabBodies={{
        Calendar: <UserCalendar record={record} />,
      }}
    />
  );
};
export default UserDetailView;
