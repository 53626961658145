import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import React, { ChangeEvent, MouseEvent, SyntheticEvent } from 'react';
import { Col, Row, Space, Spin, Tooltip } from 'antd';
import { Alert, Button, Icon, InputGroup, MenuItem } from '@blueprintjs/core';
import { ItemRendererProps, MultiSelect } from '@blueprintjs/select';
import { connect } from 'react-redux';
import moment from 'moment';
import { GanttStatic, Task } from '@d19n/dhtmlx-gantt-pro';
import { httpGet, httpPut } from '@core/http/requests';
import { errorNotification } from '@redux/stores/notification/reducers';
import { displayMessage } from '@redux/stores/messages/reducers';
import './styles.scss';

interface Props {
  ganttInstance: GanttStatic;
  users: any[];
  record: DbRecordEntityTransform;
  notifyError: (params: any) => void;
  alertMessage: (params: any) => void;
}

interface State {
  ganttTaskOwners: any[];
  showGanttBaselinesModal: boolean;
  searchValue: string;
  filterValues: any;
  showBuffer: boolean;
  bufferLayerId: string;
  showCriticalPath: boolean;
  showBaselines: boolean;
  pdfExporting: boolean;
}

class GanttActions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      ganttTaskOwners: [],
      showGanttBaselinesModal: false,
      searchValue: '',
      filterValues: {},
      showBuffer: false,
      bufferLayerId: '',
      showCriticalPath: false,
      showBaselines: false,
      pdfExporting: false,
    };
  }

  componentDidMount() {
    this.configureGanttActions(this.props.ganttInstance);
  }

  componentDidUpdate(prevProps: Props, prevStates: State) {
    // list of state keys to monitor that should trigger the Gantt chart to re-render when changed
    const keysToTriggerRender = [
      'searchValue',
      'filterValues',
      'showBuffer',
      'showCriticalPath',
      'showBaselines',
    ];

    const scopedOldStates = Object.fromEntries(
      Object.entries(prevStates).filter(([key]) => keysToTriggerRender.includes(key)),
    );
    const scopedNewStates = Object.fromEntries(
      Object.entries(this.state).filter(([key]) => keysToTriggerRender.includes(key)),
    );

    // can't compare two objects directly so need to convert them to JSON strings
    if (JSON.stringify(scopedOldStates) != JSON.stringify(scopedNewStates)) {
      // .baselines can be a boolean or an object, so ensure we only adjust fields on it when it is an object
      if (typeof this.props.ganttInstance.config.baselines == 'object') {
        this.props.ganttInstance.config.baselines.render_mode = this.state.showBaselines
          ? 'separateRow'
          : false;
      }
      this.props.ganttInstance.config.show_slack = this.state.showBuffer;
      this.props.ganttInstance.config.highlight_critical_path = this.state.showCriticalPath;
      this.props.ganttInstance.render();
    }
  }

  private configureGanttActions(gantt: GanttStatic) {
    // set the owner filter list on load of the component
    this.setFilterOwners(gantt);

    gantt.attachEvent('onBeforeGanttRender', () => {
      if (gantt.config.show_slack && !this.state.bufferLayerId) {
        // create a new slack layer, if one doesn't already exist/is being shown
        let newBufferLayerId = this.showSlackLayer(gantt);
        this.setState({ bufferLayerId: newBufferLayerId });
      } else if (!gantt.config.show_slack && this.state.bufferLayerId) {
        // remove the buffer layer when it is toggled off
        gantt.removeTaskLayer(this.state.bufferLayerId);
        this.setState({ bufferLayerId: '' });
      }
      return true;
    });

    gantt.attachEvent('onBeforeTaskDisplay', (id: any, task: any) => {
      return this.showGanttTask(task, this.state.searchValue, this.state.filterValues);
    });

    const appData = this;
    // update the owner filter list after any ajax request that is fired from the dataProcessor in the GanttChart component
    gantt._dp.attachEvent(
      'onAfterUpdate',
      function (id: any, action: any, tid: any, response: any) {
        return appData.setFilterOwners(gantt);
      },
    );
  }

  setFilterOwners(gantt: GanttStatic) {
    const ganttTaskOwnerIds = [
      ...new Set(gantt.getTaskByTime().map((task: any) => task.owner_id)),
    ].filter((n) => n);
    this.setState({
      ganttTaskOwners: this.props.users.filter((user: any) => ganttTaskOwnerIds.includes(user.id)),
    });
  }

  showGanttTask(
    task: { text?: string; owner_id?: string },
    searchValue: string,
    filterValues: any,
  ) {
    let text = task?.text || '';
    let owner_id = task?.owner_id || '';

    const searchMatch = !searchValue || text.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
    const filterMatch = !filterValues.owner_id || owner_id === filterValues.owner_id;
    return searchMatch && filterMatch;
  }

  searchGanttOnChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({ searchValue: e.target.value });
  }

  clearGanttSearch() {
    this.setState({ searchValue: '' });
  }

  filterGanttOnChange(item: { id: string }) {
    this.setState({ filterValues: { owner_id: item?.id } });
  }

  clearGanttFilters() {
    this.setState({ filterValues: {} });
  }

  toggleGanttBuffer() {
    this.setState({ showBuffer: !this.state.showBuffer });
  }

  toggleGanttCriticalPath() {
    this.setState({ showCriticalPath: !this.state.showCriticalPath });
  }

  toggleGanttBaselines() {
    this.setState({ showBaselines: !this.state.showBaselines });
  }

  openGanttBaselinesModal() {
    this.setState({ showGanttBaselinesModal: !this.state.showGanttBaselinesModal });
  }

  // is added to the gantt on render when show_slack is enabled
  showSlackLayer(gantt: GanttStatic) {
    return gantt.addTaskLayer(function addSlack(
      task: Task | null,
      timeline?: any,
      config?: any,
      viewport?: any,
    ): HTMLElement | boolean | void {
      if (!task) {
        return false;
      }
      if (!gantt.config.show_slack) {
        return false;
      }

      let slack = gantt.getTotalSlack(task);
      if (!slack || slack < 1) {
        return false;
      }

      var state = gantt.getState().drag_mode;
      if (state === 'resize' || state === 'move') {
        return false;
      }

      var slackStart = new Date(task.end_date || '');
      var slackEnd = gantt.calculateEndDate(slackStart, slack);

      var sizes = gantt.getTaskPosition(task, slackStart, slackEnd);
      var el = document.createElement('div');

      el.className = `gantt_slack gantt_slack--${task.type}`;
      el.style.left = sizes.left + 'px';
      el.style.top = sizes.top + 2 + 'px';
      el.style.width = sizes.width + 'px';
      el.style.height = sizes.height + 'px';

      return el;
    });
  }

  setGanttBaselines() {
    const gantt = this.props.ganttInstance;

    httpPut(`ProjectModule/v1.0/GanttProject/${this.props.record.id}/tasks/setBaselineDates`, {})
      .then(() => {
        // Access the baseline datastore
        const baselineStore = gantt.$data.baselineStore;

        // Iterate over all tasks and update the baselines
        gantt.eachTask((task: Task) => {
          const baselineTask = baselineStore.getItem(task.id);

          if (baselineTask) {
            // Update the existing baseline
            baselineTask.start_date = task.start_date;
            baselineTask.end_date = task.end_date;
            baselineStore.updateItem(task.id);
          } else {
            // Add a new baseline if it doesn't exist
            baselineStore.addItem({
              id: task.id,
              task_id: task.id,
              start_date: task.start_date,
              end_date: task.end_date,
            });
          }
        });

        // Apply changes to the datastore
        baselineStore.refresh();

        // Rerender the Gantt Chart with new data
        gantt.render();

        // Hide the modal
        this.setState({ showGanttBaselinesModal: false });
      })
      .catch((err: any) => {
        console.log('debug: err', err);
        this.props.notifyError({ message: 'Could not update baselines. Please try again.' });
      });
  }

  exportGanttPdf() {
    this.setState({ pdfExporting: true });
    this.props.alertMessage({ body: 'Your PDF will be downloaded shortly', type: 'success' });

    const title: string = this.props.record.title || '';

    let ganttStyles =
      document.querySelector('[data-vite-dev-id*="components/GanttChart/styles.scss"]')
        ?.innerHTML || '';

    // only to be applied to the export, not the actual GanttChart
    ganttStyles +=
      '* { font-family: Inter, Helvetica, Arial, sans-serif; } .gantt_container { margin: 0 0 2.5em 2.5em; }';

    let appData = this;
    this.props.ganttInstance.exportToPDF({
      name: `${title.replace('#', '').split(' ').join('_').toLowerCase()}_tasks_${moment(new Date()).format('YYYYMMDD-HHmmss')}.pdf`,
      header: `<style>${ganttStyles}</style><h1 class="gantt-export__header"><span class="gantt-export__netomnia-logo"></span><span>${title} Tasks</span></h1>`,
      skin: 'terrace',
      callback: function (response: any) {
        // create a temporary link on the page for the JS to click and auto-download the export
        var a: HTMLAnchorElement = document.createElement('a');
        a.href = response.url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // the download doesn't start immediately so wait 0.5 seconds before re-enabling the button to keep it in sync
        setTimeout(function () {
          appData.setState({ pdfExporting: false });
        }, 500);
      },
    });
  }

  render() {
    const {
      ganttTaskOwners,
      showGanttBaselinesModal,
      searchValue,
      filterValues,
      showBuffer,
      showCriticalPath,
      showBaselines,
      pdfExporting,
    } = this.state;

    // Generic Data helpers for MultiSelect
    const dataItemRenderer = (
      { id, name }: any,
      { handleClick, handleFocus, modifiers }: ItemRendererProps,
    ) => {
      return (
        <MenuItem
          active={modifiers.active}
          disabled={modifiers.disabled}
          key={id}
          text={name}
          onClick={handleClick}
          onFocus={handleFocus}
        />
      );
    };

    const dataItemPredicate = (
      originalQuery: string,
      data: any,
      _index: number | undefined,
      exactMatch: boolean | undefined,
    ) => {
      // filters the list when typing in the search box
      const name = data.name.toLowerCase();
      const query = originalQuery.toLowerCase();

      return exactMatch ? name === query : name.includes(query);
    };

    return (
      <>
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: 10 }}
          className="gantt-actions"
        >
          <div></div>

          {/* Search Panel */}
          <Col span={24} style={{ textAlign: 'right' }}>
            <Space>
              <InputGroup
                style={{ marginRight: 10 }}
                className="bp5-input-group--search"
                round
                id="gantt-search"
                placeholder="Search"
                leftIcon="search"
                value={searchValue}
                onChange={(e: ChangeEvent<HTMLInputElement>) => this.searchGanttOnChange(e)}
                rightElement={
                  searchValue?.length > 0 ? (
                    <Button
                      minimal
                      intent="danger"
                      icon="cross"
                      onClick={(e: MouseEvent<HTMLElement>) => this.clearGanttSearch()}
                    />
                  ) : (
                    <></>
                  )
                }
              />

              <MultiSelect<{ name?: string; id?: string }>
                placeholder="Select Owner"
                disabled={ganttTaskOwners.length === 0}
                items={ganttTaskOwners}
                selectedItems={
                  !filterValues?.owner_id
                    ? []
                    : [ganttTaskOwners.find((owner: any) => owner.id === filterValues.owner_id)]
                }
                itemRenderer={dataItemRenderer}
                itemPredicate={dataItemPredicate}
                tagRenderer={(data) => data?.name || this.clearGanttFilters()}
                onItemSelect={(item: any, e: SyntheticEvent<HTMLElement, Event> | undefined) =>
                  this.filterGanttOnChange(item)
                }
                onRemove={(value: any, index: number) => this.clearGanttFilters()}
                onClear={() => this.clearGanttFilters()}
                popoverProps={{ matchTargetWidth: true }}
                resetOnQuery={true}
                resetOnSelect={true}
                fill
              />

              <Tooltip title={showBuffer ? 'Hide Buffer' : 'Show Buffer'}>
                <Button
                  onClick={(e: MouseEvent<HTMLElement>) => this.toggleGanttBuffer()}
                  className="bp5-icon-stack"
                >
                  <Icon icon="exchange" />
                  {showBuffer && <Icon icon="slash" size={26} />}
                </Button>
              </Tooltip>

              <Tooltip title={showCriticalPath ? 'Hide Critical Path' : 'Show Critical Path'}>
                <Button
                  onClick={(e: MouseEvent<HTMLElement>) => this.toggleGanttCriticalPath()}
                  className="bp5-icon-stack"
                >
                  <Icon icon="path-search" />
                  {showCriticalPath && <Icon icon="slash" size={26} />}
                </Button>
              </Tooltip>

              <Tooltip title={showBaselines ? 'Hide Baseline Dates' : 'Show Baseline Dates'}>
                <Button
                  onClick={(e: MouseEvent<HTMLElement>) => this.toggleGanttBaselines()}
                  className="bp5-icon-stack"
                >
                  <Icon icon="time" size={14} />
                  {showBaselines && <Icon icon="slash" size={26} />}
                </Button>
              </Tooltip>

              <Tooltip title="Set Baseline Dates">
                <Button
                  onClick={(e: MouseEvent<HTMLElement>) => this.openGanttBaselinesModal()}
                  className="bp5-icon-stack"
                >
                  <Icon icon="array-timestamp" />
                </Button>
              </Tooltip>

              <Tooltip title="Export PDF">
                {pdfExporting ? (
                  <Button
                    disabled={true}
                    icon={
                      <Spin
                        size="small"
                        style={{ width: '10px', justifyContent: 'center', display: 'flex' }}
                      />
                    }
                  ></Button>
                ) : (
                  <Button
                    onClick={(e: MouseEvent<HTMLElement>) => this.exportGanttPdf()}
                    icon="export"
                  ></Button>
                )}
              </Tooltip>

              <Alert
                cancelButtonText="Cancel"
                confirmButtonText="Set Baseline Dates"
                intent="primary"
                isOpen={showGanttBaselinesModal}
                onCancel={() => this.setState({ showGanttBaselinesModal: false })}
                onConfirm={() => {
                  this.setGanttBaselines();
                }}
              >
                <p>
                  <b>Set Baseline Dates</b>
                </p>
                <p>
                  This will save the current schedule as the baseline, replacing any existing
                  baselines. The baseline is a reference point for tracking changes.
                </p>
                <p>The action cannot be undone.</p>
              </Alert>
            </Space>
          </Col>
        </Row>
      </>
    );
  }
}

const mapState = (state: any) => ({
  ganttInstance: state.ganttReducer.ganttInstance,
  users: state.ganttReducer.users || [],
});

const mapDispatch = (dispatch: any) => ({
  notifyError: (params: any) => dispatch(errorNotification(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(GanttActions);
