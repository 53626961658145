import { LockOutlined } from '@ant-design/icons';
import { Button, Callout, Icon, Section } from '@blueprintjs/core';
import NetomniaLogoWide from '@netomnia/assets/netomnia-logo-wide.svg';
import YouFibreLogoWide from '@youfibre/assets/youfibre-logo-wide.svg';
import Typography from '@core/components/Typography';
import { getOrganizationName } from '@core/http/helpers';
import { IdentityOrganizationUserLogin } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/types/identity.organization.user.login';
import { loginCancelRequest, loginRequest, logoutRequest } from '@redux/stores/identity/actions';
import { storeSelectedEntity, storeSelectedModule } from '@redux/stores/navigation/actions';
import { Col, Form, Input, Layout, Row } from 'antd';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

interface Props {
  history: any;
  login: Function;
  logout: Function;
  cancelRequest: any;
  userReducer: any;
  navigationReducer: any;
  storeSelectedEntity: any;
  storeSelectedModule: any;
}

const LoginForm = (props: Props) => {
  const { login, cancelRequest, history, userReducer } = props;
  const [form] = Form.useForm();

  // On component mount, reset userReducer
  useEffect(() => {
    cancelRequest();
  }, []);

  const onFinish = (values: any) => {
    if (values?.email && values?.password) {
      login(values, (response: any) => {
        // Save the url entered before requiring login so we can redirect
        storeSelectedModule({ selectedModule: 'Home' });
        storeSelectedEntity({ selectedEntity: '' });
      });
    }
  };

  const getLogoForOrganization = () => {
    const organizationName = getOrganizationName();

    switch (organizationName) {
      case 'YouFibre':
        return (
          <img
            src={YouFibreLogoWide}
            alt="Logo"
            style={{ width: 75, marginLeft: 7, verticalAlign: 'middle' }}
          />
        );
      case 'Netomnia':
        return (
          <img src={NetomniaLogoWide} alt="Logo" style={{ width: 110, verticalAlign: 'middle' }} />
        );
      default:
        return <Typography stronger>Company</Typography>;
    }
  };

  // To redirect the user after logging in
  useEffect(() => {
    if (!userReducer.user) return;
    if (userReducer.user.enableMfa) {
      history.push('/login/mfa');
    } else {
      history.push('/');
    }
  }, [userReducer?.user]);

  return (
    <Layout>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Callout style={{ padding: '10px 0' }}>
            <Row>
              <Col span={24}>
                <Typography strong>Connecting to Odin</Typography>
              </Col>
            </Row>
          </Callout>
        </Col>
      </Row>
      <Row style={{ height: '90vh' }} align="middle" justify="center">
        <Col xs={22} md={12} lg={7}>
          <Section
            className="loginSection"
            title={
              getOrganizationName() === 'Development' || getOrganizationName() === 'Sandbox' ? (
                <span>Odin {getOrganizationName()}</span>
              ) : (
                getLogoForOrganization()
              )
            }
          >
            <Form
              name="user-login"
              className="login-form"
              initialValues={{ remember: true }}
              form={form}
              onFinish={onFinish}
              onKeyDown={(e) => {
                // If enter key is pressed, submit the form
                if (e.key === 'Enter' && !userReducer.isLoginRequesting) {
                  form.submit();
                }
              }}
              style={{ padding: 30 }}
            >
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Please input your email' }]}
              >
                <Input
                  style={{ borderRadius: 2 }}
                  autoComplete="true"
                  prefix={<i className="bi bi-at" style={{ marginRight: 0, fontSize: '1.5em' }} />}
                  placeholder="Email Address"
                  size="large"
                  disabled={userReducer.isLoggingIn}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password' }]}
              >
                <Input
                  style={{ borderRadius: 2 }}
                  autoComplete="true"
                  prefix={<i className="bi bi-key" style={{ marginRight: 5, fontSize: '1.4em' }} />}
                  type="password"
                  placeholder="Password"
                  size="large"
                  disabled={userReducer.isLoggingIn}
                />
              </Form.Item>
              {userReducer.requireOTP && (
                <Form.Item
                  name="otp"
                  rules={[{ required: true, message: 'Please input your PIN' }]}
                >
                  <Input
                    autoComplete="true"
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="text"
                    placeholder="Authenticator PIN"
                    size="large"
                    disabled={userReducer.isLoggingIn}
                  />
                </Form.Item>
              )}
              <Form.Item style={{ textAlign: 'center', marginBottom: 25 }}>
                <Link to={`/forgot-password`}>Forgot password?</Link>
              </Form.Item>

              <Form.Item shouldUpdate={true} style={{ marginBottom: 0 }}>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <Button
                      large
                      rightIcon={<Icon icon="log-in" />}
                      intent="primary"
                      loading={userReducer.isLoginRequesting}
                      disabled={
                        form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
                        userReducer.isLoginRequesting
                      }
                      onClick={() => onFinish(form.getFieldsValue())}
                      style={{ minWidth: 150 }}
                    >
                      {userReducer.isLoginRequesting ? 'Logging in...' : 'Log in'}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Section>
        </Col>
      </Row>
    </Layout>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  navigationReducer: state.navigationReducer,
});

const mapDispatch = (dispatch: any) => ({
  login: (payload: IdentityOrganizationUserLogin, cb: () => {}) =>
    dispatch(loginRequest(payload, cb)),
  logout: () => dispatch(logoutRequest()),
  cancelRequest: () => dispatch(loginCancelRequest()),
  storeSelectedModule: (params: { selectedModule: string }) =>
    dispatch(storeSelectedModule(params)),
  storeSelectedEntity: (params: { selectedEntity: string }) =>
    dispatch(storeSelectedEntity(params)),
});

export default withRouter(connect(mapState, mapDispatch)(LoginForm));
