import { MenuItem } from '@blueprintjs/core';
import SchemaActionsListViewTable from '@core/modules/ControlPanelModule/components/Actions/SchemaActionsListViewTable';
import { ISchemaReducer } from '@redux/stores/schemas/reducer';
import { Col, Row } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import './styles.scss';

type Props = RouteComponentProps & {
  schemaReducer: ISchemaReducer;
};

const FormsListView: React.FC<Props> = (props: Props) => {
  const [favoriteSchemas, setFavoriteSchemas] = useState<string[]>([]);
  const { history, location, schemaReducer } = props;

  useEffect(() => {
    fetchFavoriteSchemas();
  }, []);

  const fetchFavoriteSchemas = async () => {
    const favorites = await localStorage.getItem('OdinSchemaFavorites');
    if (favorites) {
      setFavoriteSchemas(JSON.parse(favorites));
    }
  };

  const renderFavoriteSchemas = () => {
    let favorites: ReactElement[] = [];

    favoriteSchemas.map((schemaId: string) => {
      const schema = schemaReducer.list.find((schema) => schema.id === schemaId);

      if (schema) {
        favorites.push(
          <MenuItem
            text={schema.name}
            onClick={() => {
              history.push(`/ControlPanelModule/SchemaManager/${schema.id}`);
            }}
          />,
        );
      }
    });

    // Sort favorites by schema.name
    favorites = favorites.sort((a, b) => a.props.text.localeCompare(b.props.text));

    return favorites;
  };

  return (
    <Row>
      {/* Content */}
      <Col span={24}>
        <SchemaActionsListViewTable />
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(withRouter(FormsListView));
