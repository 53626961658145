import {
  Button,
  ButtonGroup,
  InputGroup,
  MenuItem,
  Section,
  Spinner,
  Tag,
} from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { canUserAccessModuleAndEntityInRoutes } from '@core/helpers/rbacRules';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { INavigationReducer } from '@redux/stores/navigation/reducer';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '@redux/stores/schemas/actions';
import { ISchemaReducer } from '@redux/stores/schemas/reducer';
import { Col, Row, Space } from 'antd';
import dayjs from 'dayjs';
import React, { createContext, useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getSchemaFromShortListByModuleAndEntity } from '../../helpers/schemaHelpers';
import { getAssignmentsForInbox, getMentionsForInbox } from './api';
import { getInboxViewportHeight, getMyAssignments, getTeamAssignments } from './helpers';
import InboxAssignmentsList from './InboxAssignmentsList';
import InboxMentionsList from './InboxMentionsList';
import {
  ADD_ASSIGNMENTS_TO_INBOX,
  ADD_MENTIONS_TO_INBOX,
  SET_INBOX_QUICK_SEARCH,
  SET_SELECTED_MENTION,
  TOGGLE_MENTION_READ_STATUS,
} from './store/constants';
import { IInboxReducer, inboxReducer, InboxReducerInitialState } from './store/reducer';
import { TMention } from './store/types';

const { WORK_ORDER } = SchemaModuleEntityTypeEnums;
const CASE = 'Case';
const { FIELD_SERVICE_MODULE, SUPPORT_MODULE } = SchemaModuleTypeEnums;

interface Props {
  schemaReducer: ISchemaReducer;
  navigationReducer: INavigationReducer;
  userReducer: any;
  getSchema: (payload: ISchemaByModuleAndEntity, cb?: any) => void;
  match: any;
}

export const InboxContext = createContext<{
  state: IInboxReducer;
  dispatch: any;
  setSelectedMention: (mentionId: string | undefined) => void;
  toggleMentionReadStatus: (mentionId: string) => void;
  setInboxQuickSearch: (search: string | undefined) => void;
}>({
  state: InboxReducerInitialState,
  dispatch: () => {},
  setSelectedMention: () => {},
  toggleMentionReadStatus: () => {},
  setInboxQuickSearch: () => {},
});

const InboxView: React.FC<Props> = (props: Props) => {
  const { schemaReducer, navigationReducer, userReducer, getSchema, match } = props;
  const { navigationStructure } = navigationReducer;

  const [activePanel, setActivePanel] = useState<'MENTIONS' | 'ASSIGNMENTS'>('MENTIONS');
  const [mentionsFilter, setMentionsFilter] = useState<'All' | 'Read' | 'Unread'>('All');
  const [assignmentTypeFilter, setAssignmentTypeFilter] = useState<'All' | 'Work Orders' | 'Cases'>(
    'All',
  );
  const [assignmentOwnerFilter, setAssignmentOwnerFilter] = useState<'My' | 'Team'>('My');
  const [state, dispatch] = useReducer(inboxReducer, InboxReducerInitialState);
  const [isLoadingMentions, setIsLoadingMentions] = useState<boolean>(false);
  const [isLoadingAssignments, setIsLoadingAssignments] = useState<boolean>(false);

  // On component mount
  useEffect(() => {
    getMentions();
    getAssignments();
    getWOandCaseSchemas();
  }, []);

  // If a mention id was passed into URL, set it as selected mention
  useEffect(() => {
    const selectedMentionId = props.match?.params?.selectedMention;
    if (state.mentions.length > 0 && selectedMentionId) {
      setSelectedMention(selectedMentionId);
    }
  }, [state.mentions]);

  // Reducer Actions
  const addMentions = (mentions: TMention[]) => {
    dispatch({ type: ADD_MENTIONS_TO_INBOX, payload: mentions });
  };
  const addAssignments = (assignments: DbRecordEntityTransform[]) => {
    dispatch({ type: ADD_ASSIGNMENTS_TO_INBOX, payload: assignments });
  };
  const setSelectedMention = (mentionId: string | undefined) => {
    dispatch({ type: SET_SELECTED_MENTION, payload: mentionId });
  };
  const toggleMentionReadStatus = (mentionId: string) => {
    dispatch({ type: TOGGLE_MENTION_READ_STATUS, payload: mentionId });
  };
  const setInboxQuickSearch = (search: string | undefined) => {
    dispatch({ type: SET_INBOX_QUICK_SEARCH, payload: search });
  };

  // Prefetch schemas so that they are available across the inbox view
  const getWOandCaseSchemas = () => {
    if (
      canUserAccessModuleAndEntityInRoutes(navigationStructure, FIELD_SERVICE_MODULE, WORK_ORDER)
    ) {
      const shortlistWOSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        FIELD_SERVICE_MODULE,
        WORK_ORDER,
      );
      if (!shortlistWOSchema) {
        getSchema({ moduleName: FIELD_SERVICE_MODULE, entityName: WORK_ORDER });
      }
    }
    if (canUserAccessModuleAndEntityInRoutes(navigationStructure, SUPPORT_MODULE, CASE)) {
      const shortlistCaseSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        SUPPORT_MODULE,
        CASE,
      );
      if (!shortlistCaseSchema) {
        getSchema({ moduleName: SUPPORT_MODULE, entityName: CASE });
      }
    }
  };

  const getMentions = () => {
    if (userReducer?.user) {
      setIsLoadingMentions(true);
      getMentionsForInbox(userReducer.user.id).then((res: TMention[]) => {
        setIsLoadingMentions(false);
        addMentions(res);
      });
    }
  };

  const getAssignments = () => {
    if (userReducer?.user) {
      setIsLoadingAssignments(true);
      getAssignmentsForInbox(userReducer.user.id).then((res: DbRecordEntityTransform[]) => {
        // console.log('debug: assignments came back!', res);
        setIsLoadingAssignments(false);
        addAssignments(res);
      });
    }
  };

  const renderEventType: ItemRenderer<any> = (
    event,
    { handleClick, handleFocus, modifiers, query },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={
          activePanel === 'MENTIONS'
            ? event.value === mentionsFilter
            : event.value === assignmentTypeFilter
        }
        disabled={modifiers.disabled}
        key={event.rank}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={event.label}
      />
    );
  };

  let filteredMentions = Object.assign([], state.mentions);
  let readMentions = filteredMentions.filter((mention: TMention) => mention.metaData?.read) || [];
  let unreadMentions =
    filteredMentions.filter((mention: TMention) => !mention.metaData?.read) || [];

  // First filter mentions by mention.createdAt and then by mention.read
  if (filteredMentions.length > 0) {
    // Sort read Mentions by createdAt
    readMentions.sort((a: TMention, b: TMention) => {
      return dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix();
    });
    // Sort unread Mentions by createdAt
    unreadMentions.sort((a: TMention, b: TMention) => {
      return dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix();
    });

    // Concatenate the two arrays
    filteredMentions = unreadMentions.concat(readMentions);
  }

  // Filter the mentions based on the mentionsFilter
  if (mentionsFilter === 'Read') {
    filteredMentions = filteredMentions.filter((mention: TMention) => mention.metaData?.read);
  } else if (mentionsFilter === 'Unread') {
    filteredMentions = filteredMentions.filter((mention: TMention) => !mention.metaData?.read);
  }

  // Filter mentions based on quickSearch
  if (state.quickSearch && activePanel === 'MENTIONS') {
    filteredMentions = filteredMentions.filter(
      (mention: TMention) =>
        mention.createdBy.fullName.toLowerCase().includes(state.quickSearch!.toLowerCase()) ||
        mention.linkedRecord?.recordNumber
          ?.toLowerCase()
          .includes(state.quickSearch!.toLowerCase()),
    );
  }

  let filteredAssignments = Object.assign([], state.assignments);

  if (filteredAssignments.length > 0) {
    // Filter assignments based on assignmentTypeFilter
    if (assignmentTypeFilter === 'Work Orders') {
      filteredAssignments = filteredAssignments.filter((assignment: DbRecordEntityTransform) =>
        assignment.entity?.includes('WorkOrder'),
      );
    } else if (assignmentTypeFilter === 'Cases') {
      filteredAssignments = filteredAssignments.filter((assignment: DbRecordEntityTransform) =>
        assignment.entity?.includes('Case'),
      );
    }

    filteredAssignments.sort((a: DbRecordEntityTransform, b: DbRecordEntityTransform) => {
      return dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix();
    });

    // Filter assignments based on assignmentOwnerFilter. If assignment.properties.TeamId is undefined, then it's an Own assignment. Otherwise, it's a Team assignment.
    if (assignmentOwnerFilter === 'My') {
      filteredAssignments = getMyAssignments(filteredAssignments, userReducer?.user?.id);
    } else if (assignmentOwnerFilter === 'Team') {
      filteredAssignments = getTeamAssignments(filteredAssignments, userReducer?.user?.id);
    }

    if (state.quickSearch && activePanel === 'ASSIGNMENTS') {
      filteredAssignments = filteredAssignments.filter(
        (assignment: DbRecordEntityTransform) =>
          assignment.recordNumber?.toLowerCase().includes(state.quickSearch!.toLowerCase()) ||
          assignment.title?.toLowerCase().includes(state.quickSearch!.toLowerCase()),
      );
    }
  }

  return (
    <InboxContext.Provider
      value={{
        state,
        dispatch,
        setSelectedMention,
        toggleMentionReadStatus,
        setInboxQuickSearch,
      }}
    >
      <div style={{ height: getInboxViewportHeight(navigationReducer.tabHistory.length) }}>
        <Row>
          {/* Header */}
          <Col
            span={3}
            style={{ height: getInboxViewportHeight(navigationReducer.tabHistory.length) }}
          >
            <Section
              icon="inbox"
              title={<span style={{ fontSize: '1.1em', lineHeight: '13px' }}>Inbox</span>}
            >
              <div style={{ height: getInboxViewportHeight(navigationReducer.tabHistory.length) }}>
                <Row>
                  <Col span={24}>
                    <Button
                      text="Mentions"
                      intent="primary"
                      small
                      minimal={activePanel !== 'MENTIONS'}
                      icon="chat"
                      fill
                      style={{ padding: '14px 18px' }}
                      alignText="left"
                      onClick={() => setActivePanel('MENTIONS')}
                      rightIcon={
                        <>
                          {!isLoadingMentions && unreadMentions.length > 0 && (
                            <Tag
                              style={{
                                background: 'transparent',
                                color: activePanel === 'MENTIONS' ? 'white' : '#2D72D2',
                              }}
                            >
                              {
                                state.mentions.filter(
                                  (mention: TMention) => !mention.metaData?.read,
                                ).length
                              }
                            </Tag>
                          )}
                          {/* Loader */}
                          {isLoadingMentions && <Spinner size={20} intent="primary" />}
                        </>
                      }
                    />
                  </Col>
                  <Col span={24}>
                    <Button
                      text="Assignments"
                      icon="mugshot"
                      small
                      fill
                      intent="primary"
                      minimal={activePanel !== 'ASSIGNMENTS'}
                      style={{ padding: '14px 18px' }}
                      alignText="left"
                      onClick={() => setActivePanel('ASSIGNMENTS')}
                      rightIcon={
                        <>
                          {!isLoadingAssignments && state.assignments.length > 0 && (
                            <Tag
                              style={{
                                background: 'transparent',
                                color: activePanel === 'ASSIGNMENTS' ? 'white' : '#2D72D2',
                              }}
                            >
                              {state.assignments.length}
                            </Tag>
                          )}
                          {/* Loader */}
                          {isLoadingAssignments && <Spinner size={20} intent="primary" />}
                        </>
                      }
                    />
                  </Col>
                </Row>
              </div>
            </Section>
          </Col>

          {/* Content */}
          <Col span={21}>
            <Section
              title={
                activePanel === 'ASSIGNMENTS' ? (
                  <ButtonGroup>
                    <Button
                      intent={assignmentOwnerFilter === 'My' ? 'primary' : 'none'}
                      text="My Assignments"
                      onClick={() => setAssignmentOwnerFilter('My')}
                      icon={
                        <Tag
                          style={{
                            background: 'transparent',
                            color: assignmentOwnerFilter === 'My' ? 'white' : '#2D72D2',
                          }}
                        >
                          {getMyAssignments(state.assignments, userReducer?.user?.id).length}
                        </Tag>
                      }
                    />
                    <Button
                      text="Team Assignments"
                      intent={assignmentOwnerFilter === 'Team' ? 'primary' : 'none'}
                      onClick={() => setAssignmentOwnerFilter('Team')}
                      icon={
                        <Tag
                          style={{
                            background: 'transparent',
                            color: assignmentOwnerFilter === 'Team' ? 'white' : '#2D72D2',
                          }}
                        >
                          {getTeamAssignments(state.assignments, userReducer?.user?.id).length}
                        </Tag>
                      }
                    />
                  </ButtonGroup>
                ) : (
                  <></>
                )
              }
              rightElement={
                <Row>
                  <Col span={24}>
                    <Space>
                      {/* MENTIONS - Seen/Unseen Filter */}
                      {activePanel === 'MENTIONS' && (
                        <Select
                          disabled={!state.mentions.length}
                          items={[
                            { value: 'All', key: 'All', label: 'All' },
                            { value: 'Read', key: 'Read', label: 'Read' },
                            { value: 'Unread', key: 'Unread', label: 'Unread' },
                          ]}
                          itemRenderer={renderEventType}
                          filterable={false}
                          onItemSelect={(e: any) => setMentionsFilter(e.value)}
                        >
                          <Button
                            style={{ marginRight: 10 }}
                            text={mentionsFilter}
                            rightIcon="caret-down"
                          />
                        </Select>
                      )}

                      {/* ASSIGNMENTS - Type Filter */}
                      {activePanel === 'ASSIGNMENTS' && (
                        <Select
                          disabled={!state.assignments.length}
                          items={[
                            { value: 'All', key: 'All', label: 'All' },
                            { value: 'Work Orders', key: 'Work Orders', label: 'Work Orders' },
                            { value: 'Cases', key: 'Cases', label: 'Cases' },
                          ]}
                          itemRenderer={renderEventType}
                          filterable={false}
                          onItemSelect={(e: any) => setAssignmentTypeFilter(e.value)}
                        >
                          <Button
                            style={{ marginRight: 10 }}
                            text={assignmentTypeFilter}
                            rightIcon="caret-down"
                          />
                        </Select>
                      )}
                      <InputGroup
                        round
                        leftIcon="search"
                        placeholder="Quick Search"
                        value={state.quickSearch}
                        onChange={(e: any) => setInboxQuickSearch(e.target.value)}
                        rightElement={
                          state.quickSearch?.length! > 0 ? (
                            <Button
                              minimal
                              intent="danger"
                              icon="cross"
                              onClick={() => setInboxQuickSearch('')}
                            />
                          ) : (
                            <></>
                          )
                        }
                      />
                    </Space>
                  </Col>
                </Row>
              }
            >
              <div
                style={{
                  overflowY: 'auto',
                  height: getInboxViewportHeight(navigationReducer.tabHistory.length),
                }}
              >
                {/* Mentions List */}
                {activePanel === 'MENTIONS' && <InboxMentionsList mentions={filteredMentions} />}

                {/* Assignments List */}
                {activePanel === 'ASSIGNMENTS' && (
                  <InboxAssignmentsList
                    assignments={filteredAssignments}
                    owner={assignmentOwnerFilter}
                  />
                )}
              </div>
            </Section>
          </Col>
        </Row>
      </div>
    </InboxContext.Provider>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
  navigationReducer: state.navigationReducer,
});
const mapDispatch = (dispatch: any) => ({
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

export default withRouter(connect(mapState, mapDispatch)(InboxView));
