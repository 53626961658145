import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { Button, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { getTabFromHash } from '@core/modules/ControlPanelModule/helpers/hashNavigation';

import CompanyListView from '../Companies/CompanyListView';
import DepartmentsListView from '../Departments/DepartmentsListView';
import GroupListView from '../Groups/GroupListView';
import OrganizationsDetailView from '../Organizations/DetailView';
import PermissionListView from '../Permissions/PermissionListView';
import RegionsListView from '../Regions/RegionsListView';
import RoleListView from '../Roles/RoleListView';
import TeamListView from '../Teams/TeamListView';
import TemplateListView from '../Templates/TemplateListView';
import TerritoriesListView from '../Territories/TerritoriesListView';
import UserListView from '../User/UserListView';
import AreasListView from '../Areas/AreasListView';
import './styles.scss';

const IdentityManagerView: React.FC<RouteComponentProps> = ({ history, location }) => {
  const [selectedTab, setSelectedTab] = useState<string | undefined>('users');

  useEffect(() => {
    const hash = location.hash;
    const tab = getTabFromHash(hash, 'users');
    setSelectedTab(tab);
  }, [location.hash]);

  const menuItems = [
    { text: 'Users', key: 'users' },
    { text: 'Companies', key: 'companies' },
    { text: 'Departments', key: 'departments' },
    { text: 'Territories', key: 'territories' },
    { text: 'Regions', key: 'regions' },
    { text: 'Areas', key: 'areas' },
    // divider ------------------
    { text: 'divider', key: 'divider-1' },
    // divider ------------------
    { text: 'Roles', key: 'roles' },
    { text: 'Permissions', key: 'permissions' },
    { text: 'Groups', key: 'groups' },
    { text: 'Teams', key: 'teams' },
    // divider ------------------
    { text: 'divider', key: 'divider-2' },
    // divider ------------------
    { text: 'Onboarding Templates', key: 'templates' },
    { text: 'Organization', key: 'organization' },
  ];

  return (
    <Row>
      {/* Navbar */}
      <Col span={4} style={{ padding: 5, borderRight: '1px solid #DCDCDD' }}>
        <Menu style={{ background: 'transparent' }}>
          <MenuDivider title="Identity Manager" />
          <div style={{ marginBottom: 12 }} />

          {menuItems.map((item) =>
            item.text === 'divider' ? (
              <MenuDivider key={item.key} />
            ) : (
              <MenuItem
                key={item.key}
                text={item.text}
                onClick={() => (window.location.hash = `#${item.key}`)}
                active={selectedTab === item.key}
              />
            ),
          )}
          <Button
            fill
            minimal
            alignText="left"
            style={{ paddingLeft: 7, marginTop: 1 }}
            text="Setup Organization"
            onClick={() => {
              history.push(`/setup-organization`);
            }}
          />
        </Menu>
      </Col>

      {/* Content */}
      <Col span={20}>
        {selectedTab === 'users' ? (
          <UserListView />
        ) : selectedTab === 'roles' ? (
          <RoleListView />
        ) : selectedTab === 'permissions' ? (
          <PermissionListView />
        ) : selectedTab === 'groups' ? (
          <GroupListView />
        ) : selectedTab === 'templates' ? (
          <TemplateListView />
        ) : selectedTab === 'organization' ? (
          <OrganizationsDetailView />
        ) : selectedTab === 'companies' ? (
          <CompanyListView />
        ) : selectedTab === 'departments' ? (
          <DepartmentsListView />
        ) : selectedTab === 'territories' ? (
          <TerritoriesListView />
        ) : selectedTab === 'regions' ? (
          <RegionsListView />
        ) : selectedTab === 'areas' ? (
          <AreasListView />
        ) : selectedTab === 'teams' ? (
          <TeamListView />
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};

export default withRouter(IdentityManagerView);
