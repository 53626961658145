import { EyeOutlined } from '@ant-design/icons';
import { Button, Card, Col, Descriptions, Row, Typography } from 'antd';
import { capitalCase } from 'change-case';
import React from 'react';
import { Link } from 'react-router-dom';
import ModuleEntityIcon from '@core/components/ModuleEntityIcon';
import { DashboardLineItem } from '../../hooks/types';
import { splitCamelCase } from '@core/helpers/stringHelpers';

type LineItemInformationProps = {
  title: string;
  workListId: string;
  lineItems: DashboardLineItem[];
  status: string;
  error?: { message: string; cause?: string[] };
};

export const LineItemInformation = ({
  title,
  workListId,
  lineItems,
  status,
  error,
}: LineItemInformationProps) => {
  const hideRecordTitle = false;
  const displayQuickView = false;
  const showItemActionMenu = false;
  const disableTitleLink = false;
  const propertiesLayout: string = '';

  return (
    <Card className="recordCard singleRecordCard`">
      <>
        <Row justify="space-around" className="accentedRow" style={{ opacity: 1 }}>
          {hideRecordTitle ? (
            <></>
          ) : (
            <Col span={displayQuickView || showItemActionMenu ? 18 : 24}>
              <span style={{ fontWeight: 500 }}>
                {disableTitleLink ? (
                  <span>{title}</span>
                ) : (
                  <Link to={`/ProjectModule/WorkList/${workListId}`} target="_blank">
                    {title}
                  </Link>
                )}
              </span>
            </Col>
          )}

          {displayQuickView || showItemActionMenu ? (
            <Col span={6} style={{ textAlign: 'right' }}>
              {displayQuickView ? (
                <Button
                  ghost
                  type="primary"
                  size="small"
                  style={{ verticalAlign: 'middle', borderRadius: 4 }}
                  icon={<EyeOutlined />}
                  // onClick={() =>
                  //   shortListRecord({
                  //     showPreview: true,
                  //     record: record,
                  //   })
                  // }
                />
              ) : (
                <></>
              )}
              {/* {showItemActionMenu ? (
                <div style={{ marginLeft: 7, display: 'inline-block' }}>
                  <ListItemActionMenu
                    onListItemActionSuccess={listActionSuccess}
                    relatedRecord={record}
                    record={props.record}
                    relation={association}
                    hidden={[]}
                    compact
                  />
                </div>
              ) : (
                <></>
              )} */}
            </Col>
          ) : (
            <></>
          )}
        </Row>
        <Row>
          <Descriptions
            size="small"
            layout={'horizontal'}
            column={1}
            className="recordCardDescriptions"
            style={{ opacity: 1, width: '100%' }}
            labelStyle={{ color: 'black', fontSize: '1em' }}
            contentStyle={{ fontSize: '1em' }}
          >
            <Descriptions.Item
              contentStyle={{ marginBottom: propertiesLayout === 'vertical' ? 15 : 0 }}
              label="Line Items"
            >
              {lineItems.map((li) => li.fpe_title).join(', ')}
            </Descriptions.Item>
            <Descriptions.Item
              contentStyle={{ marginBottom: propertiesLayout === 'vertical' ? 15 : 0 }}
              label="Status"
            >
              {capitalCase(status)}
            </Descriptions.Item>
            {status === 'failed' && (
              <Descriptions.Item
                contentStyle={{ marginBottom: propertiesLayout === 'vertical' ? 15 : 0 }}
                label="Error"
              >
                {error?.message ?? ''} <br />
                {Array.isArray(error?.cause) && error?.cause}
              </Descriptions.Item>
            )}
          </Descriptions>
        </Row>
      </>
    </Card>
  );
};

type SubmissionCardProps = {
  // title: string;
  children: React.ReactNode;
};

export const SubmissionCard = ({ children }: SubmissionCardProps) => {
  const borderless = false;
  const hideIcon = false;
  const moduleName = 'ProjectModule';
  const entityName = 'WorkList';
  return (
    <Card
      bordered={!borderless ? true : false}
      className="recordCardHeader"
      style={{ marginBottom: 10 }}
      title={
        <Row justify="space-between" align="middle">
          <Col>
            {/* Record Icon and Entity type */}
            <Typography.Title level={5} style={{ fontWeight: 500, marginBottom: 0, marginTop: 0 }}>
              {!hideIcon ? (
                <ModuleEntityIcon moduleName={moduleName} entityName={entityName} />
              ) : (
                <></>
              )}

              {entityName ? splitCamelCase(entityName) : ''}
            </Typography.Title>
          </Col>
        </Row>
      }
    >
      {children}
    </Card>
  );
};
