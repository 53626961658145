import { DeleteOutlined } from '@ant-design/icons';
import { Button, Drawer, InputGroup, Tag } from '@blueprintjs/core';
import { Alert, Col, Divider, Row, Space } from 'antd';
import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { httpGet, httpPost } from '@core/http/requests';
import { displayMessage } from '@redux/stores/messages/reducers';
import { getErrorMessage } from '@core/modules/ControlPanelModule/helpers/errors';

interface Props {
  template: any;
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
  onSubmit?: (tags: string[]) => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

const OnboardUsersByEmailDrawer: React.FC<Props> = (props: Props) => {
  const { onSubmit, template, toggleDrawer, isDrawerOpen } = props;
  const [emailInput, setEmailInput] = useState('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [tags, setTags] = useState<string[]>([]);
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAlertVisible(false);
    setEmailInput(e.target.value);
  };

  const handleInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      (e.key === 'Enter' && emailInput.match(emailRegex)) ||
      (e.key === ',' && emailInput.match(emailRegex))
    ) {
      e.preventDefault();
      createTags();
    }
  };

  const createTags = () => {
    if (emailInput.trim() !== '') {
      const enteredEmails = emailInput.split(',').map((email) => email.trim());
      setTags((prevTags) => [...prevTags, ...enteredEmails]);
      setEmailInput('');
    }
  };

  const removeTag = (removedTag: string) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== removedTag));
  };

  const onboardUsers = async () => {
    setIsSubmitting(true);
    setAlertVisible(false);

    // Fetch list of all users, map out only email addresses. Check if user is trying to add an email that already exists.
    const allUsers = await httpGet(`IdentityModule/v2.0/users/byorg?size=10000`);
    const allUserEmails = allUsers?.data?.data?.map((user: any) => user.email);
    const existingEmail = tags.find((tag: string) => allUserEmails.includes(tag));

    if (existingEmail) {
      props.alertMessage({
        body: `User with email ${existingEmail} already exists. Please remove it before trying to submit again.`,
        type: 'error',
      });
      setIsSubmitting(false);
      return;
    } else {
      httpPost(`IdentityModule/v1.0/users/send-registration-link`, {
        userEmails: tags,
        onboardingTemplateId: template?.id,
      })
        .then(async (res: any) => {
          setIsSubmitting(false);
          setAlertVisible(true);
          onSubmit && onSubmit(tags);
          setTags([]);
        })
        .catch((err: any) => {
          const message = getErrorMessage(err);
          props.alertMessage({
            body: `Could not send registration link via email. ${message}`,
            type: 'error',
          });
          setIsSubmitting(false);
          setTags([]);
          setAlertVisible(false);
        });
    }
  };

  const onClose = () => {
    toggleDrawer();
    setAlertVisible(false);
    setTags([]);
  };

  return (
    <Drawer
      title="Onboard Users"
      style={{ width: isMobile ? '95%' : '30%' }}
      onClose={onClose}
      isOpen={isDrawerOpen}
    >
      <Row style={{ padding: 15 }}>
        <Col span={24}>
          <Space.Compact style={{ width: '100%' }}>
            <InputGroup
              value={emailInput}
              onChange={handleInputChange}
              onKeyPress={handleInputKeyPress}
              type="email"
              placeholder="Enter Email Addresses"
              fill
              style={{ width: '100%' }}
              rightElement={
                emailInput.match(emailRegex) || emailInput.includes(',') ? (
                  <Button text="Add" onClick={createTags} intent="primary" />
                ) : (
                  <></>
                )
              }
            />
          </Space.Compact>
        </Col>
        {/* Added Tags */}
        <Col span={24} style={{ marginTop: 20 }}>
          {tags.length > 0 &&
            tags.map((tag) => (
              <Tag
                key={tag}
                interactive
                icon="envelope"
                onClick={() => removeTag(tag)}
                onRemove={() => removeTag(tag)}
                style={{ marginRight: 8, marginBottom: 8 }}
              >
                {tag}
              </Tag>
            ))}

          {/* Success Alert */}
          {alertVisible && (
            <Col span={24} style={{ marginBottom: 30 }}>
              <Alert
                closable
                onClose={() => setAlertVisible(false)}
                message="Success"
                description="Email invitation to template is successfully sent."
                type="success"
                icon={<i className="bi bi-envelope-check" />}
                showIcon
              />
            </Col>
          )}

          {/* Enter Email CTA */}
          {tags.length === 0 && (
            <Row style={{ textAlign: 'center', opacity: 0.4, margin: 10 }}>
              <Col span={24} style={{ marginBottom: 20 }}>
                <i className="bi bi-envelope-at" style={{ fontSize: '2.5em' }} />
              </Col>
              <Col span={24}>
                <span style={{ lineHeight: '1.8em', fontSize: '1.1em ' }}>
                  Enter an email address one by one, or paste a list of email addresses separated by
                  comma.
                </span>
              </Col>
            </Row>
          )}
        </Col>
        {/* Control Footer */}
        {tags.length > 0 && (
          <>
            <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => setTags([])}
                intent="danger"
                outlined
                icon={<DeleteOutlined />}
                style={{ marginRight: 10 }}
                disabled={isSubmitting}
              >
                Remove All
              </Button>
              <Button
                intent="primary"
                onClick={onboardUsers}
                disabled={!tags.length || isSubmitting}
                loading={isSubmitting}
              >
                Submit
              </Button>
            </Col>
          </>
        )}
      </Row>
    </Drawer>
  );
};

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

const mapState = (state: any) => ({});

export default connect(mapState, mapDispatch)(OnboardUsersByEmailDrawer);
