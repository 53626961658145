import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { RefObject } from 'react';
import {
  MY_CASES_ADD_EMAIL,
  MY_CASES_ADD_MESSAGE,
  MY_CASES_ADD_NOTE,
  MY_CASES_ADD_NOTE_TO_SELECTED_CASE,
  MY_CASES_INCREMENT_CURRENT_CASE_UNREAD_COUNT,
  MY_CASES_IS_LOADING_SELECTED_CASE_CONTACT_CASES,
  MY_CASES_RESET_CURRENT_CASE_COUNT,
  MY_CASES_SET_ACTIVE_TAB,
  MY_CASES_SET_AGENT_INPUT_TEXT,
  MY_CASES_SET_AGENT_TYPING_TEXT,
  MY_CASES_SET_ASSOCIATED_ACCOUNTS,
  MY_CASES_SET_ASSOCIATED_CASES_FOR_CONTACT,
  MY_CASES_SET_CASE_UNREAD_STATUS,
  MY_CASES_SET_CASES,
  MY_CASES_SET_CLIENT_TYPING_TEXT,
  MY_CASES_SET_CONTACT,
  MY_CASES_SET_FEED_CONTAINER_REF,
  MY_CASES_SET_IS_LOADING_ACTIVITY,
  MY_CASES_SET_IS_SCROLL_DOWN_BUTTON_VISIBLE,
  MY_CASES_SET_IS_SEARCHING_FOR_ASSOCIATED_CONTACT,
  MY_CASES_SET_MY_CASES_SEARCH_QUERY,
  MY_CASES_SET_SELECTED_ACCOUNT,
  MY_CASES_SET_SELECTED_CASE,
  MY_CASES_SET_SELECTED_CASE_CALLS,
  MY_CASES_SET_SELECTED_CASE_CONVERSATION,
  MY_CASES_SET_SELECTED_CASE_EMAILS,
  MY_CASES_SET_SELECTED_CASE_MESSAGES,
  MY_CASES_SET_SELECTED_CASE_NOTES,
  MY_CASES_SET_SINGLE_CASE_MODE,
  MY_CASES_TOGGLE_CONTACT_DETAILS_PANEL,
  MY_CASES_TOGGLE_EMAIL_EDITOR,
  MY_CASES_TOGGLE_MESSAGE_EDITOR,
  MY_CASES_TOGGLE_NOTE_EDITOR,
  MY_CASES_UPDATE_CASE,
} from './constants';

export interface IMyCasesReducer {
  agentInputText: string;
  agentTypingText: string;
  cases: DbRecordEntityTransform[];
  clientTypingText: string;
  currentCaseUnreadCount: number;
  feedContainerRef: RefObject<HTMLDivElement> | undefined;
  isAddingNote: boolean;
  isComposingEmail: boolean;
  isComposingMessage: boolean;
  isContactPanelOpen: boolean;
  isLoadingActivity: boolean;
  isLoadingCase: boolean;
  isLoadingCases: boolean;
  isLoadingSelectedCaseContact_Cases: boolean;
  isScrollDownButtonVisible: boolean;
  isSearchingForAssociatedContact: boolean;
  isSingleCaseMode: boolean;
  isUpdatingCase: boolean;
  myCasesSearchQuery: string;
  selectedCase: DbRecordEntityTransform | undefined;
  selectedCaseCalls: DbRecordEntityTransform[];
  selectedCaseContact_Accounts: DbRecordEntityTransform[];
  selectedCaseContact_Cases: DbRecordEntityTransform[];
  selectedCaseContact_SelectedAccount: DbRecordEntityTransform | undefined;
  selectedCaseContact: DbRecordEntityTransform | undefined;
  selectedCaseConversation: DbRecordEntityTransform | undefined;
  selectedCaseEmails: DbRecordEntityTransform[];
  selectedCaseMessages: DbRecordEntityTransform[];
  selectedCaseNotes: DbRecordEntityTransform[];
  selectedCasesTabId: 'my-queue' | 'following';
}

export const MyCasesReducerInitialState: IMyCasesReducer = {
  agentInputText: '',
  agentTypingText: '',
  cases: [],
  clientTypingText: '',
  currentCaseUnreadCount: 0,
  feedContainerRef: undefined,
  isAddingNote: false,
  isComposingEmail: false,
  isComposingMessage: false,
  isContactPanelOpen: false,
  isLoadingActivity: false,
  isLoadingCase: false,
  isLoadingCases: false,
  isLoadingSelectedCaseContact_Cases: false,
  isScrollDownButtonVisible: false,
  isSearchingForAssociatedContact: false,
  isSingleCaseMode: false,
  isUpdatingCase: false,
  myCasesSearchQuery: '',
  selectedCase: undefined,
  selectedCaseCalls: [],
  selectedCaseContact_Accounts: [],
  selectedCaseContact_Cases: [],
  selectedCaseContact_SelectedAccount: undefined,
  selectedCaseContact: undefined,
  selectedCaseConversation: undefined,
  selectedCaseEmails: [],
  selectedCaseMessages: [],
  selectedCaseNotes: [],
  selectedCasesTabId: 'my-queue',
};

export function myCasesReducer(state: IMyCasesReducer, action: { type: string; payload: any }) {
  switch (action.type) {
    case MY_CASES_SET_CASES:
      return { ...state, cases: action.payload };
    case MY_CASES_SET_SELECTED_CASE:
      return {
        ...state,
        clientTypingText: '',
        agentTypingText: '',
        selectedCase: action.payload,
      };
    case MY_CASES_SET_SELECTED_CASE_MESSAGES:
      return { ...state, selectedCaseMessages: action.payload };
    case MY_CASES_SET_SELECTED_CASE_CONVERSATION:
      return {
        ...state,
        clientTypingText: '',
        agentTypingText: '',
        selectedCaseConversation: action.payload,
      };
    case MY_CASES_SET_SELECTED_CASE_EMAILS:
      return { ...state, selectedCaseEmails: action.payload };
    case MY_CASES_SET_SELECTED_CASE_NOTES:
      return { ...state, selectedCaseNotes: action.payload };
    case MY_CASES_SET_SELECTED_CASE_CALLS:
      return { ...state, selectedCaseCalls: action.payload };
    case MY_CASES_TOGGLE_NOTE_EDITOR:
      return { ...state, isAddingNote: !state.isAddingNote };
    case MY_CASES_TOGGLE_CONTACT_DETAILS_PANEL:
      return { ...state, isContactPanelOpen: !state.isContactPanelOpen };
    case MY_CASES_SET_ACTIVE_TAB:
      return { ...state, selectedCasesTabId: action.payload };
    case MY_CASES_ADD_NOTE_TO_SELECTED_CASE:
      return { ...state, selectedCaseNotes: [...state.selectedCaseNotes, action.payload] };
    case MY_CASES_SET_CONTACT:
      return { ...state, selectedCaseContact: action.payload };
    case MY_CASES_TOGGLE_EMAIL_EDITOR:
      return {
        ...state,
        isComposingEmail: !state.isComposingEmail,
      };
    case MY_CASES_TOGGLE_MESSAGE_EDITOR:
      console.log('debug: REDUCER!', !state.isComposingMessage);
      return {
        ...state,
        isComposingMessage: !state.isComposingMessage,
      };
    case MY_CASES_ADD_MESSAGE:
      return { ...state, selectedCaseMessages: [...state.selectedCaseMessages, action.payload] };

    case MY_CASES_SET_FEED_CONTAINER_REF:
      return { ...state, feedContainerRef: action.payload };
    case MY_CASES_SET_IS_SCROLL_DOWN_BUTTON_VISIBLE:
      return { ...state, isScrollDownButtonVisible: action.payload };
    case MY_CASES_SET_IS_SEARCHING_FOR_ASSOCIATED_CONTACT:
      if (action.payload === false) {
        return {
          ...state,
          isSearchingForAssociatedContact: false,
        };
      } else {
        // wipe out contact data when searching for a new contact
        return {
          ...state,
          isSearchingForAssociatedContact: true,
          selectedCaseContact: undefined,
          selectedCaseContact_Accounts: [],
          selectedCaseContact_SelectedAccount: undefined,
          selectedCaseContact_Cases: [],
        };
      }

    case MY_CASES_SET_ASSOCIATED_ACCOUNTS:
      return { ...state, selectedCaseContact_Accounts: action.payload };
    case MY_CASES_SET_ASSOCIATED_CASES_FOR_CONTACT:
      return { ...state, selectedCaseContact_Cases: action.payload };
    case MY_CASES_IS_LOADING_SELECTED_CASE_CONTACT_CASES:
      return { ...state, isLoadingSelectedCaseContact_Cases: action.payload };
    case MY_CASES_ADD_EMAIL:
      return { ...state, selectedCaseEmails: [...state.selectedCaseEmails, action.payload] };
    case MY_CASES_ADD_NOTE:
      return { ...state, selectedCaseNotes: [...state.selectedCaseNotes, action.payload] };
    case MY_CASES_UPDATE_CASE:
      // Update cases but preserve initial status (read / unread)
      const updatedCase = action.payload;
      const updatedCases = state.cases.map((c) =>
        c.id === updatedCase.id ? { ...updatedCase, status: c.status } : c,
      );

      // If no cases in the updated cases list match the updatedCase.id, then we need to add the updatedCase to the list.
      // The reason for this is that when a new case is assigned to user, websocket event will publish CASE_UPDATED event
      return {
        ...state,
        cases: updatedCases,
        selectedCaseContact_Cases: state.selectedCaseContact_Cases.map(
          (c: DbRecordEntityTransform) => (c.id === updatedCase.id ? updatedCase : c),
        ),
        selectedCase:
          state.selectedCase?.id === updatedCase.id
            ? {
                ...updatedCase,
                status: state.selectedCase?.status,
              }
            : state.selectedCase,
      };
    case MY_CASES_SET_SELECTED_ACCOUNT:
      return { ...state, selectedCaseContact_SelectedAccount: action.payload };
    case MY_CASES_SET_SINGLE_CASE_MODE:
      return { ...state, isSingleCaseMode: action.payload };
    case MY_CASES_SET_AGENT_INPUT_TEXT:
      return { ...state, agentInputText: action.payload };
    case MY_CASES_SET_CLIENT_TYPING_TEXT:
      return { ...state, clientTypingText: action.payload };
    case MY_CASES_SET_AGENT_TYPING_TEXT:
      return { ...state, agentTypingText: action.payload };
    case MY_CASES_RESET_CURRENT_CASE_COUNT: {
      return { ...state, currentCaseUnreadCount: 0 };
    }
    case MY_CASES_INCREMENT_CURRENT_CASE_UNREAD_COUNT: {
      return { ...state, currentCaseUnreadCount: state.currentCaseUnreadCount + 1 };
    }
    // Get a case from the list of cases that matches the caseId in payload, and update the unread status.
    // If there is no status property, add it to the case object or update it.
    case MY_CASES_SET_CASE_UNREAD_STATUS: {
      const updatedCases = state.cases.map((c: any) => {
        if (c.id === action.payload.caseId) {
          return {
            ...c,
            status: action.payload.status,
          };
        }
        return c;
      });
      return { ...state, cases: updatedCases };
    }
    case MY_CASES_SET_MY_CASES_SEARCH_QUERY: {
      return { ...state, myCasesSearchQuery: action.payload };
    }
    case MY_CASES_SET_IS_LOADING_ACTIVITY: {
      if (action.payload === false) {
        return { ...state, isLoadingActivity: false };
      } else {
        return {
          ...state,
          isLoadingActivity: true,
          selectedCaseEmails: [],
          selectedCaseMessages: [],
          selectedCaseCalls: [],
          selectedCaseNotes: [],
        };
      }
    }
    default:
      throw new Error();
  }
}
