import React, { useContext } from 'react';
import { DetailViewContext } from '../../../../../core/components/DetailViewContextProvider';
import FeatureRfcDetailView from '../FeatureRfcDetailView';
import FeatureNonRfcDetailView from '../FeatureNonRfcDetailView';

interface Props {
  isQuickView?: boolean;
}

const FeatureDetailView: React.FC<Props> = ({ isQuickView }) => {
  const { record } = useContext(DetailViewContext);

  if (record?.type === 'SURVEY_ROUTE' || record?.type === 'SURVEY_STRUCTURE') {
    return <FeatureRfcDetailView isQuickView={isQuickView} />;
  } else {
    return <FeatureNonRfcDetailView />;
  }
};

export default FeatureDetailView;
