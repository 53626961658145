import { httpPost } from '@core/http/requests';
import { CaseChannel } from '@d19n/sandbox-odin-sdk/dist/entities-v2/Case';
import { displayMessage } from '@redux/stores/messages/reducers';
import { ISearchRecords, searchRecordsRequest } from '@redux/stores/records/actions';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CaseManagementDashboardStatCard from './CaseManagementDashboardStatCard';

interface Props {
  searchRecords: (params: ISearchRecords, cb: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

const ORDERED_STAGES = [
  'Open',
  'Solved',
  'Closed',
  'Pending Agent',
  'Pending Reply',
  'Pending Review',
  'Blocked',
];

type CaseAggregation = {
  name: string;
  value: number;
};

type Metric = {
  allTime: number;
  today: number;
  stageAggregation: CaseAggregation[];
};

const metricDefault: Metric = {
  allTime: 0,
  today: 0,
  stageAggregation: [],
};

const CaseManagementDashboard = (props: Props) => {
  const [allTimeCases, setAllTimeCases] = useState<number>(0);
  const [callsMetric, setCallsMetric] = useState<Metric>(metricDefault);
  const [conversationsMetric, setConversationsMetric] = useState<Metric>(metricDefault);
  const [emailsMetric, setEmailsMetric] = useState<Metric>(metricDefault);
  const [trustPilotMetric, setTrustPilotMetric] = useState<Metric>(metricDefault);
  const [facebookMetric, setFacebookMetric] = useState<Metric>(metricDefault);

  const fetchAllDataFromAllTime = async (): Promise<void> => {
    const [calls, conversations, emails, facebookPagePosts, trustPilotCases] = await Promise.all([
      fetchAllTimeCasesOnChannel(CaseChannel.CALL),
      fetchAllTimeCasesOnChannel(CaseChannel.WEB_CHAT),
      fetchAllTimeCasesOnChannel(CaseChannel.EMAIL, 'Source'),
      fetchAllTimeCasesOnChannel(CaseChannel.FACEBOOK_PAGE_POST),
      fetchAllTimeCasesOnChannel(CaseChannel.TRUSTPILOT_REVIEW),
    ]);

    setCallsMetric((prev) => ({ ...prev, allTime: calls }));
    setConversationsMetric((prev) => ({ ...prev, allTime: conversations }));
    setEmailsMetric((prev) => ({ ...prev, allTime: emails }));
    setTrustPilotMetric((prev) => ({ ...prev, allTime: trustPilotCases }));
    setFacebookMetric((prev) => ({ ...prev, allTime: facebookPagePosts }));

    setAllTimeCases(calls + conversations + emails + facebookPagePosts + trustPilotCases);
  };

  const fetchAllData = async () => {
    const [calls, conversations, emails, facebookPagePosts, trustPilotCases] = await Promise.all([
      fetchTodayCasesByChannel(CaseChannel.CALL),
      fetchTodayCasesByChannel(CaseChannel.WEB_CHAT),
      fetchTodayCasesByChannel(CaseChannel.EMAIL, 'Source'),
      fetchTodayCasesByChannel(CaseChannel.FACEBOOK_PAGE_POST),
      fetchTodayCasesByChannel(CaseChannel.TRUSTPILOT_REVIEW),
    ]);
    setCallsMetric((prev) => ({
      ...prev,
      today: calls.totals,
      stageAggregation: calls.aggregation,
    }));
    setConversationsMetric((prev) => ({
      ...prev,
      today: conversations.totals,
      stageAggregation: conversations.aggregation,
    }));
    setEmailsMetric((prev) => ({
      ...prev,
      today: emails.totals,
      stageAggregation: emails.aggregation,
    }));
    setTrustPilotMetric((prev) => ({
      ...prev,
      today: trustPilotCases.totals,
      stageAggregation: trustPilotCases.aggregation,
    }));
    setFacebookMetric((prev) => ({
      ...prev,
      today: facebookPagePosts.totals,
      stageAggregation: facebookPagePosts.aggregation,
    }));
  };

  const fetchTodayCasesByChannel = async (
    caseChannel: CaseChannel,
    columnName = 'Channel',
  ): Promise<{ totals: number; aggregation: CaseAggregation[] }> => {
    const result = await httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName,
            operator: 'eq',
            value: caseChannel,
          },
          {
            columnName: 'UpdatedAt',
            operator: 'gte',
            value: dayjs().startOf('day').format('YYYY-MM-DD'),
          },
        ],
        returnProperties: ['id', 'title', 'recordNumber'],
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 50,
      },
    });
    const { totalRecords, aggregations } = result.data.data;

    if (aggregations && aggregations['stage-agg']) {
      const stageAggs = aggregations['stage-agg'].buckets.map(
        (bucket: { key: string; doc_count: number }) => ({
          name: bucket.key,
          value: bucket.doc_count,
        }),
      );

      const orderedStageAggs: CaseAggregation[] = ORDERED_STAGES.map(
        (stage) =>
          stageAggs.find((agg: CaseAggregation) => agg.name === stage) || {
            name: stage,
            value: 0,
          },
      );
      return { totals: totalRecords, aggregation: orderedStageAggs };
    }
    return { totals: 0, aggregation: [] };
  };

  const fetchAllTimeCasesOnChannel = async (
    caseChannel: CaseChannel,
    columnName = 'Channel',
  ): Promise<number> => {
    const result = await httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName,
            operator: 'eq',
            value: caseChannel,
          },
        ],
        returnProperties: ['id', 'title', 'recordNumber'],
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 50,
      },
    });
    const { aggregations, totalRecords } = result.data.data;
    console.log('totalRecords', totalRecords);
    if (aggregations && aggregations['stage-agg']) {
      const stageAggs = aggregations['stage-agg'].buckets.map(
        (bucket: { key: string; doc_count: number }) => ({
          name: bucket.key,
          value: bucket.doc_count,
        }),
      );
      const totalDocCount = stageAggs.reduce(
        (sum: number, stage: { name: string; value: number }) => sum + stage.value,
        0,
      );
      return totalDocCount;
    }
    return 0;
  };

  useEffect(() => {
    fetchAllDataFromAllTime();
    fetchAllData();

    const interval = setInterval(() => {
      fetchAllDataFromAllTime();
      fetchAllData();
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {/* Header */}
      <Row>
        <Col span={24}>
          <h1 style={{ margin: 0 }}>Dashboard</h1>
        </Col>
      </Row>
      {/* SalesDashboard */}
      <Row
        style={{
          marginTop: 30,
          height: 'calc(100vh - 140px)',
          overflowY: 'auto',
          alignContent: 'flex-start',
        }}
      >
        {/* SLA Score Chart */}
        {/*<CaseManagementDashboardChart />*/}
        <CaseManagementDashboardStatCard
          title={'Total Cases (All Time)'}
          topStats={[{ title: 'Total Cases', value: allTimeCases }]}
          properties={[
            { name: 'Total Calls', value: callsMetric.allTime },
            { name: 'Total Chats', value: conversationsMetric.allTime },
            { name: 'Total Emails', value: emailsMetric.allTime },
            { name: 'Total Social Media', value: facebookMetric.allTime },
            { name: 'Total Trust Pilot', value: trustPilotMetric.allTime },
          ]}
        />

        {/* Tickets Stat */}

        {/* Calls Stat */}
        <CaseManagementDashboardStatCard
          title="Calls (Today)"
          topStats={[{ title: 'Total Cases', value: callsMetric.today }]}
          properties={callsMetric.stageAggregation}
        />

        {/* Chats Stat */}
        <CaseManagementDashboardStatCard
          title="Chats (Today)"
          topStats={[{ title: 'Total Cases', value: conversationsMetric.today }]}
          properties={conversationsMetric.stageAggregation}
        />

        {/* Email Stat */}
        <CaseManagementDashboardStatCard
          title="Email (Today)"
          topStats={[{ title: 'Total Cases', value: emailsMetric.today }]}
          properties={emailsMetric.stageAggregation}
        />

        {/* Social Media */}
        <CaseManagementDashboardStatCard
          title="Social Media (Today)"
          topStats={[{ title: 'Total Cases', value: facebookMetric.today }]}
          properties={facebookMetric.stageAggregation}
        />

        {/* Social Media */}
        <CaseManagementDashboardStatCard
          title="TrustPilot (Today)"
          topStats={[{ title: 'Total Cases', value: trustPilotMetric.today }]}
          properties={trustPilotMetric.stageAggregation}
        />
      </Row>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(CaseManagementDashboard);
