import { Callout, Icon, Tag } from '@blueprintjs/core';
import LookupInput from '@core/components/LookupInputFormField';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { updateRecordFormState } from '@redux/stores/form/actions';
import { IFormReducer } from '@redux/stores/form/reducer';
import { ISchemaReducer } from '@redux/stores/schemas/reducer';
import { Col, Form, Row } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';

interface Props {
  schema: SchemaEntity;
  schemaAssociationId: string;
  formRef: any;
  recordFormReducer: IFormReducer;
  updateFormState: any;
  schemaReducer: ISchemaReducer;
  // Schema action driven ↓
  required: boolean;
  disabled: boolean;
}

const AssociationFormField: React.FC<Props> = (props: Props) => {
  const {
    schema,
    schemaAssociationId,
    formRef,
    updateFormState,
    recordFormReducer,
    required,
    disabled,
    schemaReducer,
  } = props;

  const [findInSchemaRecord, setFindInSchemaRecord] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );

  const schemaAssociation: SchemaAssociationEntity | undefined = schema.associations.find(
    (association) => association.id === schemaAssociationId,
  );

  //  Find in schema / child schema parameters
  const findInSchemaId = schemaAssociation?.findInSchema;
  const findInChildSchemaId = schemaAssociation?.findInChildSchema;
  const findInSchemaSchema = schemaReducer?.list?.find(
    (schema) => schema.id === schemaAssociation?.findInSchema,
  );
  const findInChildSchemaSchema = schemaReducer?.list?.find(
    (schema) => schema.id === schemaAssociation?.findInChildSchema,
  );

  // To which entity the schema in reducer relates to (parent or child)
  let relatedEntityName: string | undefined;
  let relatedModuleName: string | undefined;

  if (schemaAssociation?.parentSchemaId === schema.id) {
    relatedEntityName = schemaAssociation.childSchema?.entityName;
    relatedModuleName = schemaAssociation.childSchema?.moduleName;
  } else if (schemaAssociation?.childSchemaId === schema.id) {
    relatedEntityName = schemaAssociation?.parentSchema?.entityName;
    relatedModuleName = schemaAssociation.parentSchema?.moduleName;
  }

  // Gather all associations for the current schemaAssociation from the form reducer
  const associations =
    recordFormReducer.modified[0]?.associations?.filter((association: any) => {
      return association.entity === `${relatedModuleName}:${relatedEntityName}`;
    }) || [];

  const isFieldDisabled = () => {
    // Disabled by schema action or no schema association found
    if (disabled || !schemaAssociation) {
      return true;
    }

    // Our schema is parent entity
    else if (schemaAssociation?.parentSchemaId === schema.id) {
      return (
        ['ONE_TO_ONE', 'MANY_TO_ONE'].includes(schemaAssociation.type) && associations.length > 0
      );
    }
    // Our schema is child entity
    else if (schemaAssociation?.childSchemaId === schema.id) {
      return (
        ['ONE_TO_ONE', 'ONE_TO_MANY'].includes(schemaAssociation.type) && associations.length > 0
      );
    } else {
      return false;
    }
  };

  const handleAddAssociation = (record: any) => {
    // Add record if it's not already in the associations array
    if (record && !associations.some((association: any) => association.recordId === record.id)) {
      let newModified: any;

      // Construct form reducer modified state
      if (recordFormReducer.modified.length > 0) {
        newModified = [{ ...recordFormReducer.modified[0] }];
      } else {
        newModified = [
          {
            schemaId: schema.id,
            entity: `${schema.moduleName}:${schema.entityName}`,
            associations: [],
          },
        ];
      }

      // Add new association to modified state
      newModified[0].associations.push({
        entity: record.entity,
        recordId: record.id,
        recordTitle: `${record.recordNumber ? record.recordNumber + ' - ' : ''}${record.title ? record.title : ''}`,
      });

      updateFormState({
        ...recordFormReducer,
        modified: newModified,
      });
    }

    formRef.current?.setFieldsValue({
      [`association-${schemaAssociationId}`]: undefined,
    });
  };

  const removeAssociation = (recordId: string) => {
    const associations = recordFormReducer.modified[0].associations;
    const newModified = [
      {
        ...recordFormReducer.modified[0],
        associations: associations?.filter((association: any) => association.recordId !== recordId),
      },
    ];
    updateFormState({
      ...recordFormReducer,
      modified: newModified,
    });
  };

  const handleAddFindInSchemaAssociation = (record: any) => {
    if (record) {
      setFindInSchemaRecord(record);
    } else {
      setFindInSchemaRecord(undefined);
    }
  };

  // If formField is set as required via schema action, and there are no associations - validate the field
  const areAssociationsRequired: boolean = required && associations.length === 0;

  // Diagnostics
  // console.log('%cdebug: Schema Association', 'color:aquamarine', schemaAssociation);
  // console.log('%cdebug: findInSchemaSchema', 'color:aquamarine', findInSchemaSchema);
  // console.log('%cdebug: findInChildSchemaSchema', 'color:aquamarine', findInChildSchemaSchema);

  return (
    <Callout
      compact
      intent={!schemaAssociation ? 'danger' : 'none'}
      title={
        schemaAssociation ? `${relatedEntityName} Associations` : 'Missing Schema Associations'
      }
      icon="link"
      style={{ borderRadius: 8, marginBottom: 15 }}
      key={schemaAssociation?.id + 'callout'}
    >
      <Row style={{ paddingBottom: 5 }} key={schemaAssociation?.id}>
        {/* Find In Schema 1. Form Field */}
        {findInSchemaSchema && schemaAssociation && findInSchemaSchema && (
          <Col span={24} style={{ marginTop: 10 }} key={schemaAssociation?.id}>
            <Form.Item
              key={`schemaAssociationId-${findInSchemaId}`}
              name={`association-${findInSchemaId}-${schemaAssociationId}`}
              label={`Select ${findInSchemaSchema?.entityName}`}
              labelCol={{ span: 24 }}
              style={{ marginBottom: 5 }}
              rules={[
                {
                  required: areAssociationsRequired,
                  message: `${relatedEntityName} association is required`,
                },
              ]}
            >
              <LookupInput
                key={`schemaAssociationId-${findInSchemaId}-lookupInput`}
                responseType="RECORD"
                schemaId={schema.id!}
                schemaAssociation={schemaAssociation!}
                onChange={handleAddFindInSchemaAssociation}
                customFilterEntity={`${findInSchemaSchema?.moduleName}:${findInSchemaSchema?.entityName}`}
              />
            </Form.Item>
          </Col>
        )}

        {/* Find in Schema 2. Form Field */}
        {findInSchemaRecord &&
          findInSchemaSchema &&
          findInChildSchemaSchema &&
          schemaAssociation && (
            <Col span={24} style={{ marginTop: 10 }} key={findInSchemaRecord?.id}>
              <Form.Item
                key={`schemaAssociationId-${findInSchemaRecord?.id}`}
                name={`association-${findInSchemaId}-${findInSchemaRecord?.id}`}
                label={`Add ${findInChildSchemaSchema?.entityName} to this ${schema.entityName}`}
                labelCol={{ span: 24 }}
                style={{ marginBottom: 5 }}
                rules={[
                  {
                    required: areAssociationsRequired,
                    message: `${relatedEntityName} association is required`,
                  },
                ]}
              >
                <LookupInput
                  key={`schemaAssociationId-${findInSchemaRecord?.id}-lookupInput`}
                  responseType="RECORD"
                  schemaId={schema?.id}
                  clearAfterSelect={true}
                  disabled={isFieldDisabled()}
                  schemaAssociation={schemaAssociation!}
                  onChange={handleAddAssociation}
                  customFilterRecordId={findInSchemaRecord.id}
                  customFilterEntity={`${findInSchemaSchema?.moduleName}:${findInSchemaSchema?.entityName}`}
                />
              </Form.Item>
            </Col>
          )}

        {/* Main Association Form Field - No Find in schema params */}
        {schemaAssociation && !findInSchemaId && (
          <Col span={24} style={{ marginTop: 10 }} key={schemaAssociation?.id}>
            <Form.Item
              key={schemaAssociationId}
              name={`association-${schemaAssociationId}`}
              label={`Add ${relatedEntityName} to this ${schema.entityName}`}
              labelCol={{ span: 24 }}
              initialValue={undefined}
              style={{ marginBottom: 5 }}
              rules={[
                {
                  required: areAssociationsRequired,
                  message: `${relatedEntityName} association is required`,
                },
              ]}
            >
              <LookupInput
                key={schemaAssociation?.id}
                disabled={isFieldDisabled()}
                responseType="RECORD"
                clearAfterSelect={true}
                schemaId={schema.id}
                schemaAssociation={schemaAssociation!}
                onChange={handleAddAssociation}
                customFilterRecordId={undefined}
                customFilterEntity={undefined}
              />
            </Form.Item>
          </Col>
        )}

        {/* Associations list */}
        {associations.length > 0 && (
          <Col span={24} style={{ marginTop: 5 }}>
            <Row>
              {associations.map((association: any) => (
                <Col span={24} key={association.recordId}>
                  <Tag
                    key={association.recordId}
                    fill
                    size="large"
                    intent="success"
                    endIcon={
                      <Icon
                        icon="cross"
                        color="#FA999C"
                        style={{ cursor: 'pointer' }}
                        onClick={() => removeAssociation(association.recordId)}
                      />
                    }
                    style={{
                      marginBottom: 5,
                      borderRadius: 5,
                      overflowX: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {association.recordTitle}
                  </Tag>
                </Col>
              ))}
            </Row>
          </Col>
        )}

        {/* Missing Schema Associations */}
        {!schemaAssociation && (
          <Col span={24} style={{ marginTop: 10 }}>
            <span>
              Please create schema association that is defined in schema action, or contact the
              Administrator.
            </span>
          </Col>
        )}
      </Row>
    </Callout>
  );
};
const mapState = (state: any) => ({
  recordFormReducer: state.recordFormReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  updateFormState: (params: IFormReducer) => dispatch(updateRecordFormState(params)),
});

export default connect(mapState, mapDispatch)(AssociationFormField);
