import { Button } from '@blueprintjs/core';
import { isSystemAdmin } from '@core/helpers/rbacRules';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { ViewConfigContext } from '@core/modules/ControlPanelModule/components/ViewConfigs/ViewConfigDefinitionEditor';
import { DefaultViewConfigTemplate } from '@core/modules/ControlPanelModule/components/ViewConfigs/ViewConfigDefinitionEditor/ViewConfigJSONDefinition/viewConfigTemplate';

interface Props {
  userReducer: any;
}

const ViewConfigInsertTemplateButton: React.FC<Props> = (props: Props) => {
  const { userReducer } = props;
  const { setDefinition, isSavingDefinition } = useContext(ViewConfigContext);

  const addTemplateToDefinition = () => {
    const template = DefaultViewConfigTemplate();
    setDefinition(JSON.stringify(template, null, '\t'));
  };

  return (
    <Button
      style={{ borderRadius: 5 }}
      disabled={isSavingDefinition || !isSystemAdmin(userReducer)}
      text="Insert Template"
      fill
      onClick={addTemplateToDefinition}
    />
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(ViewConfigInsertTemplateButton);
