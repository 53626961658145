import React, { useState } from 'react';

import { OrganizationEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/organization.entity';
import {
  OrganizationUserEntity,
} from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/organization.user.entity';


import { OrganizationSetupForm } from './OrganizationSetupForm';
import { OrganizationAdminUserForm } from './OrganizationAdminUserForm';
import { OrganizationSetupFinished } from './OrganizationSetupFinished';
import { httpPost } from '@core/http/requests';

export type Status = 'idle' | 'requesting' | 'success' | 'error';

export const OrganizationSetup: React.FC = () => {
  const [step, setStep] = useState(1);
  const [org, setOrg] = useState<Omit<OrganizationEntity, 'id'> | null>(null);
  const [status, setStatus] = useState<Status>('idle');
  const [token, setToken] = useState<string>();


  const handleOrganizationSubmit = async (orgData: Omit<OrganizationEntity, 'id'>) => {
    setOrg(orgData);
    setStep(2);
  };

  const handleUserSubmit = async (user: Omit<OrganizationUserEntity, 'id'>) => {
    try {
      setStatus('requesting');
      const result = await httpPost(
        'IdentityModule/v1.0/organizations/setup',
        { organization: org, adminUser: user },
      );
      setStatus('success');
      setToken(result.data.data.token);
      setStep(3);
    } catch (e) {
      console.error(e);
      setStatus('error');
    }
  };

  return (
    <>
      {step === 1 && (
        <OrganizationSetupForm
          data={org}
          onSubmit={handleOrganizationSubmit}
        />)}
      {step === 2 && (
        <OrganizationAdminUserForm
          orgName={org?.name || ''}
          onSubmit={handleUserSubmit}
          onBack={() => setStep(1)}
        />
      )}
      {step === 3 && (
        <OrganizationSetupFinished
          token={token}
          status={status}
        />
      )}
    </>
  );
};
