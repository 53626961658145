import { LockOutlined } from '@ant-design/icons';
import { Button, Dialog, Icon } from '@blueprintjs/core';
import NetomniaLogoWide from '@netomnia/assets/netomnia-logo-wide.svg';
import YouFibreLogoWide from '@youfibre/assets/youfibre-logo-wide.svg';
import Typography from '@core/components/Typography';
import history from '@core/helpers/browserHistory';
import { isUserAuthenticated } from '@core/helpers/rbacRules';
import { getOrganizationName } from '@core/http/helpers';
import { IdentityOrganizationUserLogin } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/types/identity.organization.user.login';
import {
  loginCancelRequest,
  loginRequest,
  logoutRequest,
  updateUserRolesAndPermissionsRequest,
} from '@redux/stores/identity/actions';
import { storeSelectedEntity, storeSelectedModule } from '@redux/stores/navigation/actions';
import { Col, Form, Input, Row } from 'antd';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import '@core/views/UserLoginView/styles.scss';

type PathParams = {
  url: string;
  recordId: string;
};
type PropsType = RouteComponentProps<PathParams> & {
  login: Function;
  logout: Function;
  loadUserFromToken: Function;
  cancelRequest: any;
  userReducer: any;
  navigationReducer: any;
  history: any;
  storeSelectedEntity: any;
  storeSelectedModule: any;
};

const UserLoginModal = (props: PropsType) => {
  const { userReducer, navigationReducer, login } = props;

  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    if (values?.email && values?.password) {
      login(values, (response: boolean) => {
        if (response) {
          // Redirect user to MFA login if available
          if (userReducer?.user?.enableMfa) {
            history.push('/login/mfa');
          }
          // If no MFA login available take the user to the previous page
          else if (!userReducer?.user?.enableMfa && navigationReducer.previousPage) {
            return history.push(navigationReducer.previousPage);
          }
          // In all other scenarios, take the user to the home page
          else {
            storeSelectedModule({ selectedModule: 'Home' });
            storeSelectedEntity({ selectedEntity: '' });
            return history.push('/');
          }
        }
      });
    }
  };

  const getLogoForOrganization = () => {
    const organizationName = getOrganizationName();

    switch (organizationName) {
      case 'YouFibre':
        return (
          <img
            src={YouFibreLogoWide}
            alt="Logo"
            style={{ width: 75, marginLeft: 7, verticalAlign: 'middle' }}
          />
        );
      case 'Netomnia':
        return (
          <img src={NetomniaLogoWide} alt="Logo" style={{ width: 110, verticalAlign: 'middle' }} />
        );
      default:
        return <Typography stronger>{organizationName}</Typography>;
    }
  };

  return (
    <Dialog
      className="loginModal"
      title={
        getOrganizationName() === 'Development' || getOrganizationName() === 'Sandbox' ? (
          <span>Odin {getOrganizationName()}</span>
        ) : (
          getLogoForOrganization()
        )
      }
      isCloseButtonShown={false}
      isOpen={
        !isUserAuthenticated(userReducer) &&
        props.location.pathname !== '/login' &&
        props.location.pathname !== '/forgot-password' &&
        !props.location.pathname.includes('/reset-password') &&
        !props.location.pathname.includes('/register') &&
        navigationReducer.previousPage
      }
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
    >
      <Row style={{ padding: 30 }}>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Row>
            <Col span={24} style={{ marginBottom: 20 }}>
              <Typography stronger style={{ textAlign: 'center', marginBottom: 15, fontSize: 16 }}>
                Your session has expired
              </Typography>
            </Col>
            <Col span={24} style={{ marginBottom: 25 }}>
              <Typography>If you would like to continue your session, please sign in.</Typography>
            </Col>
          </Row>

          <Form
            name="user-login"
            className="login-form"
            initialValues={{ remember: true }}
            form={form}
            onFinish={(values) => onFinish(values)}
            onKeyDown={(e) => {
              // If enter key is pressed, submit the form
              if (e.key === 'Enter' && !userReducer.isLoginRequesting) {
                form.submit();
              }
            }}
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your email' }]}
            >
              <Input
                style={{ borderRadius: 2 }}
                autoComplete="true"
                prefix={<i className="bi bi-at" style={{ marginRight: 0, fontSize: '1.5em' }} />}
                placeholder="Email Address"
                size="large"
                disabled={userReducer.isLoginRequesting}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your password' }]}
            >
              <Input
                style={{ borderRadius: 2 }}
                autoComplete="true"
                prefix={<i className="bi bi-key" style={{ marginRight: 5, fontSize: '1.4em' }} />}
                type="password"
                placeholder="Password"
                size="large"
                disabled={userReducer.isLoginRequesting}
              />
            </Form.Item>
            {userReducer.requireOTP && (
              <Form.Item name="otp" rules={[{ required: true, message: 'Please input your PIN' }]}>
                <Input
                  autoComplete="true"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="text"
                  placeholder="Authenticator PIN"
                  size="large"
                  disabled={userReducer.isLoggingIn}
                />
              </Form.Item>
            )}
            <Form.Item style={{ textAlign: 'center' }}>
              <Link className="login-form-forgot" to={`/forgot-password`}>
                Forgot password?
              </Link>
            </Form.Item>

            <Form.Item shouldUpdate={true} style={{ marginBottom: 5 }}>
              <Button
                large
                rightIcon={<Icon icon="log-in" />}
                intent="primary"
                type="submit"
                loading={userReducer.isLoginRequesting}
                style={{ minWidth: 150 }}
                disabled={
                  form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
                  userReducer.isLoginRequesting
                }
              >
                {userReducer.isLoginRequesting ? 'Logging in...' : 'Log in'}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Dialog>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  navigationReducer: state.navigationReducer,
});

const mapDispatch = (dispatch: any) => ({
  login: (payload: IdentityOrganizationUserLogin, cb: () => {}) =>
    dispatch(loginRequest(payload, cb)),
  logout: () => dispatch(logoutRequest()),
  cancelRequest: () => dispatch(loginCancelRequest()),
  storeSelectedModule: (params: { selectedModule: string }) =>
    dispatch(storeSelectedModule(params)),
  storeSelectedEntity: (params: { selectedEntity: string }) =>
    dispatch(storeSelectedEntity(params)),
  loadUserFromToken: (cb: () => {}) => dispatch(updateUserRolesAndPermissionsRequest()),
});

export default withRouter(connect(mapState, mapDispatch)(UserLoginModal));
