import { Button } from '@blueprintjs/core';
import { OrganizationEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/organization.entity';
import { Col, Form, Input, Row } from 'antd';
import React, { useCallback, useRef, useState } from 'react';

type OrganizationSetupFormProps = {
  data: Omit<OrganizationEntity, 'id'> | null;
  onSubmit: (data: Omit<OrganizationEntity, 'id'>) => Promise<void>;
};

export const OrganizationSetupForm: React.FC<OrganizationSetupFormProps> = ({ data, onSubmit }) => {
  const [name, setName] = useState(data?.name || '');
  const [crNumber, setCrNumber] = useState(data?.crNumber || '');
  const [vatNumber, setVatNumber] = useState(data?.vatNumber || '');
  const [billingReplyToEmail, setBillingReplyToEmail] = useState(data?.billingReplyToEmail || '');
  const [customerServiceReplyToEmail, setCustomerServiceReplyToEmail] = useState(
    data?.customerServiceReplyToEmail || '',
  );
  const [webUrl, setWebUrl] = useState(data?.webUrl || '');
  const [contactUrl, setContactUrl] = useState(data?.contactUrl || '');
  const [contactPhone, setContactPhone] = useState(data?.contactPhone || '');
  const [addressLine1, setAddressLine1] = useState(data?.addressLine1 || '');
  const [addressLine2, setAddressLine2] = useState(data?.addressLine2 || '');
  const [addressCity, setAddressCity] = useState(data?.addressCity || '');
  const [addressPostalCode, setAddressPostalCode] = useState(data?.addressPostalCode || '');
  const [countryCode, setCountryCode] = useState(data?.countryCode || '');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitOrganization = async () => {
    try {
      setIsSubmitting(true);
      await onSubmit({
        name,
        crNumber,
        vatNumber,
        billingReplyToEmail,
        customerServiceReplyToEmail,
        webUrl,
        contactUrl,
        contactPhone,
        addressLine1,
        addressLine2,
        addressCity,
        addressPostalCode,
        countryCode,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const shouldEnableSubmit = useCallback(() => {
    return name.length >= 5 && crNumber.length >= 3 && vatNumber.length >= 3;
  }, [name, crNumber, vatNumber]);

  const formRef = useRef(null);

  return (
    <Row
      style={{ background: 'white', height: 'calc(100vh - 40px)', overflowY: 'auto' }}
      justify={'center'}
    >
      <Col xs={24} lg={14} style={{ padding: 15 }}>
        <Form
          title="Setup Organization"
          layout={'vertical'}
          style={{ paddingBottom: '1rem' }}
          ref={formRef}
        >
          <Row gutter={18} justify="start">
            <Col span={24} style={{ marginBottom: 10 }}>
              <h2>1. Setup New Organization</h2>
            </Col>

            <Col span={12}>
              {/* 1 */}
              <Form.Item
                className="form-item"
                label="Organization Name"
                name="name"
                initialValue={name}
              >
                <Input placeholder="Name" onChange={(e) => setName(e.target.value)} />
                <span style={{ opacity: 0.3, fontSize: '0.8em' }}>Minimum 5 characters</span>
              </Form.Item>
            </Col>

            <Col span={12}>
              {/* 2 */}
              <Form.Item
                className="form-item"
                label="Company Registration Number (CRN)"
                name="crNumber"
                initialValue={crNumber}
              >
                <Input placeholder="CRN" onChange={(e) => setCrNumber(e.target.value)} />
                <span style={{ opacity: 0.3, fontSize: '0.8em' }}>Minimum 3 characters</span>
              </Form.Item>
            </Col>

            <Col span={12}>
              {/* 3 */}
              <Form.Item
                className="form-item"
                label="Company VAT Number"
                name="vatNumber"
                initialValue={vatNumber}
              >
                <Input placeholder="VAT Number" onChange={(e) => setVatNumber(e.target.value)} />
                <span style={{ opacity: 0.3, fontSize: '0.8em' }}>Minimum 3 characters</span>
              </Form.Item>
            </Col>

            <Col span={12}>
              {/* 4 */}
              <Form.Item
                className="form-item"
                label="Billing Reply To Email"
                name="billingReplyToEmail"
                initialValue={billingReplyToEmail}
              >
                <Input
                  placeholder="Billing Reply To Email"
                  onChange={(e) => setBillingReplyToEmail(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              {/* 5 */}
              <Form.Item
                className="form-item"
                label="Customer Service Reply To Email"
                name="customerServiceReplyToEmail"
                initialValue={customerServiceReplyToEmail}
              >
                <Input
                  placeholder="Customer Service Reply To Email"
                  onChange={(e) => setCustomerServiceReplyToEmail(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              {/* 6 */}
              <Form.Item className="form-item" label="Web URL" name="webUrl" initialValue={webUrl}>
                <Input placeholder="Web Url" onChange={(e) => setWebUrl(e.target.value)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              {/* 7 */}
              <Form.Item
                className="form-item"
                label="Contact Page URL"
                name="contactUrl"
                initialValue={contactUrl}
              >
                <Input
                  placeholder="Contact Page Url"
                  onChange={(e) => setContactUrl(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              {/* 8 */}
              <Form.Item
                className="form-item"
                label="Contact Phone Number"
                name="contactPhone"
                initialValue={contactPhone}
              >
                <Input
                  placeholder="Contact Phone Number"
                  onChange={(e) => setContactPhone(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              {/* 9 */}
              <Form.Item
                className="form-item"
                label="Address Line 1"
                name="addressLine1"
                initialValue={addressLine1}
              >
                <Input
                  placeholder="Address Line 1"
                  onChange={(e) => setAddressLine1(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              {/* 10 */}
              <Form.Item
                className="form-item"
                label="Address Line 2"
                name="addressLine2"
                initialValue={addressLine2}
              >
                <Input
                  placeholder="Address Line 2"
                  onChange={(e) => setAddressLine2(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              {/* 11 */}
              <Form.Item
                className="form-item"
                label="City"
                name="addressCity"
                initialValue={addressCity}
              >
                <Input placeholder="City" onChange={(e) => setAddressCity(e.target.value)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              {/* 12 */}
              <Form.Item
                className="form-item"
                label="Postal Code"
                name="addressPostalCode"
                initialValue={addressPostalCode}
              >
                <Input
                  placeholder="Postal Code"
                  onChange={(e) => setAddressPostalCode(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              {/* 13 */}
              <Form.Item
                className="form-item"
                label="Country Code"
                name="countryCode"
                initialValue={countryCode}
              >
                <Input
                  placeholder="Country Code"
                  onChange={(e) => setCountryCode(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12} justify="center" style={{ marginTop: 10 }}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Button
                large
                key="1"
                intent="primary"
                style={{ minWidth: 250 }}
                onClick={submitOrganization}
                disabled={isSubmitting || !shouldEnableSubmit()}
                text="Next"
                rightIcon="caret-right"
              />
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};
