// Convert anything to Sentence case. Example: UPPER_CASE_TEXT => Upper case text
export const toSentenceCase = (text: string) => {
  let modifiedText: string = '';
  modifiedText = text?.replace('_', ' ');
  modifiedText =
    modifiedText.toLowerCase().charAt(0).toUpperCase() + modifiedText?.slice(1).toLowerCase();
  return modifiedText;
};

export const splitCamelCase = (str: string) => {
  return str?.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
};
