import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';

import {
  lookupRecords as lookupRecordsRequest,
  searchRecordsRequest,
  updateRecordByIdRequest,
} from '../../../../../../../redux/stores/records/actions';
import { getSchemaByModuleAndEntityRequest } from '../../../../../../../redux/stores/schemas/actions';
import { loadTimeSlotsRequest } from '../../../../../../../redux/stores/appointments/actions';
import { useRequest } from '@core/hooks/useRequest';

import { useCalendarReducer } from './useCalendarReducer';
import dayjs from 'dayjs';
import { getRecordAssociationWithNestedEntitiesRequest } from '../../../../../../../redux/stores/recordAssociations/actions';
import { getAllRelations } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

const { FIELD_SERVICE_MODULE, IDENTITY_MODULE } = SchemaModuleTypeEnums;
const { SERVICE_APPOINTMENT_CONFIG, WORK_ORDER } = SchemaModuleEntityTypeEnums;

const USER = 'User';

const EARLIER_AVAILABILITY_START_AFTER_DAYS = 1;
const EARLIER_AVAILABILITY_PERIOD_DAYS = 3;

const getPageSizeForPeriod = (startDate?: string, endDate?: string) => {
  if (startDate && endDate) {
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    const diff = end.diff(start, 'days');

    // MonthView
    if (diff > 7) return 3000;

    // WeekView
    if (diff > 1) return 700;
  }

  // Default/DayView
  return 100;
};

const getEarlierAvailStartDate = () =>
  dayjs().add(EARLIER_AVAILABILITY_START_AFTER_DAYS, 'days').format('YYYY-MM-DD');

const getEarlierAvailEndDate = () =>
  dayjs()
    .add(EARLIER_AVAILABILITY_START_AFTER_DAYS + EARLIER_AVAILABILITY_PERIOD_DAYS, 'days')
    .format('YYYY-MM-DD');

export const useCalendarRequests = (actions: ReturnType<typeof useCalendarReducer>['actions']) => {
  const getSchema = useRequest(getSchemaByModuleAndEntityRequest);
  const lookupRecords = useRequest(lookupRecordsRequest);
  const searchRecords = useRequest(searchRecordsRequest);
  const loadTimeSlots = useRequest(loadTimeSlotsRequest);
  const updateRecord = useRequest(updateRecordByIdRequest);
  const getRecordAssociations = useRequest(getRecordAssociationWithNestedEntitiesRequest);

  const fetchSchedules = async (): Promise<DbRecordEntityTransform[]> => {
    try {
      actions.fetchSchedulesRequest();

      const schema = await getSchema({
        moduleName: FIELD_SERVICE_MODULE,
        entityName: SERVICE_APPOINTMENT_CONFIG,
      });
      if (!schema) {
        actions.fetchSchedulesError({
          message: `Unable to retrieve schema for ${FIELD_SERVICE_MODULE}:${SERVICE_APPOINTMENT_CONFIG}`,
        });
        return [];
      }
      const records = await lookupRecords({
        schema,
        query: {
          entity: `${FIELD_SERVICE_MODULE}:${SERVICE_APPOINTMENT_CONFIG}`,
          properties: [
            // {
            //   columnName: 'ExPolygonId',
            //   operator: '<>',
            //   value: '*',
            // },
          ],
        },
      });

      actions.fetchSchedulesResponse(records);
      return records;
    } catch (err: unknown) {
      actions.fetchSchedulesError(err as { message: string });
      return [];
    }
  };

  const fetchScheduleWorkOrders = async (
    scheduleIds: string[],
    startDate?: string,
    endDate?: string,
  ): Promise<DbRecordEntityTransform[]> => {
    try {
      actions.fetchWorkOrdersRequest();
      const schema = await getSchema({ moduleName: FIELD_SERVICE_MODULE, entityName: WORK_ORDER });
      if (!schema) {
        actions.fetchWorkOrdersError({
          message: `Unable to retrieve schema for ${FIELD_SERVICE_MODULE}:${WORK_ORDER}`,
        });
        return [];
      }

      const size = getPageSizeForPeriod(startDate, endDate);

      const { data: result } = await searchRecords({
        schema,
        searchQuery: {
          schemas: schema.id,
          pageable: {
            size,
          },
          terms: '',
          sort: [
            {
              'ServiceAppointment.dbRecords.properties.TimeBlock.keyword': {
                order: 'asc',
              },
            },
          ],
          boolean: {
            must: [
              {
                terms: {
                  'properties.ScheduleId.keyword': scheduleIds,
                },
              },
              {
                range: {
                  'ServiceAppointment.dbRecords.properties.Date': {
                    gte: startDate,
                    lte: endDate,
                  },
                },
              },
            ],
          },
        },
        legacyResponse: true, // Returns records with associations
      });
      actions.fetchWorkOrdersResponse(result.data);
      return result.data;
    } catch (err: unknown) {
      actions.fetchWorkOrdersError(err as { message: string });
      return [];
    }
  };

  const fetchUtilization = async (params: {
    scheduleIds: string[];
    start: string;
    end: string;
    type: string;
  }) => {
    try {
      actions.fetchUtilizationRequest();
      const result = await loadTimeSlots({ ...params, isOverview: true });
      actions.fetchUtilizationResponse(result.data);
      return result.data;
    } catch (err: unknown) {
      actions.fetchUtilizationError(err as { message: string });
      return [];
    }
  };

  const fetchScheduleEarlierAvailability = async ({
    scheduleIds,
  }: {
    scheduleIds: string[];
  }): Promise<DbRecordEntityTransform[]> => {
    try {
      actions.fetchEarlierAvailabilityRequest();
      const schema = await getSchema({ moduleName: FIELD_SERVICE_MODULE, entityName: WORK_ORDER });
      if (!schema) {
        actions.fetchEarlierAvailabilityError({
          message: `Unable to retrieve schema for ${FIELD_SERVICE_MODULE}:${WORK_ORDER}`,
        });
        return [];
      }

      const startDate = getEarlierAvailStartDate();
      const endDate = getEarlierAvailEndDate();

      const size = getPageSizeForPeriod(startDate, endDate);

      const { data: result } = await searchRecords({
        schema,
        searchQuery: {
          schemas: schema.id,
          pageable: {
            size,
          },
          terms: '',
          sort: [
            {
              'Address.dbRecords.properties.PostSector.keyword': {
                order: 'asc',
              },
            },
          ],
          boolean: {
            must: [
              {
                terms: {
                  'properties.ScheduleId.keyword': scheduleIds,
                },
              },
              {
                term: {
                  'properties.EarlierAvailability': true,
                },
              },
              {
                range: {
                  'ServiceAppointment.dbRecords.properties.Date': {
                    gte: startDate,
                    lte: endDate,
                  },
                },
              },
            ],
          },
        },
        legacyResponse: true, // Returns records with associations
      });

      actions.fetchEarlierAvailabilityResponse(result.data);
      return result.data;
    } catch (err: unknown) {
      actions.fetchEarlierAvailabilityError(err as { message: string });
      return [];
    }
  };

  const fetchScheduleEngineers = async ({
    scheduleIds,
  }: {
    scheduleIds: string[];
  }): Promise<DbRecordEntityTransform[]> => {
    try {
      actions.fetchEngineersRequest();
      const schema = await getSchema({
        moduleName: FIELD_SERVICE_MODULE,
        entityName: SERVICE_APPOINTMENT_CONFIG,
      });
      if (!schema) {
        actions.fetchEngineersError({
          message: `Unable to retrieve schema for ${FIELD_SERVICE_MODULE}:${SERVICE_APPOINTMENT_CONFIG}`,
        });
        return [];
      }

      const { results } = await getRecordAssociations({
        schema,
        recordId: scheduleIds[0],
        entity: USER,
        nestedEntities: [],
        withLinks: true,
      });
      const engineers = getAllRelations(results, 'User') ?? [];
      actions.fetchEngineersResponse(engineers);
      return engineers;
    } catch (err: unknown) {
      actions.fetchEngineersError(err as { message: string });
      return [];
    }
  };

  const assignWorkOrder = async (workOrder: DbRecordEntityTransform, engineerId: string) => {
    actions.updateWorkOrder(workOrder, true);
    const schema = await getSchema({ moduleName: FIELD_SERVICE_MODULE, entityName: WORK_ORDER });
    if (!schema) {
      return;
    }

    const result = await updateRecord({
      schema,
      recordId: workOrder.id,
      createUpdate: {
        id: workOrder.id,
        schemaId: schema.id,
        type: workOrder.type,
        properties: {
          EngineerId: engineerId,
        },
      },
    });

    actions.updateWorkOrder({
      ...workOrder,
      properties: { ...workOrder.properties, EngineerId: engineerId },
    });
    return result.data;
  };

  return {
    fetchSchedules,
    fetchScheduleWorkOrders,
    fetchUtilization,
    fetchScheduleEarlierAvailability,
    fetchScheduleEngineers,
    assignWorkOrder,
  };
};
