import { Col, Row } from 'antd';
import React, { FC, useContext } from 'react';
import { DetailViewContext } from '@core/components/DetailViewContextProvider';
import MyCasesViewV2 from '../MyCasesView';

interface Props {
  isQuickView?: boolean;
}

const CaseDetailView: FC<Props> = (props: Props) => {
  const { record } = useContext(DetailViewContext);

  return (
    <>
      <Row>
        <Col span={24} style={{ padding: 15 }}>
          <MyCasesViewV2 defaultCase={record} />
        </Col>
      </Row>
    </>
  );
};

export default CaseDetailView;
