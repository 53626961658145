import { NonIdealState } from '@blueprintjs/core';
import { httpPost } from '@core/http/requests';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import UserCasesTable from '../UserCasesTable';

interface Props {
  team: DbRecordEntityTransform;
  searchInput: string;
}

const TeamUsersTable: React.FC<Props> = (props: Props) => {
  const { team, searchInput } = props;
  const [userList, setUserList] = useState<DbRecordEntityTransform[]>([]);
  const [isSearchingUsers, setIsSearchingUsers] = useState<boolean>(false);

  useEffect(() => {
    getUsersInTeam();
  }, [team]);

  const getUsersInTeam = async () => {
    const query = {
      returnQueryPlan: false,
      query: {
        entity: 'IdentityModule:User',
        type: 'and',
        value: [
          {
            columnName: 'TeamId',
            operator: 'eq',
            value: team.id,
          },
        ],
        returnProperties: ['id', 'title'],
        sort: {
          createdAt: {
            order: 'asc',
          },
        },
        pageSize: 100,
        pageNumber: 0,
      },
    };

    try {
      setIsSearchingUsers(true);

      await httpPost(`IdentityModule/v2.0/records/search`, query).then((res) => {
        const records = res?.data?.data?.records || [];
        setUserList(records);
        setIsSearchingUsers(false);
      });
    } catch (e: any) {
      setIsSearchingUsers(false);
    }
  };

  const getInitialsFromUserName = (userName: string) => {
    const splitUserName = userName.split(' ');
    const firstName = splitUserName[0]?.toUpperCase() || '-';
    const lastName = splitUserName[splitUserName.length - 1]?.toUpperCase() || '-';
    return firstName[0] + lastName[0];
  };

  // Filter User List on Quick search term
  let filteredUserList = Object.assign([], userList);

  // sort filtered users on title
  filteredUserList.sort((a: any, b: any) => {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  });

  if (searchInput.length > 0) {
    filteredUserList = filteredUserList.filter((user: any) => {
      return user.title.toLowerCase().includes(searchInput.toLowerCase());
    });
  }

  return (
    <Row
      style={{
        height: 'calc(100vh - 170px)',
        overflowY: 'auto',
        alignContent: 'flex-start',
        border: '1px solid #f2f2f2',
        borderRadius: 8,
        padding: 10,
        paddingRight: 15,
      }}
    >
      {/* Empty List */}
      {!isSearchingUsers && filteredUserList.length === 0 ? (
        <Col span={24} style={{ height: '100%' }}>
          <NonIdealState icon="search" title="No Users in the Group" />
        </Col>
      ) : (
        filteredUserList.map((user: any) => (
          <>
            <Col span={24} style={{ padding: '5px 5px', marginBottom: 10 }}>
              <>
                {/* Avatar */}
                <div
                  style={{
                    display: 'inline-block',
                    background: '#EFEFEF',
                    padding: 6,
                    fontSize: '0.9em',
                    height: 32,
                    width: 32,
                    lineHeight: '22px',
                    textAlign: 'center',
                    borderRadius: 50,
                    marginRight: 10,
                  }}
                >
                  <span>{user?.title ? getInitialsFromUserName(user.title) : '--'}</span>
                </div>
                {/* User name */}
                {user?.title}
              </>
            </Col>
            <Col span={24}>
              <UserCasesTable user={user!} />
            </Col>
            <Col span={24} style={{ borderBottom: '1px solid #f2f2f2', marginBottom: 10 }} />
          </>
        ))
      )}
    </Row>
  );
};

export default TeamUsersTable;
