import {
  Alert,
  Button,
  ButtonGroup,
  InputGroup,
  Intent,
  Section,
  SectionCard,
  Tag,
} from '@blueprintjs/core';
import { useRequest } from '@core/hooks/useRequest';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import {
  deleteSchemaAssociationsRequest,
  getSchemaAssociationsRequest,
} from '@redux/stores/schemasAssociations/actions';
import { Col, Row } from 'antd';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { SchemaDetailsContext } from '../..';
import { Empty } from '../Empty';
import { DiagramView } from './DiagramView';
import ListView from './ListView';
import ManageLinkDialog from './ManageLinkDialog';

type SearchableSchemaAssociationEntity = SchemaAssociationEntity & { SearchKey: string } & {
  SearchKey: string;
};

interface Props {
  getAssociations: (params: any, cb: any) => void;
}

const LinksSection: FC<Props> = (props: Props) => {
  const { setSchema, schema } = useContext(SchemaDetailsContext);
  const { getAssociations } = props;
  const [viewMode, setViewMode] = useState<'list' | 'diagram'>('list');
  const [search, setSearch] = useState('');
  const [manageLinks, setManageLinks] = useState(false);
  const [linkToRemove, setLinkToRemove] = useState<SchemaAssociationEntity>();
  const [selectedLink, setSelectedLink] = useState<SchemaAssociationEntity>();

  const deleteSchemaAssociations = useRequest(deleteSchemaAssociationsRequest);
  const [parentAssociations, setParentAssociations] = useState<SchemaAssociationEntity[]>([]);
  const [childAssociations, setChildAssociations] = useState<SchemaAssociationEntity[]>([]);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  useEffect(() => {
    if (!schema?.id) return;
    getAssociationsForList(schema.id);
  }, [schema?.id]);

  const getAssociationsForList = (schemaId: string) => {
    setIsRequesting(true);
    getAssociations(
      { schemaId },
      (res: {
        childAssociations: SchemaAssociationEntity[];
        parentAssociations: SchemaAssociationEntity[];
      }) => {
        setChildAssociations(res.childAssociations);
        setParentAssociations(res.parentAssociations);
        setIsRequesting(false);
      },
    );
  };

  const associations = useMemo(() => {
    const result: SearchableSchemaAssociationEntity[] = [];
    const processedAssociationIds: string[] = [];

    childAssociations.forEach((childAssociation) => {
      if (processedAssociationIds.includes(childAssociation.id)) return;

      processedAssociationIds.push(childAssociation.id);
      result.push({
        ...childAssociation,
        SearchKey: childAssociation.childSchema?.entityName || '',
      });
    });

    parentAssociations.forEach((parentAssociation) => {
      if (processedAssociationIds.includes(parentAssociation.id)) return;

      processedAssociationIds.push(parentAssociation.id);
      result.push({
        ...parentAssociation,
        SearchKey: parentAssociation.parentSchema?.entityName || '',
      });
    });

    // Sort first by whether parentSchemaId matches schema.id, then alphabetically
    result.sort((a, b) => {
      const aIsParent = a.parentSchemaId === schema.id;
      const bIsParent = b.parentSchemaId === schema.id;
      if (aIsParent !== bIsParent) {
        return aIsParent ? -1 : 1;
      }
      return a.SearchKey.localeCompare(b.SearchKey);
    });
    return result;
  }, [childAssociations, parentAssociations, schema.id]);

  const filteredAssociations = associations.filter((association) => {
    const searchText = search.toLocaleLowerCase();
    const labelFilter = association.label.toLocaleLowerCase().includes(searchText);
    const searchKeyFilter = association.SearchKey.toLocaleLowerCase().includes(searchText);
    return labelFilter || searchKeyFilter;
  });

  const showLinkDialog = (link?: SchemaAssociationEntity) => {
    setSelectedLink(link);
    setManageLinks(true);
  };

  // Set the link to remove alert panel
  const onRemoveLink = (link: SchemaAssociationEntity) => {
    setLinkToRemove(link);
  };

  const removeLink = useCallback(async () => {
    if (!schema || !linkToRemove) return;

    try {
      await deleteSchemaAssociations({
        schemaId: schema.id,
        associationId: linkToRemove.id,
      });

      // Remove the link from the associations and schema
      setChildAssociations(
        childAssociations.filter((association) => association.id !== linkToRemove.id),
      );
      setParentAssociations(
        parentAssociations.filter((association) => association.id !== linkToRemove.id),
      );
      setSchema({
        ...schema,
        associations: associations.filter((association) => association.id !== linkToRemove.id),
      });
      setLinkToRemove(undefined);
    } catch (error) {}
  }, [schema?.id, linkToRemove?.id]);

  const onUpdate = (association: SchemaAssociationEntity) => {
    // Update the association in the associations array
    setChildAssociations(childAssociations.map((a) => (a.id === association.id ? association : a)));
    setParentAssociations(
      parentAssociations.map((a) => (a.id === association.id ? association : a)),
    );
    setSchema({
      ...schema,
      associations: associations.map((a) => (a.id === association.id ? association : a)),
    });
  };

  const onCreateLink = (association: SchemaAssociationEntity) => {
    // Add new association to the schema
    setSchema({
      ...schema,
      associations: [...schema.associations, association],
    });

    if (association.childSchemaId === schema.id) {
      setChildAssociations([...childAssociations, association]);
    } else {
      setParentAssociations([...parentAssociations, association]);
    }
  };

  const parentSchema = linkToRemove?.parentSchema || schema;
  const childSchema = linkToRemove?.childSchema || schema;
  const parentEntityName = parentSchema?.entityName || schema?.entityName;
  const childEntityName = childSchema?.entityName || schema?.entityName;

  return (
    <>
      <Section
        className="schema-links"
        title="Links"
        rightElement={
          <>
            {/* Search Links */}
            <InputGroup
              round
              type="search"
              id="schema-properties"
              leftIcon="search"
              placeholder="Search"
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
            />

            {/* Diagram View Option */}
            <ButtonGroup>
              <Button
                icon="many-to-one"
                variant={viewMode === 'diagram' ? 'solid' : 'outlined'}
                intent={viewMode === 'diagram' ? 'primary' : 'none'}
                onClick={() => setViewMode('diagram')}
              />
              <Button
                icon="list"
                variant={viewMode === 'list' ? 'solid' : 'outlined'}
                intent={viewMode === 'list' ? 'primary' : 'none'}
                onClick={() => setViewMode('list')}
              />
            </ButtonGroup>

            {/* New Link Option */}
            <Button
              outlined
              icon="plus"
              intent="success"
              text="Add"
              onClick={() => showLinkDialog()}
              style={{ borderRadius: 5 }}
            />
          </>
        }
      >
        <SectionCard
          padded={false}
          className="limited-height-mid"
          style={{
            overflowY: 'auto',
            padding: 0,
          }}
        >
          {isRequesting && <></>}
          {associations.length === 0 ? (
            <Empty icon="search" text="No links" />
          ) : viewMode === 'list' ? (
            <ListView
              links={filteredAssociations}
              onLinkClick={showLinkDialog}
              onLinkRemove={onRemoveLink}
              schema={schema}
            />
          ) : (
            <DiagramView
              schema={schema}
              links={filteredAssociations}
              onLinkClick={showLinkDialog}
              onLinkRemove={onRemoveLink}
            />
          )}
        </SectionCard>
      </Section>
      {manageLinks && (
        <ManageLinkDialog
          schema={schema}
          link={selectedLink}
          isOpen={manageLinks}
          onClose={() => setManageLinks(false)}
          onCreate={onCreateLink}
          onUpdate={onUpdate}
        />
      )}
      {!!linkToRemove && (
        <Alert
          isOpen={true}
          canEscapeKeyCancel
          canOutsideClickCancel
          intent={Intent.DANGER}
          loading={isRequesting}
          style={{ minWidth: '500px' }}
          cancelButtonText="Cancel"
          confirmButtonText="Remove"
          onClose={() => setLinkToRemove(undefined)}
          onConfirm={removeLink}
        >
          <Row>
            <Col span={24}>
              <h3 style={{ marginTop: 0, marginBottom: '1rem' }}>Remove Link</h3>
            </Col>

            {/* Show the parent -> type -> child in the col = 24 */}
            <Col span={24} style={{ marginTop: 10 }}>
              <div style={{ fontWeight: 600, fontSize: '1em' }}>
                <span style={{ fontWeight: 600, fontSize: '1em' }}>{parentEntityName}</span>
                <i className="bi bi-arrow-right" style={{ marginLeft: 8 }} />
                <Tag
                  round
                  minimal
                  fill={false}
                  style={{
                    verticalAlign: 'middle',
                    marginLeft: 8,
                    marginBottom: 4,
                  }}
                >
                  {linkToRemove?.type}
                </Tag>
                <i className="bi bi-arrow-right" style={{ marginLeft: 8, marginRight: 8 }} />
                <span style={{ fontWeight: 600, fontSize: '1em' }}>{childEntityName}</span>
              </div>
            </Col>
            <Col span={24} style={{ marginTop: 10 }}>
              <p>Are you sure you want to remove this link?</p>
            </Col>
          </Row>
        </Alert>
      )}
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  getAssociations: (params: any, cb: any) => dispatch(getSchemaAssociationsRequest(params, cb)),
});
export default connect(mapState, mapDispatch)(LinksSection);
