import React, { Fragment, useEffect, useState } from 'react';

import { Divider, Space } from 'antd';
import { ButtonGroup } from '@blueprintjs/core';
import {
  useOdinSearchActions,
  useOdinSearchState,
} from '@core/modules/SupportModule/views/SupportDashboard/views/CaseManagementCaseListView/context/provider';

import { OdinDropdown } from '../OdinDropdown';
import { GLOBAL_FILTERS, GlobalFilterItem } from './GlobalFilters.data';

export const GlobalFilters: React.FC = () => {
  const [activeGlobalFilter, setActiveGlobalFilter] = useState<GlobalFilterItem | null>(null);

  const { selectedParams, savedParams } = useOdinSearchState();
  const { replaceFilters } = useOdinSearchActions();

  // Check if the selected filters match any of the global filters
  useEffect(() => {
    if (!selectedParams?.filters?.length) return setActiveGlobalFilter(null);

    for (const globalFilter of GLOBAL_FILTERS) {
      const isGlobalFilter = globalFilter.items.find(
        (item) => JSON.stringify(item.filters) === JSON.stringify(selectedParams.filters),
      );

      if (isGlobalFilter) return setActiveGlobalFilter(isGlobalFilter);
    }

    setActiveGlobalFilter(null);
  }, [selectedParams]);

  // Everytime the activeGlobalFilter changes, completely replace the filters in the search params
  useEffect(() => {
    if (!activeGlobalFilter || !activeGlobalFilter.filters) return;

    replaceFilters(activeGlobalFilter.filters);
  }, [activeGlobalFilter]);

  return (
    <Fragment>
      {savedParams.length > 0 ? (
        <Space>
          <Divider type="vertical" />
        </Space>
      ) : null}
      <Space>
        {GLOBAL_FILTERS.map(({ placeholder, items }) => {
          const selectedFilter = items.find((item) => item.id === activeGlobalFilter?.id);

          return (
            <ButtonGroup key={placeholder}>
              <OdinDropdown
                placeholder={placeholder}
                items={items}
                selectedItem={selectedFilter || null}
                onChange={(selected) => setActiveGlobalFilter(selected)}
                filterable={false}
                size="medium"
                rounded
                className={selectedFilter ? 'is-active' : undefined}
              />
            </ButtonGroup>
          );
        })}
      </Space>
    </Fragment>
  );
};
