import { Col, Row } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import './styles.scss';
import ViewConfigsListViewTable from '@core/modules/ControlPanelModule/components/ViewConfigs/ViewConfigsListViewTable';

const ViewConfigsListView: React.FC = () => {
  return (
    <Row>
      {/* Content */}
      <Col span={24}>
        <ViewConfigsListViewTable />
      </Col>
    </Row>
  );
};

export default withRouter(ViewConfigsListView);
