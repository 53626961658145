export const createEmailTemplateFormFields = [
  {
    label: 'Template ID',
    property: 'templateId',
    type: 'TEXT',
    isRequired: true,
    message: 'Please input template ID',
    isHidden: false,
    value: undefined,
  },
  {
    label: 'Name',
    property: 'name',
    type: 'TEXT',
    isRequired: true,
    message: 'Please input Name',
    isHidden: false,
    value: undefined,
  },
  {
    label: 'Label',
    property: 'label',
    type: 'TEXT',
    isRequired: true,
    message: 'Please input Label',
    isHidden: false,
    value: undefined,
  },
  {
    label: 'Description',
    property: 'description',
    type: 'TEXT',
    isRequired: true,
    message: 'Please input Description',
    isHidden: false,
    value: undefined,
  },
];
