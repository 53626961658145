export const CASE_MANAGEMENT_SCHEDULING_TOGGLE_USER_DIALOG =
  'CASE_MANAGEMENT_SCHEDULING_TOGGLE_USER_DIALOG';
export const CASE_MANAGEMENT_SCHEDULING_SET_SELECTED_USER =
  'CASE_MANAGEMENT_SCHEDULING_SET_SELECTED_USER';
export const CASE_MANAGEMENT_SCHEDULING_IS_LOADING_LIST_VIEW =
  'CASE_MANAGEMENT_SCHEDULING_IS_LOADING_LIST_VIEW';
export const CASE_MANAGEMENT_SCHEDULING_SET_LIST_VIEW_DATA =
  'CASE_MANAGEMENT_SCHEDULING_SET_LIST_VIEW_DATA';
export const CASE_MANAGEMENT_SCHEDULING_UPDATE_USERS_TIMEBLOCKS =
  'CASE_MANAGEMENT_SCHEDULING_UPDATE_USERS_TIMEBLOCKS';
export const CASE_MANAGEMENT_SCHEDULING_SET_ZOOM = 'CASE_MANAGEMENT_SCHEDULING_SET_ZOOM';
export const CASE_MANAGEMENT_SCHEDULING_REMOVE_TIME_BLOCK_FROM_USER =
  'CASE_MANAGEMENT_SCHEDULING_REMOVE_TIME_BLOCK_FROM_USER';
export const CASE_MANAGEMENT_SCHEDULING_ADD_INITIAL_TIME_BLOCK =
  'CASE_MANAGEMENT_SCHEDULING_ADD_INITIAL_TIME_BLOCK';
export const CASE_MANAGEMENT_SCHEDULING_REMOVE_INITIAL_TIME_BLOCK =
  'CASE_MANAGEMENT_SCHEDULING_REMOVE_INITIAL_TIME_BLOCK';
