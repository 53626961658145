import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { Checkbox, Col, Divider, Empty, Image, Row, Spin } from 'antd';
import { FC, useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { uploadFileFromAssociationContext } from '../index';
import { getBrowserPath } from '@core/helpers/recordHelpers';
import { getSchemaFromShortListByModuleAndEntity } from '@core/helpers/schemaHelpers';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../redux/stores/schemas/actions';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '../../../../redux/stores/recordAssociations/actions';
import {
  ADD_FILE_TO_SELECTION,
  REMOVE_FILE_FROM_SELECTION,
  SET_FILE_LIST,
  SET_LOADING_FILES,
  SET_SELECTED_RECORD,
} from '../store/constants';

interface Props {
  getAssociations: Function;
  getSchema: Function;
  schemaReducer: any;
}

const { FILE } = SchemaModuleEntityTypeEnums;

const FileBrowser: FC<Props> = (props: Props) => {
  const { getAssociations, schemaReducer, getSchema } = props;
  const { state, dispatch } = useContext(uploadFileFromAssociationContext);

  // When record/folder is selected, get all file associations
  useEffect(() => {
    const moduleName = state.selectedRecord?.entity?.split(':')[0];
    const entityName = state.selectedRecord?.entity?.split(':')[1];
    const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );

    if (!shortlistSchema) {
      getSchema(
        { moduleName: moduleName, entityName: entityName },
        (responseSchema: SchemaEntity) => {
          if (responseSchema) {
            getAllFiles(responseSchema);
          }
        },
      );
    } else {
      getAllFiles(shortlistSchema);
    }
  }, [state.selectedRecord]);

  // Get all associated File records
  const getAllFiles = (schema: SchemaEntity) => {
    dispatch({ type: SET_LOADING_FILES, payload: true });
    getAssociations(
      {
        recordId: state.selectedRecord?.id,
        schema: schema,
        entities: [FILE],
      },
      (response: any) => {
        dispatch({ type: SET_LOADING_FILES, payload: false });
        if (response.results?.[FILE]?.dbRecords?.length > 0) {
          dispatch({ type: SET_FILE_LIST, payload: response.results?.[FILE]?.dbRecords });
        }
      },
    );
  };

  // Shorten big filenames
  const prettifyFileName = (fileName: string) => {
    let response: string = '';
    response = fileName.substring(fileName.lastIndexOf('/') + 1);

    if (response.length > 25) {
      response = response.substring(0, 10) + '...' + response.slice(-10);
    }
    return response;
  };

  const addOrRemoveFileFromTheList = (file: DbRecordEntityTransform) => {
    if (state.selectedFiles.find((f: any) => f.id === file.id)) {
      dispatch({ type: REMOVE_FILE_FROM_SELECTION, payload: file });
    } else {
      dispatch({ type: ADD_FILE_TO_SELECTION, payload: file });
    }
  };

  const isFileSelected = (file: DbRecordEntityTransform) => {
    return state.selectedFiles.find((f: any) => f.id === file.id);
  };

  const fileListHeader = () => {
    return (
      <>
        <Row align="middle" style={{ fontWeight: 500, background: '#fafafa', padding: '5px' }}>
          <Col span={2}>
            <i
              className="bi bi-arrow-90deg-up clickable"
              style={{ fontWeight: 500 }}
              onClick={() => dispatch({ type: SET_SELECTED_RECORD, payload: undefined })}
            />
          </Col>
          <Col span={4}>
            <span>Image</span>
          </Col>
          <Col span={isMobile ? 6 : 4}>
            <span>Record #</span>
          </Col>
          <Col span={10} style={{ display: isMobile ? 'none' : 'block' }}>
            <span>Filename</span>
          </Col>
          <Col span={4}>
            <span>Type</span>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Divider style={{ marginTop: 0, marginBottom: 15 }} />
          </Col>
        </Row>
      </>
    );
  };

  const renderFileList = () => {
    if (state.loadingFiles) {
      return (
        <Col span={24} style={{ padding: 80, textAlign: 'center' }}>
          <Spin size="large" style={{ marginBottom: 20 }} />
          <br />
          <span>Loading...</span>
        </Col>
      );
    } else if (!state.loadingFiles && state.fileList.length > 0) {
      let filteredFileList = Object.assign(state.fileList);

      if (state.searchTerm.length > 0) {
        filteredFileList = filteredFileList.filter(
          (f: any) => f.recordNumber?.indexOf(state.searchTerm) > -1,
        );
      }

      return (
        <Col span={24} style={{ padding: 0, fontSize: '0.9em' }}>
          <Row align="middle" style={{ padding: '0 5px' }}>
            {filteredFileList.map((file: DbRecordEntityTransform, i: number) => (
              <>
                {/* Radio button */}
                <Col span={2}>
                  <Checkbox
                    checked={isFileSelected(file)}
                    onChange={() => addOrRemoveFileFromTheList(file)}
                  />
                </Col>

                {/* Thumbnail / No preview icon */}
                <Col span={4}>
                  {['image/jpeg', 'image/png'].includes(getProperty(file, 'Mimetype')) ? (
                    <Image
                      src={getProperty(file, 'Url')}
                      style={{ height: isMobile ? 30 : 50, width: isMobile ? 40 : 60 }}
                    />
                  ) : (
                    <Row
                      align="middle"
                      style={{
                        height: isMobile ? 30 : 50,
                        width: isMobile ? 40 : 60,
                        textAlign: 'center',
                        border: '1px solid #dddbda',
                      }}
                    >
                      <Col span={24}>
                        <i className="bi bi-eye-slash" style={{ fontSize: '1.2em' }} />
                      </Col>
                    </Row>
                  )}
                </Col>

                {/* Record number */}
                <Col span={isMobile ? 6 : 4}>
                  <span>{file.recordNumber || '-'}</span>
                </Col>

                {/* File name */}
                <Col span={10} style={{ display: isMobile ? 'none' : 'block' }}>
                  <Link target="_blank" to={getBrowserPath(file)}>
                    {prettifyFileName(getProperty(file, 'Url'))}
                  </Link>
                </Col>

                {/* Mimetype */}
                <Col span={4}>
                  <span>{getProperty(file, 'Mimetype')}</span>
                </Col>

                {state.fileList.length > i + 1 ? (
                  <Col span={24}>
                    <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                  </Col>
                ) : (
                  <></>
                )}
              </>
            ))}
          </Row>
        </Col>
      );
    } else {
      return (
        <Col span={24} style={{ padding: 80, textAlign: 'center' }}>
          <Empty description="No files to show" />
        </Col>
      );
    }
  };

  return (
    <div>
      {state.selectedRecord ? (
        <Row>
          <Col span={24}>{fileListHeader()}</Col>
          {renderFileList()}
        </Row>
      ) : (
        <></>
      )}
    </div>
  );
};

const mapState = (state: any) => ({
  userInterfaceReducer: state.userInterfaceReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(FileBrowser);
