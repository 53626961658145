import { FileOutlined } from '@ant-design/icons';
import { Button, Card, List, Typography } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IAutosplicingReducer } from '../../store/types';
import { httpGet } from '@core/http/requests';
import { displayMessage } from '../../../../../../../redux/stores/messages/reducers';

type Props = {
  alertMessage: any;
  autosplicingReducer: IAutosplicingReducer;
};

interface State {
  isLoading: boolean;
  data: any[];
}

class AutosplicingSplicingMatricesFiles extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
    };
  }

  componentDidMount() {
    this.getSplicingMatricesByClosureId();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (
      prevProps.autosplicingReducer?.formData?.closureId !==
      this.props.autosplicingReducer?.formData?.closureId
    ) {
      this.getSplicingMatricesByClosureId();
    }
  }

  getFileName = (key: string) => {
    const split = key.split('/');
    return split[split.length - 1];
  };

  private async getSplicingMatricesByClosureId() {
    const { autosplicingReducer } = this.props;

    const { closure_id, cable_id } = autosplicingReducer?.formData!;

    if (closure_id) {
      this.setState({
        isLoading: true,
      });

      let pathName = `ProjectModule/v1.0/network/autosplicing/splicing-matrix/${closure_id}/list`;
      if (cable_id) {
        pathName = pathName.concat(`?cableId=${cable_id}`);
      }

      await httpGet(pathName)
        .then((res) => {
          this.setState({
            isLoading: false,
            data: res.data.data,
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          console.error('Error while fetching S3 files: ', err);
        });
    }
  }

  render() {
    const { data } = this.state;

    return (
      <Card
        title={
          <>
            <FileOutlined style={{ marginRight: 7 }} />
            <span>Splicing Files</span>
          </>
        }
        size="small"
        style={{ marginTop: 16 }}
        extra={[
          <Button type="link" size="small" onClick={() => this.getSplicingMatricesByClosureId()}>
            Refresh
          </Button>,
        ]}
      >
        <List
          loading={this.state.isLoading}
          size="small"
          bordered
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              actions={[
                <a key="list-loadmore-edit" href={item.fileUrl}>
                  Export
                </a>,
              ]}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography.Text strong>{item.title}</Typography.Text>
              </div>
            </List.Item>
          )}
        />
      </Card>
    );
  }
}

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  autosplicingReducer: state.autosplicingReducer,
});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(AutosplicingSplicingMatricesFiles);
