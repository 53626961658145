import { call, put, takeLatest } from 'redux-saga/effects';
import { httpDelete, httpGet, httpPost } from '@core/http/requests';
import { DISPLAY_MESSAGE } from '../../stores/messages/reducers';
import { ERROR_NOTIFICATION } from '../../stores/notification/reducers';
import { USER_LOGOUT_REQUEST } from '../../stores/identity/constants';
import { ICreateServiceAppointment, ILoadAppointments } from './actions';
import {
  CANCEL_APPOINTMENT_RECORD_ERROR,
  CANCEL_APPOINTMENT_RECORD_REQUEST,
  CANCEL_APPOINTMENT_RECORD_SUCCESS,
  CREATE_APPOINTMENT_ERROR,
  CREATE_APPOINTMENT_REQUEST,
  CREATE_APPOINTMENT_SUCCESS,
  CREATE_WORK_ORDER_AND_APPOINTMENT_ERROR,
  CREATE_WORK_ORDER_AND_APPOINTMENT_REQUEST,
  CREATE_WORK_ORDER_AND_APPOINTMENT_SUCCESS,
  LOAD_AVAILABLE_APPOINTMENTS_ERROR,
  LOAD_AVAILABLE_APPOINTMENTS_REQUEST,
  LOAD_AVAILABLE_APPOINTMENTS_SUCCESS,
} from './constants';

function* loadAvailableAppointments(action: { params: ILoadAppointments; cb: any }): any {
  const {
    start,
    end,
    type,
    addressId,
    exPolygonId,
    scheduleId,
    scheduleIds,
    isOverview,
    contractor,
  } = action.params;

  let path = 'FieldServiceModule/v1.0/ServiceAppointment/calendar?';
  path = path + `start=${start}`;
  path = path + `&end=${end}`;
  if (type) {
    path = path + `&type=${type}`;
  }
  if (addressId) {
    path = path + `&addressId=${addressId}`;
  }
  if (exPolygonId) {
    path = path + `&exPolygonId=${exPolygonId}`;
  }
  if (scheduleId) {
    path = path + `&scheduleId=${scheduleId}`;
  }
  if (scheduleIds) {
    path = path + `&scheduleIds=${scheduleIds.join()}`;
  }
  if (isOverview) {
    path = path + `&isOverview=${isOverview}`;
  }
  if (contractor && contractor?.operator) {
    path = path + `&filters[contractor][${contractor.operator}]=${contractor.value}`;
  }

  try {
    const res = yield call(async () => await httpGet(path));
    yield put({ type: LOAD_AVAILABLE_APPOINTMENTS_SUCCESS, results: res.data });

    if (action.cb) {
      yield call(action.cb, res.data);
    }
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: LOAD_AVAILABLE_APPOINTMENTS_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* createAppointment(action: { params: ICreateServiceAppointment; cb: any }): any {
  const { workOrderId, createUpdate } = action.params;
  try {
    let path = `FieldServiceModule/v1.0/ServiceAppointment/WorkOrder/${workOrderId}/reserve`;

    const res = yield call(async () => await httpPost(`${path}`, createUpdate));

    yield put({ type: CREATE_APPOINTMENT_SUCCESS, results: res.data.data });
    yield put({
      type: DISPLAY_MESSAGE,
      message: { body: 'appointment created', type: 'success' },
    });
    yield call(action.cb, true);
  } catch (e: any) {
    console.log('debug: SAGA FAIL', e.response.data);
    yield call(action.cb, false);
    const error = e.response ? e.response.data : undefined;
    yield put({ type: CREATE_APPOINTMENT_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* cancelAppointment(action: { params: any; cb: any }): any {
  try {
    const url = `FieldServiceModule/v1.0/ServiceAppointment/cst/${action.params.id}`;
    const res = yield call(async () => await httpDelete(url, action.params.saveData));
    yield put({
      type: DISPLAY_MESSAGE,
      message: { body: 'successfully cancelled appointment', type: 'success' },
    });
    yield put({ type: CANCEL_APPOINTMENT_RECORD_SUCCESS, results: res.data });
    yield call(action.cb, true);
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: CANCEL_APPOINTMENT_RECORD_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* createWorkOrderAndAppointment(action: { params: any; cb: any }): any {
  const { orderId, createUpdate } = action.params;
  try {
    let path = `FieldServiceModule/v1.0/WorkOrder/order/${orderId}`;
    const res = yield call(async () => await httpPost(`${path}`, createUpdate));

    yield put({
      type: CREATE_WORK_ORDER_AND_APPOINTMENT_SUCCESS,
      results: res.data.data,
    });
    yield put({
      type: DISPLAY_MESSAGE,
      message: { body: 'appointment created', type: 'success' },
    });
    yield call(action.cb, res.data.data);
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield call(action.cb, null, error);
    yield put({ type: CREATE_WORK_ORDER_AND_APPOINTMENT_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* rootSaga() {
  yield takeLatest<any>(LOAD_AVAILABLE_APPOINTMENTS_REQUEST, loadAvailableAppointments);
  yield takeLatest<any>(CREATE_APPOINTMENT_REQUEST, createAppointment);
  yield takeLatest<any>(CANCEL_APPOINTMENT_RECORD_REQUEST, cancelAppointment);
  yield takeLatest<any>(CREATE_WORK_ORDER_AND_APPOINTMENT_REQUEST, createWorkOrderAndAppointment);
}

export default rootSaga;
