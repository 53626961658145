import { Button, Tooltip } from '@blueprintjs/core';
import { BlueprintNavigation } from '@core/components/BlueprintPagination';
import OdinTable from '@core/components/OdinTable';
import { getBrowserPath } from '@core/helpers/recordHelpers';
import { toSentenceCase } from '@core/helpers/stringHelpers';
import { httpPost } from '@core/http/requests';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { displayMessage } from '@redux/stores/messages/reducers';
import { IOpenRecordDrawer, openRecordDrawer } from '@redux/stores/userInterface/actions';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CommunicationsStatusTag from '@core/modules/SupportModule/views/MyCasesView/components/CommunicationsStatusTag';
import { getCaseContactFromRecord } from '../../../helpers';

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;
const CASE = 'Case';

interface Props {
  user: DbRecordEntityTransform;
  openDrawer: (params: IOpenRecordDrawer) => void;
}

const UserCasesTable: React.FC<Props> = (props: Props) => {
  const { user, openDrawer } = props;
  const [caseList, setCaseList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  // Get the user's cases
  useEffect(() => {
    getCaseList();
  }, [user]);

  // Search 2.0
  const getCaseList = async () => {
    const query = {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'OwnerId',
            operator: 'eq',
            value: user.id,
          },
          {
            columnName: 'StageName',
            operator: 'not in',
            value: ['Solved', 'Closed'],
          },
        ],
        returnProperties: [
          'id',
          'title',
          'recordNumber',
          'createdAt',
          'stage',
          'entity',
          'caseTeam',
          'caseOwner',
          'caseContact',
          'properties.OwnerId',
          'properties.TeamId',
          'properties.ContactId',
          'properties.Category',
          'properties.Source',
          'properties.Channel',
        ],
        sort: {
          createdAt: {
            order: 'asc',
          },
        },
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 1000,
      },
    };
    httpPost(`SupportModule/v2.0/records/search`, query).then((res) => {
      const records = res?.data?.data?.records || [];
      const totalRecords = res.data?.data?.totalRecords || 0;

      setTotalRecords(totalRecords);
      setCaseList(records);
      setIsLoading(false);
    });
  };

  let tableData: any[] = Object.assign([], caseList);

  // Sort the tableData by stages, using the sorting logic provided by client.
  const stageOrder: string[] = [
    'Pending Agent',
    'Open',
    'Pending Reply',
    'Blocked',
    'Pending Review',
  ];

  const compareStages = (a: any, b: any) => {
    const indexA = stageOrder.indexOf(a.stage?.name);
    const indexB = stageOrder.indexOf(b.stage?.name);
    const orderA = indexA !== -1 ? indexA : stageOrder.length;
    const orderB = indexB !== -1 ? indexB : stageOrder.length;
    return orderA - orderB;
  };

  tableData = tableData.sort(compareStages);

  // Remap and modify table data for the user's cases table
  tableData = tableData.map((item: DbRecordEntityTransform) => {
    return {
      key: item.id,
      caseNumber: (
        <Link target="_blank" key={item.id} to={getBrowserPath(item)}>
          {item.recordNumber}
        </Link>
      ),
      contact: (
        <Row>
          <Col span={20}>{getCaseContactFromRecord(item)}</Col>
          <Col span={4}>
            {item.caseContact?.length! > 0 ? (
              <Link to={`/CrmModule/Contact/${item.caseContact[0].id}`} target="_blank">
                <Button
                  small
                  intent="primary"
                  minimal
                  icon={<i className="bi bi-box-arrow-up-right" />}
                />
              </Link>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      ),
      age: (
        <Tooltip
          hoverOpenDelay={1000}
          position="top"
          content={String(dayjs(item.createdAt).format('DD/MM/YYYY HH:mm:ss'))}
        >
          <span style={{ cursor: 'pointer' }} key={`age${item.id}`}>
            {dayjs(item.createdAt).fromNow()}
          </span>
        </Tooltip>
      ),
      source: `${
        getProperty(item, 'Source') ? toSentenceCase(getProperty(item, 'Source')) : 'Unknown'
      } → ${
        getProperty(item, 'Channel') ? toSentenceCase(getProperty(item, 'Channel')) : 'Unknown'
      }`,
      category: getProperty(item, 'Category') || '',
      SLA: getProperty(item, 'SLA') || '',
      status: (
        <Row align="middle" justify="center">
          <Col>
            <CommunicationsStatusTag status={item.stage?.name} />
          </Col>
        </Row>
      ),
      action: (
        <Row align="middle" justify="end">
          <Col>
            <Button
              key={item.id}
              size="small"
              icon="panel-stats"
              variant="minimal"
              onClick={() => {
                openDrawer({
                  recordId: item.id,
                  moduleName: SUPPORT_MODULE,
                  entityName: CASE,
                });
              }}
            />
          </Col>
        </Row>
      ),
    };
  });

  // Paginate the tableData
  const start = (currentPage - 1) * pageSize;
  const end = start + pageSize;
  tableData = tableData.slice(start, end);

  return (
    <Row
      style={{
        paddingLeft: 45,
      }}
    >
      <Col span={24}>
        {!isLoading && caseList.length > 0 ? (
          <OdinTable
            height="auto"
            data={tableData}
            columns={[
              {
                key: 'caseNumber',
                title: 'Case #',
                width: 3,
              },
              {
                key: 'contact',
                title: 'Contact',
                width: 4,
              },
              {
                key: 'age',
                title: 'Age',
                width: 3,
              },
              {
                key: 'source',
                title: 'Source',
                width: 4,
              },
              {
                key: 'category',
                title: 'Category',
                width: 4,
              },
              {
                key: 'SLA',
                title: 'SLA',
                width: 2,
                align: 'center',
              },
              {
                key: 'status',
                title: 'Status',
                width: 3,
                align: 'center',
              },
              {
                key: 'action',
                title: '',
                align: 'right',
                width: 1,
              },
            ]}
          />
        ) : (
          <></>
        )}
      </Col>
      <Col span={24} style={{ textAlign: 'right', marginBottom: 20, marginTop: 10 }}>
        <Row justify="space-between" align="middle">
          <Col>
            <span style={{ fontSize: '0.9em' }}>Total Cases: {totalRecords}</span>
          </Col>
          <Col>
            <BlueprintNavigation
              totalCount={totalRecords}
              currentPage={currentPage}
              disabled={totalRecords <= pageSize}
              pageSize={pageSize}
              onPaginate={(nr: number) => setCurrentPage(nr)}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(UserCasesTable);
