import { Button, NonIdealState } from '@blueprintjs/core';
import CacheBuster from '@core/components/CacheBuster';
import HotKeyWrapper from '@core/components/HotKeyWrapper';
import Message from '@core/components/Message';
import Navigation from '@core/components/Navigation/Navigation';
import Notification from '@core/components/Notification';
import OdinHelmet from '@core/components/OdinHelmet';
import RawDataDrawer from '@core/components/RawData/RawDataDrawer';
import RecordDrawer from '@core/components/RecordDrawer';
import Search from '@core/components/Search/Search';
import OdinSettings from '@core/components/Settings';
import SupportDrawer from '@core/components/SupportDrawer/SupportDrawer';
import history from '@core/helpers/browserHistory';
import { getEnvironmentName } from '@core/helpers/identityHelpers';
import {
  canUserAccessDesktopApp,
  isExternalUser as checkExternalUser,
  isUserAuthenticated,
  isUserTokenExpired,
} from '@core/helpers/rbacRules';
import { logoutRequest } from '@redux/stores/identity/actions';
import CoreRouter from '@router/CoreRouter';
import * as Sentry from '@sentry/react';
import { Col, Layout, Result, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';
import LoginModal from 'src/core/components/UserLoginModal';
import UserSync from 'src/core/modules/IdentityModule/components/UserSync';
import { INavigationReducer } from './redux/stores/navigation/reducer';
import './styles/App.scss';

const { Header } = Layout;

const SENTRY_TESTING = false;

interface Props {
  userReducer: any;
  navigationReducer: INavigationReducer;
  logout: () => void;
}

interface State {
  odinSettingsVisible: boolean;
  supportModalVisible: boolean;
  isIdleUser: boolean;
}

class App extends React.Component<Props, State> {
  sessionExpiryTimer: NodeJS.Timeout | undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      odinSettingsVisible: false,
      supportModalVisible: false,
      isIdleUser: false,
    };
  }

  toggleOdinSettingsModal = () => {
    this.setState({ odinSettingsVisible: !this.state.odinSettingsVisible });
  };

  toggleSupportModal = () => {
    this.setState({ supportModalVisible: !this.state.supportModalVisible });
  };

  componentDidMount() {
    const { userReducer } = this.props;

    // Added this line to stop the body from moving left/right
    document.body.style.overflowX = 'hidden';
    const userToken = localStorage.getItem(`token`);

    // Try to Initialize Sentry if it is not already initialized
    if (this.props.userReducer && !Sentry.getCurrentHub()?.getClient() && window) {
      this.initializeSentry();
    }

    // Initialize Freshchat on Component mount if userReducer is available
    if (
      userToken &&
      userReducer?.user &&
      import.meta.env.VITE_FRESHCHAT_TOKEN &&
      import.meta.env.VITE_FRESHCHAT_HOST
    ) {
      this.initializeFreshchat();
    }
  }

  componentWillUnmount() {
    // Remove the session expiry timer
    if (this.sessionExpiryTimer) {
      clearInterval(this.sessionExpiryTimer);
    }
    this.sessionExpiryTimer = undefined;

    // Unmount the chat widget
    (window as any).fcWidget?.destroy();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    // userReducer might come late into the picture, so we need to check if it is available
    if (
      !prevProps.userReducer?.user &&
      this.props.userReducer?.user &&
      !(window as any)!.fcWidget!.isInitialized()
    ) {
      this.initializeFreshchat();
    }
  }

  initializeFreshchat = () => {
    const { userReducer } = this.props;
    if (window && userReducer) {
      (window as any).fcWidget?.init({
        token: import.meta.env.VITE_FRESHCHAT_TOKEN,
        host: import.meta.env.VITE_FRESHCHAT_HOST,
        externalId: userReducer.user?.id,
        firstName: userReducer.user?.firstname,
        lastName: userReducer.user?.lastname,
        email: userReducer.user?.email,
        config: {
          headerProperty: {
            hideChatButton: true,
            backgroundColor: '#2e598a',
            foregroundColor: '#ffffff',
          },
        },
      });
    }
  };

  // Initialize Sentry only for environments other than localhost or if SENTRY_TESTING is true
  initializeSentry = () => {
    const environment = getEnvironmentName(window);
    if (environment && (environment !== 'localhost' || SENTRY_TESTING)) {
      console.log('%cSentry initialized', 'color: lime');
      Sentry.init({
        dsn: 'https://1809f594ee0f4494b14a9c38f1aa32a9@o916048.ingest.sentry.io/4505239303880704',
        environment: environment,
        integrations: [Sentry.browserTracingIntegration],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        initialScope: {
          user: {
            id: this.props.userReducer?.user?.id,
            email: this.props.userReducer?.user?.email,
            name: `${this.props.userReducer?.user?.firstname} ${this.props.userReducer?.user?.lastname}`,
          },
        },
      });
    }
  };

  blurBackgroundForLoggedOutUsers() {
    const { userReducer } = this.props;
    if (history && history.location && history.location.pathname) {
      return (
        !isUserAuthenticated(userReducer) &&
        isUserTokenExpired() &&
        history.location.pathname.indexOf('login') === -1 &&
        history.location.pathname !== '/forgot-password' &&
        !history.location.pathname.includes('/reset-password') &&
        !history.location.pathname.includes('/register')
      );
    }
  }

  render() {
    const { userReducer, logout } = this.props;

    return (
      <div
        className="app-container"
        style={{
          filter: this.blurBackgroundForLoggedOutUsers() ? 'blur(0.8em)' : 'none',
        }}
      >
        <Layout className="page-layout">
          <Notification />
          <Message />
          <Router history={history}>
            <UserSync />
            <RecordDrawer />
            <CacheBuster />
            <OdinSettings
              isVisible={this.state.odinSettingsVisible}
              toggleOdinSettingsModal={this.toggleOdinSettingsModal}
            />
            <SupportDrawer
              supportDrawerVisible={this.state.supportModalVisible}
              toggleSupportModal={this.toggleSupportModal}
            />
            <HotKeyWrapper />
            <OdinHelmet />
            {!isUserAuthenticated(userReducer) && isUserTokenExpired() ? <LoginModal /> : <></>}
            {isUserAuthenticated(userReducer) &&
              !checkExternalUser(userReducer) &&
              canUserAccessDesktopApp(userReducer) && (
                <>
                  <Search
                    entities={[
                      'CrmModule:Account',
                      'OrderModule:Order',
                      'FieldServiceModule:WorkOrder',
                      'CrmModule:Contact',
                    ]}
                    schema={{
                      id: 'GLOBAL_SEARCH_DRAWER',
                      moduleName: 'SchemaModule',
                      entityName: 'ALL',
                    }}
                    renderStyle="drawer"
                  />
                  <RawDataDrawer />
                  <Header className="header">
                    <Navigation
                      toggleOdinSettingsModal={this.toggleOdinSettingsModal}
                      toggleSupportModal={this.toggleSupportModal}
                    />
                  </Header>
                </>
              )}
            {isUserAuthenticated(userReducer) &&
              checkExternalUser(userReducer) &&
              canUserAccessDesktopApp(userReducer) &&
              history.location.pathname !== '/login/mfa' && (
                <>
                  <Header className="header">
                    <Navigation
                      toggleOdinSettingsModal={this.toggleOdinSettingsModal}
                      toggleSupportModal={this.toggleSupportModal}
                    />
                    <Result
                      status="403"
                      title="403"
                      subTitle="Customer portal is under development.. check back later"
                      extra={<></>}
                    />
                  </Header>
                </>
              )}

            {isUserAuthenticated(userReducer) && !canUserAccessDesktopApp(userReducer) && (
              <Row style={{ height: '100vh' }}>
                <Col span={24}>
                  <NonIdealState
                    icon="user"
                    title="You are not authorized to access this application"
                    description="Please contact your administrator to request access."
                    action={
                      <Button size="large" intent="primary" onClick={logout}>
                        Logout
                      </Button>
                    }
                  />
                </Col>
              </Row>
            )}
          </Router>

          {/* Core Router */}
          <CoreRouter />
        </Layout>
      </div>
    );
  }
}

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  navigationReducer: state.navigationReducer,
});

const mapDispatch = (dispatch: any) => ({
  logout: () => dispatch(logoutRequest()),
});

export default connect(mapState, mapDispatch)(App);
