import { Button, Section } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Modal, Row, Spin } from 'antd';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IRecordReducer } from '../../../../../redux/stores/records/reducer';
import AssociationDataTable from 'src/core/components/AssociationDataTable';
import UploadFileFromAssociation from 'src/core/components/UploadFileFromAssociationDrawer';
import { getModuleAndEntityNameFromRecord } from '../../../../../core/helpers/recordHelpers';
import { DetailViewContext } from '../../../../../core/components/DetailViewContextProvider';
import RFCDetails from './RFCDetails';
import HeaderDetailView from '@core/components/HeaderDetailView';
import RecordStageStandardPipeline from '@core/components/RecordStageStandardPipeline';
import SchemaActionPageHeader from '@core/components/SchemaActions/SchemaActionPageHeader';
import NoteFeed from '@core/components/NoteFeed';
import StageHistory from 'src/core/components/StageHistorySection';
import { isMobile } from 'react-device-detect';

interface Props {
  recordReducer: IRecordReducer;
  isQuickView?: boolean;
}

const { PROJECT_MODULE } = SchemaModuleTypeEnums;

const FeatureRfcDetailView: React.FC<Props> = (props: Props) => {
  const { recordReducer, isQuickView } = props;
  const { record, schema, pipeline } = useContext(DetailViewContext);

  const getViewInMapPath = (record: DbRecordEntityTransform) => {
    let path: string = '';
    let recordType: string = '';

    if (record && getProperty(record!, 'ExternalRef')) {
      const moduleEntity = getModuleAndEntityNameFromRecord(record);
      if (record.type) {
        recordType = record?.type;
      } else {
        if (moduleEntity && moduleEntity.entityName === 'Project') {
          recordType = 'polygon';
        }
      }
      if (recordType.length) {
        path = `/${PROJECT_MODULE}/Map/${recordType}/${getProperty(record, 'ExternalRef')}`;
      }
    }
    return path;
  };

  const canAccessBuildPack = (record: DbRecordEntityTransform) => {
    if (record && getProperty(record, 'ExternalRef')) {
      const moduleEntity = getModuleAndEntityNameFromRecord(record);
      return moduleEntity && moduleEntity.entityName === 'Project';
    } else {
      return false;
    }
  };

  const headerActions = () => {
    let response: JSX.Element[] = [];
    record.title = `${record.properties.ExternalRef} - ${record.properties.ExchangeName}`;

    response.push(
      <SchemaActionPageHeader
        moduleName={schema.moduleName}
        entityName={schema.entityName}
        record={record}
        launcherType="INLINE"
      />,
    );

    // VIEW IN MAP
    if (getViewInMapPath(record!).length > 0) {
      response.push(
        <Link target="_blank" to={getViewInMapPath(record)}>
          <Button text="View in Map" intent="primary" outlined />
        </Link>,
      );
    }

    // BUILD PACK
    if (canAccessBuildPack(record!)) {
      response.push(
        <Link target="_blank" to={`/${PROJECT_MODULE}/BuildPack/${record?.id}`}>
          <Button text="Build Pack - Cabling" intent="primary" outlined />
        </Link>,
      );
    }

    return response;
  };

  return (
    <>
      <UploadFileFromAssociation />
      <Modal open={recordReducer.isExportingAssociations} centered={true} footer={null}>
        <Spin spinning={recordReducer.isExportingAssociations}>data exporting...</Spin>
      </Modal>

      <Row style={{ marginTop: 1 }}>
        <Col span={24}>
          <HeaderDetailView
            isQuickView={isQuickView}
            record={record!}
            extra={[
              // Non-Sequential LegacyPipeline
              <RecordStageStandardPipeline record={record} pipeline={pipeline} />,
              ...(headerActions() || []),
            ]}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 1, padding: 15 }}>
        <Col
          span={isMobile ? 24 : 16}
          style={{ paddingRight: isMobile ? 0 : 15, paddingBottom: isMobile ? 15 : 0 }}
        >
          <RFCDetails record={record} schema={schema} />

          {/* Files Section */}
          <AssociationDataTable
            thumbnailSize={4}
            title="Files"
            record={record}
            showFileCategoryForType="DEFAULT"
            moduleName="SchemaModule"
            entityName="File"
            location="sidebar"
            collapsedByDefault
          />
        </Col>

        <Col span={isMobile ? 24 : 8}>
          {!isQuickView && record?.stage && (
            <div style={{ paddingBottom: 15 }}>
              <StageHistory record={record} schema={schema} />
            </div>
          )}

          {/* Note Feed inside the sidebar */}
          <Section title="Notes">
            <div style={{ padding: 15 }}>
              <NoteFeed record={record!} />
            </div>
          </Section>
        </Col>
      </Row>
    </>
  );
};

const mapState = (state: any) => ({
  recordReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(FeatureRfcDetailView);
