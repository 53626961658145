import { Button } from '@blueprintjs/core';
import ListItemActionMenu from '@core/components/ListActions/ListItemActionMenu';
import TableCellStageTag from '@core/components/TableCellStageTag';
import { parseDateAndTimeLocal, parseDateToLocalFormat } from '@core/helpers/dateHelpers';
import { canUserUpdateRecord, hasPermissions } from '@core/helpers/rbacRules';
import {
  getModuleAndEntityNameFromRecord,
  splitModuleAndEntityName,
} from '@core/helpers/recordHelpers';
import { renderBooleanValue } from '@core/helpers/userInterfaceHelpers';
import { DbRecordAssociationRecordsTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/transform/db.record.association.records.transform';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaColumnTypes } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/types/schema.column.types';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Space, Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { IAddRecordToShortList, IGetRecordById } from '../../../../redux/stores/records/actions';
import { IOpenRecordDrawer } from '../../../../redux/stores/userInterface/actions';
import EditableTableField from '../components/EditableTableField';

const { PROJECT_MODULE } = SchemaModuleTypeEnums;

export interface TableHeaderColumn {
  title: string;
  dataIndex: string;
  position: number;
  columnType?: string;
  isTitleColumn: boolean;
  width?: string | number;
  render?: any;
  sorter?: boolean;
}

const renderActions = (
  schema: SchemaEntity | undefined,
  dbRecords: DbRecordEntityTransform[] | undefined,
  dataIndex: string,
  text: any,
  record: DbRecordEntityTransform,
  isTitleCol: boolean,
  relatedObj?: {
    record: DbRecordEntityTransform;
    relation: DbRecordAssociationRecordsTransform;
    hidden?: string[];
  },
  column?: TableHeaderColumn | undefined,
) => {
  if (!!dbRecords && dbRecords.length >= 1 && !!schema) {
    // Entity specific conditions
    if (['Premise'].includes(schema.entityName)) {
      return (
        <Link
          to={`/${schema.moduleName}/${schema.entityName}/${getProperty(
            record,
            'UDPRN',
          )}/${getProperty(record, 'UMPRN')}`}
          target="_blank"
        >
          {text}
        </Link>
      );
    } else if (schema.entityName === 'Note') {
      return (
        <Link to={`/${schema.moduleName}/${schema.entityName}/${record.id}`} target="_blank">
          {text}
        </Link>
      );
    } else if ((record?.title && dataIndex === 'title') || isTitleCol) {
      let textEl = text;

      if (
        relatedObj &&
        relatedObj.relation.schemaAssociation.hasColumnMappings &&
        isTitleCol &&
        relatedObj.relation.schemaAssociation.relationType === 'child'
      ) {
        const relatedObjModuleName = splitModuleAndEntityName(
          relatedObj.record?.entity!,
        ).moduleName;
        const relatedObjEntityName = splitModuleAndEntityName(
          relatedObj.record?.entity!,
        ).entityName;

        textEl = (
          <Link
            target="_blank"
            to={`/${relatedObjModuleName}/${relatedObjEntityName}/${relatedObj?.record?.id}/related/${record?.dbRecordAssociation?.id}/${schema.moduleName}/${schema.entityName}/${record?.id}`}
          >
            {text}
          </Link>
        );
      } else if (
        relatedObj &&
        record.dbRecordAssociation &&
        isTitleCol &&
        record.dbRecordAssociation.relatedAssociationId &&
        relatedObj.relation.schemaAssociation.relationType === 'child'
      ) {
        const relatedObjModuleName = splitModuleAndEntityName(
          relatedObj.record?.entity!,
        ).moduleName;
        const relatedObjEntityName = splitModuleAndEntityName(
          relatedObj.record?.entity!,
        ).entityName;

        textEl = (
          <Link
            target="_blank"
            to={`/${relatedObjModuleName}/${relatedObjEntityName}/${relatedObj?.record?.id}/related/${record?.dbRecordAssociation?.id}/${schema.moduleName}/${schema.entityName}/${record?.id}`}
          >
            {text}
          </Link>
        );
      } else if (isTitleCol) {
        if (
          relatedObj &&
          relatedObj.relation.schemaAssociation.hasColumnMappings &&
          isTitleCol &&
          relatedObj.relation.schemaAssociation.relationType === 'child'
        ) {
          const relatedObjModuleName = splitModuleAndEntityName(
            relatedObj.record?.entity!,
          ).moduleName;
          const relatedObjEntityName = splitModuleAndEntityName(
            relatedObj.record?.entity!,
          ).entityName;

          textEl = (
            <Link
              target="_blank"
              to={`/${relatedObjModuleName}/${relatedObjEntityName}/${relatedObj?.record?.id}/related/${record?.dbRecordAssociation?.id}/${schema.moduleName}/${schema.entityName}/${record?.id}`}
            >
              {text}
            </Link>
          );
        } else if (
          relatedObj &&
          record.dbRecordAssociation &&
          isTitleCol &&
          record.dbRecordAssociation.relatedAssociationId &&
          relatedObj.relation.schemaAssociation.relationType === 'child'
        ) {
          const relatedObjModuleName = splitModuleAndEntityName(
            relatedObj.record?.entity!,
          ).moduleName;
          const relatedObjEntityName = splitModuleAndEntityName(
            relatedObj.record?.entity!,
          ).entityName;

          textEl = (
            <Link
              target="_blank"
              to={`/${relatedObjModuleName}/${relatedObjEntityName}/${relatedObj?.record?.id}/related/${record?.dbRecordAssociation?.id}/${schema.moduleName}/${schema.entityName}/${record?.id}`}
            >
              {text}
            </Link>
          );
        } else {
          textEl = record ? (
            <Link to={`/${schema.moduleName}/${schema.entityName}/${record.id}`}>{text}</Link>
          ) : (
            ''
          );
        }
      }

      if (isTitleCol) {
        // ODN-2043 render 'Archived' tag on the relation
        return (
          <>
            {renderArchivedTag(record)}
            {textEl}
          </>
        );
      } else {
        return textEl;
      }
    } else if (dataIndex === 'groups') {
      return renderRecordGroups(record);
    } else {
      //TODO:  format data based on schema col types here....
      if (column) {
        switch (column.columnType) {
          case 'TAG':
            return <>{text && text.split(',').map((tag: string) => <Tag>{tag}</Tag>)}</>;
          // Callable Phone Number
          case SchemaColumnTypes.PHONE_NUMBER_E164_GB:
            return text;
          case SchemaColumnTypes.PHONE_NUMBER:
            return text;
          case 'BOOLEAN':
            return renderBooleanValue(text);
          default:
            return text;
        }
      }
      return text;
    }
  } else {
    return text;
  }
};

export const renderRecordGroups = (record: any) => {
  const tags: any[] = [];
  record.groups?.forEach((g: any) => {
    if (tags.length > 0) {
      tags.push(<br />);
    }
    tags.push(
      <Tooltip placement="top" title={g.name} mouseEnterDelay={1}>
        <Tag>{g.name}</Tag>
      </Tooltip>,
    );
  });
  return tags;
};

const renderArchivedTag = (record: DbRecordEntityTransform) => {
  if (record?.isArchived) {
    return (
      <>
        <Tooltip placement="top" title="Archived">
          <Space />
          <Tag style={{ backgroundColor: 'wheat' }}>A</Tag>
          <Space />
        </Tooltip>
      </>
    );
  } else {
    return '';
  }
};

const sortColumns = (col1: SchemaColumnEntity, col2: SchemaColumnEntity) => {
  if (col1.position && col2.position) {
    return col1.position - col2.position;
  } else {
    return 0;
  }
};

const getViewInMapPath = (record: DbRecordEntityTransform) => {
  let path: string = '';
  let recordType: string = '';

  if (record && getProperty(record!, 'ExternalRef')) {
    const moduleEntity = getModuleAndEntityNameFromRecord(record);
    if (record.type) {
      recordType = record?.type;
    } else {
      if (moduleEntity && moduleEntity.entityName === 'Project') {
        recordType = 'polygon';
      }
    }
    if (recordType.length) {
      path = `/${PROJECT_MODULE}/Map/${recordType}/${getProperty(record, 'ExternalRef')}`;
    }
  }
  return path;
};

export const formatDbRecordListColumns = (
  schema: SchemaEntity | undefined,
  visibleColumns: TableHeaderColumn[],
  dbRecords: DbRecordEntityTransform[] | undefined,
  pipelinesEnabled: boolean | undefined,
  shortListRecord: (params: IAddRecordToShortList) => {},
  openDrawer: (params: IOpenRecordDrawer) => void,
  getRecordById: (payload: IGetRecordById, cb: any) => {},
  relatedObj?: {
    record: DbRecordEntityTransform;
    relation: DbRecordAssociationRecordsTransform;
    hidden?: string[];
  },
  disableRelatedProductEdit?: boolean,
  canUserGetRecord?: boolean,
  authUser?: any,
  initializeUpdateForm?: Function,
  hasLabel?: boolean,
) => {
  const columns = () => {
    const headerColumns = [];

    function isSupportedSpliceType(
      rowRecord: DbRecordEntityTransform | undefined,
      record: DbRecordEntityTransform | undefined,
    ) {
      if (getProperty(record, 'ClosureType') === '4') {
        return getProperty(rowRecord, 'CableType') === '3';
      }
      if (getProperty(record, 'ClosureType') === '3') {
        return getProperty(rowRecord, 'CableType') === '2';
      }
      if (getProperty(record, 'ClosureType') === '2') {
        return getProperty(rowRecord, 'CableType') === '1';
      }

      return false;
    }

    if (schema) {
      const standardColumns = [
        'createdAt',
        'updatedAt',
        'recordNumber',
        'title',
        'stage',
        'stageName',
        'actions',
        'type',
      ];

      // Initialize table with default columns
      if (schema.isSequential) {
        headerColumns.push({
          title: 'record #',
          sorter: true,
          dataIndex: 'recordNumber',
          width: 'auto',
          columnType: 'TEXT',
          position: -3,
          ellipsis: true,
          render: (
            text: string | undefined,
            row: { [x: string]: any; rowRecord: DbRecordEntityTransform },
            rowRecord: any,
          ) => {
            return renderActions(
              schema,
              dbRecords,
              'recordNumber',
              text,
              row.rowRecord,
              true,
              relatedObj,
              undefined,
            );
          },
        });
      }

      if (hasLabel) {
        headerColumns.push({
          title: 'label',
          dataIndex: 'label',
          width: 'auto',
          position: -1,
          columnType: 'TEXT',
          ellipsis: true,
          sorter: true,
          render(text: any, row: any) {
            return row.rowRecord.label;
          },
        });
      }

      if (schema.types && schema.types.length > 0) {
        headerColumns.push({
          title: 'Type',
          dataIndex: 'type',
          width: 'auto',
          position: -1,
          columnType: 'TEXT',
          ellipsis: true,
          sorter: true,
        });
      }

      if (schema.hasTitle) {
        headerColumns.push({
          title: 'Title',
          sorter: true,
          dataIndex: 'title',
          width: 350,
          position: -4,
          columnType: 'TEXT',
          ellipsis: true,
          render: (
            text: string | undefined,
            row: { [x: string]: any; rowRecord: DbRecordEntityTransform },
          ) =>
            renderActions(
              schema,
              dbRecords,
              'title',
              text,
              row.rowRecord,
              true,
              relatedObj,
              undefined,
            ),
        });
      }

      if (pipelinesEnabled) {
        headerColumns.push({
          title: 'stage',
          sorter: true,
          dataIndex: 'stageName',
          width: 'auto',
          columnType: 'TEXT',
          position: -2,
          ellipsis: true,
          render(text: any, row: any) {
            return {
              props: {
                className: 'stageCell',
              },
              children: <TableCellStageTag record={row.rowRecord} />,
            };
          },
        });
      }

      // parse visible columns from the list view and exclude the standard columns

      if (visibleColumns) {
        for (const col of visibleColumns) {
          if (!standardColumns.includes(col.dataIndex)) {
            headerColumns.push({
              sorter: col.columnType != null,
              title: col.title,
              dataIndex: col.dataIndex,
              width: 'auto',
              columnType: col.columnType,
              position: col.position + 1,
              ellipsis: true,
              render: (
                text: string | undefined,
                row: { [x: string]: any; rowRecord: DbRecordEntityTransform },
                index: any,
              ) => {
                const formattedDataIndex = `${col.dataIndex}_Fmt`;
                const formattedValue = row[formattedDataIndex];
                // Date
                if (col.columnType === SchemaColumnTypes.DATE && !formattedValue) {
                  return (
                    <EditableTableField
                      record={row.rowRecord}
                      schema={schema}
                      initialValue={text}
                      propertyKey={col.dataIndex}
                      hasColumnMapping={relatedObj?.relation?.schemaAssociation?.hasColumnMappings}
                      relatedEntityName={relatedObj?.record?.entity}
                    >
                      {text ? parseDateToLocalFormat(text) : undefined}
                    </EditableTableField>
                  );
                }
                // DateTime
                else if (col.columnType === SchemaColumnTypes.DATE_TIME && !formattedValue) {
                  return (
                    <EditableTableField
                      record={row.rowRecord}
                      schema={schema}
                      initialValue={text}
                      propertyKey={col.dataIndex}
                      hasColumnMapping={relatedObj?.relation?.schemaAssociation?.hasColumnMappings}
                      relatedEntityName={relatedObj?.record?.entity}
                    >
                      {text ? parseDateAndTimeLocal(text) : undefined}
                    </EditableTableField>
                  );
                }
                // LOOKUP
                else if (col.columnType === SchemaColumnTypes.LOOKUP) {
                  const link = row.rowRecord?.links?.find((l) => l.id === text);
                  // Link
                  if (link) {
                    const { moduleName, entityName } = splitModuleAndEntityName(link.entity);
                    return (
                      <Link to={`/${moduleName}/${entityName}/${link?.id}`} target="_blank">{`${
                        link.recordNumber ? `${link.recordNumber} - ` : ''
                      }${link.title || ''}`}</Link>
                    );
                  }
                }
                // Currency
                else if (
                  col.columnType === SchemaColumnTypes.CURRENCY &&
                  !hasPermissions(authUser, ['financial.data.access'])
                ) {
                  return '*******';
                }
                // Else -> All the rest
                else {
                  const excludeTypesFromInlineEditing = ['JSON'];

                  return (
                    <EditableTableField
                      record={row.rowRecord}
                      schema={schema}
                      initialValue={text}
                      propertyKey={col.dataIndex}
                      hasColumnMapping={relatedObj?.relation?.schemaAssociation?.hasColumnMappings}
                      relatedEntityName={relatedObj?.record?.entity}
                    >
                      {text && !excludeTypesFromInlineEditing.includes(String(col?.columnType))
                        ? renderActions(
                            schema,
                            dbRecords,
                            col.dataIndex,
                            formattedValue ?? text,
                            row.rowRecord,
                            col.isTitleColumn,
                            undefined,
                            col,
                          )
                        : undefined}
                    </EditableTableField>
                  );
                }
              },
            });
          }
        }
      }

      headerColumns.push({
        title: 'created',
        dataIndex: 'createdAt',
        width: 'auto',
        columnType: 'DATE_TIME',
        position: 99998,
        ellipsis: true,
        sorter: true,
        render: (text: string | undefined) => parseDateAndTimeLocal(text!?.toString()),
      });

      headerColumns.push({
        title: 'updated',
        dataIndex: 'updatedAt',
        width: 'auto',
        columnType: 'DATE_TIME',
        position: 99998,
        ellipsis: true,
        sorter: true,
        render: (text: string | undefined) => parseDateAndTimeLocal(text!?.toString()),
      });

      if (schema.entityName !== 'Premise' && !disableRelatedProductEdit) {
        const { entityName, moduleName } = schema;

        headerColumns.push({
          sorter: false,
          title: 'actions',
          dataIndex: 'actions',
          width: 140,
          position: 99999,
          columnType: 'TEXT',
          fixed: 'right',
          align: 'right',
          ellipsis: true,
          // align: 'center' as const,
          render: (
            text: string | undefined,
            row: { [x: string]: any; rowRecord: DbRecordEntityTransform },
          ) => (
            <div>
              {canUserGetRecord && (
                <>
                  {/* Features can go straight to map */}
                  {schema.entityName === 'Feature' && (
                    <Tooltip title="View on map" mouseEnterDelay={1}>
                      <Link target="_blank" to={getViewInMapPath(row?.rowRecord)}>
                        <Button icon="map-marker" style={{ marginRight: 8 }} small />
                      </Link>
                    </Tooltip>
                  )}

                  {/* Update Button */}
                  <Tooltip title="Update Record" mouseEnterDelay={1}>
                    <Button
                      small
                      style={{ marginRight: 8 }}
                      icon="edit"
                      disabled={!canUserUpdateRecord(authUser, schema, row.rowRecord!)}
                      onClick={() =>
                        initializeUpdateForm
                          ? initializeUpdateForm(
                              row.rowRecord!,
                              relatedObj?.relation?.schemaAssociation?.hasColumnMappings,
                            )
                          : {}
                      }
                    />
                  </Tooltip>

                  {/* Quick View Button */}
                  <Tooltip title="Quick view" mouseEnterDelay={1}>
                    <Button
                      size="small"
                      style={{ marginRight: 8 }}
                      icon="panel-stats"
                      onClick={() =>
                        openDrawer({ recordId: row.rowRecord?.id, entityName, moduleName })
                      }
                    />
                  </Tooltip>
                </>
              )}

              {relatedObj && (
                <ListItemActionMenu
                  relatedRecord={row.rowRecord}
                  record={relatedObj.record}
                  relation={relatedObj.relation}
                />
              )}
            </div>
          ),
        });
      }
    }

    return headerColumns.sort(sortColumns as any);
  };

  return columns();
};
