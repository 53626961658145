import DetailViewContext from '@core/components/DetailViewContext';
import RecordListView from '@core/components/ListView';
import FileListFeedView from '@core/components/ListView/FileListFeedView';
import history from '@core/helpers/browserHistory';
import {
  canUserAccessDesktopApp,
  isExternalUser as checkExternalUser,
  isSystemAdmin,
  isUserTokenExpired,
} from '@core/helpers/rbacRules';
import ProtectedRoute from '@core/components/ProtectedRoute';
import RoleBasedProtectedRoute from '@core/components/RoleBasedProtectedRoute';
import ControlPanelView from '@core/modules/ControlPanelModule/components/ControlPanel';
import MFASettings from '@core/modules/ControlPanelModule/components/IdentityManager/MFASettings';
import { OrganizationSetup } from '@core/modules/OrganizationSetupModule';
import Error403 from '@core/pages/403';
import Error404 from '@core/pages/404';
import Error500 from '@core/pages/500';
import AppLoadingPage from '@core/pages/AppLoading';
import AppUnavailable from '@core/pages/AppUnavailable';
import DefaultDetailViewRouter from '@core/routes/DefaultDetailViewRouter';
import Home from '@core/views/HomeView';
import Inbox from '@core/views/InboxView';
import Login from '@core/views/UserLoginView';
import ForgotPassword from '@core/views/ForgotPasswordView';
import Register from '@core/views/RegisterUserView';
import ResetPassword from '@core/views/ResetPasswordView';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { configureRouter, TRouteConfig } from '@router/config';

const { SCHEMA_MODULE } = SchemaModuleTypeEnums;
const IDENTITY_MANAGER_MODULE = 'IdentityManagerModule';
const CONTROL_PANEL_MODULE = 'ControlPanelModule';

interface Props {
  userReducer: any;
}

const CoreRouter: React.FC<Props> = (props: Props) => {
  const { userReducer } = props;

  const isExternalUser = checkExternalUser(userReducer);
  const RouterConfiguration: TRouteConfig = configureRouter();

  // Warning: Do not update this router unless you are working on Core application.
  //          For Netomnia and YouFibre, update the respective routes above.
  return (
    <Router history={history}>
      {canUserAccessDesktopApp(userReducer) ? (
        <Switch>
          {/* BUSINESS ROUTES */}
          {RouterConfiguration.routes}

          {/* HOME */}
          <Route exact path="/">
            <Switch>
              {!isExternalUser && (
                <ProtectedRoute
                  path="/"
                  moduleName={'OVERRIDE'}
                  entityName="Dashboard"
                  exact
                  component={<Home />}
                />
              )}
            </Switch>
          </Route>

          {/* INBOX */}
          <Route exact path="/Inbox/:selectedMention?">
            <Inbox />
          </Route>

          {/* CONTROL PANEL */}
          <Route path={`/${CONTROL_PANEL_MODULE}`}>
            <Switch>
              <RoleBasedProtectedRoute
                exact
                path={`/${CONTROL_PANEL_MODULE}`}
                moduleName={IDENTITY_MANAGER_MODULE}
                component={<ControlPanelView />}
              />
              <RoleBasedProtectedRoute
                exact
                path={`/${CONTROL_PANEL_MODULE}/IdentityManager`}
                moduleName={IDENTITY_MANAGER_MODULE}
                component={<ControlPanelView moduleName="IdentityManager" />}
              />

              <RoleBasedProtectedRoute
                exact
                path={`/${CONTROL_PANEL_MODULE}/SchemaManager`}
                moduleName={SCHEMA_MODULE}
                component={<ControlPanelView moduleName="SchemaManager" />}
              />

              <RoleBasedProtectedRoute
                exact
                path={`/${CONTROL_PANEL_MODULE}/Columns`}
                moduleName={SCHEMA_MODULE}
                component={<ControlPanelView moduleName="Columns" />}
              />

              <RoleBasedProtectedRoute
                exact
                path={`/${CONTROL_PANEL_MODULE}/Forms/ListView`}
                moduleName={SCHEMA_MODULE}
                component={<ControlPanelView moduleName="Forms" />}
              />

              <RoleBasedProtectedRoute
                exact
                path={`/${CONTROL_PANEL_MODULE}/Configs/ListView`}
                moduleName={SCHEMA_MODULE}
                component={<ControlPanelView moduleName="Configs" />}
              />

              <RoleBasedProtectedRoute
                exact
                path={`/${CONTROL_PANEL_MODULE}/ViewConfigs/ListView`}
                moduleName={SCHEMA_MODULE}
                component={<ControlPanelView moduleName="ViewConfigs" />}
              />

              <RoleBasedProtectedRoute
                exact
                path={`/${CONTROL_PANEL_MODULE}/SchemaManager/:id`}
                moduleName={SCHEMA_MODULE}
                component={<ControlPanelView moduleName="SchemaManagerDetailView" />}
              />

              <RoleBasedProtectedRoute
                exact
                path={`/${CONTROL_PANEL_MODULE}/EmailTemplateManager`}
                moduleName={SCHEMA_MODULE}
                component={<ControlPanelView moduleName="EmailTemplateManager" />}
              />
            </Switch>
          </Route>

          {/* SCHEMA MODULE */}
          <Route path="/SchemaModule">
            <Switch>
              <RoleBasedProtectedRoute
                exact
                path={`/${SCHEMA_MODULE}/File`}
                moduleName={SCHEMA_MODULE}
                component={<FileListFeedView moduleName={SCHEMA_MODULE} entityName={'File'} />}
              />
              <RoleBasedProtectedRoute
                exact
                path={`/${SCHEMA_MODULE}/File/:recordId`}
                moduleName={SCHEMA_MODULE}
                component={
                  <DetailViewContext moduleName={SCHEMA_MODULE} entityName="File">
                    <DefaultDetailViewRouter />
                  </DetailViewContext>
                }
              />
              <ProtectedRoute
                exact
                path={`/${SCHEMA_MODULE}/:entityName`}
                moduleName={SCHEMA_MODULE}
                component={<RecordListView moduleName={SCHEMA_MODULE} />}
              />
              <ProtectedRoute
                exact
                path={`/${SCHEMA_MODULE}/:entityName/:recordId`}
                moduleName={SCHEMA_MODULE}
                component={
                  <DetailViewContext moduleName={SCHEMA_MODULE}>
                    <DefaultDetailViewRouter />
                  </DetailViewContext>
                }
              />
            </Switch>
          </Route>

          {/* FORGOT PASSWORD */}
          <Route exact path="/forgot-password" component={ForgotPassword} />

          {/* RESET PASSWORD */}
          <Route exact path="/reset-password/:token" component={ResetPassword} />

          {/* REGISTER USER */}
          <Route exact path="/register/:token" component={Register} />

          {/* REGISTER USER WITH CONTACT ID*/}
          <Route exact path="/register/:token/:apiToken/:contactId" component={Register} />

          {/* SETUP NEW ORGANIZATION */}
          {isSystemAdmin(userReducer) && (
            <Route path="/setup-organization">
              <ProtectedRoute
                path="/setup-organization"
                moduleName={'OVERRIDE'}
                exact
                component={<OrganizationSetup />}
              />
            </Route>
          )}

          {/* LOGIN */}
          <Route path="/login" exact>
            <Login />
          </Route>

          {/* LOGIN / MFA */}
          <Route path="/login/mfa" exact>
            <MFASettings />
          </Route>

          {/* 500 */}
          <Route path="/500">
            <Error500 />
          </Route>

          {/* 403 */}
          <Route path="/403">
            <Error403 />
          </Route>

          {/* 404 */}
          <Route>
            <Error404 />
          </Route>
        </Switch>
      ) : (
        // 2. USERS WITHOUT DESKTOP ACCESS
        <Switch>
          {/* FORGOT PASSWORD */}
          <Route exact path="/forgot-password" component={ForgotPassword} />

          {/* RESET PASSWORD */}
          <Route exact path="/reset-password/:token" component={ResetPassword} />

          {/* REGISTER USER */}
          <Route exact path="/register/:token" component={Register} />

          {/* REGISTER USER WITH CONTACT ID*/}
          <Route exact path="/register/:token/:apiToken/:contactId" component={Register} />

          {/* LOGIN */}
          <Route path="/login" exact>
            <Login />
          </Route>

          {/* LOGIN / MFA */}
          <Route path="/login/mfa" exact>
            <MFASettings />
          </Route>

          {/* APP UNAVAILABLE */}
          <Route>
            {!isUserTokenExpired() &&
            userReducer.user?.roles?.length! > 0 &&
            !canUserAccessDesktopApp(userReducer) ? (
              <AppUnavailable />
            ) : (
              <></>
            )}
          </Route>
          {/* APP LOADING */}
          <Route>
            {!isUserTokenExpired() && canUserAccessDesktopApp(userReducer) ? (
              <AppLoadingPage />
            ) : (
              <></>
            )}
          </Route>
        </Switch>
      )}
    </Router>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(CoreRouter);
