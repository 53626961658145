import moment from 'moment';
import { DateTime } from 'luxon';

function parseDate(date: string | Date | undefined): moment.Moment {
  let parsedDate = moment(date);
  if (!parsedDate.isValid()) {
    parsedDate = moment(date, 'MM-YYYY');
  }
  return parsedDate;
}

export function parseDateForNoteFeed(date: string) {
  const parsedDate = parseDate(date);
  if (parsedDate.isValid()) {
    return DateTime.fromISO(date).toFormat('dd/MM/yyyy h:mm a ZZZZ');
  }
}

export function parseDateToLocalFormat(date: string) {
  if (date) {
    const parsedDate = parseDate(date);
    if (parsedDate.isValid()) {
      return parsedDate.format('DD/MM/YYYY');
    }
  } else {
    return undefined;
  }
}

export function parseDateAndTimeLocal(date: string) {
  if (date) {
    const parsedDate = parseDate(date);
    if (parsedDate.isValid()) {
      return DateTime.fromISO(date).toFormat('dd/MM/yyyy h:mm a ZZZZ');
    }
  } else {
    return null;
  }
}

export function parseDateLocalizedHours(date: string | Date | undefined) {
  const parsedDate = parseDate(date);
  if (parsedDate.isValid()) {
    return parsedDate.format('DD/MM/YYYY LT');
  }
}
