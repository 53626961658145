import { httpPost } from '@core/http/requests';
import { CaseChannel } from '@d19n/sandbox-odin-sdk/dist/entities-v2/Case';
import { useEffect, useState } from 'react';
import DashboardBarChart, { DataItem } from './DashboardBarChart';

type Props = {
  refreshBit: 0 | 1;
};
const OngoingChatsConcurrencyChart = (props: Props) => {
  const [chatConcurrencyMetrics, setChatConcurrencyMetrics] = useState<DataItem[]>([]);

  const getChatConcurrencyAggregate = async () => {
    const result = await httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Channel',
            operator: 'eq',
            value: CaseChannel.WEB_CHAT,
          },
          {
            columnName: 'OwnerId',
            operator: 'notNull',
            value: '',
          },
          {
            columnName: 'ConversationStatus',
            operator: 'eq',
            value: 'Created',
          },
        ],
        sort: {
          createdAt: {
            order: 'desc',
          },
        },
        returnProperties: [],
        aggs: {
          'owner-agg': {
            terms: {
              field: 'caseOwner.name.keyword',
            },
          },
        },
        pageSize: 0,
      },
    });
    const aggregation = result.data.data.aggregations['owner-agg'].buckets;
    if (aggregation) {
      setChatConcurrencyMetrics(
        aggregation.map((item: any) => ({
          name: item.key,
          value: item.doc_count,
        })),
      );
    }
  };

  useEffect(() => {
    getChatConcurrencyAggregate();
  }, [props.refreshBit]);

  return (
    <DashboardBarChart
      title="Ongoing Chat Concurrency"
      orderByValue="asc"
      data={chatConcurrencyMetrics}
      barColorChangeConditionFn={(item) => {
        if (item.value < 3) {
          return '#EC1017';
        }
        return '#000000';
      }}
    />
  );
};

export default OngoingChatsConcurrencyChart;
