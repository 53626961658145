export const allowedAddresses = [
  'retentions@youfibre.com',
  'tech@youfibre.com',
  'sales@youfibre.com',
  'pricematch@youfibre.com',
  'internalbillingrequests@youfibre.com',
  'installationteam@youfibre.com',
  'installations@youfibre.com',
  'hello@youfibre.com',
  'fsops@youfibre.com',
  'etf@youfibre.com',
  'cs@youfibre.com',
  'complaints@youfibre.com',
  'bill@youfibre.com',
  'abuse@youfibre.com',
  'bugs@youfibre.com',
];
