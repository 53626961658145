import { getAllSchemaActionsForMultipleSchemaIds } from '@core/components/OutcomeFormLauncher/api';
import { renderBooleanValue } from '@core/helpers/userInterfaceHelpers';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { MetadataLinks } from '@d19n/temp-fe-d19n-models/dist/schema-manager/metadata.links';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import FileFormField from '@core/components/Files/FileFormField';
import { getSchemaActionVersion } from '@core/components/Forms/helpers';
import { Col, Divider, Row } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import './styles.scss';

interface Props {
  schema: SchemaEntity;
  record: DbRecordEntityTransform;
}

export const OutcomeFormBody: FC<Props> = (props: Props) => {
  const { schema, record } = props;
  const [schemaAction, setSchemaAction] = useState<SchemaActionEntity | undefined>(undefined);

  // Get schema action that was used to create the record
  useEffect(() => {
    if (schema && !schemaAction) {
      getSchemaAction();
    }
  }, [schema]);

  const getSchemaAction = () => {
    if (schema) {
      getAllSchemaActionsForMultipleSchemaIds([schema?.id]).then(
        (schemaActions: SchemaActionEntity[]) => {
          const action = schemaActions.find(
            (schemaAction: SchemaActionEntity) => schemaAction.isCreate,
          );

          if (action) {
            setSchemaAction(action);
          }
        },
      );
    }
  };

  // Depending on schema column type, render form field body
  const renderFieldBody = (type: string | undefined, value: string) => {
    switch (type) {
      case 'FILE_MULTIPLE':
        return value?.length > 0 ? (
          <FileFormField
            readOnly
            thumbnailSize={isMobile ? 'large' : 'medium'}
            withoutBorder
            mode={type}
            fileIds={value ? value.split(',') : []}
            parentSchemaId={''}
          />
        ) : (
          <span className="disabledText">-</span>
        );
      case 'FILE_SINGLE':
        return value && value?.length > 0 ? (
          <FileFormField
            readOnly
            thumbnailSize={isMobile ? 'large' : 'medium'}
            withoutBorder
            mode={type}
            fileIds={value ? value.split(',') : []}
            parentSchemaId={''}
          />
        ) : (
          <span className="disabledText">-</span>
        );
      case 'SIGNATURE':
        return value && value?.length > 0 ? (
          <FileFormField
            thumbnailSize={isMobile ? 'large' : 'medium'}
            readOnly
            mode={'FILE_SINGLE'}
            withoutBorder
            fileIds={value ? value.split(',') : []}
            parentSchemaId={''}
          />
        ) : (
          <span className="disabledText">-</span>
        );
      case 'DATE':
        return (
          <span style={{ paddingLeft: 10 }}>
            {value ? dayjs(value).format('DD/MM/YYYY') : <span className="disabledText">-</span>}
          </span>
        );
      case 'BOOLEAN':
        return renderBooleanValue(value);
      default:
        return (
          <span style={{ paddingLeft: value ? 10 : 0 }}>
            {value ? String(value) : <span className="disabledText">-</span>}
          </span>
        );
    }
  };

  const sortColumns = (a: SchemaColumnEntity, b: SchemaColumnEntity) => {
    if (a.position && b.position) {
      return a.position - b.position;
    } else {
      return 0;
    }
  };

  let allSchemaColumns = schema?.columns;

  // If Create schema action is found, use it to sort columns
  // by the order specified in the schema action definition.
  if (schemaAction) {
    let formFields: any[] = [];

    if (getSchemaActionVersion(schemaAction) === 1) {
      formFields = schemaAction?.definition?.formFields;
    } else {
      schemaAction?.definition?.formDefinition.forEach((section: any) => {
        formFields = formFields.concat(section.formFields);
      });
    }

    allSchemaColumns.map((column: any) => {
      const action = formFields.find((field: any) => field.name === column.name);
      column.schemaActionOrder = action?.order || 0;
    });

    allSchemaColumns = allSchemaColumns?.sort(
      (a: any, b: any) => a.schemaActionOrder - b.schemaActionOrder,
    );
  }
  // Otherwise, sort by column position
  else {
    allSchemaColumns = allSchemaColumns?.sort(sortColumns);
  }

  return (
    <Row style={{ padding: '10px 5px' }}>
      {record && schema && schemaAction ? (
        allSchemaColumns?.map((schemaColumn: SchemaColumnEntity) => {
          const fieldName = schemaColumn.label;

          let fieldValue = getProperty(record, schemaColumn.name);

          // For LOOKUP fields, search the record.links array
          if (schemaColumn.type === 'LOOKUP') {
            const link = record?.links?.find((link: MetadataLinks) => link?.id === fieldValue);
            if (link && link.title) {
              fieldValue = link.title;
            }
          }

          return (
            <Col span={24} style={{ marginBottom: 20 }} key={schemaColumn.id}>
              <Row key={schemaColumn.id}>
                <Col span={24} style={{ marginBottom: 10 }} key={schemaColumn.id}>
                  <Divider
                    key={schemaColumn.id}
                    orientation="left"
                    orientationMargin={0}
                    style={{ marginTop: 10, marginBottom: 5 }}
                  >
                    {fieldName}
                  </Divider>
                </Col>
                <Col key={schemaColumn.id} span={24}>
                  {renderFieldBody(schemaColumn?.type, fieldValue)}
                </Col>
              </Row>
            </Col>
          );
        })
      ) : (
        <></>
      )}
    </Row>
  );
};
