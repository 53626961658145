import React from 'react';
import { Row, Col, Spin, notification } from 'antd';
import { Kanban, template } from '@d19n/dhtmlx-kanban-pro';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import ReactDOMServer from 'react-dom/server';

import '@d19n/dhtmlx-kanban-pro/dist/kanban.css';
import './styles.scss';
import BoardCard, { BoardCardProps } from './BoardCard';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaColumnOptionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/option/schema.column.option.entity';
import { PipelineStageEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/stage/pipeline.stage.entity';
import { httpPatch } from '@core/http/requests';
import { IOpenRecordDrawer } from 'src/redux/stores/userInterface/actions';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';

const { PROJECT_MODULE } = SchemaModuleTypeEnums;
const { FEATURE } = SchemaModuleEntityTypeEnums;

interface KanbanBoardProps {
  loading: boolean;
  updating?: boolean;
  records: DbRecordEntityTransform[];
  schema: SchemaEntity;
  routeTypeList: SchemaColumnOptionEntity[];
  structureTypeList: SchemaColumnOptionEntity[];
  stages: PipelineStageEntity[];
  openDrawer: (params: IOpenRecordDrawer) => void;
}

class KanbanBoard extends React.Component<KanbanBoardProps> {
  private kanbanRef = React.createRef<HTMLDivElement>();
  private kanban: Kanban | null = null;
  private isUpdating = false;

  constructor(props: KanbanBoardProps) {
    super(props);
  }

  componentDidMount() {
    if (this.kanbanRef.current) {
      this.initKanban();
    }
  }

  componentDidUpdate(prevProps: KanbanBoardProps) {
    if (prevProps.records !== this.props.records) {
      if (this.kanban) {
        this.kanban.destructor();
      }
      this.initKanban();
    }
  }

  componentWillUnmount() {
    this.kanban?.destructor();
  }

  private getRFCType(record: DbRecordEntityTransform) {
    if (record.type === 'SURVEY_ROUTE') {
      return this.props.routeTypeList.find((opt) => opt.value === record.properties.RouteType)
        ?.label;
    } else if (record.type === 'SURVEY_STRUCTURE') {
      return this.props.structureTypeList.find(
        (opt) => opt.value === record.properties.StructureType,
      )?.label;
    }
  }

  private handleStageChange = async (cardId: string, card: any, newStage: string) => {
    const record = this.props.records.find((r) => r.id === cardId);
    const toStageEntity = this.props.stages?.find((stage) => stage.key === newStage);

    if (!record || !toStageEntity) {
      notification.error({
        message: 'Failed to Update Stage',
        description: 'An error occurred while updating the stage. Please try again.',
      });

      return;
    }

    try {
      card.stage = toStageEntity;
      this.isUpdating = true;
      const isResolved = card.stage.isSuccess || card.stage.isFail;

      await httpPatch(`SchemaModule/v2.0/records/update`, {
        recordsToUpdate: [
          {
            id: record.id,
            entity: record.entity,
            stageKey: toStageEntity.key,
            type: record.type,
            properties: {
              Resolved: isResolved,
            },
          },
        ],
      });

      notification.success({
        message: 'Stage Updated',
        description: `Successfully moved ${record.properties.ExternalRef} - ${record.properties.ExchangeName} to ${toStageEntity.name}`,
      });
    } catch (error: any) {
      this.kanban?.api.exec('move-card', { id: cardId, columnId: card.stage.key });

      notification.error({
        message: 'Failed to Update Stage',
        description:
          error.response?.data?.message ||
          'An error occurred while updating the stage. Please try again.',
      });
    } finally {
      this.isUpdating = false;
    }
  };

  private initKanban() {
    if (!this.kanbanRef.current) return;

    const priorityList = this.props.schema.columns.find((col) => col.name === 'Priority')?.options;

    this.kanban = new Kanban(this.kanbanRef.current, {
      columns: this.props.stages.map((stage) => ({ id: stage.key, label: stage.name })),
      cards: this.props.records.map((record) => ({
        id: record.id,
        rfcType: this.getRFCType(record),
        externalRef: record.properties.ExternalRef,
        exchangeName: record.properties.ExchangeName,
        description: record.properties.Description,
        assigneeName: record.featureUser?.[0]?.name,
        dueDate: record.properties.DueDate,
        priority: priorityList?.find((opt) => opt.value === record.properties.Priority)?.label,
        stage: record.stage,
        column: record.stage?.key,
      })),
      cardTemplate: template((card: BoardCardProps) =>
        ReactDOMServer.renderToString(<BoardCard {...card} />),
      ),
      columnShape: {
        headerTemplate: template(
          ({ column, columnState }: { column: any; columnState: any }) => `
          <div class="wx-collapse-icon" data-action="collapse"><i class="wx-icon wxi-angle-${column.collapsed ? 'right' : 'left'}"></i></div>
          <div class="wx-label" data-action="rename">${column.label} (${columnState.cardsCount})</div>
        `,
        ),
        collapsedTemplate: template(
          ({ column, columnState }: { column: any; columnState: any }) => `
          <div class="wx-column wx-collapsed" data-drop-area="${column.id}">
            <div class="wx-collapsed-label">
              <div class="wx-label-text">${column.label} (${columnState.cardsCount})</div>
            </div>
          </div>
        `,
        ),
      },
      readonly: {
        edit: false,
        add: false,
        select: true,
        dnd: true,
      },
    });

    this.kanban.api.on('select-card', (obj) => {
      const record = this.props.records.find((r) => r.id === obj.id);

      if (record) {
        this.props.openDrawer({
          recordId: record.id,
          moduleName: PROJECT_MODULE,
          entityName: FEATURE,
        });
      }
    });

    this.kanban.api.on('move-card', ({ id, columnId }) => {
      if (this.isUpdating) return;

      const card = this.kanban?.api.getCard(id);

      if (card && card.stage.key !== columnId) {
        this.handleStageChange(id, card, columnId);
      }
    });
  }

  render() {
    const { loading, updating } = this.props;

    if (loading) {
      return (
        <Row style={{ height: '97vh', paddingTop: '12vh' }}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Spin style={{ marginBottom: 20 }} />
            <br />
            <span>Loading DataSet records...</span>
          </Col>
        </Row>
      );
    }

    return (
      <>
        <div
          ref={this.kanbanRef}
          style={{
            width: '100%',
            // Subtract height of header, navigation and padding (135px) to fill remaining viewport
            height: 'calc(100vh - 135px)',
            opacity: updating ? 0.6 : 1,
            transition: 'opacity 0.2s ease',
          }}
        />
      </>
    );
  }
}

export default KanbanBoard;
