import { Section, SectionCard } from '@blueprintjs/core';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { ActionsDropdown } from './ActionsDropdown';
import { SchemaCardinality } from './SchemaCardinality';
import { SchemaDropdown } from './SchemaDropdown';
import { BaseFormProps } from './types';

interface ParentFormProps extends BaseFormProps {
  schema: SchemaEntity;
}
export const ParentForm = ({
  link,
  cardinalityProps,
  schemaProps,
  actionsProps,
}: ParentFormProps) => {
  const { value: cardinality, onChange: setCardinality } = cardinalityProps;

  const { value: selectedItem, onChange } = schemaProps;

  const { value: action, onChange: setAction } = actionsProps;

  return (
    <Section
      title={'Parent'}
      subtitle={selectedItem?.name || `Select the Parent Schema`}
      icon="diagram-tree"
      className="parentForm"
    >
      <SectionCard>
        <h3>Entity</h3>
        <SchemaDropdown selectedItem={selectedItem} setSelectedItem={onChange} disabled={!!link} />
      </SectionCard>

      <SectionCard>
        <h3>Cardinality</h3>
        <SchemaCardinality value={cardinality} onChange={setCardinality} />
      </SectionCard>
      <SectionCard>
        <h3>Actions</h3>
        <ActionsDropdown value={action} onChange={setAction} />
      </SectionCard>
    </Section>
  );
};
