import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Divider, Row } from 'antd';
import React from 'react';
import RecordProperties from 'src/core/components/RecordProperties';
import { IViewConfigurationDetailSection } from '@core/modules/ControlPanelModule/components/ViewConfigs/types';

interface Props {
  viewConfiguration: any;
  record: DbRecordEntityTransform;
}

const DetailsConfigurator: React.FC<Props> = (props: Props) => {
  const { record, viewConfiguration } = props;

  const renderSection = (section: IViewConfigurationDetailSection) => {
    const visibleProperties: string[] | undefined =
      section.visibleProperties?.map((property: any) => property.name) || undefined;

    return (
      <Col span={24}>
        <Row>
          <Col span={24}>
            {section.title ? (
              <Divider orientation="left" orientationMargin={0} style={{ fontSize: 14 }}>
                {section.title}
              </Divider>
            ) : (
              <></>
            )}
          </Col>
          <Col span={24}>
            <RecordProperties
              record={record}
              visibleProperties={visibleProperties}
              columns={section.columns}
              showMoreByDefault={section.showMoreByDefault}
            />
          </Col>
        </Row>
      </Col>
    );
  };

  const renderViewConfiguration = () => {
    if (viewConfiguration) {
      const sections = viewConfiguration.definition?.details || [];

      if (sections.length > 0) {
        return sections.map((section: IViewConfigurationDetailSection) => {
          return <Row>{renderSection(section)}</Row>;
        });
      } else {
        return <RecordProperties record={record} showMoreByDefault />;
      }
    } else {
      return <RecordProperties record={record} showMoreByDefault />;
    }
  };

  return renderViewConfiguration();
};
export default DetailsConfigurator;
