import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import React from 'react';
import { connect } from 'react-redux';
import { gantt, GanttConfigOptions, GanttStatic } from '@d19n/dhtmlx-gantt-pro';
import { GanttTaskLinkType } from '@d19n/sandbox-odin-sdk/dist/entities-v2/GanttTaskLink';
import '@d19n/dhtmlx-gantt-pro/codebase/dhtmlxgantt.css';
import { httpGet, httpPost, httpPut, httpDelete } from '@core/http/requests';
import { SET_GANTT_INSTANCE } from './store/reducer';
import { errorNotification } from '../../../redux/stores/notification/reducers';
import { Alert } from '@blueprintjs/core';
import './styles.scss';

interface Props {
  record: DbRecordEntityTransform;
  config?: GanttConfigOptions;
  dataProcessorUrl?: string | undefined;
  setGanttInstance: (gantt: GanttStatic) => void;
  ganttInstance: GanttStatic | null;
  notifyError: (params: any) => void;
  ganttSchemaActions: any[];
}

interface State {
  isDeleteLinkAlertOpen: boolean;
  selectedLinkId: string | null;
  LinkFromTaskName: string;
  LinkToTaskName: string;
}

class GanttChart extends React.Component<Props, State> {
  private ganttRef = React.createRef<HTMLDivElement>();

  state = {
    isDeleteLinkAlertOpen: false,
    selectedLinkId: null,
    LinkFromTaskName: '',
    LinkToTaskName: '',
  };

  componentDidMount() {
    if (this.ganttRef.current) {
      this.configureGantt(gantt);
      gantt.init(this.ganttRef.current);
    }
  }

  componentWillUnmount(): void {
    gantt.clearAll();
  }

  private configureGantt(ganttInstance: GanttStatic) {
    ganttInstance.plugins({ marker: true, tooltip: true, critical_path: true, export_api: true });

    ganttInstance.config.highlight_critical_path = false;
    ganttInstance.config.show_slack = false;

    ganttInstance.config.baselines = {
      datastore: 'baselines',
      render_mode: false,
      dataprocessor_baselines: false,
      row_height: 16,
      bar_height: 8,
    };

    // Set XML date format to handle ISO dates correctly. Avoids us having to convert the dates in the backend.
    ganttInstance.config.xml_date = '%Y-%m-%d %H:%i';
    // Set date format so PDF exports work as intended
    ganttInstance.config.date_format = '%Y-%m-%d %H:%i';

    // Add today red line marker
    ganttInstance.addMarker({
      start_date: new Date(),
      css: 'today-line',
      title: new Date().toDateString(),
    });

    ganttInstance.config.scale_height = 50;
    ganttInstance.config.scales = [
      { unit: 'month', step: 1, format: '%F, %Y' },
      { unit: 'day', step: 1, format: '%j, %D' },
    ];

    // Override default delete link confirmation modal with BlueprintJS Alert
    ganttInstance.attachEvent('onLinkDblClick', (id) => {
      const link = ganttInstance.getLink(id);

      this.setState({
        isDeleteLinkAlertOpen: true,
        selectedLinkId: id.toString(),
        LinkFromTaskName: ganttInstance.getTask(link.source).text,
        LinkToTaskName: ganttInstance.getTask(link.target).text,
      });

      return false; // Prevent default behavior
    });

    // Override config if provided
    if (this.props.config) {
      // Apply config properties to gantt instance, ensuring functions are correctly assigned
      Object.entries(this.props.config).forEach(([key, value]) => {
        if (key === 'locale') {
          // Merge locale settings instead of overwriting
          ganttInstance.locale.labels = { ...ganttInstance.locale.labels, ...value.labels };
        } else if (key === 'columns') {
          ganttInstance.config.columns = value;
        } else {
          gantt[key] = value;
        }
      });
    }

    ganttInstance.templates.grid_date_format = function (date, column) {
      return ganttInstance.date.date_to_str(ganttInstance.config.date_grid)(date);
    };

    ganttInstance.templates.task_class = function (start, end, task) {
      var css = [];

      switch (task.progress) {
        case 1:
          css.push('complete');
          break;
        case 0:
          css.push('not_started');
          break;
        default:
          css.push('in_progress');
          break;
      }

      return css.join(' ');
    };

    ganttInstance.templates.progress_text = function (start, end, task) {
      let progress = task.progress != null ? Math.floor(task?.progress * 100) : 0;
      return `<span>${progress}%</span>`;
    };

    if (this.props.dataProcessorUrl) {
      const makeRequest = async (
        method: string,
        entity: string,
        data?: any,
        id?: string | number,
      ) => {
        // Get the appropriate schema actions for the entity
        const createAction = this.props.ganttSchemaActions.find(
          (action: any) => action?.isCreate == true && action?.definition?.entityName == entity,
        );
        const updateAction = this.props.ganttSchemaActions.find(
          (action: any) => action?.isUpdate == true && action?.definition?.entityName == entity,
        );

        let properties = {};

        if (method !== 'delete') {
          // Build properties based on entity type
          if (entity == 'GanttTask') {
            properties = {
              Type: data.type,
              Name: data.text,
              Description: data.description,
              Progress: data.progress,
              ActualStartDate: data.start_date,
              ActualEndDate: data.end_date,
              GanttProjectId: this.props.record.id,
              OwnerId: data.owner_id,
            };
          } else if (entity == 'GanttTaskLink') {
            const linkTypes: any = {
              0: GanttTaskLinkType.FINISH_TO_START,
              1: GanttTaskLinkType.START_TO_START,
              2: GanttTaskLinkType.FINISH_TO_FINISH,
              3: GanttTaskLinkType.START_TO_FINISH,
            };

            properties = {
              FromTask: data.source,
              ToTask: data.target,
              Type: linkTypes[data.type],
            };
          }
        }

        try {
          switch (method) {
            case 'post':
              const createPayload = [
                {
                  entity: `ProjectModule:${entity}`,
                  type: 'DEFAULT',
                  properties,
                  schemaId: createAction?.schemaId,
                  schemaActionId: createAction?.schemaActionId,
                  schemaTypeId: createAction?.schemaTypeId,
                },
              ];

              const createResponse = await httpPost(`SchemaModule/v1.0/db/bulk-upsert`, {
                recordsToUpsert: createPayload,
                options: { linkRelatedRecordsAfterUpsert: true },
              });
              return { action: 'inserted', tid: createResponse.data.data.creates[0].id };

            case 'put':
              const updatePayload = [
                {
                  id: id,
                  entity: `ProjectModule:${entity}`,
                  type: 'DEFAULT',
                  properties,
                  schemaId: updateAction?.schemaId,
                  schemaActionId: updateAction?.schemaActionId,
                  schemaTypeId: updateAction?.schemaTypeId,
                },
              ];

              await httpPut(`SchemaModule/v1.0/db/bulk-update`, {
                recordsToUpdate: updatePayload,
                options: { linkRelatedRecordsAfterUpsert: true },
              });
              return { action: 'updated' };

            case 'delete':
              await httpDelete(`ProjectModule/v1.0/db/${entity}/${id}`);
              return { action: 'deleted' };

            default:
              throw new Error(`Unsupported method: ${method}`);
          }
        } catch (err: any) {
          this.props.notifyError({
            message:
              err?.message || `HTTP error ${err?.response?.status}: Failed to process request`,
          });
          throw err; // Re-throw to ensure gantt chart stays in sync
        }
      };

      // Create the data processor utilising our function for API calls, ensuring responses are handled correctly
      const dp = ganttInstance.createDataProcessor({
        task: {
          create: (data) => makeRequest('post', 'GanttTask', data),
          update: (data, id) => makeRequest('put', 'GanttTask', data, id),
          delete: (id) => makeRequest('delete', 'GanttTask', undefined, id),
        },
        link: {
          create: (data) => makeRequest('post', 'GanttTaskLink', data),
          update: (data, id) => makeRequest('put', 'GanttTaskLink', data, id),
          delete: (id) => makeRequest('delete', 'GanttTaskLink', undefined, id),
        },
      });

      // Load the initial tasks and links
      httpGet(this.props.dataProcessorUrl).then((response) => {
        ganttInstance.parse(response.data.data);
        this.props.setGanttInstance(ganttInstance);
      });

      // set the row heights to allow room for the toggleable baseline dates
      ganttInstance.attachEvent('onBeforeTaskDisplay', (id: any, task: any) => {
        task.bar_height = 30;
        task.row_height = 30 + 16; // task.bar_height + ganttInstance.config.baselines.row_height
        return true;
      });
    }
  }

  handleDeleteLink = () => {
    const { ganttInstance } = this.props;
    const { selectedLinkId } = this.state;

    if (selectedLinkId && ganttInstance) {
      ganttInstance.deleteLink(selectedLinkId);
    }

    this.setState({
      isDeleteLinkAlertOpen: false,
      selectedLinkId: null,
      LinkFromTaskName: '',
      LinkToTaskName: '',
    });
  };

  render() {
    const { isDeleteLinkAlertOpen, LinkFromTaskName, LinkToTaskName } = this.state;

    return (
      <>
        <div
          ref={this.ganttRef}
          style={{
            width: '100%',
            // Subtract height of header, navigation and padding (190px) to fill remaining viewport
            height: 'calc(100vh - 190px)',
          }}
        />
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          intent="danger"
          isOpen={isDeleteLinkAlertOpen}
          onCancel={() => this.setState({ isDeleteLinkAlertOpen: false })}
          onConfirm={this.handleDeleteLink}
        >
          <p>
            Are you sure you want to delete the link between <b>{LinkFromTaskName}</b> and{' '}
            <b>{LinkToTaskName}</b>? This action cannot be undone.
          </p>
        </Alert>
      </>
    );
  }
}

const mapState = (state: any) => ({
  ganttInstance: state.ganttReducer.ganttInstance,
});

const mapDispatch = (dispatch: any) => ({
  setGanttInstance: (gantt: GanttStatic) => dispatch({ type: SET_GANTT_INSTANCE, payload: gantt }),
  notifyError: (params: any) => dispatch(errorNotification(params)),
});

export default connect(mapState, mapDispatch)(GanttChart);
