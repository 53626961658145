import { Section } from '@blueprintjs/core';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import React, { FC, useContext } from 'react';
import SendDynamicEmail from '@core/components/SendDynamicEmail';
import { DetailViewContext } from '@core/components/DetailViewContextProvider';
import DetailView from '@core/views/DefaultDetailView';
import CreateGocardlessMandate from '@core/modules/BillingModule/components/CreateGocardlessMandate';
import PaymentMethodRefund from '@core/modules/BillingModule/components/PaymentMethodRefund';
import DetailsColumn from './DetailsColumn';
import ContactAccountAssociations from './ContactAccountAssociations';
import { Col, Row } from 'antd';
import CreateBillingRequestFromContact from './CreateBillingRequestFromContact';
import SchemaActionPageHeader from '@core/components/SchemaActions/SchemaActionPageHeader';

interface Props {}

const ContactDetailView: FC<Props> = (props: Props) => {
  const { record, schema } = useContext(DetailViewContext);

  return (
    <>
      <DetailView
        hideSchemaActionsInHeader
        showCollaborators
        showCommunicationHistory
        hideMiddleColumnCardWithTabs
        rightColumnTop={[
          // Action Section
          <Section compact title="Actions" icon="cog">
            <div style={{ padding: '15px 15px 5px 15px' }}>
              <Row gutter={12}>
                {/* Create Gocardless Mandate */}
                <Col span={12} style={{ marginBottom: 10 }}>
                  <CreateGocardlessMandate record={record!} fill />
                </Col>

                {/* Payment Method Refund */}
                <Col span={12} style={{ marginBottom: 10 }}>
                  <PaymentMethodRefund record={record!} fill />
                </Col>

                {/* Setup Mandate */}
                <Col span={12} style={{ marginBottom: 10 }}>
                  <SendDynamicEmail
                    buttonText="Setup Mandate"
                    fill
                    email={{
                      to: getProperty(record, 'EmailAddress'),
                      templateLabel: 'SENDGRID_DD_MISSING',
                      dynamicTemplateData: {
                        id: record?.id,
                        recordId: record?.id,
                        description: 'Setup Direct Debit - Payment info missing',
                        properties: {
                          FirstName: getProperty(record, 'FirstName'),
                        },
                      },
                    }}
                  />
                </Col>

                {/* Create Billing Request from Contact */}
                <Col span={12} style={{ marginBottom: 10 }}>
                  <CreateBillingRequestFromContact contactRecord={record!} fill />
                </Col>
              </Row>

              <SchemaActionPageHeader
                moduleName={schema.moduleName}
                entityName={schema.entityName}
                record={record!}
                launcherType="INLINE"
                colSpan={12}
                rowGutter={12}
                colStyle={{ marginBottom: 10 }}
              />
            </div>
          </Section>,
        ]}
        middleColumnTop={[<ContactAccountAssociations />]}
        defaultTabKey="Summary"
        leftColumn={[<DetailsColumn contactRecord={record} />]}
      />
    </>
  );
};

export default ContactDetailView;
