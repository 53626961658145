import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Select } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { ISearchRecords, searchRecordsRequest } from '@redux/stores/records/actions';
import { IRecordReducer } from '@redux/stores/records/reducer';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '@redux/stores/recordAssociations/actions';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '@redux/stores/schemas/actions';
import { ISchemaReducer } from '@redux/stores/schemas/reducer';
import { getSchemaFromShortListByModuleAndEntity } from '@core/helpers/schemaHelpers';

const { Option } = Select;

const { PRODUCT_MODULE } = SchemaModuleTypeEnums;
const { PRICE_BOOK } = SchemaModuleEntityTypeEnums;

interface Props {
  schemaReducer: ISchemaReducer;
  recordReducer: IRecordReducer;
  searchRecords: any;
  onOptionSelected: any;
  getAssociations: any;
  getSchemaByModuleEntity: Function;
}

interface State {
  selected: any;
  optionsVisible: boolean;
}

class PriceBookSelector extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: undefined,
      optionsVisible: false,
    };
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.recordReducer.isSearching !== this.props.recordReducer.isSearching) {
      if (!this.props.recordReducer.isSearching) {
        this.openSelectOptions();
      }
    }
  }

  private openSelectOptions() {
    const { recordReducer, schemaReducer } = this.props;

    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PRODUCT_MODULE,
      PRICE_BOOK,
    );
    if (schema) {
      // @ts-ignore
      const data = recordReducer.list[schema.id];
      if (data && !this.state.selected) {
        this.setState({
          selected: undefined,
          optionsVisible: true,
        });
      }
    }
  }

  searchRecordOnChange(e?: any) {
    const { schemaReducer, recordReducer, searchRecords } = this.props;
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PRODUCT_MODULE,
      PRICE_BOOK,
    );

    if (schema) {
      searchRecords({
        schema: schema,
        searchQuery: {
          terms: e ? e.target.value : recordReducer.searchQuery.terms,
          schemas: schema.id,
          pageable: {
            size: 100,
          },
        },
      });
    }
  }

  setInitialSearchQuery() {
    const { recordReducer, schemaReducer } = this.props;
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PRODUCT_MODULE,
      PRICE_BOOK,
    );
    if (!!recordReducer.searchQuery && schema) {
      // @ts-ignore
      return !!recordReducer.searchQuery[schema.id]
        ? recordReducer.searchQuery[schema.id].terms
        : '';
    }
  }

  initialize() {
    const { searchRecords, schemaReducer, getSchemaByModuleEntity } = this.props;

    const priceBookSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PRODUCT_MODULE,
      PRICE_BOOK,
    );

    // Get schema from shortlist and
    if (priceBookSchema) {
      searchRecords({
        schema: priceBookSchema,
        searchQuery: {
          terms: '*',
          schemas: priceBookSchema?.id,
          pageable: {
            size: 100,
          },
        },
      });
    } else {
      getSchemaByModuleEntity(
        {
          moduleName: PRODUCT_MODULE,
          entityName: PRICE_BOOK,
        },
        (response: SchemaEntity) => {
          if (response) {
            searchRecords({
              schema: response,
              searchQuery: {
                terms: '*',
                schemas: response?.id,
                pageable: {
                  size: 100,
                },
              },
            });
          }
        },
      );
    }
  }

  private renderPriceBookOptions() {
    const { recordReducer, schemaReducer } = this.props;
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PRODUCT_MODULE,
      PRICE_BOOK,
    );
    if (schema) {
      // @ts-ignore
      const data = recordReducer.list[schema.id];

      return data?.map((elem: DbRecordEntityTransform) => (
        <Option key={elem?.id?.toString()} value={elem.id}>
          {elem.title}
        </Option>
      ));
    }
  }

  private optionSelected(val: any) {
    const { schemaReducer, getAssociations } = this.props;
    this.setState({
      selected: val,
      optionsVisible: false,
    });
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PRODUCT_MODULE,
      PRICE_BOOK,
    );

    if (schema) {
      getAssociations({
        recordId: val,
        key: 'Product',
        schema: schema,
        entities: ['Product'],
      });
    }
    this.props.onOptionSelected(val);
  }

  onSearch(val: any) {
    const { schemaReducer, searchRecords } = this.props;
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PRODUCT_MODULE,
      PRICE_BOOK,
    );
    searchRecords({
      schema: schema,
      searchQuery: {
        terms: val,
        schemas: schema?.id,
        pageable: {
          size: 100,
        },
      },
    });
  }

  render() {
    const { recordReducer } = this.props;
    return (
      <div style={{ width: 300 }}>
        <Select
          showSearch
          loading={recordReducer.isSearching}
          style={{ width: '100%' }}
          defaultValue={[]}
          placeholder="Select Pricebook"
          onSearch={(val: any) => this.onSearch(val)}
          onSelect={(val: any) => this.optionSelected(val)}
          filterOption={false}
          allowClear
          onClear={() => this.onSearch('*')}
        >
          {this.renderPriceBookOptions()}
        </Select>
      </div>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
});

const mapDispatch = (dispatch: any) => ({
  searchRecords: (params: ISearchRecords) => dispatch(searchRecordsRequest(params)),
  getAssociations: (params: IGetRecordAssociations) =>
    dispatch(getRecordAssociationsRequest(params)),
  getSchemaByModuleEntity: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(PriceBookSelector);
