import {
  Card,
  CardList,
  Drawer,
  InputGroup,
  Section,
  SectionCard,
  Spinner,
  Tag,
} from '@blueprintjs/core';
import { useAction } from '@core/hooks/useAction';
import { httpGet } from '@core/http/requests';
import { getSchemaActionType } from '@core/modules/ControlPanelModule/components/Actions/helpers';
import SchemaActionDetails from '@core/modules/ControlPanelModule/components/Actions/SchemaActionDetails';
import SchemaActionEditCreateForm from '@core/modules/ControlPanelModule/components/Actions/SchemaActionEditCreateForm';
import { getErrorMessage } from '@core/modules/ControlPanelModule/helpers/errors';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { displayMessage } from '@redux/stores/messages/reducers';
import { Col, Row } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { SchemaDetailsContext } from '../..';
import { Empty } from '../Empty';
import './styles.scss';

export const ActionsSection = ({ schemaTypeId }: any) => {
  const alertMessage = useAction(displayMessage);

  const { schema } = useContext(SchemaDetailsContext);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [actions, setActions] = useState<any[]>([]);
  const [pipeline, setPipeline] = useState<PipelineEntity>();
  const [selectedAction, setSelectedAction] = useState<SchemaActionEntity>();
  const [refetch, setRefetchActions] = useState(0);
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);

  const filteredActions = actions
    .filter((action) => {
      const schemaTypeFilter = schemaTypeId === 'all' ? true : action.schemaTypeId === schemaTypeId;
      const nameFilter = action.name.toLocaleLowerCase().includes(search?.toLowerCase());

      return schemaTypeFilter && nameFilter;
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    if (!schema?.id) return;
    let mounted = true;

    setIsLoading(true);
    Promise.all([getSchemaActions(schema.id), getSchemaPipelines(schema, schemaTypeId)])
      .then(([actions, pipelines]) => {
        if (!mounted) return;

        setActions(actions);
        setPipeline(pipelines[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!mounted) return;

        const message = getErrorMessage(error);
        alertMessage({
          body: 'Could not retrieve schema details. ' + message,
          type: 'error',
        });
        setIsLoading(false);
        setActions([]);
      });

    return () => {
      mounted = false;
    };
  }, [schema?.id, refetch, schemaTypeId]);

  const getSchemaActions = async (schemaId: string): Promise<SchemaActionEntity[]> => {
    // TODO: this endpoint should be /schemas/${schemaId}/actions
    return await httpGet(`SchemaModule/v1.0/schemas-actions/schema/${schemaId}`).then((res) => {
      return res.data.data || [];
    });
  };

  const getSchemaPipelines = async (
    schema: SchemaEntity,
    schemaTypeId?: string | null,
  ): Promise<PipelineEntity[]> => {
    const schemaType = schema.types.find((type) => type.id === schemaTypeId);
    return await httpGet(
      `SchemaModule/v1.0/pipelines/bymodule/${schema?.moduleName}/${schema?.entityName}${
        schemaType ? `?schemaType=${schemaType.name}` : '?schemaType='
      }`,
    ).then((res: any) => {
      return res.data?.data || [];
    });
  };

  const onActionDelete = (actionId: string) => {
    setActions(actions.filter((a) => a.id !== actionId));
    setIsDrawerVisible(false);
    setSelectedAction(undefined);
  };

  const onActionUpdate = (action: SchemaActionEntity) => {
    if (action) {
      setActions(actions.map((a) => (a.id === action.id ? action : a)));
      if (selectedAction?.id === action.id) {
        setSelectedAction(action);
      }
    }
  };

  const onActionCreate = (action: SchemaActionEntity) => {
    setActions([...actions, action]);
    setSelectedAction(action);
    setIsDrawerVisible(true);
  };

  return (
    <>
      <Section
        title="Actions"
        className="actions-section"
        rightElement={
          <>
            <InputGroup
              style={{ maxWidth: 170 }}
              leftIcon="search"
              placeholder="Search"
              type="search"
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
            />
            <SchemaActionEditCreateForm
              mode="CREATE"
              onCreate={onActionCreate}
              defaultSchemaId={schema.id}
              buttonProps={{
                fill: false,
                outlined: true,
                text: 'Add',
                style: { borderRadius: 5 },
              }}
            />
          </>
        }
      >
        <SectionCard padded={false} className="limited-height">
          {isLoading && (
            <div className="loading-page">
              <Spinner />
            </div>
          )}
          {actions.length === 0 ? (
            <Empty icon="search" text="No actions" />
          ) : (
            <CardList bordered={false} compact>
              {filteredActions.map((action) => (
                <Card
                  key={action.id}
                  interactive
                  compact={true}
                  onClick={() => {
                    setSelectedAction(action);
                    setIsDrawerVisible(true);
                  }}
                >
                  <Row justify="space-between" style={{ width: '100%' }}>
                    <Col>
                      <span style={{ color: 'black', fontWeight: 500 }}>{action.name}</span>
                    </Col>

                    <Col>
                      <Tag round minimal intent="primary" fill={false}>
                        {getSchemaActionType(action)}
                      </Tag>
                    </Col>
                  </Row>
                </Card>
              ))}
            </CardList>
          )}
        </SectionCard>
      </Section>

      {/* Action Drawer */}
      <Drawer
        className="actionsSectionDrawer"
        isOpen={isDrawerVisible && !!selectedAction}
        onClose={() => setIsDrawerVisible(false)}
        title="Action Details"
        hasBackdrop
        style={{ width: isMobile ? '95%' : '30%' }}
      >
        <Row>
          <Col span={24} style={{ height: 'calc(100vh - 42px)', overflowY: 'auto' }}>
            <SchemaActionDetails
              schemaAction={selectedAction!}
              allSchemaActions={actions}
              onDelete={onActionDelete}
              onUpdate={onActionUpdate}
              setSelectedAction={setSelectedAction}
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};
