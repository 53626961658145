import {
  Button,
  Divider,
  InputGroup,
  MenuItem,
  Section,
  Tag,
  TagInput,
  TextArea,
  Tooltip,
} from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import FileUploaderPopoverButton from '@core/components/FileUploaderPopoverButton';
import MessageTemplateHandler from 'src/core/modules/SupportModule/views/MyCasesView/components/MessageTemplateHandler';
import { getOrganizationName } from '@core/http/helpers';
import { httpPost } from '@core/http/requests';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { displayMessage } from '@redux/stores/messages/reducers';
import {
  createRecordsRequest,
  getRecordByIdRequest,
  ICreateRecords,
  IGetRecordById,
} from '@redux/stores/records/actions';
import { Col, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { MyCasesContext } from '../../index';
import { MY_CASES_TOGGLE_EMAIL_EDITOR } from '../../store/constants';
import { allowedAddresses } from './constants';
import './styles.scss';

interface Props {
  createRecord: (params: ICreateRecords, cb: any) => void;
  getRecordById: (payload: IGetRecordById, cb: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
  userReducer: any;
}

interface IFromAddress {
  title: string;
  id: string;
}

const MyCasesEmailEditorWidget: React.FC<Props> = (props: Props) => {
  const { alertMessage, userReducer } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const { selectedCase } = state;

  const isModalOpen: boolean = state.isComposingEmail;
  const inputRef = React.createRef<HTMLTextAreaElement>();
  const signature = `\n\n---\n${userReducer.user?.firstname}\nYouFibre Customer Services`;

  const [newEmailBody, setNewEmailBody] = useState<string>('');
  const [newEmailSubject, setNewEmailSubject] = useState<string>('');
  const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);

  const [selectedFromEmailAddress, setSelectedFromEmailAddress] = useState<string>('');
  const [fromEmailAddressList, setFromEmailAddressList] = useState<IFromAddress[]>([]);

  const [validationErrorMessage, setValidationErrorMessage] = useState<string>('');
  const [CCEmails, setCCEmails] = useState<string[]>([]);
  const [attachments, setAttachments] = useState<File[]>([]);

  const isCaseOwn = getProperty(state.selectedCase, 'OwnerId') === userReducer.user.id;

  const initializeEmailEditor = () => {
    const customerEmails = state.selectedCaseEmails?.filter(
      (elem) => elem.properties?.SenderType === 'CUSTOMER',
    );

    let addressList: IFromAddress[] = [];
    let fromAddress: string = '';

    // 1. Sandbox / Development
    if (['Development', 'Sandbox'].includes(getOrganizationName())) {
      addressList.push({ id: 'info@d19n.io', title: 'info@d19n.io' });
      fromAddress = 'info@d19n.io';
    }
    // 2. YouFibre
    else if (['YouFibre'].includes(getOrganizationName())) {
      addressList.push(
        { id: 'hello@youfibre.com', title: 'hello@youfibre.com' },
        { id: 'retentions@youfibre.com', title: 'retentions@youfibre.com' },
        {
          id: 'installations@youfibre.com',
          title: 'installations@youfibre.com',
        },
      );
      fromAddress = 'hello@youfibre.com';
    }
    // 3. Netomnia
    else if (['Netomnia'].includes(getOrganizationName())) {
      addressList.push(
        { id: 'hello@netomnia.com', title: 'hello@netomnia.com' },
        { id: 'complaints@netomnia.com', title: 'complaints@netomnia.com' },
        { id: 'build@netomnia.com', title: 'build@netomnia.com' },
        { id: 'buildqueries@netomnia.com', title: 'buildqueries@netomnia.com' },
        { id: 'disputes@netomnia.com', title: 'disputes@netomnia.com' },
        { id: 'fundingforbroadband@netomnia.com', title: 'fundingforbroadband@netomnia.com' },
        { id: 'installations@netomnia.com', title: 'installations@netomnia.com' },
        { id: 'tlos@netomnia.com', title: 'tlos@netomnia.com' },
      );
      fromAddress = 'hello@netomnia.com';
    }

    // 3. If there are CUSTOMER type emails in the feed
    if (customerEmails.length > 0) {
      // Find if any customer email contains any allowed address in either From or Cc
      const filteredCustomerEmails = customerEmails.filter(
        (email: DbRecordEntityTransform) =>
          allowedAddresses.some(
            (address: string) => getProperty(email, 'To')?.indexOf(address) > -1,
          ) ||
          allowedAddresses.some(
            (address: string) => getProperty(email, 'Cc')?.indexOf(address) > -1,
          ),
      );

      // If there is any email with allowed address, construct From / To / Subject fields
      if (filteredCustomerEmails.length > 0) {
        const lastCustomerEmail: DbRecordEntityTransform =
          filteredCustomerEmails[filteredCustomerEmails.length - 1];

        const allowedAddress = allowedAddresses.find(
          (address: string) =>
            getProperty(lastCustomerEmail, 'To')?.indexOf(address) > -1 ||
            getProperty(lastCustomerEmail, 'Cc')?.indexOf(address) > -1,
        );

        if (allowedAddress) {
          setNewEmailSubject(`Re: ${getProperty(lastCustomerEmail, 'Subject')}`);
          fromAddress = allowedAddress;
          addressList.push({
            title: allowedAddress,
            id: allowedAddress,
          });
        }
      }
    }

    setFromEmailAddressList(addressList);
    setSelectedFromEmailAddress(fromAddress);
    setNewEmailBody(signature);
  };

  const toggleEmailEditor = () => {
    dispatch({ type: MY_CASES_TOGGLE_EMAIL_EDITOR, payload: null });
    setNewEmailBody('');
    setFromEmailAddressList([]);
    setSelectedFromEmailAddress('');
    setNewEmailSubject('');
    setValidationErrorMessage('');
    setIsSendingEmail(false);
    setCCEmails([]);
  };

  const validateEmail = () => {
    setValidationErrorMessage('');

    if (isEmailSubjectEmpty()) {
      setValidationErrorMessage('Subject is required');
    } else if (isEmailBodyEmpty()) {
      setValidationErrorMessage('Email body is required');
    } else {
      sendEmail();
    }
  };

  const sendEmail = async () => {
    try {
      setIsSendingEmail(true);

      // The id of the last email record in the case, will be the parent message id of the new email.
      // This means we are writing a reply. If no email exists, then this is the first/parent email in the case.
      let lastEmail = null;
      if (state.selectedCaseEmails.length > 0) {
        lastEmail = state.selectedCaseEmails[state.selectedCaseEmails.length - 1];
      }

      let formData = new FormData();
      formData.append(
        'properties',
        JSON.stringify({
          Subject: newEmailSubject,
          Message: newEmailBody,
          From: selectedFromEmailAddress,
          To: getProperty(state.selectedCaseContact, 'EmailAddress'),
          Cc: CCEmails.length > 0 ? CCEmails.join(',') : undefined,
          Case: selectedCase?.id || null,
          ParentMessageId: lastEmail?.id,
        }),
      );

      // Add attachments
      if (attachments.length > 0) {
        attachments.forEach((file: File) => {
          formData.append('file', file);
        });
      }

      await httpPost(`NotificationModule/v1.0/email/support/send`, formData);

      setAttachments([]);
      setIsSendingEmail(false);
      toggleEmailEditor();
    } catch (error: any) {
      alertMessage({ body: error.response?.data?.message || error.message, type: 'error' });
      setAttachments([]);
      setIsSendingEmail(false);
    }
  };

  const isEmailBodyEmpty = () => {
    return newEmailBody.trim() === '' || newEmailBody.trim() === signature.trim();
  };

  const isEmailSubjectEmpty = () => {
    return newEmailSubject.trim() === '';
  };

  // Add the template to the email body at the cursor position
  const onTemplateInsert = (template: string) => {
    const cursorPosition = inputRef.current?.selectionStart || 0;
    const newEmailBodyArray = newEmailBody.split('');
    newEmailBodyArray.splice(cursorPosition, 0, template);
    setNewEmailBody(newEmailBodyArray.join(''));

    setTimeout(function () {
      const textArea: any = document.getElementById('emailTextArea');
      textArea?.focus();
      textArea?.setSelectionRange(0, 0);
    }, 0);
  };

  const renderFromField: ItemRenderer<any> = (
    address,
    { handleClick, handleFocus, modifiers, query },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={address.id === selectedFromEmailAddress}
        disabled={modifiers.disabled}
        key={address.id}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={`${address.title}`}
      />
    );
  };

  const doesSelectedContactHaveEmail = (): boolean => {
    return !!getProperty(state.selectedCaseContact, 'EmailAddress');
  };

  return (
    <>
      {/* Add Internal Comment Button */}
      <Tooltip
        hoverOpenDelay={1000}
        disabled={doesSelectedContactHaveEmail()}
        content="Email sending is disabled when no contact is available or the contact lacks an email address."
      >
        <Button
          text="Email Customer"
          disabled={
            state.isAddingNote ||
            state.isComposingEmail ||
            !isCaseOwn ||
            !doesSelectedContactHaveEmail()
          }
          outlined
          onClick={() => {
            dispatch({ type: MY_CASES_TOGGLE_EMAIL_EDITOR, payload: true });
            initializeEmailEditor();
          }}
          intent="primary"
          style={{
            boxShadow: '0px 0px 12px 0px rgba(0,0,0,0.10)',
            marginRight: 10,
            borderRadius: 5,
          }}
        />
      </Tooltip>

      {/* Email Widget */}
      {isModalOpen && (
        <div className="newEmailContainer">
          <Section
            compact
            className="newEmailSection"
            title="Compose Email"
            icon={<i className="bi bi-envelope" />}
            rightElement={
              <Button
                minimal
                icon="cross"
                style={{ opacity: isSendingEmail ? 0.3 : 1 }}
                onClick={() => {
                  if (!isSendingEmail) {
                    toggleEmailEditor();
                  }
                }}
              />
            }
          >
            <div
              style={{
                background: 'white',
                height: 390,
                overflowY: 'auto',
                padding: 8,
              }}
            >
              <Row align="middle" justify="start" style={{ marginBottom: 5 }}>
                {/* From */}
                <Col span={2}>
                  <span style={{ color: '#606B85' }}>From: </span>
                </Col>
                <Col style={{ paddingLeft: 5 }}>
                  <Select<IFromAddress>
                    fill={false}
                    items={fromEmailAddressList}
                    filterable={false}
                    itemRenderer={renderFromField}
                    onItemSelect={(e: any) => {
                      setSelectedFromEmailAddress(e.title);
                    }}
                  >
                    <Button
                      small={true}
                      minimal
                      text={selectedFromEmailAddress}
                      rightIcon={<i className="bi bi-chevron-expand" />}
                      alignText={'left'}
                      placeholder="Select an Account"
                    />
                  </Select>
                </Col>
              </Row>
              <Row align="middle" justify="space-between">
                {/* To: */}
                <Col span={1}>
                  <span style={{ color: '#606B85' }}>To: </span>
                </Col>
                <Col span={23} style={{ paddingLeft: 5 }}>
                  <Tag minimal intent="primary" style={{ borderRadius: 5 }}>
                    {state.selectedCaseContact?.title} (
                    {getProperty(state.selectedCaseContact, 'EmailAddress')})
                  </Tag>
                </Col>
              </Row>

              {/* Cc */}
              <Row align="middle">
                <Col span={1}>
                  <span style={{ color: '#606B85' }}>Cc: </span>
                </Col>
                <Col span={23} style={{ paddingLeft: 5 }}>
                  <TagInput
                    fill
                    addOnBlur
                    values={CCEmails}
                    tagProps={{ minimal: true, intent: 'none', style: { borderRadius: 5 } }}
                    className="myCasesEmailTagInput"
                    onChange={(values: any[]) => {
                      setCCEmails(values);
                    }}
                    separator={','}
                    inputProps={{ autoFocus: true }}
                  />
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Divider style={{ marginLeft: 0, marginRight: 0 }} />
                </Col>
              </Row>

              {/* Subject */}
              <Row align="middle">
                <Col>
                  <span style={{ color: '#606B85' }}>Subject: </span>
                </Col>
                <Col>
                  <InputGroup
                    value={newEmailSubject}
                    fill
                    style={{
                      boxShadow: 'none',
                      padding: 0,
                      color: 'black',
                      minWidth: 400,
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setNewEmailSubject(e.target.value);
                      setValidationErrorMessage('');
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Divider style={{ marginLeft: 0, marginRight: 0 }} />
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <div
                    key="emailTextAreaDiv"
                    style={{
                      background: 'white',
                      height: 240,
                      overflowY: 'auto',
                      padding: '3px 1px',
                    }}
                  >
                    <TextArea
                      fill
                      autoFocus
                      id="emailTextArea"
                      key="emailTextArea"
                      inputRef={inputRef}
                      placeholder={'Write your email here'}
                      value={newEmailBody}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setNewEmailBody(e.target.value);
                        setValidationErrorMessage('');
                      }}
                      style={{ height: '100%', boxShadow: 'none', padding: 0 }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div
              style={{
                background: 'white',
                borderTop: '1px solid #D9DADA',
              }}
            >
              <Row
                style={{
                  padding: 10,
                  height: 50,
                }}
                align="middle"
              >
                {/* Send button / Attach Button */}
                <Col span={8}>
                  <Row>
                    <Col>
                      <Button
                        intent="primary"
                        text="Send"
                        onClick={validateEmail}
                        disabled={isEmailBodyEmpty() || isSendingEmail}
                        loading={isSendingEmail}
                        style={{ borderRadius: 5 }}
                      />
                    </Col>
                    <Col style={{ marginRight: 8 }}>
                      <FileUploaderPopoverButton
                        disabled={isSendingEmail}
                        files={attachments}
                        setFiles={setAttachments}
                      />
                    </Col>
                    <Col>
                      <MessageTemplateHandler
                        context="EMAIL"
                        record={state.selectedCaseContact}
                        onTemplateSelected={onTemplateInsert}
                        disabled={isSendingEmail}
                      />
                    </Col>
                  </Row>
                </Col>

                {/* Error Validation */}
                <Col span={16} style={{ textAlign: 'right' }}>
                  <span style={{ color: 'red' }}>{validationErrorMessage}</span>
                </Col>
              </Row>
            </div>
          </Section>
        </div>
      )}
    </>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  createRecord: (params: ICreateRecords, cb: any) => dispatch(createRecordsRequest(params, cb)),
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(MyCasesEmailEditorWidget);
