import { Button, Card, Switch } from '@blueprintjs/core';
import { OrganizationUserGroupEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/group/organization.user.group.entity';
import { RelationTypeEnum } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/types/db.record.association.constants';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Empty, Row } from 'antd';
import { createContext, FC, useEffect, useReducer, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  createRecordsRequest,
  getRecordByIdRequest,
  ICreateRecords,
  IGetRecordById,
} from '@redux/stores/records/actions';
import { httpGet } from '../../http/requests';
import { getOdinSchemaByEntity } from '../../helpers/schemaHelpers';
import { getAssociatedAttachmentsAndNotesWithAttachments, getUsersForMention } from './api';
import { emptyNoteBody, isUserMemberOfInternalNoteGroup } from './helpers';
import NoteAttachments from './NoteAttachments';
import NoteBody from './NoteBody';
import NoteEditor from './NoteEditor';
import {
  NOTE_FEED_ADD_NOTE,
  NOTE_FEED_ADD_NOTES,
  NOTE_FEED_ADD_REPLY,
  NOTE_FEED_ADD_TASK,
  NOTE_FEED_ADD_USERS,
  NOTE_FEED_DELETE_NOTE,
  NOTE_FEED_UPDATE_NOTE,
  NOTE_FEED_UPDATE_REACTION,
} from './store/constants';
import { INoteFeedReducer, noteFeedReducer, noteFeedReducerInitialState } from './store/reducer';
import { INTERNAL_NOTE_GROUP_NAME, SUPPORT_TASK, TReaction } from './types';

interface Props {
  record: DbRecordEntityTransform;
  userReducer: any;
  createRecord: (params: ICreateRecords, cb?: any) => void;
  hideCreateNoteForm?: boolean;
  getRecordById: (payload: IGetRecordById, cb: any) => void;
  onNoteCreated?: () => void;
  includeRelatedEntities?: string[];
  previewMode?: boolean;
  showOnlyNoteIds?: string[];
}

const { SUPPORT_MODULE, SCHEMA_MODULE } = SchemaModuleTypeEnums;
const { NOTE, FILE } = SchemaModuleEntityTypeEnums;

let timer: NodeJS.Timeout | undefined = undefined;
const interval = 10000; // 10 seconds
const STOP_POLLING = true;

export const NoteFeedContext = createContext<{
  state: INoteFeedReducer;
  dispatch: any;
  updateNote: (noteId: string, noteContents: string, groups?: any[]) => void;
  deleteNote: (noteId: string) => void;
  addNote: (note: DbRecordEntityTransform) => void;
  addReply: (parentNoteId: string, newReply: DbRecordEntityTransform) => void;
  addTask: (parentNoteId: string, newTask: DbRecordEntityTransform) => void;
  updateReaction: (noteId: string, reactions: TReaction[]) => void;
  addUsers: (users: any[]) => void;
}>({
  state: noteFeedReducerInitialState,
  dispatch: () => {},
  updateNote: () => {},
  deleteNote: () => {},
  addNote: () => {},
  addReply: () => {},
  addTask: () => {},
  updateReaction: () => {},
  addUsers: () => {},
});

const NoteFeed: FC<Props> = (props: Props) => {
  const {
    record,
    hideCreateNoteForm,
    onNoteCreated,
    createRecord,
    getRecordById,
    previewMode,
    showOnlyNoteIds,
    userReducer,
  } = props;

  const [newNoteBody, setNewNoteBody] = useState<any>(emptyNoteBody);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [noteSchema, setNoteSchema] = useState<SchemaEntity | undefined>(undefined);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [isSearchingUsers, setIsSearchingUsers] = useState<boolean>(false);
  const [userList, setUserList] = useState<any[]>([]);
  const [clear, setClear] = useState<boolean>(false);
  const [state, dispatch] = useReducer(noteFeedReducer, noteFeedReducerInitialState);
  const [isPrivateNote, setIsPrivateNote] = useState<boolean>(false);

  // This is the list of file ids that are attached to the new note
  const [attachedFileIds, setAttachedFileIds] = useState<string[]>([]);

  const searchInput = useRef<any>(null);

  // Reducer Actions
  const addUsers = (users: any[]) => {
    dispatch({ type: NOTE_FEED_ADD_USERS, payload: users });
  };
  const addNotes = (notes: DbRecordEntityTransform[]) => {
    dispatch({ type: NOTE_FEED_ADD_NOTES, payload: notes });
  };
  const updateNote = (noteId: string, noteContents: string, groups?: any[]) => {
    dispatch({ type: NOTE_FEED_UPDATE_NOTE, payload: { noteId, noteContents, groups } });
  };
  const deleteNote = (noteId: string) => {
    dispatch({ type: NOTE_FEED_DELETE_NOTE, payload: { noteId } });
  };
  const addNote = (note: DbRecordEntityTransform) => {
    dispatch({ type: NOTE_FEED_ADD_NOTE, payload: note });
  };
  const addReply = (noteId: string, newReply: DbRecordEntityTransform) => {
    dispatch({ type: NOTE_FEED_ADD_REPLY, payload: { parentNoteId: noteId, newReply } });
  };
  const addTask = (noteId: string, newTask: DbRecordEntityTransform) => {
    dispatch({ type: NOTE_FEED_ADD_TASK, payload: { noteId, newTask } });
  };
  const updateReaction = (noteId: string, reactions: TReaction[]) => {
    dispatch({ type: NOTE_FEED_UPDATE_REACTION, payload: { noteId, reactions } });
  };

  useEffect(() => {
    getUsersForMention().then((res: any) => {
      addUsers(res);
    });
  }, []);

  // Configure timer
  const startTimer = () => {
    timer = setInterval(() => {
      if (!document.hidden && !STOP_POLLING) {
        getNoteAssociations();
      }
    }, interval);
  };
  const clearTimer = () => {
    clearInterval(timer);
    timer = undefined;
  };

  useEffect(() => {
    if (!isSearchingUsers) {
      searchInput.current?.focus();
    }
  }, [isSearchingUsers]);

  useEffect(() => {
    if (isPopoverOpen) {
      setIsSearchingUsers(true);
      getUsersForMention().then((res: any) => {
        setUserList(res);
        setIsSearchingUsers(false);
      });
    }
  }, [isPopoverOpen]);

  // On component mount / record update, get note associations
  useEffect(() => {
    getNoteSchema();
  }, []);

  useEffect(() => {
    // console.log('%cdebug: getting notes', 'color:orange');
    getNoteAssociations();
  }, [record]);

  // On component mount start timer, on unmount clear timer
  useEffect(() => {
    startTimer();
    return () => {
      clearTimer();
    };
  }, []);

  const getNoteSchema = async () => {
    const noteSchema = await getOdinSchemaByEntity(SUPPORT_MODULE, NOTE);
    setNoteSchema(noteSchema);
  };

  // Fetch all notes from either 1. parent record or 2. parent and associations
  const getNoteAssociations = async () => {
    if (record) {
      getAssociatedAttachmentsAndNotesWithAttachments(record).then((res: any) => {
        addNotes(res);
      });
    }
  };

  const createNoteRequest = () => {
    if (record && noteSchema && newNoteBody) {
      setIsCreating(true);

      // Is note private?
      let privateGroupId: string | null = null;
      if (isPrivateNote && userReducer) {
        const groups = userReducer.user.groups || [];
        const privateGroup = groups.find(
          (group: OrganizationUserGroupEntity) => group.name === INTERNAL_NOTE_GROUP_NAME,
        );
        if (privateGroup) {
          privateGroupId = privateGroup.id;
        }
      }

      // 1. Create the note record
      return createRecord(
        {
          schema: noteSchema,
          createUpdate: [
            {
              schemaId: noteSchema.id,
              entity: `${SUPPORT_MODULE}:${NOTE}`,
              properties: {
                JSONContent: newNoteBody,
              },
              associations: [
                {
                  entity: record.entity!,
                  recordId: record.id,
                },
                // Attach file associations to the note
                ...attachedFileIds.map((fileId: string) => ({
                  entity: `${SCHEMA_MODULE}:${FILE}`,
                  recordId: fileId,
                  relationType: RelationTypeEnum.CHILD,
                })),
              ],
              groups: privateGroupId ? [privateGroupId] : [],
            },
          ],
        },
        (createResponse: any) => {
          setIsCreating(false);
          setNewNoteBody(emptyNoteBody);
          initializeClear();

          // 1. Fetch note by id
          getRecordById(
            { schema: noteSchema, recordId: createResponse.id },
            (noteResponse: any) => {
              // 2. Fetch associated files (attachments) and set to the note response
              httpGet(
                `SupportModule/v1.0/db-associations/Note/${noteResponse.id}/one-relation?entity=File&withLinks=false`,
              ).then((fileAssocRes: any) => {
                const Files = fileAssocRes?.data[FILE]?.dbRecords || [];
                if (Files.length > 0) {
                  noteResponse[FILE] = fileAssocRes.data[FILE];
                }
                addNote(noteResponse);
              });
            },
          );
          setIsPrivateNote(false);
          onNoteCreated && onNoteCreated();
          setAttachedFileIds([]);
        },
      );
    }
  };

  const isNoteFormEmpty = JSON.stringify(newNoteBody) === JSON.stringify(emptyNoteBody);

  const initializeClear = () => {
    setClear(true);
    setAttachedFileIds([]);
    setTimeout(() => {
      setClear(false);
    }, 150);
  };

  const addFileToAttachments = (id: string) => {
    setAttachedFileIds([...attachedFileIds, id]);
  };

  const removeFileFromAttachments = (id: string) => {
    setAttachedFileIds(attachedFileIds.filter((fileId: string) => fileId !== id));
  };

  const canFormBeCleared =
    JSON.stringify(newNoteBody) !== JSON.stringify(emptyNoteBody) || attachedFileIds.length > 0;

  let filteredNotes = Object.assign([], state.notes);

  // If user wants to show only specific notes, filter out the rest
  if (showOnlyNoteIds) {
    filteredNotes = filteredNotes.filter((note: DbRecordEntityTransform) =>
      showOnlyNoteIds.includes(note.id),
    );
  }

  return (
    <NoteFeedContext.Provider
      value={{
        state,
        dispatch,
        updateNote,
        deleteNote,
        addNote,
        addReply,
        addTask,
        updateReaction,
        addUsers,
      }}
    >
      {/* Create Note Form */}
      {!hideCreateNoteForm && (
        <Card compact style={{ marginBottom: 8 }}>
          <Row align="top">
            <Col span={24}>
              <div style={{ opacity: isCreating ? 0.3 : 1 }}>
                <Row>
                  <Col span={24}>
                    {/* Editor for the new Note */}
                    <NoteEditor
                      onChange={(newValue: string) => setNewNoteBody(newValue)}
                      value={newNoteBody}
                      isViewMode={isCreating}
                      clear={clear}
                    />
                  </Col>
                  {/* Attachments for the new Note */}
                  <Col span={24}>
                    <NoteAttachments
                      onFileAdded={addFileToAttachments}
                      onFileRemoved={removeFileFromAttachments}
                      fileIds={attachedFileIds}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={12} style={{ marginTop: 16 }}>
              {/* Private Switch */}
              {isUserMemberOfInternalNoteGroup(userReducer) && (
                <Switch
                  label="Private Note"
                  onChange={() => setIsPrivateNote(!isPrivateNote)}
                  checked={isPrivateNote}
                  inline
                  style={{ margin: 0, paddingTop: 6 }}
                />
              )}
            </Col>
            <Col span={12} style={{ textAlign: 'right', marginTop: 15 }}>
              <Button
                text="Clear"
                disabled={!canFormBeCleared || isCreating}
                outlined
                style={{ marginRight: 10 }}
                onClick={initializeClear}
              />
              <Button
                intent="primary"
                text="Submit"
                disabled={isNoteFormEmpty || isCreating || !noteSchema}
                loading={isCreating}
                onClick={createNoteRequest}
              />
            </Col>
          </Row>
        </Card>
      )}

      {/* Note Feed */}
      <Row>
        {filteredNotes.length > 0 ? (
          filteredNotes.map((note: DbRecordEntityTransform, i: number) => (
            <Col span={24} key={i} style={{ marginTop: i > 0 ? 5 : 0 }}>
              <NoteBody
                key={i}
                sourceRecord={record}
                noteRecord={note}
                noteAttachments={note[FILE]?.dbRecords || []}
                noteTasks={note[SUPPORT_TASK]?.dbRecords || []}
                previewMode={previewMode}
              />
            </Col>
          ))
        ) : (
          <Col span={24}>
            <Empty description="No Notes" />
          </Col>
        )}
      </Row>
    </NoteFeedContext.Provider>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  createRecord: (params: ICreateRecords, cb: any) => dispatch(createRecordsRequest(params, cb)),
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(NoteFeed);
