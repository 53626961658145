import { Button, Drawer, Section } from '@blueprintjs/core';
import { isJsonValid } from '@core/modules/ControlPanelModule/components/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration/helpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { displayMessage } from '@redux/stores/messages/reducers';
import { ISchemaActionUpdate, updateSchemaAction } from '@redux/stores/schemas/actions';
import { Col, Row } from 'antd';
import { createContext, createRef, FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import './styles.scss';
import ViewConfigJSONDefinition from '@core/modules/ControlPanelModule/components/ViewConfigs/ViewConfigDefinitionEditor/ViewConfigJSONDefinition';
import { httpPut } from '@core/http/requests';

interface Props {
  schema: SchemaEntity | undefined;
  config: any;
  onClose?: () => void;
  onUpdate?: (config: any) => void;
  updateAction: (payload: any, cb: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

export const ViewConfigContext = createContext<{
  definition: any;
  parsedDefinition: any;
  schema: SchemaEntity | undefined;
  config: any;
  JSONInvalid: boolean;
  isSavingDefinition: boolean;
  setDefinition: (definition: any) => void;
  builderContainerRef: any;
}>({
  definition: undefined,
  parsedDefinition: undefined,
  schema: undefined,
  config: undefined,
  JSONInvalid: false,
  isSavingDefinition: false,
  setDefinition: () => {},
  builderContainerRef: undefined,
});

const ViewConfigDefinitionEditor: FC<Props> = (props: Props) => {
  const closeDrawer = () => {
    setDefinition(undefined);
    if (props.onClose) {
      props.onClose();
    }
  };

  const { schema, config, onUpdate, updateAction, alertMessage } = props;
  const [definition, setDefinition] = useState<string | undefined>(undefined);
  const [parsedDefinition, setParsedDefinition] = useState<Object | undefined>(undefined);
  const [isSavingDefinition, setIsSavingDefinition] = useState<boolean>(false);
  const [JSONInvalid, setJSONInvalid] = useState<boolean>(false);
  const [builderContainerRef, setBuilderContainerRef] = useState<any>(undefined);

  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    setBuilderContainerRef(ref);
  }, []);

  // Set definition when schema action is available
  useEffect(() => {
    if (config) {
      setDefinition(JSON.stringify(config.definition, null, '\t'));
    }
  }, [config]);

  const parseDefinition = async (definition: string) => {
    try {
      const parsed = JSON.parse(definition);
      setParsedDefinition(parsed);
    } catch (error) {
      setParsedDefinition(undefined);
    }
  };

  // Handle JSON validation on definition change
  useEffect(() => {
    if (definition && definition?.length! > 0 && isJsonValid(definition)) {
      setJSONInvalid(false);
      parseDefinition(definition);
    } else if (definition?.length !== 0 && !isJsonValid(definition)) {
      setJSONInvalid(true);
      setParsedDefinition(undefined);
    } else {
      setJSONInvalid(false);
    }
  }, [definition]);

  const saveDefinition = async () => {
    if (config && definition) {
      try {
        setIsSavingDefinition(true);

        const payload = {
          ...config,
          definition: JSON.parse(definition),
        };

        const res = await httpPut(`SchemaModule/v1.0/schemas-views/${config?.id}`, payload);
        alertMessage({ body: 'Definition Updated', type: 'success' });
        setIsSavingDefinition(false);
        onUpdate && onUpdate(res.data.data);
      } catch (err: any) {
        alertMessage({
          body: err.response ? err.response.data : 'Error updating View config',
          type: 'error',
        });
        setIsSavingDefinition(false);
      }
    }
  };

  return (
    <ViewConfigContext.Provider
      value={{
        definition,
        parsedDefinition,
        schema,
        config,
        JSONInvalid,
        setDefinition,
        isSavingDefinition,
        builderContainerRef,
      }}
    >
      <Drawer
        title={config?.name}
        isOpen={!!config}
        onClose={closeDrawer}
        size={isMobile ? '95%' : '90%'}
        canEscapeKeyClose={false}
        className="schemaActionConfigurationDrawerSection"
      >
        <Section
          style={{ height: 'calc(100vh - 40px)' }}
          rightElement={
            <Button
              intent={JSONInvalid ? 'danger' : 'primary'}
              icon="floppy-disk"
              disabled={JSONInvalid || isSavingDefinition}
              loading={isSavingDefinition}
              onClick={saveDefinition}
              text={JSONInvalid ? 'Invalid JSON' : 'Save'}
            />
          }
          title={
            <Row justify="center">
              <Col span={24} style={{ textAlign: 'center' }}></Col>
            </Row>
          }
        >
          <ViewConfigJSONDefinition />
        </Section>
      </Drawer>
    </ViewConfigContext.Provider>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  updateAction: (payload: ISchemaActionUpdate, cb: any) =>
    dispatch(updateSchemaAction(payload, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(ViewConfigDefinitionEditor);
