import React from 'react';
import { Col, Empty, Row } from 'antd';

import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

import RecordList from 'src/core/components/RecordList';

interface SearchResultsProps {
  records: DbRecordEntityTransform[];
  selectedRecords: DbRecordEntityTransform[];
  totalRecords: number;
  onRecordClicked: (record: DbRecordEntityTransform) => void;
}

export const SearchResults: React.FC<SearchResultsProps> = ({
  records = [],
  selectedRecords = [],
  totalRecords,
  onRecordClicked,
}: SearchResultsProps) => {
  return (
    <>
      <Row
        style={{
          height: '49vh',
          overflowY: 'scroll',
        }}
      >
        <Col span={24}>
          {records.length > 0 ? (
            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
              <RecordList
                selectedRecords={selectedRecords}
                disabledRecords={[]}
                allRecords={records}
                onRecordSelect={onRecordClicked}
                highlightSelectedRecord
                customRecordRowTitleElements={['type', 'title']}
              />
            </div>
          ) : (
            <Empty style={{ marginTop: '20vh' }} description="No associated records found" />
          )}
        </Col>
      </Row>

      {/* Search Results */}
      <Row
        style={{
          fontSize: '0.8em',
          padding: 3,
          marginTop: 5,
        }}
      >
        <Col span={12} style={{ textAlign: 'left', color: '#888888' }}>
          <span>Total Records: </span>
          <span>{totalRecords}</span>
        </Col>
      </Row>
    </>
  );
};
