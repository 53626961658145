import { Alert, Button, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { httpPost } from '@core/http/requests';
import { displayMessage } from '../../../../../redux/stores/messages/reducers';
interface Props {
  record: DbRecordEntityTransform;
  alertMessage: (params: { body: string; type: string }) => void;
}

const OrderEmailActionButton: React.FC<Props> = (props: Props) => {
  const { record, alertMessage } = props;

  const [orderConfirmationAlertOpen, setOrderConfirmationAlertOpen] = useState<boolean>(false);
  const [bookingConfirmationAlertOpen, setBookingConfirmationAlertOpen] = useState<boolean>(false);

  const sendOrderConfirmationEmail = () => {
    httpPost(
      `OrderModule/v1.0/orders/${record ? record.id : null}/email/SENDGRID_ORDER_CONFIRMATION_V2`,
      {},
    );
    setOrderConfirmationAlertOpen(false);
    alertMessage({
      body: 'Order confirmation Email sent successfully',
      type: 'success',
    });
  };

  const sendBookingConfirmationEmail = () => {
    httpPost(
      `OrderModule/v1.0/orders/${
        record ? record.id : null
      }/email/SENDGRID_INSTALL_SCHEDULING_REQUEST`,
      {},
    );
    setBookingConfirmationAlertOpen(false);
    alertMessage({
      body: 'Booking confirmation Email sent successfully',
      type: 'success',
    });
  };

  const menuItems = () => {
    return (
      <Menu>
        <MenuItem
          text="Send Order Confirmation"
          key="sendConfirmation"
          onClick={() => setOrderConfirmationAlertOpen(true)}
        />
        <MenuItem
          text="Send Install Booking Request"
          key="sendInstallRequest"
          onClick={() => setBookingConfirmationAlertOpen(true)}
        />
      </Menu>
    );
  };

  return (
    <>
      {/* Send Confirmation Alert dialog */}
      <Alert
        isOpen={orderConfirmationAlertOpen}
        onConfirm={sendOrderConfirmationEmail}
        onCancel={() => setOrderConfirmationAlertOpen(false)}
        cancelButtonText="Cancel"
        intent="primary"
        confirmButtonText="Send"
        canEscapeKeyCancel
      >
        <Row>
          <Col span={24}>
            <Typography.Text strong>Send Order confirmation Email</Typography.Text>
          </Col>
          <Col span={24} style={{ marginTop: 15 }}>
            <span>Do you want to send the Order confirmation email to the customer?</span>
          </Col>
        </Row>
      </Alert>

      {/* Install booking request Alert dialog */}
      <Alert
        isOpen={bookingConfirmationAlertOpen}
        onConfirm={sendBookingConfirmationEmail}
        onCancel={() => setBookingConfirmationAlertOpen(false)}
        cancelButtonText="Cancel"
        intent="primary"
        confirmButtonText="Send"
        canEscapeKeyCancel
      >
        <Row>
          <Col span={24}>
            <Typography.Text strong>Send Install Booking Request</Typography.Text>
          </Col>
          <Col span={24} style={{ marginTop: 15 }}>
            <span>Do you want to send the install booking request Email to the customer?</span>
          </Col>
        </Row>
      </Alert>

      {/* Button Action Menu */}
      <Popover content={menuItems()} placement="bottom">
        <Button alignText="left" rightIcon="envelope" intent="primary" outlined />
      </Popover>
    </>
  );
};

const mapState = (state: any) => ({});
const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(OrderEmailActionButton);
