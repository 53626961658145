import React, { PropsWithChildren, Ref } from 'react';
import ReactDOM from 'react-dom';

interface BaseProps {
  className: string;
  [key: string]: unknown;
}

export const Button = React.forwardRef(
  (
    {
      className,
      active,
      reversed,
      ...props
    }: PropsWithChildren<
      {
        active: boolean;
        reversed: boolean;
      } & BaseProps
    >,
    ref: Ref<HTMLSpanElement> | undefined,
  ) => (
    <span
      {...props}
      ref={ref}
      className={className}
      style={{
        cursor: 'pointer',
        color: reversed ? (active ? 'white' : '#aaa') : active ? 'black' : '#ccc',
      }}
    />
  ),
);

export const Icon = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<HTMLSpanElement> | undefined,
  ) => (
    <span
      {...props}
      ref={ref}
      className={`material-icons ${className}`}
      style={{
        fontSize: '18px',
        verticalAlign: 'text-bottom',
        marginLeft: '15px',
      }}
    />
  ),
);

export const Menu = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<HTMLDivElement> | undefined) => (
    <div {...props} ref={ref} className={className} />
  ),
);

export const Portal = ({ children }: any) => {
  return typeof document === 'object' ? ReactDOM.createPortal(children, document.body) : null;
};

export const Toolbar = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<HTMLDivElement> | undefined) => (
    <Menu
      {...props}
      ref={ref}
      style={{
        position: 'relative',
        padding: '1px 0px 10px',
        margin: '0 -10px',
        borderBottom: '2px solid #eee',
        marginBottom: '20px',
      }}
      className={className}
    />
  ),
);
