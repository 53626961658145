import { Button, FormGroup, InputGroup, MenuItem, Section, Switch } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { SchemaActionDefinitionContext } from '@core/modules/ControlPanelModule/components/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration';
import FlowBuilderDialogStep from '@core/modules/ControlPanelModule/components/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration/SchemaActionBuilder/FlowBuilder/FlowBuilderDialogStep';
import FlowBuilderPropertyFilter from '@core/modules/ControlPanelModule/components/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration/SchemaActionBuilder/FlowBuilder/FlowBuilderPropertyFilter';
import { Col, Row } from 'antd';
import React, { useContext } from 'react';

interface Props {}

const FlowBuilder: React.FC<Props> = (props: Props) => {
  const {} = props;
  const { schemaAction, builderContainerRef, parsedDefinition, setDefinition, schema } = useContext(
    SchemaActionDefinitionContext,
  );

  const actionLabel = parsedDefinition?.settings?.actionLabel || '';
  const summaryStep = parsedDefinition?.settings?.showSummaryStep || false;
  const onSubmitURL = parsedDefinition?.onSubmitUrl?.url || '';
  const onSubmitURLMethod = parsedDefinition?.onSubmitUrl?.method || 'POST';
  const dialogSteps = parsedDefinition?.dialogSteps || [];
  const propertyFilters = parsedDefinition?.propertyFilters || [];

  const onSettingsChange = (key: string, value: any) => {
    const newDefinition = {
      ...parsedDefinition,
      settings: {
        ...parsedDefinition.settings,
        [key]: value,
      },
    };
    setDefinition(JSON.stringify(newDefinition, null, 4));
  };

  const addDialogStep = () => {
    const newDialogSteps = [
      ...dialogSteps,
      {
        name: '',
        linkType: '',
        description: '',
        schemaActions: [],
      },
    ];
    const newDefinition = {
      ...parsedDefinition,
      dialogSteps: newDialogSteps,
    };
    setDefinition(JSON.stringify(newDefinition, null, 4));
    // Scroll builderRef div to bottom smoothly

    // After 200 ms, scroll to bottom
    setTimeout(() => {
      builderContainerRef.current.scrollTo({
        top: builderContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }, 100);
  };

  const addNewPropertyFilter = () => {
    // Add new property filter to propertyFilters and save definition
    const newPropertyFilter = {
      property: '',
      operator: 'IN',
      values: [],
    };
    const newPropertyFilters = [...propertyFilters, newPropertyFilter];
    const newDefinition = {
      ...parsedDefinition,
      propertyFilters: newPropertyFilters,
    };
    setDefinition(JSON.stringify(newDefinition, null, 4));
  };

  return (
    <Row>
      <Col span={24}>
        <Section
          compact
          title={schemaAction?.name || 'Flow Builder'}
          icon="flow-linear"
          style={{ borderRadius: 8 }}
        >
          <div style={{ padding: 12 }}>
            <Row style={{ padding: '0 12px' }} gutter={16} justify="space-between">
              {/* Action Label */}
              <Col span={18}>
                <FormGroup label="Action Label" labelFor="action-label">
                  <InputGroup
                    id="action-label"
                    autoComplete="off"
                    placeholder="Action Label"
                    defaultValue={actionLabel}
                    onChange={(e) => onSettingsChange('actionLabel', e.target.value)}
                  />
                </FormGroup>
              </Col>

              {/* Show Summary Step */}
              <Col span={6} style={{ paddingLeft: 10 }}>
                <FormGroup label="Show Summary Step" labelFor="action-label">
                  <Switch
                    large
                    checked={summaryStep}
                    onChange={(e: any) => {
                      if (parsedDefinition.settings.showSummaryStep) {
                        onSettingsChange('showSummaryStep', false);
                      } else {
                        onSettingsChange('showSummaryStep', true);
                      }
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ padding: '0 12px' }}>
              {/* On Submit URL */}
              <Col span={18}>
                <FormGroup label="On Submit URL" labelFor="custom-url">
                  <InputGroup
                    id="custom-url"
                    placeholder="URL"
                    autoComplete="off"
                    defaultValue={onSubmitURL}
                    onChange={(e) => {
                      const newDefinition = {
                        ...parsedDefinition,
                        onSubmitUrl: {
                          url: e.target.value,
                          method: onSubmitURLMethod || 'post',
                        },
                      };
                      setDefinition(JSON.stringify(newDefinition, null, 4));
                    }}
                  />
                </FormGroup>
              </Col>

              <Col span={6} style={{ paddingLeft: 10 }}>
                <Select<string>
                  fill
                  placeholder="URL"
                  items={['get', 'post', 'put', 'delete']}
                  filterable={false}
                  itemRenderer={(item, { handleClick }) => (
                    <MenuItem
                      key={item}
                      text={item.toUpperCase()}
                      onClick={handleClick}
                      active={item === onSubmitURLMethod}
                    />
                  )}
                  onItemSelect={(item) => {
                    const newDefinition = {
                      ...parsedDefinition,
                      onSubmitUrl: {
                        url: onSubmitURL,
                        method: item,
                      },
                    };
                    setDefinition(JSON.stringify(newDefinition, null, 4));
                  }}
                >
                  <Button
                    disabled={!onSubmitURL.length}
                    text={onSubmitURLMethod?.toUpperCase()}
                    rightIcon="caret-down"
                    alignText="left"
                    style={{ marginTop: 22 }}
                    fill
                  />
                </Select>
              </Col>
            </Row>

            {/* Property Filters */}
            <Row style={{ padding: '0 12px', marginTop: 10 }}>
              {propertyFilters?.length > 0 && (
                <Col span={24} style={{ marginBottom: 10 }}>
                  <span style={{ fontWeight: 600 }}>Property Filters</span>
                </Col>
              )}

              {propertyFilters?.map((propertyFilter: any, index: number) => (
                <Col span={24} key={`propertyFilter` + index}>
                  <FlowBuilderPropertyFilter propertyFilter={propertyFilter} index={index} />
                </Col>
              ))}

              <Col span={24} style={{ textAlign: 'center', marginTop: 10 }}>
                <Button
                  disabled={propertyFilters?.some(
                    (filter: any) => filter?.values?.length! === 0 || filter?.property === '',
                  )}
                  intent="success"
                  minimal
                  text="Add Property Filter"
                  icon="plus"
                  onClick={addNewPropertyFilter}
                />
              </Col>
            </Row>
          </div>
        </Section>

        {/* Dialog Steps */}
        {dialogSteps.map((dialogStep: any, index: number) => (
          <>
            <FlowBuilderDialogStep
              dialogStep={dialogStep}
              stepNumber={index + 1}
              key={`dialogStep-` + index}
            />
          </>
        ))}

        {/* Add Dialog Step */}
        <Row
          justify="center"
          style={{ padding: 15, border: '1px dashed #bfbfbf', marginTop: 20, borderRadius: 8 }}
        >
          <Col>
            <Button large text="Add Step" icon="plus" intent="success" onClick={addDialogStep} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default FlowBuilder;
