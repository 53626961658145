import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import RecordMainContentWide from '@netomnia/modules/ProjectModule/components/DetailViewWide/RecordMainContentWide';
import { getRecordByIdRequest, IGetRecordById } from '../../../../redux/stores/records/actions';
import { IRecordReducer } from '../../../../redux/stores/records/reducer';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../redux/stores/schemas/actions';
import { ISchemaReducer } from '../../../../redux/stores/schemas/reducer';
import {
  getAllSchemaAssociationSchemas,
  getRecordFromShortListById,
} from '../../../../core/helpers/recordHelpers';
import {
  getSchemaFromShortListByModuleAndEntity,
  getSchemaFromShortListBySchemaId,
} from '../../../../core/helpers/schemaHelpers';
import CableDiagram from '../components/CableDiagram';
import MapViewer from '../components/MapViewer';

const { PROJECT_MODULE } = SchemaModuleTypeEnums;
const { NOTE } = SchemaModuleEntityTypeEnums;

type PathParams = {
  url: string;
  recordId: string;
};

type State = {
  detailsColumnExpanded: boolean;
};

type PropsType = RouteComponentProps<PathParams> & {
  match?: any;
  recordId?: string;
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  disableBreadcrumbs?: boolean;
  disableClone?: boolean;
  disableEdit?: boolean;
  disableDelete?: boolean;
  excludeRelations?: string[];
  defaultTabKey?: string;
  getRecord: any;
  entityName?: string;
  moduleName: SchemaModuleTypeEnums;
  getSchema: Function;
  quickView?: boolean;
};

class ProjectModuleRecordDetailViewWide extends React.Component<PropsType, State> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      detailsColumnExpanded: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(
    prevProps: Readonly<PropsType>,
    prevState: Readonly<State>,
    snapshot?: any,
  ): void {
    const { recordId } = this.props;
    const { recordId: prevRecordId } = prevProps;
    if (recordId && recordId !== prevRecordId) this.fetchData();
  }

  fetchData() {
    const { getRecord, schemaReducer, recordId, match, entityName, moduleName, getSchema } =
      this.props;

    if (moduleName && entityName) {
      const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        moduleName,
        entityName,
      );

      if (shortlistSchema) {
        getRecord({
          schema: shortlistSchema,
          recordId: recordId || match?.params?.recordId,
        });
      } else {
        getSchema({ moduleName, entityName }, (response: SchemaEntity) => {
          getRecord({
            schema: response,
            recordId: recordId || match?.params?.recordId,
          });
        });
      }
    }
  }

  toggleColumnState = () => {
    this.setState({ detailsColumnExpanded: !this.state.detailsColumnExpanded });
  };

  render() {
    const {
      recordId,
      schemaReducer,
      recordReducer,
      match,
      excludeRelations,
      disableClone,
      disableEdit,
      disableDelete,
      defaultTabKey,
    } = this.props;

    const record = getRecordFromShortListById(
      recordReducer.shortList,
      recordId || match?.params?.recordId,
    );
    const externalRef = getProperty(record, 'ExternalRef');

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);
    const relatedSchemas = getAllSchemaAssociationSchemas(
      schema?.associations,
      excludeRelations ? [NOTE, ...excludeRelations] : [NOTE],
    );

    /* If you want Cable Diagram and Map Viewer on other Entities, just condition them against recordType */
    const addCableDiagramAndMapViewerTabs = () => {
      if (externalRef && record?.type) {
        if (record?.type === 'POLYGON') {
          return {
            tabs: [
              {
                key: 'CableDiagram',
                tab: 'Cable Diagram',
              },
              {
                key: 'MapViewer',
                tab: 'Map Viewer',
              },
            ],
            content: {
              CableDiagram: (
                <CableDiagram
                  recordId={record?.id}
                  polygonId={Number(externalRef)}
                  columnFullscreen={this.state.detailsColumnExpanded}
                />
              ),
              MapViewer: (
                <MapViewer
                  polygonId={Number(externalRef)}
                  columnFullscreen={this.state.detailsColumnExpanded}
                />
              ),
            },
          };
        } else {
          return { tabs: [], content: {} };
        }
      } else {
        return { tabs: [], content: {} };
      }
    };

    return !record ? (
      <Spin />
    ) : (
      <RecordMainContentWide
        quickView={this.props.quickView}
        disableBreadcrumbs={this.props.disableBreadcrumbs}
        disableClone={disableClone}
        disableEdit={disableEdit}
        disableDelete={disableDelete}
        defaultTabKey={defaultTabKey}
        record={record}
        schema={schema}
        relatedSchemas={relatedSchemas}
        toggleColumnState={this.toggleColumnState}
        detailsColumnExpanded={this.state.detailsColumnExpanded}
        customTabs={addCableDiagramAndMapViewerTabs().tabs}
        customContent={addCableDiagramAndMapViewerTabs().content}
      />
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
});

const mapDispatch = (dispatch: any) => ({
  getRecord: (payload: IGetRecordById, cb: () => {}) => dispatch(getRecordByIdRequest(payload, cb)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

export default withRouter(connect(mapState, mapDispatch)(ProjectModuleRecordDetailViewWide));
