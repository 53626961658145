import { SearchOutlined } from '@ant-design/icons';
import { Button, Drawer, FormGroup, InputGroup, Tooltip } from '@blueprintjs/core';
import {
  getRecordListFromShortListById,
  splitModuleAndEntityName,
} from '@core/helpers/recordHelpers';
import { getDefaultFields } from '@core/helpers/searchHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { QueryBuilderReducer } from '@core/components/DynamicTable/QueryBuilder/store/reducer';
import { TableReducer } from '@redux/stores/table/reducer';
import {
  ISearchRecords,
  resetRecordsList,
  searchRecordsDebounced,
  searchRecordsRequest,
  setDbRecordSearchQuery,
  toggleSearchVisibility,
} from '@redux/stores/records/actions';
import { IRecordReducer } from '@redux/stores/records/reducer';
import { ISchemaReducer } from '@redux/stores/schemas/reducer';
import { Col, Row, Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import './index.scss';
import SearchResult from './SearchResult';

interface Props {
  entities: string[];
  navigationReducer: any;
  onNumberClick?: Function;
  queryBuilderReducer: QueryBuilderReducer;
  recordReducer: IRecordReducer;
  recordTableReducer: TableReducer;
  renderStyle: string;
  resetRecordState: any;
  schema: any;
  schemaReducer: ISchemaReducer;
  searchRecords: any;
  searchRecordsDebounced: Function;
  setSearchQuery: any;
  toggleSearchVisibility: any;
}

interface State {
  collapsed: boolean;
  schemaList: SchemaEntity[];
}

class PowerSearch extends React.Component<Props, State> {
  private inputRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      collapsed: false,
      schemaList: [],
    };
  }

  componentDidMount() {
    this.fetchSchemasForTheRoutingStructure();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    // Schema reducer became available after the component mount, fetch schemas.
    if (
      prevProps.schemaReducer.list !== this.props.schemaReducer.list &&
      this.state.schemaList.length === 0
    ) {
      this.fetchSchemasForTheRoutingStructure();
    }
  }

  fetchSchemasForTheRoutingStructure() {
    const { schemaReducer, entities } = this.props;

    // Go over each passed search moduleName:entityName string and fetch schema from the schema list
    if (entities?.length > 0 && schemaReducer.list?.length > 0) {
      entities.map((moduleEntity: string) => {
        const { moduleName, entityName } = splitModuleAndEntityName(moduleEntity);

        const foundSchema = schemaReducer.list?.find(
          (schema: SchemaEntity) =>
            schema.moduleName === moduleName && schema.entityName === entityName,
        );
        if (foundSchema) {
          let schemaList = this.state.schemaList;
          schemaList.push(foundSchema);
          this.setState({ schemaList: schemaList });
        }
      });
    }
  }

  searchRecordOnChange(e: any) {
    const { schema, searchRecordsDebounced } = this.props;

    let schemaIds;

    for (const schema of this.state.schemaList) {
      if (schema && schema.id && schemaIds) {
        schemaIds = schemaIds.concat(`,${schema.id}`);
      } else if (schema) {
        schemaIds = schema.id;
      }
    }

    if (schemaIds) {
      searchRecordsDebounced({
        schema: schema,
        searchQuery: {
          terms: e,
          fields: getDefaultFields(schema, schema.moduleName, schema.entityName),
          schemas: schemaIds,
          sort: [{ schemaPosition: { order: 'desc' } }],
        },
      });
    }
  }

  renderResults() {
    const { recordReducer, schema } = this.props;
    let searchResults: any[] = [];

    if (recordReducer.list && schema && schema.id) {
      const list = getRecordListFromShortListById(recordReducer.list, schema.id);

      if (list) {
        searchResults.push(...list);
      }
    }

    if (recordReducer.isSearching) {
      return (
        <Col span={24}>
          <Row
            style={{
              color: '#afafaf',
              padding: '25px',
              textAlign: 'center',
            }}
          >
            <Col span={24}>
              <Spin tip="Searching..." size="large" />
            </Col>
          </Row>
        </Col>
      );
    }

    if (searchResults && searchResults.length > 0) {
      searchResults = searchResults.map((result: any) => (
        <Col span={24} style={{ padding: '8px 1px' }}>
          <SearchResult
            key={result.id}
            isLoading={false}
            entityName={result.entity.split(':')[1]}
            searchResult={result}
            onClose={this.onClose}
            globalCollapsed={this.state.collapsed}
            onNumberClick={this.props.onNumberClick}
          />
        </Col>
      ));
    } else {
      return (
        <Col
          span={24}
          style={{
            color: '#afafaf',
            padding: '45px 0',
            textAlign: 'center',
          }}
        >
          <Row>
            <Col span={24}>
              <SearchOutlined style={{ fontSize: '2em', marginBottom: '25px' }} />
            </Col>
            <Col span={24}>
              <span style={{ fontSize: 14 }}>Your results will appear here as you type</span>
            </Col>
          </Row>
        </Col>
      );
    }

    return searchResults;
  }

  setFocus = (isOpen: any) => {
    isOpen && this.inputRef.current!.focus();
  };

  onClose = () => {
    if (this.props.renderStyle === 'drawer') this.props.toggleSearchVisibility();
    this.setState({ collapsed: false });
  };

  clearSearchResults = () => {
    this.props.resetRecordState();

    if (this.inputRef?.current) {
      this.inputRef.current.setValue('');
      this.inputRef.current.focus();
    }
  };

  toggleGlobalCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  renderSearchBody(searchContainer: string) {
    const { recordReducer } = this.props;

    // const checkIfSearchResultsPresent = () => {
    //   if (searchContainer === 'card') return !!recordReducer.list.GLOBAL_SEARCH_CARD;
    //   else if (searchContainer === 'drawer') return !!recordReducer.list.GLOBAL_SEARCH_DRAWER;
    //   else return false;
    // };

    return (
      <>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Row gutter={8}>
              <Col span={22}>
                <FormGroup labelFor="text-input" labelInfo="(required)">
                  <InputGroup
                    type="search"
                    id="text-input"
                    placeholder="Search"
                    leftIcon="search"
                    inputRef={this.inputRef}
                    disabled={!this.state.schemaList.length}
                    onChange={(e: any) => this.searchRecordOnChange(e.target.value)}
                    style={{ width: '100%' }}
                    autoFocus
                  />
                </FormGroup>
              </Col>
              {/* Clear Results */}
              <Col span={2} style={{ textAlign: 'right' }}>
                <Tooltip content="Clear Results" hoverOpenDelay={800}>
                  <Button
                    disabled={recordReducer.isSearching || this.state.schemaList.length === 0}
                    icon="trash"
                    onClick={() => {
                      this.clearSearchResults();
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row
          style={{
            overflowY: 'auto',
            height: this.props.renderStyle === 'drawer' ? 'calc(100vh - 120px)' : 'auto',
          }}
        >
          <Col span={24}>
            <Row style={{ marginTop: '5px' }}>{this.renderResults()}</Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { recordReducer, renderStyle } = this.props;

    /* Use in antd Drawer */
    if (renderStyle === 'drawer') {
      return (
        <Drawer
          title="Search"
          onClose={this.onClose}
          isOpen={recordReducer.isSearchVisible}
          style={{ width: 500 }}
          onOpened={this.setFocus}
          // afterOpenChange={this.setFocus}
        >
          <div style={{ height: '100%', padding: 15 }}>{this.renderSearchBody('drawer')}</div>
        </Drawer>
      );
    } else {
      /* Use in antd Card */
      return this.renderSearchBody('card');
    }
  }
}

const mapState = (state: any) => ({
  recordTableReducer: state.recordTableReducer,
  recordAssociationReducer: state.recordAssociationReducer,
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
  queryBuilderReducer: state.queryBuilderReducer,
  navigationReducer: state.navigationReducer,
});

const mapDispatch = (dispatch: any) => ({
  setSearchQuery: (params: ISearchRecords) => dispatch(setDbRecordSearchQuery(params)),
  searchRecords: (params: ISearchRecords) => dispatch(searchRecordsRequest(params)),
  searchRecordsDebounced: (params: ISearchRecords, cb: any) =>
    dispatch(searchRecordsDebounced(params, cb)),
  resetRecordState: () => dispatch(resetRecordsList()),
  toggleSearchVisibility: () => dispatch(toggleSearchVisibility()),
});

export default connect(mapState, mapDispatch)(PowerSearch);
