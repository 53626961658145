import { SchemaColumnOptionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/option/schema.column.option.entity';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { TableHeaderColumn } from '@core/components/DynamicTable/helpers/configureColumns';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import {
  getSchemaByIdRequest,
  getSchemaByModuleAndEntityRequest,
} from '@redux/stores/schemas/actions';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getModuleAndEntityNameFromRecord } from './recordHelpers';
import { store } from '@redux/configureStore';

// Check if schema has type with name
export const schemaHasType = (schema: SchemaEntity, typeName: string) => {
  if (schema && schema.types?.length! > 0 && typeName) {
    return schema.types?.find((schemaType: SchemaTypeEntity) => schemaType.name === typeName);
  } else {
    return false;
  }
};

export const getSchemaFromShortListByModuleAndEntity = (
  shortList: { [key: string]: SchemaEntity },
  moduleName: string | undefined,
  entityName: string | undefined,
): SchemaEntity | undefined => {
  if (!shortList) return;
  const keys = Object.keys(shortList);
  for (const key of keys) {
    const schema = shortList[key];
    if (schema?.moduleName === moduleName && schema?.entityName === entityName) {
      return schema;
    }
  }
};

// Get schema by schema ID
//
// This method should return shortlist schema if found, otherwise it should return
// schema from API. If schema is found in shortlist, fetch the schema from API anyway,
// to update the shortlist in the schema reducer.
export const getOdinSchemaById = async (schemaId: string): Promise<SchemaEntity> => {
  return new Promise((resolve, reject) => {
    store.dispatch(
      getSchemaByIdRequest({ schemaId: schemaId }, (response: SchemaEntity) => {
        if (response) {
          resolve(response);
        } else {
          reject('Schema not found');
        }
      }),
    );
  });
};

// Get schema by record
//
// This method should return shortlist schema if found, otherwise it should return
// schema from API. If schema is found in shortlist, fetch the schema from API anyway,
// to update the shortlist in the schema reducer.
export const getOdinSchemaByRecord = async (
  record: DbRecordEntityTransform,
): Promise<SchemaEntity> => {
  return new Promise((resolve, reject) => {
    const { moduleName, entityName } = getModuleAndEntityNameFromRecord(record);

    store.dispatch(
      getSchemaByModuleAndEntityRequest(
        { moduleName: moduleName, entityName: entityName },
        (response: SchemaEntity) => {
          if (response) {
            resolve(response);
          } else {
            reject('Schema not found');
          }
        },
      ),
    );
  });
};

// Get schema by module and entity name
//
// This method should return shortlist schema if found, otherwise it should return
// schema from API. If schema is found in shortlist, fetch the schema from API anyway,
// to update the shortlist in the schema reducer.
export const getOdinSchemaByEntity = async (
  moduleName: string,
  entityName: string,
): Promise<SchemaEntity> => {
  return new Promise((resolve, reject) => {
    store.dispatch(
      getSchemaByModuleAndEntityRequest(
        { moduleName: moduleName, entityName: entityName },
        (response: SchemaEntity) => {
          if (response) {
            resolve(response);
          } else {
            reject('Schema not found');
          }
        },
      ),
    );
  });
};

/**
 * Very lousy attempt that should be deprecated
 * Check for schema in shortlist or return schema object from API
 * @param shortList
 * @param moduleName
 * @param entityName
 */
export const getSchemaFromShortListByModuleAndEntityOrAPI = async (
  shortList: { [key: string]: SchemaEntity },
  moduleName: string,
  entityName: string,
  getSchema: Function,
): Promise<SchemaEntity | undefined> => {
  const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
    shortList,
    moduleName,
    entityName,
  );

  if (shortlistSchema) {
    return shortlistSchema;
  } else {
    getSchema(
      { moduleName: moduleName, entityName: entityName },
      (responseSchema: SchemaEntity) => {
        if (responseSchema) {
          return responseSchema;
        }
      },
    );
  }
};

/**
 * Get all JSON columns from schema
 * @param schema
 */
export const getJSONColumnsFromSchema = (
  schema: SchemaEntity | undefined,
): SchemaColumnEntity[] => {
  if (schema && schema.columns?.length > 0) {
    return schema.columns?.filter((column: SchemaColumnEntity) => column.type === 'JSON');
  } else {
    return [];
  }
};

export const getSchemaFromShortListBySchemaId = (
  shortList: { [key: string]: SchemaEntity },
  schemaId: string | null | undefined,
): SchemaEntity | undefined => {
  return schemaId ? shortList?.[schemaId] : undefined;
};

/**
 *  formats schema columns into elastic search index mappings
 * @param schema
 */
export const getElasticSearchKeysFromSchemaColumn = (
  schema: SchemaEntity,
  schemaTypeId?: string,
): TableHeaderColumn[] => {
  return schema?.columns
    ?.filter(
      (col) => col.isVisibleInTables && (col.schemaTypeId === schemaTypeId || !col.schemaTypeId),
    )
    .map((col) => ({
      title: col.label ? col.label : '',
      dataIndex: `properties.${col.name}`,
      columnType: col.type,
      position: col.position,
      isTitleColumn: col.isTitleColumn ? col.isTitleColumn : false,
    }));
};

/**
 * Sort schema column options
 *
 * Supports: strings and numbers
 * @param a
 * @param b
 */
export const sortOptions = (a: SchemaColumnOptionEntity, b: SchemaColumnOptionEntity) => {
  if (!isNaN(Number(a.value))) {
    return Number(a.value) - Number(b.value);
  } else {
    return a.value.localeCompare(b.value);
  }
};

/**
 * Sort schema column / schema column options by position parameter
 *
 * Supports: numbers
 * @param a
 * @param b
 */
export const sortByPosition = (
  a: SchemaColumnOptionEntity | SchemaColumnEntity,
  b: SchemaColumnOptionEntity | SchemaColumnEntity,
) => {
  return Number(a.position) - Number(b.position);
};

export const sortByName = (a: SchemaColumnOptionEntity, b: SchemaColumnOptionEntity) => {
  return Number(a.label) - Number(b.label);
};

export const entityNamesByModules: [string, string][] = [];
