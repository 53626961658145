import { Button, Drawer, Section } from '@blueprintjs/core';
import { isJsonValid } from '@core/modules/ControlPanelModule/components/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration/helpers';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { displayMessage } from '@redux/stores/messages/reducers';
import { IUpdateRecordById, updateRecordByIdRequest } from '@redux/stores/records/actions';
import { Editor } from '@monaco-editor/react';
import { FC, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import './styles.scss';

interface Props {
  schema: SchemaEntity | undefined;
  config: DbRecordEntityTransform | undefined;
  onClose?: () => void;
  onUpdate?: (config: DbRecordEntityTransform) => void;
  alertMessage: (params: { body: string; type: string }) => void;
  updateRecord: (params: IUpdateRecordById, cb: any) => void;
}

const SchemaActionConfiguration: FC<Props> = (props: Props) => {
  const closeDrawer = () => {
    setDefinition(undefined);
    if (props.onClose) {
      props.onClose();
    }
  };

  const { schema, config, onUpdate, alertMessage, updateRecord } = props;

  const [definition, setDefinition] = useState<string | undefined>(undefined);
  const [isSavingDefinition, setIsSavingDefinition] = useState<boolean>(false);
  const [JSONInvalid, setJSONInvalid] = useState<boolean>(false);

  // Set definition when schema action is available
  useEffect(() => {
    if (config) {
      setDefinition(JSON.stringify(getProperty(config, 'Mappings'), null, 2));
    }
  }, [config]);

  // Handle JSON validation on definition change
  useEffect(() => {
    if (definition && definition?.length! > 0 && isJsonValid(definition)) {
      setJSONInvalid(false);
    } else if (definition?.length !== 0 && !isJsonValid(definition)) {
      setJSONInvalid(true);
    } else {
      setJSONInvalid(false);
    }
  }, [definition]);

  const saveDefinition = () => {
    if (config && definition && schema) {
      setIsSavingDefinition(true);
      updateRecord(
        {
          schema: schema,
          recordId: config.id,
          createUpdate: {
            schemaId: schema?.id,
            properties: {
              Mappings: JSON.parse(definition),
            },
          },
        },
        (res: any) => {
          setIsSavingDefinition(false);
          onUpdate && onUpdate(res);
        },
      );
    }
  };

  // Set up editor
  const editorRef = useRef(null);
  const handleEditorDidMount = (editor: any, monaco: any) => {
    editorRef.current = editor;
  };
  function setJSONEditorTheme(monaco: any) {
    monaco.editor.defineTheme('odinstyle', {
      base: 'vs-dark',
      inherit: true,
      rules: [
        {
          token: 'comment',
          foreground: '#5d7988',
          fontStyle: 'italic',
        },
      ],
      colors: {},
    });
  }

  return (
    <Drawer
      title="JSON Editor"
      isOpen={!!config}
      onClose={closeDrawer}
      size={isMobile ? '95%' : '50%'}
      canEscapeKeyClose={false}
      className="schemaActionConfigurationDrawerSection"
    >
      <Section
        style={{ height: 'calc(100vh - 40px)' }}
        title={' '}
        rightElement={
          <>
            <Button
              intent={JSONInvalid ? 'danger' : 'primary'}
              icon="floppy-disk"
              disabled={JSONInvalid || isSavingDefinition}
              loading={isSavingDefinition}
              onClick={saveDefinition}
              text={JSONInvalid ? 'Invalid JSON' : 'Save'}
            />
          </>
        }
      >
        <Editor
          height="calc(100vh - 90px)"
          theme="odinstyle"
          width="100%"
          defaultLanguage="json"
          value={definition}
          defaultValue={definition}
          onMount={handleEditorDidMount}
          beforeMount={setJSONEditorTheme}
          onChange={(value: any) => setDefinition(value)}
          options={{
            wordWrap: 'on',
            minimap: { enabled: false },
          }}
        />
      </Section>
    </Drawer>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  updateRecord: (params: IUpdateRecordById, cb: any) =>
    dispatch(updateRecordByIdRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(SchemaActionConfiguration);
