import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Col, Row } from 'antd';
import { FC } from 'react';
import RecordCard from '@core/components/RecordCard';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import CollaboratorsCard from 'src/core/components/CollaboratorsSection';
import ActivitiesAndNotes from '@core/components/ActivitiesAndNotes';

interface IProps {
  selectedRecord: DbRecordEntityTransform;
  schema: SchemaEntity;
}

const { CRM_MODULE, PRODUCT_MODULE } = SchemaModuleTypeEnums;
const { CONTACT, PRODUCT, OFFER, ADDRESS } = SchemaModuleEntityTypeEnums;

export const DataSetLeadDetailView: FC<IProps> = (props) => {
  const { selectedRecord, schema } = props;

  return (
    <Row gutter={20}>
      <Col span={14}>
        <RecordCard
          showActionMenu
          showItemActionMenu
          record={selectedRecord}
          displayQuickView
          associatedRecordModuleName={CRM_MODULE}
          associatedRecordEntityName={CONTACT}
          visibleProperties={['EmailAddress', 'Phone', 'Mobile']}
        />

        <ActivitiesAndNotes record={selectedRecord} schema={schema!} maxHeightPx={700} />

        {/* Collaborators */}
        <div style={{ marginTop: 14 }}>
          <CollaboratorsCard record={selectedRecord} />
        </div>
      </Col>
      <Col span={10}>
        <RecordCard
          showWithoutPagination
          showActionMenu
          showItemActionMenu
          customTitle={<span>Address</span>}
          record={selectedRecord}
          displayQuickView
          associatedRecordModuleName={CRM_MODULE}
          associatedRecordEntityName={ADDRESS}
          visibleProperties={['ConnectionType']}
        />
        <RecordCard
          showActionMenu
          showItemActionMenu
          customTitle={<span>Appointment</span>}
          record={selectedRecord}
          displayQuickView
          associatedRecordModuleName={CRM_MODULE}
          associatedRecordEntityName={'Appointment'}
          visibleProperties={['Date']}
        />
        <RecordCard
          showWithoutPagination
          showActionMenu
          showItemActionMenu
          customTitle={<span>Products</span>}
          record={selectedRecord}
          displayQuickView
          associatedRecordModuleName={PRODUCT_MODULE}
          associatedRecordEntityName={PRODUCT}
          visibleProperties={['Category', 'ContractType', 'CustomerType', 'Type', 'UnitPrice']}
        />
        <RecordCard
          showWithoutPagination
          showActionMenu
          showItemActionMenu
          record={selectedRecord!}
          displayQuickView
          associatedRecordModuleName={PRODUCT_MODULE}
          associatedRecordEntityName={OFFER}
          visibleProperties={[
            'AvailableFrom',
            'AvailableTo',
            'ContractType',
            'CustomerType',
            'IsDefault',
          ]}
        />
        <RecordCard
          record={selectedRecord!}
          showActionMenu
          showItemActionMenu
          displayQuickView
          associatedRecordEntityName={'Discount'}
          associatedRecordModuleName={'ProductModule'}
          visibleProperties={['DiscountLength', 'DiscountType', 'DiscountValue', 'Description']}
        />
      </Col>
    </Row>
  );
};
