import { ReloadOutlined, SearchOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Modal, Result, Row, Spin } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { updateAutosplicingReducerState } from '../../store/actions';
import {
  GraphEntity,
  IAutosplicingReducer,
  TUpdateAutosplicingReducerAction,
} from '../../store/types';
import { httpPost } from '@core/http/requests';
import '../../styles.scss';
import AutosplicingJobListener from '../../listeners/AutosplicingJobListener';

interface OwnProps {
  fetchSplicingTemplates: Function;
  getTemplateDifference: Function;
  updateAutosplicingReducer: TUpdateAutosplicingReducerAction;
  autosplicingReducer: IAutosplicingReducer;
}

type Props = OwnProps;

const AutosplicingCableConnection: FunctionComponent<Props> = (props: Props) => {
  const { updateAutosplicingReducer, autosplicingReducer, getTemplateDifference } = props;
  const { loadingIndicators, differenceList, focusedGraphEntity } = autosplicingReducer;

  const [applyButtonForceDisable, setApplyButtonForceDisable] = useState<boolean>(false);

  const { Panel } = Collapse;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadOutcome, setUploadOutcome] = useState<null | {
    status: 'success' | 'error';
    message: string;
    output: string;
    rawOutput: any;
  }>(null);

  const applyDifferenceChanges = async () => {
    if (autosplicingReducer?.formData?.closure_id! && differenceList!.data) {
      setUploading(true);

      return await httpPost(
        `ProjectModule/v1.0/network/autosplicing/templates/${autosplicingReducer?.formData
          ?.closure_id!}/difference?cableId=${autosplicingReducer?.formData?.cable_id!}`,
        null,
      )
        .then(async (res) => {
          setUploading(false);
          console.log('rawOutput', res.data.data);
        })
        .catch((err) => {
          setUploading(false);
          setUploadOutcome({
            status: 'error',
            message: 'There was an error applying changes.',
            output: JSON.stringify(err.message),
            rawOutput: {},
          });
        });
    }
  };

  // When the user clicks Apply Changes, we want to disable the button
  // for a few seconds, before the Autosplicing jobs kick in.
  useEffect(() => {
    if (applyButtonForceDisable) {
      setTimeout(function () {
        setApplyButtonForceDisable(false);
      }, 5000);
    }
  }, [applyButtonForceDisable]);

  const handleOk = () => {
    setApplyButtonForceDisable(true);
    applyDifferenceChanges();
    setIsModalVisible(false);
  };

  const getEntityTypeAndIdFromRevisionObject = (diffObject: any) => {
    let entity: { id: string; type: GraphEntity | '' } = { id: '', type: '' };

    if (
      diffObject &&
      diffObject?.revisionExternal &&
      diffObject.featureType &&
      ['CABLE', 'CLOSURE'].includes(diffObject.featureType)
    ) {
      entity = {
        id: diffObject.revisionExternal.featureId,
        type: diffObject.featureType,
      };
    }
    return entity;
  };

  const renderDiffPanel = (focusedNode: any, diffObject: any, index: number) => {
    const entity = getEntityTypeAndIdFromRevisionObject(diffObject);

    return (
      <Panel
        header={`${index + 1}. ${diffObject.description}`}
        key={String(index + 1 + 1)}
        extra={
          !diffObject?.revisionExternal?.canSave ? (
            <WarningOutlined style={{ color: '#fe9339' }} />
          ) : (
            <div />
          )
        }
      >
        <Row>
          <Col span={24} style={{ textAlign: 'center', marginBottom: 10, marginTop: 5 }}>
            <Button
              type="primary"
              ghost
              size="small"
              style={{
                border: focusedNode?.id === entity?.id ? '1px solid #52c41a' : '',
                color: focusedNode?.id === entity?.id ? '#52c41a' : '',
              }}
              icon={<SearchOutlined />}
              onClick={() =>
                updateAutosplicingReducer({
                  focusedGraphEntity: {
                    id: entity?.id,
                    type: entity?.type === 'CLOSURE' ? 'CLOSURE' : 'CABLE',
                  },
                })
              }
              disabled={!entity?.id}
            >
              {focusedNode?.id === entity?.id
                ? `Focused ${entity?.type} ${entity?.id}`
                : `Focus ${entity?.type} ${entity?.id}`}
              {entity?.type ? '' : ' Unavailable'}
            </Button>
          </Col>
          {/*<Col span={24}><span style={{ fontWeight: 800 }}>Impact</span></Col>*/}
          {/*{elem.impact.map((impactElem: any, j: number) => <Col*/}
          {/*  key={`impact${j}`}>{j + 1}. {impactElem}</Col>)}*/}
        </Row>
        <Row style={{ marginTop: 8 }}>
          <Col span={24}>
            <span style={{ fontWeight: 800 }}>Revision</span>
          </Col>
          <Col span={24}>
            <span>
              <pre>{JSON.stringify(diffObject.revisionExternal, null, 2)}</pre>
            </span>
          </Col>
        </Row>
      </Panel>
    );
  };

  const renderDifferenceList = () => {
    if (differenceList) {
      const summary = differenceList!.summary;
      return (
        <Collapse defaultActiveKey={['1']} style={{ fontSize: '0.9em' }}>
          <Panel header="Summary" key={1}>
            <Row>
              <Col span={24}>
                <span style={{ fontWeight: 600 }}>Differences:</span> {summary.differences}
              </Col>
            </Row>
          </Panel>
          {differenceList?.validationErrors?.length > 0
            ? differenceList?.validationErrors.map((elem: any, index: number) =>
                renderDiffPanel(focusedGraphEntity, elem, index),
              )
            : differenceList.data.map((elem: any, index: number) =>
                renderDiffPanel(focusedGraphEntity, elem, index),
              )}
        </Collapse>
      );
    }
  };

  return (
    <>
      <Row justify="end">
        <Col
          span={24}
          style={{
            textAlign: 'right',
            display: loadingIndicators?.isLoadingTemplates ? 'none' : 'block',
          }}
        >
          <Button
            size="small"
            disabled={loadingIndicators?.isLoadingTemplates}
            onClick={() => getTemplateDifference()}
            icon={<ReloadOutlined />}
          >
            Refresh
          </Button>
        </Col>
      </Row>
      <AutosplicingJobListener
        callbackFn={getTemplateDifference}
        allJobs={autosplicingReducer.allJobs!}
      />
      {loadingIndicators?.isLoadingTemplates ? (
        <Row style={{ textAlign: 'center', marginTop: 50 }}>
          <Col span={24} style={{ marginBottom: 30 }}>
            <Spin size="large" />
          </Col>
          <Col span={24}>Loading Templates ...</Col>
        </Row>
      ) : (
        <></>
      )}

      {!loadingIndicators?.isLoadingTemplates && uploadOutcome ? (
        <Row>
          <Col
            span={24}
            style={{
              padding: 10,
              borderRadius: 8,
              backgroundColor: uploadOutcome?.status === 'success' ? '#f6ffed' : '#fff2f0',
              border:
                uploadOutcome?.status === 'success' ? '1px solid #b7eb8f' : '1px solid #ffccc7',
            }}
          >
            <span>{uploadOutcome?.message}</span>
            <Collapse style={{ fontSize: '0.9em', marginTop: 10 }}>
              <Panel header="More Details" key={2} style={{ overflow: 'hidden' }}>
                <pre>{uploadOutcome.output}</pre>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      ) : (
        <></>
      )}

      {(!loadingIndicators?.isLoadingTemplates && differenceList?.data.length) ||
      differenceList?.validationErrors?.length ? (
        <div
          style={{
            backgroundColor: differenceList?.validationErrors.length ? '#ffe8e8' : '#f7f7f7',
            marginTop: 20,
            borderRadius: 8,
            padding: 20,
            marginBottom: 20,
          }}
        >
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                loading={uploading}
                disabled={
                  applyButtonForceDisable ||
                  uploading ||
                  differenceList?.data?.length < 1 ||
                  differenceList?.validationErrors?.length > 0
                }
                onClick={() => setIsModalVisible(true)}
                style={{ marginBottom: 20 }}
              >
                Apply Changes
              </Button>
            </Col>
            <Col span={24}>{renderDifferenceList()}</Col>
          </Row>

          <Modal
            title="Apply Changes"
            open={isModalVisible}
            onOk={handleOk}
            onCancel={() => setIsModalVisible(false)}
          >
            <p>Are you sure you want to Apply the changes?</p>
          </Modal>
        </div>
      ) : !loadingIndicators?.isLoadingTemplates ? (
        <Result status="success" title="There are no differences" />
      ) : (
        <></>
      )}
    </>
  );
};

const mapDispatch = (dispatch: any) => ({
  updateAutosplicingReducer: (params: IAutosplicingReducer) =>
    dispatch(updateAutosplicingReducerState(params)),
});

const mapState = (state: any) => ({
  autosplicingReducer: state.autosplicingReducer,
});

export default connect(mapState, mapDispatch)(AutosplicingCableConnection);
