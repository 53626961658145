import { Button, Dialog, DialogBody, DialogFooter } from '@blueprintjs/core';
import { FC, useState } from 'react';
import { connect } from 'react-redux';
import { httpDelete, httpGet, httpPost } from '@core/http/requests';
import { displayMessage } from '@redux/stores/messages/reducers';
import { DualPanelAssignment } from '@core/components/DualPanelAssignment';
import { getErrorMessage } from '@core/modules/ControlPanelModule/helpers/errors';

interface Props {
  company: any;
  departments: any[];
  onUpdate: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

type TDepartment = {
  id: string;
  name: string;
  description: string;
};

const ManageCompanyDepartmentsDialog: FC<Props> = (props: Props) => {
  const { company, alertMessage, onUpdate } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [allDepartments, setAllDepartments] = useState<TDepartment[]>([]);
  const [companyDepartments, setCompanyDepartments] = useState<TDepartment[]>([]);
  const [isLoadingDepartments, setIsLoadingDepartments] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const closeModal = () => {
    setIsDialogOpen(false);
    setAllDepartments([]);
    setCompanyDepartments([]);
  };

  const openModal = () => {
    loadAllDepartments();
    getExistingDepartmentsInTheCompany();
  };

  const getExistingDepartmentsInTheCompany = () => {
    if (props.departments) {
      const departments: any[] =
        props.departments?.map((d: any) => ({
          id: d.id,
          name: d.name,
          description: d.description,
        })) || [];
      setCompanyDepartments(departments);
    }
  };

  const loadAllDepartments = async () => {
    setIsLoadingDepartments(true);
    try {
      const res = await httpGet('IdentityModule/v2.0/departments?size=10000');
      const departments: TDepartment[] =
        res.data.data?.map((d: any) => ({
          id: d.id,
          name: d.name,
          description: d.description,
        })) || [];

      // Remove departments that are already assigned to the company
      const existingCompanyDepartmentsIds = props.departments?.map((d: any) => d.id) || [];
      const filteredDepartments = departments.filter(
        (u) => !existingCompanyDepartmentsIds.includes(u.id),
      );

      setAllDepartments(filteredDepartments);
      setIsLoadingDepartments(false);
      setIsDialogOpen(true);
    } catch (e: any) {
      setIsLoadingDepartments(false);
    }
  };

  const addDepartments = async () => {
    const departmentIds =
      companyDepartments
        .filter((u) => !props.departments?.map((cd) => cd.id).includes(u.id))
        ?.map((d: any) => d.id) || [];

    if (!departmentIds.length || !company) return;

    try {
      await httpPost(`IdentityModule/v2.0/companies/${company.id}/departments`, {
        departmentIds,
      });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({ body: 'Could not add departments to the company. ' + message, type: 'error' });
    }
  };

  const removeDepartments = async () => {
    const departmentIds =
      props.departments
        ?.filter((d) => !companyDepartments.map((cd) => cd.id).includes(d.id))
        ?.map((d: any) => d.id) || [];

    if (!departmentIds.length || !company) return;

    try {
      await httpDelete(`IdentityModule/v2.0/companies/${company.id}/departments`, {
        departmentIds,
      });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not remove departments from the company. ' + message,
        type: 'error',
      });
    }
  };

  const updateDepartmentsInCompany = async () => {
    setIsSaving(true);

    const addDepartmentsPromise = addDepartments();
    const removeDepartmentsPromise = removeDepartments();

    try {
      await Promise.all([addDepartmentsPromise, removeDepartmentsPromise]);
      alertMessage({ body: 'Departments updated successfully', type: 'success' });
      setIsSaving(false);
      closeModal();
      onUpdate();
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not update departments in the company. ' + message,
        type: 'error',
      });
      setIsSaving(false);
    }
  };

  const isSaveButtonDisabled = () => {
    const existingCompanyDepartmentsIds =
      props.departments?.map((d: any) => d.id)?.sort((a: any, b: any) => a.localeCompare(b)) || [];
    const companyDepartmentsIds = companyDepartments
      .map((d) => d.id)
      ?.sort((a: any, b: any) => a.localeCompare(b));
    return JSON.stringify(existingCompanyDepartmentsIds) === JSON.stringify(companyDepartmentsIds);
  };

  // Add departments to company, remove from the right side list
  const onDepartmentPanelChange = (departmentId: string) => {
    const department = allDepartments.find((d) => d.id === departmentId);
    if (department) {
      setCompanyDepartments([...companyDepartments, department]);
      setAllDepartments(allDepartments.filter((d) => d.id !== departmentId));
    }
  };

  // Remove department from company, add back to the left side list
  const onCompanyPanelChange = (departmentId: string) => {
    const department: TDepartment | undefined = companyDepartments?.find(
      (d) => d.id === departmentId,
    );
    if (department) {
      setCompanyDepartments(companyDepartments.filter((d) => d.id !== departmentId));
      setAllDepartments([...allDepartments, department]);
    }
  };

  const clearCompanyDepartments = () => {
    const newDepartments = [...allDepartments, ...companyDepartments].sort((a, b) =>
      a.name.localeCompare(b.name),
    );

    setAllDepartments(newDepartments);
    setCompanyDepartments([]);
  };

  return (
    <>
      <Button
        small
        minimal
        intent="primary"
        text="Manage"
        loading={isLoadingDepartments}
        onClick={openModal}
      />
      <Dialog
        title="Manage Departments in the Company"
        isOpen={isDialogOpen}
        onClose={closeModal}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        style={{ width: '80%' }}
      >
        <DialogBody>
          <DualPanelAssignment
            // Left panel
            leftPanelTitle="Departments"
            leftPanelIcon="briefcase"
            leftPanelSubtitle="All available Departments"
            leftPanelData={allDepartments}
            onLeftPanelChange={onDepartmentPanelChange}
            // Right Panel
            rightPanelTitle={`${company.name}`}
            rightPanelIcon="office"
            rightPanelSubtitle="All Departments in this Company"
            rightPanelData={companyDepartments}
            onRightPanelChange={onCompanyPanelChange}
            onRightPanelClear={clearCompanyDepartments}
          />
        </DialogBody>
        <DialogFooter
          actions={[
            <Button key="Close" text="Close" onClick={closeModal} />,
            <Button
              key="SaveChanges"
              text="Save Changes"
              disabled={isSaveButtonDisabled()}
              intent="primary"
              onClick={updateDepartmentsInCompany}
              loading={isSaving}
            />,
          ]}
        />
      </Dialog>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(ManageCompanyDepartmentsDialog);
