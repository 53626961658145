export const getSchemaActionType = (schemaAction: any): string => {
  if (schemaAction && schemaAction.schemaAssociationId && schemaAction.isCreate) {
    return 'CREATE LINK';
  } else if (schemaAction.schemaAssociationId && schemaAction.isUpdate) {
    return 'UPDATE LINK';
  } else if (schemaAction.schemaAssociationId && schemaAction.isDelete) {
    return 'DELETE LINK';
  } else if (schemaAction.isStepFlow && schemaAction.targetStages?.length! > 0) {
    return 'STAGE CHANGE';
  } else if (
    (schemaAction.isStepFlow && !schemaAction.targetStages) ||
    (schemaAction.isStepFlow && schemaAction.targetStages?.length === 0)
  ) {
    return 'MULTI STEP';
  } else if (schemaAction.isCreate) {
    return 'CREATE FORM';
  } else if (schemaAction.isUpdate) {
    return 'UPDATE FORM';
  } else if (schemaAction.isDelete) {
    return 'DELETE FORM';
  } else {
    return 'UNKNOWN';
  }
};
