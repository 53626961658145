import { PlusOutlined } from '@ant-design/icons';
import { Button, Section } from '@blueprintjs/core';
import { beforeUploadWithoutWatermark, getBase64 } from '@core/components/Files/helpers';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { displayMessage } from '@redux/stores/messages/reducers';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '@redux/stores/recordAssociations/actions';
import { deleteRecordByIdRequest } from '@redux/stores/records/actions';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '@redux/stores/schemas/actions';
import {
  DeleteSchemaAssociation,
  deleteSchemaAssociationsRequest,
} from '@redux/stores/schemasAssociations/actions';
import { IOpenRecordDrawer, openRecordDrawer } from '@redux/stores/userInterface/actions';
import { Col, Image, Modal, Row, Spin, Upload, UploadFile } from 'antd';
import { RcFile, UploadProps } from 'antd/es/upload';
import { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getBrowserPath } from '../../helpers/recordHelpers';
import { getSchemaFromShortListByModuleAndEntity } from '../../helpers/schemaHelpers';
import { getHostName } from '../../http/helpers';

interface Props {
  record: DbRecordEntityTransform;
  colSpan?: number;
  onUpload?: (id: string) => void;
  schemaReducer: any;
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) => void;
  getAssociations: (params: IGetRecordAssociations, cb: any) => void;
  deleteRecord: (payload: any, cb?: any) => void;
  hidden?: boolean;
  openDrawer: (params: IOpenRecordDrawer) => void;
}

const { SCHEMA_MODULE } = SchemaModuleTypeEnums;
const { FILE } = SchemaModuleEntityTypeEnums;

const SimpleUploadCard: FC<Props> = (props: Props) => {
  const {
    record,
    schemaReducer,
    getSchema,
    getAssociations,
    deleteRecord,
    onUpload,
    colSpan,
    openDrawer,
  } = props;
  const [loadingInitialFiles, setLoadingInitialFiles] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [parentSchema, setParentSchema] = useState<SchemaEntity | undefined>(undefined);
  const [fileSchema, setFileSchema] = useState<SchemaEntity | undefined>(undefined);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState('');

  // Fetch file schema on component mount
  useEffect(() => {
    if (!fileSchema) {
      const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer?.shortList,
        SCHEMA_MODULE,
        FILE,
      );

      if (shortlistSchema) {
        setFileSchema(shortlistSchema);
      } else {
        getSchema({ moduleName: SCHEMA_MODULE, entityName: FILE }, (res: SchemaEntity) => {
          setFileSchema(res);
        });
      }
    }
  }, []);

  // Fetch parent record schema when record component is available
  useEffect(() => {
    if (!parentSchema && record) {
      const moduleName = record.entity?.split(':')[0];
      const entityName = record.entity?.split(':')[1];

      const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer?.shortList,
        moduleName,
        entityName,
      );

      if (shortlistSchema) {
        setParentSchema(shortlistSchema);
      } else if (!shortlistSchema && moduleName && entityName) {
        getSchema({ moduleName: moduleName, entityName: entityName }, (res: SchemaEntity) => {
          setParentSchema(res);
        });
      }
    }
  }, [record]);

  // Fetch existing files on component mount
  useEffect(() => {
    if (parentSchema && record) {
      setLoadingInitialFiles(true);
      getAssociations(
        {
          recordId: record.id,
          key: FILE,
          schema: parentSchema,
          entities: [FILE],
        },
        (res: any) => {
          if (res && res.results?.[FILE]?.dbRecords?.length > 0) {
            setLoadingInitialFiles(false);
            const newFileList = res.results?.[FILE]?.dbRecords.map(
              (file: DbRecordEntityTransform) => {
                return {
                  name: file.title,
                  status: 'done',
                  url: getProperty(file, 'Url'),
                  thumbUrl: getProperty(file, 'Url'),
                  percent: 100,
                  uid: file.id,
                  id: file.id,
                  type: getProperty(file, 'MimeType'),
                };
              },
            );
            setFileList(newFileList);
          } else {
            setLoadingInitialFiles(false);
          }
        },
      );
    }
  }, [parentSchema]);

  const uploadButton = () => (
    <div>
      {loadingInitialFiles ? <Spin size="small" /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload File</div>
    </div>
  );

  // We need to show confirmation when user removes the file
  const onGalleryFileRemove = async (file: any): Promise<boolean> => {
    const { confirm } = Modal;
    return new Promise((resolve, reject) => {
      confirm({
        title: 'Removing file',
        content: 'Removing this file form the form will permanently delete it.',
        onOk: () => {
          resolve(true);
          deleteRecord({
            schema: fileSchema,
            recordId: file.id || file.response?.data?.id,
          });
        },
        onCancel: () => {
          reject(true);
        },
      });
    });
  };

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage('');
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || file.preview || '');
    setPreviewVisible(true);
  };

  // This will run on file upload
  const handleChange: UploadProps['onChange'] = (info: any) => {
    setFileList(info.fileList);
    if (info.file?.status === 'done') {
      const fileDbRecord: DbRecordEntityTransform = info.file.response.data;
      if (onUpload) {
        onUpload(fileDbRecord.id);
      }
    }
  };

  return (
    <Col span={colSpan ? colSpan : 24} style={{ display: props.hidden ? 'none' : 'block' }}>
      <Image
        className="previewImagePlaceholder"
        width={200}
        style={{ display: 'none', border: 'none' }}
        src={previewImage}
        preview={{
          visible: previewVisible,
          src: previewImage,
          onVisibleChange: (value) => {
            setPreviewVisible(value);
          },
        }}
      />
      <Section
        rightElement={
          <Button
            icon="panel-stats"
            onClick={() =>
              openDrawer({
                recordId: record?.id,
                moduleName: record?.entity?.split(':')[0] || '',
                entityName: record?.entity?.split(':')[1] || '',
              })
            }
          />
        }
        title={
          <Row>
            <Col span={12} style={{ paddingTop: 5, paddingLeft: 4 }}>
              <span>{record.type}</span>
              <Link to={getBrowserPath(record)} target="_blank" style={{ marginLeft: 5 }}>
                ({getProperty(record, 'ExternalRef')})
              </Link>
            </Col>
          </Row>
        }
      >
        <Row style={{ padding: 15 }}>
          <Col span={24}>
            <Upload
              disabled={loadingInitialFiles}
              className={``}
              multiple={true}
              name={'file'}
              fileList={fileList}
              action={`${getHostName()}/SchemaModule/v1.0/s3/files/${record.entity}/${
                record?.id
              }/upload`}
              headers={{ Authorization: 'Bearer ' + localStorage.getItem(`token`) }}
              data={{
                device: isMobile ? 'MOBILE' : 'DESKTOP',
                applicationVersion: import.meta.env.VITE_VERSION || '',
              }}
              onChange={handleChange}
              onPreview={handlePreview}
              onRemove={onGalleryFileRemove}
              beforeUpload={(file: any) => beforeUploadWithoutWatermark(file) as any}
              listType="picture-card"
            >
              {uploadButton()}
            </Upload>
          </Col>
        </Row>
      </Section>
    </Col>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  deleteAssociation: (params: DeleteSchemaAssociation) =>
    dispatch(deleteSchemaAssociationsRequest(params)),
  deleteRecord: (payload: any, cb: any) => dispatch(deleteRecordByIdRequest(payload, cb)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(SimpleUploadCard);
