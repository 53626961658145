import React from 'react';
import { BlockOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import { connect } from 'react-redux';
import {
  MapReducerUpdate,
  updateMapState,
} from '@netomnia/modules/ProjectModule/views/Map/store/actions';
import { MapReducer } from '@netomnia/modules/ProjectModule/views/Map/store/reducer';

interface Props {
  mapReducer: MapReducer;
  layerSwitcherRef: any;
  updateMap: (params: MapReducerUpdate) => {};
}

class SidebarLayerSwitcher extends React.Component<Props> {
  render() {
    const { mapReducer, layerSwitcherRef } = this.props;

    return (
      <Row
        style={{
          display: mapReducer.mapSidebarSection === 'LAYERS' ? 'block' : 'none',
        }}
      >
        {/* Layers */}
        <Col span={24} style={{ marginBottom: '15px' }}>
          <Card
            className="layerSwitcherCard"
            size="small"
            title={
              <span>
                <BlockOutlined style={{ marginRight: '5px' }} />
                Layer Control
              </span>
            }
          >
            <div ref={layerSwitcherRef} id="layer-switcher" className="layer-switcher" />
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapState = (state: any) => ({
  mapReducer: state.mapReducer,
});

const mapDispatch = (dispatch: any) => ({
  updateMap: (params: MapReducerUpdate) => dispatch(updateMapState(params)),
});

export default connect(mapState, mapDispatch)(SidebarLayerSwitcher);
