import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Select } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { TableReducer } from '@redux/stores/table/reducer';
import {
  getQueryBuilderReducer,
  IQueryBuilderByModuleAndEntityReducer,
  QueryBuilderReducer,
} from '../store/reducer';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import { setQueryBuilderState, setSearchQuery } from '../store/actions';
import { generateModuleAndEntityKeyFromProps, getSavedFilter } from '@core/helpers/searchHelpers';
import { parseTypeFilterForQuery } from '../helpers/typeFilterParsers';
import { ISchemaReducer } from '../../../../../redux/stores/schemas/reducer';
import { parseGroupsFilterForQuery } from '../helpers/groupsFilterParsers';

interface Props {
  schema: SchemaEntity | undefined;
  moduleName: string;
  entityName: string;
  queryBuilderReducer: QueryBuilderReducer;
  recordTableReducer: TableReducer;
  setBuilderState: (params: any) => {};
  configure: any;
  schemaReducer: ISchemaReducer;
}

const SchemaTypeFilterDropdown: React.FC<Props> = (props: Props) => {
  const { schema, setBuilderState, configure, schemaReducer, recordTableReducer } = props;

  const queryBuilderReducer = getQueryBuilderReducer(
    props.queryBuilderReducer,
    schema?.moduleName,
    schema?.entityName,
  );

  useEffect(() => {
    const savedFilter = getSavedFilter(
      schemaReducer,
      recordTableReducer,
      schema?.moduleName ?? '',
      schema?.entityName ?? '',
    );
    if (!!savedFilter) {
      // setBuilderState({
      //   formFields: savedFilter.formFields,
      // });
      if (savedFilter.formFields.typeFilters) {
        const filter = savedFilter.formFields.typeFilters[0];
        if (filter && filter.value) {
          // applyFilters(filter.value[0]);
        }
      }
    }
  }, []);

  const applyFilters = (types: string, clearPipelines?: boolean) => {
    const formFields = queryBuilderReducer.formFields;
    let queries = [];

    const typeFiltersForQuery = parseTypeFilterForQuery([types]);
    if (typeFiltersForQuery) {
      queries.push(typeFiltersForQuery);
    }

    setBuilderState({
      formFields: {
        ...formFields,
        typeFilters: [
          {
            condition: 'filter',
            esPropPath: 'type.keyword',
            value: [types],
          },
        ],
        pipelineFilters: clearPipelines ? [] : formFields.pipelineFilters,
        propertyFilters: [],
      },
    });

    // 2. Group filters
    const groupsFilterForQuery = parseGroupsFilterForQuery(formFields?.groupsFilters?.[0]?.value);
    if (groupsFilterForQuery) {
      queries.push(groupsFilterForQuery);
    }

    // Set search query
    formFields.propertyFilters.map((elem) => queries.push(elem));
    configure({ schema: schema, query: queries, queryType: 'query_string' });
  };

  return (
    <Select
      key="stage"
      allowClear
      onClear={() => applyFilters('')}
      defaultValue={['']}
      style={{ width: '100%' }}
      disabled={false}
      value={queryBuilderReducer.formFields?.typeFilters?.[0]?.value || ['']}
      onChange={(val: any) => {
        applyFilters(val, true);
      }}
    >
      {schema?.types
        ?.sort((typeA: SchemaTypeEntity, typeB: SchemaTypeEntity) =>
          typeA.name.localeCompare(typeB.name),
        )
        .map((type: SchemaTypeEntity) => (
          <Select.Option key={type.id} value={type.name}>
            {type.name}
          </Select.Option>
        ))}
    </Select>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  queryBuilderReducer: state.queryBuilderReducer,
  recordTableReducer: state.recordTableReducer,
});
const mapDispatch = (dispatch: any, ownProps: any) => ({
  setBuilderState: (params: IQueryBuilderByModuleAndEntityReducer) =>
    dispatch(setQueryBuilderState(generateModuleAndEntityKeyFromProps(ownProps), params)),
  configure: (params: any) =>
    dispatch(setSearchQuery(generateModuleAndEntityKeyFromProps(ownProps), params)),
});

export default connect(mapState, mapDispatch)(SchemaTypeFilterDropdown);
