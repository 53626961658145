import { IdentityOrganizationUserLogin } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/types/identity.organization.user.login';
import {
  COMPLETE_REGISTRATION_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  GENERATE_REGISTRATION_LINK_REQUEST,
  GET_USER_LIST_REQUEST,
  RESET_PASSWORD_REQUEST,
  SEND_REGISTRATION_LINK_REQUEST,
  UPDATE_USER_ROLES_AND_PERMISSIONS_REQUEST,
  USER_LOGIN_CANCEL_REQUESTS,
  USER_LOGIN_FROM_TOKEN,
  USER_LOGIN_REQUEST,
  USER_LOGOUT_REQUEST,
} from './constants';

export function listUsers(cb = () => {}) {
  return {
    type: GET_USER_LIST_REQUEST,
    cb,
  };
}

export function loginRequest(payload: IdentityOrganizationUserLogin, cb = () => {}) {
  return {
    type: USER_LOGIN_REQUEST,
    payload,
    cb,
  };
}

export function loginFromToken(newToken: string, cb = () => {}) {
  return {
    type: USER_LOGIN_FROM_TOKEN,
    newToken,
    cb,
  };
}

export function loginCancelRequest() {
  return {
    type: USER_LOGIN_CANCEL_REQUESTS,
  };
}

export function logoutRequest() {
  return {
    type: USER_LOGOUT_REQUEST,
  };
}

export function updateUserRolesAndPermissionsRequest(cb = () => {}) {
  return {
    type: UPDATE_USER_ROLES_AND_PERMISSIONS_REQUEST,
    cb,
  };
}

export function forgotPasswordRequest(params: string, cb = () => {}) {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    params,
    cb,
  };
}

export function resetPasswordRequest(params: any, cb = () => {}) {
  return {
    type: RESET_PASSWORD_REQUEST,
    params,
    cb,
  };
}

export function sendRegistrationLinkRequest(params: any, cb = () => {}) {
  return {
    type: SEND_REGISTRATION_LINK_REQUEST,
    params,
    cb,
  };
}

export function generateRegistrationLinkRequest(params: any, cb = () => {}) {
  return {
    type: GENERATE_REGISTRATION_LINK_REQUEST,
    params,
    cb,
  };
}

export function completeRegistrationRequest(params: any, cb = () => {}) {
  return {
    type: COMPLETE_REGISTRATION_REQUEST,
    params,
    cb,
  };
}
