import { LoadingOutlined } from '@ant-design/icons';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Button, Col, Descriptions, Empty, List, Radio, Row, Spin, Switch, Typography } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { httpPost } from '@core/http/requests';
import { hasPermissions } from '@core/helpers/rbacRules';
import { IAutosplicingReducer } from '../../store/types';

interface Props {
  autosplicingReducer: IAutosplicingReducer;
  userReducer: any;
}

const AutosplicingL4Sync: React.FC<Props> = (props: Props) => {
  const { autosplicingReducer, userReducer } = props;
  const [syncRunning, setSyncRunning] = useState<boolean>(false);
  const [syncResponse, setSyncResponse] = useState<any>(undefined);
  const [dryRun, setDryRun] = useState<boolean>(true);
  const [activeResultTab, setActiveResultTab] = useState<'Files' | 'Updates'>('Files');

  const runClosureSync = async () => {
    const L2PolygonId = getProperty(autosplicingReducer.selectedEntityOdinRecord, 'L2PolygonId');
    setSyncRunning(true);

    const response = await httpPost(
      `ProjectModule/v1.0/network/changemanager/sync-build-status/${L2PolygonId}?dryRun=${dryRun ? 'true' : 'false'}`,
      {},
    );

    if (response?.data?.data) {
      setSyncResponse(response.data?.data);
      setSyncRunning(false);
    }
  };

  return (
    <Row>
      <Col
        span={24}
        style={{
          background: '#f7f7f7',
          borderRadius: 8,
          padding: '15px 20px 20px 20px',
        }}
      >
        <Row>
          <Col span={24}>
            <Typography.Title level={5}>L4 Closure synchronization</Typography.Title>
          </Col>

          <Col span={24}>
            This sync will update all feature from an L4 closure back to the L2 closure if the L4
            closure is in build status 7-Build Done or 8-RFS all feature build statuses will be
            updated to the L4 closure build status.
          </Col>
          <Col style={{ marginTop: 15 }} span={24}>
            <Row>
              <Col span={12}>
                <span>Dry run</span>
                <br />
                <Switch
                  style={{ marginTop: 5 }}
                  checked={dryRun}
                  disabled={syncRunning}
                  onChange={() => setDryRun(!dryRun)}
                />
              </Col>
              <Col span={12} style={{ textAlign: 'right', marginTop: 15 }}>
                <Button
                  type="primary"
                  onClick={() => runClosureSync()}
                  disabled={
                    syncRunning ||
                    !hasPermissions(userReducer, ['projectmodule.feature.buildstatussync'])
                  }
                >
                  Start
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      {syncRunning ? (
        <Col style={{ marginTop: 20, textAlign: 'center', borderRadius: 8 }} span={24}>
          <Row style={{ padding: 30 }}>
            <Col span={24}>
              <span style={{ fontSize: '1.2em!important' }}>L4 synchronization is running...</span>
            </Col>
            <Col span={24} style={{ marginTop: 30 }}>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 28 }} spin />} />;
            </Col>
          </Row>
        </Col>
      ) : (
        <></>
      )}
      {syncResponse ? (
        <Col style={{ marginTop: 20, textAlign: 'center', borderRadius: 8 }} span={24}>
          <Row style={{ padding: 5 }}>
            <Col span={24} style={{ marginBottom: 20 }}>
              <Radio.Group
                optionType="button"
                onChange={(e: any) => {
                  setActiveResultTab(e.target.value);
                }}
                value={activeResultTab}
                options={[
                  { label: 'Files', value: 'Files' },
                  { label: 'Updates', value: 'Updates' },
                ]}
              />
            </Col>
            {activeResultTab === 'Files' ? (
              <Col span={24}>
                <Row>
                  {syncResponse?.s3Files?.length > 0 ? (
                    <Col span={24}>
                      <List
                        style={{ width: '100%', fontSize: '0.9em' }}
                        size="small"
                        bordered
                        dataSource={syncResponse?.s3Files}
                        renderItem={(item: string) => (
                          <List.Item style={{ wordBreak: 'break-all' }}>
                            <a href={item} target="_blank">
                              {item}
                            </a>
                          </List.Item>
                        )}
                      />
                    </Col>
                  ) : (
                    <Col span={24} style={{ marginTop: 40 }}>
                      <Empty description="No files to show" />
                    </Col>
                  )}
                </Row>
              </Col>
            ) : (
              <></>
            )}
            {activeResultTab === 'Updates' ? (
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    {syncResponse?.updates?.length > 0 ? (
                      syncResponse?.updates?.map((response: any) => (
                        <Descriptions
                          bordered
                          size="small"
                          column={1}
                          style={{ width: '100%', marginBottom: 30 }}
                        >
                          <Descriptions.Item label="Feature type">
                            {response.feature_type}
                          </Descriptions.Item>
                          <Descriptions.Item label="Feature Id">
                            {response.feature_id}
                          </Descriptions.Item>
                          <Descriptions.Item label="Build Status Before">
                            {response.build_status_before}
                          </Descriptions.Item>
                          <Descriptions.Item label="Build Status After">
                            {response.build_status_after}
                          </Descriptions.Item>
                        </Descriptions>
                      ))
                    ) : (
                      <Col span={24} style={{ marginTop: 40 }}>
                        <Empty description="No updates to show" />
                      </Col>
                    )}
                  </Col>
                </Row>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Col>
      ) : (
        <></>
      )}
    </Row>
  );
};

const mapState = (state: any) => ({
  autosplicingReducer: state.autosplicingReducer,
  userReducer: state.userReducer,
});

export default connect(mapState)(AutosplicingL4Sync);
