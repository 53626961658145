import { Card, Flex } from 'antd';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  title: string;
}>;
const SectionCard = (props: Props) => {
  return (
    <Card
      styles={{ body: { padding: 16 } }}
      style={{
        boxSizing: 'border-box',
        backgroundColor: '#00000008',
        borderRadius: '8px',
        border: '1px solid #0000000D',
      }}
    >
      <div
        style={{
          marginBottom: 16,
          fontFamily: 'DM Sans',
          fontWeight: 700,
          fontSize: '20px',
          lineHeight: '100%',
          letterSpacing: '0%',
        }}
      >
        {props.title}
      </div>
      <Flex gap={8} vertical>
        {props.children}
      </Flex>
    </Card>
  );
};

export default SectionCard;
