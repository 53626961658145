import HeaderListView from '@core/components/HeaderListView';
import { canUserSearchRecord } from '@core/helpers/rbacRules';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import { generateFilterKey } from '@core/helpers/searchHelpers';
import { getPipelinesByModuleAndEntity } from '@redux/stores/pipelines/actions';
import { resetRecordsList } from '@redux/stores/records/actions';
import { IRecordReducer } from '@redux/stores/records/reducer';
import Error403 from '@core/pages/403';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Layout } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DataTable from '../DynamicTable';
import {
  getQueryBuilderReducer,
  QueryBuilderReducer,
} from '../DynamicTable/QueryBuilder/store/reducer';
import { resetTableState, saveTableFilters } from '@redux/stores/table/actions';
import './styles.scss';

type PathParams = {
  url: string;
  entityName: string;
};

type PropsType = RouteComponentProps<PathParams> & {
  moduleName: string;
  entityName?: string;
  userReducer?: Object;
  recordReducer: IRecordReducer;
  queryBuilderReducer: QueryBuilderReducer;
  recordTableReducer: any;
  saveFilter: any;
  resetRecordState: any;
  resetTableReducer: any;
  match: any;
  getPipelines: (params: SchemaEntity, cb: any) => void;
};

interface State {
  entityName: string | undefined;
  schema: SchemaEntity | undefined;
  pipeline: PipelineEntity | undefined;
}

class RecordListView extends React.Component<PropsType, State> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      entityName: undefined,
      schema: undefined,
      pipeline: undefined,
    };
  }

  // Load schema
  componentDidMount(): void {
    const { match, entityName, resetTableReducer } = this.props;

    this.loadSchema(this.props.entityName || match.params.entityName);
    this.props.resetRecordState();
    resetTableReducer();

    this.setState({
      entityName: match.params.entityName || entityName,
    });
  }

  componentDidUpdate(prevProps: Readonly<PropsType>, prevState: Readonly<State>): void {
    const { moduleName, match } = this.props;

    // Watch for entityName updates and update schema
    if (prevProps.entityName !== this.props.entityName && this.props.entityName) {
      this.setState({ entityName: this.props.entityName });
      this.loadSchema(this.props.entityName);
    } else if (prevProps.match.params.entityName !== this.props.match.params.entityName) {
      this.setState({ entityName: this.props.match.params.entityName });
      this.loadSchema(this.props.match.params.entityName);
    }

    // Schema is updated, fetch the pipeline
    if (prevState.schema !== this.state.schema && this.state.schema) {
      this.loadPipelines(this.state.schema);
    }

    // User has logged back in
    else if (
      (prevProps.userReducer as any)?.user?.id !== (this.props.userReducer as any)?.user?.id
    ) {
      this.loadSchema(this.props.entityName || match.params.entityName);
    }

    if (prevProps.recordTableReducer.columns !== this.props.recordTableReducer.columns) {
      this.saveTableFilters();
    }

    if (prevProps.recordReducer.searchQuery !== this.props.recordReducer.searchQuery) {
      this.saveTableFilters();
    }

    const prevQbr = getQueryBuilderReducer(
      prevProps.queryBuilderReducer,
      moduleName,
      this.state.entityName,
    );
    const queryBuilderReducer = getQueryBuilderReducer(
      this.props.queryBuilderReducer,
      moduleName,
      this.state.entityName,
    );
    if (prevQbr.queries !== queryBuilderReducer.queries) {
      this.saveTableFilters();
    }

    if (prevQbr.dateRangeFilters !== queryBuilderReducer.dateRangeFilters) {
      this.saveTableFilters();
    }

    if (prevQbr.formFields !== queryBuilderReducer.formFields) {
      this.saveTableFilters();
    }
  }

  async loadSchema(entityName: string) {
    const { moduleName } = this.props;
    const schema = await getOdinSchemaByEntity(moduleName, entityName);
    this.setState({ schema });
  }

  loadPipelines(schema: SchemaEntity) {
    const { getPipelines } = this.props;

    getPipelines(schema, (res: any) => {
      if (res?.length! > 0) {
        this.setState({ pipeline: res[0] });
      } else {
        this.setState({ pipeline: undefined });
      }
    });
  }

  private saveTableFilters() {
    const { moduleName, recordReducer, recordTableReducer, saveFilter } = this.props;
    const { entityName, schema } = this.state;

    const queryBuilderReducer = getQueryBuilderReducer(
      this.props.queryBuilderReducer,
      moduleName,
      entityName,
    );

    if (!recordReducer.isSearching) {
      if (schema) {
        const name = generateFilterKey(schema.moduleName, schema.entityName);
        saveFilter(name, {
          search: recordReducer.searchQuery,
          columns: recordTableReducer.columns,
          queryBuilder: queryBuilderReducer,
        });
      }
    }
  }

  render() {
    const { moduleName, userReducer } = this.props;
    const { entityName } = this.state;

    let pipelinesEnabledInTable = !!this.state.pipeline || false;

    if (this.state.schema && userReducer && !canUserSearchRecord(userReducer, this.state.schema)) {
      return (
        <Error403
          missingPermission={`${this.state.schema?.moduleName?.toLowerCase()}.${this.state?.schema?.entityName?.toLowerCase()}.search`}
        />
      );
    } else {
      return (
        <Layout className="listViewWrapper">
          <HeaderListView moduleName={moduleName!} entityName={entityName!} />
          <div>
            <DataTable
              bordered
              schema={this.state.schema}
              moduleName={moduleName}
              entityName={entityName}
              pipelinesEnabled={pipelinesEnabledInTable}
            />
          </div>
        </Layout>
      );
    }
  }
}

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
  recordTableReducer: state.recordTableReducer,
  queryBuilderReducer: state.queryBuilderReducer,
});

const mapDispatch = (dispatch: any) => ({
  saveFilter: (name: string, params: any) => dispatch(saveTableFilters(name, params)),
  resetRecordState: () => dispatch(resetRecordsList()),
  resetTableReducer: () => dispatch(resetTableState()),
  getPipelines: (params: SchemaEntity, cb: any) =>
    dispatch(getPipelinesByModuleAndEntity({ schema: params }, cb)),
});

export default withRouter(connect(mapState, mapDispatch)(RecordListView));
