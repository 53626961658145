import { DetailViewContext } from '@core/components/DetailViewContextProvider';
import DetailView from 'src/core/views/DefaultDetailView';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { FunctionComponent, useContext } from 'react';
import RecordCard from 'src/core/components/RecordCard';

const { ORDER_MODULE } = SchemaModuleTypeEnums;
const { ORDER } = SchemaModuleEntityTypeEnums;

const PICRequestDetailView: FunctionComponent = () => {
  const { record } = useContext(DetailViewContext);

  return (
    <DetailView
      showCollaborators
      leftColumn={[
        <RecordCard
          showActionMenu
          borderless
          showItemActionMenu
          showRecordStage={true}
          shouldPollData={true}
          pollingIntervalSeconds={5}
          record={record}
          displayQuickView
          associatedRecordModuleName={ORDER_MODULE}
          associatedRecordEntityName={ORDER}
          visibleProperties={['ActiveDate', 'DunningStatus']}
        />,
      ]}
    />
  );
};

export default PICRequestDetailView;
