import { Col, Row } from 'antd';
import { FC } from 'react';

import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';

import { ChildForm } from './ChildForm';
import { ParentForm } from './ParentForm';
import { SettingsForm, SettingsFormProps } from './SettingsForm';
import './styles.scss';
import { TypeConstraintsForm } from './TypeConstraintsForm';
import { Action, Cardinality, TPanelDataItem } from './types';

type SchemaFormProps = {
  schema: TPanelDataItem | null;
  setSchema: (schema: TPanelDataItem | null) => void;
  cardinality: Cardinality;
  setCardinality: (cardinality: Cardinality) => void;
  action: Action;
  setAction: (action: Action) => void;
};

type ManageLinkFormProps = {
  schema: SchemaEntity;
  link?: SchemaAssociationEntity;
  parentProps: SchemaFormProps;
  childProps: SchemaFormProps;
  settingsProps: SettingsFormProps;
  typeConstraintsProps: any;
};

export const ManageLinkForm: FC<ManageLinkFormProps> = ({
  schema,
  link,
  parentProps,
  childProps,
  settingsProps,
  typeConstraintsProps,
}) => {
  return (
    <>
      <Row
        align="top"
        justify="space-between"
        gutter={14}
        style={{ padding: 10 }}
        className="manage-link-form"
      >
        {/* Left Panel */}
        <Col span={12}>
          <ParentForm
            schema={schema}
            link={link}
            cardinalityProps={{
              value: parentProps.cardinality,
              onChange: parentProps.setCardinality,
            }}
            schemaProps={{
              value: parentProps.schema,
              onChange: parentProps.setSchema,
            }}
            actionsProps={{
              value: parentProps.action,
              onChange: parentProps.setAction,
            }}
          />
        </Col>

        {/* Right Panel */}
        <Col span={12}>
          <ChildForm
            link={link}
            schemaProps={{
              value: childProps.schema,
              onChange: childProps.setSchema,
            }}
            cardinalityProps={{
              value: childProps.cardinality,
              onChange: childProps.setCardinality,
            }}
            actionsProps={{
              value: childProps.action,
              onChange: childProps.setAction,
            }}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 14, padding: 10 }}>
        {/* Bottom Panel */}
        <Col span={24}>
          <SettingsForm {...settingsProps} />
          <TypeConstraintsForm {...typeConstraintsProps} link={link} />
        </Col>
      </Row>
    </>
  );
};
