import { Button, InputGroup } from '@blueprintjs/core';
import { Cell, Column, RenderMode, Table2, TableLoadingOption } from '@blueprintjs/table';
import { BlueprintNavigation } from '@core/components/BlueprintPagination';
import FormModal, { FormReducerSubmitEvt } from '@core/components/Forms/SharedForm/SharedFormModal';
import { PageHeader } from '@core/components/PageHeader';
import { searchString } from '@core/helpers/searchHelpers';
import { httpGet, httpPost } from '@core/http/requests';
import { getErrorMessage } from '@core/modules/ControlPanelModule/helpers/errors';
import { displayMessage } from '@redux/stores/messages/reducers';
import { initializeSharedForm } from '@redux/stores/sharedForm/actions';
import { SharedFormReducer } from '@redux/stores/sharedForm/reducer';
import { Col, Row, Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import * as formFields from './FormFields';
import './styles.scss';

interface Props {
  initializeForm: any;
  formReducer: SharedFormReducer;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface ITableData {
  key: string | undefined;
  name: string | undefined;
  label: string | undefined;
  description: string | undefined;
  service: string | undefined;
  templateId: string | undefined;
}

const uuid = uuidv4();

const EmailTemplateManagerView: FC<Props> = (props: Props) => {
  const { formReducer, initializeForm, alertMessage } = props;
  const [searchKey, setSearchKey] = useState<string>('');
  const [tableWidth, setTableWidth] = useState<number>(1);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<any | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(33);
  const [emailTemplateList, setEmailTemplateList] = useState<any[]>([]);
  const [tableSlice, setTableSlice] = useState<ITableData[]>([]);
  const [isLoadingEmailTemplateList, setIsLoadingEmailTemplateList] = useState<boolean>(false);

  // create ref for html element
  const tableRef = React.createRef<any>();

  useEffect(() => {
    getAllEmailTemplateList();
  }, []);

  useEffect(() => {
    setSelectedEmailTemplate(undefined);
  }, [currentPage]);

  useEffect(() => {
    if (emailTemplateList.length > 0) {
      let tableData: ITableData[] = Object.assign([], emailTemplateList);

      tableData = emailTemplateList.map((emailTemplate: any) => ({
        key: emailTemplate.id,
        name: emailTemplate.name,
        label: emailTemplate.label,
        description: emailTemplate.description,
        service: emailTemplate.service,
        templateId: emailTemplate.templateId,
      }));
      // Apply search
      tableData = tableData.filter((emailTemplate: any) => {
        return (
          searchString(emailTemplate.name, searchKey) ||
          searchString(emailTemplate.description, searchKey)
        );
      });

      // Apply pagination
      const start = currentPage * pageSize - pageSize;
      const end = start + pageSize - 1;
      if (!searchKey) {
        tableData = tableData.slice(start, end);
      }

      setTableSlice(tableData);
    }
  }, [emailTemplateList, currentPage, searchKey, pageSize]);

  const getAllEmailTemplateList = async () => {
    try {
      setIsLoadingEmailTemplateList(true);
      const res = await httpGet(`NotificationModule/v1.0/templates/emails?size=10000`);
      const emails: any[] = res.data.data || [];
      setEmailTemplateList(emails);
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({ body: 'Could not retrieve role list. ' + message, type: 'error' });
      setEmailTemplateList([]);
    } finally {
      setIsLoadingEmailTemplateList(false);
    }
  };

  // Table Width Calculation
  const getColumnWidthByPercentage = (percentage: number): number => {
    return (percentage / 100) * tableWidth;
  };

  const updateWidth = () => {
    if (tableRef.current) {
      const width = tableRef.current.scrollContainerElement?.clientWidth;
      setTableWidth(width);
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [selectedEmailTemplate, tableRef, tableSlice]);

  const showCreateEmailTemplateForm = () => {
    initializeForm({
      showModal: true,
      formUUID: uuid,
      title: 'Create Email Template',
      formFields: formFields.createEmailTemplateFormFields,
      entityName: 'EmailTemplate',
    });
  };

  const createEmailTemplate = async (params: { body: any }) => {
    const { body } = params;
    try {
      const res = await httpPost('NotificationModule/v1.0/templates/emails', body);
      const newEmailTemplate = res.data.data;
      setEmailTemplateList([...emailTemplateList, newEmailTemplate]);

      alertMessage({ body: 'Email template Created', type: 'success' });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not create email template. ' + message,
        type: 'error',
      });
    }
  };

  const handleFormSubmit = (params: FormReducerSubmitEvt) => {
    if (params.data && !formReducer.isUpdateReq) {
      const body = {
        templateId: params.data.templateId,
        name: params.data.name,
        description: params.data.description,
        label: params.data.label,
        service: 'SENDGRID',
        dynamicTemplateData: {},
      };
      createEmailTemplate({ body });
    }
  };

  const onSearch = (e: any) => {
    setSearchKey(e.target.value);
  };

  const renderEmailTemplates = () => {
    return (
      <>
        <FormModal
          formUUID={uuid}
          onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
        />
        <PageHeader
          className="page-tool-bar"
          style={{ background: 'white', padding: 0, margin: 0 }}
          ghost
        >
          <Row style={{ marginBottom: 15, marginTop: 5 }} justify="end">
            <Col span={4}>
              <h2 style={{ margin: 0 }}>Email Templates</h2>
            </Col>
            <Col span={20} style={{ textAlign: 'right' }}>
              <Space>
                <InputGroup
                  type="search"
                  placeholder="Search Email Templates"
                  intent={searchKey.length > 0 ? 'primary' : 'none'}
                  onChange={onSearch}
                  leftIcon="search"
                  style={{ width: isMobile ? '100%' : 220 }}
                />
                <Button icon="plus" intent="success" onClick={showCreateEmailTemplateForm}>
                  Create Email Template
                </Button>
              </Space>
            </Col>
          </Row>
        </PageHeader>

        {/* Row with dynamically calculated height */}
        <Row className="listViewContainer">
          <Col span={selectedEmailTemplate ? 17 : 24} style={{ height: '100%', width: 0 }}>
            <Table2
              loadingOptions={isLoadingEmailTemplateList ? [TableLoadingOption.CELLS] : []}
              enableGhostCells={isLoadingEmailTemplateList}
              ref={tableRef}
              numRows={tableSlice.length}
              defaultRowHeight={30}
              enableMultipleSelection={false}
              enableRowHeader={false}
              renderMode={RenderMode.NONE}
              forceRerenderOnSelectionChange={false}
              columnWidths={[
                getColumnWidthByPercentage(20),
                getColumnWidthByPercentage(20),
                getColumnWidthByPercentage(20),
                getColumnWidthByPercentage(20),
                getColumnWidthByPercentage(20),
              ]}
            >
              <Column
                key="name"
                name="Name"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].name}</Cell>
                )}
              />
              <Column
                key="label"
                name="Label"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].label}</Cell>
                )}
              />
              <Column
                key="description"
                name="Description"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].description}</Cell>
                )}
              />
              <Column
                key="service"
                name="Service"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].service}</Cell>
                )}
              />
              <Column
                key="templateId"
                name="Template ID"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].templateId}</Cell>
                )}
              />
            </Table2>
          </Col>
        </Row>

        {/* Pagination */}
        <Row style={{ background: 'white' }}>
          <div style={{ padding: '10px 0' }}>
            <BlueprintNavigation
              totalCount={emailTemplateList.length}
              currentPage={currentPage}
              pageSize={pageSize}
              onPaginate={setCurrentPage}
              disabled={searchKey.length > 0}
            />
          </div>
        </Row>
      </>
    );
  };

  return (
    <Col span={24} style={{ background: 'white', padding: '0 15px' }}>
      {renderEmailTemplates()}
    </Col>
  );
};

const mapState = (state: any) => ({
  formReducer: state.formReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(EmailTemplateManagerView);
