import AddressDetailView from 'src/core/modules/CrmModule/views/AddressDetailView';
import ApplicationForPaymentDetailView from '@netomnia/modules/ProjectModule/views/ApplicationForPaymentDetailView';
import BillingAdjustmentDetailView from '@core/modules/OrderModule/views/BillingAdjustmentDetailView';
import BillingRequestDetailView from '@core/modules/BillingModule/views/BillingRequestDetailView';
import CaseDetailView from '@core/modules/SupportModule/views/CaseDetailView';
import ChurnRequestDetailView from '@core/modules/OrderModule/views/ChurnRequestDetailView';
import ContactDetailView from 'src/core/modules/CrmModule/views/ContactDetailView';
import CreditNoteDetailView from '@core/modules/BillingModule/views/CreditNoteDetailView';
import CrmDataSetDetailView from 'src/core/modules/CrmModule/components/DataSet/CrmDataSetDetailView';
import DefaultDetailView from 'src/core/views/DefaultDetailView';
import ExchangeDetailView from '@netomnia/modules/ProjectModule/views/ExchangeDetailView';
import FeatureDetailView from '@netomnia/modules/ProjectModule/views/FeatureDetailView';
import FeaturePriceEntryDetailView from '@netomnia/modules/ProjectModule/views/FeaturePriceEntryDetailView';
import FileDetailView from '@core/modules/FileModule/views/FileDetailView';
import GanttPortfolioDetailView from '@core/modules/ProjectModule/views/GanttPortfolioDetailView';
import GanttProgramDetailView from '@core/modules/ProjectModule/views/GanttProgramDetailView';
import GanttProjectDetailView from '@core/modules/ProjectModule/views/GanttProjectDetailView';
import InvoiceDetailView from '@core/modules/BillingModule/views/InvoiceDetailView';
import InvoiceItemDetailView from '@core/modules/BillingModule/views/InvoiceItemDetailView';
import KnowledgeArticleDetailView from '@core/modules/SupportModule/views/KnowledgeArticleDetailView';
import LeadDetailView from 'src/core/modules/CrmModule/views/LeadDetailView';
import NetomniaAccountDetailView from '@netomnia/modules/CrmModule/NetomniaAccountDetailView';
import OrderDetailView from '@core/modules/OrderModule/views/OrderDetailView';
import PICRequestDetailView from '@core/modules/OrderModule/views/PICRequestDetailView';
import PaymentMethodDetailView from '@core/modules/BillingModule/views/PaymentMethodDetailView';
import PaymentMethodRefundDetailView from '@core/modules/BillingModule/views/PaymentMethodRefundDetailView';
import PaymentNoticeDetailView from '@netomnia/modules/ProjectModule/views/PaymentNoticeDetailView';
import PermissionToFailDetailView from '@core/modules/FieldServiceModule/views/PermissionToFailDetailView';
import ProgramDetailView from '@netomnia/modules/ProjectModule/views/ProgramDetailView';
import ProjectDetailView from '@netomnia/modules/ProjectModule/views/ProjectDetailView';
import RegionDetailView from '@netomnia/modules/ProjectModule/views/RegionDetailView';
import ServiceAppointmentConfigDetailView from '@core/modules/FieldServiceModule/views/ServiceAppointmentConfigDetailView';
import TransactionDetailView from '@core/modules/BillingModule/views/TransactionDetailView';
import UserDetailView from '@core/modules/IdentityModule/views/UserDetailView';
import VisitDetailView from 'src/core/modules/CrmModule/views/VisitDetailView';
import WorkListDetailView from '@netomnia/modules/ProjectModule/views/WorkListDetailView';
import WorkOrderDetailView from '@core/modules/FieldServiceModule/views/WorkOrderDetailView';
import { DetailViewContext } from '@core/components/DetailViewContextProvider';
import { DownloadRecordPdf } from '@core/components/DownloadRecordPdf';
import { FC, useContext } from 'react';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';

const {
  CONTACT,
  LEAD,
  ACCOUNT,
  ADDRESS,
  VISIT,
  INVOICE,
  BILLING_ADJUSTMENT,
  ORDER,
  BILLING_REQUEST,
  CREDIT_NOTE,
  INVOICE_ITEM,
  PAYMENT_METHOD,
  TRANSACTION,
  SERVICE_APPOINTMENT_CONFIG,
  WORK_ORDER,
  FILE,
  EXCHANGE,
  PROGRAM,
  PROJECT,
  REGION,
  FEATURE,
} = SchemaModuleEntityTypeEnums;

const CASE = 'Case';
const USER = 'User';
const CRM_DATASET = 'CrmDataset';
const PAYMENT_METHOD_REFUND = 'PaymentMethodRefund';
const APPLICATION_FOR_PAYMENT = 'ApplicationForPayment';
const FEATURE_PRICE_ENTRY = 'FeaturePriceEntry';
const PAYMENT_NOTICE = 'PaymentNotice';
const WORK_LIST = 'WorkList';
const GANTT_PORTFOLIO = 'GanttPortfolio';
const GANTT_PROGRAM = 'GanttProgram';
const GANTT_PROJECT = 'GanttProject';
const KNOWLEDGE_ARTICLE = 'KnowledgeArticle';
const CHURN_REQUEST = 'ChurnRequest';
const PIC_REQUEST = 'PICRequest';
const PERMISSION_TO_FAIL = 'PermissionToFail';

const NetomniaDetailViewRouter: FC = () => {
  const { schema } = useContext(DetailViewContext);

  const detailViewController = () => {
    if (schema) {
      const { entityName } = schema;

      switch (entityName) {
        // CRM MODULE
        case ACCOUNT:
          return <NetomniaAccountDetailView />;
        case ADDRESS:
          return <AddressDetailView />;
        case CONTACT:
          return <ContactDetailView />;
        case LEAD:
          return <LeadDetailView />;
        case VISIT:
          return <VisitDetailView />;
        case CRM_DATASET:
          return <CrmDataSetDetailView />;
        // BILLING MODULE
        case INVOICE:
          return <InvoiceDetailView />;
        case BILLING_REQUEST:
          return <BillingRequestDetailView />;
        case CREDIT_NOTE:
          return <CreditNoteDetailView />;
        case INVOICE_ITEM:
          return <InvoiceItemDetailView />;
        case PAYMENT_METHOD:
          return <PaymentMethodDetailView />;
        case PAYMENT_METHOD_REFUND:
          return <PaymentMethodRefundDetailView />;
        case TRANSACTION:
          return <TransactionDetailView />;
        // FIELD SERVICE MODULE
        case SERVICE_APPOINTMENT_CONFIG:
          return <ServiceAppointmentConfigDetailView />;
        case WORK_ORDER:
          return <WorkOrderDetailView isQuickView />;
        case PERMISSION_TO_FAIL:
          return <PermissionToFailDetailView />;
        // ORDER MODULE
        case ORDER:
          return <OrderDetailView />;
        case BILLING_ADJUSTMENT:
          return <BillingAdjustmentDetailView />;
        case CHURN_REQUEST:
          return <ChurnRequestDetailView />;
        case PIC_REQUEST:
          return <PICRequestDetailView />;
        // PROJECT MODULE
        case APPLICATION_FOR_PAYMENT:
          return <ApplicationForPaymentDetailView />;
        case EXCHANGE:
          return <ExchangeDetailView />;
        case FEATURE_PRICE_ENTRY:
          return <FeaturePriceEntryDetailView />;
        case PAYMENT_NOTICE:
          return <PaymentNoticeDetailView />;
        case PROGRAM:
          return <ProgramDetailView />;
        case PROJECT:
          return <ProjectDetailView />;
        case REGION:
          return <RegionDetailView />;
        case WORK_LIST:
          return <WorkListDetailView />;
        case FEATURE:
          return <FeatureDetailView isQuickView />;
        case GANTT_PORTFOLIO:
          return <GanttPortfolioDetailView />;
        case GANTT_PROGRAM:
          return <GanttProgramDetailView />;
        case GANTT_PROJECT:
          return <GanttProjectDetailView />;
        // SCHEMA MODULE
        case FILE:
          return <FileDetailView />;
        // IDENTITY MODULE
        case USER:
          return <UserDetailView />;
        // SUPPORT MODULE
        case CASE:
          return <CaseDetailView />;
        case KNOWLEDGE_ARTICLE:
          return <KnowledgeArticleDetailView />;
        // DEFAULT DETAIL VIEW
        default:
          return <DefaultDetailView showCollaborators headerExtras={[<DownloadRecordPdf />]} />;
      }
    } else return <></>;
  };

  return detailViewController();
};
export default NetomniaDetailViewRouter;
