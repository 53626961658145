import React, { FC, useContext } from 'react';
import { Col, Row } from 'antd';
import { DetailViewContext } from '../../../../components/DetailViewContextProvider';
import HeaderDetailView from '../../../../components/HeaderDetailView';
import RecordStageSequentialPipeline_V2 from '../../../../components/RecordStageSequentialPipeline';
import AssociationDataTable from 'src/core/components/AssociationDataTable';
import SchemaActionPageHeader from '@core/components/SchemaActions/SchemaActionPageHeader';

const GanttProgramDetailView: FC = () => {
  const { record, schema, pipeline } = useContext(DetailViewContext);

  return (
    <Row style={{ marginTop: 1 }}>
      <Col span={24}>
        <HeaderDetailView
          record={record!}
          extra={[
            <SchemaActionPageHeader
              record={record!}
              moduleName="ProjectModule"
              entityName="GanttProgram"
              launcherType="INLINE"
            />,
          ]}
        />

        {record?.stage && pipeline?.isSequential && (
          <RecordStageSequentialPipeline_V2
            className="record-pipeline"
            record={record}
            pipeline={pipeline}
          />
        )}
      </Col>

      <Col xs={24} style={{ padding: 15 }}>
        <AssociationDataTable
          record={record!}
          moduleName="ProjectModule"
          entityName="GanttProject"
        />
      </Col>
    </Row>
  );
};

export default GanttProgramDetailView;
