import { Button, Collapse, Divider, EntityTitle, Icon, Section, Tag } from '@blueprintjs/core';
import { getOdinSchemaById } from '@core/helpers/schemaHelpers';
import SchemaColumnUpdateDrawer from '@core/modules/ControlPanelModule/components/SchemaManager/SchemaDetailsView/SchemaDetails/PropertiesSection/SchemaColumnUpdateDrawer';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { EditUser, editUserRequest } from '@redux/stores/identityUser/actions';
import { displayMessage } from '@redux/stores/messages/reducers';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

interface Props {
  column: any;
  alertMessage: (params: { body: string; type: string }) => void;
  setSelectedColumn: (column: SchemaColumnEntity) => void;
}

const ColumnDetails: FC<Props> = (props: Props) => {
  const { column, alertMessage, setSelectedColumn } = props;

  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);
  const [isShowingSchemaActionDetails, setIsShowingSchemaActionDetails] = useState<boolean>(true);
  const [isShowingSchemaDetails, setIsShowingSchemaDetails] = useState<boolean>(true);
  const [copiedTextToClipboard, setCopiedTextToClipboard] = useState<boolean>(false);
  const [isLoadingSchema, setIsLoadingSchema] = useState<boolean>(false);
  const [selectedColumnId, setSelectedColumnId] = useState<string>('');

  // On component mount, fetch schema for the schema action
  useEffect(() => {
    if (column && column.schemaId) {
      setSchema(undefined);
      getSchemaForColumn(column.schemaId);
    }
  }, [column]);

  const getSchemaForColumn = async (schemaId: string) => {
    try {
      setIsLoadingSchema(true);
      const res = await getOdinSchemaById(schemaId);
      setSchema(res);
      setIsLoadingSchema(false);
    } catch (e: any) {
      setIsLoadingSchema(false);
      alertMessage({ body: e.message, type: 'error' });
    }
  };

  const copyTextToClipboard = (text: any) => {
    navigator.clipboard.writeText(text);
    setCopiedTextToClipboard(true);
    setTimeout(() => {
      setCopiedTextToClipboard(false);
    }, 1500);
  };

  return (
    <>
      {schema && column && (
        <SchemaColumnUpdateDrawer
          schema={schema}
          schemaColumnId={selectedColumnId}
          onClose={() => {
            setSelectedColumnId('');
          }}
          onUpdate={(column: SchemaColumnEntity) => {
            setSelectedColumn(column);
            setSelectedColumnId('');
          }}
        />
      )}

      <Row style={{ padding: 15 }}>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h3 style={{ marginTop: 0, marginBottom: 5, lineBreak: 'anywhere' }}>
                {column?.name}
              </h3>
              {/* <span>{column?.description}</span> */}
            </Col>
            <Col>
              <Tag intent="primary" minimal round>
                {column.type}
              </Tag>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col span={24}>
              <span>
                <span>{column?.description}</span>
              </span>
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ marginTop: 20, marginBottom: 0 }}>
          <Button
            fill
            text="Edit"
            icon="annotation"
            onClick={() => setSelectedColumnId(column.id)}
          />
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Column Action Details */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Property Details"
            rightElement={
              <Button
                minimal
                small
                text={isShowingSchemaActionDetails ? 'Hide' : 'Show'}
                intent="primary"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setIsShowingSchemaActionDetails(!isShowingSchemaActionDetails);
                }}
              />
            }
          >
            <Row>
              <Col span={24}>
                <Collapse isOpen={isShowingSchemaActionDetails}>
                  <Row>
                    {/* Label */}
                    <Col span={24} style={{ marginTop: 5 }}>
                      <EntityTitle title="Label" subtitle={<span>{column.label || 'None'}</span>} />
                    </Col>

                    <Col span={12} style={{ marginTop: 15 }}>
                      <EntityTitle title="Type" subtitle={<span>{column.type || 'None'}</span>} />
                    </Col>

                    <Col span={12} style={{ marginTop: 15 }}>
                      <EntityTitle
                        title="Position"
                        subtitle={<span>{column.position || 'None'}</span>}
                      />
                    </Col>

                    {/* Is Title */}
                    <Col span={12} style={{ marginTop: 15 }}>
                      <EntityTitle
                        title="Is Title"
                        subtitle={
                          column.isTitleColumn ? (
                            <Icon icon="tick" color="#32A467" />
                          ) : (
                            <Icon icon="cross" color="#ABB3BF" />
                          )
                        }
                      />
                    </Col>

                    {/* Is Tracked */}
                    <Col span={12} style={{ marginTop: 15 }}>
                      <EntityTitle
                        title="Is Tracked"
                        subtitle={
                          column.isTrackedColumn ? (
                            <Icon icon="tick" color="#32A467" />
                          ) : (
                            <Icon icon="cross" color="#ABB3BF" />
                          )
                        }
                      />
                    </Col>

                    {/* Is Encrypted */}
                    <Col span={12} style={{ marginTop: 15 }}>
                      <EntityTitle
                        title="Is Encrypted"
                        subtitle={
                          column.pii ? (
                            <Icon icon="tick" color="#32A467" />
                          ) : (
                            <Icon icon="cross" color="#ABB3BF" />
                          )
                        }
                      />
                    </Col>

                    {/* Is Searchable */}
                    <Col span={12} style={{ marginTop: 15 }}>
                      <EntityTitle
                        title="Is Searchable"
                        subtitle={
                          column.isSearchable ? (
                            <Icon icon="tick" color="#32A467" />
                          ) : (
                            <Icon icon="cross" color="#ABB3BF" />
                          )
                        }
                      />
                    </Col>

                    {/* Is Visible in Tables */}
                    <Col span={12} style={{ marginTop: 15 }}>
                      <EntityTitle
                        title="Is Visible in Tables"
                        subtitle={
                          column.isVisibleInTables ? (
                            <Icon icon="tick" color="#32A467" />
                          ) : (
                            <Icon icon="cross" color="#ABB3BF" />
                          )
                        }
                      />
                    </Col>

                    {/* Is Hidden */}
                    <Col span={12} style={{ marginTop: 15 }}>
                      <EntityTitle
                        title="Is Hidden"
                        subtitle={
                          column.isHidden ? (
                            <Icon icon="tick" color="#32A467" />
                          ) : (
                            <Icon icon="cross" color="#ABB3BF" />
                          )
                        }
                      />
                    </Col>

                    {/* Updated At */}
                    <Col span={12} style={{ marginTop: 15 }}>
                      <EntityTitle
                        title="Updated At"
                        subtitle={
                          <span>
                            {dayjs(column?.updatedAt).format('DD/MM/YYYY HH:mm') || 'None'}
                          </span>
                        }
                      />
                    </Col>

                    {/* Created At */}
                    <Col span={12} style={{ marginTop: 15, marginBottom: 20 }}>
                      <EntityTitle
                        title="Created At"
                        subtitle={
                          <span>
                            {dayjs(column?.createdAt).format('DD/MM/YYYY HH:mm') || 'None'}
                          </span>
                        }
                      />
                    </Col>
                  </Row>
                </Collapse>
              </Col>
            </Row>
          </Section>
        </Col>

        <Col span={24} style={{ margin: 0 }}>
          <Divider />
        </Col>

        {/* Schema Details */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Schema Details"
            rightElement={
              <Button
                minimal
                small
                text={isShowingSchemaDetails ? 'Hide' : 'Show'}
                intent="primary"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setIsShowingSchemaDetails(!isShowingSchemaDetails);
                }}
              />
            }
          >
            <Row>
              <Col span={24}>
                <Collapse isOpen={isShowingSchemaDetails}>
                  <Row>
                    {/* Entity Name */}
                    <Col span={24} style={{ marginTop: 5 }}>
                      <EntityTitle
                        loading={isLoadingSchema}
                        title="Entity Name"
                        subtitle={
                          <Link
                            to={`/ControlPanelModule/SchemaManager/${schema?.id}`}
                            target="_blank"
                          >
                            {schema?.entityName || '...'}
                          </Link>
                        }
                      />
                    </Col>

                    {/* Module Name */}
                    <Col span={24} style={{ marginTop: 15 }}>
                      <EntityTitle
                        loading={isLoadingSchema}
                        title="Module Name"
                        subtitle={<span>{schema?.moduleName || '...'}</span>}
                      />
                    </Col>
                  </Row>
                </Collapse>
              </Col>
            </Row>
          </Section>
        </Col>
      </Row>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  editUser: (params: EditUser, cb: any) => dispatch(editUserRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(ColumnDetails);
