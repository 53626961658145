import { AppstoreOutlined, CaretDownOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { getBrowserPath } from '@core/helpers/recordHelpers';
import { getSchemaFromShortListByModuleAndEntity, sortOptions } from '@core/helpers/schemaHelpers';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Image,
  Menu,
  message,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PDFModalViewer from '../PDFModalViewer';
import InlineEditableField from '../InlineEditableField';
import {
  deleteRecordByIdRequest,
  IUpdateRecordById,
  updateRecordByIdRequest,
} from '../../../redux/stores/records/actions';
import './styles.scss';

const { Option } = Select;

interface Props {
  schemaReducer: any;
  files: any;
  dataSource: any;
  columns: any;
  thumbnailSize?: number;
  hideViewOptions?: boolean;
  updateRecord: (params: IUpdateRecordById, cb?: any) => void;
  fileView?: 'thumbnails' | 'list';
  editableFileFields?: Array<string>;
  showFileCategoryForType?: string | undefined;
  deleteRecord: any;
}

interface State {
  view: 'list' | 'thumbnails';
  pdfModalVisible: boolean;
  pdfFile: any;
}

const truncateTitlesAtChar = 40;

class FileManagerOverview extends React.Component<Props, State> {
  private thumbnailRef: React.RefObject<HTMLInputElement>;
  private thumbnailSize: number | undefined;

  constructor(props: Props) {
    super(props);
    this.thumbnailSize = undefined;
    this.thumbnailRef = React.createRef();
    this.state = {
      view: this.props.fileView ? this.props.fileView : 'thumbnails',
      pdfFile: '',
      pdfModalVisible: false,
    };
  }

  getS3BucketUrl = (record: DbRecordEntityTransform) => {
    return `https://${getProperty(record, 'Bucket')}.s3.eu-west-2.amazonaws.com/${encodeURIComponent(getProperty(record, 'Key'))}`;
  };

  // Calculate thumbnail container and resize the thumb to 1:1 ratio.
  setThumbnailSize = () => {
    if (this.thumbnailRef?.current) {
      this.thumbnailSize = this.thumbnailRef?.current?.clientWidth;
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.setThumbnailSize);
    this.setThumbnailSize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setThumbnailSize);
  }

  // Update file view when the prop is updated
  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.fileView != this.props.fileView) {
      this.setState({ view: this.props.fileView! });
    }

    if (this.thumbnailRef.current) {
      this.setThumbnailSize();
    }

    if (prevProps.files.length !== this.props.files.length) {
      this.setThumbnailSize();
    }
  }

  copyText = (text: string) => {
    message.success('URL copied to Clipboard');
    navigator.clipboard.writeText(text);
  };

  public updateRecordCategoryOnChange(
    record: DbRecordEntityTransform,
    params: { category: string },
  ) {
    const { updateRecord, schemaReducer } = this.props;

    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      SchemaModuleTypeEnums.SCHEMA_MODULE,
      SchemaModuleEntityTypeEnums.FILE,
    );

    if (schema) {
      // update the record
      return updateRecord(
        {
          schema: schema,
          recordId: record.id,
          createUpdate: {
            entity: record.entity,
            properties: {
              Category: params?.category,
            },
          },
        },
        (res: DbRecordEntityTransform) => {
          if (res) {
          }
        },
      );
    }
  }

  public renderFileCategoryField(record: DbRecordEntityTransform) {
    const { schemaReducer } = this.props;

    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      SchemaModuleTypeEnums.SCHEMA_MODULE,
      SchemaModuleEntityTypeEnums.FILE,
    );

    if (schema && schema.columns) {
      const column = schema?.columns?.find((elem) => elem.name === 'Category');

      return (
        <Col span={24}>
          <Row style={{ marginBottom: 10 }}>
            <Col span={24} style={{ paddingBottom: 5, fontWeight: 500 }}>
              <span>Category</span>
            </Col>

            <Col span={24}>
              <Select
                allowClear
                key={column?.id}
                value={getProperty(record, 'Category')}
                defaultValue={
                  !!column?.defaultValue ? column?.defaultValue : `select ${column?.label}`
                }
                style={{ width: '100%', marginBottom: 8 }}
                onChange={(val, option) =>
                  this.updateRecordCategoryOnChange(record, { category: val })
                }
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {column?.options ? (
                  column?.options.sort(sortOptions).map((opt) => (
                    <Option value={opt.value} key={opt.label}>
                      {opt.label}
                    </Option>
                  ))
                ) : (
                  <Option value="">no options</Option>
                )}
              </Select>
            </Col>
          </Row>
        </Col>
      );
    }
  }

  public renderEditableFields(record: DbRecordEntityTransform) {
    const { schemaReducer, editableFileFields } = this.props;

    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      SchemaModuleTypeEnums.SCHEMA_MODULE,
      SchemaModuleEntityTypeEnums.FILE,
    );

    if (schema && schema.columns && editableFileFields && editableFileFields.length > 0 && record) {
      const matchedColumns = schema?.columns?.filter((elem: SchemaColumnEntity) =>
        editableFileFields.includes(elem.name),
      );

      if (matchedColumns && matchedColumns?.length > 0) {
        return matchedColumns?.map((column: SchemaColumnEntity) => {
          return (
            <Col span={24} style={{ marginTop: 5, marginBottom: 10 }}>
              <InlineEditableField record={record} schema={schema} column={column} />
            </Col>
          );
        });
      } else {
        return <></>;
      }
    }
  }

  renderList() {
    return (
      <Col span={24}>
        <Table
          size="small"
          tableLayout="auto"
          columns={this.props.columns}
          dataSource={this.props.dataSource}
        />
      </Col>
    );
  }

  showPDFViewer = (record: DbRecordEntityTransform) => {
    this.setState({ pdfFile: this.getS3BucketUrl(record) }, () => {
      this.setState({ pdfModalVisible: true });
    });
  };

  public hidePDFViewer() {
    if (this.state.pdfModalVisible) {
      this.setState({ pdfModalVisible: false });
    }
  }

  private deleteFile(recordId: string) {
    const { schemaReducer, deleteRecord } = this.props;

    const fileSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      SchemaModuleTypeEnums.SCHEMA_MODULE,
      SchemaModuleEntityTypeEnums.FILE,
    );

    return deleteRecord({
      schema: fileSchema,
      recordId: recordId,
    });
  }

  renderThumbnailPreview = (record: any) => {
    const mimeType = getProperty(record, 'Mimetype');
    const extension = getProperty(record, 'Url')?.split('.').pop() || 'Unknown';

    if (record && mimeType) {
      if (mimeType.indexOf('image') > -1) {
        return (
          <Image
            src={this.getS3BucketUrl(record)}
            style={{
              borderRadius: 0,
              height: this.thumbnailSize,
              width: this.thumbnailSize,
            }}
          />
        );
      } else if (mimeType.indexOf('pdf') > -1) {
        return (
          <div onClick={() => this.showPDFViewer(record)} style={{ cursor: 'pointer' }}>
            <Row
              align="middle"
              style={{
                backgroundColor: '#F32929',
                height: this.thumbnailSize,
                width: this.thumbnailSize,
              }}
            >
              <Col
                span={24}
                style={{
                  textAlign: 'center',
                  backgroundColor: 'transparent',
                  overflowX: 'auto',
                }}
              >
                <Col
                  span={24}
                  style={{
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                    overflowX: 'auto',
                  }}
                />
                <i className="bi-filetype-pdf" style={{ fontSize: '3em', color: 'white' }} />
                <br />
              </Col>
            </Row>
          </div>
        );
      } else {
        return (
          <Row
            align="middle"
            justify={'center'}
            style={{
              height: this.thumbnailSize,
              width: this.thumbnailSize,
              background: '#2D71D2',
              cursor: 'pointer',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            onClick={() => window.open(getProperty(record, 'Url'), '_blank')}
          >
            <Col>
              <i className="bi bi-file-earmark" style={{ fontSize: '3em', color: 'white' }} />
              <br />
              <span
                style={{
                  color: 'white',
                }}
              >
                {extension}
              </span>
            </Col>
          </Row>
        );
      }
    }
  };

  renderThumbnail(record: any, thumbnailSize: number, index: number) {
    const { showFileCategoryForType } = this.props;
    return (
      <Col key={index} span={isMobile ? '24' : thumbnailSize} style={{ padding: '5px 5px' }}>
        <Card
          size="small"
          className="filePreviewCard"
          title={
            <Row ref={this.thumbnailRef} style={{ width: '100%' }}>
              <Col span={18} style={{ paddingTop: 3 }}>
                <Link target="_blank" to={getBrowserPath(record)}>
                  #{record.recordNumber}
                </Link>
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <Menu>
                      {/* Copy URL to Clipboard */}
                      <Menu.Item
                        key="copyClipboard"
                        onClick={() => this.copyText(this.getS3BucketUrl(record))}
                      >
                        Copy URL to Clipboard
                      </Menu.Item>

                      {/* Download File */}
                      <Menu.Item key="downloadFile">
                        <a href={getProperty(record, 'Url')} target="_blank" rel="noreferrer">
                          Download File
                        </a>
                      </Menu.Item>

                      {/* Delete File */}
                      <Popconfirm
                        title="Are you sure you want to delete the file?"
                        onConfirm={() => this.deleteFile(record?.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Menu.Item style={{ color: 'red' }} key="deleteFile">
                          Delete File
                        </Menu.Item>
                      </Popconfirm>
                    </Menu>
                  }
                >
                  <Button icon={<CaretDownOutlined style={{ fontSize: '0.9em' }} />} size="small" />
                </Dropdown>
              </Col>
            </Row>
          }
          cover={
            <div style={{ padding: '7px 7px 0px 7px' }}>
              <div style={{ textAlign: 'center', width: '100%' }}>
                <Tooltip title={record?.title!} mouseEnterDelay={0.5}>
                  {this.renderThumbnailPreview(record)}
                </Tooltip>
              </div>

              <div style={{ textAlign: 'center', marginBottom: 5 }}>
                <Typography.Text ellipsis style={{ fontSize: 11 }}>
                  {dayjs(record.createdAt).format('DD/MM/YYYY')}
                </Typography.Text>
              </div>
              <Row style={{ padding: 5 }}>
                <Col span={24}>
                  <Row>
                    {/* Show Category Select if the record matches the type passed in the props */}
                    {showFileCategoryForType && showFileCategoryForType === record.type ? (
                      this.renderFileCategoryField(record)
                    ) : (
                      <></>
                    )}
                    {/* Editable fields */}
                    {this.props.editableFileFields &&
                    this.props.editableFileFields?.length > 0 &&
                    record ? (
                      this.renderEditableFields(record)
                    ) : (
                      <></>
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
          }
        />
      </Col>
    );
  }

  render() {
    const { files, thumbnailSize, hideViewOptions } = this.props;

    return (
      <div>
        <PDFModalViewer
          isModalVisible={this.state.pdfModalVisible}
          file={this.state.pdfFile}
          togglePDFModal={this.hidePDFViewer.bind(this)}
        />

        {!hideViewOptions && (
          <Divider style={{ marginTop: '10px' }}>
            <Row style={{ marginTop: '10px', padding: '15px' }}>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Tooltip placement="left" title="Thumbnail">
                  <Button
                    type={this.state.view === 'thumbnails' ? 'primary' : 'default'}
                    size="large"
                    icon={<AppstoreOutlined />}
                    style={{ marginRight: '8px' }}
                    onClick={() => this.setState({ view: 'thumbnails' })}
                  />
                </Tooltip>
                <Tooltip placement="right" title="List">
                  <Button
                    type={this.state.view === 'list' ? 'primary' : 'default'}
                    size="large"
                    icon={<UnorderedListOutlined />}
                    onClick={() => this.setState({ view: 'list' })}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Divider>
        )}

        <Image.PreviewGroup>
          <Row>
            {this.state.view === 'thumbnails'
              ? files.map((record: any, index: number) =>
                  this.renderThumbnail(record, this.props.thumbnailSize || 12, index),
                )
              : this.renderList()}
          </Row>
        </Image.PreviewGroup>
      </div>
    );
  }
}

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  updateRecord: (params: IUpdateRecordById, cb: any) =>
    dispatch(updateRecordByIdRequest(params, cb)),
  deleteRecord: (payload: any, cb: any) => dispatch(deleteRecordByIdRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(FileManagerOverview);
