import { httpPost } from '@core/http/requests';
import { CaseChannel } from '@d19n/sandbox-odin-sdk/dist/entities-v2/Case';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import DashboardBarChart, { DataItem } from './DashboardBarChart';

type Props = {
  refreshBit: 0 | 1;
};
const OngoingChatsLengthChart = (props: Props) => {
  const [chatLengthMetrics, setChatLengthMetrics] = useState<DataItem[]>([]);

  const getTopOngoingChats = async () => {
    const result = await httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Channel',
            operator: 'eq',
            value: CaseChannel.WEB_CHAT,
          },
          {
            columnName: 'OwnerId',
            operator: 'notNull',
            value: '',
          },
          {
            columnName: 'ConversationStatus',
            operator: 'eq',
            value: 'Created',
          },
          {
            columnName: 'StageName',
            operator: 'not in',
            value: ['Solved'],
          },
        ],
        sort: {
          createdAt: {
            order: 'desc',
          },
        },
        returnProperties: [],
        pageSize: 100,
      },
    });

    const chatLength: any[] = [];
    const chatConcurrency: { [id: string]: number } = {};

    result.data.data.records.forEach((item: any) => {
      const agentName = item.caseOwner?.[0]?.name;
      if (agentName) {
        chatLength.push({
          name: agentName,
          value: dayjs().diff(dayjs(item.createdAt), 'minute'),
        });
        chatConcurrency[agentName] = chatConcurrency[agentName] || 0;
        chatConcurrency[agentName] += 1;
      }
    });
    setChatLengthMetrics(chatLength);
  };

  useEffect(() => {
    getTopOngoingChats();
  }, [props.refreshBit]);

  return (
    <DashboardBarChart
      title="Ongoing Chat Length (mins)"
      orderByValue="desc"
      data={chatLengthMetrics}
    />
  );
};

export default OngoingChatsLengthChart;
