export const DefaultViewConfigTemplate = () => {
  return {
    details: [
      {
        title: 'Enter your title here',
        visibleProperties: [{ name: 'Enter property name here' }],
      },
    ],
  };
};
