import { Button, Card, NonIdealState, Section, Tag, Tooltip } from '@blueprintjs/core';
import { Badge, Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { INavigationReducer } from '../../../../redux/stores/navigation/reducer';
import { ISchemaReducer } from '../../../../redux/stores/schemas/reducer';
import {
  IOpenRecordDrawer,
  openRecordDrawer,
} from '../../../../redux/stores/userInterface/actions';
import NoteFeed from '../../../components/NoteFeed';
import { httpDelete, httpPost } from '../../../http/requests';
import { getInboxViewportHeight } from '../helpers';
import { InboxContext } from '../index';
import { TMention } from '../store/types';
import './styles.scss';

interface Props {
  navigationReducer: INavigationReducer;
  schemaReducer: ISchemaReducer;
  userReducer: any;
  openDrawer: (params: IOpenRecordDrawer) => void;
  mentions: TMention[];
}

const InboxMentionsList: React.FC<Props> = (props: Props) => {
  const { navigationReducer, openDrawer, mentions } = props;
  const { state, setSelectedMention, toggleMentionReadStatus } = useContext(InboxContext);

  const selectedMention = state.selectedMention;

  const isMentionSelected = (mention: TMention) => {
    if (selectedMention) {
      return mention.id === selectedMention!.id;
    } else {
      return false;
    }
  };

  const markMentionAsReadOrUnread = (mention: TMention) => {
    // Mark as unread
    if (mention.metaData?.read) {
      httpDelete(`AuditModule/v1.0/UserAudit/byRecordId/${mention.id}`).then(() => {
        toggleMentionReadStatus(mention.id);
      });
    }
    // Mark as read
    else {
      httpPost(`AuditModule/v1.0/UserAudit/batch`, [
        {
          type: 'DB_RECORD_VIEWED',
          recordId: mention.id,
          data: { viewedFrom: 'Feed' },
        },
      ]).then((res: any) => {
        toggleMentionReadStatus(mention.id);
      });
    }
  };

  const generateMentionShortOverview = (JSONContents: any[]) => {
    let accumulatedNoteContents: string[] = [];
    if (!JSONContents || JSONContents?.length! < 1) {
    } else if (JSONContents?.length! > 0) {
      JSONContents.map((content: any) => {
        content.children.map((child: any) => {
          if (child.text?.length! > 1) {
            accumulatedNoteContents.push(child.text);
          }
        });
      });
    }
    let shortPreview: string = accumulatedNoteContents.join(' ');
    if (shortPreview.length > 100 && !selectedMention) {
      shortPreview = shortPreview.substring(0, 100) + '...';
    } else if (shortPreview.length > 40 && selectedMention) {
      shortPreview = shortPreview.substring(0, 40) + '...';
    }
    return shortPreview;
  };

  return (
    <Row>
      <Col
        span={selectedMention ? 14 : 24}
        onClick={() => {
          if (selectedMention) {
            setSelectedMention(undefined);
          }
        }}
      >
        <Row>
          {
            // If there are no mentions, show a message
            mentions.length === 0 && (
              <Col span={24} style={{ textAlign: 'center', padding: 20 }}>
                <span>No mentions to show</span>
              </Col>
            )
          }

          {/* Render Mentions */}
          {mentions.length > 0 &&
            mentions.map((mention: TMention, i: number) => (
              <Col
                key={i}
                span={24}
                className={`inboxListRow ${isMentionSelected(mention) ? 'selected' : ''} ${
                  mention.metaData?.read ? 'read' : ''
                }`}
                onClick={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSelectedMention(mention.id);
                }}
              >
                <Card compact elevation={0} key={i}>
                  <Row align="middle">
                    <Col span={selectedMention ? 14 : 16}>
                      <Row key={i}>
                        <Col>
                          {!mention.metaData?.read && (
                            <Badge color="blue" style={{ marginRight: 10 }} />
                          )}
                          <span
                            style={{
                              fontWeight: mention.metaData?.read ? 400 : 600,
                              marginRight: 15,
                            }}
                          >
                            {mention.createdBy?.fullName}
                          </span>
                        </Col>
                        <Col>
                          <span style={{ opacity: 0.55 }}>
                            {generateMentionShortOverview(mention.properties.JSONContent)}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={selectedMention ? 10 : 8} style={{ textAlign: 'right' }}>
                      <Row align="middle" justify="end">
                        <Col style={{ paddingRight: 10 }}>
                          <Tag intent="primary" minimal round>
                            {mention.linkedRecord?.recordNumber || 'Unknown'}
                          </Tag>
                        </Col>
                        <Col style={{ paddingRight: 10 }}>
                          <span className="dateTime">
                            {dayjs(mention.updatedAt).format('DD/MM/YYYY HH:mm')}
                          </span>
                        </Col>
                        <Col>
                          <Tooltip content="Mark as Read/Unread" hoverOpenDelay={1000}>
                            <Button
                              onClick={(e: any) => {
                                e.stopPropagation();
                                markMentionAsReadOrUnread(mention);
                              }}
                              icon={
                                mention.metaData?.read ? (
                                  <i
                                    className="bi bi-envelope-open-fill"
                                    style={{ opacity: 0.7 }}
                                  />
                                ) : (
                                  <i className="bi bi-envelope-fill" style={{ opacity: 0.7 }} />
                                )
                              }
                            />
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
        </Row>
      </Col>
      {selectedMention && (
        <Col span={10}>
          <Section
            title="Message Preview"
            rightElement={
              <>
                {selectedMention?.linkedRecord && (
                  <Button
                    text={selectedMention.linkedRecord.recordNumber}
                    onClick={() =>
                      openDrawer({
                        recordId: selectedMention.linkedRecord?.id,
                        moduleName: selectedMention.linkedRecord?.entity?.split(':')[0] || '',
                        entityName: selectedMention.linkedRecord?.entity?.split(':')[1] || '',
                      })
                    }
                    size="small"
                    icon="panel-stats"
                  />
                )}
                <Button
                  onClick={() => {
                    setSelectedMention(undefined);
                  }}
                  small
                  rightIcon="cross"
                  minimal
                />
              </>
            }
          >
            <div
              style={{
                height: getInboxViewportHeight(navigationReducer.tabHistory!.length! || 0),
                overflowY: 'scroll',
                paddingBottom: 40,
              }}
            >
              <Row>
                <Col span={24} style={{ paddingTop: 8 }}>
                  {/* Cannot show Preview */}
                  {!selectedMention?.linkedRecord && (
                    <div style={{ padding: 50 }}>
                      <NonIdealState
                        icon="search"
                        title={'Cannot show Preview'}
                        description="We could not find a mention to preview. Please select another mention from the list."
                      />
                    </div>
                  )}

                  {/* Can show Preview */}
                  {selectedMention?.linkedRecord && (
                    <div style={{ paddingRight: 15, overflowY: 'scroll' }}>
                      <NoteFeed
                        previewMode
                        hideCreateNoteForm
                        record={selectedMention?.linkedRecord!}
                        showOnlyNoteIds={[
                          selectedMention?.properties?.ParentNoteId || selectedMention.id,
                        ]}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Section>
        </Col>
      )}
    </Row>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
  navigationReducer: state.navigationReducer,
});
const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(InboxMentionsList);
