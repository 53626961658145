import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Descriptions, Divider, Layout, Row, Skeleton } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useReducer, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import {
  getRecordByIdRequest,
  IGetRecordById,
  IUpdateRecordById,
  updateRecordByIdRequest,
} from '../../../../../../redux/stores/records/actions';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '../../../../../../redux/stores/recordAssociations/actions';
import ModuleEntityIcon from 'src/core/components/ModuleEntityIcon';
import { getBrowserPath } from '@core/helpers/recordHelpers';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import { toSentenceCase } from '@core/helpers/stringHelpers';
import { DataSetApprovalStatusesENUM, DataSetTaskStatusesENUM } from '../DataSetWork/types';
import DataSetContentsPanel from './DataSetContentsPanel';
import RecordSearchPanel from './RecordSearchPanel';
import {
  SET_ACTIVE_STATUS_TAB,
  SET_ALL_RECORD_STATUSES,
  SET_DATASET_PARENT_RECORD,
} from './store/constants';
import { dataSetBuilderInitialState, dataSetBuilderReducer } from './store/reducer';
import './styles.scss';
import { PageHeader } from 'src/core/components/PageHeader';
import { Button } from '@blueprintjs/core';

type TProps = RouteComponentProps<any> & {
  match: any;
  history: any;
  getRecordById: Function;
  updateRecord: Function;
  getAssociations: Function;
};

const { CRM_MODULE } = SchemaModuleTypeEnums;

export const DataSetBuilderContext = React.createContext<any>({});

const DataSetBuild: React.FC<TProps> = (props: TProps) => {
  const { getRecordById, match, updateRecord, getAssociations } = props;
  const [associatedRecordSchema, setAssociatedRecordSchema] = useState<SchemaEntity | undefined>(
    undefined,
  );
  const [associatedRecordEntityName, setAssociatedRecordEntityName] = useState<string | undefined>(
    undefined,
  );
  const [dataSetSchema, setDataSetSchema] = useState<SchemaEntity | undefined>(undefined);
  const [isLoadingDataSetSchema, setIsLoadingDataSetSchema] = useState<boolean>(true);
  const [dataSetRecord, setDataSetRecord] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [state, dispatch] = useReducer(dataSetBuilderReducer, dataSetBuilderInitialState);

  // Fetch all necessary schemas.
  useEffect(() => {
    if (!dataSetSchema) {
      getDataSetSchema();
    }
  }, []);

  // When DataSet record is available, construct statuses using the DS_Status (DataSet status) column.
  useEffect(() => {
    if (dataSetRecord && state.allRecordStatuses.length === 0) {
      const dataSetType = getProperty(dataSetRecord, 'Process');

      if (dataSetType) {
        switch (dataSetType) {
          case 'TASK':
            dispatch({
              type: SET_ALL_RECORD_STATUSES,
              payload: Object.keys(DataSetTaskStatusesENUM).map((key: any) => key),
            });
            dispatch({
              type: SET_ACTIVE_STATUS_TAB,
              payload: Object.keys(DataSetTaskStatusesENUM)[0],
            });
            return;
          case 'APPROVAL':
            dispatch({
              type: SET_ALL_RECORD_STATUSES,
              payload: Object.keys(DataSetApprovalStatusesENUM).map((key: any) => key),
            });
            dispatch({
              type: SET_ACTIVE_STATUS_TAB,
              payload: Object.keys(DataSetApprovalStatusesENUM)[0],
            });
            return;
          default:
            return;
        }
      }
    }
  }, [dataSetRecord]);

  // On component mount, wait for CrmDataset Schema and fetch the record
  useEffect(() => {
    if (match.params?.recordId && dataSetSchema) {
      getRecordById(
        {
          schema: dataSetSchema,
          recordId: match.params?.recordId,
        },
        (response: DbRecordEntityTransform) => {
          if (response) {
            setDataSetRecord(response);
            dispatch({ type: SET_DATASET_PARENT_RECORD, payload: response });
          }
        },
      );
    }
  }, [dataSetSchema]);

  const getDataSetSchema = async () => {
    const schema = await getOdinSchemaByEntity(CRM_MODULE, 'CrmDataset');
    setDataSetSchema(schema);
    setIsLoadingDataSetSchema(false);
  };

  // When DataSet Record is fetched, get the associated record schema, according to DataSet type
  useEffect(() => {
    if (dataSetRecord) {
      getAssociatedRecordSchema(dataSetRecord?.type);
    }
  }, [dataSetRecord]);

  // When DataSet record + schema and associated record schema are available, fetch existing
  // record associations, because CrmDataSet can actually have records already.
  useEffect(() => {
    if (dataSetRecord && dataSetSchema && associatedRecordEntityName) {
      dispatch({ type: 'SET_IS_FETCHING_RELATED_RECORDS', payload: true });
      getAssociations(
        {
          recordId: dataSetRecord?.id,
          schema: dataSetSchema,
          entities: [associatedRecordEntityName],
        },
        (res: any) => {
          if (res && res.results?.[associatedRecordEntityName]?.dbRecords?.length > 0) {
            const associations = res.results?.[associatedRecordEntityName]?.dbRecords;
            dispatch({ type: 'SET_IS_FETCHING_RELATED_RECORDS', payload: false });
            dispatch({ type: 'ADD_ASSOCIATED_RECORDS_TO_DATASET', payload: associations });
          } else {
            dispatch({ type: 'SET_IS_FETCHING_RELATED_RECORDS', payload: false });
          }
        },
      );
    }
  }, [dataSetRecord, dataSetSchema, associatedRecordEntityName]);

  // Get associated record schema according to DataSet type, but default to Address if no type provided
  const getAssociatedRecordSchema = async (type: string | undefined) => {
    let associatedEntityName = type ? toSentenceCase(type) : 'Address';
    setAssociatedRecordEntityName(associatedEntityName);
    const schema = await getOdinSchemaByEntity(CRM_MODULE, associatedEntityName);
    setAssociatedRecordSchema(schema);
  };

  const updateDataSet = () => {
    dispatch({ type: 'SET_IS_UPDATING_DATA_SET', payload: true });

    const allDataSetAssociatedRecords = state.dataSetRecords?.map(
      (record: DbRecordEntityTransform) => ({
        entity: record.entity,
        recordId: record.id,
      }),
    );

    updateRecord(
      {
        schema: dataSetSchema,
        recordId: dataSetRecord?.id,
        createUpdate: {
          entity: `${CRM_MODULE}:CrmDataset`,
          associations: allDataSetAssociatedRecords,
        },
      },
      (res: DbRecordEntityTransform) => {
        dispatch({ type: 'SET_IS_UPDATING_DATA_SET', payload: false });
        dispatch({ type: 'SET_IS_DATASET_UPDATED', payload: true });
      },
    );
  };

  return (
    <DataSetBuilderContext.Provider value={{ state, dispatch }}>
      <Layout style={{ padding: 15, marginTop: 5 }}>
        <Row gutter={12}>
          <Col span={24}>
            <PageHeader
              style={{ borderRadius: 6, border: '1px solid #dddbda' }}
              extra={
                <>
                  <Col>
                    <Link to={`/${CRM_MODULE}/CrmDataset/${dataSetRecord?.id}/Work`}>
                      <Button
                        large
                        minimal
                        intent="primary"
                        disabled={!dataSetRecord}
                        icon={
                          <i className="bi bi-box-arrow-in-right" style={{ marginRight: 10 }} />
                        }
                      >
                        Work Mode
                      </Button>
                    </Link>
                  </Col>
                  <Col>
                    <Button
                      large
                      onClick={() => updateDataSet()}
                      intent="primary"
                      disabled={
                        state.dataSetRecords.length === 0 ||
                        !dataSetSchema ||
                        state.isUpdatingDataSet ||
                        !dataSetRecord
                      }
                      loading={state.isUpdatingDataSet}
                    >
                      Update DataSet
                    </Button>
                  </Col>
                </>
              }
              title={
                <Row>
                  <Col style={{ marginTop: 8 }}>
                    <ModuleEntityIcon
                      moduleName={CRM_MODULE}
                      entityName="CrmDataset"
                      style={{ padding: '0px 2px' }}
                    />
                  </Col>
                  <Col style={{ paddingLeft: 8 }}>
                    <Row>
                      <Col span={24}>
                        <span style={{ fontSize: '1.1em' }}>
                          DataSet - Build Mode (
                          <Link to={getBrowserPath(dataSetRecord!)}>
                            {dataSetRecord?.recordNumber}
                          </Link>
                          )
                        </span>
                      </Col>
                      <Col span={24} style={{ lineHeight: '15px', margin: 0 }}>
                        <span style={{ fontSize: '14px', color: '#b9b9b9', fontWeight: 400 }}>
                          {dataSetRecord?.title}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }
              className="dataSetBuilderHeader"
            >
              <Skeleton loading={!dataSetSchema || !dataSetRecord}>
                <Row justify="space-between">
                  <Divider style={{ marginTop: 0 }} />
                  <Col xs={12} md={5}>
                    <Descriptions size="small" column={1}>
                      <Descriptions.Item label="Query From Date">
                        {getProperty(dataSetRecord, 'QueryFromDate')}
                      </Descriptions.Item>
                      <Descriptions.Item label="Query To Date">
                        {getProperty(dataSetRecord, 'QueryToDate')}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  <Col xs={12} md={9}>
                    <Descriptions size="small" column={1}>
                      <Descriptions.Item label="Type">{dataSetRecord?.type}</Descriptions.Item>
                      <Descriptions.Item label="Description">
                        {getProperty(dataSetRecord, 'Description')}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>

                  <Col xs={12} md={4}>
                    <Descriptions size="small" column={1}>
                      <Descriptions.Item label="Created By">
                        {dataSetRecord?.createdBy?.fullName}
                      </Descriptions.Item>
                      <Descriptions.Item label="Created At">
                        {dayjs(dataSetRecord?.createdAt).format('MM/DD/YYYY')}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>

                  <Col xs={12} md={4}>
                    <Descriptions size="small" column={1}>
                      <Descriptions.Item label="Updated By">
                        {dataSetRecord?.updatedBy?.fullName || '-'}
                      </Descriptions.Item>
                      <Descriptions.Item label="Updated At">
                        {dayjs(dataSetRecord?.updatedAt).format('MM/DD/YYYY')}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              </Skeleton>
            </PageHeader>
          </Col>
          <Col span={24} style={{ marginTop: 15, marginBottom: 15 }}>
            <Row gutter={16}>
              {/* Left Panel */}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <RecordSearchPanel schema={associatedRecordSchema} />
              </Col>

              {/* Right Panel */}
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{ marginTop: isMobile ? 20 : 0, height: '100%' }}
              >
                <DataSetContentsPanel />
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    </DataSetBuilderContext.Provider>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
  updateRecord: (params: IUpdateRecordById, cb: any) =>
    dispatch(updateRecordByIdRequest(params, cb)),
});

export default withRouter(connect(mapState, mapDispatch)(DataSetBuild));
