import { call, put, takeEvery } from 'redux-saga/effects';
import { getEntityFromSchema } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { httpGet } from '@core/http/requests';
import { ERROR_NOTIFICATION } from '../../stores/notification/reducers';
import { USER_LOGOUT_REQUEST } from '../../stores/identity/constants';
import { IGetRecordAuditLogs } from './actions';
import {
  GET_DB_RECORD_AUDIT_LOGS_ERROR,
  GET_DB_RECORD_AUDIT_LOGS_REQUEST,
  GET_DB_RECORD_AUDIT_LOGS_SUCCESS,
} from './constants';

function* getRecordAuditLogs(action: {
  params: IGetRecordAuditLogs;
  cb?: (params?: { recordId: string; results: any[] }) => void;
}): any {
  const { recordId, schema, whereQuery, limit, skip, sort, entities } = action.params;

  try {
    let path = `AuditModule/v1.0/UserActivity/record/${recordId}`;

    let queryStringBuilder: string[] = [];
    if (whereQuery) queryStringBuilder.push(`whereQuery=${JSON.stringify(whereQuery)}`);
    if (limit) queryStringBuilder.push(`limit=${limit}`);
    if (skip) queryStringBuilder.push(`skip=${skip}`);
    if (sort) queryStringBuilder.push(`sort=${JSON.stringify(sort)}`);
    if (entities) queryStringBuilder.push(`entities=${JSON.stringify(entities)}`);
    queryStringBuilder.push(`srcEntity=${getEntityFromSchema(schema)}`);
    if (queryStringBuilder.length > 0) {
      path = path + '?' + queryStringBuilder.join('&');
    }

    const res = yield call(async () => await httpGet(path));

    yield put({
      type: GET_DB_RECORD_AUDIT_LOGS_SUCCESS,
      recordId,
      results: res.data,
    });

    if (action.cb) {
      yield call(action.cb, { recordId, results: res.data });
    }
  } catch (e: any) {
    const error = e.response ? e.response?.data : undefined;
    yield put({ type: GET_DB_RECORD_AUDIT_LOGS_ERROR });
    if (!!error && e.response?.data?.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error: e.response?.data });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: e.response?.data });
    }
    if (action.cb) {
      yield call(action.cb, undefined);
    }
  }
}

function* rootSaga() {
  yield takeEvery<any>(GET_DB_RECORD_AUDIT_LOGS_REQUEST, getRecordAuditLogs);
}

export default rootSaga;
