import { httpPost } from '@core/http/requests';
import { CaseChannel } from '@d19n/sandbox-odin-sdk/dist/entities-v2/Case';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import DashboardBarChart, { DataItem } from './DashboardBarChart';

type Props = {
  refreshBit: 0 | 1;
};
const OutOfSlaCategoryChart = (props: Props) => {
  const [chatConcurrencyMetrics, setChatConcurrencyMetrics] = useState<DataItem[]>([]);

  const getChatConcurrencyAggregate = async () => {
    const result = await httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Channel',
            operator: 'not in',
            value: [CaseChannel.WEB_CHAT, CaseChannel.CALL],
          },
          {
            columnName: 'StageName',
            operator: 'in',
            value: ['Open', 'Pending Agent', 'Pending Review'],
          },
          {
            columnName: 'SlaEndDate',
            operator: 'lte',
            value: dayjs().format('YYYY-MM-DD'),
          },
        ],
        returnProperties: [],
        aggs: {
          'category-agg': {
            terms: {
              field: 'properties.Category.keyword',
            },
          },
        },
        pageSize: 0,
      },
    });
    const aggregation = result.data.data.aggregations['category-agg'].buckets;
    if (aggregation) {
      setChatConcurrencyMetrics(
        aggregation.map((item: any) => ({
          name: item.key
            .toLowerCase()
            .split('_')
            .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' '),
          value: item.doc_count,
        })),
      );
    }
  };

  useEffect(() => {
    getChatConcurrencyAggregate();
  }, [props.refreshBit]);

  return (
    <DashboardBarChart
      title="Out of SLA Category"
      orderByValue="desc"
      data={chatConcurrencyMetrics}
    />
  );
};

export default OutOfSlaCategoryChart;
