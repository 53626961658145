import React, { useEffect, useState } from 'react';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { getSchemaFromShortListByModuleAndEntity } from '@core/helpers/schemaHelpers';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '@redux/stores/schemas/actions';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import { Checkbox, Col, Divider, Empty, Row, Spin } from 'antd';
import Search from 'antd/lib/input/Search';
import {
  getRecordByIdRequest,
  IGetRecordById,
  IRecordLookup,
  ISearchRecords,
  IUpdateRecordById,
  lookupRecordsDebounced,
  searchRecordsDebounced,
  updateRecordByIdRequest,
} from '@redux/stores/records/actions';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import RecordCard from '../RecordCard';
import CoreForm from '../Forms/CoreForm';
import { initializeRecordForm } from '@redux/stores/form/actions';
import { getDefaultRecordCardPropertiesForEntity } from '../RecordCard/defaultProperties';
import { setLookupRecord, toggleLookupDrawer } from '@redux/stores/userInterface/actions';
import { ILookupSetRecord, IUserInterfaceReducer } from '@redux/stores/userInterface/types';
import { updateOrCreateRecordAssociations } from '@redux/stores/recordAssociations/actions';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { Button } from '@blueprintjs/core';

const uuid = uuidv4();

type Props = {
  createAssociations: Function;
  customLookupQuery?: any;
  entityName: string;
  finishButtonTitle?: string;
  getRecordById: Function;
  getSchema: Function;
  initializeForm: Function;
  lookupColumn: string;
  lookupDefaultRecord?: DbRecordEntityTransform;
  lookupOperator?: string;
  lookupRecords: Function;
  lookupSearchPlaceholder?: string;
  mode: 'STANDALONE' | 'FLOW';
  moduleName: string;
  onCancelCb?: Function;
  onSuccessCb?: Function;
  relatedRecords?: DbRecordEntityTransform[];
  schemaReducer: any;
  searchRecordsDebounced: Function;
  setLookupRecord: Function;
  toggleDrawer: Function;
  type: 'LOOKUP' | 'LOOKUP_AND_CREATE' | 'CREATE';
  updateRecord: Function;
  userInterfaceReducer: IUserInterfaceReducer;
};

const LookUpCreateShared: React.FC<Props> = (props: Props) => {
  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);
  const [isLoadingSchema, setIsLoadingSchema] = useState<boolean>(true);
  const [isLoadingRecords, setIsLoadingRecords] = useState<boolean>(false);
  const [recordList, setRecordList] = useState<DbRecordEntityTransform[]>([]);
  const [createFormIsVisible, setCreateFormIsVisible] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [isCreatedRecord, setIsCreatedRecord] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const {
    moduleName,
    entityName,
    schemaReducer,
    getSchema,
    lookupRecords,
    relatedRecords,
    customLookupQuery,
    lookupDefaultRecord,
    createAssociations,
    type,
    searchRecordsDebounced,
  } = props;

  // On component mount, clean lookup record in the reducer
  useEffect(() => {
    const { setLookupRecord } = props;
    if (lookupDefaultRecord) {
      setRecordList([lookupDefaultRecord]);
      setLookupRecord({ record: lookupDefaultRecord });
      setRecord(lookupDefaultRecord);
      setIsCreatedRecord(false);
    } else {
      setLookupRecord({ record: undefined });
    }
  }, [lookupDefaultRecord, moduleName, entityName]);

  const setRecord = (record: DbRecordEntityTransform | undefined) => {
    setSelectedRecord(record);
    setLookupRecord({ record: record });
  };

  // Fetch schema from shortlist or API
  useEffect(() => {
    if (moduleName && entityName) {
      const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        moduleName,
        entityName,
      );
      if (shortlistSchema) {
        setSchema(shortlistSchema);
        setIsLoadingSchema(false);

        if (!lookupDefaultRecord && type !== 'CREATE') {
          lookupRecordsBySearchTerm(shortlistSchema, '');
        }
      } else {
        getSchema(
          {
            moduleName,
            entityName,
          },
          (res: SchemaEntity) => {
            setSchema(res);
            setIsLoadingSchema(false);
            if (!lookupDefaultRecord && type !== 'CREATE') {
              lookupRecordsBySearchTerm(res, '');
            }
          },
        );
      }
    }
  }, [moduleName, entityName]);

  const lookupRecordsBySearchTerm = (schema: SchemaEntity, searchTerm: string) => {
    setSearchTerm(searchTerm);
    setIsLoadingRecords(true);
    setRecord(undefined);
    setIsCreatedRecord(false);
    setRecordList([]);

    const { lookupColumn } = props;

    searchRecordsDebounced(
      {
        schema: schema,
        searchQuery: {
          terms: '',
          schemas: schema?.id,
          fields: [lookupColumn],
          boolean: {
            must: [
              {
                query_string: {
                  fields: [lookupColumn],
                  query: searchTerm?.length > 0 ? searchTerm : '*',
                  lenient: true,
                  default_operator: 'AND',
                },
              },
            ],
            ...(customLookupQuery || undefined),
          },
          sort: [{ createdAt: { order: 'desc' } }],
        },
      },
      (searchResults: any) => {
        if (searchResults?.data?.data?.length > 0) {
          setRecordList(searchResults?.data?.data);
        }
        setIsLoadingRecords(false);
      },
    );
  };

  const isRecordSelected = (record: DbRecordEntityTransform) => {
    return record?.id === selectedRecord?.id ? true : false;
  };

  const setCardOpacity = (record: DbRecordEntityTransform) => {
    if (selectedRecord) {
      if (isRecordSelected(record)) {
        return 1;
      } else {
        return 0.25;
      }
    } else {
      return 1;
    }
  };

  const handleCreateRecord = (createdRecord: any) => {
    const { getRecordById } = props;
    if (createdRecord?.results?.id) {
      getRecordById({ schema: schema, recordId: createdRecord?.results?.id }, (res: any) => {
        setIsCreatedRecord(true);
        setRecordList([]);
        setRecordList([res]);
        setRecord(res);
      });
    }
  };

  const initializeCreateForm = () => {
    const { initializeForm } = props;

    initializeForm({
      formUUID: uuid,
      isCreateReq: true,
      title: 'Create' + entityName,
      showFormModal: true,
      isBatchCreateReq: false,
      schema: schema,
      sections: [{ name: schema?.name, schema: schema }],
      modified: [
        {
          associations: [
            {
              // entity: addressRecord.entity,
              // recordId: addressRecord.id,
            },
          ],
          schemaId: schema?.id,
        },
      ],
    });
  };

  const renderRecordList = () => {
    if (recordList?.length > 0) {
      return recordList.map((record: DbRecordEntityTransform) => (
        <Col span={24} style={{ opacity: setCardOpacity(record) }} key={record.id}>
          <RecordCard
            // hideIcon={props.mode === 'FLOW'}
            openTitleLinkInNewTab
            disableTitleLink={!isRecordSelected(record) && selectedRecord !== undefined}
            headerElement={
              <>
                <span
                  style={{
                    fontWeight: isRecordSelected(record) ? 500 : 'normal',
                    marginRight: 10,
                  }}
                >
                  {isRecordSelected(record) ? 'Selected' : 'Select'}
                </span>
                <Checkbox
                  key={record.id}
                  disabled={(selectedRecord && !isRecordSelected(record)) || isCreatedRecord}
                  checked={isRecordSelected(record)}
                  onChange={(e: any) => {
                    selectedRecord !== undefined ? setRecord(undefined) : setRecord(record);
                  }}
                  style={{ marginTop: 5, marginRight: 5 }}
                />
              </>
            }
            record={record}
            visibleProperties={getDefaultRecordCardPropertiesForEntity(entityName)}
          />
        </Col>
      ));
    } else {
      return (
        <Col span={24} style={{ paddingTop: 90, textAlign: 'center' }}>
          <Empty
            description={
              type === 'CREATE'
                ? 'Create a Record to proceed'
                : searchTerm !== ''
                  ? 'No Records found.'
                  : 'Enter search'
            }
          />
        </Col>
      );
    }
  };

  const onLookupFinish = () => {
    const { onSuccessCb, mode, toggleDrawer } = props;

    if (mode === 'STANDALONE') {
      toggleDrawer();
    }

    // If there are related records passed, update associations with the current selected/created one
    if (relatedRecords && relatedRecords?.length > 0 && schema && selectedRecord) {
      for (const relatedRecord of relatedRecords) {
        const relatedRecordEntityName = relatedRecord.entity?.split(':')[1];

        const schemaAssociation = schema?.associations?.find(
          (assoc: SchemaAssociationEntity) =>
            assoc.childSchema?.entityName === relatedRecordEntityName ||
            assoc.parentSchema?.entityName === relatedRecordEntityName,
        );

        let associationBody = [
          {
            entity: relatedRecord?.entity,
            recordId: relatedRecord?.id,
            relatedAssociationId: relatedRecord?.dbRecordAssociation?.id,
          },
        ];

        createAssociations(
          {
            recordId: selectedRecord?.id,
            schema,
            schemaAssociation,
            createUpdate: associationBody,
          },
          (res: any) => {
            setSearchTerm('');
            // Success callback to parent component
            if (onSuccessCb) {
              onSuccessCb({
                results: { id: selectedRecord?.id },
              });
            }
          },
        );
      }
    } else {
      setSearchTerm('');

      // Success callback to parent component
      if (onSuccessCb) {
        onSuccessCb({
          results: { id: selectedRecord?.id },
        });
      }
    }
  };

  return (
    <div>
      <CoreForm
        type="MODAL"
        formUUID={uuid}
        onSubmitEvent={(params: { event: string; results: any }) => handleCreateRecord(params)}
      />
      <Row style={{ paddingBottom: 20 }} align="middle">
        {/* Search Box */}
        {type !== 'CREATE' ? (
          <Col span={type === 'LOOKUP' ? 24 : 19}>
            <Search
              allowClear
              value={searchTerm}
              disabled={isLoadingSchema || selectedRecord !== undefined}
              onSearch={(e: any) => {
                lookupRecordsBySearchTerm(schema!, e?.target?.value);
              }}
              onChange={(e: any) => {
                lookupRecordsBySearchTerm(schema!, e?.target?.value);
              }}
              placeholder={props.lookupSearchPlaceholder || `Search for ${entityName} records...`}
            />
          </Col>
        ) : (
          <Col span={19} />
        )}

        {/* Create Button */}
        {type !== 'LOOKUP' ? (
          <Col span={5} style={{ textAlign: 'right' }}>
            <Button
              intent="primary"
              style={{ width: '80%' }}
              disabled={
                selectedRecord !== undefined ||
                isLoadingSchema ||
                isLoadingRecords ||
                props.type === 'LOOKUP'
              }
              onClick={() => {
                initializeCreateForm();
              }}
            >
              Create New
            </Button>
          </Col>
        ) : (
          <></>
        )}
      </Row>
      <Row
        style={{
          height: props.mode === 'FLOW' ? 400 : '80vh',
          overflow: selectedRecord ? 'hidden' : 'auto',
          padding: 2,
        }}
      >
        {isLoadingRecords ? (
          <Col span={24} style={{ textAlign: 'center', paddingTop: 100 }}>
            <Spin size="large" style={{ marginBottom: 30 }} />
            <br />
            <span>Loading records...</span>
          </Col>
        ) : (
          renderRecordList()
        )}
      </Row>
      {props.mode === 'FLOW' ? (
        <Row>
          <Col span={24}>
            <Divider />
          </Col>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button
              style={{ marginRight: 10 }}
              onClick={() => (props.onCancelCb ? props.onCancelCb() : undefined)}
            >
              Cancel
            </Button>
            <Button intent="primary" disabled={!selectedRecord} onClick={() => onLookupFinish()}>
              {props.finishButtonTitle || 'Finish'}
            </Button>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </div>
  );
};

const mapDispatch = (dispatch: any) => ({
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  lookupRecords: (params: IRecordLookup, cb: any) => dispatch(lookupRecordsDebounced(params, cb)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getRecordById: (payload: IGetRecordById, cb?: any) => dispatch(getRecordByIdRequest(payload, cb)),
  updateRecord: (params: IUpdateRecordById, cb: any) =>
    dispatch(updateRecordByIdRequest(params, cb)),
  setLookupRecord: (params: ILookupSetRecord) => dispatch(setLookupRecord(params)),
  toggleDrawer: () => dispatch(toggleLookupDrawer()),
  createAssociations: (params: any, cb: () => {}) =>
    dispatch(updateOrCreateRecordAssociations(params, cb)),
  searchRecordsDebounced: (params: ISearchRecords, cb: any) =>
    dispatch(searchRecordsDebounced(params, cb)),
});

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userInterfaceReducer: state.userInterfaceReducer,
});

export default connect(mapState, mapDispatch)(LookUpCreateShared);
