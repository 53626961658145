import React from 'react';
import CardWithTabs from '@core/components/CardWithTabs';
import ActivityCenter from '@core/components/ActivityCenter';
import AssociationDataTable from 'src/core/components/AssociationDataTable';
import RecordCard from '@core/components/RecordCard';
import RecordCardMissing from '@core/components/RecordCard/RecordCardMissing';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { Avatar, Col, Descriptions, Row } from 'antd';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { getInitialsFromName, getPastelColorForUsername } from '@core/helpers/userInterfaceHelpers';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
import GroupDetails from '@core/components/GroupDetails';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { getSchemasForDefaultDetailView } from '@core/views/DefaultDetailView/helpers';
import { getAllSchemaAssociationSchemas } from '@core/helpers/recordHelpers';
import { renderDynamicAssociationTables } from '@core/helpers/associationHelper';
import './styles.scss';

interface Props {
  record: DbRecordEntityTransform;
  schema: SchemaEntity;
}

const RFCDetails = ({ record, schema }: Props) => {
  const routeTypeList =
    schema.columns.find((col: SchemaColumnEntity) => col.name === 'RouteType')?.options || [];

  const structureTypeList =
    schema.columns.find((col: SchemaColumnEntity) => col.name === 'StructureType')?.options || [];

  const routeModelList =
    schema.columns.find((col: SchemaColumnEntity) => col.name === 'RouteModel')?.options || [];

  const structureModelList =
    schema.columns.find((col: SchemaColumnEntity) => col.name === 'StructureModel')?.options || [];

  const priorityList = schema.columns.find((col) => col.name === 'Priority')?.options;

  const RFCPriority = priorityList?.find((opt) => opt.value === record.properties.Priority)?.label;

  const isOverdue = () => {
    if (record.stage?.isSuccess || record.stage?.isFail) {
      return false;
    } else {
      return dayjs(getProperty(record, 'DueDate')) < dayjs();
    }
  };

  // Get related schemas but remove those that have type constraint that doesn't match the record type
  const relatedSchemas = getAllSchemaAssociationSchemas(schema?.associations, []).filter(
    (schemaAssociation: SchemaEntity) => {
      if (
        schemaAssociation.isVisibleInTabs &&
        getSchemasForDefaultDetailView(schema!, record).includes(schemaAssociation.name)
      ) {
        return true;
      }
    },
  );

  const rfcModel = () => {
    switch (record.type) {
      case 'SURVEY_ROUTE':
        return (
          <Descriptions.Item label="Route Model">
            {routeModelList.find((opt) => opt.value === record.properties.RouteModel)?.label || '-'}
          </Descriptions.Item>
        );
      case 'SURVEY_STRUCTURE':
        return (
          <Descriptions.Item label="Structure Model">
            {structureModelList.find((opt) => opt.value === record.properties.StructureModel)
              ?.label || '-'}
          </Descriptions.Item>
        );
    }
  };

  const rfcType = () => {
    switch (record.type) {
      case 'SURVEY_ROUTE':
        return (
          <Descriptions.Item label="Route Type">
            {routeTypeList.find((opt) => opt.value === record.properties.RouteType)?.label || '-'}
          </Descriptions.Item>
        );
      case 'SURVEY_STRUCTURE':
        return (
          <Descriptions.Item label="Structure Type">
            {structureTypeList.find((opt) => opt.value === record.properties.StructureType)
              ?.label || '-'}
          </Descriptions.Item>
        );
    }
  };

  const detailsTab = (
    <Row>
      <Col span={24}>
        {!isMobile ? (
          <Descriptions
            labelStyle={{
              fontSize: '0.9em',
              padding: '4px 6px',
              fontWeight: 500,
              whiteSpace: 'nowrap',
              width: '15%',
            }}
            contentStyle={{
              fontSize: '0.9em',
              padding: '4px 6px',
              width: '35%',
            }}
            className="recordProperties_small"
            column={isMobile ? 1 : 2}
            size="small"
            bordered={true}
          >
            <Descriptions.Item label="Description">
              {getProperty(record, 'Description') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Assignee">
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                {record?.featureUser?.[0]?.name ? (
                  <Avatar
                    size="small"
                    style={{
                      backgroundColor: getPastelColorForUsername(record?.featureUser?.[0]?.name),
                    }}
                  >
                    {getInitialsFromName(record?.featureUser?.[0]?.name || '')}
                  </Avatar>
                ) : (
                  <Avatar size="small"></Avatar>
                )}
                {record?.featureUser?.[0]?.name || '-'}
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Current Stage">{record.stage?.name}</Descriptions.Item>

            <Descriptions.Item label="Regional Build Manager">
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                {getProperty(record, 'BuildManager') ? (
                  <Avatar
                    size="small"
                    style={{
                      backgroundColor: getPastelColorForUsername(
                        getProperty(record, 'BuildManager'),
                      ),
                    }}
                  >
                    {getInitialsFromName(getProperty(record, 'BuildManager') || '')}
                  </Avatar>
                ) : (
                  <Avatar size="small"></Avatar>
                )}
                {getProperty(record, 'BuildManager') || '-'}
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="External Ref">
              {getProperty(record, 'ExternalRef') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Regional Planning Lead">
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                {getProperty(record, 'PlanningManager') ? (
                  <Avatar
                    size="small"
                    style={{
                      backgroundColor: getPastelColorForUsername(
                        getProperty(record, 'PlanningManager'),
                      ),
                    }}
                  >
                    {getInitialsFromName(getProperty(record, 'PlanningManager') || '')}
                  </Avatar>
                ) : (
                  <Avatar size="small"></Avatar>
                )}
                {getProperty(record, 'PlanningManager') || '-'}
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Exchange Name">
              {getProperty(record, 'ExchangeName') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Build Contractor">
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                {getProperty(record, 'BuildContractor') ? (
                  <Avatar
                    size="small"
                    style={{
                      backgroundColor: getPastelColorForUsername(
                        getProperty(record, 'BuildContractor'),
                      ),
                    }}
                  >
                    {getInitialsFromName(getProperty(record, 'BuildContractor') || '')}
                  </Avatar>
                ) : (
                  <Avatar size="small"></Avatar>
                )}
                {getProperty(record, 'BuildContractor') || '-'}
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Ex Polygon ID">
              {getProperty(record, 'ExPolygonId') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Design Partner">
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                {getProperty(record, 'PlanningContractor') ? (
                  <Avatar
                    size="small"
                    style={{
                      backgroundColor: getPastelColorForUsername(
                        getProperty(record, 'PlanningContractor'),
                      ),
                    }}
                  >
                    {getInitialsFromName(getProperty(record, 'PlanningContractor') || '')}
                  </Avatar>
                ) : (
                  <Avatar size="small"></Avatar>
                )}
                {getProperty(record, 'PlanningContractor') || '-'}
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="L1 Polygon ID">
              {getProperty(record, 'L1PolygonId') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Due Date">
              {getProperty(record, 'DueDate') ? (
                <div className={`due-date ${isOverdue() ? 'due-date--overdue' : ''}`}>
                  {dayjs(getProperty(record, 'DueDate')).format('DD/MM/YYYY')}
                </div>
              ) : (
                '-'
              )}
            </Descriptions.Item>

            <Descriptions.Item label="L2 Polygon ID">
              {getProperty(record, 'L2PolygonId') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Part of SLA?">
              {getProperty(record, 'PartOfSla') || '-'}
            </Descriptions.Item>

            {rfcModel()}

            <Descriptions.Item label="Splicing Affected">
              {getProperty(record, 'SplicingAffected') || '-'}
            </Descriptions.Item>
            {rfcType()}

            <Descriptions.Item label="Priority" styles={{ content: { display: 'flex' } }}>
              {RFCPriority ? (
                <div className={`wx-card__tag wx-card__tag--${RFCPriority.toLowerCase()}`}>
                  {RFCPriority}
                </div>
              ) : (
                '-'
              )}
            </Descriptions.Item>

            <Descriptions.Item label="Project Name">
              {record.projectFeature?.[0]?.name || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Reporter">
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                <Avatar
                  size="small"
                  style={{
                    backgroundColor: getPastelColorForUsername(record.createdBy?.fullName || '-'),
                  }}
                >
                  {getInitialsFromName(record.createdBy?.fullName || '')}
                </Avatar>
                {record?.createdBy?.fullName || '-'}
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Groups">
              <GroupDetails record={record} />
            </Descriptions.Item>
          </Descriptions>
        ) : (
          <Descriptions
            labelStyle={{
              fontSize: '0.9em',
              padding: '4px 6px',
              fontWeight: 500,
            }}
            contentStyle={{
              fontSize: '0.9em',
              padding: '4px 6px',
            }}
            className="recordProperties_small"
            column={isMobile ? 1 : 2}
            size="small"
            bordered={true}
          >
            <Descriptions.Item label="Description">
              {getProperty(record, 'Description') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Current Stage">{record.stage?.name}</Descriptions.Item>

            <Descriptions.Item label="External Ref">
              {getProperty(record, 'ExternalRef') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Exchange Name">
              {getProperty(record, 'ExchangeName') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Ex Polygon ID">
              {getProperty(record, 'ExPolygonId') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="L1 Polygon ID">
              {getProperty(record, 'L1PolygonId') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="L2 Polygon ID">
              {getProperty(record, 'L2PolygonId') || '-'}
            </Descriptions.Item>

            {rfcModel()}
            {rfcType()}

            <Descriptions.Item label="Project Name">
              {record.projectFeature?.[0]?.name || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Priority" styles={{ content: { display: 'flex' } }}>
              {RFCPriority ? (
                <div className={`wx-card__tag wx-card__tag--${RFCPriority.toLowerCase()}`}>
                  {RFCPriority}
                </div>
              ) : (
                '-'
              )}
            </Descriptions.Item>

            <Descriptions.Item label="Due Date">
              {getProperty(record, 'DueDate') ? (
                <div className={`due-date ${isOverdue() ? 'due-date--overdue' : ''}`}>
                  {dayjs(getProperty(record, 'DueDate')).format('DD/MM/YYYY')}
                </div>
              ) : (
                '-'
              )}
            </Descriptions.Item>

            <Descriptions.Item label="Part of SLA?">
              {getProperty(record, 'PartOfSla') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Splicing Affected">
              {getProperty(record, 'SplicingAffected') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Reporter">
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                <Avatar
                  size="small"
                  style={{
                    backgroundColor: getPastelColorForUsername(record.createdBy?.fullName || '-'),
                  }}
                >
                  {getInitialsFromName(record.createdBy?.fullName || '')}
                </Avatar>
                {record?.createdBy?.fullName || '-'}
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Assignee">
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                {record?.featureUser?.[0]?.name ? (
                  <Avatar
                    size="small"
                    style={{
                      backgroundColor: getPastelColorForUsername(record?.featureUser?.[0]?.name),
                    }}
                  >
                    {getInitialsFromName(record?.featureUser?.[0]?.name || '')}
                  </Avatar>
                ) : (
                  <Avatar size="small"></Avatar>
                )}
                {record?.featureUser?.[0]?.name || '-'}
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Regional Build Manager">
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                {getProperty(record, 'BuildManager') ? (
                  <Avatar
                    size="small"
                    style={{
                      backgroundColor: getPastelColorForUsername(
                        getProperty(record, 'BuildManager'),
                      ),
                    }}
                  >
                    {getInitialsFromName(getProperty(record, 'BuildManager') || '')}
                  </Avatar>
                ) : (
                  <Avatar size="small"></Avatar>
                )}
                {getProperty(record, 'BuildManager') || '-'}
              </div>
            </Descriptions.Item>

            <Descriptions.Item
              label="Regional Planning Lead"
              contentStyle={{ display: 'flex', alignItems: 'center', gap: 5 }}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                {getProperty(record, 'PlanningManager') ? (
                  <Avatar
                    size="small"
                    style={{
                      backgroundColor: getPastelColorForUsername(
                        getProperty(record, 'PlanningManager'),
                      ),
                    }}
                  >
                    {getInitialsFromName(getProperty(record, 'PlanningManager') || '')}
                  </Avatar>
                ) : (
                  <Avatar size="small"></Avatar>
                )}
                {getProperty(record, 'PlanningManager') || '-'}
              </div>
            </Descriptions.Item>

            <Descriptions.Item
              label="Build Contractor"
              contentStyle={{ display: 'flex', alignItems: 'center', gap: 5 }}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                {getProperty(record, 'BuildContractor') ? (
                  <Avatar
                    size="small"
                    style={{
                      backgroundColor: getPastelColorForUsername(
                        getProperty(record, 'BuildContractor'),
                      ),
                    }}
                  >
                    {getInitialsFromName(getProperty(record, 'BuildContractor') || '')}
                  </Avatar>
                ) : (
                  <Avatar size="small"></Avatar>
                )}
                {getProperty(record, 'BuildContractor') || '-'}
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Design Partner">
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                {getProperty(record, 'PlanningContractor') ? (
                  <Avatar
                    size="small"
                    style={{
                      backgroundColor: getPastelColorForUsername(
                        getProperty(record, 'PlanningContractor'),
                      ),
                    }}
                  >
                    {getInitialsFromName(getProperty(record, 'PlanningContractor') || '')}
                  </Avatar>
                ) : (
                  <Avatar size="small"></Avatar>
                )}
                {getProperty(record, 'PlanningContractor') || '-'}
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Groups">
              <GroupDetails record={record} />
            </Descriptions.Item>
          </Descriptions>
        )}
      </Col>
    </Row>
  );

  return (
    <>
      <CardWithTabs
        title="Feature Details"
        defaultTabKey="Details"
        hideCardHeader
        relatedAssociations={renderDynamicAssociationTables(record, relatedSchemas)}
        tabList={[
          { key: 'Details', tab: 'Details' },
          { key: 'ProjectDetails', tab: 'Project' },
          { key: 'Activity', tab: 'Activity' },
        ]}
        tabContents={{
          Details: detailsTab,
          Activity: <ActivityCenter record={record} schema={schema} />,
          ProjectDetails: (
            <RecordCard
              record={record}
              associatedRecordModuleName={SchemaModuleTypeEnums.PROJECT_MODULE}
              associatedRecordEntityName={SchemaModuleEntityTypeEnums.PROJECT}
              visibleProperties={[
                'Contractor',
                'PurchaseOrderNumber',
                'EstimatedStart',
                'EstimatedEnd',
              ]}
            />
          ),
        }}
      />
    </>
  );
};

export default RFCDetails;
