import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import AssociationDataTable from '../components/AssociationDataTable';

export function renderDynamicAssociationTables(
  record: DbRecordEntityTransform,
  relatedSchemas: SchemaEntity[],
  isEditDisabled?: boolean,
) {
  const obj = {};

  if (record && relatedSchemas) {
    for (const schema of relatedSchemas) {
      // @ts-ignore
      obj[schema.entityName] = (
        <AssociationDataTable
          location="association"
          title={schema.entityName}
          record={record}
          hideIcon
          moduleName={schema.moduleName}
          entityName={schema.entityName}
          isEditDisabled={isEditDisabled}
        />
      );
    }
  }
  return obj;
}

export function renderDynamicAssociationsAsTables(
  record: DbRecordEntityTransform,
  relatedSchemas: SchemaEntity[],
  isEditDisabled?: boolean,
) {
  if (record && relatedSchemas) {
    let response = [];

    for (const schema of relatedSchemas) {
      // @ts-ignore
      response.push(
        <AssociationDataTable
          location="association"
          hideIcon
          showAllRecords
          title={schema.entityName}
          record={record}
          moduleName={schema.moduleName}
          entityName={schema.entityName}
          isEditDisabled={isEditDisabled}
        />,
      );
    }

    return response;
  }
}
