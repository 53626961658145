import { Col, Row, Spin, Switch } from 'antd';
import React, { useContext } from 'react';
import { invoiceBuilderContext } from '../index';
import RecordCard from '@core/components/RecordCard';
import { SET_IS_DISCOUNT_APPLIED } from '../store/constants';

const DiscountPanel: React.FC = () => {
  const { state, dispatch } = useContext(invoiceBuilderContext);

  const renderDiscountCard = () => {
    return (
      <Col span={24} style={{ margin: 5 }}>
        <RecordCard
          openTitleLinkInNewTab
          cardBodyOpacity={state.isDiscountApplied ? 1 : 0.5}
          customTitle={<span>Order Discount</span>}
          record={state.parentRecord!}
          associatedRecordModuleName="ProductModule"
          associatedRecordEntityName="Discount"
          visibleProperties={['DiscountLength', 'DiscountType', 'DiscountValue', 'Description']}
          headerElement={
            state.discount && state.invoiceItems.length > 0 ? (
              <Switch
                style={{ marginTop: 4 }}
                checked={state.isDiscountApplied}
                onChange={() =>
                  dispatch({
                    type: SET_IS_DISCOUNT_APPLIED,
                    payload: !state.isDiscountApplied,
                  })
                }
              />
            ) : (
              <></>
            )
          }
        />
      </Col>
    );
  };

  return state.isLoadingParentRecordAssociations ? (
    <Row style={{ padding: 40, textAlign: 'center' }}>
      <Col span={24}>
        <Spin size="large" />
      </Col>
      <Col span={24} style={{ marginTop: 30 }}>
        <span>Loading Discounts</span>
      </Col>
    </Row>
  ) : (
    renderDiscountCard()
  );
};

export default DiscountPanel;
