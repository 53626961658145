import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  Divider,
  InputGroup,
  Label,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Switch,
} from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { getOdinSchemaById } from '@core/helpers/schemaHelpers';
import { httpGet, httpPost, httpPut } from '@core/http/requests';
import { PipelineStageEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/stage/pipeline.stage.entity';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import { displayMessage } from '@redux/stores/messages/reducers';
import { ISchemaReducer } from '@redux/stores/schemas/reducer';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  DefaultSchemaActionFlowTemplate,
  DefaultSchemaActionTemplate,
} from '../../SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration/SchemaActionJSONDefinition/V2Template';

interface Props {
  mode: 'EDIT' | 'CREATE';
  schemaAction?: any;
  schemaReducer: ISchemaReducer;
  onUpdate?: (schemaAction: SchemaActionEntity) => void;
  onCreate?: (schemaAction: SchemaActionEntity) => void;
  alertMessage: (params: { body: string; type: string }) => void;
  buttonProps?: any;
  defaultSchemaId?: string;
}

interface ISchemaMenuItem {
  key: string;
  name: string;
  value: string;
  entityName: string;
  moduleName: string;
}

interface ISchemaAssociationMenuItem {
  key: string;
  name: string;
}

interface ISchemaTypeMenuItem {
  key: string;
  name: string;
}

interface IStageMenuItem {
  key: string;
  name: string;
}

type ICreateActionFormType =
  | 'Create Form'
  | 'Update Form'
  | 'Delete Form'
  | 'Stage Change'
  | 'Multi Step'
  | 'Create Link Form'
  | 'Update Link Form'
  | 'Delete Link Form';

const SchemaActionEditCreateForm: React.FC<Props> = (props: Props) => {
  const {
    schemaAction,
    mode,
    schemaReducer,
    onUpdate,
    onCreate,
    alertMessage,
    buttonProps,
    defaultSchemaId,
  } = props;

  // State
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [allSchemas, setAllSchemas] = useState<any[]>([]);
  const [allSchemaAssociations, setAllSchemaAssociations] = useState<SchemaAssociationEntity[]>([]);
  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);

  const [schemaTypes, setSchemaTypes] = useState<SchemaTypeEntity[]>([]);
  const [schemaSearchQuery, setSchemaSearchQuery] = useState<string>('');
  const [associationSearchQuery, setAssociationSearchQuery] = useState<string>('');
  const [stages, setStages] = useState<PipelineStageEntity[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);

  // Form values
  const [formSchemaId, setFormSchemaId] = useState<string | undefined>(undefined);
  const [formSchemaTypeId, setFormSchemaTypeId] = useState<string | undefined>(undefined);
  const [formSchemaAssociationId, setFormSchemaAssociationId] = useState<string | undefined>(
    undefined,
  );
  const [formDescription, setFormDescription] = useState<string | undefined>(undefined);
  const [formEventName, setFormEventName] = useState<string | undefined>(undefined);
  const [formName, setFormName] = useState<string | undefined>(undefined);
  const [formStage, setFormStage] = useState<string | undefined>(undefined);
  const [formTargetStage, setFormTargetStage] = useState<string | undefined>(undefined);
  const [formUserAction, setFormUserAction] = useState<boolean>(false);
  const [formDefaultForm, setFormDefaultForm] = useState<boolean>(false);
  const [formType, setFormType] = useState<ICreateActionFormType | undefined>(undefined);

  // Get all Schemas from the schema reducer list. We use this for select dropdown
  useEffect(() => {
    if (isOpen && schemaReducer.list?.length! > 0) {
      const schemas = schemaReducer.list.map((schema: SchemaEntity) => {
        return {
          id: schema.id,
          name: schema.name,
          entityName: schema.entityName,
          moduleName: schema.moduleName,
          associations: schema.associations,
        };
      });
      setAllSchemas(schemas);
    }
  }, [isOpen, schemaReducer.list]);

  const defineFormType = () => {
    if (schemaAction?.schemaAssociationId && schemaAction?.isCreate) {
      setFormType('Create Link Form');
    } else if (schemaAction?.schemaAssociationId && schemaAction?.isUpdate) {
      setFormType('Update Link Form');
    } else if (schemaAction?.schemaAssociationId && schemaAction?.isDelete) {
      setFormType('Delete Link Form');
    } else if (schemaAction?.isCreate) {
      setFormType('Create Form');
    } else if (schemaAction?.isUpdate) {
      setFormType('Update Form');
    } else if (schemaAction?.isDelete) {
      setFormType('Delete Form');
    } else if (
      (schemaAction?.isStepFlow && !schemaAction?.targetStages?.length) ||
      (schemaAction?.isStepFlow && !schemaAction.targetStages)
    ) {
      setFormType('Multi Step');
    } else if (schemaAction?.isStepFlow) {
      setFormType('Stage Change');
    }
  };

  useEffect(() => {
    // In edit mode, set all form values from the schema action
    if (isOpen && mode === 'EDIT') {
      defineFormType();

      if (schemaAction?.schemaId) {
        setFormSchemaId(schemaAction.schemaId);
      }
      if (schemaAction?.schemaTypeId) {
        setFormSchemaTypeId(schemaAction.schemaTypeId);
      }
      if (schemaAction?.schemaAssociationId) {
        setFormSchemaAssociationId(schemaAction.schemaAssociationId);
      }
      if (schemaAction?.description) {
        setFormDescription(schemaAction.description);
      }
      if (schemaAction?.eventName) {
        setFormEventName(schemaAction.eventName);
      }
      if (schemaAction?.name) {
        setFormName(schemaAction.name);
      }
      if (schemaAction?.userAction) {
        setFormUserAction(schemaAction.userAction);
      }
      if (schemaAction?.defaultForm) {
        setFormDefaultForm(schemaAction.defaultForm);
      }
      if (schemaAction?.stages && schemaAction.stages?.length! > 0) {
        setFormStage(schemaAction.stages[0]);
      }
      if (schemaAction?.targetStages && schemaAction?.targetStages?.length! > 0) {
        setFormTargetStage(schemaAction.targetStages[0]);
      }
    }
  }, [schemaAction, isOpen, mode]);

  // Set default schema id on modal open in CREATE mode
  useEffect(() => {
    if (defaultSchemaId && isOpen && mode === 'CREATE') {
      setFormSchemaId(props.defaultSchemaId);
    }
  }, [defaultSchemaId, mode, isOpen]);

  // When a schema is selected, get the schema types
  useEffect(() => {
    if (formSchemaId) {
      getSchemaTypesBySchemaId(formSchemaId);
    }
  }, [formSchemaId]);

  // When both schema and schema type are selected, get the pipeline
  useEffect(() => {
    if (formSchemaId) {
      getPipeline(formSchemaId, formSchemaTypeId);
    }
  }, [formSchemaId, formSchemaTypeId, schemaTypes]);

  // When a schema is selected, get the schema object
  useEffect(() => {
    if (formSchemaId) {
      getSchema(formSchemaId);
    }
  }, [formSchemaId]);

  const getSchema = async (schemaId: string) => {
    const schema = await getOdinSchemaById(schemaId);
    if (schema) {
      setSchema(schema);
      setAllSchemaAssociations(schema.associations);
    }
  };

  const onClose = () => {
    setIsOpen(false);
    setFormSchemaId(undefined);
    setFormSchemaTypeId(undefined);
    setFormType(undefined);
    setFormDescription(undefined);
    setFormEventName(undefined);
    setFormStage(undefined);
    setFormTargetStage(undefined);
    setSchemaSearchQuery('');
    setFormName(undefined);
    setFormUserAction(false);
    setFormDefaultForm(false);
    setSchemaTypes([]);
    setStages([]);
    setAllSchemaAssociations([]);
    setSchema(undefined);
  };

  const getPipeline = async (schemaId: string, typeId?: string) => {
    try {
      const schema = await getOdinSchemaById(schemaId);
      const type = schemaTypes.find((t: SchemaTypeEntity) => t.id === typeId);

      if (schema) {
        const res = await httpGet(
          `SchemaModule/v1.0/pipelines/bymodule/${schema?.moduleName}/${schema?.entityName}${
            type?.name ? `?schemaType=${type.name}` : '?schemaType='
          }`,
        );

        const pipelines = res?.data?.data || [];

        if (pipelines.length > 0) {
          const stages = pipelines[0]?.stages || [];
          if (stages.length > 0) {
            setStages(stages);
          }
        }
      }
    } catch (error) {}
  };

  const getSchemaTypesBySchemaId = async (schemaId: string) => {
    try {
      const schema = await getOdinSchemaById(schemaId);
      if (schema && schema?.types?.length! > 0) {
        setSchemaTypes(schema.types);
      } else if (schema && schema?.types?.length! === 0) {
        setSchemaTypes([]);
      }
    } catch (error) {
      console.error('Error getting schema types', error);
    }
  };

  const renderSchemaItem: ItemRenderer<ISchemaMenuItem> = (
    schema,
    { handleClick, handleFocus, modifiers },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else
      return (
        <MenuItem
          active={schema.key === formSchemaId}
          label={schema.moduleName}
          disabled={modifiers.disabled}
          key={schema.key}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={schema.name}
        />
      );
  };

  const createAction = async () => {
    try {
      setIsCreating(true);

      // Pre-fill the definition based on the form type
      const moduleName = schema?.moduleName || 'Unknown Module';
      const entityName = schema?.entityName || 'Unknown Entity';
      const definition =
        formType &&
        [
          'Stage Change',
          'Multi Step',
          'Create Link Form',
          'Update Link Form',
          'Delete Link Form',
        ].includes(formType)
          ? DefaultSchemaActionFlowTemplate(moduleName, entityName)
          : DefaultSchemaActionTemplate(moduleName, entityName);
      const payload = {
        name: formName,
        schemaId: formSchemaId,
        schemaTypeId: formSchemaTypeId || null,
        isCreate: formType && ['Create Form', 'Create Link Form'].includes(formType),
        isUpdate: formType && ['Update Form', 'Update Link Form'].includes(formType),
        isDelete: formType && ['Delete Form', 'Delete Link Form'].includes(formType),
        isStepFlow: formType && ['Stage Change', 'Multi Step'].includes(formType),
        schemaAssociationId: formSchemaAssociationId || null,
        description: formDescription,
        eventName: formEventName,
        userAction: formUserAction,
        defaultForm: formDefaultForm,
        stages: formStage ? [formStage] : null,
        targetStages: formTargetStage ? [formTargetStage] : null,
        definition: definition,
      };

      const res = await httpPost(`SchemaModule/v1.0/schemas-actions`, payload);

      console.log('%cdebug: Action created!', 'color:limegreen', res);
      alertMessage({ body: 'Action created', type: 'success' });
      onCreate && onCreate(res.data.data);
      setIsCreating(false);
      onClose();
    } catch (error: any) {
      console.error('Error Creating action', error?.message || error);
      alertMessage({ body: 'Error Creating action. ' + error?.message || error, type: 'error' });
      setIsCreating(false);
    }
  };

  const updateAction = async () => {
    try {
      setIsUpdating(true);

      const payload = {
        defaultForm: formDefaultForm,
        description: formDescription,
        eventName: formEventName,
        name: formName,
        schemaAssociationId: formSchemaAssociationId || null,
        schemaId: formSchemaId,
        schemaTypeId: formSchemaTypeId,
        stages: formStage ? [formStage] : [],
        targetStages: formTargetStage ? [formTargetStage] : [],
        userAction: formUserAction,
      };

      const res = await httpPut(`SchemaModule/v1.0/schemas-actions/${schemaAction?.id}`, payload);
      // console.log('%cdebug: Action updated!', 'color:limegreen', res);
      alertMessage({ body: 'Form updated', type: 'success' });
      onUpdate && onUpdate(res.data.data);
      setIsUpdating(false);
      onClose();
    } catch (error: any) {
      console.error('Error updating action', error);
      alertMessage({ body: 'Error updating form', type: 'error' });
      setIsUpdating(false);
    }
  };

  const isAssociationForm =
    formType && ['Create Link Form', 'Update Link Form', 'Delete Link Form'].includes(formType);

  const canUpdateOrCreate = () => {
    // Stage Change form must have a target stage
    if (formType === 'Stage Change' && !formTargetStage) {
      return false;
    }
    // Association form must have a schema association
    else if (isAssociationForm && !formSchemaAssociationId) {
      return false;
    } else {
      return formSchemaId && formName && formDescription && formType;
    }
  };

  const renderSchemaTypeItem: ItemRenderer<ISchemaTypeMenuItem> = (
    schema,
    { handleClick, handleFocus, modifiers },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else
      return (
        <MenuItem
          active={schema.key === formSchemaTypeId}
          disabled={modifiers.disabled}
          key={schema.key}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={schema.name}
        />
      );
  };

  const renderSchemaAssociationItem: ItemRenderer<ISchemaAssociationMenuItem> = (
    association,
    { handleClick, handleFocus, modifiers },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else
      return (
        <MenuItem
          active={association.key === formSchemaAssociationId}
          disabled={modifiers.disabled}
          key={association.key}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={association.name}
        />
      );
  };

  const renderStageItem: ItemRenderer<IStageMenuItem> = (
    stage,
    { handleClick, handleFocus, modifiers },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else
      return (
        <MenuItem
          active={stage.name === formStage}
          disabled={modifiers.disabled}
          key={stage.key}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={stage.name}
        />
      );
  };

  const renderTargetStageItem: ItemRenderer<IStageMenuItem> = (
    targetStage,
    { handleClick, handleFocus, modifiers },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else
      return (
        <MenuItem
          active={targetStage.name === formTargetStage}
          disabled={modifiers.disabled}
          key={targetStage.key}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={targetStage.name}
        />
      );
  };

  let ALL_SCHEMAS = allSchemas.map((schema: any) => {
    return {
      key: schema.id,
      name: schema.entityName,
      value: schema.name,
      entityName: schema.entityName,
      moduleName: schema.moduleName,
    };
  });
  ALL_SCHEMAS = ALL_SCHEMAS.sort((a: any, b: any) => {
    return a.name.localeCompare(b.name);
  });
  if (schemaSearchQuery.length > 0) {
    ALL_SCHEMAS = ALL_SCHEMAS.filter((schema: any) => {
      return schema.name.toLowerCase().includes(schemaSearchQuery.toLowerCase());
    });
  }

  // Schema Associations
  let ALL_SCHEMA_ASSOCIATIONS = allSchemaAssociations.map(
    (association: SchemaAssociationEntity) => {
      return {
        key: association.id,
        name: association.label,
        label: association.label,
      };
    },
  );
  ALL_SCHEMA_ASSOCIATIONS = ALL_SCHEMA_ASSOCIATIONS?.sort((a: any, b: any) => {
    return a.label.localeCompare(b.label);
  });
  if (associationSearchQuery.length > 0) {
    ALL_SCHEMA_ASSOCIATIONS = ALL_SCHEMA_ASSOCIATIONS?.filter((association: any) => {
      return association.label.toLowerCase().includes(associationSearchQuery.toLowerCase());
    });
  }

  const getSelectedSchemaName = () => {
    if (allSchemas.length > 0 && formSchemaId) {
      const schema = allSchemas.find((s: any) => s.id === formSchemaId);
      return schema.entityName;
    } else {
      return '';
    }
  };

  const getSelectedSchemaTypeName = () => {
    if (schemaTypes.length > 0 && formSchemaTypeId) {
      const schemaType = schemaTypes.find((s: SchemaTypeEntity) => s.id === formSchemaTypeId);
      return schemaType?.name;
    } else {
      return '';
    }
  };

  const getSelectedSchemaAssociationName = () => {
    if (allSchemaAssociations.length > 0 && formSchemaAssociationId) {
      const association = allSchemaAssociations.find((s: any) => s.id === formSchemaAssociationId);
      return association?.label;
    } else {
      return 'Select Schema Association';
    }
  };

  let SCHEMA_TYPES: ISchemaTypeMenuItem[] = schemaTypes.map((schemaType: SchemaTypeEntity) => {
    return {
      key: schemaType.id,
      name: schemaType.name,
    };
  });
  //  Add first option to schema type (None)
  SCHEMA_TYPES.unshift({
    key: 'none',
    name: '(None)',
  });

  let STAGES: IStageMenuItem[] = stages.map((stage: PipelineStageEntity) => {
    return {
      key: stage.id,
      name: stage.key,
    };
  });
  // Add first option to stages (None)
  STAGES.unshift({
    key: 'none',
    name: '(None)',
  });

  const createActionMenu = () => {
    return (
      <Menu>
        {[
          { type: 'Create Form' },
          { type: 'Update Form' },
          { type: 'Delete Form' },
          { type: 'DIVIDER' },
          { type: 'Stage Change' },
          { type: 'Multi Step' },
          { type: 'DIVIDER' },
          { type: 'Create Link Form' },
          { type: 'Update Link Form' },
          { type: 'Delete Link Form' },
        ].map(({ type }) =>
          type === 'DIVIDER' ? (
            <MenuDivider key={type} />
          ) : (
            <MenuItem
              key={type}
              text={type}
              onClick={() => {
                setFormType(type as ICreateActionFormType);
                setIsOpen(true);
              }}
            />
          ),
        )}
      </Menu>
    );
  };

  return (
    <>
      {/*  Create Dropdown */}
      {mode === 'CREATE' && (
        <Popover content={createActionMenu()} placement="bottom">
          <Button
            fill
            alignText="start"
            disabled={isOpen}
            intent="success"
            endIcon="caret-down"
            text="New Action"
            {...buttonProps}
          />
        </Popover>
      )}

      {/*  Edit Button */}
      {mode === 'EDIT' && (
        <Button
          fill
          onClick={() => setIsOpen(true)}
          disabled={isOpen}
          intent="none"
          text="Edit Action"
          icon="annotation"
          {...buttonProps}
        />
      )}

      <Dialog
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        isOpen={isOpen}
        onClose={onClose}
        usePortal
        title={
          mode === 'EDIT' ? `Edit ${schemaAction?.name} (${formType})` : `New Action (${formType})`
        }
      >
        <DialogBody>
          <Row>
            {/* Schema Field (All Types) */}
            <Col span={24}>
              <Label style={{ marginBottom: 5 }}>
                Entity<span style={{ color: 'red' }}> *</span>
              </Label>
              <Select<ISchemaMenuItem>
                items={ALL_SCHEMAS}
                resetOnClose
                disabled={allSchemas.length === 0}
                itemRenderer={renderSchemaItem}
                noResults={<MenuItem disabled={true} text="No results." roleStructure="menuitem" />}
                onItemSelect={(schema: any) => {
                  setFormSchemaId(schema.key);
                  getSchemaTypesBySchemaId(schema.key);
                  setFormSchemaTypeId(undefined);
                  setFormStage(undefined);
                  setFormTargetStage(undefined);
                  setFormSchemaAssociationId(undefined);
                }}
                query={schemaSearchQuery}
                onQueryChange={(e: any) => {
                  setSchemaSearchQuery(e);
                }}
              >
                <Button
                  outlined={!!formSchemaId}
                  alignText="left"
                  style={{ backgroundColor: formSchemaId ? 'white' : undefined }}
                  disabled={allSchemas.length === 0 || mode === 'EDIT' || !!defaultSchemaId}
                  text={formSchemaId ? getSelectedSchemaName() : 'Select Entity'}
                  endIcon="caret-down"
                  fill
                />
              </Select>
            </Col>

            {/* Schema Type (All Types) */}
            <Col span={24} style={{ marginTop: 15 }}>
              <Label style={{ marginBottom: 5 }}>Schema Type</Label>
              <Select<ISchemaTypeMenuItem>
                items={SCHEMA_TYPES}
                resetOnClose
                filterable={false}
                disabled={allSchemas.length === 0}
                itemRenderer={renderSchemaTypeItem}
                onItemSelect={(schema: any) => {
                  if (schema.key === 'none') {
                    setFormSchemaTypeId(undefined);
                    setFormStage(undefined);
                    setFormTargetStage(undefined);
                    setStages([]);
                  } else {
                    setFormSchemaTypeId(schema.key);
                  }
                  setFormStage(undefined);
                  setFormTargetStage(undefined);
                }}
              >
                <Button
                  style={{ backgroundColor: formSchemaTypeId ? 'white' : undefined }}
                  outlined={!!formSchemaTypeId}
                  alignText="left"
                  disabled={!formSchemaId || schemaTypes.length === 0}
                  text={formSchemaTypeId ? getSelectedSchemaTypeName() : 'Select Type'}
                  endIcon="caret-down"
                  fill
                />
              </Select>
            </Col>

            {/* Schema Association Field */}
            {formType &&
              ['Create Link Form', 'Update Link Form', 'Delete Link Form'].includes(formType) && (
                <Col span={24} style={{ marginTop: 15 }}>
                  <Label style={{ marginBottom: 5 }}>
                    Schema Association <span style={{ color: 'red' }}>*</span>
                  </Label>
                  <Select<ISchemaAssociationMenuItem>
                    items={ALL_SCHEMA_ASSOCIATIONS}
                    resetOnClose
                    filterable={true}
                    disabled={allSchemaAssociations.length === 0}
                    itemRenderer={renderSchemaAssociationItem}
                    onItemSelect={(schema: any) => {
                      setFormSchemaAssociationId(schema.key);
                    }}
                    noResults={
                      <MenuItem disabled={true} text="No results." roleStructure="menuitem" />
                    }
                    query={associationSearchQuery}
                    onQueryChange={(e: any) => {
                      setAssociationSearchQuery(e);
                    }}
                  >
                    <Button
                      style={{ backgroundColor: formSchemaAssociationId ? 'white' : undefined }}
                      outlined={!!formSchemaAssociationId}
                      alignText="left"
                      disabled={!formSchemaId || allSchemaAssociations.length === 0}
                      text={
                        formSchemaAssociationId
                          ? getSelectedSchemaAssociationName()
                          : 'Select Association'
                      }
                      endIcon="caret-down"
                      fill
                    />
                  </Select>
                </Col>
              )}

            <Col span={24} style={{ marginTop: 15 }}>
              <Divider style={{ marginLeft: 0, marginRight: 0 }} />
            </Col>

            {/* Action Name */}
            <Col span={24} style={{ marginTop: 10 }}>
              <Label style={{ marginBottom: 5 }}>
                Action Name <span style={{ color: 'red' }}>*</span>
              </Label>
              <InputGroup
                fill
                type="text"
                placeholder="Action Name"
                value={formName}
                disabled={!formSchemaId}
                onChange={(e: any) => setFormName(e.target.value)}
              />
            </Col>

            {/* Description */}
            <Col span={24} style={{ marginTop: 15 }}>
              <Label style={{ marginBottom: 5 }}>
                Action Description<span style={{ color: 'red' }}> *</span>
              </Label>
              <InputGroup
                fill
                type="text"
                placeholder="Description"
                value={formDescription}
                disabled={!formSchemaId}
                onChange={(e: any) => setFormDescription(e.target.value)}
              />
            </Col>

            {/* Schema Action Type - Hidden for now */}
            {/* <Col span={12} style={{ marginTop: 15, paddingRight: 5 }}>
              <Label style={{ marginBottom: 5 }}>
                Action Type<span style={{ color: 'red' }}> *</span>
              </Label>
              <Select<ISchemaTypeMenuItem>
                items={[
                  { key: 'CREATE', name: 'Create' },
                  { key: 'UPDATE', name: 'Update' },
                  { key: 'TRANSITION', name: 'Transition' },
                ]}
                resetOnClose
                filterable={false}
                disabled={allSchemas.length === 0}
                itemRenderer={renderSchemaTypeItem}
                onItemSelect={(actionType: any) => {
                  setFormActionType(actionType.key);
                }}
              >
                <Button
                  rightIcon="caret-down"
                  fill
                  alignText="left"
                  style={{ backgroundColor: formActionType ? 'white' : undefined }}
                  outlined={!!formActionType}
                  disabled={!formSchemaId}
                  text={formActionType ? toSentenceCase(formActionType) : 'Select Action Type'}
                />
              </Select>
            </Col> */}

            {/* Event name */}
            <Col span={24} style={{ marginTop: 15 }}>
              <Label style={{ marginBottom: 5 }}>
                Event name
                {/*<span style={{ color: 'red' }}> *</span>*/}
              </Label>
              <InputGroup
                fill
                type="text"
                placeholder="Event name"
                value={formEventName}
                disabled={!formSchemaId}
                onChange={(e: any) => setFormEventName(e.target.value)}
              />
            </Col>

            {/* Stage */}
            <Col span={24} style={{ marginTop: 15 }}>
              <Label style={{ marginBottom: 5 }}>Stage</Label>
              <Select<ISchemaTypeMenuItem>
                items={STAGES}
                resetOnClose
                filterable={false}
                disabled={allSchemas.length === 0}
                itemRenderer={renderStageItem}
                onItemSelect={(actionType: any) => {
                  if (actionType.key === 'none') {
                    setFormStage(undefined);
                  } else {
                    setFormStage(actionType.name);
                  }
                }}
              >
                <Button
                  style={{ backgroundColor: formStage ? 'white' : undefined }}
                  outlined={!!formStage}
                  alignText="left"
                  disabled={
                    !stages.length || !formSchemaId || (schemaTypes.length > 0 && !formSchemaTypeId)
                  }
                  text={formStage ? formStage : 'Select Stage'}
                  endIcon="caret-down"
                  fill
                />
              </Select>
            </Col>

            {/* Target Stage */}
            {formType === 'Stage Change' && (
              <Col span={24} style={{ marginTop: 15 }}>
                <Label style={{ marginBottom: 5 }}>
                  Target stage <span style={{ color: 'red' }}>*</span>
                </Label>
                <Select<ISchemaTypeMenuItem>
                  items={STAGES}
                  resetOnClose
                  filterable={false}
                  itemRenderer={renderTargetStageItem}
                  onItemSelect={(actionType: any) => {
                    if (actionType.key === 'none') {
                      setFormTargetStage(undefined);
                    } else {
                      setFormTargetStage(actionType.name);
                    }
                  }}
                >
                  <Button
                    style={{ backgroundColor: formTargetStage ? 'white' : undefined }}
                    outlined={!!formTargetStage}
                    alignText="left"
                    disabled={
                      !stages.length ||
                      !formSchemaId ||
                      (schemaTypes.length > 0 && !formSchemaTypeId)
                    }
                    text={formTargetStage ? formTargetStage : 'Select Target stage'}
                    endIcon="caret-down"
                    fill
                  />
                </Select>
              </Col>
            )}

            {/* User Action / Default Form */}
            {formType !== 'Stage Change' && (
              <>
                <Col span={6} style={{ marginTop: 15 }}>
                  <Label>User Action</Label>
                  <Switch
                    size="large"
                    disabled={!formSchemaId}
                    checked={formUserAction}
                    onChange={() => {
                      setFormUserAction(!formUserAction);
                      setFormDefaultForm(false);
                    }}
                  />
                </Col>

                {formType !== 'Multi Step' && (
                  <Col span={6} style={{ marginTop: 15 }}>
                    <Label>Default Form</Label>
                    <Switch
                      size="large"
                      disabled={!formSchemaId}
                      checked={formDefaultForm}
                      onChange={() => {
                        setFormDefaultForm(!formDefaultForm);
                        setFormUserAction(false);
                      }}
                    />
                  </Col>
                )}
              </>
            )}
          </Row>
        </DialogBody>

        <DialogFooter
          actions={[
            <Button key="cancel" onClick={onClose} text="Close" />,
            <Button
              disabled={!canUpdateOrCreate()}
              loading={isUpdating || isCreating}
              key="submit"
              intent="primary"
              onClick={mode === 'CREATE' ? createAction : updateAction}
              text={mode === 'CREATE' ? 'Submit' : 'Save'}
            />,
          ]}
        />
      </Dialog>
    </>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(SchemaActionEditCreateForm);
