import { Button } from '@blueprintjs/core';
import DetailViewContextProvider from '@core/components/DetailViewContextProvider';
import { canUserGetRecord, isSystemAdmin } from '@core/helpers/rbacRules';
import {
  getRecordFromShortListById,
  getRecordRelatedFromShortListById,
} from '@core/helpers/recordHelpers';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import { httpGet } from '@core/http/requests';
import { getGroupsDataRequest } from '../../../redux/stores/identityGroups/actions';
import { IdentityGroupsReducer } from '../../../redux/stores/identityGroups/reducer';
import { addTabToHistory } from '../../../redux/stores/navigation/actions';
import { getRecordAuditLogs, IGetRecordAuditLogs } from '../../../redux/stores/recordAudit/actions';
import {
  getRecordByIdRequest,
  IGetRecordById,
  IRestoreRecordById,
  restoreRecordByIdRequest,
} from '../../../redux/stores/records/actions';
import { IRecordReducer } from '../../../redux/stores/records/reducer';
import {
  getRecordAssociationByIdRequest,
  IGetRecordAssociationById,
} from '../../../redux/stores/recordAssociations/actions';
import { IRecordAssociationsReducer } from '../../../redux/stores/recordAssociations/reducer';
import Error403 from '@core/pages/403';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Card, Col, Layout, Result, Row } from 'antd';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ActivityCenter from '../ActivityCenter';
import './styles.scss';

type PathParams = {
  url: string;
  recordId: string;
};

type PropsType = RouteComponentProps<PathParams> & {
  moduleName: string;
  userReducer: any;
  recordReducer: IRecordReducer;
  addToHistory: any;
  getRecordById: (payload: IGetRecordById, cb?: any) => void;
  getRelatedRecordById: any;
  getAuditLogs: (
    params: IGetRecordAuditLogs,
    cb?: (params: { recordId: string; results: any[] }) => void,
  ) => void;
  entityName?: string;
  children?: any;
  match: any;
  identityGroupsReducer: IdentityGroupsReducer;
  getGroupsList: () => {};
  restoreRecord: (param: IRestoreRecordById, cb: (res: any) => {}) => {};
  recordAssociationReducer: IRecordAssociationsReducer;
  overrideRecordId?: string;
  skipLoadingUserActivity?: boolean;
};

interface State {
  record: DbRecordEntityTransform | null | undefined;
  schema: SchemaEntity | undefined;
  pipeline: PipelineEntity | undefined;
  isLoadingRecord: boolean;
  hasColumnMapping?: boolean;
}

class DetailViewContext extends React.Component<PropsType, State> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      record: undefined,
      schema: undefined,
      pipeline: undefined,
      isLoadingRecord: false,
      hasColumnMapping: false,
    };
  }

  componentDidMount(): void {
    this.fetchData();
    this.loadGroups();
  }

  private loadGroups() {
    const { identityGroupsReducer, getGroupsList } = this.props;
    if (identityGroupsReducer.list?.length < 1 && !identityGroupsReducer.isRequesting) {
      getGroupsList();
    }
  }

  componentDidUpdate(prevProps: Readonly<PropsType>): void {
    if (prevProps.userReducer.user === null && this.props.userReducer.user) {
      this.fetchData();
    } else if (prevProps.entityName !== this.props.entityName) {
      this.fetchData();
    } else if (prevProps.match.url !== this.props.match.url) {
      this.fetchData();
    } else if (
      this.props.overrideRecordId &&
      prevProps.overrideRecordId &&
      prevProps.overrideRecordId !== this.props.overrideRecordId
    ) {
      this.fetchData();
    } else if (prevProps.recordReducer.isRequesting !== this.props.recordReducer.isRequesting) {
      this.handleRouteChange();
    }
  }

  private getTabTitle() {
    const { match, recordReducer } = this.props;
    const record = getRecordFromShortListById(recordReducer.shortList, match.params.recordId);

    if (record) {
      if (record.title && record.recordNumber) {
        return `${record.recordNumber} ${record.title}`;
      } else if (record.title && !record.recordNumber) {
        return record.title;
      } else if (record.type && record.recordNumber) {
        return `${record.recordNumber} ${record.type}`;
      } else if (record.type && !record.recordNumber) {
        return record.type;
      } else if (record.recordNumber) {
        return record.recordNumber;
      } else {
        return match.url;
      }
    } else {
      return match.url;
    }
  }

  handleRouteChange() {
    const { addToHistory, match, recordReducer, getRecordById } = this.props;
    const record = getRecordFromShortListById(recordReducer.shortList, match.params.recordId);
    if (record) {
      addToHistory({ path: match.url, title: this.getTabTitle() });
    }
  }

  attemptToRestoreRecord = async () => {
    const { match, moduleName, entityName, restoreRecord } = this.props;

    let localEntityName = entityName ? entityName : match.params.entityName;
    const schema = await getOdinSchemaByEntity(moduleName, localEntityName);

    if (schema) {
      restoreRecord(
        {
          schema,
          recordId: match.params.recordId,
        },
        (res: any) => {
          window.location.reload();
          return res;
        },
      );
    }
  };

  async fetchData() {
    const {
      match,
      getRecordById,
      getRelatedRecordById,
      getAuditLogs,
      moduleName,
      entityName,
      overrideRecordId,
      skipLoadingUserActivity,
    } = this.props;

    const recordId = overrideRecordId || match?.params?.recordId;
    const relatedRecordId = match?.params?.relatedRecordId;
    const relatedRecordModuleName = match?.params?.relatedRecordModuleName;
    const relatedRecordEntityName = match?.params?.relatedRecordEntityName;
    const dbRecordAssociationId = match?.params?.dbRecordAssociationId;

    let entity = entityName ? entityName : match?.params?.entityName;

    const getRecordAndAuditLogs = (schema: SchemaEntity) => {
      // Related detail view
      if (
        dbRecordAssociationId &&
        recordId &&
        relatedRecordId &&
        relatedRecordEntityName &&
        relatedRecordModuleName
      ) {
        this.setState({ isLoadingRecord: true });
        getRelatedRecordById(
          {
            recordId,
            dbRecordAssociationId,
            parentSchema: schema,
            relatedEntityName: relatedRecordEntityName,
          },
          (res: any) => {
            if (res) {
              this.setState({ record: res });
              this.setState({ isLoadingRecord: false });
              this.setState({ hasColumnMapping: true });
            } else {
              this.setState({ isLoadingRecord: false });
              this.setState({ record: null });
            }
          },
        );

        if (!skipLoadingUserActivity) {
          getAuditLogs({ recordId, schema: schema });
        }
      } else {
        // Fetch the record, and if there's none - set state record to null
        this.setState({ isLoadingRecord: true });
        getRecordById({ schema: schema, recordId }, (res: any) => {
          if (res) {
            this.setState({ record: res });
            this.setState({ isLoadingRecord: false });
            this.fetchPipeline(res);
          } else {
            this.setState({ record: null });
            this.setState({ isLoadingRecord: false });
          }
        });
        if (!skipLoadingUserActivity) {
          getAuditLogs({ recordId, schema: schema });
        }
      }
    };

    const schema = await getOdinSchemaByEntity(moduleName, entity);
    this.setState({ schema });
    getRecordAndAuditLogs(schema);
  }

  fetchPipeline = async (record: DbRecordEntityTransform) => {
    const { entityName, match, moduleName } = this.props;

    let entity = entityName ? entityName : match?.params?.entityName;
    const schema = await getOdinSchemaByEntity(moduleName, entity);

    if (record && schema) {
      httpGet(
        `SchemaModule/v1.0/pipelines/bymodule/${schema.moduleName}/${schema.entityName}${
          record.type ? `?schemaType=${record.type}` : '?schemaType='
        }`,
      ).then((res: any) => {
        const pipelines = res.data?.data || [];
        if (pipelines.length > 0) {
          // console.log('%cdebug: LegacyPipeline Response: ', 'color:salmon', pipelines[0]);
          this.setState({ pipeline: pipelines[0] });
        }
      });
    }
  };

  render() {
    const { userReducer, match, overrideRecordId, recordReducer, recordAssociationReducer } =
      this.props;
    const { schema, pipeline } = this.state;

    const recordId = overrideRecordId || match?.params?.recordId;
    const dbRecordAssociationId = match?.params?.dbRecordAssociationId;

    // We use the shortlisted record, because it will be updated when the record changes
    const record =
      getRecordFromShortListById(recordReducer.shortList, recordId) ||
      getRecordRelatedFromShortListById(
        recordAssociationReducer.shortList,
        dbRecordAssociationId,
        recordId,
      );

    // IF user cannot access => Show a 403 page
    if (schema && userReducer && !canUserGetRecord(userReducer!, schema!)) {
      return <Error403 />;
    }

    // IF record is not found => Show a delete Page
    if (this.state.record === null && !this.state.isLoadingRecord && schema) {
      return (
        <Layout className="record-detail-view" style={{ margin: isMobile ? 10 : 30 }}>
          <Row>
            <Col span={24}>
              <Card>
                <div
                  style={{
                    padding: 10,
                    margin: '20px 20px 0px 20px',
                    background: '#fff',
                    borderRadius: 10,
                  }}
                >
                  <Result
                    status="warning"
                    title="Record could not be found."
                    subTitle={`This record was either deleted or doesn't exist at all. ${
                      isSystemAdmin(userReducer) ? 'You can try to restore the record.' : ''
                    }`}
                    extra={
                      isSystemAdmin(userReducer) ? (
                        <Button
                          icon="undo"
                          onClick={this.attemptToRestoreRecord}
                          intent="primary"
                          text="Restore Record"
                          large
                          style={{ marginTop: 20 }}
                        />
                      ) : (
                        <></>
                      )
                    }
                  />
                </div>
              </Card>
            </Col>
            {isSystemAdmin(userReducer) && (
              <Col span={24}>
                <Card title="Deleted Record Activity" style={{ marginTop: 20 }}>
                  <ActivityCenter
                    hideNoteTab
                    record={{ id: match.params.recordId, properties: {} }}
                    schema={this.state.schema!}
                  />
                </Card>
              </Col>
            )}
          </Row>
        </Layout>
      );
    }

    // IF record is found => Show the record detail view
    if (this.state.record !== null && record && schema && !this.state.isLoadingRecord) {
      return (
        <DetailViewContextProvider record={record} schema={schema} pipeline={pipeline}>
          <div className="detailViewWrapper">{this.props.children}</div>
        </DetailViewContextProvider>
      );
    }

    return <></>;
  }
}

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
  identityGroupsReducer: state.identityGroupsReducer,
});

const mapDispatch = (dispatch: any) => ({
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
  getRelatedRecordById: (payload: IGetRecordAssociationById, cb: any) =>
    dispatch(getRecordAssociationByIdRequest(payload, cb)),
  addToHistory: (params: { path: string; title: string }) => dispatch(addTabToHistory(params)),
  getAuditLogs: (
    params: IGetRecordAuditLogs,
    cb?: (params: { recordId: string; results: any[] }) => void,
  ) => dispatch(getRecordAuditLogs(params, cb)),
  getGroupsList: () => dispatch(getGroupsDataRequest()),
  restoreRecord: (payload: any, cb: any) => dispatch(restoreRecordByIdRequest(payload, cb)),
});

export default withRouter(connect(mapState, mapDispatch)(DetailViewContext));
