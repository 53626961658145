import { Button, Dialog, DialogBody, DialogFooter, Spinner } from '@blueprintjs/core';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { SchemaAssociationSchemaTypesConstraintEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/constraint/schema.association.schema.types.constraint.entity';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaAssociationCardinalityTypes } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/types/schema.association.cardinality.types';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';

import {
  CreateSchemaAssociation,
  createSchemaAssociationRequest,
  UpdateSchemaAssociation,
  updateSchemaAssociationRequest,
} from '@redux/stores/schemasAssociations/actions';

import { isMobile } from 'react-device-detect';
import { ManageLinkForm } from './ManageLinkForm';
import { parseSchema } from './ManageLinkForm/SchemaDropdown';
import { Action, Cardinality, TPanelDataItem } from './ManageLinkForm/types';

interface ManageLinksDialogProps {
  schema: SchemaEntity;
  link?: SchemaAssociationEntity;
  isOpen: boolean;
  onClose?: () => void;
  onCreate?: (association: SchemaAssociationEntity) => void;
  onUpdate?: (association: SchemaAssociationEntity) => void;
  // Redux props
  isSaving: boolean;
  createSchemaAssociation: (params: CreateSchemaAssociation, cb: any) => void;
  updateSchemaAssociation: (params: UpdateSchemaAssociation, cb: any) => void;
}

const getCardinality = (parent: Cardinality, child: Cardinality) => {
  return `${parent.toLocaleUpperCase()}_TO_${child.toLocaleUpperCase()}` as SchemaAssociationCardinalityTypes;
};

const parseCardinality = (type: SchemaAssociationCardinalityTypes) => {
  const cardinalityMap = { ONE: 'One', MANY: 'Many' };
  return type.split('_TO_').map((c) => cardinalityMap[c as 'ONE' | 'MANY']) as [
    Cardinality,
    Cardinality,
  ];
};

const ManageLinksDialog: FC<ManageLinksDialogProps> = (props) => {
  const {
    schema,
    link,
    onClose,
    isSaving,
    createSchemaAssociation,
    updateSchemaAssociation,
    onUpdate,
    onCreate,
  } = props;

  // Form state - Parent
  const [parentSchema, setParentSchema] = useState<TPanelDataItem | null>(parseSchema(schema));
  const [parentCardinality, setParentCardinality] = useState<Cardinality>('One');
  const [parentAction, setParentAction] = useState<Action>('READ_ONLY');

  // Form state - Child
  const [childSchema, setChildSchema] = useState<TPanelDataItem | null>(null);
  const [childCardinality, setChildCardinality] = useState<Cardinality>('One');
  const [childAction, setChildAction] = useState<Action>('READ_ONLY');

  // Form state - Settings
  const [name, setName] = useState('');
  const [pluralName, setPluralName] = useState('');
  const [apiName, setApiName] = useState('');
  const [position, setPosition] = useState(0);
  const [cascadeDelete, setCascadeDelete] = useState(false);
  const [linkProperties, setLinkProperties] = useState(false);
  const [isSearchable, setIsSearchable] = useState(false);
  const [isDeletable, setIsDeletable] = useState(false);
  const [processDependency, setProcessDependency] = useState(false);

  // Form state - Schema Type Constraints
  const [schemaTypesConstraints, setSchemaTypesConstraints] = useState<
    SchemaAssociationSchemaTypesConstraintEntity[]
  >([]);

  // Diagnostics
  // useEffect(() => {
  //   if (link) {
  //     console.log('%cdebug: Link', 'color:aquamarine', link);
  //   }
  // }, [link]);

  // Sync state with link passed in props
  // to allow editing of existing link
  useEffect(() => {
    if (!link) return;

    const [parentCardinality, childCardinality] = parseCardinality(link.type);
    const parentSchema =
      schema.id === link.parentSchemaId
        ? parseSchema(schema)
        : parseSchema(link.parentSchema ?? (link.childSchema as SchemaEntity));

    const childSchema =
      schema.id === link.childSchemaId
        ? parseSchema(schema)
        : parseSchema(link.childSchema ?? (link.parentSchema as SchemaEntity));

    // Load Parent data
    setParentSchema(parentSchema);
    setParentCardinality(parentCardinality);
    setParentAction(link.parentActions as Action);

    // Load Child data
    setChildSchema(childSchema);
    setChildCardinality(childCardinality);
    setChildAction(link.childActions as Action);

    // Load Settings data
    setApiName(link.label);
    setPosition(link.position);
    setCascadeDelete(link.cascadeDeleteChildRecord);
    setLinkProperties(link.hasColumnMappings);
    setIsSearchable(link.searchable);
    setIsDeletable(link.isDeletable);
    setProcessDependency((link as any).processDependencyEnabled || false);

    // Load Schema Type Constraints data
    setSchemaTypesConstraints(link.schemaTypesConstraints);
  }, [link?.id]);

  // Local functions
  const closeModal = () => {
    onClose?.();
  };

  const isSaveButtonDisabled = () => {
    // Validate state before submitting
    // const requiredFieldsEmpty = !childSchema || !name || !pluralName || !apiName
    const requiredFieldsEmpty = !childSchema || !apiName;
    const invalidSchemas = parentSchema?.id !== schema.id && childSchema?.id !== schema.id;
    const fieldsChanged = true; // checkFieldsChanged(link, formState);
    return requiredFieldsEmpty || invalidSchemas || !fieldsChanged;
  };

  const saveLink = () => {
    // update/create link
    if (!parentSchema || !childSchema) return;

    if (props.link) {
      // Edit link
      const updatedLink = {
        ...link,
        type: getCardinality(parentCardinality, childCardinality),
        parentActions: parentAction,
        childSchemaId: childSchema.id,
        childActions: childAction,
        name,
        pluralName,
        label: apiName,
        position,
        cascadeDeleteChildRecord: cascadeDelete,
        hasColumnMappings: linkProperties,
        searchable: isSearchable,
        isDeletable,
        schemaTypesConstraints,
        processDependencyEnabled: processDependency,
      } as SchemaAssociationEntity;

      updateSchemaAssociation(
        {
          associationId: props.link.id,
          schemaId: parentSchema.id,
          body: updatedLink,
        },
        (res: any) => {
          if (onUpdate) onUpdate(res);
          if (onClose) onClose();
        },
      );
    } else {
      // Create link
      const newLink = {
        type: getCardinality(parentCardinality, childCardinality),
        parentActions: parentAction,
        childSchemaId: childSchema.id,
        childActions: childAction,
        name,
        pluralName,
        label: apiName,
        cascadeDeleteChildRecord: cascadeDelete,
        hasColumnMappings: linkProperties,
      };

      createSchemaAssociation(
        {
          schemaId: parentSchema.id,
          body: newLink,
        },
        (res: any) => {
          if (onCreate) onCreate(res);
          if (onClose) onClose();
        },
      );
    }
  };

  return (
    <Dialog
      title={props.link ? 'Edit Link' : 'Create Link'}
      isOpen={props.isOpen}
      onClose={closeModal}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      style={{ minWidth: isMobile ? '100%' : '50%' }}
      className="manage-links-dialog"
    >
      <DialogBody
        style={{
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: 1,
        }}
      >
        <ManageLinkForm
          schema={schema}
          link={link}
          parentProps={{
            schema: parentSchema,
            setSchema: setParentSchema,
            cardinality: parentCardinality,
            setCardinality: setParentCardinality,
            action: parentAction,
            setAction: setParentAction,
          }}
          childProps={{
            schema: childSchema,
            setSchema: setChildSchema,
            cardinality: childCardinality,
            setCardinality: setChildCardinality,
            action: childAction,
            setAction: setChildAction,
          }}
          settingsProps={{
            name,
            setName,
            pluralName,
            setPluralName,
            apiName,
            setApiName,
            position,
            setPosition,
            cascadeDelete,
            setCascadeDelete,
            linkProperties,
            setLinkProperties,
            isSearchable,
            setIsSearchable,
            isDeletable,
            setIsDeletable,
            processDependency,
            setProcessDependency,
          }}
          typeConstraintsProps={{
            parentSchema,
            childSchema,
            schemaTypesConstraints,
            setSchemaTypesConstraints,
          }}
        />
      </DialogBody>
      <DialogFooter
        actions={[
          <Button key="Close" text="Close" onClick={closeModal} />,
          <Button
            key="SaveChanges"
            text="Save Changes"
            icon={isSaving ? <Spinner size={10} /> : undefined}
            disabled={isSaveButtonDisabled() || isSaving}
            intent="primary"
            onClick={() => saveLink()}
            loading={isSaving}
          />,
        ]}
      />
    </Dialog>
  );
};

const mapState = (state: any) => ({
  isSaving: state.schemaAssociationReducer.isRequesting,
});

const mapDispatch = (dispatch: any) => ({
  createSchemaAssociation: (params: CreateSchemaAssociation, cb: any) =>
    dispatch(createSchemaAssociationRequest(params, cb)),
  updateSchemaAssociation: (params: UpdateSchemaAssociation, cb: any) =>
    dispatch(updateSchemaAssociationRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(ManageLinksDialog);
