import { Alignment, Button, Icon, Navbar, Section, Spinner } from '@blueprintjs/core';
import { SchemaDetailsContext } from '@core/modules/ControlPanelModule/components/SchemaManager/SchemaDetailsView';
import PipelineSection from '@core/modules/ControlPanelModule/components/SchemaManager/SchemaDetailsView/SchemaDetails/PipelineSection';
import { displayMessage } from '@redux/stores/messages/reducers';
import { Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ActionsSection } from './ActionsSection';
import { DetailsSection } from './DetailsSection';
import { SchemaTypesSelect } from './DetailsSection/SchemaTypesSelect';
import { Empty } from './Empty';
import LinksSection from './LinksSection';
import { PermissionsSection } from './PermissionsSection';
import PropertiesSection from './PropertiesSection';
import SchemaSettingsSection from './SchemaSettingsSection';
import TypesSection from './TypesSection';

type Props = {
  loading: boolean;
  schemaDetailsRef: React.RefObject<HTMLDivElement>;
  alertMessage: (params: { body: string; type: string }) => void;
};

const SchemaDetails = (props: Props) => {
  const { loading, schemaDetailsRef, alertMessage } = props;
  const [schemaTypeId, setSchemaTypeId] = useState<string | null>(null);
  const [isNavbarVisible, setIsNavbarVisible] = useState<boolean>(false);
  const [sectionWidth, setSectionWidth] = useState<string | undefined>(undefined);
  const [favoriteSchemas, setFavoriteSchemas] = useState<string[]>([]);

  const { schema, saveSchema, isSavingSchema, updatedSchema } = useContext(SchemaDetailsContext);

  const isSchemaUpdated = updatedSchema && JSON.stringify(updatedSchema) !== JSON.stringify(schema);

  useEffect(() => {
    fetchFavoriteSchemas();
  }, []);

  const fetchFavoriteSchemas = () => {
    const favorites = localStorage.getItem('OdinSchemaFavorites');
    if (favorites) {
      setFavoriteSchemas(JSON.parse(favorites));
    }
  };

  // On component mount, set section width to schemaDetailsRef.current.clientWidth
  useEffect(() => {
    if (schemaDetailsRef.current) {
      setSectionWidth(`${schemaDetailsRef.current.clientWidth}px`);
    }
  }, [schemaDetailsRef.current]);

  // When window size is updated, add event listener to update section width
  useEffect(() => {
    const handleResize = () => {
      setSectionWidth(`${schemaDetailsRef.current?.clientWidth}px`);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [schemaDetailsRef.current]);

  useEffect(() => {
    const handleScroll = () => {
      if (schemaDetailsRef.current) {
        setIsNavbarVisible(schemaDetailsRef.current.scrollTop > 100);
      }
    };

    const currentRef = schemaDetailsRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [schemaDetailsRef.current, isNavbarVisible]);

  useEffect(() => {
    if (!schema?.id) return;

    if (schema.types?.length) {
      setSchemaTypeId('all');
    }
  }, [schema?.id]);

  if (loading || !schema)
    return (
      <div className="loading-page">
        <Spinner />
      </div>
    );

  const addOrRemoveFavorite = () => {
    const id = schema?.id;
    let favorites = Object.assign([], favoriteSchemas);

    //  Remove
    if (favorites.includes(id)) {
      const index = favorites.indexOf(id);
      if (index > -1) {
        favorites.splice(index, 1);
      }
      alertMessage({
        body: 'Entity removed from Bookmarks',
        type: 'success',
      });
    }
    // Add
    else {
      favorites.push(id);
      alertMessage({
        body: 'Entity added to Bookmarks',
        type: 'success',
      });
    }
    setFavoriteSchemas(favorites);
    localStorage.setItem('OdinSchemaFavorites', JSON.stringify(favorites));
  };

  const isSchemaFavorite = favoriteSchemas.includes(schema?.id);

  const saveSchemaButton = () => {
    return (
      <Button
        disabled={!isSchemaUpdated}
        loading={isSavingSchema}
        style={{ borderRadius: 5 }}
        intent="primary"
        onClick={saveSchema}
      >
        Save Changes
      </Button>
    );
  };

  const addRemoveFavoriteButton = () => {
    return (
      <Button
        onClick={addOrRemoveFavorite}
        style={{ borderRadius: 5 }}
        icon={<Icon icon="star" size={12} color={isSchemaFavorite ? '#F0B726' : '#8F99A8'} />}
      />
    );
  };

  return (
    <div>
      {/* Sticky Top Navigation */}
      <Row
        style={{
          position: 'fixed',
          top: 38,
          width: sectionWidth,
          zIndex: 100,
          boxShadow: '0 10px 9px -9px rgb(217, 218, 218)',
          height: isNavbarVisible ? 'auto' : 1,
          opacity: isNavbarVisible ? 1 : 0,
          transition: 'opacity 0.3s',
        }}
      >
        <Col span={24}>
          <Navbar title={schema.name} className="controlPanelSchemaDetailsNavbar">
            <Navbar.Group align={Alignment.LEFT}>
              <Navbar.Heading style={{ fontWeight: 600 }}>{schema.name}</Navbar.Heading>
            </Navbar.Group>

            {/*  Right */}
            <Navbar.Group align={Alignment.RIGHT}>
              {addRemoveFavoriteButton()}
              <div style={{ marginRight: 8 }} />
              {saveSchemaButton()}
            </Navbar.Group>
          </Navbar>
        </Col>
      </Row>

      {/* Schema detail view */}
      <div
        ref={schemaDetailsRef}
        style={{
          padding: '20px 30px',
          background: 'white',
          display: 'grid',
          gap: '1rem',
          height: 'calc(100vh - 40px)',
          overflowY: 'auto',
        }}
      >
        {/* Top Section */}
        <Row>
          <Col span={24}>
            <DetailsSection
              schema={schema}
              rightElement={
                <>
                  <SchemaTypesSelect
                    schemaTypes={schema.types}
                    selected={schemaTypeId}
                    onChange={(val) => setSchemaTypeId(val)}
                  />
                  {addRemoveFavoriteButton()}
                  {saveSchemaButton()}
                </>
              }
            />
          </Col>
        </Row>

        {/* Types */}
        <Row style={{ marginTop: 15 }}>
          <Col span={24}>
            <TypesSection schemaTypeId={schemaTypeId} />
          </Col>
        </Row>

        {/* Properties | Actions */}
        <Row gutter={24} style={{ marginTop: 15 }}>
          <Col span={12}>
            <PropertiesSection schemaTypeId={schemaTypeId} />
          </Col>
          <Col span={12}>
            <ActionsSection schemaTypeId={schemaTypeId} />
          </Col>
        </Row>

        {/* Links */}
        <Row style={{ marginTop: 15 }}>
          <Col span={24}>
            <LinksSection />
          </Col>
        </Row>

        {/* Permissions */}
        <Row style={{ marginTop: 15 }}>
          <Col span={24}>
            <PermissionsSection schemaTypeId={schemaTypeId} />
          </Col>
        </Row>

        {/* Pipelines */}
        <Row style={{ marginTop: 15 }}>
          <Col span={24}>
            <PipelineSection schemaTypeId={schemaTypeId} />
          </Col>
        </Row>

        {/* Data | Usage */}
        <Row gutter={24} style={{ marginTop: 15 }}>
          <Col span={15}>
            <Section title="Data">
              <Empty icon="th" text="No data available" />
            </Section>
          </Col>
          <Col span={9}>
            <Section title="Usage">
              <Empty icon="chart" text="No usage data available" />
            </Section>
          </Col>
        </Row>

        {/* Settings */}
        <Row style={{ marginTop: 15 }}>
          <Col span={24}>
            <SchemaSettingsSection data={schema} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(SchemaDetails);
